import React, { Component } from "react";

export default class ProjectsImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg 
      id="a6dc9487-50e2-4fb9-af91-bf1b7118d463"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="1103.79"
      height="707.8"
      x="0px" y="0px"
      viewBox="0 0 500 500">
      <g id="Background_Complete">
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
       
      
      <rect x="358.97" y="258.68" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 790.2039 573.0951)" style={{fill:theme.jacketColor}} width="72.264" height="55.736"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
       
      
      <rect x="364.135" y="299.707" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 736.9787 609.2582)" style={{fill:'#FFFFFF'}} width="8.708" height="9.843"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <rect x="400.98" y="258.823" style={{fill:'#FAFAFA'}} width="8.208" height="11.893"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <rect x="400.98" y="302.357" style={{fill:'#FAFAFA'}} width="8.208" height="11.893"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M377.007,314.799c-0.127,0-0.23-12.259-0.23-27.377c0-15.123,0.103-27.379,0.23-27.379
      c0.127,0,0.23,12.256,0.23,27.379C377.237,302.54,377.134,314.799,377.007,314.799z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
       
      
      <rect x="329.496" y="314.232" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 783.676 724.6304)" style={{fill:theme.jacketColor}} width="124.684" height="96.167"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
       
      
      <rect x="338.408" y="385.021" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 691.8409 787.0265)" style={{fill:'#FFFFFF'}} width="15.024" height="16.984"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <rect x="401.98" y="314.479" style={{fill:'#FAFAFA'}} width="14.163" height="20.52"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <rect x="401.98" y="389.593" style={{fill:'#FAFAFA'}} width="14.163" height="20.52"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M360.617,411.06c-0.22,0-0.398-21.151-0.398-47.236c0-26.094,0.178-47.241,0.398-47.241
      c0.22,0,0.397,21.146,0.397,47.241C361.014,389.909,360.836,411.06,360.617,411.06z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <polygon style={{fill:theme.jacketColor}} points="450.219,114.211 456.898,127.744 471.833,129.915 461.026,140.449 463.577,155.324 
      450.219,148.301 436.861,155.324 439.412,140.449 428.605,129.915 443.54,127.744 "/>
      </g>
      <g>
      <polygon style={{fill:theme.jacketColor}} points="52.509,288.967 56.719,297.496 66.131,298.864 59.32,305.503 60.928,314.877 
      52.509,310.451 44.091,314.877 45.698,305.503 38.888,298.864 48.3,297.496 "/>
      </g>
      <g>
      <polygon style={{fill:theme.jacketColor}} points="57.732,214.661 67.079,233.601 87.98,236.638 72.856,251.38 76.426,272.196 
      57.732,262.368 39.038,272.196 42.609,251.38 27.485,236.638 48.385,233.601 "/>
      </g>
      <g>
      <polygon style={{fill:theme.jacketColor}} points="444.271,175.324 448.3,183.487 457.308,184.796 450.79,191.15 452.329,200.122 
      444.271,195.886 436.214,200.122 437.753,191.15 431.234,184.796 440.242,183.487 "/>
      </g>
      <g>
      <polygon style={{fill:theme.jacketColor}} points="312.049,43.068 316.077,51.231 325.086,52.54 318.567,58.895 320.106,67.867 
      312.049,63.631 303.991,67.867 305.53,58.895 299.012,52.54 308.02,51.231 "/>
      </g>
      <g>
      <polygon style={{fill:theme.jacketColor}} points="404.224,130.409 406.861,135.752 412.757,136.609 408.49,140.768 409.497,146.64 
      404.224,143.867 398.95,146.64 399.957,140.768 395.691,136.609 401.587,135.752 "/>
      </g>
      <g>
      <polygon style={{fill:theme.jacketColor}} points="160.924,400.108 166.06,410.514 177.544,412.183 169.234,420.283 171.196,431.721 
      160.924,426.321 150.652,431.721 152.614,420.283 144.304,412.183 155.788,410.514 "/>
      </g>
      <g>
      <polygon style={{fill:theme.jacketColor}} points="174.481,389.764 176.603,394.064 181.348,394.753 177.915,398.1 178.725,402.826 
      174.481,400.594 170.237,402.826 171.048,398.1 167.615,394.753 172.359,394.064 "/>
      </g>
      </g>
      <g id="Stars">
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="364.135,349.92 368.651,359.07 378.748,360.537 371.442,367.659 373.167,377.716 
      364.135,372.967 355.104,377.716 356.829,367.659 349.523,360.537 359.62,359.07 "/>
      </g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="385.678,341.811 387.806,346.122 392.564,346.814 389.121,350.17 389.934,354.908 
      385.678,352.671 381.423,354.908 382.235,350.17 378.792,346.814 383.55,346.122 "/>
      </g>
      </g>
      <g id="Speech_Bubble">
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FAFAFA'}} d="M357.442,103.66l3.531-11.059c-2.749-5.513-4.055-11.828-3.459-18.41
      c1.712-18.931,18.468-32.887,37.424-31.172c18.958,1.714,32.937,18.45,31.225,37.381
      c-1.712,18.93-18.468,32.886-37.425,31.172c-7.916-0.716-14.435-3.582-19.835-8.602l0,0L357.442,103.66z"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M357.442,103.66c0,0,0.266-0.027,0.763-0.064c0.514-0.037,1.247-0.09,2.204-0.158
      c1.942-0.133,4.788-0.328,8.487-0.581l0.048-0.003l0.036,0.033c3.257,3.007,8.23,6.344,14.956,7.766
      c1.668,0.358,3.427,0.637,5.261,0.751c0.918,0.035,1.851,0.07,2.8,0.105c0.948-0.007,1.91-0.106,2.888-0.156
      c3.895-0.362,7.981-1.41,11.902-3.326c3.918-1.907,7.663-4.677,10.794-8.251c3.123-3.568,5.661-7.932,7.075-12.821
      c1.406-4.881,1.763-10.232,0.85-15.51c-0.158-1.334-0.579-2.619-0.914-3.931c-0.146-0.663-0.427-1.285-0.656-1.926
      c-0.244-0.635-0.468-1.281-0.731-1.911c-1.176-2.464-2.469-4.931-4.228-7.102
      c-0.795-1.146-1.781-2.126-2.707-3.147c-0.444-0.53-0.998-0.947-1.497-1.418c-0.517-0.45-1.004-0.935-1.543-1.353
      l-1.644-1.213l-0.82-0.603l-0.871-0.521l-1.735-1.038c-0.584-0.332-1.208-0.585-1.807-0.881
      c-4.837-2.269-10.085-3.371-15.159-3.261c-5.077,0.1-9.954,1.338-14.22,3.4c-4.275,2.058-7.939,4.923-10.821,8.184
      c-2.89,3.262-4.994,6.913-6.376,10.568c-0.165,0.461-0.329,0.917-0.491,1.371
      c-0.173,0.451-0.324,0.903-0.422,1.369c-0.229,0.92-0.454,1.826-0.676,2.717c-0.245,1.818-0.579,3.565-0.614,5.275
      c-0.276,6.854,1.531,12.538,3.5,16.526l0.021,0.042l-0.014,0.043c-1.169,3.563-2.069,6.303-2.683,8.173
      c-0.306,0.921-0.54,1.626-0.704,2.12C357.534,103.407,357.442,103.66,357.442,103.66s0.061-0.228,0.203-0.698
      c0.15-0.491,0.365-1.191,0.646-2.105c0.589-1.897,1.452-4.677,2.574-8.291l0.007,0.085
      c-2.021-3.994-3.885-9.716-3.643-16.64c0.026-1.727,0.354-3.494,0.595-5.333c0.22-0.901,0.443-1.818,0.671-2.75
      c0.097-0.471,0.248-0.93,0.421-1.386c0.163-0.459,0.327-0.922,0.492-1.389c1.383-3.703,3.5-7.407,6.418-10.72
      c2.909-3.311,6.615-6.224,10.944-8.32c4.321-2.099,9.266-3.365,14.417-3.472
      c5.147-0.118,10.474,0.993,15.386,3.294c0.608,0.299,1.241,0.556,1.834,0.893l1.763,1.052l0.885,0.529l0.833,0.611
      l1.67,1.231c0.547,0.424,1.042,0.916,1.567,1.373c0.506,0.478,1.068,0.901,1.52,1.439
      c0.94,1.036,1.94,2.032,2.748,3.195c1.784,2.205,3.099,4.708,4.29,7.211c0.267,0.64,0.494,1.296,0.742,1.941
      c0.233,0.65,0.517,1.282,0.665,1.955c0.34,1.331,0.765,2.637,0.926,3.991c0.923,5.359,0.555,10.788-0.879,15.739
      c-1.442,4.959-4.024,9.382-7.198,12.993c-3.182,3.617-6.985,6.414-10.958,8.334
      c-3.977,1.928-8.117,2.976-12.059,3.326c-0.989,0.047-1.963,0.143-2.921,0.145c-0.959-0.04-1.902-0.08-2.83-0.119
      c-1.851-0.124-3.624-0.414-5.307-0.784c-6.786-1.471-11.788-4.88-15.035-7.938l0.083,0.03
      c-3.737,0.195-6.612,0.345-8.574,0.448c-0.944,0.046-1.668,0.081-2.175,0.106
      C357.675,103.657,357.442,103.66,357.442,103.66z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="372.306,75.996 377.978,71.085 387.531,80.889 408.948,63.053 413.481,68.697 
      386.042,91.04 "/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g id="Form">
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M298.111,349.494L106.349,392.23c-4.655,1.037-9.269-1.895-10.306-6.55L33.809,106.432
      c-1.037-4.655,1.895-9.269,6.55-10.306l191.763-42.736c4.655-1.037,9.269,1.895,10.306,6.55l62.233,279.249
      C305.699,343.843,302.766,348.457,298.111,349.494z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M298.111,349.494c0,0,0.213-0.066,0.636-0.187c0.421-0.125,1.037-0.364,1.797-0.783
      c0.744-0.442,1.62-1.104,2.423-2.11c0.788-1.005,1.5-2.375,1.753-4.049c0.144-0.833,0.127-1.74-0.021-2.67
      c-0.157-0.93-0.413-1.901-0.631-2.924c-0.459-2.044-0.96-4.28-1.504-6.702c-1.085-4.845-2.337-10.434-3.747-16.725
      c-5.626-25.174-13.766-61.597-23.825-106.602c-5.023-22.505-10.526-47.156-16.432-73.619
      c-2.952-13.232-6.006-26.917-9.151-41.013c-1.572-7.048-3.167-14.199-4.785-21.448c-0.813-3.621-1.63-7.267-2.454-10.937
      c-0.436-1.793-1.521-3.405-2.967-4.557c-1.467-1.126-3.303-1.797-5.171-1.79c-0.936-0.004-1.845,0.18-2.783,0.401
      c-0.933,0.208-1.867,0.416-2.803,0.625c-1.871,0.417-3.748,0.836-5.631,1.256c-3.765,0.84-7.552,1.684-11.362,2.534
      c-7.619,1.699-15.324,3.417-23.112,5.154c-15.576,3.473-31.48,7.019-47.67,10.629
      c-32.381,7.218-65.908,14.691-100.246,22.345c-2.102,0.461-3.996,1.753-5.182,3.539c-1.201,1.775-1.679,4.013-1.304,6.115
      c0.905,4.256,1.886,8.519,2.821,12.757c1.89,8.481,3.767,16.906,5.631,25.269c3.727,16.727,7.399,33.209,11.007,49.403
      c7.214,32.39,14.173,63.633,20.802,93.395c6.626,29.763,12.922,58.045,18.815,84.514c0.736,3.309,1.466,6.589,2.19,9.84
      c0.369,1.621,0.706,3.256,1.097,4.844c0.405,1.569,1.293,2.992,2.477,4.07c1.187,1.082,2.679,1.801,4.243,2.08
      c0.785,0.131,1.582,0.15,2.369,0.074c0.779-0.103,1.564-0.294,2.348-0.468c3.133-0.697,6.235-1.387,9.307-2.071
      c12.286-2.733,24.077-5.357,35.331-7.86c45.017-10.004,81.446-18.1,106.625-23.695c12.588-2.788,22.362-4.953,28.993-6.422
      c3.313-0.729,5.84-1.285,7.542-1.66C297.238,349.674,298.111,349.494,298.111,349.494s-0.867,0.208-2.563,0.593
      c-1.7,0.384-4.224,0.953-7.533,1.7c-6.627,1.486-16.396,3.676-28.977,6.497c-25.171,5.626-61.591,13.765-106.596,23.823
      c-11.252,2.512-23.041,5.144-35.325,7.886c-3.071,0.686-6.173,1.378-9.305,2.077c-0.782,0.173-1.562,0.366-2.382,0.475
      c-0.818,0.08-1.646,0.061-2.462-0.075c-1.626-0.289-3.176-1.035-4.41-2.158c-1.232-1.119-2.155-2.599-2.577-4.229
      c-0.399-1.616-0.732-3.23-1.103-4.857c-0.726-3.251-1.457-6.531-2.196-9.839c-5.904-26.466-12.213-54.745-18.852-84.505
      c-6.636-29.76-13.603-61.002-20.825-93.39c-3.61-16.194-7.284-32.676-11.013-49.402
      c-1.864-8.363-3.742-16.788-5.632-25.269c-0.937-4.247-1.912-8.481-2.825-12.776c-0.393-2.205,0.109-4.555,1.368-6.415
      c1.243-1.873,3.234-3.23,5.436-3.712c34.339-7.651,67.866-15.122,100.248-22.337c16.191-3.607,32.096-7.149,47.673-10.619
      c7.788-1.734,15.495-3.451,23.114-5.148c3.81-0.848,7.597-1.692,11.363-2.531c1.883-0.419,3.76-0.837,5.631-1.254
      c0.936-0.208,1.87-0.416,2.803-0.624c0.92-0.217,1.894-0.415,2.868-0.409c1.953-0.007,3.872,0.695,5.403,1.872
      c1.512,1.203,2.644,2.89,3.099,4.762c0.823,3.674,1.64,7.324,2.452,10.949c1.614,7.25,3.206,14.401,4.776,21.45
      c3.138,14.098,6.185,27.785,9.131,41.018c5.889,26.467,11.375,51.122,16.383,73.63
      c10.004,45.017,18.1,81.449,23.696,106.63c1.395,6.295,2.635,11.887,3.71,16.734c0.536,2.423,1.032,4.66,1.484,6.706
      c0.215,1.026,0.466,1.99,0.622,2.932c0.146,0.94,0.159,1.857,0.011,2.698c-0.262,1.69-0.987,3.068-1.786,4.076
      c-0.815,1.009-1.7,1.669-2.452,2.107c-0.767,0.415-1.388,0.648-1.812,0.767
      C298.328,349.442,298.111,349.494,298.111,349.494z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g style={{opacity:0.3}}>
      <g>
      <g>
      <polygon points="116.073,382.344 290.794,342.403 285.266,308.156 275.364,288.967 108.224,354.618 "/>
      </g>
      </g>
      </g>
      <g style={{opacity:0.3}}>
      <g>
      <g>
      <polygon points="189.016,63.631 195.625,74.544 196.914,80.326 192.427,81.326 "/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <polygon style={{fill:'#FFFFFF'}} points="43.764,108.05 104.381,380.052 294.392,337.706 283.553,289.069 233.774,65.705 
      "/>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M43.764,108.05c1.354-0.303,74.49-16.669,189.974-42.51l0.165-0.037l0.037,0.165
      c16.857,75.589,37.894,169.919,60.652,271.971l0.005,0.022l0.046,0.204l-0.204,0.046
      c-67.931,15.132-132.433,29.501-190.014,42.327l-0.187,0.042l-0.041-0.186C68.514,219.469,44.132,109.707,43.764,108.05
      c0.37,1.655,24.903,111.385,60.804,271.96l-0.228-0.145c57.579-12.838,122.078-27.218,190.006-42.364l-0.159,0.25
      l-0.005-0.022c-22.729-102.058-43.738-196.394-60.573-271.988l0.202,0.128C118.282,91.511,45.117,107.75,43.764,108.05z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M193.452,83.535l-96.14,21.426l-4.838-21.711c-0.76-3.41,1.388-6.79,4.798-7.55l83.792-18.674
      c3.41-0.76,6.79,1.388,7.55,4.798L193.452,83.535z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <polygon style={{fill:'#263238'}} points="149.066,53.15 124.596,58.603 132.131,78.94 150.562,74.832 "/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M181.062,57.027c0.025,0.111-18.713,4.382-41.847,9.538
      c-23.142,5.157-41.916,9.247-41.941,9.135c-0.025-0.111,18.709-4.381,41.851-9.539
      C162.259,61.006,181.037,56.916,181.062,57.027z"/>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M98.462,99.983c-0.111,0.025-1.081-3.899-2.165-8.764c-1.085-4.866-1.873-8.83-1.762-8.855
      c0.111-0.025,1.081,3.899,2.165,8.765C97.784,95.994,98.573,99.958,98.462,99.983z"/>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M188.873,77.644c-0.112,0.025-1-3.536-1.984-7.953c-0.985-4.418-1.692-8.019-1.581-8.043
      c0.111-0.025,1,3.535,1.984,7.954C188.276,74.018,188.984,77.619,188.873,77.644z"/>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M185.063,82.386c0.025,0.112-19.21,4.493-42.957,9.785
      c-23.755,5.294-43.026,9.494-43.051,9.382c-0.025-0.111,19.206-4.492,42.961-9.786
      C165.763,86.476,185.038,82.275,185.063,82.386z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M134.45,161.514c0.025,0.113-12.948,3.1-28.972,6.671c-16.03,3.572-29.041,6.376-29.066,6.263
      c-0.025-0.113,12.945-3.099,28.975-6.672C121.411,164.205,134.425,161.401,134.45,161.514z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M231.163,150.622c0.025,0.113-33.873,7.763-75.703,17.086
      c-41.845,9.326-75.777,16.791-75.802,16.679c-0.025-0.113,33.866-7.762,75.711-17.087
      C197.2,157.977,231.138,150.51,231.163,150.622z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M233.247,159.97c0.025,0.113-33.873,7.764-75.703,17.086
      c-41.845,9.326-75.777,16.791-75.802,16.678c-0.025-0.113,33.866-7.762,75.711-17.087
      C199.283,167.325,233.221,159.857,233.247,159.97z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M235.33,169.318c0.025,0.113-33.873,7.763-75.703,17.086
      c-41.845,9.326-75.777,16.791-75.802,16.678c-0.025-0.113,33.866-7.762,75.711-17.087
      C201.366,176.673,235.305,169.205,235.33,169.318z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M237.413,178.666c0.025,0.113-33.873,7.764-75.703,17.086
      c-41.845,9.326-75.777,16.791-75.802,16.678c-0.025-0.113,33.866-7.762,75.711-17.087
      C203.449,186.02,237.388,178.553,237.413,178.666z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M239.496,188.014c0.025,0.113-33.873,7.764-75.703,17.086
      c-41.845,9.326-75.776,16.791-75.802,16.678c-0.025-0.113,33.866-7.762,75.711-17.087
      C205.533,195.368,239.471,187.901,239.496,188.014z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M117.372,253.814c-0.008-0.005-0.039-0.113-0.091-0.316c-0.057-0.233-0.13-0.526-0.22-0.891
      c-0.192-0.811-0.459-1.933-0.793-3.342c-0.668-2.891-1.606-6.954-2.728-11.814l0.216,0.137
      c-4.775,1.073-10.397,2.335-16.395,3.683c-0.037,0.054,0.299-0.471,0.158-0.247l0.001,0.004l0.002,0.009l0.004,0.018
      l0.008,0.035l0.016,0.07l0.031,0.14l0.063,0.281l0.125,0.56c0.083,0.372,0.165,0.743,0.247,1.112
      c0.164,0.738,0.327,1.47,0.487,2.193c0.321,1.447,0.634,2.863,0.94,4.241c0.606,2.754,1.178,5.354,1.704,7.742l-0.216-0.137
      c4.877-1.048,8.954-1.924,11.855-2.547c1.418-0.296,2.547-0.531,3.362-0.701c0.368-0.073,0.665-0.132,0.9-0.179
      C117.253,253.826,117.364,253.808,117.372,253.814c0.008,0.005-0.086,0.033-0.277,0.083c-0.224,0.055-0.506,0.125-0.856,0.212
      c-0.801,0.19-1.91,0.454-3.302,0.784c-2.919,0.674-7.02,1.62-11.927,2.753l-0.176,0.041l-0.04-0.178
      c-0.538-2.385-1.124-4.982-1.745-7.733c-0.309-1.377-0.626-2.793-0.95-4.239c-0.162-0.724-0.325-1.455-0.49-2.193
      c-0.082-0.369-0.165-0.74-0.248-1.112l-0.125-0.56l-0.063-0.281l-0.031-0.141l-0.016-0.07l-0.008-0.035l-0.004-0.018
      l-0.002-0.009l-0.001-0.004c-0.142,0.222,0.192-0.308,0.16-0.253c6.003-1.327,11.629-2.571,16.407-3.628l0.178-0.039
      l0.038,0.177c1.058,4.923,1.943,9.038,2.572,11.967c0.292,1.401,0.524,2.517,0.692,3.323c0.07,0.354,0.127,0.639,0.171,0.866
      C117.365,253.721,117.38,253.819,117.372,253.814z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
       
      
      <rect x="124.527" y="226.001" transform="matrix(0.9761 -0.2175 0.2175 0.9761 -45.8387 45.3649)" style={{fill:'#E0E0E0'}} width="117.218" height="9.779"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M123.713,282.265c-0.008-0.005-0.039-0.113-0.091-0.316c-0.057-0.233-0.13-0.526-0.22-0.891
      c-0.192-0.811-0.459-1.933-0.793-3.342c-0.668-2.892-1.605-6.954-2.728-11.814l0.216,0.137
      c-4.775,1.073-10.397,2.335-16.395,3.683c-0.037,0.054,0.299-0.472,0.158-0.247l0.001,0.004l0.002,0.009l0.004,0.018
      l0.008,0.035l0.016,0.07l0.031,0.141l0.063,0.281l0.125,0.56c0.083,0.372,0.165,0.743,0.248,1.112
      c0.164,0.738,0.326,1.47,0.487,2.193c0.321,1.447,0.634,2.863,0.94,4.241c0.606,2.754,1.178,5.354,1.704,7.742l-0.216-0.137
      c4.877-1.048,8.954-1.924,11.855-2.547c1.418-0.296,2.547-0.531,3.362-0.701c0.368-0.073,0.665-0.132,0.9-0.179
      C123.594,282.278,123.704,282.26,123.713,282.265c0.008,0.005-0.086,0.033-0.277,0.083c-0.224,0.055-0.506,0.125-0.856,0.212
      c-0.801,0.19-1.91,0.454-3.302,0.785c-2.919,0.674-7.021,1.62-11.928,2.753l-0.176,0.041l-0.04-0.178
      c-0.538-2.385-1.124-4.982-1.745-7.733c-0.309-1.377-0.626-2.792-0.95-4.239c-0.162-0.723-0.325-1.455-0.49-2.193
      c-0.082-0.369-0.165-0.74-0.248-1.112l-0.125-0.56l-0.063-0.281l-0.031-0.141l-0.016-0.07l-0.008-0.035l-0.004-0.018
      l-0.002-0.009l-0.001-0.004c-0.142,0.222,0.192-0.308,0.16-0.253c6.003-1.327,11.629-2.571,16.407-3.627l0.178-0.04
      l0.038,0.177c1.058,4.923,1.943,9.038,2.573,11.967c0.292,1.401,0.524,2.517,0.692,3.323c0.07,0.354,0.126,0.639,0.171,0.866
      C123.706,282.173,123.721,282.271,123.713,282.265z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
       
      
      <rect x="130.868" y="254.453" transform="matrix(0.9761 -0.2175 0.2175 0.9761 -51.8759 47.4254)" style={{fill:'#E0E0E0'}} width="117.218" height="9.779"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M130.053,310.717c-0.008-0.005-0.039-0.113-0.091-0.316c-0.057-0.233-0.13-0.526-0.22-0.891
      c-0.192-0.81-0.459-1.933-0.793-3.342c-0.668-2.892-1.605-6.954-2.728-11.814l0.216,0.137
      c-4.775,1.073-10.397,2.335-16.395,3.683c-0.037,0.054,0.3-0.471,0.158-0.247l0.001,0.005l0.002,0.009l0.004,0.018l0.008,0.035
      l0.016,0.07l0.031,0.141l0.063,0.281l0.125,0.56c0.083,0.372,0.165,0.743,0.247,1.112c0.164,0.738,0.327,1.47,0.487,2.193
      c0.321,1.447,0.634,2.863,0.939,4.241c0.606,2.754,1.178,5.354,1.704,7.742l-0.216-0.137c4.877-1.048,8.954-1.924,11.855-2.548
      c1.418-0.296,2.547-0.531,3.363-0.701c0.368-0.073,0.665-0.132,0.9-0.179C129.935,310.73,130.045,310.712,130.053,310.717
      c0.008,0.005-0.086,0.033-0.277,0.083c-0.224,0.056-0.506,0.126-0.856,0.213c-0.801,0.19-1.91,0.454-3.302,0.784
      c-2.919,0.674-7.02,1.62-11.927,2.753l-0.176,0.041l-0.04-0.178c-0.538-2.386-1.124-4.982-1.745-7.733
      c-0.309-1.377-0.626-2.793-0.95-4.239c-0.162-0.724-0.325-1.455-0.49-2.193c-0.082-0.369-0.165-0.74-0.248-1.112l-0.125-0.56
      l-0.063-0.281l-0.031-0.141l-0.016-0.07l-0.008-0.035l-0.004-0.018l-0.002-0.009l-0.001-0.004
      c-0.142,0.222,0.192-0.308,0.16-0.253c6.003-1.327,11.629-2.571,16.407-3.628l0.178-0.039l0.038,0.177
      c1.058,4.923,1.943,9.038,2.572,11.967c0.292,1.401,0.524,2.517,0.692,3.323c0.07,0.354,0.127,0.64,0.171,0.866
      C130.047,310.625,130.061,310.723,130.053,310.717z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
       
      
      <rect x="137.209" y="282.905" transform="matrix(0.9761 -0.2175 0.2175 0.9761 -57.913 49.486)" style={{fill:'#E0E0E0'}} width="117.218" height="9.779"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="101.615,247.935 103.333,245.232 108.442,248.071 115.016,238.153 117.561,239.906 
      109.076,252.405 "/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="107.744,275.435 109.462,272.732 114.57,275.571 121.145,265.653 123.69,267.406 
      115.205,279.905 "/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="114.028,303.631 115.746,300.928 120.854,303.767 127.428,293.849 129.974,295.602 
      121.488,308.101 "/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M166.396,339.014c0.025,0.113-9.635,2.362-21.573,5.022
      c-11.943,2.661-21.641,4.727-21.666,4.614c-0.025-0.113,9.632-2.361,21.575-5.023
      C156.67,340.967,166.37,338.902,166.396,339.014z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M187.941,333.864c0.025,0.113-3.529,1.001-7.937,1.983c-4.41,0.983-8.003,1.688-8.029,1.575
      c-0.025-0.113,3.528-1.001,7.938-1.984C184.321,334.456,187.916,333.751,187.941,333.864z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M259.281,311.409c0.025,0.113-8.667,2.146-19.411,4.54c-10.748,2.395-19.479,4.245-19.504,4.132
      c-0.025-0.113,8.665-2.146,19.413-4.541C250.523,313.146,259.256,311.296,259.281,311.409z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M249.992,337.733c-0.024-0.008,0.085-0.274,0.191-0.796c0.116-0.521,0.122-1.326-0.121-2.31
      c-0.251-0.985-0.766-2.124-1.383-3.442c-0.59-1.313-1.356-2.87-1.369-4.768c0.028-1.863,0.849-3.932,2.535-5.383
      c1.665-1.479,4.276-2.001,6.645-1.239c1.179,0.384,2.317,1.051,3.163,2.077c0.404,0.523,0.728,1.151,0.751,1.872
      c0.063,0.735-0.5,1.465-1.173,1.69c-0.665,0.255-1.314,0.331-1.968,0.488c-0.657,0.135-1.319,0.272-1.986,0.409
      c-1.337,0.264-2.701,0.509-4.093,0.691c-2.777,0.372-5.567,0.423-8.181,0.047c-1.278-0.135-2.628-0.156-3.591,0.585
      c-0.96,0.733-1.447,1.924-2.164,2.923c-0.353,0.504-0.804,0.986-1.395,1.246c-0.583,0.27-1.262,0.263-1.834,0.077
      c-1.154-0.389-2.002-1.162-2.918-1.682c-0.453-0.265-0.925-0.484-1.407-0.532c-0.478-0.046-0.959,0.071-1.34,0.339
      c-0.773,0.549-1.182,1.459-1.694,2.22c-1.012,1.617-2.753,2.383-4.222,2.436c-1.507,0.074-2.73-0.566-3.501-1.241
      c-0.787-0.688-1.18-1.42-1.385-1.919c-0.201-0.506-0.227-0.794-0.227-0.794c0.022-0.006,0.082,0.274,0.304,0.76
      c0.226,0.479,0.633,1.18,1.413,1.828c0.766,0.637,1.95,1.224,3.383,1.131c1.405-0.074,3.044-0.82,3.987-2.363
      c0.498-0.743,0.885-1.682,1.758-2.324c0.435-0.311,1.003-0.458,1.558-0.406c0.559,0.053,1.073,0.293,1.546,0.565
      c0.95,0.538,1.81,1.292,2.853,1.631c0.515,0.162,1.079,0.16,1.573-0.069c0.496-0.221,0.899-0.642,1.234-1.125
      c0.688-0.95,1.16-2.176,2.239-3.019c0.536-0.415,1.21-0.629,1.874-0.705c0.668-0.075,1.338-0.034,1.998,0.032
      c2.611,0.368,5.324,0.313,8.086-0.054c1.379-0.181,2.734-0.423,4.066-0.685c0.666-0.136,1.326-0.272,1.982-0.406
      c0.648-0.152,1.331-0.242,1.898-0.462c0.574-0.211,0.956-0.715,0.923-1.304c-0.013-0.582-0.295-1.161-0.66-1.632
      c-0.769-0.941-1.853-1.592-2.965-1.956c-2.249-0.732-4.719-0.253-6.303,1.136c-1.612,1.366-2.42,3.343-2.464,5.123
      c-0.009,1.802,0.714,3.348,1.286,4.667c0.595,1.324,1.097,2.489,1.327,3.5c0.224,1.012,0.19,1.84,0.045,2.365
      C250.131,337.48,249.992,337.733,249.992,337.733z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M270.667,324.931c0.003,0.014-0.177,0.067-0.522,0.155c-0.388,0.094-0.89,0.215-1.514,0.365
      c-1.366,0.317-3.259,0.756-5.577,1.293c-4.709,1.092-11.206,2.633-18.354,4.445c-7.15,1.815-13.596,3.557-18.256,4.842
      c-2.294,0.632-4.167,1.149-5.519,1.521c-0.62,0.165-1.119,0.298-1.505,0.4c-0.345,0.087-0.529,0.126-0.533,0.112
      c-0.004-0.014,0.171-0.08,0.509-0.193c0.38-0.122,0.872-0.28,1.483-0.476c1.29-0.407,3.163-0.971,5.484-1.65
      c4.641-1.358,11.079-3.147,18.234-4.963c7.153-1.813,13.664-3.31,18.392-4.328c2.364-0.51,4.28-0.906,5.607-1.164
      c0.631-0.119,1.138-0.214,1.53-0.288C270.479,324.942,270.664,324.917,270.667,324.931z"/>
      </g>
      </g>
      </g>
      <g>
       
      
      <rect x="111.659" y="113.974" transform="matrix(0.9761 -0.2175 0.2175 0.9761 -22.7915 35.8505)" style={{fill:'#E0E0E0'}} width="79.571" height="14.95"/>
      </g>
      </g>
      </g>
      <g id="Character_00000039826182135072414070000016352920049323683971_">
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="262.119,342.306 287.211,474.868 310.141,432.501 353.603,458.009 307.468,328.925 
      "/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g style={{opacity:0.3}}>
      <g>
      <path d="M270.558,386.89c4.638,0.955,10.052-0.685,13.343-4.09c2.286-2.365,3.811-5.578,6.694-7.162
      c2.588-1.422,5.779-1.219,8.625-0.433c2.846,0.786,5.526,2.105,8.384,2.846c5.602,1.454,11.78,0.536,16.719-2.482l-7.238-20.1
      l-33.603-4.197l-16.022,19.246L270.558,386.89z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M308.506,427.236c-0.139,0.036-3.876-13.731-8.345-30.747
      c-4.471-17.022-7.981-30.846-7.842-30.882c0.139-0.036,3.875,13.728,8.346,30.75
      C305.134,413.372,308.645,427.199,308.506,427.236z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="265.792,349.202 240.699,481.763 217.769,439.396 174.307,464.905 220.442,335.821 
      "/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g style={{opacity:0.3}}>
      <path d="M206.643,374.429c0,0,7.136,5.875,15.651,3.168c8.514-2.707,13.8-3.276,18.406,0
      c4.606,3.276,17.076,13.956,17.076,13.956l2.895-15.294l-16.746-22.999l-17.489,3.531l-12.455,1.244l-4.63,8.764
      L206.643,374.429z"/>
      </g>
      <g>
      <path style={{fill:theme.imageHighlight}} d="M412.904,229.537c0,9.511-12.157,17.704-13.935,26.693c-1.832,9.262,6.231,21.471,2.697,29.993
      c-3.595,8.671-17.965,11.586-23.125,19.293c-5.199,7.766-2.426,22.151-9.011,28.735c-6.584,6.584-20.969,3.811-28.735,9.01
      c-7.707,5.16-10.622,19.529-19.293,23.125c-8.522,3.534-20.731-4.529-29.993-2.697c-8.989,1.778-17.182,13.935-26.693,13.935
      c-9.511,0-17.704-12.157-26.693-13.935c-9.262-1.832-21.471,6.23-29.993,2.697c-8.671-3.595-11.586-17.965-19.293-23.125
      c-7.766-5.199-22.151-2.426-28.735-9.011c-6.584-6.584-3.811-20.969-9.01-28.735c-5.159-7.707-19.529-10.622-23.125-19.293
      c-3.534-8.522,4.529-20.731,2.697-29.993c-1.778-8.989-13.935-17.182-13.935-26.693s12.157-17.704,13.935-26.693
      c1.832-9.262-6.231-21.471-2.697-29.993c3.595-8.671,17.965-11.586,23.125-19.293c5.199-7.766,2.426-22.151,9.011-28.735
      c6.584-6.584,20.969-3.811,28.735-9.01c7.707-5.16,10.622-19.529,19.293-23.125c8.522-3.534,20.731,4.529,29.993,2.697
      c8.989-1.778,17.182-13.935,26.693-13.935c9.511,0,17.704,12.157,26.693,13.935c9.262,1.832,21.471-6.23,29.993-2.697
      c8.671,3.595,11.586,17.965,19.293,23.125c7.766,5.199,22.151,2.426,28.735,9.01c6.584,6.584,3.811,20.969,9.01,28.735
      c5.159,7.707,19.529,10.622,23.125,19.293c3.534,8.522-4.529,20.731-2.697,29.993
      C400.747,211.833,412.904,220.026,412.904,229.537z"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M230.168,146.531c-0.016,0.008-0.108-0.133-0.269-0.409c-0.185-0.332-0.41-0.734-0.682-1.219
      c-0.586-1.062-1.395-2.62-2.359-4.562c-1.932-3.883-4.43-9.336-6.839-15.503c-2.404-6.171-4.26-11.876-5.467-16.041
      c-0.605-2.082-1.065-3.776-1.353-4.954c-0.129-0.541-0.236-0.989-0.324-1.358c-0.068-0.312-0.096-0.478-0.079-0.483
      c0.017-0.005,0.079,0.152,0.18,0.455c0.114,0.362,0.252,0.801,0.418,1.332c0.381,1.235,0.891,2.891,1.51,4.902
      c1.296,4.131,3.202,9.803,5.6,15.959c2.403,6.152,4.841,11.615,6.685,15.533c0.906,1.899,1.652,3.463,2.208,4.629
      c0.236,0.503,0.433,0.92,0.594,1.263C230.122,146.365,230.184,146.522,230.168,146.531z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M243.346,141.519c-0.035,0.009-0.169-0.384-0.382-1.103c-0.215-0.718-0.498-1.765-0.828-3.063
      c-0.66-2.597-1.478-6.209-2.218-10.23c-0.738-4.023-1.257-7.691-1.562-10.353c-0.153-1.331-0.26-2.41-0.314-3.157
      c-0.056-0.747-0.07-1.162-0.035-1.166c0.036-0.004,0.118,0.403,0.236,1.142c0.13,0.849,0.289,1.896,0.478,3.132
      c0.398,2.645,0.973,6.294,1.709,10.307c0.738,4.012,1.497,7.627,2.064,10.24c0.263,1.223,0.486,2.258,0.666,3.098
      C243.313,141.1,243.381,141.51,243.346,141.519z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M266.596,139.209c-0.144,0.003-0.514-11.214-0.827-25.051
      c-0.313-13.842-0.45-25.062-0.306-25.065c0.144-0.003,0.514,11.212,0.827,25.054
      C266.602,127.983,266.739,139.206,266.596,139.209z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M289.99,116.161c0.137,0.043-1.477,5.652-3.605,12.526c-2.129,6.877-3.966,12.416-4.103,12.374
      c-0.137-0.042,1.477-5.65,3.606-12.528C288.016,121.658,289.852,116.118,289.99,116.161z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M299.54,145.147c-0.017-0.006,0.027-0.169,0.126-0.473c0.124-0.359,0.275-0.794,0.456-1.319
      c0.402-1.144,1.024-2.783,1.801-4.806c1.563-4.042,3.811-9.59,6.369-15.685c2.558-6.098,4.935-11.591,6.688-15.553
      c0.864-1.919,1.575-3.5,2.106-4.679c0.238-0.502,0.436-0.918,0.599-1.26c0.142-0.286,0.225-0.432,0.242-0.425
      s-0.034,0.168-0.146,0.467c-0.138,0.353-0.306,0.782-0.509,1.299c-0.492,1.196-1.152,2.798-1.953,4.744
      c-1.704,4.064-4.004,9.548-6.546,15.609c-2.527,6.064-4.813,11.552-6.507,15.62c-0.801,1.945-1.461,3.547-1.954,4.742
      c-0.218,0.511-0.398,0.935-0.547,1.284C299.633,145.003,299.556,145.153,299.54,145.147z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M312.041,151.666c-0.03-0.02,0.175-0.381,0.57-1.018c0.394-0.638,0.988-1.545,1.739-2.654
      c1.502-2.219,3.658-5.23,6.172-8.455c2.517-3.225,4.913-6.049,6.699-8.046c0.893-0.999,1.628-1.795,2.151-2.333
      c0.521-0.539,0.822-0.825,0.849-0.801c0.027,0.024-0.223,0.356-0.697,0.935c-0.548,0.661-1.224,1.476-2.023,2.439
      c-1.712,2.055-4.057,4.909-6.568,8.126c-2.508,3.217-4.705,6.186-6.282,8.347c-0.739,1.009-1.365,1.864-1.873,2.556
      C312.332,151.363,312.071,151.686,312.041,151.666z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M330.221,166.571c-0.098-0.105,7.814-7.626,17.67-16.795
      c9.859-9.172,17.929-16.52,18.027-16.415c0.098,0.105-7.813,7.624-17.672,16.796
      C338.39,159.327,330.319,166.676,330.221,166.571z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M361.984,169.677c0.032,0.072-1.14,0.659-3.014,1.642c-1.876,0.98-4.433,2.394-7.252,3.977
      c-2.821,1.584-5.419,2.954-7.344,3.852c-0.962,0.449-1.748,0.798-2.301,1.016c-0.551,0.222-0.863,0.329-0.878,0.296
      c-0.032-0.071,1.14-0.659,3.014-1.642c1.876-0.98,4.433-2.394,7.254-3.978c2.82-1.583,5.418-2.953,7.342-3.852
      c0.962-0.449,1.748-0.798,2.301-1.016C361.658,169.751,361.969,169.644,361.984,169.677z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M391.388,181.874c0.048,0.136-9.371,3.575-21.034,7.681
      c-11.667,4.107-21.161,7.326-21.209,7.191c-0.048-0.136,9.369-3.574,21.036-7.682
      C381.845,184.958,391.34,181.738,391.388,181.874z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M400.747,235.34c-0.014,0.143-10.249-0.714-22.859-1.914
      c-12.614-1.201-22.825-2.29-22.812-2.433c0.014-0.143,10.247,0.714,22.861,1.915
      C390.547,234.108,400.76,235.197,400.747,235.34z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M393.22,283.297c-0.061,0.13-9.631-4.262-21.372-9.809c-11.745-5.549-21.213-10.15-21.151-10.28
      c0.061-0.13,9.629,4.261,21.374,9.81C383.811,278.564,393.281,283.167,393.22,283.297z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M361.74,328.925c-0.11,0.093-7.012-7.913-15.415-17.879
      c-8.405-9.969-15.128-18.123-15.018-18.216c0.11-0.093,7.011,7.911,15.416,17.88
      C355.126,320.677,361.85,328.833,361.74,328.925z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M335.465,334.953c-0.03,0.019-0.326-0.382-0.834-1.124c-0.51-0.742-1.226-1.829-2.095-3.183
      c-1.738-2.707-4.069-6.494-6.523-10.746c-2.454-4.254-4.565-8.167-6.039-11.027c-0.737-1.43-1.32-2.594-1.707-3.407
      c-0.388-0.812-0.588-1.268-0.556-1.285c0.032-0.016,0.292,0.409,0.735,1.191c0.486,0.873,1.108,1.99,1.852,3.327
      c1.556,2.813,3.716,6.692,6.166,10.941c2.451,4.246,4.728,8.057,6.385,10.812c0.785,1.314,1.441,2.411,1.953,3.268
      C335.257,334.496,335.495,334.934,335.465,334.953z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M319.141,357.901c-0.016,0.008-0.105-0.133-0.26-0.41c-0.178-0.333-0.394-0.736-0.654-1.222
      c-0.562-1.064-1.336-2.625-2.256-4.57c-1.843-3.889-4.211-9.352-6.464-15.524c-2.247-6.177-3.949-11.883-5.04-16.046
      c-0.547-2.082-0.959-3.774-1.213-4.95c-0.113-0.539-0.207-0.987-0.285-1.356c-0.06-0.311-0.082-0.477-0.065-0.481
      c0.018-0.004,0.074,0.153,0.167,0.456c0.104,0.363,0.229,0.802,0.38,1.332c0.346,1.236,0.809,2.892,1.371,4.901
      c1.18,4.131,2.932,9.805,5.174,15.965c2.247,6.157,4.555,11.627,6.309,15.549c0.863,1.9,1.573,3.466,2.104,4.635
      c0.225,0.503,0.412,0.92,0.566,1.264C319.099,357.736,319.157,357.893,319.141,357.901z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M286.079,341.813c-0.077,0.004-0.2-1.331-0.432-3.482c-0.231-2.151-0.594-5.116-1.137-8.369
      c-0.546-3.254-1.168-6.176-1.649-8.285c-0.481-2.109-0.799-3.412-0.725-3.433c0.035-0.01,0.157,0.304,0.349,0.88
      c0.194,0.576,0.446,1.417,0.733,2.464c0.575,2.093,1.257,5.019,1.806,8.288c0.545,3.269,0.852,6.257,0.99,8.423
      c0.07,1.083,0.106,1.961,0.109,2.568C286.128,341.475,286.115,341.811,286.079,341.813z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M264.451,370.599c-0.018,0-0.033-0.177-0.044-0.513c-0.006-0.396-0.014-0.881-0.023-1.47
      c-0.013-1.277-0.005-3.124,0.037-5.406c0.076-4.565,0.285-10.869,0.651-17.824c0.361-6.958,0.809-13.249,1.177-17.799
      c0.186-2.275,0.352-4.114,0.482-5.384c0.066-0.585,0.12-1.068,0.164-1.461c0.043-0.334,0.076-0.508,0.094-0.506
      c0.018,0.001,0.021,0.179,0.011,0.515c-0.018,0.395-0.04,0.88-0.067,1.469c-0.079,1.352-0.187,3.176-0.317,5.395
      c-0.266,4.627-0.626,10.884-1.024,17.8c-0.365,6.951-0.632,13.248-0.803,17.807c-0.083,2.221-0.152,4.046-0.202,5.4
      c-0.03,0.588-0.054,1.073-0.074,1.468C264.49,370.423,264.469,370.599,264.451,370.599z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M246.87,320.234c0.069,0.013-0.039,0.988-0.3,2.552c-0.26,1.563-0.681,3.713-1.232,6.067
      c-0.552,2.355-1.13,4.468-1.592,5.984c-0.461,1.517-0.798,2.438-0.865,2.419c-0.071-0.02,0.141-0.978,0.51-2.515
      c0.412-1.717,0.9-3.754,1.44-6.006c0.517-2.257,0.984-4.298,1.378-6.02C246.561,321.173,246.797,320.221,246.87,320.234z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M215.082,304.961c0.115,0.087-1.844,2.863-4.375,6.2c-2.531,3.338-4.676,5.972-4.79,5.885
      c-0.115-0.087,1.844-2.862,4.376-6.2C212.822,307.509,214.967,304.874,215.082,304.961z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M198.406,293.757c0.108,0.095-6.097,7.382-13.859,16.274
      c-7.764,8.896-14.145,16.028-14.253,15.933c-0.108-0.095,6.096-7.38,13.861-16.275
      C191.916,300.796,198.297,293.662,198.406,293.757z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M190.061,281.355c0.091,0.111-3.361,3.107-7.711,6.692c-4.351,3.586-7.951,6.402-8.043,6.291
      c-0.091-0.111,3.36-3.107,7.711-6.693C186.368,284.06,189.969,281.244,190.061,281.355z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M180.298,262.641c0.063,0.13-8.581,4.424-19.303,9.591c-10.726,5.169-19.469,9.253-19.531,9.123
      c-0.062-0.13,8.579-4.423,19.305-9.592C171.491,266.596,180.236,262.512,180.298,262.641z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M176.375,253.307c0.007,0.035-0.313,0.128-0.898,0.266c-0.584,0.14-1.435,0.315-2.491,0.51
      c-2.111,0.392-5.047,0.837-8.308,1.165c-3.263,0.325-6.229,0.47-8.376,0.505c-1.074,0.018-1.942,0.015-2.543-0.007
      c-0.6-0.019-0.932-0.047-0.933-0.083c-0.001-0.076,1.324-0.149,3.461-0.278c2.138-0.129,5.088-0.331,8.338-0.655
      c3.249-0.326,6.18-0.713,8.301-1.01C175.048,253.423,176.361,253.232,176.375,253.307z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M173.209,231.762c-0.003,0.036-0.626,0.02-1.75-0.038c-1.209-0.062-2.813-0.145-4.756-0.244
      c-4.015-0.195-9.566-0.378-15.698-0.348c-6.134,0.035-11.683,0.278-15.696,0.516c-1.941,0.121-3.544,0.22-4.753,0.295
      c-1.122,0.071-1.745,0.093-1.749,0.057c-0.003-0.036,0.613-0.126,1.731-0.259c1.118-0.136,2.739-0.296,4.745-0.461
      c4.012-0.332,9.571-0.634,15.718-0.67c6.145-0.031,11.707,0.212,15.722,0.501c2.008,0.143,3.631,0.286,4.75,0.41
      C172.595,231.643,173.212,231.726,173.209,231.762z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M175.173,212.839c-0.039,0.139-6.514-1.547-14.461-3.765c-7.95-2.218-14.361-4.128-14.323-4.267
      c0.039-0.139,6.513,1.547,14.463,3.765C168.799,210.79,175.212,212.701,175.173,212.839z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M179.358,196.974c-0.006,0.017-0.168-0.02-0.472-0.106c-0.361-0.109-0.797-0.242-1.323-0.401
      c-1.147-0.354-2.791-0.914-4.808-1.648c-4.034-1.465-9.533-3.708-15.414-6.6c-5.879-2.9-11.01-5.892-14.631-8.197
      c-1.811-1.151-3.257-2.113-4.237-2.806c-0.447-0.32-0.817-0.585-1.124-0.804c-0.254-0.188-0.382-0.295-0.372-0.31
      c0.01-0.015,0.158,0.062,0.43,0.223c0.321,0.197,0.709,0.435,1.177,0.722c1.013,0.641,2.487,1.554,4.319,2.662
      c3.663,2.22,8.806,5.152,14.667,8.042c5.863,2.883,11.322,5.173,15.314,6.723c1.996,0.777,3.618,1.389,4.744,1.801
      c0.513,0.196,0.938,0.359,1.29,0.493C179.213,196.887,179.364,196.957,179.358,196.974z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M188.771,181.025c-0.083,0.118-5.145-3.305-11.306-7.643c-6.163-4.34-11.09-7.952-11.007-8.07
      c0.083-0.118,5.144,3.304,11.307,7.644C183.926,177.295,188.854,180.908,188.771,181.025z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M168.876,132.417c0.012-0.013,0.142,0.083,0.379,0.277c0.279,0.239,0.614,0.526,1.017,0.871
      c0.933,0.827,2.176,1.929,3.683,3.264c3.159,2.819,7.412,6.613,12.113,10.807c4.721,4.174,8.991,7.951,12.163,10.755
      c1.504,1.339,2.745,2.443,3.676,3.272c0.39,0.359,0.714,0.658,0.984,0.907c0.221,0.212,0.332,0.33,0.32,0.343
      c-0.012,0.014-0.145-0.077-0.389-0.263c-0.287-0.229-0.633-0.503-1.048-0.833c-0.904-0.732-2.197-1.806-3.785-3.147
      c-3.176-2.681-7.525-6.432-12.268-10.644c-4.741-4.212-8.979-8.086-12.016-10.924c-1.518-1.419-2.739-2.577-3.572-3.387
      c-0.377-0.373-0.69-0.684-0.951-0.943C168.97,132.552,168.864,132.43,168.876,132.417z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M200.071,133.326c0.028-0.023,0.269,0.225,0.68,0.692c0.412,0.466,0.988,1.158,1.684,2.026
      c1.392,1.735,3.244,4.191,5.161,6.993c1.917,2.804,3.534,5.421,4.646,7.348c0.557,0.963,0.992,1.751,1.277,2.304
      c0.286,0.553,0.43,0.867,0.399,0.884c-0.066,0.038-0.8-1.125-1.99-2.997c-1.19-1.874-2.851-4.45-4.761-7.245
      c-1.911-2.793-3.709-5.275-5.024-7.063C200.829,134.479,200.012,133.374,200.071,133.326z"/>
      </g>
      </g>
      <g style={{opacity:0.2}}>
      <path d="M160.104,124.823l43.503,38.091l10.814-8.653l-25.245-38.683c0,0-1.934,1.423-6.961,2.859
      C177.346,119.829,163.008,120.201,160.104,124.823z"/>
      </g>
      <g style={{opacity:0.2}}>
      <path d="M208.528,92.601l21.245,53.172l13.573-4.253l-4.523-46.117c0,0-2.338,0.547-7.524-0.112
      C226.275,94.652,214.08,89.884,208.528,92.601z"/>
      </g>
      <g style={{opacity:0.3}}>
      <path d="M265.171,81.467l1.425,57.742l12.725,0.611l12.883-44.317c-0.245-0.022-3.57-0.597-7.491-3.103
      C280.306,89.585,271.334,81.008,265.171,81.467z"/>
      </g>
      <g style={{opacity:0.2}}>
      <path d="M320.65,92.376l-21.111,53.232l11.494,5.495l29.051-35.861c-0.218-0.115-2.971-2.408-5.517-6.487
      C331.799,104.318,327.081,94.153,320.65,92.376z"/>
      </g>
      <g style={{opacity:0.2}}>
      <path d="M370.229,125.594l-39.322,40.977l8.764,11.81l38.42-25.643c0,0-1.614-2.558-2.527-7.481
      C374.639,140.277,374.505,131.203,370.229,125.594z"/>
      </g>
      <g style={{opacity:0.2}}>
      <path d="M401.268,171.852l-52.95,21.793l4.393,13.528l46.068-4.999c0,0-0.288-1.424,0.317-6.617
      C399.683,190.526,404.966,179.29,401.268,171.852z"/>
      </g>
      <g style={{opacity:0.2}}>
      <path d="M412.986,229.537l-57.724,2.021l-0.48,12.73l44.035,12.883c0.019-0.246,0.412-2.733,1.746-5.256
      C403.805,245.784,412.986,237.14,412.986,229.537z"/>
      </g>
      <g style={{opacity:0.2}}>
      <path d="M402.353,284.121l-53.15-20.716l-5.376,11.55l36.159,28.679c0.113-0.219,2.225-2.176,6.277-4.764
      C390.672,296.056,401.13,291.51,402.353,284.121z"/>
      </g>
      <g style={{opacity:0.2}}>
      <g>
      <path d="M324.177,283.297l-13.155,11.041c0.996,1.042,18.53,30.84,29.332,49.262c0.149-0.109,0.288-0.236,0.44-0.339
      c7.116-4.764,19.774-2.854,26.911-7.565L324.177,283.297z"/>
      </g>
      <g>
      <path d="M300.003,304.181l-19.642,6.697l9.3,53.324c0.613-0.214,1.229-0.39,1.849-0.513c9.262-1.832,21.471,6.231,29.993,2.697
      c1.172-0.486,2.231-1.181,3.219-2.011L300.003,304.181z"/>
      </g>
      <g>
      <path d="M264.136,377.597l3.139-69.661l-16.982-4.302l-12.636,59.978c0.156,0.025,0.313,0.046,0.468,0.076
      C246.898,365.424,254.914,377.041,264.136,377.597z"/>
      </g>
      <g>
      <path d="M221.435,296.11c0,0-22.441,30.057-34.173,46.245c0.549,0.273,1.077,0.572,1.576,0.906
      c7.707,5.16,10.622,19.53,19.293,23.125c0.398,0.165,0.806,0.297,1.219,0.414l31.349-59.339L221.435,296.11z"/>
      </g>
      <g>
      <path d="M198.406,271.908l-47.86,32.895c0.183,0.237,0.383,0.465,0.548,0.712c5.199,7.766,2.426,22.151,9.01,28.735
      c0.606,0.606,1.296,1.11,2.023,1.571c16.912-19.063,47.272-53.277,48.372-54.466L198.406,271.908z"/>
      </g>
      <g>
      <path d="M187.44,248.423l-56.585,9.139c0.901,9.076-6.253,20.541-2.886,28.66c0.206,0.497,0.456,0.969,0.729,1.43
      c19.802-9.859,61.176-30.368,62.897-30.481L187.44,248.423z"/>
      </g>
      <g>
      <path d="M184.35,216.904l-53.491-15.437c-0.045,0.465-0.104,0.926-0.194,1.378c-1.778,8.989-13.935,17.182-13.935,26.693
      c0,0.638,0.059,1.27,0.161,1.897h65.293L184.35,216.904z"/>
      </g>
      <g>
      <path d="M128.185,172.426c19.988,10.713,59.078,32.382,59.078,32.382l9.62-18.269l-45.187-34.021
      c-0.193,0.353-0.381,0.711-0.603,1.041C146.022,161.134,132.074,164.093,128.185,172.426z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M412.904,229.537c0,0-0.025-0.213-0.063-0.633c-0.054-0.418-0.035-1.055-0.264-1.86
      c-0.314-1.632-1.228-3.977-2.997-6.704c-1.717-2.763-4.32-5.846-7.034-9.759c-1.323-1.967-2.699-4.188-3.434-6.854
      c-0.733-2.67-0.593-5.676-0.018-8.707c0.552-3.049,1.461-6.189,2.256-9.52c0.735-3.313,1.527-6.932,0.788-10.615
      c-0.393-1.819-1.257-3.554-2.485-5.061c-1.241-1.502-2.8-2.764-4.447-3.947c-3.319-2.355-7.132-4.241-10.785-6.698
      c-1.816-1.234-3.62-2.608-5.103-4.362c-1.499-1.749-2.47-3.93-3.125-6.186c-1.286-4.547-1.569-9.414-2.546-14.275
      c-0.498-2.419-1.171-4.878-2.463-7.058c-1.264-2.198-3.309-3.898-5.696-4.928c-2.382-1.057-5-1.605-7.647-2.044
      c-2.654-0.436-5.367-0.745-8.095-1.229c-2.716-0.488-5.501-1.132-8.016-2.524c-2.53-1.382-4.507-3.604-6.164-5.97
      c-1.66-2.385-3.087-4.945-4.607-7.472c-1.522-2.519-3.122-5.052-5.198-7.205c-2.073-2.133-4.838-3.668-7.887-3.868
      c-3.036-0.226-6.112,0.355-9.144,1.074c-3.04,0.73-6.083,1.618-9.236,2.21c-3.126,0.61-6.488,0.825-9.657-0.181
      c-3.122-1.084-5.875-2.977-8.533-4.927c-2.662-1.959-5.263-4.046-8.115-5.778c-2.837-1.676-6.076-3.073-9.445-2.695
      l-0.625,0.048c-0.206,0.035-0.41,0.089-0.615,0.132c-0.408,0.097-0.823,0.172-1.227,0.286
      c-0.792,0.292-1.605,0.529-2.361,0.924c-1.553,0.69-2.999,1.625-4.433,2.573c-2.848,1.942-5.533,4.172-8.45,6.153
      c-1.443,1.011-2.988,1.898-4.602,2.661c-1.633,0.736-3.407,1.18-5.208,1.316c-3.62,0.177-7.179-0.64-10.667-1.52
      c-3.497-0.876-6.995-1.88-10.603-2.226c-1.8-0.151-3.623-0.075-5.369,0.361c-1.759,0.423-3.319,1.422-4.703,2.584
      c-2.758,2.407-4.686,5.645-6.603,8.829c-1.912,3.202-3.728,6.541-6.268,9.415c-1.281,1.411-2.763,2.698-4.499,3.569
      c-1.735,0.852-3.585,1.422-5.457,1.864c-3.759,0.825-7.599,1.178-11.383,1.863c-3.742,0.68-7.636,1.744-10.411,4.433
      c-1.335,1.371-2.27,3.087-2.956,4.89c-0.701,1.806-1.103,3.727-1.458,5.652c-0.701,3.858-1.024,7.82-1.959,11.715
      c-0.445,1.947-1.114,3.875-2.128,5.634c-1.016,1.758-2.424,3.283-4.008,4.535c-3.16,2.54-6.754,4.42-10.187,6.522
      c-1.712,1.059-3.403,2.161-4.959,3.434c-1.532,1.291-2.924,2.773-3.878,4.523c-0.967,1.74-1.409,3.748-1.393,5.763
      c-0.007,2.016,0.295,4.035,0.702,6.034c0.846,3.997,2.115,7.934,2.792,12.044c0.353,2.047,0.48,4.165,0.206,6.257
      c-0.269,2.099-1.08,4.094-2.089,5.929c-2.083,3.655-4.803,6.859-7.214,10.262c-1.218,1.693-2.342,3.458-3.225,5.336
      c-0.877,1.872-1.498,3.89-1.513,5.958c0.013,2.068,0.62,4.089,1.494,5.964c0.877,1.882,2.004,3.646,3.22,5.341
      c2.409,3.406,5.133,6.608,7.219,10.263c1.012,1.834,1.828,3.826,2.105,5.926c0.281,2.092,0.156,4.212-0.197,6.26
      c-0.672,4.112-1.944,8.051-2.79,12.049c-0.407,1.999-0.713,4.018-0.71,6.035c-0.022,2.016,0.423,4.024,1.382,5.768
      c0.949,1.753,2.34,3.238,3.871,4.531c1.557,1.272,3.245,2.378,4.958,3.437c3.435,2.103,7.029,3.984,10.192,6.521
      c1.583,1.253,2.995,2.774,4.014,4.531c1.018,1.758,1.686,3.687,2.133,5.633c0.938,3.895,1.26,7.859,1.96,11.717
      c0.354,1.926,0.757,3.847,1.457,5.654c0.686,1.803,1.616,3.523,2.953,4.894c2.772,2.694,6.667,3.759,10.409,4.44
      c3.785,0.686,7.626,1.039,11.386,1.863c1.873,0.441,3.722,1.011,5.458,1.862c1.736,0.872,3.219,2.158,4.501,3.569
      c2.541,2.874,4.357,6.214,6.27,9.416c1.918,3.184,3.846,6.423,6.604,8.831c1.384,1.162,2.944,2.161,4.704,2.585
      c1.746,0.436,3.57,0.512,5.37,0.361c3.608-0.347,7.107-1.351,10.604-2.227c3.489-0.88,7.048-1.697,10.669-1.519
      c1.801,0.137,3.575,0.582,5.208,1.318c1.614,0.764,3.159,1.651,4.602,2.662c2.917,1.982,5.602,4.212,8.451,6.154
      c1.434,0.948,2.88,1.883,4.434,2.572c0.756,0.396,1.57,0.632,2.362,0.924c0.405,0.113,0.819,0.189,1.227,0.286
      c0.205,0.043,0.409,0.098,0.615,0.132l0.625,0.048c3.37,0.377,6.608-1.024,9.445-2.699c2.852-1.734,5.453-3.82,8.115-5.78
      c2.659-1.949,5.411-3.844,8.535-4.925c3.17-1.005,6.531-0.787,9.658-0.176c3.153,0.592,6.196,1.481,9.236,2.211
      c3.033,0.718,6.109,1.298,9.145,1.071c3.048-0.205,5.813-1.74,7.884-3.876c2.075-2.154,3.675-4.688,5.196-7.207
      c1.52-2.527,2.947-5.086,4.607-7.472c1.659-2.365,3.637-4.586,6.169-5.966c2.516-1.39,5.302-2.031,8.017-2.519
      c2.728-0.484,5.441-0.793,8.095-1.229c2.647-0.44,5.265-0.988,7.646-2.047c2.386-1.032,4.43-2.735,5.692-4.934
      c1.29-2.181,1.962-4.639,2.459-7.059c0.976-4.861,1.259-9.729,2.547-14.274c0.654-2.256,1.628-4.436,3.129-6.184
      c1.484-1.753,3.288-3.126,5.105-4.359c3.654-2.456,7.466-4.343,10.784-6.698c1.647-1.183,3.204-2.447,4.445-3.948
      c1.227-1.508,2.089-3.244,2.48-5.063c0.426-1.82,0.316-3.677,0.153-5.449c-0.176-1.783-0.575-3.495-0.944-5.165
      c-0.796-3.33-1.704-6.47-2.256-9.518c-0.574-3.031-0.712-6.037,0.021-8.706c0.736-2.665,2.112-4.886,3.435-6.853
      c2.714-3.912,5.317-6.994,7.033-9.757c1.769-2.727,2.683-5.071,2.996-6.703c0.229-0.805,0.21-1.442,0.263-1.859
      C412.879,229.75,412.904,229.537,412.904,229.537s-0.008,0.213-0.036,0.634c-0.045,0.419-0.017,1.059-0.238,1.869
      c-0.297,1.642-1.2,4.005-2.959,6.748c-1.707,2.779-4.301,5.878-6.994,9.788c-1.312,1.965-2.673,4.18-3.39,6.812
      c-0.715,2.634-0.574,5.607,0.006,8.624c0.557,3.033,1.471,6.166,2.276,9.502c0.373,1.675,0.777,3.392,0.96,5.191
      c0.168,1.788,0.285,3.666-0.145,5.526c-0.395,1.858-1.276,3.64-2.523,5.176c-1.26,1.53-2.833,2.81-4.49,4.004
      c-3.337,2.376-7.151,4.271-10.778,6.719c-1.803,1.228-3.585,2.591-5.036,4.311c-1.465,1.711-2.415,3.843-3.06,6.077
      c-1.27,4.499-1.548,9.36-2.522,14.254c-0.498,2.437-1.172,4.929-2.491,7.167c-1.289,2.258-3.41,4.028-5.842,5.08
      c-2.429,1.083-5.072,1.637-7.73,2.082c-2.665,0.442-5.377,0.754-8.088,1.238c-2.7,0.487-5.451,1.129-7.9,2.487
      c-2.458,1.342-4.392,3.51-6.035,5.857c-1.643,2.364-3.065,4.918-4.586,7.453c-1.524,2.528-3.132,5.085-5.247,7.286
      c-2.102,2.184-4.997,3.794-8.134,4.003c-3.118,0.236-6.23-0.355-9.278-1.073c-3.053-0.731-6.093-1.615-9.216-2.199
      c-3.103-0.604-6.383-0.804-9.446,0.169c-3.037,1.053-5.762,2.922-8.409,4.868c-2.653,1.955-5.259,4.05-8.146,5.808
      c-0.744,0.402-1.454,0.868-2.239,1.203c-0.387,0.176-0.767,0.371-1.161,0.533l-1.21,0.429c-1.63,0.495-3.374,0.828-5.106,0.602
      l-0.65-0.051c-0.214-0.034-0.424-0.091-0.636-0.135c-0.422-0.099-0.851-0.178-1.269-0.295
      c-0.813-0.298-1.652-0.543-2.423-0.945c-1.589-0.705-3.052-1.651-4.501-2.607c-2.872-1.956-5.562-4.187-8.454-6.149
      c-1.432-1.003-2.953-1.873-4.532-2.62c-1.596-0.717-3.299-1.141-5.048-1.275c-3.52-0.172-7.043,0.63-10.523,1.511
      c-3.488,0.874-7.003,1.889-10.679,2.244c-1.836,0.154-3.716,0.081-5.536-0.373c-1.838-0.443-3.47-1.485-4.896-2.684
      c-2.843-2.484-4.781-5.757-6.708-8.948c-1.915-3.206-3.729-6.527-6.216-9.336c-1.256-1.38-2.682-2.614-4.359-3.457
      c-1.683-0.824-3.496-1.384-5.343-1.819c-3.715-0.814-7.55-1.167-11.367-1.856c-1.902-0.368-3.822-0.761-5.654-1.486
      c-1.828-0.707-3.592-1.687-5.023-3.092c-1.402-1.437-2.365-3.223-3.069-5.071c-0.717-1.854-1.124-3.8-1.482-5.742
      c-0.706-3.889-1.03-7.846-1.957-11.687c-0.441-1.921-1.097-3.802-2.079-5.496c-0.982-1.692-2.339-3.16-3.896-4.392
      c-3.103-2.49-6.684-4.368-10.137-6.479c-1.724-1.066-3.431-2.182-5.019-3.479c-1.563-1.32-3.003-2.85-3.996-4.683
      c-1.005-1.824-1.467-3.938-1.444-6.007c-0.004-2.075,0.309-4.128,0.72-6.147c0.855-4.033,2.123-7.968,2.785-12.024
      c0.348-2.021,0.465-4.089,0.195-6.107c-0.266-2.02-1.05-3.941-2.044-5.744c-2.048-3.591-4.762-6.787-7.189-10.216
      c-1.224-1.706-2.368-3.495-3.267-5.422c-0.895-1.92-1.53-4.016-1.543-6.184c0.016-2.169,0.664-4.261,1.562-6.177
      c0.904-1.923,2.046-3.712,3.272-5.416c2.429-3.425,5.139-6.622,7.184-10.214c0.991-1.804,1.77-3.726,2.029-5.746
      c0.264-2.017,0.145-4.085-0.204-6.105c-0.666-4.054-1.933-7.988-2.786-12.02c-0.41-2.019-0.72-4.072-0.712-6.146
      c-0.018-2.068,0.442-4.183,1.455-6.003c0.998-1.829,2.44-3.357,4.004-4.674c1.587-1.298,3.296-2.411,5.019-3.476
      c3.451-2.111,7.032-3.988,10.132-6.48c1.557-1.231,2.911-2.702,3.89-4.395c0.978-1.696,1.635-3.576,2.074-5.497
      c0.924-3.84,1.249-7.796,1.955-11.684c0.359-1.941,0.766-3.887,1.484-5.74c0.704-1.848,1.671-3.631,3.072-5.068
      c1.433-1.403,3.198-2.38,5.026-3.086c1.832-0.723,3.751-1.117,5.653-1.484c3.816-0.689,7.649-1.041,11.364-1.856
      c1.847-0.435,3.659-0.995,5.341-1.82c1.677-0.842,3.101-2.078,4.357-3.457c2.486-2.808,4.3-6.129,6.214-9.335
      c1.926-3.191,3.865-6.463,6.707-8.946c1.426-1.198,3.058-2.24,4.896-2.683c1.819-0.454,3.699-0.527,5.535-0.373
      c3.675,0.355,7.19,1.37,10.677,2.244c3.48,0.881,7.002,1.683,10.522,1.512c1.748-0.134,3.451-0.557,5.047-1.274
      c1.578-0.746,3.099-1.616,4.532-2.619c2.892-1.962,5.581-4.192,8.453-6.148c1.448-0.957,2.911-1.902,4.5-2.607
      c0.771-0.402,1.609-0.648,2.422-0.946c0.417-0.118,0.846-0.196,1.268-0.296c0.212-0.045,0.423-0.1,0.636-0.136l0.65-0.051
      c1.732-0.227,3.476,0.106,5.106,0.599l1.21,0.429c0.395,0.161,0.774,0.356,1.162,0.532c0.785,0.335,1.496,0.8,2.239,1.202
      c2.888,1.757,5.493,3.852,8.146,5.807c2.646,1.947,5.371,3.815,8.407,4.87c3.062,0.974,6.342,0.777,9.445,0.173
      c3.123-0.584,6.163-1.467,9.216-2.199c3.047-0.718,6.159-1.31,9.277-1.076c3.137,0.203,6.032,1.814,8.137,3.994
      c2.116,2.2,3.725,4.757,5.249,7.285c1.521,2.535,2.943,5.089,4.585,7.453c1.641,2.348,3.575,4.517,6.031,5.861
      c2.448,1.359,5.199,2.004,7.898,2.491c2.711,0.484,5.424,0.796,8.088,1.237c2.658,0.445,5.301,0.998,7.731,2.079
      c2.432,1.05,4.556,2.818,5.847,5.074c1.321,2.237,1.996,4.729,2.494,7.165c0.976,4.894,1.253,9.754,2.521,14.255
      c0.646,2.234,1.594,4.367,3.057,6.079c1.451,1.721,3.232,3.085,5.034,4.314c3.626,2.449,7.441,4.343,10.778,6.719
      c1.657,1.194,3.231,2.472,4.491,4.003c1.248,1.535,2.131,3.316,2.527,5.174c0.432,1.86,0.315,3.739,0.148,5.527
      c-0.182,1.799-0.585,3.517-0.959,5.191c-0.805,3.337-1.719,6.47-2.276,9.503c-0.58,3.017-0.723,5.99-0.008,8.625
      c0.717,2.633,2.077,4.848,3.389,6.814c2.693,3.911,5.288,7.01,6.995,9.79c1.759,2.743,2.663,5.107,2.96,6.749
      c0.221,0.81,0.193,1.45,0.238,1.869C412.897,229.324,412.904,229.537,412.904,229.537z"/>
      </g>
      </g>
      <g>
      <path style={{fill:'none',stroke:'#000000',strokeWidth:0.05,strokeMiterlimit:10}} d="M311.561,209.528"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M237.042,366.958c0.139,0.035-3.552,15.001-8.244,33.422
      c-4.693,18.427-8.61,33.332-8.749,33.296c-0.139-0.036,3.551-14.998,8.244-33.425
      C232.985,381.831,236.903,366.923,237.042,366.958z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <circle style={{fill:'#FFFFFF'}} cx="264.817" cy="229.537" r="90.776"/>
      </g>
      <g>
      <path style={{fill:'#455A64'}} d="M208.922,198.083c-1.252,1.252-8.136,13.768-8.762,24.094
      c-0.626,10.326,0.858,68.318,0.858,68.318l0.268,3.843c16.343,19.111,75.739,47.549,129.242-0.096l0,0l2.998-35.668
      l-8.828-75.51l-30.665-7.51l-7.191-11.655l-47.925,11.22l-4.274,10.105L208.922,198.083z"/>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M283.518,133.536c2.11-7.845,4.186-15.746,4.916-23.837
      c0.729-8.091,0.054-16.451-3.164-23.91c-0.488-1.131-4.539-8.676-5.757-8.493c-0.871,0.132,1.751,7.23,1.348,8.013
      c-3.848,7.467-4.546,16.245-3.441,24.571C278.525,118.208,280.652,125.641,283.518,133.536"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFBF9D'}} d="M282.723,102.542c-0.387-20.37-12.564-37.123-32.915-36.172l-1.876,0.197
      c-18.875,1.99-33.065,18.135-32.599,37.11c0.433,17.653,1.139,37.153,2.245,46.532
      c2.289,19.415,22.053,20.389,22.053,20.389c0.044-0.002,0.906,12.769,0.906,12.769l8.352,9.073l38.711-23.977
      L282.723,102.542z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M239.631,170.748c0,0,12.33-0.573,24.524-10.152c0,0-4.084,17.61-23.668,17.904
      L239.631,170.748z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M226.516,119.513c0.102,1.471,1.434,2.598,2.972,2.519
      c1.536-0.079,2.701-1.333,2.598-2.805c-0.103-1.471-1.433-2.6-2.969-2.521
      C227.578,116.785,226.413,118.042,226.516,119.513z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M220.339,111.308c0.382,0.326,2.321-1.453,5.31-1.76
      c2.98-0.35,5.335,0.956,5.621,0.556c0.142-0.178-0.278-0.795-1.305-1.39
      c-1.013-0.593-2.687-1.097-4.547-0.893c-1.859,0.207-3.353,1.056-4.179,1.853
      C220.394,110.476,220.152,111.164,220.339,111.308z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M252.909,117.271c0.103,1.471,1.434,2.598,2.973,2.519
      c1.536-0.079,2.701-1.333,2.598-2.805c-0.103-1.471-1.434-2.599-2.97-2.521
      C253.971,114.544,252.807,115.8,252.909,117.271z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M249.73,109.432c0.383,0.325,2.325-1.453,5.312-1.76
      c2.98-0.349,5.335,0.956,5.621,0.556c0.141-0.179-0.274-0.795-1.306-1.389
      c-1.012-0.594-2.687-1.098-4.547-0.894c-1.86,0.207-3.353,1.057-4.179,1.853
      C249.789,108.6,249.545,109.288,249.73,109.432z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M241.433,133.519c-0.004-0.171-1.895-0.297-4.945-0.364
      c-0.771,0.002-1.509-0.074-1.693-0.581c-0.238-0.532-0.005-1.388,0.271-2.315
      c0.523-1.921,1.07-3.936,1.643-6.047c2.262-8.601,3.813-15.641,3.466-15.729
      c-0.348-0.088-2.462,6.812-4.723,15.413c-0.539,2.119-1.055,4.139-1.545,6.072
      c-0.197,0.897-0.59,1.933-0.08,3.036c0.265,0.549,0.869,0.903,1.372,0.984
      c0.505,0.097,0.935,0.061,1.315,0.043C239.56,133.874,241.44,133.69,241.433,133.519z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M250.444,134.658c-0.489,0.023-0.154,3.267-2.687,5.861
      c-2.527,2.601-6.022,2.601-6,3.057c-0.023,0.208,0.836,0.549,2.3,0.435c1.436-0.102,3.478-0.783,5.028-2.369
      c1.544-1.591,2.074-3.55,2.044-4.892C251.11,135.381,250.663,134.616,250.444,134.658z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M248.617,97.291c0.38,0.782,3.323,0.082,6.872,0.135
      c3.551-0.018,6.486,0.71,6.874-0.067c0.17-0.37-0.403-1.083-1.629-1.749c-1.215-0.666-3.101-1.222-5.231-1.234
      c-2.128-0.008-4.02,0.529-5.242,1.183C249.03,96.212,248.45,96.919,248.617,97.291z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M220.526,101.247c0.597,0.623,2.581-0.298,5.052-0.592
      c2.46-0.38,4.618-0.026,5.026-0.787c0.175-0.366-0.227-1.011-1.21-1.557c-0.97-0.545-2.535-0.909-4.23-0.678
      c-1.695,0.233-3.103,1.003-3.891,1.79C220.473,100.211,220.26,100.94,220.526,101.247z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFBF9D'}} d="M282.653,117.105c0.316-0.178,12.942-5.359,13.918,7.977
      c0.976,13.336-12.614,11.565-12.665,11.185C283.855,135.886,282.653,117.105,282.653,117.105z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M287.61,130.917c0.055-0.047,0.245,0.145,0.65,0.296
      c0.396,0.148,1.063,0.22,1.739-0.091c1.371-0.62,2.394-2.943,2.282-5.352c-0.055-1.216-0.394-2.353-0.894-3.278
      c-0.479-0.946-1.16-1.581-1.878-1.656c-0.718-0.111-1.189,0.35-1.324,0.737c-0.151,0.386-0.047,0.646-0.117,0.674
      c-0.035,0.036-0.302-0.202-0.236-0.761c0.036-0.269,0.169-0.595,0.464-0.874c0.301-0.283,0.762-0.451,1.26-0.433
      c1.034-0.004,2.029,0.874,2.578,1.89c0.607,1.017,1.016,2.292,1.078,3.659c0.111,2.693-1.081,5.309-2.968,6.007
      c-0.92,0.314-1.723,0.088-2.15-0.197C287.657,131.237,287.565,130.944,287.61,130.917z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M222.543,81.015c-2.236-1.052,3.795-8.463,8.947-11.213
      c7.787-4.156,19.891-7.185,27.422-5.563c9.066,1.951,16.749,5.906,21.741,13.722
      c4.992,7.816,7.28,17.151,7.616,26.419c0.102,2.828-0.001,5.79-1.355,8.274c-1.674,3.07-5.131,5.159-4.712,9.218
      c0.153,1.484-0.175,3.012-1.124,4.163c-2.274,2.755-5.418,0.506-6.949-1.852c-3.27-5.038-3.998-11.257-4.641-17.228
      c-0.458-4.244-0.91-8.58,0.027-12.745c0.509-2.266,1.423-4.435,1.806-6.726c0.383-2.291,0.15-4.848-1.374-6.601
      c-0.248-0.285-0.65-0.639-1.155-1.027c-3.992-3.069-9.346-3.72-13.975-1.739c-7.343,3.142-19.92,7.939-23.436,5.604
      c-5.058-3.358-6.365-5.676-6.365-5.676L222.543,81.015z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <path style={{fill:'#455A64'}} d="M208.922,198.083c-0.773,1.07-11.704,4.698-15.85,11.606c-4.146,6.908-8.249,37.929-8.249,37.929
      l-2.639,20.421c5.2,8.226,12.174,19.116,19.575,26.797L208.922,198.083z"/>
      </g>
      <g style={{opacity:0.3}}>
      <g>
      <path d="M291.047,228.033c0.46,4.162,0.93,8.37,2.362,12.305c1.432,3.935,3.942,7.631,7.606,9.657
      c1.535,0.849,3.266,1.409,4.55,2.603c1.38,1.283,2.075,3.144,3.324,4.555c1.453,1.641,3.545,2.555,5.611,3.286
      c6.114,2.165,12.137,3.199,18.62,2.966c0.196-2.14,0.21-10.002,0.406-12.142L291.047,228.033z"/>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M324.699,183.063c0,0,13.783,4.076,17.819,9.947c4.037,5.872,11.193,28.626,12.661,32.846
      c1.468,4.22,0.184,14.006-2.569,21.041c-2.752,7.034-7.529,13.09-15.783,14.007c-8.255,0.917-21.292-3.854-24.224-6.973
      c-2.931-3.12-4.159-10.121-4.159-10.121c3.665-0.644,7.284-1.552,10.819-2.714"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M319.264,241.096c0.002,0.006-0.056,0.031-0.172,0.075c-0.137,0.049-0.306,0.11-0.52,0.187
      c-0.462,0.158-1.151,0.387-2.06,0.661c-1.818,0.545-4.519,1.283-8.043,1.925l0.111-0.158c0.309,1.559,0.754,3.27,1.407,5.057
      c0.328,0.892,0.706,1.809,1.193,2.704c0.488,0.889,1.058,1.793,1.847,2.514c0.79,0.718,1.766,1.302,2.788,1.838
      c1.017,0.552,2.113,1.027,3.243,1.49c2.268,0.914,4.721,1.701,7.322,2.318c2.601,0.602,5.364,1.048,8.224,1.057
      c0.716,0.032,1.43-0.064,2.153-0.1c0.713-0.118,1.443-0.183,2.149-0.384c1.43-0.324,2.834-0.858,4.141-1.606
      c2.638-1.465,4.873-3.738,6.569-6.413c1.719-2.669,2.976-5.698,3.904-8.863c0.719-2.431,1.248-4.889,1.592-7.333
      c0.349-2.442,0.52-4.882,0.359-7.252c-0.092-1.194-0.313-2.313-0.716-3.423c-0.372-1.12-0.74-2.229-1.105-3.327
      c-0.733-2.192-1.45-4.338-2.15-6.432c-1.419-4.18-2.819-8.135-4.222-11.798c-1.429-3.645-2.762-7.074-4.432-9.936
      c-0.815-1.439-1.931-2.568-3.112-3.482c-1.159-0.94-2.362-1.689-3.505-2.35c-2.299-1.307-4.389-2.234-6.108-2.954
      c-1.723-0.714-3.084-1.212-4.011-1.54c-0.444-0.156-0.793-0.279-1.055-0.371c-0.237-0.084-0.359-0.129-0.356-0.138
      c0.002-0.008,0.129,0.019,0.372,0.087c0.266,0.08,0.621,0.187,1.072,0.322c0.939,0.296,2.314,0.764,4.053,1.452
      c1.736,0.693,3.846,1.596,6.176,2.888c1.159,0.654,2.38,1.398,3.568,2.344c1.201,0.91,2.377,2.083,3.221,3.55
      c1.72,2.902,3.068,6.32,4.52,9.971c1.424,3.665,2.841,7.62,4.273,11.799c0.706,2.093,1.429,4.239,2.168,6.43
      c0.366,1.096,0.736,2.203,1.11,3.321c0.408,1.109,0.658,2.341,0.747,3.546c0.168,2.429-0.004,4.896-0.355,7.365
      c-0.346,2.471-0.878,4.951-1.605,7.409c-0.938,3.2-2.213,6.273-3.969,8.995c-1.732,2.727-4.032,5.066-6.757,6.572
      c-1.35,0.769-2.801,1.317-4.273,1.647c-0.729,0.204-1.478,0.271-2.211,0.389c-0.741,0.036-1.476,0.131-2.205,0.097
      c-2.917-0.018-5.706-0.481-8.329-1.098c-2.623-0.633-5.09-1.438-7.375-2.372c-1.139-0.474-2.243-0.96-3.273-1.526
      c-1.035-0.553-2.022-1.147-2.857-1.914c-0.831-0.772-1.408-1.709-1.901-2.622c-0.491-0.92-0.865-1.852-1.19-2.758
      c-0.645-1.816-1.074-3.537-1.369-5.121l-0.025-0.135l0.136-0.023c3.519-0.601,6.223-1.295,8.05-1.795
      c0.89-0.254,1.581-0.451,2.078-0.592c0.218-0.063,0.391-0.112,0.531-0.152C319.201,241.104,319.262,241.09,319.264,241.096z"
      />
      </g>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M344.548,233.728c-0.102,0.024-0.415-1.697-1.573-4.235c-1.13-2.535-3.162-5.885-6.219-8.829
      c-1.522-1.479-3.214-2.603-4.833-3.441c-0.416-0.186-0.822-0.368-1.218-0.545c-0.394-0.182-0.81-0.258-1.195-0.388
      c-0.768-0.281-1.524-0.348-2.207-0.456c-0.17-0.026-0.337-0.052-0.501-0.077c-0.163,0.001-0.323,0.001-0.48,0.001
      c-0.307,0-0.601,0-0.881,0.001c-0.555-0.034-1.021,0.067-1.406,0.122c-0.77,0.112-1.205,0.159-1.214,0.124
      c-0.009-0.035,0.408-0.148,1.18-0.324c0.386-0.087,0.858-0.219,1.424-0.213c0.287-0.013,0.588-0.027,0.903-0.041
      c0.161-0.006,0.326-0.013,0.494-0.02c0.169,0.02,0.341,0.039,0.517,0.06c0.706,0.086,1.49,0.137,2.288,0.407
      c0.399,0.124,0.833,0.197,1.241,0.376c0.411,0.176,0.833,0.356,1.265,0.541c1.68,0.837,3.428,1.984,4.982,3.495
      c3.124,3.009,5.138,6.451,6.198,9.059c0.272,0.652,0.507,1.246,0.657,1.788c0.164,0.537,0.321,0.993,0.384,1.385
      C344.513,233.293,344.583,233.721,344.548,233.728z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M352.068,221.363c-0.113,0.044-0.488-1.771-2.078-4.197c-1.557-2.385-4.535-5.379-8.783-6.648
      c-4.231-1.288-8.338-0.89-11.206-0.615c-1.365,0.163-2.513,0.301-3.427,0.41c-0.81,0.099-1.261,0.14-1.267,0.104
      c-0.006-0.035,0.434-0.143,1.235-0.304c0.801-0.159,1.965-0.373,3.414-0.575c2.87-0.367,7.036-0.842,11.405,0.483
      c2.166,0.659,4.069,1.768,5.557,3.036c1.506,1.256,2.602,2.663,3.382,3.912c0.778,1.256,1.223,2.373,1.47,3.156
      C352.022,220.906,352.094,221.357,352.068,221.363z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M341.676,206.568c-0.036,0.17-3.629-0.987-8.192-0.784c-4.567,0.155-8.056,1.591-8.106,1.424
      c-0.023-0.056,0.812-0.486,2.257-0.946c1.441-0.462,3.509-0.904,5.828-0.999c2.319-0.088,4.414,0.191,5.888,0.538
      C340.828,206.147,341.695,206.511,341.676,206.568z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M319.377,186.503c0.082,0.013-0.117,1.406-0.278,3.664c-0.161,2.257-0.236,5.39,0.074,8.826
      c0.283,3.305,0.881,6.267,1.409,8.422l-0.361,0.116c-0.409-1.156-0.731-2.094-0.942-2.747
      c-0.211-0.653-0.311-1.021-0.276-1.034c0.035-0.013,0.202,0.329,0.473,0.96s0.647,1.549,1.1,2.688l-0.361,0.116
      c-0.618-2.148-1.276-5.134-1.561-8.474c-0.313-3.473-0.178-6.64,0.078-8.905c0.126-1.133,0.274-2.045,0.397-2.67
      C319.255,186.839,319.342,186.497,319.377,186.503z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#FFBF9D'}} d="M299.892,160.846c0,0-7.154,0-8.051,8.597c-0.689,6.599,0,13.006,0,15.896
      s-1.273,13.167-1.273,13.167s-4.881,4.33-4.798,9.531c0.083,5.226,3.8,6.824,3.8,6.824s-2.271,4.955,2.064,9.496
      c0,0-2.079,4.794-0.37,8.022c1.323,2.499,4.292,3.333,4.292,3.333s-2.115,4.724,2.961,6.71c3,1.174,7.435,0.913,7.435,0.913
      l5.338-0.103l4.705,2.596c0,0,8.051-3.51,8.258-8.258c0.206-4.748,0.206-4.748,0.206-4.748s-2.271-7.845-2.477-10.322
      c-0.206-2.477,1.858-8.464,0.619-11.148c-1.239-2.684-5.574-4.261-5.161-8.014c0.413-3.753-0.413-5.818-2.271-7.882
      c-1.858-2.064-10.07-6.193-10.07-6.193s-2.111-5.987-2.111-10.116C302.989,175.017,307.118,162.424,299.892,160.846z"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M304.712,190.827c0.036,0.062-0.724,0.549-1.98,1.285c-1.257,0.736-3.011,1.721-4.964,2.782
      c-1.954,1.061-3.737,1.996-5.039,2.649s-2.126,1.026-2.16,0.963c-0.034-0.063,0.726-0.55,1.983-1.286
      c1.257-0.736,3.01-1.721,4.963-2.781c1.952-1.06,3.733-1.994,5.036-2.648C303.852,191.138,304.676,190.764,304.712,190.827z"
      />
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M309.408,206.991c0.021,0.029-0.202,0.226-0.624,0.559c-0.42,0.336-1.055,0.786-1.862,1.309
      c-1.612,1.048-3.958,2.329-6.704,3.384c-2.749,1.049-5.352,1.662-7.251,1.959c-0.95,0.15-1.723,0.239-2.26,0.269
      c-0.536,0.035-0.834,0.037-0.838,0.001c-0.009-0.083,1.165-0.243,3.031-0.632c1.866-0.386,4.418-1.047,7.132-2.083
      c2.711-1.041,5.052-2.253,6.699-3.212C308.378,207.588,309.359,206.924,309.408,206.991z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M315.549,201.85c0.13,0.045-0.138,0.963-0.986,1.729c-0.842,0.773-1.781,0.954-1.814,0.821
      c-0.05-0.142,0.715-0.514,1.463-1.206C314.97,202.515,315.412,201.787,315.549,201.85z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M312.604,221.151c0.021,0.061-1.109,0.52-2.993,1.095c-1.883,0.576-4.529,1.239-7.499,1.719
      c-2.972,0.476-5.693,0.676-7.661,0.72c-1.969,0.045-3.186-0.036-3.186-0.101c0-0.079,1.214-0.128,3.168-0.267
      c1.954-0.137,4.647-0.394,7.596-0.866c2.947-0.476,5.585-1.077,7.483-1.56C311.411,221.41,312.579,221.076,312.604,221.151z"
      />
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M319.137,219.961c-0.071-0.043,0.243-0.589,0.549-1.531c0.309-0.936,0.568-2.302,0.391-3.79
      c-0.178-1.489-0.752-2.756-1.273-3.592c-0.52-0.844-0.953-1.3-0.894-1.359c0.04-0.046,0.574,0.322,1.199,1.152
      c0.624,0.819,1.295,2.146,1.485,3.738c0.19,1.591-0.15,3.039-0.563,3.981C319.619,219.513,319.187,219.996,319.137,219.961z"
      />
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M312.974,235.626c0.013,0.064-0.949,0.335-2.542,0.6c-1.591,0.266-3.819,0.496-6.286,0.491
      c-2.468-0.009-4.694-0.251-6.284-0.526c-1.591-0.274-2.552-0.551-2.539-0.614c0.015-0.077,0.999,0.071,2.59,0.249
      c1.591,0.18,3.794,0.361,6.234,0.37c2.439,0.005,4.643-0.164,6.235-0.335C311.974,235.691,312.959,235.548,312.974,235.626z"
      />
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M300.278,179.264c0.029,0.055-0.324,0.324-0.992,0.563c-0.664,0.24-1.657,0.408-2.74,0.281
      c-1.083-0.131-2.009-0.527-2.598-0.916c-0.594-0.389-0.874-0.733-0.832-0.78c0.09-0.13,1.451,0.957,3.492,1.179
      C298.642,179.855,300.221,179.117,300.278,179.264z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#FF9A6C'}} d="M320.623,220.566c0.031-0.003,0.09,0.269,0.115,0.772c0.015,0.503-0.05,1.234-0.248,2.109
      c-0.193,0.875-0.523,1.885-0.838,3.001c-0.3,1.111-0.592,2.369-0.374,3.641c0.172,1.265,1.119,2.292,1.268,3.577
      c0.169,1.264-0.33,2.334-0.786,3.093c-0.476,0.771-0.954,1.317-1.291,1.683c-0.341,0.364-0.549,0.545-0.575,0.523
      c-0.08-0.061,0.687-0.877,1.546-2.388c0.401-0.753,0.807-1.752,0.631-2.842c-0.121-1.086-1.079-2.11-1.307-3.556
      c-0.24-1.417,0.098-2.741,0.426-3.865c0.347-1.129,0.709-2.114,0.942-2.958C320.632,221.671,320.534,220.572,320.623,220.566
      z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M323.312,226.848c0.034-0.008,0.156,0.325,0.335,0.939c0.179,0.615,0.419,1.51,0.645,2.636
      c0.219,1.126,0.441,2.483,0.478,4.014c0.031,1.519-0.119,3.263-0.904,4.899c-0.805,1.612-1.964,2.875-3.074,3.915
      c-1.125,1.033-2.229,1.846-3.176,2.493c-0.949,0.644-1.743,1.123-2.302,1.436c-0.558,0.314-0.879,0.466-0.897,0.436
      c-0.044-0.071,1.155-0.824,2.986-2.172c0.911-0.679,1.978-1.513,3.061-2.543c1.069-1.035,2.176-2.274,2.934-3.793
      c0.733-1.525,0.898-3.185,0.893-4.666c-0.009-1.49-0.189-2.833-0.36-3.955C323.562,228.245,323.229,226.868,323.312,226.848z"
      />
      </g>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M247.856,193.563c-0.023,0.027-0.434-0.282-1.158-0.866c-0.791-0.655-1.809-1.497-3.03-2.508
      c-0.642-0.542-1.336-1.127-2.076-1.751c-0.371-0.314-0.748-0.635-1.179-0.847c-0.427-0.212-0.91-0.267-1.339-0.046
      c-0.863,0.451-1.439,1.559-2.176,2.478c-0.712,0.96-1.449,1.952-2.203,2.969c-1.209,1.618-2.372,3.172-3.444,4.606
      l-0.647,0.865l0.205-1.058c0.703-3.618,1.682-6.637,2.489-8.724c0.403-1.045,0.758-1.861,1.016-2.413
      c0.26-0.551,0.417-0.842,0.443-0.831c0.027,0.012-0.08,0.324-0.291,0.894c-0.21,0.57-0.518,1.4-0.879,2.454
      c-0.721,2.105-1.63,5.119-2.292,8.711l-0.441-0.193c1.065-1.439,2.221-2.998,3.423-4.622c0.757-1.014,1.496-2.005,2.212-2.963
      c0.359-0.478,0.712-0.948,1.058-1.408c0.357-0.459,0.757-0.927,1.322-1.198c0.567-0.29,1.26-0.194,1.748,0.069
      c0.502,0.257,0.893,0.61,1.259,0.931c0.725,0.641,1.405,1.242,2.034,1.799c1.177,1.061,2.159,1.945,2.922,2.633
      C247.513,193.174,247.879,193.536,247.856,193.563z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M290.081,185.106c0.01,0.014-0.124,0.134-0.391,0.349c-0.316,0.246-0.706,0.55-1.181,0.921
      c-1.099,0.827-2.589,1.948-4.406,3.315c-3.809,2.804-8.977,6.607-14.713,10.829l-0.223,0.164l-0.15-0.235
      c-0.115-0.181-0.24-0.376-0.364-0.571c-2.71-4.278-5.242-8.274-7.384-11.656l0.27,0.087c-3.531,1.404-6.306,2.943-8.196,4.087
      c-0.946,0.573-1.674,1.047-2.172,1.368c-0.496,0.324-0.765,0.491-0.779,0.472c-0.013-0.018,0.229-0.223,0.701-0.583
      c0.475-0.358,1.182-0.867,2.113-1.476c1.859-1.214,4.622-2.822,8.167-4.281l0.17-0.07l0.1,0.157
      c2.161,3.369,4.715,7.352,7.449,11.614c0.125,0.196,0.249,0.391,0.364,0.572l-0.373-0.07
      c5.772-4.173,10.972-7.932,14.805-10.703c1.856-1.312,3.379-2.389,4.502-3.183c0.498-0.339,0.906-0.618,1.237-0.843
      C289.916,185.184,290.071,185.092,290.081,185.106z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M200.334,224.875c0.018,0,0.036,0.243,0.055,0.706c0.014,0.515,0.032,1.187,0.055,2.024
      c0.034,1.818,0.081,4.345,0.139,7.443c0.119,6.284,0.37,14.962,0.696,24.548c0.325,9.589,0.519,18.277,0.544,24.565
      c0.01,3.144,0.004,5.688-0.026,7.446c-0.018,0.837-0.032,1.509-0.043,2.024c-0.016,0.463-0.032,0.706-0.05,0.706
      c-0.018,0-0.036-0.243-0.055-0.706c-0.014-0.515-0.032-1.187-0.055-2.024c-0.034-1.818-0.081-4.345-0.139-7.442
      c-0.119-6.284-0.37-14.963-0.696-24.552c-0.325-9.586-0.518-18.272-0.543-24.561c-0.01-3.144-0.004-5.689,0.026-7.447
      c0.018-0.837,0.032-1.51,0.043-2.025C200.3,225.118,200.316,224.875,200.334,224.875z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M248.185,315.204c-0.009,0.001-0.026-0.097-0.051-0.288c-0.025-0.22-0.057-0.5-0.096-0.849
      c-0.077-0.785-0.184-1.879-0.32-3.27c-0.278-2.84-0.598-6.958-0.916-12.051c-0.328-5.093-0.594-11.164-0.767-17.91
      c-0.146-6.746-0.183-14.166-0.024-21.952c0.179-7.788,0.539-15.201,0.978-21.936c0.466-6.733,0.995-12.788,1.544-17.861
      c0.54-5.074,1.038-9.174,1.439-11.999c0.197-1.384,0.351-2.472,0.462-3.253c0.055-0.347,0.099-0.625,0.133-0.844
      c0.033-0.19,0.054-0.286,0.063-0.285c0.009,0.001,0.005,0.1-0.01,0.292c-0.021,0.221-0.048,0.501-0.082,0.851
      c-0.088,0.783-0.21,1.876-0.365,3.264c-0.339,2.832-0.781,6.936-1.274,12.011c-0.501,5.075-0.992,11.127-1.432,17.855
      c-0.412,6.73-0.757,14.136-0.936,21.916c-0.159,7.778-0.136,15.19-0.017,21.93c0.147,6.74,0.374,12.808,0.654,17.899
      c0.271,5.092,0.535,9.211,0.751,12.056c0.095,1.394,0.169,2.491,0.223,3.278c0.018,0.351,0.033,0.632,0.045,0.854
      C248.195,315.104,248.194,315.203,248.185,315.204z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M218.389,151.516c0,0-0.353,0.247-1.056,0.708c-0.712,0.463-1.758,1.143-3.126,2.032
      c-1.373,0.894-2.961,2.16-4.9,3.565c-0.955,0.722-1.914,1.588-2.978,2.465c-0.528,0.444-1.072,0.902-1.634,1.374
      c-0.553,0.482-1.084,1.022-1.651,1.554c-4.545,4.231-9.708,9.911-14.464,17.363c-0.583,0.938-1.176,1.891-1.779,2.861
      c-0.627,0.956-1.113,2.015-1.69,3.043c-0.545,1.045-1.152,2.08-1.671,3.179c-0.505,1.106-1.017,2.229-1.537,3.367
      c-0.253,0.572-0.537,1.136-0.77,1.726c-0.226,0.593-0.453,1.191-0.682,1.792c-0.451,1.205-0.935,2.417-1.377,3.662
      c-0.74,2.537-1.674,5.092-2.232,7.817c-1.386,5.383-2.15,11.184-2.459,17.234c-0.349,6.055,0.012,12.392,1.052,18.837
      c2.105,12.868,7.182,26.268,15.812,38.179c8.518,11.947,20.557,22.339,35.015,29.169c14.426,6.872,31.308,9.842,48.167,8.095
      c16.884-1.749,32.699-8.395,45.367-18.11c3.194-2.403,6.126-5.075,8.944-7.801c0.678-0.708,1.354-1.414,2.029-2.119
      c0.683-0.697,1.342-1.413,1.966-2.157c0.636-0.733,1.27-1.465,1.903-2.195c0.598-0.759,1.194-1.516,1.788-2.27
      c4.754-6.046,8.504-12.64,11.467-19.35c2.958-6.724,4.916-13.666,6.122-20.512c0.62-3.424,0.961-6.84,1.188-10.207
      c0.107-1.684,0.105-3.36,0.162-5.019c-0.032-1.659-0.122-3.305-0.179-4.937c-0.032-0.816-0.064-1.628-0.096-2.437
      c-0.049-0.807-0.181-1.605-0.266-2.402c-0.193-1.593-0.383-3.172-0.572-4.736c-0.248-1.555-0.607-3.077-0.9-4.595
      c-0.312-1.514-0.573-3.026-1.043-4.47c-0.419-1.457-0.834-2.899-1.245-4.327c-0.184-0.721-0.437-1.414-0.706-2.099
      c-0.258-0.688-0.515-1.372-0.771-2.053c-0.533-1.352-0.968-2.728-1.565-4.018c-0.602-1.288-1.198-2.561-1.786-3.819
      c-4.828-10.021-11.028-18.12-17.253-24.371c-6.247-6.265-12.532-10.658-17.827-13.892c-1.348-0.774-2.621-1.506-3.817-2.193
      c-0.588-0.362-1.195-0.641-1.777-0.915c-0.582-0.277-1.143-0.544-1.684-0.802c-1.081-0.515-2.078-0.991-2.991-1.426
      c-0.903-0.458-1.787-0.718-2.542-1.034c-1.521-0.59-2.684-1.04-3.476-1.347c-0.782-0.306-1.179-0.476-1.179-0.476
      s0.408,0.136,1.197,0.426c0.797,0.296,1.967,0.731,3.497,1.3c0.759,0.308,1.648,0.561,2.555,1.012
      c0.918,0.429,1.92,0.898,3.006,1.406c0.543,0.254,1.107,0.519,1.692,0.792c0.586,0.271,1.195,0.547,1.787,0.906
      c1.202,0.682,2.482,1.408,3.837,2.177c5.323,3.215,11.642,7.595,17.929,13.861c6.265,6.251,12.509,14.363,17.378,24.415
      c0.594,1.262,1.194,2.539,1.801,3.831c0.601,1.295,1.042,2.675,1.579,4.032c0.258,0.683,0.517,1.37,0.778,2.06
      c0.271,0.687,0.526,1.384,0.713,2.107c0.415,1.433,0.835,2.881,1.259,4.344c0.474,1.45,0.739,2.968,1.055,4.489
      c0.297,1.524,0.66,3.053,0.911,4.614c0.192,1.571,0.387,3.157,0.583,4.757c0.087,0.801,0.221,1.602,0.271,2.413
      c0.033,0.813,0.067,1.629,0.101,2.448c0.06,1.639,0.152,3.293,0.186,4.96c-0.055,1.667-0.051,3.351-0.156,5.043
      c-0.223,3.383-0.562,6.817-1.181,10.259c-1.204,6.883-3.166,13.863-6.133,20.626c-2.973,6.749-6.739,13.381-11.514,19.464
      c-0.597,0.759-1.196,1.521-1.797,2.284c-0.636,0.734-1.273,1.47-1.912,2.208c-0.627,0.749-1.289,1.469-1.976,2.17
      c-0.678,0.709-1.358,1.42-2.039,2.132c-2.831,2.743-5.779,5.431-8.99,7.849c-12.734,9.775-28.641,16.464-45.622,18.223
      c-16.954,1.758-33.934-1.234-48.439-8.151c-14.537-6.875-26.635-17.333-35.187-29.351
      c-8.665-11.982-13.752-25.457-15.849-38.39c-1.036-6.478-1.389-12.844-1.028-18.925c0.32-6.076,1.1-11.9,2.502-17.302
      c0.567-2.735,1.51-5.299,2.259-7.843c0.446-1.249,0.935-2.465,1.391-3.673c0.231-0.603,0.461-1.202,0.689-1.797
      c0.235-0.592,0.522-1.157,0.777-1.731c0.525-1.141,1.042-2.267,1.551-3.375c0.523-1.102,1.135-2.139,1.685-3.186
      c0.582-1.03,1.073-2.091,1.704-3.048c0.608-0.971,1.206-1.926,1.794-2.865c4.795-7.46,9.995-13.135,14.571-17.353
      c0.571-0.53,1.106-1.068,1.662-1.548c0.565-0.47,1.114-0.926,1.645-1.368c1.071-0.872,2.037-1.734,2.998-2.451
      c1.952-1.395,3.552-2.649,4.936-3.53c1.382-0.871,2.437-1.537,3.156-1.991C218.017,151.733,218.389,151.516,218.389,151.516z"
      />
      </g>
      </g>
      </g>
      </g>
      </g>
      </svg>
      
    );
  }
}
