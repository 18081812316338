import React, { Component } from "react";

export default class AddressImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        id="aaafdec0-95c8-49a3-a8f8-64216c6da511"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="1013.44925"
        height="610.28138"
        viewBox="0 0 500 500"
      >
<g id="Background_Complete">
	<g>
		<g>
			<g>
				<path style={{fill:theme.dark}} d="M439.313,257.228c-12.306-18.727-1.07-38.863-2.943-59.695
					c-1.873-20.832-21.67-25.38-17.924-43.037c4.343-20.473-7.223-40.129-20.332-40.129c-13.109,0-24.675,19.656-20.332,40.129
					c3.745,17.657-16.052,22.205-17.925,43.037c-1.873,20.832,9.364,40.967-2.943,59.695c-12.306,18.727-9.077,43.533-2.14,55.843
					c1.911,3.391,4.16,6.394,6.613,9.05c19.681,21.314,53.774,21.314,73.455,0c2.452-2.656,4.702-5.658,6.613-9.05
					C448.39,300.761,451.62,275.955,439.313,257.228z"/>
				<path style={{fill:theme.text}} d="M404.333,382.398h-12.44V277.664c0-3.421,2.799-6.22,6.22-6.22h0c3.421,0,6.22,2.799,6.22,6.22
					V382.398z"/>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M319.909,382.398h-16.936V277.672c0-4.658,3.811-8.468,8.468-8.468l0,0
					c4.658,0,8.468,3.811,8.468,8.468V382.398z"/>
				<circle style={{fill:theme.text}} cx="311.441" cy="301.979" r="52.168"/>
				
					<ellipse transform="matrix(0.2411 -0.9705 0.9705 0.2411 -5.3395 555.9239)" style={{fill:theme.text}} cx="352.79" cy="281.376" rx="20.558" ry="20.558"/>
				
					<ellipse transform="matrix(0.1608 -0.987 0.987 0.1608 -39.7411 491.6678)" style={{fill:theme.text}} cx="269.253" cy="269.204" rx="12.172" ry="12.173"/>
				<circle style={{fill:theme.text}} cx="282.484" cy="262.901" r="8.543"/>
			</g>
			<path style={{fill:theme.dark}} d="M203.97,319.892c-8.686,1.291-16.215,4.439-22.716,8.668c-0.062-7.915-1.511-15.433-5.231-21.504
				c-5.663,5.519-10.25,12.656-13.955,20.333c-1.388-14.433-5.177-28.416-13.568-37.254c-12.572,7.908-19.052,21.089-22.134,35.048
				c-5.009-8.945-11.92-17.201-21.463-23.204c-7.692,8.102-10.148,19.304-9.944,30.777c-8.738-10.173-18.443-18.116-27.902-19.049
				c-2.329,16.115,2.631,31.308,8.934,43.288c8.268,15.713,24.716,25.404,42.472,25.404h41.085c17.511,0,33.928-9.343,42.144-24.806
				C206.895,347.799,209.37,335.186,203.97,319.892z"/>
			<path style={{fill:theme.text}} d="M192.256,112.139c5.077-14.871,1.047-25.458-11.364-25.458c-7.783,0-17.434,4.167-26.832,10.952
				c-1.062-3.868-4.052-6.186-8.863-6.186c-8.466,0-20.136,7.162-29.291,17.267c-2.215-2.253-5.421-3.633-9.54-3.855
				c-8.102-0.456-17.916,3.659-26.448,10.353c-0.739-1.12-2.006-1.823-3.755-1.927c-4.669-0.26-10.989,3.894-14.129,9.272
				c-0.196,0.339-0.369,0.664-0.543,0.99h9.698h7.716h26.803h13.19h10.256h15.797h2.867h39.186h7.716
				C195.119,118.858,194.29,114.938,192.256,112.139z"/>
			<path style={{fill:theme.text}} d="M192.668,202.432c2.48-7.264,0.511-12.436-5.551-12.436c-3.802,0-8.516,2.036-13.106,5.35
				c-0.518-1.889-1.979-3.021-4.329-3.021c-4.136,0-9.836,3.498-14.308,8.435c-1.082-1.1-2.648-1.775-4.66-1.883
				c-3.958-0.223-8.751,1.788-12.919,5.057c-0.361-0.547-0.98-0.891-1.834-0.941c-2.281-0.127-5.368,1.902-6.902,4.529
				c-0.096,0.165-0.18,0.324-0.265,0.484h4.737h3.769h13.093h6.443h5.01h7.717h1.4h19.141h3.769
				C194.066,205.714,193.661,203.799,192.668,202.432z"/>
			<path style={{fill:theme.text}} d="M342.767,147.493c-0.507-0.074-1.022-0.112-1.544-0.112c0.479-7.256-2.295-11.889-8.22-11.889
				c-4.275,0-9.52,2.419-14.867,6.512c1.53-14.084-3.369-23.238-14.462-23.238c-8.949,0-20.277,5.954-31.467,15.666
				c-0.98-5.526-4.277-8.838-9.817-8.838c-9.732,0-23.56,10.233-34.648,24.671c0,0-0.004,0.019-0.014,0
				c-2.395-3.219-5.995-5.21-10.726-5.526c-9.266-0.651-20.772,5.228-30.973,14.81c-0.776-1.619-2.189-2.623-4.21-2.754
				c-5.067-0.354-12.05,4.93-16.106,12.001h11.821h8.014h30.818h14.433h12.852h18.254h34.488h13.443h4.784h19.134h17.168
				C355.271,158.08,351.807,148.703,342.767,147.493z"/>
		</g>
	</g>
</g>
<g id="Shadow">
	<ellipse style={{fill:theme.jacketColor}} cx="250" cy="416.238" rx="193.889" ry="11.323"/>
</g>
<g id="Mail">
	<g>
		<g>
			<g>
				<polygon style={{fill:theme.imageHighlight}} points="48.856,150.562 276.827,183.377 307.422,214.252 				"/>
				<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="48.856,150.562 276.827,183.377 307.422,214.252 				"/>
			</g>
			<g>
				<polygon style={{fill:theme.imageHighlight}} points="48.856,150.562 276.827,183.377 266.248,228.022 				"/>
				<polygon style={{opacity:0.2}} points="48.856,150.562 276.827,183.377 266.248,228.022 				"/>
			</g>
			<g>
				<g>
					<path style={{fill:theme.imageHighlight}} d="M261.566,98.876l13.237,62.027c0.717,3.359-1.445,6.694-4.804,7.411L151.92,193.513
						c-3.359,0.717-6.694-1.445-7.411-4.804l-13.237-62.027c-0.375-1.756,0.038-3.51,0.996-4.885
						c0.878-1.246,2.205-2.184,3.808-2.526l118.079-25.199c1.603-0.342,3.198-0.028,4.508,0.751
						C260.098,95.687,261.192,97.12,261.566,98.876z"/>
					<path style={{opacity:0.4}} d="M261.566,98.876l13.237,62.027c0.717,3.359-1.445,6.694-4.804,7.411L151.92,193.513
						c-3.359,0.717-6.694-1.445-7.411-4.804l-13.237-62.027c-0.375-1.756,0.038-3.51,0.996-4.885
						c0.878-1.246,2.205-2.184,3.808-2.526l118.079-25.199c1.603-0.342,3.198-0.028,4.508,0.751
						C260.098,95.687,261.192,97.12,261.566,98.876z"/>
				</g>
				<path style={{fill:theme.imageHighlight}} d="M258.663,94.823l-39.457,48.432c-7.535,9.25-20.494,12.015-31.149,6.647l-55.79-28.106
					c0.878-1.246,2.205-2.184,3.808-2.526l118.079-25.199C255.759,93.729,257.353,94.044,258.663,94.823z"/>
			</g>
			<g>
				<polygon style={{fill:theme.imageHighlight}} points="48.856,150.562 236.889,195.247 266.248,228.022 				"/>
				<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="48.856,150.562 236.889,195.247 266.248,228.022 				"/>
			</g>
			
				<rect x="127.154" y="188.076" transform="matrix(0.5055 -0.8628 0.8628 0.5055 -109.6119 209.1521)" style={{fill:theme.imageHighlight}} width="1.001" height="24.247"/>
			
				<rect x="150.621" y="209.585" transform="matrix(0.5055 -0.8628 0.8628 0.5055 -109.8692 236.1929)" style={{fill:theme.imageHighlight}} width="1.001" height="8.723"/>
			<polygon style={{fill:theme.imageHighlight}} points="48.856,150.562 236.889,195.247 200.34,239.303 			"/>
		</g>
		<g>
			<g>
				<path style={{fill:theme.imageHighlight}} d="M300.359,255.262c-0.244,0-0.493-0.046-0.735-0.142l-30.634-12.128
					c-1.027-0.406-1.53-1.568-1.123-2.596c0.406-1.026,1.563-1.532,2.596-1.123l30.634,12.128c1.027,0.406,1.53,1.568,1.123,2.596
					C301.909,254.782,301.156,255.262,300.359,255.262z"/>
				<path style={{opacity:0.3}} d="M300.359,255.262c-0.244,0-0.493-0.046-0.735-0.142l-30.634-12.128
					c-1.027-0.406-1.53-1.568-1.123-2.596c0.406-1.026,1.563-1.532,2.596-1.123l30.634,12.128c1.027,0.406,1.53,1.568,1.123,2.596
					C301.909,254.782,301.156,255.262,300.359,255.262z"/>
			</g>
			<g>
				<path style={{fill:theme.imageHighlight}} d="M298.555,237.695c-0.244,0-0.493-0.045-0.735-0.141l-16.452-6.514
					c-1.027-0.406-1.53-1.568-1.123-2.596c0.405-1.026,1.564-1.532,2.596-1.123l16.452,6.514c1.027,0.406,1.53,1.568,1.123,2.596
					C300.104,237.216,299.352,237.695,298.555,237.695z"/>
				<path style={{opacity:0.5,fill:'#FFFFFF'}} d="M298.555,237.695c-0.244,0-0.493-0.045-0.735-0.141l-16.452-6.514
					c-1.027-0.406-1.53-1.568-1.123-2.596c0.405-1.026,1.564-1.532,2.596-1.123l16.452,6.514c1.027,0.406,1.53,1.568,1.123,2.596
					C300.104,237.216,299.352,237.695,298.555,237.695z"/>
			</g>
			<g>
				<path style={{fill:theme.imageHighlight}} d="M264.161,254.959c-0.244,0-0.493-0.046-0.735-0.142l-29.869-11.826
					c-1.027-0.406-1.53-1.569-1.123-2.596c0.406-1.027,1.567-1.53,2.596-1.123l29.869,11.826c1.027,0.406,1.53,1.568,1.123,2.596
					C265.711,254.48,264.958,254.959,264.161,254.959z"/>
				<path style={{opacity:0.3}} d="M264.161,254.959c-0.244,0-0.493-0.046-0.735-0.142l-29.869-11.826
					c-1.027-0.406-1.53-1.569-1.123-2.596c0.406-1.027,1.567-1.53,2.596-1.123l29.869,11.826c1.027,0.406,1.53,1.568,1.123,2.596
					C265.711,254.48,264.958,254.959,264.161,254.959z"/>
			</g>
		</g>
	</g>
</g>
<g id="Character">
	<g>
		<path style={{fill:'#E4897B'}} d="M372.301,164.821c2.648,5.174,5.079,10.349,7.387,15.654c2.291,5.305,4.466,10.665,6.339,16.291
			l0.684,2.133c0.115,0.379,0.293,1.002,0.386,1.445c0.094,0.462,0.194,0.939,0.248,1.362c0.265,1.742,0.311,3.316,0.341,4.864
			c0.049,3.084-0.159,6.025-0.416,8.95c-0.56,5.841-1.471,11.524-2.723,17.223l-5.482-0.608c-0.071-5.633-0.124-11.321-0.319-16.87
			c-0.099-2.769-0.257-5.532-0.538-8.137c-0.142-1.294-0.351-2.563-0.594-3.625c-0.057-0.281-0.132-0.495-0.196-0.727
			c-0.078-0.248-0.113-0.306-0.221-0.603l-0.754-1.891c-2.089-5.06-4.441-10.165-6.839-15.244l-7.259-15.233L372.301,164.821z"/>
		<path style={{fill:'#E4897B'}} d="M379.143,230.42l-2.56,4.788l8.176,2.281c0,0,1.557-3.852-0.297-6.889L379.143,230.42z"/>
		<polygon style={{fill:'#E4897B'}} points="373.448,242.111 380.966,242.705 384.76,237.49 376.583,235.208 		"/>
		<path style={{fill:'#263238'}} d="M337.873,130.264c-0.118,0.581-0.521,0.989-0.901,0.912c-0.38-0.077-0.592-0.61-0.474-1.191
			c0.118-0.581,0.521-0.989,0.901-0.912C337.779,129.151,337.991,129.684,337.873,130.264z"/>
		<path style={{fill:'#DE5753'}} d="M337.405,131.264c0,0-2.001,3.082-3.783,4.402c0.832,1.168,2.668,1.085,2.668,1.085
			L337.405,131.264z"/>
		<path style={{fill:'#263238'}} d="M340.654,127.472c-0.094,0-0.187-0.038-0.256-0.112c-1.041-1.128-2.278-0.944-2.333-0.938
			c-0.184,0.033-0.368-0.097-0.399-0.287c-0.031-0.189,0.096-0.368,0.284-0.4c0.066-0.012,1.652-0.263,2.96,1.151
			c0.13,0.141,0.121,0.361-0.02,0.492C340.823,127.441,340.739,127.472,340.654,127.472z"/>
		<path style={{fill:'#E4897B'}} d="M354.414,138.432c-1.054,5.374-2.105,15.222,1.655,18.808c0,0-1.469,5.452-11.455,5.452
			c-10.981,0-5.248-5.451-5.248-5.451c5.993-1.431,5.838-5.878,4.794-10.053L354.414,138.432z"/>
		<path style={{fill:'#263238'}} d="M357.577,160.634c0.793-2.634,1.666-5.648-0.14-6.592c-1.987-1.039-12.862-2.098-16.612-0.148
			c-3.75,1.95-2.686,7.292-2.686,7.292L357.577,160.634z"/>
		<path style={{fill:theme.imageHighlight}} d="M380.643,410.021c-0.753,0-1.48-0.115-1.764-0.485c-0.119-0.155-0.215-0.425,0.006-0.826
			c0.115-0.21,0.3-0.347,0.546-0.405c1.079-0.248,3.102,1.138,3.188,1.196c0.057,0.039,0.085,0.107,0.072,0.176
			c-0.013,0.067-0.063,0.121-0.13,0.137C382.184,409.903,381.401,410.021,380.643,410.021z M379.725,408.62
			c-0.076,0-0.148,0.007-0.214,0.023c-0.148,0.035-0.254,0.112-0.321,0.234c-0.157,0.286-0.064,0.407-0.034,0.447
			c0.314,0.412,1.816,0.419,2.912,0.233C381.435,409.168,380.393,408.62,379.725,408.62z"/>
		<path style={{fill:theme.imageHighlight}} d="M382.52,409.817c-0.028,0-0.057-0.007-0.083-0.021c-0.76-0.412-2.23-2.021-2.075-2.841
			c0.028-0.148,0.138-0.405,0.554-0.457c0.264-0.03,0.519,0.042,0.741,0.224c0.854,0.697,1.029,2.817,1.037,2.907
			c0.005,0.063-0.025,0.125-0.079,0.159C382.586,409.809,382.553,409.817,382.52,409.817z M381.036,406.84
			c-0.025,0-0.052,0.001-0.078,0.005c-0.227,0.027-0.246,0.132-0.255,0.176c-0.091,0.485,0.845,1.701,1.601,2.273
			c-0.086-0.619-0.32-1.854-0.868-2.302C381.31,406.89,381.18,406.84,381.036,406.84z"/>
		<path style={{fill:theme.imageHighlight}} d="M336.528,410.022c-0.942,0-1.852-0.13-2.157-0.554c-0.107-0.148-0.191-0.401,0.014-0.761
			c0.115-0.202,0.302-0.339,0.553-0.407c1.216-0.325,3.79,1.131,3.898,1.193c0.063,0.035,0.097,0.106,0.085,0.179
			c-0.012,0.071-0.066,0.128-0.138,0.143C338.314,409.908,337.407,410.022,336.528,410.022z M335.364,408.596
			c-0.122,0-0.235,0.013-0.336,0.04c-0.161,0.044-0.272,0.123-0.341,0.243c-0.124,0.217-0.079,0.323-0.034,0.385
			c0.331,0.459,2.186,0.483,3.564,0.293C337.536,409.207,336.223,408.596,335.364,408.596z"/>
		<path style={{fill:theme.imageHighlight}} d="M338.749,409.817c-0.024,0-0.049-0.005-0.071-0.015c-0.886-0.397-2.642-1.985-2.509-2.817
			c0.032-0.195,0.17-0.438,0.648-0.486c0.355-0.034,0.68,0.064,0.965,0.298c0.935,0.768,1.132,2.746,1.14,2.83
			c0.006,0.061-0.021,0.122-0.071,0.158C338.82,409.807,338.785,409.817,338.749,409.817z M336.957,406.84
			c-0.034,0-0.069,0.002-0.105,0.005c-0.313,0.032-0.333,0.155-0.34,0.196c-0.08,0.499,1.113,1.747,2.018,2.29
			c-0.094-0.577-0.356-1.762-0.97-2.266C337.376,406.914,337.178,406.84,336.957,406.84z"/>
		<polygon style={{fill:'#E4897B'}} points="346.827,409.644 339.481,409.644 338.749,392.632 346.095,392.632 		"/>
		<polygon style={{fill:'#E4897B'}} points="390.936,409.644 383.589,409.644 379.897,392.632 387.243,392.632 		"/>
		<path style={{fill:'#263238'}} d="M383.067,408.793h8.253c0.3,0,0.56,0.209,0.626,0.502l1.451,6.53
			c0.151,0.677-0.382,1.318-1.076,1.306c-2.881-0.049-4.281-0.219-7.911-0.219c-2.233,0-6.833,0.232-9.917,0.232
			c-3.016,0-3.486-3.049-2.224-3.325c5.66-1.238,7.793-2.946,9.605-4.575C382.201,408.951,382.628,408.793,383.067,408.793z"/>
		<path style={{fill:'#263238'}} d="M339.529,408.793h8.023c0.3,0,0.56,0.209,0.626,0.502l1.451,6.53
			c0.15,0.677-0.364,1.318-1.057,1.307c-2.894-0.049-7.076-0.219-10.713-0.219c-4.253,0-7.926,0.232-12.914,0.232
			c-3.016,0-3.853-3.049-2.59-3.325c5.747-1.257,10.439-1.392,15.406-4.454C338.299,409.033,338.895,408.793,339.529,408.793z"/>
		<path style={{fill:'#E4897B'}} d="M335.982,170.144c-6.229-0.487-12.217-1.293-18.307-2.434c-3.031-0.582-6.068-1.233-9.103-2.05
			c-3.04-0.815-6.085-1.739-9.163-3.024c-0.769-0.316-1.546-0.671-2.322-1.04c-0.973-0.481-1.782-0.981-2.494-1.455
			c-1.445-0.954-2.681-1.938-3.912-2.919c-2.446-1.959-4.723-3.995-6.969-6.053c-4.472-4.136-8.715-8.417-12.801-12.921l3.679-4.11
			l14.509,10.427c2.395,1.727,4.812,3.413,7.19,5.004c1.185,0.787,2.396,1.534,3.498,2.156c0.559,0.317,1.056,0.554,1.44,0.706
			c0.595,0.233,1.197,0.463,1.829,0.674c5.079,1.694,10.822,2.782,16.526,3.701c5.711,0.904,11.611,1.648,17.31,2.239
			L335.982,170.144z"/>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M369.223,158.901c6.885,1.579,13.847,21.71,13.847,21.71l-14.173,10.166
				c0,0-3.959-9.376-7.854-17.576C356.961,164.609,362.183,157.287,369.223,158.901z"/>
			<path style={{opacity:0.4}} d="M369.223,158.901c6.885,1.579,13.847,21.71,13.847,21.71l-14.173,10.166
				c0,0-3.959-9.376-7.854-17.576C356.961,164.609,362.183,157.287,369.223,158.901z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M365.25,169.9l-2.81,6.29c2.91,6.28,5.57,12.5,6.28,14.16
				C368.62,182.92,367.21,173.7,365.25,169.9z"/>
			<path style={{opacity:0.6}} d="M365.25,169.9l-2.81,6.29c2.91,6.28,5.57,12.5,6.28,14.16C368.62,182.92,367.21,173.7,365.25,169.9
				z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M328.066,159.279c0,0-7.819,8.832-0.435,63.497c11.839,0,33.532,0,39.293,0
				c0.271-6.032-3.519-35.456,2.298-63.876c0,0-6.735-1.352-13.154-1.661c-5.021-0.242-12.289-0.402-16.703,0.001
				C333.547,157.771,328.066,159.279,328.066,159.279z"/>
			<path style={{opacity:0.4}} d="M328.066,159.279c0,0-7.819,8.832-0.435,63.497c11.839,0,33.532,0,39.293,0
				c0.271-6.032-3.519-35.456,2.298-63.876c0,0-6.735-1.352-13.154-1.661c-5.021-0.242-12.289-0.402-16.703,0.001
				C333.547,157.771,328.066,159.279,328.066,159.279z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M325.16,169.9c-0.26,2.07-0.46,4.52-0.59,7.41c3.25-1.01,7.29-2.63,10.94-5.14L325.16,169.9z"/>
			<path style={{opacity:0.6}} d="M325.16,169.9c-0.26,2.07-0.46,4.52-0.59,7.41c3.25-1.01,7.29-2.63,10.94-5.14L325.16,169.9z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M341.968,170.972c-5.257,4.658-25.339,4.192-25.339,4.192l-0.662-19.414
				c0,0,11.385,1.669,20.469,1.484C347.658,157.007,347.737,165.86,341.968,170.972z"/>
			<path style={{opacity:0.4}} d="M341.968,170.972c-5.257,4.658-25.339,4.192-25.339,4.192l-0.662-19.414
				c0,0,11.385,1.669,20.469,1.484C347.658,157.007,347.737,165.86,341.968,170.972z"/>
		</g>
		<path style={{fill:'#E4897B'}} d="M274.769,134.238l-5.043-5.887l-5.383,5.962c0,0,3.314,5.575,8.592,4.695L274.769,134.238z"/>
		<polygon style={{fill:'#E4897B'}} points="262.607,121.485 260.667,129.167 264.343,134.314 269.726,128.351 		"/>
		<g>
			<polygon style={{fill:'#CE6F64'}} points="338.751,392.636 339.127,401.405 346.476,401.405 346.1,392.636 			"/>
			<polygon style={{fill:'#CE6F64'}} points="387.246,392.636 379.896,392.636 381.803,401.405 389.153,401.405 			"/>
		</g>
		<path style={{fill:'#263238'}} d="M343.173,122.962c-6.59-3.045-8.673-16.337,10.285-13.045c0,0-2.857,3.167,2.947,5.167
			C362.208,117.083,357.35,129.514,343.173,122.962z"/>
		<path style={{fill:'#E4897B'}} d="M357.491,132.97c-1.887,7.042-2.634,11.26-6.98,14.189c-6.537,4.405-14.676-0.65-14.931-8.115
			c-0.229-6.719,2.896-17.107,10.453-18.526C353.479,119.12,359.379,125.928,357.491,132.97z"/>
		<path style={{fill:'#263238'}} d="M353.2,113.495c4.486,2.509,10.568,4.005,9.957,7.562c-0.747,4.348,3.677,1.628,2.647,8.106
			c-0.673,4.23-8.475,17.048-13.967,16.422c-8.256-0.941-7.21-13.552-7.237-20.179C344.583,121.5,344.4,108.574,353.2,113.495z"/>
		<path style={{fill:'#263238'}} d="M343.61,119.111c-2,0.472-4.651,12,3.096,13.056C355.623,133.382,343.61,119.111,343.61,119.111z"
			/>
		<path style={{fill:'#263238'}} d="M343.813,123.203c-2.451,0.216-7.979-2.734-7.319-7.171
			C336.493,116.033,340.016,121.224,343.813,123.203z"/>
		<path style={{fill:'#E4897B'}} d="M347.446,133.088c-0.767,1.749-2.219,3.125-3.624,3.857c-2.114,1.1-3.301-0.509-2.823-2.623
			c0.43-1.903,2.049-4.769,4.222-5.004C347.364,129.086,348.323,131.088,347.446,133.088z"/>
		<path style={{fill:'#263238'}} d="M358.446,124.949c1.484-0.755,4.864-2.467,5.785-7.324
			C364.231,117.624,367.532,126.84,358.446,124.949z"/>
		<path style={{fill:'#263238'}} d="M366.793,124.852c-0.741-0.434-2.423-1.423-4.786-0.472
			C362.007,124.38,365.086,120.385,366.793,124.852z"/>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M341.57,222.777c0,0,8.337,56.142,12.914,77.175c5.012,23.033,23.895,99.755,23.895,99.755h13.455
				c0,0-12.451-74.863-15.311-97.667c-3.104-24.75-9.599-79.263-9.599-79.263H341.57z"/>
			<path style={{opacity:0.5,fill:'#FFFFFF'}} d="M341.57,222.777c0,0,8.337,56.142,12.914,77.175
				c5.012,23.033,23.895,99.755,23.895,99.755h13.455c0,0-12.451-74.863-15.311-97.667c-3.104-24.75-9.599-79.263-9.599-79.263
				H341.57z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M376.23,394.75c-0.057,0,1.389,5.167,1.389,5.167h15.138l-0.278-4.611L376.23,394.75z"/>
			<path style={{opacity:0.1}} d="M376.23,394.75c-0.057,0,1.389,5.167,1.389,5.167h15.138l-0.278-4.611L376.23,394.75z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M346.839,242.78c9.64,1.89,8.62,37.84,7.46,56.33c-2.78-13.07-6.85-38.22-9.63-56.07
				C345.309,242.72,346.029,242.62,346.839,242.78z"/>
			<path style={{opacity:0.4,fill:'#FFFFFF'}} d="M346.839,242.78c9.64,1.89,8.62,37.84,7.46,56.33c-2.78-13.07-6.85-38.22-9.63-56.07
				C345.309,242.72,346.029,242.62,346.839,242.78z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M327.632,222.777c0,0-1.012,54.927-0.213,77.33c0.831,23.302,8.97,99.601,8.97,99.601h12.445
				c0,0,0.457-74.937,1.01-97.822c0.604-24.949,3.616-79.108,3.616-79.108H327.632z"/>
			<path style={{opacity:0.5,fill:'#FFFFFF'}} d="M327.632,222.777c0,0-1.012,54.927-0.213,77.33c0.831,23.302,8.97,99.601,8.97,99.601
				h12.445c0,0,0.457-74.937,1.01-97.822c0.604-24.949,3.616-79.108,3.616-79.108H327.632z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M338.981,390.768c-0.063,0-0.117-0.048-0.124-0.112c-2.193-21.117-7.366-72.213-8.021-90.544
				c-0.667-18.711-0.061-60.69,0.141-73.111c0.001-0.068,0.057-0.123,0.125-0.123c0.001,0,0.001,0,0.002,0
				c0.069,0.001,0.124,0.058,0.123,0.127c-0.202,12.419-0.808,54.393-0.141,73.097c0.654,18.324,5.825,69.413,8.019,90.528
				c0.008,0.068-0.042,0.13-0.111,0.137C338.99,390.768,338.985,390.768,338.981,390.768z"/>
			<path style={{opacity:0.4,fill:'#FFFFFF'}} d="M338.981,390.768c-0.063,0-0.117-0.048-0.124-0.112
				c-2.193-21.117-7.366-72.213-8.021-90.544c-0.667-18.711-0.061-60.69,0.141-73.111c0.001-0.068,0.057-0.123,0.125-0.123
				c0.001,0,0.001,0,0.002,0c0.069,0.001,0.124,0.058,0.123,0.127c-0.202,12.419-0.808,54.393-0.141,73.097
				c0.654,18.324,5.825,69.413,8.019,90.528c0.008,0.068-0.042,0.13-0.111,0.137C338.99,390.768,338.985,390.768,338.981,390.768z"
				/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M333.994,394.75c-0.057,0,0.702,5.167,0.702,5.167h15.139l0.409-4.611L333.994,394.75z"/>
			<path style={{opacity:0.1}} d="M333.994,394.75c-0.057,0,0.702,5.167,0.702,5.167h15.139l0.409-4.611L333.994,394.75z"/>
		</g>
	</g>
</g>
      </svg>
    );
  }
}
