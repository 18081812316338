import React, { useEffect, useState } from "react";
import "./App.css";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { allThemes, chosenTheme } from "./theme";
import { GlobalStyles } from "./global";

function App() {
  const [theme, setTheme] = useState(chosenTheme);

  const getTheme = () => {
    let name = localStorage.getItem('theme')||chosenTheme.name;
    let themer = allThemes.find(e=>e.name===name);
    setTheme(themer||chosenTheme);
  };

  useEffect(()=>{
    getTheme();
  },[])
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <div>
          <Main theme={theme} callBack={getTheme} />
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;