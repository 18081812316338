import React, { Component } from "react";

export default class ProgramImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
		<svg
        id="1c51936f-ad83-4493-a1e8-5a7ca1846cda"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="864.81"
        height="658.45"
      >
<g id="Background_Simple">
	<g>
		<path style={{fill:theme.imageHighlight}} d="M456.551,209.106c-48.744-83.474-180.95-53.744-207.955-94.349
			c-13.827-20.79-29.295-44.889-51.732-57.421c-21.389-11.947-46.19-16.21-67.811-14.627
			C51.845,48.36,19.107,117.439,33.944,193.299c19.792,101.192,109.496,204.559,216.064,205.283
			c55.736,0.379,108.948-19.545,150.689-47.71c0.312-0.211,0.623-0.422,0.934-0.633
			C443.331,321.854,493.766,272.837,456.551,209.106z"/>
		<path style={{opacity:0.7,fill:'#FFFFFF'}} d="M456.551,209.106c-48.744-83.474-180.95-53.744-207.955-94.349
			c-13.827-20.79-29.295-44.889-51.732-57.421c-21.389-11.947-46.19-16.21-67.811-14.627
			C51.845,48.36,19.107,117.439,33.944,193.299c19.792,101.192,109.496,204.559,216.064,205.283
			c55.736,0.379,108.948-19.545,150.689-47.71c0.312-0.211,0.623-0.422,0.934-0.633
			C443.331,321.854,493.766,272.837,456.551,209.106z"/>
	</g>
</g>
<g id="Lines">
	<g>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10,strokeDasharray:4.4124}} d="
			M78.189,215.179H72.77c-12.113,0-21.932,9.819-21.932,21.932v120.342c0,12.112,9.819,21.932,21.932,21.932h74.36
			c12.113,0,21.932-9.819,21.932-21.932l0,0"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10,strokeDasharray:4.4124}} d="
			M162.964,101.629V77.857c0-7.418,6.014-13.432,13.432-13.432h96.702c7.418,0,13.432,6.014,13.432,13.432l0,0"/>
	</g>
</g>
<g id="Shadow">
	<g>
		<ellipse style={{fill:theme.imageHighlight}} cx="250" cy="445.399" rx="191.522" ry="26.764"/>
		<ellipse style={{opacity:0.5,fill:'#FFFFFF'}} cx="250" cy="445.399" rx="191.522" ry="26.764"/>
	</g>
</g>
<g id="Window">
	<g>
		
			<path style={{fill:'#FFFFFF',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M430.54,133.524v180.06c0,12.255-9.939,22.195-22.194,22.195H91.655c-12.256,0-22.195-9.939-22.195-22.195v-180.06
			c0-0.529,0.022-1.048,0.066-1.566c0.794-11.527,10.402-20.639,22.128-20.639h316.691c11.726,0,21.334,9.112,22.128,20.639
			C430.518,132.476,430.54,132.995,430.54,133.524z"/>
		<path style={{fill:theme.text}} d="M400.071,327.408H101.06c-11.488,0-20.8-9.313-20.8-20.8V140.276h340.611v166.331
			C420.871,318.095,411.559,327.408,400.071,327.408z"/>
		<path style={{opacity:0.2,fill:'#FFFFFF'}} d="M420.877,140.275v166.337c0,11.483-9.215,20.793-20.569,20.793H100.829
			c-11.353,0-20.569-9.31-20.569-20.793V140.275H420.877z"/>
		<path style={{fill:theme.imageHighlight,stroke:theme.text,strokeWidth:1.1031,strokeMiterlimit:10}} d="M430.474,131.958H69.526
			c0.794-11.527,10.402-20.639,22.128-20.639h316.691C420.071,111.319,429.679,120.43,430.474,131.958z"/>
		<path style={{fill:theme.text}} d="M113.881,121.638c0,2.171-1.76,3.931-3.931,3.931c-2.171,0-3.931-1.76-3.931-3.931
			c0-2.171,1.76-3.931,3.931-3.931C112.121,117.707,113.881,119.467,113.881,121.638z"/>
		<path style={{fill:theme.text}} d="M130.504,121.638c0,2.171-1.76,3.931-3.931,3.931c-2.171,0-3.931-1.76-3.931-3.931
			c0-2.171,1.76-3.931,3.931-3.931C128.743,117.707,130.504,119.467,130.504,121.638z"/>
		<circle style={{fill:theme.text}} cx="143.195" cy="121.638" r="3.931"/>
		<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeMiterlimit:10}} d="M420.871,140.282v166.326
			c0,11.483-9.31,20.805-20.805,20.805H101.059c-10.104,0-18.521-7.214-20.396-16.767l-0.011-0.011v-0.044
			c-0.254-1.291-0.386-2.625-0.386-3.982V140.282H420.871z"/>
		<path style={{fill:theme.text}} d="M420.548,166.008c-1.456-0.816-3.011-1.456-4.622-1.908l-1.864-8.35h-7.942l-1.853,8.35
			c-1.622,0.441-3.177,1.092-4.633,1.908l-7.17-4.589l-5.615,5.615l4.512,7.17c-0.827,1.467-1.467,3.022-1.908,4.633l-8.35,1.853
			v7.942l8.35,1.864c0.441,1.633,1.081,3.199,1.908,4.666l-4.589,7.214l5.615,5.615l7.214-4.589
			c1.456,0.827,3.011,1.467,4.633,1.919l1.853,8.34h7.942l1.864-8.34c1.611-0.452,3.166-1.103,4.622-1.919l0.364,0.232v-37.837
			L420.548,166.008z M410.113,194.965c-5.67,0-10.259-4.589-10.259-10.259c0-5.659,4.589-10.259,10.259-10.259
			c5.67,0,10.259,4.6,10.259,10.259C420.372,190.376,415.783,194.965,410.113,194.965z"/>
		<path style={{fill:theme.text}} d="M277.451,174.052v-16.745l-17.606-3.905c-0.96-3.398-2.305-6.685-4.026-9.774l2.129-3.353h-83.097
			l2.052,3.265c-1.732,3.078-3.078,6.354-4.015,9.762l-17.606,3.916v16.745l17.606,3.927c0.938,3.431,2.283,6.74,4.015,9.84
			l-9.674,15.212l11.847,11.836l15.201-9.674c3.089,1.732,6.365,3.089,9.773,4.048l3.905,17.583H224.7l3.938-17.583
			c3.397-0.96,6.663-2.328,9.74-4.048l15.212,9.674l11.836-11.836l-9.608-15.212c1.71-3.089,3.067-6.354,4.026-9.751
			L277.451,174.052z M216.449,187.312c-11.947,0-21.632-9.685-21.632-21.632s9.685-21.632,21.632-21.632
			c11.947,0,21.632,9.685,21.632,21.632S228.396,187.312,216.449,187.312z"/>
		<path style={{fill:theme.text}} d="M166.385,289.036l0.706-13.281l-13.767-3.883c-0.64-2.725-1.589-5.383-2.824-7.898l8.317-11.682
			l-8.913-9.895l-12.465,7.049c-2.383-1.489-4.92-2.714-7.589-3.629l-2.383-14.12l-13.281-0.706l-3.839,13.811
			c-2.736,0.607-5.383,1.555-7.898,2.802l-11.671-8.317l-9.906,8.913l7.06,12.476c-1.522,2.372-2.736,4.909-3.64,7.578l-4.026,0.662
			v17.992l2.923,0.827c0.618,2.747,1.566,5.405,2.824,7.92l-5.747,8.042l0.386,6.894v0.044l0.011,0.011l5.924,6.575l12.487-7.049
			c2.383,1.533,4.942,2.769,7.611,3.673l2.272,13.557v0.011l0.11,0.563l13.281,0.706l0.353-1.269v-0.011l3.486-12.531
			c2.758-0.618,5.405-1.555,7.942-2.802l11.649,8.306l9.906-8.902l-7.038-12.487c1.5-2.372,2.714-4.909,3.596-7.567L166.385,289.036
			z M131.107,268.286c-6.354-7.06-17.23-7.633-24.29-1.28c-7.06,6.354-7.633,17.23-1.28,24.29c6.354,7.06,17.23,7.622,24.29,1.268
			C136.887,286.212,137.461,275.346,131.107,268.286z"/>
		<path style={{fill:theme.text}} d="M272.601,269.189c0.516-2.056,0.783-4.169,0.806-6.29l9.549-5.02l-2.639-9.688l-10.778,0.504
			c-1.09-1.817-2.395-3.502-3.872-5.004l3.194-10.311l-8.703-4.986l-7.281,7.945c-2.049-0.502-4.152-0.765-6.27-0.799l-5.041-9.556
			l-9.674,2.632l0.511,10.792c-1.817,1.09-3.502,2.394-5.014,3.868l-10.314-3.184l-4.992,8.689l7.988,7.262
			c-0.516,2.056-0.779,4.159-0.788,6.273l-9.566,5.037l2.639,9.688l10.792-0.511c1.076,1.824,2.37,3.504,3.858,5.01l-3.209,10.258
			l8.704,4.986l7.244-7.877c2.056,0.516,4.158,0.78,6.284,0.792l5.027,9.563l9.674-2.632l-0.424-9.218l-0.072-1.58
			c1.838-1.083,3.533-2.383,5.063-3.874l10.307,3.204l4.259-7.444l0.727-1.26L272.601,269.189z M249.728,275.074
			c-6.912,1.881-14.033-2.199-15.908-9.097c-1.881-6.912,2.199-14.033,9.097-15.908c6.912-1.881,14.033,2.199,15.908,9.097
			C260.706,266.078,256.626,273.2,249.728,275.074z"/>
		<path style={{fill:theme.text}} d="M383.316,286.227l2.206-13.292l-13.458-5.416c-0.309-2.835-0.96-5.615-1.93-8.295l9.685-10.788
			l-7.843-10.965l-13.359,5.67c-2.217-1.776-4.633-3.276-7.214-4.479l-0.783-14.495l-13.303-2.195l-5.449,13.403
			c-2.824,0.331-5.593,0.982-8.262,1.942l-10.822-9.685l-10.954,7.843l5.681,13.348c-1.566,1.963-2.923,4.059-4.048,6.288
			c-0.154,0.309-0.309,0.618-0.452,0.927l-14.484,0.805l-2.206,13.281l2.184,0.883l11.274,4.545c0.309,2.813,0.949,5.593,1.93,8.262
			l-9.685,10.822l7.843,10.954l13.359-5.692c2.206,1.798,4.633,3.309,7.203,4.512l0.75,14.429l13.292,2.195l5.438-13.303
			c2.835-0.309,5.615-0.96,8.284-1.941l10.799,9.696l10.965-7.854l-5.681-13.359c1.809-2.217,3.342-4.655,4.556-7.247
			L383.316,286.227z M346.02,285.632c-7.821,5.593-18.698,3.795-24.29-4.026c-5.604-7.81-3.795-18.687,4.015-24.279
			c7.821-5.604,18.698-3.795,24.29,4.015C355.639,269.162,353.83,280.039,346.02,285.632z"/>
	</g>
</g>
<g id="Graphics">
	<g>
		<g>
			<path style={{fill:theme.imageHighlight,stroke:theme.text,strokeWidth:1.1031,strokeMiterlimit:10}} d="M203.076,358.784H98.499
				c-7.24,0-13.11-5.87-13.11-13.11V200.88c0-7.24,5.869-13.11,13.11-13.11h104.577c7.24,0,13.11,5.87,13.11,13.11v144.794
				C216.186,352.915,210.316,358.784,203.076,358.784z"/>
			<path style={{opacity:0.2}} d="M203.076,358.784H98.499c-7.24,0-13.11-5.87-13.11-13.11V200.88c0-7.24,5.869-13.11,13.11-13.11
				h104.577c7.24,0,13.11,5.87,13.11,13.11v144.794C216.186,352.915,210.316,358.784,203.076,358.784z"/>
			<g>
				
					<path style={{fill:'#FFFFFF',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M228.362,213.797V358.59c0,7.247-5.868,13.116-13.116,13.116H110.671c-7.236,0-13.105-5.868-13.105-13.116V213.797
					c0-7.236,5.868-13.105,13.105-13.105h104.575C222.493,200.692,228.362,206.56,228.362,213.797z"/>
				<path style={{fill:theme.imageHighlight,stroke:theme.text,strokeWidth:1.1031,strokeMiterlimit:10}} d="M228.362,213.797v7.755H97.566v-7.755
					c0-7.236,5.868-13.105,13.105-13.105h104.575C222.493,200.692,228.362,206.56,228.362,213.797z"/>
				<rect x="109.867" y="230.534" style={{fill:theme.imageHighlight}} width="32.178" height="5.397"/>
				<rect x="109.867" y="278.922" style={{fill:theme.imageHighlight}} width="32.178" height="5.397"/>
				<rect x="109.867" y="322.802" style={{fill:theme.imageHighlight}} width="32.178" height="5.397"/>
				<g>
					<rect x="109.867" y="243.896" style={{fill:theme.text}} width="91.392" height="3.959"/>
					<rect x="109.867" y="251.473" style={{fill:theme.text}} width="74.912" height="3.858"/>
					<rect x="109.867" y="258.922" style={{fill:theme.text}} width="84.722" height="3.858"/>
					<rect x="109.867" y="266.363" style={{fill:theme.text}} width="84.722" height="3.858"/>
				</g>
				<g>
					<rect x="109.867" y="287.776" style={{fill:theme.text}} width="91.392" height="3.959"/>
					<rect x="109.867" y="295.353" style={{fill:theme.text}} width="74.912" height="3.858"/>
					<rect x="109.867" y="302.801" style={{fill:theme.text}} width="84.722" height="3.858"/>
				</g>
				<g>
					<rect x="109.867" y="335.962" style={{fill:theme.text}} width="91.392" height="3.959"/>
					<rect x="109.867" y="343.539" style={{fill:theme.text}} width="74.912" height="3.858"/>
					<rect x="109.867" y="350.988" style={{fill:theme.text}} width="84.722" height="3.858"/>
				</g>
			</g>
		</g>
		<g>
			<path style={{fill:'#FFFFFF',stroke:'#222221',strokeWidth:1.1031,strokeMiterlimit:10}} d="M375.765,100.294v58.52
				c0,8.637-6.994,15.631-15.631,15.631H225.346c-8.626,0-15.631-6.994-15.631-15.631v-58.52c0-8.637,7.005-15.642,15.631-15.642
				h134.788C368.771,84.652,375.765,91.657,375.765,100.294z"/>
			<path style={{fill:theme.imageHighlight,stroke:theme.text,strokeWidth:1.1031,strokeMiterlimit:10}} d="M375.765,100.294v1.335h-166.05v-1.335
				c0-8.637,7.005-15.642,15.631-15.642h134.788C368.771,84.652,375.765,91.657,375.765,100.294z"/>
			<path style={{fill:theme.text}} d="M234.109,95.937c-0.935,0.935-2.451,0.935-3.386,0c-0.935-0.935-0.935-2.451,0-3.386
				c0.935-0.935,2.451-0.935,3.386,0C235.044,93.486,235.044,95.002,234.109,95.937z"/>
			<path style={{fill:theme.text}} d="M245.229,95.937c-0.935,0.935-2.451,0.935-3.386,0c-0.935-0.935-0.935-2.451,0-3.386
				c0.935-0.935,2.451-0.935,3.386,0C246.164,93.486,246.164,95.002,245.229,95.937z"/>
			<path style={{fill:theme.text}} d="M256.349,95.937c-0.935,0.935-2.451,0.935-3.386,0c-0.935-0.935-0.935-2.451,0-3.386
				c0.935-0.935,2.451-0.935,3.386,0C257.284,93.486,257.284,95.002,256.349,95.937z"/>
			
				<rect x="220.303" y="112.39" style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10,strokeDasharray:4.4124}} width="54.278" height="46.918"/>
			<g>
				<rect x="282.956" y="112.39" style={{fill:theme.text}} width="62.467" height="3.295"/>
				<rect x="282.956" y="119.223" style={{fill:theme.text}} width="47.916" height="3.295"/>
				<rect x="282.956" y="126.017" style={{fill:theme.text}} width="71.353" height="3.334"/>
				<rect x="282.956" y="132.655" style={{fill:theme.text}} width="60.758" height="3.334"/>
			</g>
		</g>
	</g>
</g>
<g id="Character">
	<g>
		<path style={{fill:theme.imageHighlight}} d="M296.863,440.465c0,0-29.001,8.317-33.049,9.751c-3.684,1.302-13.667,4.798-21.158,3.717
			c-0.739-0.11-1.456-0.265-2.14-0.463c-7.589-2.261-10.998-4.368-11.991-9.145c-1.004-4.787,2.78-8.284,6.42-10.535
			c3.64-2.25,23.386-4.6,23.386-4.6l4.765,1.401h0.011L296.863,440.465z"/>
		<path style={{fill:'#FFFFFF'}} d="M242.657,453.934c-0.739-0.11-1.456-0.265-2.14-0.463c-7.589-2.261-10.998-4.368-11.991-9.145
			c-1.004-4.787,2.78-8.284,6.42-10.535c3.64-2.25,23.386-4.6,23.386-4.6l4.765,1.401c-4.313,1.986-20.396,3.089-23.904,5.107
			c-3.508,2.019-5.207,5.824-3.133,10.965C237.163,449.401,240.119,452.048,242.657,453.934z"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M292.5,441.648c0,0-21.78,5.643-30.416,9.255c-8.636,3.612-25.114,5.192-30.983-2.257c-5.869-7.449-0.677-13.857,5.192-15.281
			s17.737-3.681,24.218-2.778"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M243.094,453.538c0,0-11.082-6.076-7.402-14.593c2.716-6.285,17.499-4.922,24.819-8.358"/>
		<path style={{fill:theme.imageHighlight}} d="M385.189,455.28c-0.298,2.967-1.346,5.813-4.346,7.578c-1.997,1.18-4.843,1.886-8.913,1.842
			c-10.171-0.132-29.287-7.402-29.287-7.402l-17.903-9.895l9.31-14.737l2.548-4.038l6.585,1.478c0,0,5.449,3.089,10.932,5.548
			c5.482,2.46,18.786,2.978,23.474,6.773C382.277,446.212,385.696,450.338,385.189,455.28z"/>
		<path style={{fill:'#FFFFFF'}} d="M385.189,455.28c-0.298,2.967-1.346,5.813-4.346,7.578c0.563-2.306,1.026-5.494-0.507-8.935
			c-1.986-4.49-8.505-8.45-17.065-9.111c-8.549-0.662-15.113-4.666-18.543-6.453c-2.68-1.39-8.328-4.423-10.678-5.692l2.548-4.038
			l6.585,1.478c0,0,5.449,3.089,10.932,5.548c5.482,2.46,18.786,2.978,23.474,6.773C382.277,446.212,385.696,450.338,385.189,455.28
			z"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M328.706,450.178c12.745,7.301,27.322,12.558,41.904,14.315c3.256,0.392,7.046,0.446,9.228-2.001
			c1.216-1.364,1.66-3.294,1.504-5.115c-0.395-4.596-4.259-8.241-8.497-10.062c-4.238-1.822-8.918-2.257-13.419-3.266
			c-4.482-1.005-8.832-2.596-12.905-4.721c-3.236-1.688-6.368-3.739-9.939-4.495c-3.571-0.756-7.824,0.161-9.728,3.275"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M378.643,463.739c3.295-1.691,6-4.797,6.549-8.46c0.649-4.332-1.818-8.687-5.295-11.352c-3.477-2.665-7.82-3.916-12.109-4.807
			c-4.289-0.892-8.675-1.491-12.755-3.085c-6.273-2.451-12.46-7.292-18.97-5.565"/>
		<path style={{fill:theme.imageHighlight}} d="M348.908,295.263l-30.291-11.527l-0.375-0.144c0,0,0.11-0.805,0.199-1.974
			c0.188-2.416,0.287-6.354-0.849-7.689c-1.688-1.975-6.475-0.32-11.141-2.46c-4.666-2.14-13.303,1.688-13.303,1.688
			s-6.233-1.147-8.979,3.354c-1.401,3.629-0.916,9.034-0.916,9.034s-0.198,0.077-0.574,0.21
			c-3.607,1.346-23.242,8.737-27.401,11.704c-4.589,3.254-9.663,36.623-11.296,46.419c-1.633,9.806-4.534,18.631-3.056,22.007
			c1.467,3.365,22.57,19.613,34.704,23.805c12.134,4.203,55.188,2.967,55.188,2.967s0.706-0.188,1.952-0.585
			c7.281-2.339,33.115-11.969,46.816-34.682C379.585,357.39,366.315,313.862,348.908,295.263z M267.068,351.389
			c-2.228,1.578-1.037,7.7-1.037,7.7l-3.795-3.342l9.52-23.408l4.059,17.97C275.816,350.308,269.297,349.823,267.068,351.389z
			 M343.227,366.259c-1.048,0.54,3.012-10.711,1.081-13.259c-1.93-2.559-7.711-2.305-7.711-2.305l0.496-3.078
			c0.066-0.342,3.431-17.594,3.431-17.594l1.136-1.357c0,0,11.781,20.198,13.888,27.313
			C355.548,355.978,342.708,366.259,343.227,366.259z"/>
		<path style={{fill:'#FFFFFF'}} d="M291.715,222.254l-15.634,16.599c0,0-2.156,1.906-1.299,6.721c0.857,4.815,2.852,8.952,4.36,9.272
			c1.507,0.321,3.62,0.324,3.62,0.324s3.35,7.261,5.723,10.114c0,0,0.827,11.207,0.387,14.318c0,0,5.616,4.906,10.907,5.53
			c5.291,0.624,13.465-7.482,13.465-7.482l2.026-9.923c0,0,4.796-7.28,5.123-9.218c0.327-1.938,2.52-1.441,3.597-2.397
			c1.077-0.956,3.571-5.232,3.983-9.272c0.412-4.04,0.441-8.681-4.253-7.318c-4.066-1.093-1.521-9.69-1.521-9.69l-7.146-6.305
			L291.715,222.254z"/>
		<path style={{opacity:0.5,fill:'#FFFFFF'}} d="M318.616,283.736c-0.243,0.64-0.507,1.246-0.805,1.809
			c-2.581,4.865-13.822,14.131-13.822,14.131l-3.398-10.446l-1.864,10.446c-3.751-1.633-15.19-11.549-15.19-11.549
			s-0.386-0.927-0.86-2.372c0.375-0.132,0.574-0.21,0.574-0.21s-0.485-5.405,0.916-9.034c2.747-4.501,8.979-3.354,8.979-3.354
			s8.637-3.828,13.303-1.688c4.666,2.14,9.454,0.485,11.141,2.46c1.688,1.974,0.651,9.663,0.651,9.663L318.616,283.736z"/>
		<path style={{opacity:0.2}} d="M348.908,295.263l-30.291-11.527l-0.375-0.144c0,0,0.11-0.805,0.199-1.974l-0.629,3.927
			l-4.479,6.089c0,0,7.574,2.733,13.977,6.161c4.452,2.383,7.008,7.23,6.422,12.245l-5.584,47.748l-2.283,27.114l6.905,7.17
			c7.281-2.339,33.115-11.969,46.816-34.682C379.585,357.39,366.315,313.862,348.908,295.263z M343.227,366.259
			c-1.048,0.54,3.012-10.711,1.081-13.259c-1.93-2.559-7.711-2.305-7.711-2.305l0.496-3.078c0.066-0.342,3.431-17.594,3.431-17.594
			l1.136-1.357c0,0,11.781,20.198,13.888,27.313C355.548,355.978,342.708,366.259,343.227,366.259z"/>
		<path style={{opacity:0.2}} d="M277.868,353l-4.71-15.741c0,0-8.445-28.804-14.236-35.908l1.522,20.257
			c0,0-4.969,24.2-9.569,31.392c-4.6,7.181-5.593,8.979,0,8.979c5.604,0,19.194,10.844,19.194,10.844l-1.048-9.046L277.868,353z
			 M266.031,359.089l-3.795-3.342l9.52-23.408l4.059,17.97c0,0-6.519-0.485-8.748,1.081
			C264.84,352.967,266.031,359.089,266.031,359.089z"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M288.485,265.284c0,0,0.87,8.488,0,12.366"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M314.108,276.513c0,0-0.049-4.135,0.945-8.109"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M316.382,228.582c0,0,5.135,6.452,4.872,11.324c-0.263,4.872-0.658,10.666-0.527,16.328c0.132,5.662-9.086,17.513-13.826,18.566
			c-4.74,1.053-8.822,1.317-11.851-1.712c-3.028-3.029-6.057-7.242-10.007-12.641c-3.95-5.399-3.028-17.25-3.028-17.25"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M304.021,247.714c-0.314,1.769-0.448,3.571-0.401,5.367c0.04,1.504,0.263,3.13,1.317,4.204c0.568,0.579,1.392,1.042,1.5,1.845
			c0.096,0.713-0.449,1.36-1.055,1.748c-0.606,0.388-1.309,0.628-1.851,1.1"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M295.866,262.604c0,0,0.594,3.827,5.726,4.628"/>
		<path style={{fill:theme.text}} d="M296.4,250.632c0,1.106-0.478,2.003-1.068,2.003c-0.59,0-1.068-0.897-1.068-2.003
			c0-1.106,0.478-2.003,1.068-2.003C295.922,248.63,296.4,249.526,296.4,250.632z"/>
		<path style={{fill:theme.text}} d="M311.71,251.113c0,1.106-0.478,2.003-1.068,2.003c-0.59,0-1.068-0.897-1.068-2.003
			c0-1.106,0.478-2.003,1.068-2.003C311.231,249.111,311.71,250.007,311.71,251.113z"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:2.2062,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M308.621,246.265c0,0,4.978-4.978,9.19,0.574"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:2.2062,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M298.091,246.361c0,0-3.159-3.542-8.137,0"/>
		<path style={{fill:theme.text}} d="M288.871,269.948c0,0,3.049,8.404,9.858,10.788c6.81,2.383,15.851-9.986,15.851-9.986l-0.473-1.609
			c0,0-5.924,7.697-12.515,6.385c-6.591-1.313-13.108-10.242-13.108-10.242L288.871,269.948z"/>
		
			<path style={{fill:'none',stroke:'#000',strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M282.762,254.846c0,0-4.059,1.433-5.138-1.73c-1.079-3.163-4.779-11.488-1.542-14.263c3.238-2.775,5.84,3.664,5.84,3.664"/>
		
			<path style={{fill:'none',stroke:'#000',strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M320.72,256.57c0,0,3.773,0.759,4.852-2.404c1.079-3.163,4.779-11.488,1.542-14.264c-3.238-2.775-5.84,3.664-5.84,3.664"/>
		<path style={{fill:'#000'}} d="M281.921,250.632c1.124-0.051,2.97-1.147,3.562-2.105c0.592-0.957,0.843-2.083,1.035-3.192
			c0.368-2.124,0.557-4.279,0.564-6.434c0.003-0.842-0.02-1.702,0.226-2.507c0.256-0.839,0.788-1.565,1.357-2.232
			c1.417-1.66,3.122-3.063,4.469-4.78c1.347-1.717,2.337-3.891,1.973-6.043c0,0,9.205,8.414,22.474,5.812
			c7.143-1.401,14-7.67,13.165-15.549c-0.297-2.804-2.358-4.82-5.033-5.486c-3.531-0.878-6.704,1.004-9.903,2.211
			c-5.833,2.2-11.54-1.578-17.433-2.211c-5.04-0.542-11.225,0.083-13.85,5.094c0,0-9.052,1.064-13.018,8.498
			c-3.965,7.435,3.572,17.728,3.572,17.728s3.034-3.672,6.932,3.762L281.921,250.632z"/>
		<path style={{fill:'#000'}} d="M316.952,226.681c0,0-2.341,12.199,3.767,18.105c0,0,1.337-3.667,2.692-4.562
			c0,0,3.4-6.445-1.07-13.544C317.873,219.582,316.952,226.681,316.952,226.681z"/>
		
			<path style={{opacity:0.2,fill:'none',stroke:'#FFFFFF',strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M328.142,212.799c-0.904,3.362-3.747,5.963-6.971,7.276c-8.48,3.454-16.716-0.377-25.206-1.127
			c-6.939-0.613-14.588,5.638-15.073,12.762"/>
		
			<path style={{opacity:0.2,fill:'none',stroke:'#FFFFFF',strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M275.626,229.831c0.128-5.608,3.833-10.975,9.032-13.082"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M286.53,276.513c0,0,5.587,6.156,13.205,9.034c0,0,10.151-2.944,15.318-10.022"/>
		
			<ellipse transform="matrix(0.909 -0.4169 0.4169 0.909 -77.7934 145.0154)" style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} cx="293.147" cy="250.632" rx="8.983" ry="8.983"/>
		
			<ellipse transform="matrix(0.9925 -0.1219 0.1219 0.9925 -28.2199 40.0562)" style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} cx="313.216" cy="250.632" rx="8.983" ry="8.983"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M288.485,273.162c0,0-2.731,0.553-4.321,3.35c-1.59,2.798-0.631,11.609-0.631,11.609s8.207,8.123,15.196,11.549l2.062-11.594
			l3.2,11.594c0,0,10.823-7.654,13.82-14.124c0,0,0.977-7.544,0-10.022s-3.23-2.363-3.23-2.363"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M275.626,350.698c0,0-3.204-21.495-7.474-30.265"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M258.921,301.351c0,0,4.055,5.685,6.639,11.111"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M343.228,317.399c1.129-4.283,2.329-8.04,3.528-10.492"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M336.596,350.698c0,0,2.315-14.736,5.069-26.881"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M340.521,330.018c2.204-2.943,5.4-5.128,8.942-6.115"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M318.245,283.592c0,0,18.639,7.445,30.668,11.673c0,0,14.8,13.918,30.668,62.126c0,0-14.545,24.46-42.201,32.302"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M342.184,367.135l13.363-11.157c0,0,7.069-5.373,14.421-1.697"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M341.665,328.669c0,0,11.054,16.281,13.881,27.309"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M266.152,359.707l-3.911-3.729c0,0-5.805-1.015-12.378,3.392"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M262.241,355.978c0,0,4.857-15.954,9.648-24.191"/>
		
			<path style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M283.247,285.547c0,0-26.811,10.06-29.44,12.99c-2.629,2.93-12.62,59.197-12.883,65.636c-0.235,5.756,20.649,18.689,30.965,24.141
			"/>
		
			<path style={{fill:'#FFFFFF',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M380.311,276.513H349.76c-3.607,0-6.532-2.924-6.532-6.532v-17.923c0-3.607,2.924-6.532,6.532-6.532h30.551
			c3.607,0,6.532,2.924,6.532,6.532v17.923C386.842,273.588,383.918,276.513,380.311,276.513z"/>
		
			<polyline style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
			357.3,254.846 348.913,260.111 356.696,267.013 		"/>
		
			<polyline style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
			372.582,267.515 380.119,261.092 371.419,255.388 		"/>
		
			<line style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="361.377" y1="271.467" x2="367.568" y2="252.054"/>
		<path style={{fill:theme.text}} d="M303.296,304.556c0,0.974-0.789,1.763-1.763,1.763c-0.974,0-1.763-0.789-1.763-1.763
			c0-0.974,0.789-1.763,1.763-1.763C302.507,302.793,303.296,303.582,303.296,304.556z"/>
		<g>
			<path style={{fill:theme.text}} d="M334.32,398.776h-61.018c-0.79,0-1.43-0.641-1.43-1.43l0,0c0-0.79,0.641-1.43,1.43-1.43h61.018
				c0.79,0,1.43,0.641,1.43,1.43l0,0C335.75,398.135,335.11,398.776,334.32,398.776z"/>
			
				<path style={{fill:'#FFFFFF',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
				M336.456,394.034l8.177-38.399c0.541-2.542-1.397-4.937-3.997-4.937h-71.291c-2.574,0-4.508,2.352-4.009,4.878l7.331,37.121
				c0.371,1.88,1.999,3.25,3.915,3.293l55.783,1.278C334.329,397.313,336.047,395.955,336.456,394.034z"/>
			<path style={{fill:theme.text}} d="M310.439,372.826c0,3.143-2.548,5.692-5.692,5.692s-5.692-2.548-5.692-5.692
				c0-3.143,2.548-5.692,5.692-5.692S310.439,369.683,310.439,372.826z"/>
			
				<line style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="321.625" y1="354.341" x2="330.095" y2="354.341"/>
			
				<line style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="281.176" y1="354.341" x2="313.328" y2="354.341"/>
		</g>
		<path style={{fill:theme.text}} d="M336.596,434.771l-6.501,17.058c0,0-16.572-4.177-37.807-8.529
			c-22.204-4.55-45.395-10.441-57.425-31.545c-1.467-2.573-2.442-5.613-1.756-8.494c1.736-7.29,11.219-9.268,17.47-9.998
			c3.923-0.458,7.884-0.61,11.831-0.665c2.012-0.028,8.652-1.083,10.258,0.099c0,0,4.167,3.066,7.716,4.572
			c3.55,1.507,55.367-1.354,55.367-1.354s1.541-3.253,1.629-6.221c8.145-4.14,27.007-7.647,34.977-6.097
			c12.533,2.437,19.843,9.747,18.451,17.406C389.414,408.661,361.757,429.201,336.596,434.771z"/>
		
			<path style={{fill:'none',stroke:'#FFFFFF',strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M336.596,434.771c0,0-15.762-4.177-28.643-12.881c-12.881-8.703-22.944-18.799-40.888-16.71"/>
		
			<path style={{fill:'none',stroke:'#FFFFFF',strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M304.99,419.837c8.136-2.341,14.107-7.603,20.636-12.657c6.915-5.354,14.228-10.539,22.093-14.517"/>
		
			<line style={{fill:'none',stroke:theme.text,strokeWidth:1.1031,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="302.13" y1="252.054" x2="304.517" y2="252.054"/>
	</g>
</g>
</svg>

    );
  }
}
