import React, { Component } from "react";

export default class ExperienceImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg 
      id="bf56719a-dd30-4b09-833f-7c41eeee086b"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="1094"
        height="760"
        x="0px" y="0px"
        viewBox="0 0 500 500" >
      <g id="Background_Complete">
      <rect x="44.842" y="277.649" style={{fill:'#F5F5F5'}} width="52.974" height="199.453"/>
      <rect x="331.869" y="343.975" style={{fill:'#F5F5F5'}} width="52.974" height="132.025"/>
      <rect x="412.77" y="240.203" style={{fill:'#F5F5F5'}} width="52.974" height="235.797"/>
      <rect x="242.125" y="248.762" style={{fill:'#F5F5F5'}} width="52.974" height="227.238"/>
      <rect x="135.85" y="184.426" style={{fill:'#F5F5F5'}} width="52.974" height="291.574"/>
      <g>
      <g>
      <g>
      <path style={{fill:'#EBEBEB'}} d="M412.77,54.142c0,0-0.256,0.271-0.773,0.785c-0.526,0.519-1.297,1.279-2.309,2.277
      c-2.046,2.003-5.056,4.951-8.947,8.76c-7.811,7.619-19.15,18.679-33.29,32.472c-28.325,27.568-67.867,66.053-112.768,109.753
      l-0.199,0.193l-0.178-0.213c-8.866-10.621-18.131-21.722-27.578-33.039c-25.367-30.409-49.427-59.251-70.781-84.85l0.378-0.021
      c-25.11,37.744-46.335,69.649-61.289,92.127c-7.486,11.221-13.4,20.088-17.449,26.157c-2.026,3.021-3.583,5.345-4.641,6.922
      c-0.526,0.777-0.926,1.368-1.201,1.774c-0.271,0.397-0.418,0.594-0.418,0.594s0.124-0.211,0.385-0.615
      c0.267-0.411,0.657-1.009,1.169-1.795c1.043-1.586,2.581-3.923,4.579-6.962c4.023-6.087,9.9-14.978,17.338-26.231
      c14.916-22.504,36.086-54.445,61.132-92.232l0.175-0.264l0.203,0.244c21.365,25.589,45.438,54.421,70.818,84.819
      c9.445,11.319,18.708,22.421,27.572,33.043l-0.376-0.02c44.949-43.651,84.534-82.091,112.89-109.628
      c14.186-13.746,25.561-24.768,33.398-32.361c3.918-3.782,6.949-6.708,9.009-8.696c1.026-0.983,1.808-1.732,2.341-2.243
      C412.491,54.39,412.77,54.142,412.77,54.142z"/>
      </g>
      <circle style={{fill:'#EBEBEB'}} cx="156.129" cy="90.131" r="9.83"/>
       
      
      <ellipse transform="matrix(0.9248 -0.3804 0.3804 0.9248 -77.5023 43.5099)" style={{fill:'#EBEBEB'}} cx="71.329" cy="217.835" rx="9.829" ry="9.83"/>
       
      
      <ellipse transform="matrix(0.1597 -0.9872 0.9872 0.1597 8.5249 426.0266)" style={{fill:'#EBEBEB'}} cx="254.504" cy="208.006" rx="9.83" ry="9.829"/>
      </g>
       
      
      <ellipse transform="matrix(0.2195 -0.9756 0.9756 0.2195 276.9308 446.9384)" style={{fill:'#EBEBEB'}} cx="417.791" cy="50.394" rx="9.83" ry="9.83"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#EBEBEB'}} d="M57.976,69.924c2.405-1.453,4.964-2.555,7.605-3.305l1.567-7.528l12.718-0.223l1.83,7.467
      c2.665,0.657,5.263,1.668,7.717,3.036l6.428-4.213l9.151,8.836l-3.984,6.57c1.454,2.405,2.555,4.966,3.305,7.607l7.527,1.566
      l0.223,12.718l-7.467,1.831c-0.657,2.665-1.668,5.261-3.036,7.716l4.213,6.428l-8.835,9.151l-6.571-3.984
      c-2.405,1.453-4.965,2.555-7.606,3.305l-1.567,7.528l-12.718,0.223l-1.831-7.468c-2.665-0.657-5.262-1.668-7.716-3.036
      l-6.428,4.213l-9.151-8.835l3.984-6.571c-1.453-2.405-2.556-4.965-3.305-7.606l-7.527-1.566l-0.223-12.718l7.468-1.831
      c0.657-2.665,1.669-5.262,3.036-7.716l-4.213-6.428l8.835-9.151L57.976,69.924L57.976,69.924z M64.756,86.866
      c-5.235,5.422-5.083,14.06,0.339,19.295c5.422,5.235,14.06,5.083,19.294-0.339c5.234-5.422,5.083-14.06-0.339-19.294
      C78.628,81.293,69.99,81.444,64.756,86.866z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#F5F5F5'}} d="M267.881,46.946c2.989-1.806,6.17-3.176,9.452-4.108l1.947-9.356l15.807-0.277l2.275,9.281
      c3.313,0.816,6.541,2.073,9.591,3.773l7.989-5.237l11.374,10.982l-4.952,8.166c1.807,2.99,3.176,6.173,4.107,9.454l9.355,1.947
      l0.278,15.807l-9.281,2.276c-0.816,3.312-2.073,6.539-3.773,9.59l5.236,7.99l-10.98,11.374l-8.167-4.952
      c-2.989,1.806-6.172,3.175-9.453,4.107l-1.947,9.356l-15.807,0.277l-2.275-9.282c-3.312-0.816-6.54-2.073-9.591-3.773
      l-7.989,5.237l-11.374-10.981l4.952-8.167c-1.806-2.989-3.176-6.171-4.108-9.453l-9.355-1.947l-0.277-15.807l9.281-2.276
      c0.816-3.312,2.074-6.54,3.773-9.59l-5.236-7.989l10.981-11.374L267.881,46.946L267.881,46.946z M276.307,68.004
      c-6.506,6.739-6.318,17.475,0.421,23.981c6.739,6.506,17.475,6.317,23.981-0.422c6.505-6.739,6.318-17.475-0.421-23.981
      C293.549,61.077,282.813,61.266,276.307,68.004z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#F5F5F5'}} d="M118.788,38.515c1.259-0.761,2.598-1.337,3.98-1.73l0.82-3.94l6.657-0.117l0.958,3.908
      c1.395,0.344,2.755,0.873,4.039,1.589l3.364-2.205l4.79,4.625l-2.085,3.439c0.761,1.259,1.338,2.599,1.73,3.981l3.94,0.82
      l0.117,6.657l-3.909,0.958c-0.344,1.395-0.873,2.754-1.589,4.039l2.205,3.365l-4.624,4.79l-3.439-2.085
      c-1.259,0.761-2.599,1.337-3.981,1.73l-0.82,3.94l-6.657,0.117l-0.958-3.909c-1.395-0.344-2.754-0.873-4.039-1.589l-3.364,2.205
      l-4.79-4.625l2.085-3.439c-0.761-1.259-1.338-2.599-1.73-3.981l-3.94-0.82l-0.117-6.657l3.909-0.958
      c0.344-1.395,0.873-2.754,1.589-4.039l-2.205-3.364l4.624-4.79L118.788,38.515L118.788,38.515z M122.336,47.383
      c-2.74,2.838-2.66,7.359,0.177,10.099c2.838,2.74,7.359,2.66,10.099-0.178c2.74-2.838,2.661-7.359-0.178-10.099
      C129.597,44.466,125.076,44.545,122.336,47.383z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#F5F5F5'}} d="M428.87,108.4c1.702-1.029,3.514-1.808,5.383-2.34l1.109-5.328l9.003-0.158l1.296,5.286
      c1.887,0.465,3.725,1.181,5.462,2.149l4.55-2.982l6.477,6.254l-2.82,4.651c1.029,1.703,1.809,3.515,2.339,5.384l5.328,1.109
      l0.158,9.003l-5.286,1.296c-0.465,1.886-1.181,3.724-2.149,5.462l2.982,4.55l-6.254,6.478l-4.651-2.82
      c-1.703,1.029-3.515,1.808-5.384,2.339l-1.109,5.328l-9.002,0.158l-1.296-5.286c-1.886-0.465-3.725-1.181-5.462-2.149
      l-4.55,2.982l-6.478-6.254l2.82-4.651c-1.029-1.703-1.809-3.515-2.34-5.384l-5.328-1.109l-0.158-9.002l5.286-1.296
      c0.465-1.886,1.181-3.724,2.149-5.462l-2.982-4.55l6.254-6.478L428.87,108.4L428.87,108.4z M433.669,120.393
      c-3.705,3.838-3.598,9.953,0.24,13.658c3.838,3.705,9.953,3.598,13.658-0.24c3.705-3.838,3.598-9.953-0.24-13.658
      C443.488,116.448,437.374,116.556,433.669,120.393z"/>
      </g>
      </g>
      </g>
      <g id="Floor">
      <g>
      <path style={{fill:'#263238'}} d="M483.201,477.365c0,0.144-104.416,0.26-233.189,0.26c-128.818,0-233.212-0.117-233.212-0.26
      c0-0.144,104.393-0.26,233.212-0.26C378.785,477.105,483.201,477.221,483.201,477.365z"/>
      </g>
      </g>
      <g id="Briefcase">
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M322.1,477.365h82.08c1.568,0,2.807-1.33,2.697-2.894l-4.097-58.088
      c-0.1-1.414-1.275-2.512-2.693-2.513l-75.352-0.107c-1.447-0.002-2.64,1.136-2.705,2.582l-2.631,58.195
      C319.329,476.079,320.559,477.365,322.1,477.365z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M333.424,477.365c0.126,0,0.229-14.214,0.229-31.745c0-17.536-0.103-31.748-0.229-31.748
      c-0.126,0-0.229,14.211-0.229,31.748C333.195,463.151,333.297,477.365,333.424,477.365z"/>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M334.395,415.379c-0.007,0.004,0.035,0.083,0.124,0.232c0.104,0.17,0.236,0.386,0.401,0.656
      c0.379,0.598,0.91,1.432,1.584,2.494c1.396,2.152,3.422,5.267,6.157,8.958c2.763,3.656,6.23,7.949,10.897,11.878
      c2.324,1.966,4.96,3.802,7.878,5.384c2.927,1.544,6.226,2.774,9.768,3.059c3.547,0.297,7.008-0.502,10.078-1.746
      c3.077-1.262,5.837-2.958,8.204-4.882c4.745-3.873,7.878-8.556,9.795-12.726c1.93-4.195,2.617-7.916,2.9-10.459
      c0.056-0.64,0.121-1.208,0.149-1.702c0.006-0.486,0.011-0.903,0.015-1.251c0-0.317,0-0.57,0-0.769
      c-0.003-0.173-0.008-0.262-0.016-0.262c-0.008,0-0.018,0.089-0.031,0.262c-0.012,0.198-0.027,0.451-0.046,0.767
      c-0.017,0.347-0.036,0.762-0.059,1.245c-0.042,0.491-0.12,1.054-0.19,1.69c-0.336,2.523-1.066,6.204-3.017,10.344
      c-1.937,4.114-5.067,8.727-9.767,12.534c-2.345,1.891-5.072,3.556-8.106,4.794c-3.027,1.219-6.417,1.995-9.874,1.705
      c-3.457-0.277-6.701-1.48-9.595-3c-2.887-1.558-5.504-3.369-7.816-5.31c-4.643-3.879-8.121-8.129-10.909-11.75
      c-2.76-3.656-4.826-6.741-6.267-8.862c-0.705-1.041-1.259-1.86-1.655-2.446c-0.181-0.26-0.325-0.468-0.439-0.631
      C334.457,415.444,334.402,415.374,334.395,415.379z"/>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M368.363,448.092c0,2.08,1.686,3.767,3.767,3.767s3.767-1.686,3.767-3.767
      c0-2.08-1.687-3.767-3.767-3.767S368.363,446.012,368.363,448.092z"/>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M346.322,402.043c1.576-1.459,3.792-2,5.929-2.211c5.155-0.512,10.829,0.56,15.333,3.118
      c4.505,2.558,7.82,6.819,10.023,11.508l-2.96,3.097c-1.209-4.875-3.91-9.119-8.1-11.889c-4.19-2.77-8.644-4.04-13.603-3.242
      c-1.14,0.183-2.751,0.609-3.697,1.271c-1.935,1.354-2.232,2.929-1.891,4.938c0.505,2.97,1.252,4.262,2.523,5.855
      c-2.179,0.051-2.688,0.063-4.866,0.114c-0.55-2.147-1.104-4.325-1.065-6.541C343.989,405.846,344.696,403.549,346.322,402.043z
      "/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g id="Books">
      <g>
      <g>
      <g>
      <rect x="232.787" y="446.886" style={{fill:'#FAFAFA'}} width="31.117" height="12.26"/>
      </g>
      <g>
      <rect x="231.491" y="444.781" style={{fill:'#455A64'}} width="97.727" height="2.568"/>
      </g>
      <g>
      <rect x="231.944" y="458.599" style={{fill:'#455A64'}} width="97.274" height="2.568"/>
      </g>
      <g>
      <polygon style={{fill:'#455A64'}} points="263.904,445.392 264.051,460.678 329.217,460.678 329.217,445.392 "/>
      </g>
      <g>
      <path style={{fill:'#263238'}} d="M279.742,453.02c0,2.101-1.577,3.804-3.522,3.804s-3.523-1.703-3.523-3.804
      c0-2.101,1.577-3.804,3.523-3.804S279.742,450.919,279.742,453.02z"/>
      <path style={{fill:'#E0E0E0'}} d="M276.219,457.37c-2.243,0-4.068-1.952-4.068-4.351c0-2.399,1.825-4.35,4.068-4.35
      c2.244,0,4.069,1.951,4.069,4.35C280.289,455.418,278.463,457.37,276.219,457.37z M276.219,449.763
      c-1.641,0-2.975,1.461-2.975,3.257c0,1.796,1.334,3.257,2.975,3.257c1.641,0,2.976-1.461,2.976-3.257
      C279.195,451.224,277.86,449.763,276.219,449.763z"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M261.566,456.141c0,0.157-6.394,0.285-14.279,0.285c-7.888,0-14.28-0.127-14.28-0.285
      c0-0.157,6.392-0.284,14.28-0.284C255.172,455.856,261.566,455.984,261.566,456.141z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M261.566,454.005c0,0.157-6.394,0.285-14.279,0.285c-7.888,0-14.28-0.127-14.28-0.285
      c0-0.157,6.392-0.285,14.28-0.285C255.172,453.72,261.566,453.848,261.566,454.005z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M261.566,451.869c0,0.157-6.394,0.285-14.279,0.285c-7.888,0-14.28-0.127-14.28-0.285
      c0-0.157,6.392-0.285,14.28-0.285C255.172,451.585,261.566,451.712,261.566,451.869z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M261.566,449.734c0,0.157-6.394,0.285-14.279,0.285c-7.888,0-14.28-0.128-14.28-0.285
      c0-0.157,6.392-0.285,14.28-0.285C255.172,449.449,261.566,449.577,261.566,449.734z"/>
      </g>
      </g>
      <g>
      <g>
      <rect x="292.625" y="448.643" style={{fill:'#FFFFFF'}} width="29.366" height="8.181"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M297.199,455.992c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.285,1.459,0.285,3.259C297.483,454.533,297.356,455.992,297.199,455.992z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M301.082,455.992c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.284,1.459,0.284,3.259C301.366,454.533,301.239,455.992,301.082,455.992z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M304.965,455.992c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.284,1.459,0.284,3.259C305.249,454.533,305.122,455.992,304.965,455.992z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M308.848,455.992c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.285,1.459,0.285,3.259C309.132,454.533,309.005,455.992,308.848,455.992z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M312.731,455.992c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.285,1.459,0.285,3.259C313.015,454.533,312.888,455.992,312.731,455.992z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M316.614,455.992c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.285,1.459,0.285,3.259C316.898,454.533,316.771,455.992,316.614,455.992z"/>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <rect x="236.921" y="462.821" style={{fill:'#FAFAFA'}} width="31.117" height="12.26"/>
      </g>
      <g>
      <rect x="235.625" y="460.716" style={{fill:theme.imageHighlight}} width="97.727" height="2.568"/>
      </g>
      <g>
      <rect x="236.078" y="474.534" style={{fill:theme.imageHighlight}} width="97.274" height="2.568"/>
      </g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="268.038,461.327 268.185,476.613 333.352,476.613 333.352,461.327 "/>
      </g>
      <g>
      <path style={{fill:'#263238'}} d="M283.876,468.954c0,2.101-1.577,3.804-3.522,3.804c-1.946,0-3.523-1.703-3.523-3.804
      c0-2.101,1.577-3.804,3.523-3.804C282.299,465.151,283.876,466.854,283.876,468.954z"/>
      <path style={{fill:'#E0E0E0'}} d="M280.354,473.305c-2.244,0-4.069-1.952-4.069-4.351c0-2.399,1.825-4.35,4.069-4.35
      c2.244,0,4.069,1.951,4.069,4.35C284.423,471.353,282.598,473.305,280.354,473.305z M280.354,465.697
      c-1.641,0-2.976,1.461-2.976,3.257c0,1.796,1.335,3.257,2.976,3.257c1.641,0,2.976-1.461,2.976-3.257
      C283.33,467.158,281.994,465.697,280.354,465.697z"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M265.7,472.075c0,0.157-6.394,0.285-14.279,0.285c-7.888,0-14.28-0.127-14.28-0.285
      c0-0.157,6.392-0.285,14.28-0.285C259.306,471.791,265.7,471.918,265.7,472.075z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M265.7,469.94c0,0.157-6.394,0.285-14.279,0.285c-7.888,0-14.28-0.128-14.28-0.285
      c0-0.157,6.392-0.285,14.28-0.285C259.306,469.655,265.7,469.783,265.7,469.94z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M265.7,467.804c0,0.157-6.394,0.285-14.279,0.285c-7.888,0-14.28-0.128-14.28-0.285
      c0-0.157,6.392-0.285,14.28-0.285C259.306,467.519,265.7,467.647,265.7,467.804z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M265.7,465.668c0,0.157-6.394,0.285-14.279,0.285c-7.888,0-14.28-0.127-14.28-0.285
      s6.392-0.285,14.28-0.285C259.306,465.384,265.7,465.511,265.7,465.668z"/>
      </g>
      </g>
      <g>
      <g>
      <rect x="296.759" y="464.578" style={{fill:'#FFFFFF'}} width="29.366" height="8.181"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M301.333,471.927c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.285,1.459,0.285,3.259C301.618,470.468,301.49,471.927,301.333,471.927z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M305.216,471.927c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.128-3.259,0.285-3.259
      s0.285,1.459,0.285,3.259C305.5,470.468,305.373,471.927,305.216,471.927z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M309.099,471.927c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.128-3.259,0.285-3.259
      c0.157,0,0.284,1.459,0.284,3.259C309.383,470.468,309.256,471.927,309.099,471.927z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M312.982,471.927c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.284,1.459,0.284,3.259C313.266,470.468,313.139,471.927,312.982,471.927z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M316.865,471.927c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.285,1.459,0.285,3.259C317.15,470.468,317.022,471.927,316.865,471.927z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M320.748,471.927c-0.157,0-0.285-1.459-0.285-3.259c0-1.8,0.127-3.259,0.285-3.259
      c0.157,0,0.285,1.459,0.285,3.259C321.033,470.468,320.905,471.927,320.748,471.927z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g id="Speech_Bubble">
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFFFFF'}} d="M204.48,168.872l-8.054-1.613c-5.414,3.038-11.805,4.51-18.537,3.816
      c-15.879-1.636-28.131-15.346-28.014-31.308c0.135-18.444,16.076-32.818,34.558-30.901
      c15.691,1.627,27.859,15.031,27.99,30.805c0.065,7.828-2.724,14.942-7.351,20.431l0.015-0.01L204.48,168.872z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M204.48,168.872c0,0,0.001-0.209,0.02-0.594c0.021-0.406,0.052-0.97,0.091-1.699
      c0.088-1.503,0.216-3.681,0.381-6.494l0.179,0.102c0,0-0.009,0.007-0.014,0.01
      c-0.052,0.036-0.123,0.024-0.16-0.028c-0.03-0.043-0.025-0.102,0.007-0.14c2.458-2.971,5.276-7.444,6.55-13.469
      c0.645-2.994,0.855-6.339,0.504-9.862c-0.381-3.517-1.418-7.199-3.218-10.731
      c-1.814-3.52-4.411-6.868-7.718-9.659c-3.302-2.779-7.309-5.059-11.808-6.229
      c-4.475-1.205-9.375-1.415-14.183-0.488c-4.82,0.896-9.486,3.117-13.445,6.328
      c-2.049,1.577-3.751,3.514-5.328,5.488c-0.713,1.043-1.444,2.075-2.068,3.158l-0.871,1.658l-0.43,0.827
      l-0.344,0.864c-1.93,4.563-2.662,9.462-2.387,14.134c0.252,4.689,1.734,9.105,3.837,12.916
      c2.12,3.817,4.938,7.03,8.071,9.498c3.146,2.46,6.588,4.186,9.985,5.235c0.862,0.221,1.709,0.439,2.542,0.653
      c0.837,0.194,1.681,0.278,2.499,0.42c1.64,0.256,3.247,0.304,4.779,0.281c6.169-0.127,11.08-2.052,14.421-3.887
      c0.023-0.013,0.052-0.017,0.077-0.012c2.56,0.541,4.552,0.962,5.93,1.254c0.666,0.145,1.183,0.257,1.558,0.338
      C204.289,168.82,204.48,168.872,204.48,168.872s-0.166-0.02-0.511-0.081c-0.37-0.068-0.882-0.162-1.542-0.282
      c-1.4-0.265-3.424-0.648-6.024-1.14l0.076-0.012c-3.34,1.893-8.281,3.883-14.521,4.055
      c-1.55,0.034-3.175-0.005-4.838-0.254c-0.829-0.138-1.685-0.219-2.534-0.411
      c-0.845-0.213-1.705-0.429-2.579-0.648c-3.45-1.045-6.949-2.782-10.152-5.266
      c-3.19-2.493-6.062-5.746-8.228-9.62c-2.148-3.867-3.664-8.356-3.93-13.128
      c-0.289-4.753,0.45-9.739,2.411-14.388l0.351-0.879l0.436-0.843c0.295-0.561,0.59-1.124,0.886-1.689
      c0.635-1.103,1.377-2.155,2.104-3.216c1.604-2.011,3.338-3.982,5.424-5.589c4.031-3.27,8.784-5.528,13.693-6.438
      c4.897-0.94,9.88-0.72,14.434,0.515c4.576,1.199,8.648,3.527,11.996,6.361c3.354,2.846,5.98,6.255,7.808,9.835
      c1.814,3.592,2.85,7.333,3.218,10.9c0.339,3.575,0.105,6.958-0.567,9.984c-1.33,6.088-4.219,10.579-6.732,13.54
      l-0.153-0.168c0.005-0.004,0.014-0.01,0.014-0.01c0.052-0.036,0.123-0.024,0.159,0.028
      c0.015,0.022,0.022,0.05,0.02,0.075c-0.225,2.84-0.4,5.039-0.52,6.556c-0.061,0.715-0.108,1.269-0.142,1.668
      C204.505,168.695,204.48,168.872,204.48,168.872z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="167.281,141.993 171.198,137.8 179.257,144.486 194.108,129.189 
      198.012,133.117 178.944,152.328 "/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g id="Character">
      <g>
      <g>
      <path style={{fill:theme.imageHighlight}} d="M421.714,116.304h-25.458l-74.288,44.71c0.278,0.418,9.047,6.759,20.5,14.948L234.891,374.04
      l-90.032-39.763L65.14,477.557h43.56l54.929-100.337l82.62,38.273l35.023,4.18l94.807-219.803
      c11.913,8.445,21.064,14.911,21.064,14.911h25.458c0,0-7.424-62.31-7.424-62.805
      C415.177,151.481,421.714,116.304,421.714,116.304z"/>
      <path style={{opacity:0.3}} d="M421.714,116.304h-25.458l-74.288,44.71c0.278,0.418,9.047,6.759,20.5,14.948L234.891,374.04
      l-90.032-39.763L65.14,477.557h43.56l54.929-100.337l82.62,38.273l35.023,4.18l94.807-219.803
      c11.913,8.445,21.064,14.911,21.064,14.911h25.458c0,0-7.424-62.31-7.424-62.805
      C415.177,151.481,421.714,116.304,421.714,116.304z"/>
      <polygon style={{fill:theme.imageHighlight}} points="90.913,477.557 170.951,333.05 260.663,374.04 376.359,161.014 410.806,179.111 
      281.272,419.673 189.402,377.22 134.473,477.557 "/>
      <path style={{fill:theme.imageHighlight}} d="M347.426,161.014c0.82,1.23,75.176,53.767,75.176,53.767l-0.887-98.477L347.426,161.014z"/>
      <g>
      <path style={{fill:'#263238'}} d="M347.426,161.014c0,0.144-5.699,0.26-12.728,0.26c-7.031,0-12.729-0.117-12.729-0.26
      s5.698-0.26,12.729-0.26C341.726,160.754,347.426,160.871,347.426,161.014z"/>
      </g>
      <g>
      <path style={{fill:'#263238'}} d="M367.314,176.378c0,0.144-5.699,0.26-12.728,0.26c-7.031,0-12.729-0.117-12.729-0.26
      c0-0.144,5.698-0.26,12.729-0.26C361.615,176.117,367.314,176.234,367.314,176.378z"/>
      </g>
      </g>
      <g>
      <g>
      <g>
      <path style={{fill:'#AE7461'}} d="M176.119,265.569c0,0-1.545,0.672-1.786-1.39c-0.242-2.063,4.057-6.357,5.432-7.721
      c1.375-1.364,3.34-1.071,5.084-0.682c1.744,0.389,8.881,1.108,11.249,2.628c2.368,1.52,5.993,9.145,6.749,9.507
      c0.756,0.362,23.022,1.522,23.022,1.522l-0.958,18.249c0,0-21.854-4.164-26.182-6.871c-4.328-2.707-3.76-3.785-4.99-6.31
      c-1.23-2.525-2.551-5.206-2.551-5.206l-2.046-0.339l-3.28,1.81c0,0-0.387,4.632-3.633,4.514l-1.591-0.838
      c0,0-1.061,1.227-2.27-0.67s-0.98-2.251-0.98-2.251s-1.273,1.574-2.648-0.378C173.365,269.191,176.119,265.569,176.119,265.569
      z"/>
      <g>
      <path style={{fill:'#6F4439'}} d="M200.107,273.316c0.003,0.014-0.079,0.04-0.241,0.076c-0.161,0.039-0.407,0.07-0.726,0.071
      c-0.634,0.006-1.595-0.15-2.62-0.745c-1.02-0.579-2.089-1.621-2.775-3.077c-0.707-1.446-1.053-3.226-1.151-5.113l0.353,0.215
      c-1.464,0.678-3.188,1.113-4.994,1.176c-1.267,0.048-2.51-0.093-3.669-0.386c-0.554-0.164-1.195-0.249-1.678-0.78
      c-0.258-0.252-0.318-0.777-0.057-1.075c0.238-0.295,0.539-0.4,0.787-0.506c2.004-0.812,3.847-1.444,5.409-1.921
      c1.563-0.478,2.845-0.8,3.735-1.003c0.891-0.2,1.39-0.281,1.398-0.247c0.009,0.037-0.474,0.184-1.346,0.443
      c-0.872,0.262-2.134,0.637-3.674,1.158c-1.54,0.519-3.358,1.183-5.339,2.014c-0.242,0.098-0.472,0.211-0.579,0.357
      c-0.101,0.143-0.095,0.3,0.045,0.458c0.291,0.34,0.87,0.461,1.424,0.608c1.115,0.274,2.309,0.407,3.527,0.36
      c1.736-0.061,3.393-0.471,4.805-1.11l0.337-0.153l0.016,0.368c0.081,1.849,0.394,3.566,1.04,4.955
      c0.627,1.4,1.6,2.398,2.551,2.991c0.952,0.602,1.854,0.8,2.464,0.846C199.761,273.34,200.101,273.276,200.107,273.316z"/>
      </g>
      <g>
      <path style={{fill:'#6F4439'}} d="M188.834,260.803c0.042,0.053-0.752,0.483-2.102,0.409c-0.663-0.007-1.444-0.12-2.27-0.064
      c-0.842,0.037-1.629,0.395-2.279,1.145c-1.353,1.511-2.971,2.51-4.19,3.019c-1.228,0.518-2.051,0.623-2.064,0.566
      c-0.023-0.081,0.756-0.313,1.908-0.9c1.152-0.586,2.646-1.569,3.971-3.046c0.35-0.362,0.74-0.732,1.218-0.942
      c0.466-0.222,0.958-0.302,1.409-0.322c0.907-0.031,1.676,0.124,2.32,0.176C188.044,261.007,188.804,260.728,188.834,260.803z"
      />
      </g>
      <g>
      <path style={{fill:'#6F4439'}} d="M184.246,265.665c0.122,0.041-0.779,2.093-2.838,3.845c-2.045,1.771-4.209,2.349-4.231,2.222
      c-0.062-0.149,1.937-0.92,3.892-2.617C183.042,267.441,184.108,265.582,184.246,265.665z"/>
      </g>
      <g>
      <path style={{fill:'#6F4439'}} d="M185.048,268.464c0.13,0.014-0.163,1.811-1.466,3.575c-1.288,1.776-2.912,2.599-2.965,2.479
      c-0.089-0.13,1.329-1.104,2.544-2.786C184.396,270.065,184.897,268.418,185.048,268.464z"/>
      </g>
      </g>
      <polygon style={{fill:'#E0E0E0'}} points="252.295,230.003 232.37,265.956 201.445,265.424 199.121,283.996 231.033,291.82 
      244.173,277.393 "/>
      <g>
      <path style={{fill:'#263238'}} d="M234.857,272.057c-0.154,0.014-0.347-1.464-1.037-3.134c-0.674-1.677-1.57-2.869-1.45-2.966
      c0.093-0.095,1.219,1.002,1.932,2.77C235.028,270.488,234.99,272.06,234.857,272.057z"/>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M259.872,166.367c-2.521,0.335-5.147-1.474-5.733-3.948
      c-0.587-2.475,0.949-5.269,3.352-6.101c-1.601-1.582-2.043-4.215-1.046-6.233c0.996-2.017,3.357-3.266,5.586-2.956
      c0.052-2.221,1.339-4.374,3.272-5.47c1.932-1.096,4.441-1.097,6.374-0.002c2.414-2.149,6.369-2.3,8.941-0.343
      c2.15-0.438,4.523,0.667,5.572,2.595c1.048,1.928,0.686,4.521-0.851,6.087"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#AE7461'}} d="M281.827,146.415l-1.14,0.022c-11.474,0.222-20.854,9.216-21.555,20.671
      c-0.652,10.654-1.235,22.433-1.054,28.138c0.375,11.813,12.229,13.422,12.229,13.422l-0.724,14.215l1.402,10.835
      l27.259-12.279l1.539-51.535C300.603,157.616,294.134,146.895,281.827,146.415z"/>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#6F4439'}} d="M270.209,208.653c0,0,7.554,0.308,15.395-4.831c0,0-3.662,9.149-15.473,8.314
      L270.209,208.653z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M262.968,179.152c-0.014,0.892,0.729,1.639,1.66,1.671
      c0.929,0.032,1.696-0.663,1.71-1.555c0.014-0.892-0.729-1.64-1.658-1.672
      C263.749,177.564,262.982,178.261,262.968,179.152z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M261.784,172.84c0.213,0.216,1.473-0.755,3.289-0.785
      c1.813-0.057,3.164,0.852,3.357,0.626c0.095-0.101-0.126-0.494-0.714-0.905c-0.579-0.41-1.562-0.8-2.693-0.773
      c-1.131,0.028-2.074,0.463-2.613,0.9C261.86,172.342,261.679,172.744,261.784,172.84z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M278.346,178.775c-0.014,0.891,0.729,1.639,1.66,1.67
      c0.929,0.032,1.696-0.663,1.71-1.555c0.014-0.891-0.729-1.64-1.659-1.672
      C279.127,177.187,278.36,177.883,278.346,178.775z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M279.639,172.833c0.214,0.216,1.475-0.755,3.29-0.785
      c1.813-0.056,3.164,0.852,3.357,0.626c0.095-0.1-0.124-0.493-0.715-0.904c-0.579-0.41-1.561-0.8-2.692-0.773
      c-1.131,0.028-2.074,0.463-2.613,0.9C279.718,172.335,279.535,172.737,279.639,172.833z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M273.31,186.428c0.007-0.104-1.126-0.277-2.959-0.475
      c-0.465-0.038-0.905-0.122-0.99-0.437c-0.116-0.333,0.069-0.836,0.283-1.38c0.414-1.13,0.848-2.315,1.303-3.557
      c1.807-5.064,3.105-9.224,2.901-9.295c-0.205-0.071-1.835,3.976-3.642,9.04c-0.434,1.248-0.85,2.439-1.245,3.577
      c-0.165,0.531-0.455,1.134-0.205,1.824c0.132,0.344,0.477,0.589,0.775,0.663c0.299,0.085,0.56,0.085,0.79,0.094
      C272.163,186.544,273.305,186.53,273.31,186.428z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M277.929,165.877c0.248,0.456,1.795-0.074,3.71-0.173
      c1.912-0.142,3.534,0.188,3.7-0.294c0.071-0.229-0.277-0.636-0.975-0.992c-0.692-0.355-1.739-0.62-2.887-0.549
      c-1.147,0.073-2.136,0.466-2.758,0.905C278.092,165.214,277.818,165.66,277.929,165.877z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M261.551,165.355c0.391,0.405,1.881-0.052,3.683-0.107
      c1.8-0.108,3.334,0.213,3.676-0.225c0.15-0.212-0.098-0.621-0.772-0.998c-0.664-0.376-1.77-0.673-3.008-0.618
      c-1.237,0.057-2.304,0.451-2.922,0.886C261.579,164.728,261.378,165.157,261.551,165.355z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M260.164,161.667c0,0,2.973-7.46,6.508-9.256c4.279-2.174,6.231,2.531,10.325,1.301
      c4.094-1.23,8.179-3.316,12.448-0.215c4.672,3.394,2.562,5.879,2.948,11.117c1.565,21.234,5.563,27.169,6.474,17.136
      c0.1-1.099,3.127-2.031,3.488-6.674c0.468-6.021,0.558-13.322-0.866-17.561c-1.2-3.573-3.298-6.016-7.026-8.683
      c-5.885-4.21-13.856-4.148-19.257-3.499C269.804,145.982,259.491,152.412,260.164,161.667z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#AE7461'}} d="M298.106,181.442c0.039-1.825,1.583-3.296,3.403-3.158
      c2.208,0.167,4.684,1.371,4.627,5.927c-0.102,8.083-8.196,6.314-8.207,6.082
      C297.922,190.136,298.034,184.824,298.106,181.442z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#6F4439'}} d="M299.726,187.274c0.036-0.025,0.14,0.101,0.376,0.212
      c0.23,0.109,0.629,0.188,1.052,0.035c0.858-0.303,1.594-1.649,1.651-3.106c0.03-0.735-0.116-1.438-0.369-2.021
      c-0.239-0.595-0.616-1.012-1.045-1.094c-0.427-0.104-0.734,0.149-0.836,0.375c-0.111,0.225-0.062,0.387-0.105,0.4
      c-0.023,0.02-0.172-0.138-0.103-0.471c0.035-0.16,0.133-0.35,0.324-0.502c0.196-0.155,0.482-0.232,0.781-0.196
      c0.623,0.051,1.177,0.631,1.455,1.271c0.313,0.644,0.493,1.433,0.46,2.259c-0.072,1.629-0.925,3.142-2.098,3.465
      c-0.57,0.141-1.042-0.036-1.285-0.23C299.738,187.47,299.698,187.289,299.726,187.274z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M297.645,165.794c-1.035,0.736-2.129,1.497-3.391,1.646
      c-1.262,0.15-2.721-0.548-2.959-1.797c-0.107-0.561,0.018-1.216-0.36-1.643c-0.451-0.51-1.262-0.332-1.938-0.247
      c-2.344,0.295-5.138-2.246-5.781-4.52c-0.581,0.909-1.888,1.205-2.806,0.638c-0.918-0.567-1.255-0.894-1.98-1.692
      c-0.725-0.799-1.735-1.506-2.8-1.334c-0.596,0.096-1.148,0.463-1.749,0.421c-1.063-0.074-1.605-1.306-1.786-2.356
      c-0.181-1.05-0.33-2.276-1.239-2.831c-0.843-0.514-1.916-0.187-2.89-0.024c-0.974,0.163-2.239-0.042-2.508-0.992
      c-0.141-0.498,0.064-1.03,0.333-1.472c1.219-2.005,3.945-2.938,6.138-2.1c0.738-2.685,3.195-4.811,5.958-5.156
      c2.763-0.346,5.667,1.109,7.045,3.529c1.677-1.582,4.146-2.28,6.403-1.811c2.258,0.469,4.243,2.093,5.152,4.212
      c1.955-0.555,4.21,0.162,5.486,1.743c1.276,1.582,1.499,3.937,0.543,5.73c1.468,0.801,2.739,2.22,2.802,3.892
      c0.063,1.671-1.534,3.369-3.156,2.96c0.677,1.289,0.181,3.066-1.066,3.818c-1.248,0.752-3.051,0.361-3.875-0.84"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M271.376,149.881c-0.007,0.001-0.005,0.051,0.005,0.148
      c0.013,0.097,0.021,0.244,0.06,0.43c0.081,0.372,0.242,0.921,0.617,1.545c0.377,0.615,0.956,1.322,1.828,1.875
      c0.855,0.561,2.021,0.976,3.327,0.88l-0.14-0.176c-0.237,0.696-0.153,1.524,0.205,2.227
      c0.34,0.716,0.866,1.346,1.421,1.96c0.284,0.294,0.532,0.642,0.922,0.91c0.379,0.257,0.83,0.401,1.289,0.45
      c0.913,0.099,1.936-0.203,2.582-0.982l-0.304-0.081c0.237,1.602,1.181,3.191,2.651,4.143
      c0.779,0.54,1.736,0.861,2.67,0.818c0.935-0.027,1.799-0.318,2.603-0.625l-0.218-0.091
      c0.454,0.996,1.137,1.839,1.96,2.456c0.807,0.616,1.875,0.966,2.829,0.712c1.899-0.44,3.133-2.051,3.359-3.549
      l-0.181,0.089c0.956,0.497,1.972,0.527,2.784,0.354c0.823-0.167,1.464-0.538,1.919-0.901
      c0.457-0.37,0.724-0.745,0.883-1.005c0.146-0.269,0.206-0.418,0.197-0.422c-0.041-0.033-0.256,0.619-1.164,1.319
      c-0.453,0.335-1.081,0.675-1.873,0.816c-0.782,0.149-1.745,0.098-2.628-0.379l-0.153-0.083l-0.029,0.172
      c-0.229,1.398-1.418,2.901-3.18,3.289c-0.865,0.221-1.803-0.093-2.569-0.675c-0.769-0.582-1.421-1.395-1.842-2.331
      l-0.067-0.148l-0.151,0.057c-0.792,0.299-1.626,0.572-2.49,0.595c-0.864,0.035-1.723-0.25-2.465-0.762
      c-1.389-0.9-2.275-2.387-2.507-3.903l-0.058-0.381l-0.246,0.301c-0.545,0.667-1.46,0.954-2.28,0.867
      c-0.412-0.042-0.812-0.168-1.143-0.39c-0.322-0.212-0.581-0.556-0.869-0.852c-0.555-0.603-1.066-1.209-1.389-1.875
      c-0.34-0.658-0.417-1.385-0.215-2.015l0.062-0.194l-0.203,0.018c-1.23,0.107-2.353-0.268-3.188-0.793
      c-0.852-0.517-1.43-1.187-1.815-1.773C271.426,150.717,271.433,149.861,271.376,149.881z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M273.827,192.633c0.04-0.302,2.035,0.074,3.776-1.362
      c1.746-1.433,1.928-3.603,2.209-3.565c0.131-0.004,0.297,0.548,0.15,1.45c-0.138,0.886-0.666,2.118-1.731,2.997
      c-1.067,0.876-2.311,1.102-3.142,1.014C274.24,193.084,273.789,192.767,273.827,192.633z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M216.241,307.021l15.386-16.97l12.867-38.885c2.627-6.746,4.882-15.311,8.064-21.708
      c1.566-3.146,4.204-5.627,7.415-7.054l5.463-2.426l3.267-5.214c-1.882,11.793,14.646,13.245,32.212-1.396
      c0,0,5.803,3.926,13.982,10.709c3.581,2.97,6.828,6.215,8.182,11.117c2.457,8.9-8.104,35.239-19.603,60.017
      c-3.44,7.412-19.823,40.624-19.823,40.624l-5.897,4.297c0,0-52.135-15.796-56.825-24.453
      C216.241,307.021,216.241,307.021,216.241,307.021z"/>
      <g>
      <path style={{fill:'#263238'}} d="M224.777,296.903c-0.012-0.013,0.138-0.187,0.436-0.509c0.338-0.356,0.778-0.818,1.325-1.393
      c1.21-1.239,2.893-2.96,4.961-5.078l-0.044,0.071c0.963-2.964,2.115-6.505,3.396-10.449c1.325-3.948,2.668-8.331,4.025-12.965
      c2.628-9.086,5.115-17.304,7.412-23.078c1.114-2.898,2.16-5.185,2.894-6.757c0.37-0.738,0.668-1.331,0.896-1.786
      c0.21-0.406,0.328-0.615,0.344-0.607s-0.07,0.232-0.249,0.652c-0.206,0.465-0.473,1.071-0.806,1.825
      c-0.676,1.595-1.667,3.897-2.734,6.804c-2.202,5.791-4.626,14.001-7.257,23.094c-1.357,4.633-2.721,9.033-4.071,12.968
      c-1.323,3.93-2.512,7.46-3.507,10.413l-0.014,0.042l-0.03,0.029c-2.117,2.069-3.838,3.752-5.077,4.962
      c-0.575,0.547-1.037,0.987-1.393,1.325C224.964,296.765,224.789,296.915,224.777,296.903z"/>
      </g>
      <g>
      <g>
      <polygon style={{fill:theme.imageHighlight}} points="272.643,232.143 254.612,306.064 235.883,315.378 235.883,297.295 268.293,230.536 
      "/>
      </g>
      </g>
      <g style={{opacity:0.3}}>
      <path d="M238.156,299.409c5.112,1.923,9.716,4.967,14.232,8.041c4.515,3.074,9.029,6.225,14.039,8.402
      c5.01,2.177,10.624,3.337,15.973,2.234c5.35-1.103,10.346-4.757,12.109-9.927L238.156,299.409z"/>
      </g>
      <polygon style={{fill:'#E0E0E0'}} points="265.437,219.748 270.046,212.691 271.855,222.36 276.448,217.163 299.237,206.503 
      303.192,214.974 278.013,229.864 271.03,229.344 265.938,223.301 "/>
      <g>
      <path style={{fill:'#263238'}} d="M265.437,219.749c0.059-0.006,0.159,0.538,0.345,1.458c0.229,1.103,0.487,2.348,0.773,3.727
      l-0.456-0.068c0.391-0.716,0.807-1.478,1.241-2.271c0.688-1.217,1.333-2.359,1.92-3.398l0.246-0.436l0.163,0.464
      c0.404,1.152,0.619,2.124,0.801,2.799c0.171,0.676,0.278,1.061,0.232,1.08c-0.044,0.018-0.237-0.341-0.484-1
      c-0.256-0.654-0.539-1.621-0.97-2.716l0.409,0.028c-0.568,1.049-1.194,2.202-1.86,3.431c-0.442,0.789-0.867,1.546-1.266,2.257
      l-0.327,0.583l-0.129-0.651C265.468,221.985,265.313,219.759,265.437,219.749z"/>
      </g>
      <path style={{fill:theme.imageHighlight}} d="M273.106,223.313l-2.901-0.354c-0.786,0.883-2.708,2.702-3.494,3.586l0.006,0.081
      c0.343,1.935,0.687,3.869,1.03,5.804c1.621-0.079,3.253,0.052,4.841,0.389c0.968-1.699,1.699-2.98,2.667-4.679L273.106,223.313z
      "/>
      <g>
      <path style={{fill:'#263238'}} d="M278.058,332.349c-0.06,0.053-0.912-0.856-2.34-2.267c-1.427-1.411-3.456-3.301-5.842-5.216
      c-2.39-1.911-4.677-3.479-6.366-4.563c-1.688-1.086-2.761-1.718-2.723-1.788c0.017-0.032,0.305,0.09,0.813,0.337
      c0.509,0.244,1.229,0.631,2.102,1.137c1.748,1.01,4.089,2.543,6.499,4.471c2.406,1.931,4.414,3.881,5.781,5.366
      c0.685,0.742,1.219,1.36,1.569,1.803C277.903,332.071,278.085,332.326,278.058,332.349z"/>
      </g>
      <polygon style={{fill:theme.imageHighlight}} points="273.333,223.909 276.553,222.323 277.645,227.961 274.962,228.199 "/>
      <polygon style={{opacity:0.3}} points="276.114,220.052 276.114,232.82 299.852,217.141 277.761,230.003 "/>
      <g>
      <path style={{fill:'#263238'}} d="M303.192,214.974c0.02,0.03-0.471,0.399-1.38,1.042c-0.917,0.63-2.259,1.52-3.965,2.545
      c-3.391,2.085-8.258,4.637-13.504,7.651c-2.217,1.286-4.309,2.579-6.191,3.855l-0.312,0.211l-0.068-0.367
      c-0.72-3.899-1.345-7.282-1.815-9.828l0.267,0.084c-1.027,0.957-1.863,1.737-2.505,2.335c-0.588,0.535-0.914,0.812-0.935,0.79
      c-0.021-0.021,0.265-0.34,0.815-0.913c0.615-0.625,1.417-1.44,2.402-2.441l0.208-0.211l0.06,0.295
      c0.514,2.538,1.197,5.91,1.984,9.796l-0.38-0.156c1.884-1.3,3.983-2.609,6.207-3.9c5.266-3.024,10.15-5.52,13.579-7.517
      c1.724-0.982,3.092-1.822,4.041-2.398C302.642,215.257,303.172,214.945,303.192,214.974z"/>
      </g>
      <polygon style={{fill:theme.imageHighlight}} points="270.24,224.039 268.909,220.779 266.712,224.816 267.653,226.279 "/>
      <g>
      <path style={{fill:'#263238'}} d="M273.88,223.567c0.112,0.104-1.477,1.941-3.185,4.387c-1.718,2.44-2.903,4.56-3.038,4.49
      c-0.122-0.057,0.869-2.313,2.612-4.789C272.002,225.173,273.784,223.472,273.88,223.567z"/>
      </g>
      <g>
      <path style={{fill:'#263238'}} d="M272.268,232.916c-0.037,0.142-1.126-0.022-2.457-0.244c-1.331-0.226-2.414-0.428-2.402-0.574
      c0.01-0.141,1.132-0.169,2.488,0.061C271.254,232.385,272.304,232.78,272.268,232.916z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M152.605,278.895L152.605,278.895l-15.022-42.459c-0.287-0.915,0.273-1.88,1.21-2.084
      l34.7-9.064c0.866-0.191,1.736,0.304,2.014,1.146l15.788,47.83l48.967,32.163l-2.353,2.402l-12.527-3.053L152.605,278.895z"/>
      <g>
      <path style={{fill:'#263238'}} d="M237.91,308.829c-0.005,0.007-0.091-0.04-0.253-0.139c-0.186-0.117-0.421-0.266-0.714-0.452
      c-0.653-0.427-1.562-1.022-2.717-1.778c-2.38-1.584-5.767-3.84-9.939-6.618c-8.392-5.649-19.933-13.417-32.681-21.998
      c-0.888-0.6-1.771-1.197-2.593-1.753l-0.073-0.05l-0.028-0.083c-4.612-13.915-8.743-26.38-11.736-35.409
      c-1.459-4.478-2.64-8.102-3.469-10.647c-0.389-1.229-0.695-2.196-0.916-2.892c-0.092-0.309-0.167-0.557-0.226-0.755
      c-0.048-0.171-0.069-0.26-0.061-0.262c0.008-0.003,0.045,0.081,0.109,0.246c0.072,0.193,0.161,0.437,0.273,0.739
      c0.242,0.689,0.578,1.646,1.005,2.862c0.866,2.532,2.099,6.139,3.623,10.596c3.032,9.016,7.217,21.463,11.889,35.358
      l-0.101-0.133c0.823,0.556,1.705,1.152,2.594,1.753c12.715,8.63,24.226,16.443,32.596,22.124
      c4.133,2.836,7.489,5.138,9.847,6.755c1.132,0.79,2.023,1.412,2.662,1.859c0.281,0.203,0.507,0.366,0.685,0.494
      C237.84,308.759,237.915,308.821,237.91,308.829z"/>
      </g>
      <g>
      <path style={{fill:'#263238'}} d="M158.594,277.989c-0.007-0.144,6.817-0.583,15.24-0.981c8.426-0.398,15.26-0.604,15.266-0.461
      c0.007,0.144-6.816,0.583-15.242,0.981C165.436,277.926,158.601,278.132,158.594,277.989z"/>
      </g>
      </g>
      <path style={{fill:'#E0E0E0'}} d="M164.126,253.753c0.605,1.926,0.265,3.747-0.759,4.069c-1.024,0.322-2.344-0.979-2.949-2.905
      c-0.605-1.926-0.265-3.747,0.759-4.069C162.201,250.527,163.521,251.827,164.126,253.753z"/>
      </g>
      <g>
      <g>
      <path style={{fill:'#AE7461'}} d="M206.884,268.894c0,0-1.296,0.644-1.498-1.423c-0.179-1.834,4.222-4.549,5.869-5.567
      c1.647-1.019,5.121-0.318,6.893-0.088c2.394,0.311,7.971,2.808,9.744,4.935c1.801,2.161,3.036,7.688,3.691,8.211
      c0.655,0.523,22.827,9.246,22.827,9.246l-5.047,17.564c0,0-20.353-8.984-23.959-12.597c-3.606-3.613-2.81-4.535-3.439-7.273
      c-0.629-2.737-1.312-5.647-1.312-5.647l-1.916-0.792l-3.604,1.024c0,0-0.44,1.369-1.369,2.438
      c-1.109,1.277-3.118,1.221-4.234-0.05l0,0c0,0-1.829,0.636-2.721-1.429c-0.365-0.846-0.102-2.133-0.102-2.133
      s-1.639,0.322-1.652-1.72C205.042,271.401,206.884,268.894,206.884,268.894z"/>
      <g>
      <path style={{fill:'#6F4439'}} d="M218.429,271.218c-0.003,0.086-0.744,0.059-1.885,0.309c-1.132,0.241-2.674,0.834-4.001,2.001
      c-1.326,1.169-2.107,2.625-2.489,3.718c-0.391,1.1-0.458,1.839-0.543,1.831c-0.057,0-0.124-0.762,0.192-1.941
      c0.306-1.168,1.081-2.75,2.496-3.998c1.416-1.247,3.082-1.817,4.279-1.973C217.689,270.999,218.437,271.162,218.429,271.218z"
      />
      </g>
      <g>
      <path style={{fill:'#6F4439'}} d="M216.771,267.451c-0.009,0.093-0.807-0.041-2.028,0.243c-1.215,0.267-2.813,1.05-4.177,2.389
      c-1.365,1.346-2.293,2.855-2.967,3.934c-0.668,1.088-1.089,1.764-1.155,1.728c-0.06-0.033,0.243-0.767,0.835-1.911
      c0.598-1.132,1.494-2.713,2.924-4.124c1.435-1.411,3.168-2.193,4.471-2.378C215.987,267.129,216.789,267.404,216.771,267.451z
      "/>
      </g>
      <g>
      <path style={{fill:'#6F4439'}} d="M216.449,263.332c-0.01,0.087-0.698-0.007-1.764,0.19c-1.064,0.184-2.486,0.734-3.821,1.714
      c-1.338,0.983-2.368,2.113-3.122,2.919c-0.75,0.813-1.228,1.312-1.285,1.264c-0.053-0.044,0.321-0.622,1.005-1.503
      c0.688-0.872,1.697-2.072,3.095-3.1c1.401-1.03,2.941-1.561,4.076-1.658C215.776,263.047,216.464,263.278,216.449,263.332z"/>
      </g>
      </g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M311.073,223.443c6.233,1.218,10.291,6.695,11.559,13.544
      c1.799,9.714-20.471,55.626-24.621,65.649c-4.372,10.56-14.044,12.868-19.676,12.737c-5.632-0.13-45.098-18.641-45.098-18.641
      l5.786-20.028l35.068,10.411l13.897-39.1"/>
      <g>
      <path style={{fill:'#263238'}} d="M287.989,248.016c0,0-0.065,0.24-0.219,0.698c-0.162,0.474-0.393,1.15-0.693,2.031
      c-0.627,1.799-1.541,4.421-2.716,7.794c-2.387,6.777-5.833,16.557-10.089,28.64l-0.06,0.169l-0.173-0.051
      c-10.078-2.978-21.99-6.497-35.082-10.365l0.296-0.162c-1.845,6.404-3.775,13.103-5.772,20.032l-0.135-0.298
      c9.211,4.277,19.07,8.765,29.347,13.09c4.174,1.731,8.31,3.456,12.488,4.787l1.572,0.451c0.523,0.136,1.042,0.181,1.562,0.28
      c1.076,0.017,2.156-0.035,3.218-0.18c2.13-0.263,4.195-0.869,6.114-1.733c3.842-1.732,7.022-4.69,8.955-8.262
      c0.981-1.765,1.7-3.679,2.532-5.51c0.824-1.832,1.638-3.642,2.442-5.431c6.479-14.279,12.223-27.187,16.342-38.302
      c2.027-5.557,3.783-10.659,4.497-15.298c0.15-1.157,0.243-2.292,0.086-3.352c-0.207-1.067-0.445-2.09-0.769-3.039
      c-0.623-1.907-1.456-3.56-2.397-4.913c-1.889-2.732-4.172-4.128-5.747-4.813c-0.799-0.34-1.433-0.548-1.867-0.65
      c-0.432-0.114-0.651-0.186-0.651-0.186s0.226,0.036,0.664,0.134c0.44,0.086,1.083,0.277,1.895,0.605
      c1.601,0.659,3.932,2.044,5.871,4.793c0.965,1.361,1.822,3.028,2.466,4.952c0.335,0.959,0.582,1.99,0.799,3.069
      c0.173,1.113,0.082,2.265-0.063,3.44c-0.695,4.695-2.432,9.808-4.44,15.385c-4.082,11.149-9.795,24.081-16.251,38.372
      c-0.801,1.789-1.612,3.6-2.432,5.431c-0.827,1.823-1.536,3.729-2.546,5.555c-1.972,3.657-5.241,6.707-9.178,8.484
      c-1.967,0.887-4.08,1.508-6.258,1.779c-1.088,0.149-2.191,0.202-3.299,0.185c-0.555-0.098-1.134-0.153-1.672-0.294
      l-1.6-0.458c-4.223-1.344-8.354-3.067-12.537-4.802c-10.29-4.33-20.147-8.824-29.361-13.113l-0.195-0.091l0.06-0.208
      c2.006-6.926,3.945-13.622,5.799-20.024l0.067-0.231l0.23,0.069c13.082,3.903,24.983,7.453,35.054,10.458l-0.233,0.118
      c4.324-12.059,7.824-21.82,10.249-28.583c1.217-3.358,2.163-5.969,2.812-7.76c0.323-0.873,0.57-1.543,0.744-2.013
      C287.888,248.243,287.989,248.016,287.989,248.016z"/>
      </g>
      </g>
      <g>
      <path style={{fill:'#263238'}} d="M279.689,301.806c-0.162,0.029-0.699-3.488-2.262-7.549c-1.536-4.073-3.475-7.055-3.336-7.141
      c0.054-0.036,0.608,0.664,1.36,1.894c0.752,1.228,1.676,3.003,2.462,5.062c0.783,2.059,1.274,3.999,1.53,5.416
      C279.702,300.905,279.754,301.797,279.689,301.806z"/>
      </g>
      <g>
      <path style={{fill:'#263238'}} d="M308.646,223.529c0.031,0.091-1.291,0.516-3.273,1.518c-0.994,0.494-2.133,1.169-3.343,1.994
      c-1.196,0.845-2.456,1.853-3.671,3.028c-1.204,1.188-2.24,2.425-3.113,3.601c-0.853,1.191-1.554,2.313-2.071,3.296
      c-1.047,1.959-1.503,3.27-1.593,3.237c-0.034-0.012,0.056-0.346,0.25-0.944c0.184-0.601,0.527-1.447,1.015-2.461
      c0.482-1.018,1.161-2.18,2.008-3.408c0.867-1.213,1.913-2.483,3.139-3.694c1.238-1.198,2.533-2.214,3.765-3.052
      c1.247-0.818,2.424-1.471,3.453-1.929c1.025-0.465,1.878-0.788,2.484-0.958C308.299,223.577,308.635,223.494,308.646,223.529z
      "/>
      </g>
      </g>
      </g>
      <g>
      <path style={{fill:'#263238'}} d="M89.841,367.569l-18.952-23.685c0,0,73.313-60.398,80.244-64.712
      c5.249-3.267,15.918-7.605,26.019-4.211c10.1,3.394,27.198,15.207,33.501,19.039c6.303,3.832,61.173,37.818,67.104,46.132
      c0,0-11.939,21.597-19.757,25.218c-6.123,2.836-23.775-1.398-34.088-7.668c-10.314-6.27-57.632-46.794-57.632-46.794
      L89.841,367.569z"/>
      <path style={{fill:'#263238'}} d="M96.902,395.335l25.604,18.392l49.273-80.298l49.75,22.669l-33.453-55.893
      c0,0-26.98,0.317-31.221-0.16c-5.44-0.612-15.372,7.975-20.794,18.458C131.349,327.614,96.902,395.335,96.902,395.335z"/>
      <g>
      <path style={{fill:'#455A64'}} d="M205.425,349.479c-0.103,0.1-11.339-11.362-25.091-25.598
      c-13.757-14.241-24.822-25.863-24.719-25.963c0.103-0.1,11.336,11.36,25.093,25.601
      C194.461,337.755,205.529,349.38,205.425,349.479z"/>
      </g>
      <path style={{opacity:0.3}} d="M111.287,351.666c0,0,22.046-41.205,32.43-43.948c0,0-15.022,20.357-20.05,34.768L111.287,351.666
      z"/>
      <g>
      <path style={{fill:'#455A64'}} d="M160.309,302.797c-0.019,0.07-1-0.395-2.895-0.553c-1.877-0.183-4.698,0.142-7.742,1.647
      c-3.063,1.472-6.078,4.3-8.731,7.919c-2.639,3.641-5.289,7.85-7.823,12.387c-5.001,9.133-8.858,17.761-11.935,23.863
      c-1.512,3.065-2.805,5.511-3.709,7.195c-0.448,0.799-0.806,1.438-1.076,1.92c-0.251,0.436-0.391,0.661-0.407,0.652
      c-0.016-0.009,0.093-0.25,0.314-0.702c0.248-0.493,0.577-1.147,0.989-1.964c0.848-1.711,2.089-4.179,3.557-7.262
      c2.989-6.138,6.792-14.789,11.81-23.954c2.548-4.561,5.211-8.765,7.894-12.422c2.697-3.638,5.803-6.502,8.954-7.962
      c3.126-1.493,6.019-1.767,7.922-1.518C159.355,302.267,160.309,302.797,160.309,302.797z"/>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M109.467,380.418l-14.025,25.846c0,0-31.267-3.694-34.045,1.007l50.807,25.976
      l21.087-40.73L109.467,380.418z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M112.278,412.095c2.069,1.868-0.69,5.167-2.848,3.35
      c-2.304-2.079,0.986-5.435,3.004-3.19"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M112.204,433.247l1.965-4.189l-49.448-23.339c0,0-2.765-0.172-3.324,1.552
      L112.204,433.247z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFFFFF'}} d="M94.751,405.584c-0.119,0.252,1.064,0.999,1.877,2.421
      c0.85,1.401,0.93,2.797,1.208,2.816C99.255,409.959,96.185,404.758,94.751,405.584z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFFFFF'}} d="M88.295,405.064c-0.18,0.212,0.679,1.183,0.985,2.663
      c0.349,1.471-0.018,2.714,0.235,2.829C91.144,410.223,89.906,404.673,88.295,405.064z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFFFFF'}} d="M82.007,409.894c1.37,0.177,1.574-5.323,0.198-5.244
      C82.271,404.818,82.081,409.736,82.007,409.894z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFFFFF'}} d="M98.26,399.825c0.179-0.009,5.039,2.849,5.114,2.998
      C104.156,401.659,98.889,398.574,98.26,399.825z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#263238'}} d="M90.392,334.348l-24.033,16.945c0,0-26.385-17.178-30.96-14.197l34.016,45.815
      l36.048-28.38L90.392,334.348z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#E0E0E0'}} d="M78.863,363.985c1.026,2.592-2.91,4.325-4.038,1.739
      c-1.143-2.885,3.294-4.433,4.107-1.526"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#455A64'}} d="M69.415,382.911l3.619-2.883l-33.968-42.849c0,0-2.402-1.381-3.667-0.083
      L69.415,382.911z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFFFFF'}} d="M66.041,350.377c-0.218,0.173,0.511,1.368,0.609,3.002
      c0.141,1.633-0.407,2.919-0.166,3.06C68.138,356.296,67.693,350.272,66.041,350.377z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFFFFF'}} d="M60.486,347.047c-0.255,0.11,0.084,1.361-0.298,2.824
      c-0.339,1.473-1.22,2.425-1.044,2.64C60.751,352.934,62.103,347.411,60.486,347.047z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <g>
      <path style={{fill:'#FFFFFF'}} d="M52.708,348.588c1.149,0.767,3.771-4.073,2.503-4.613
      C55.195,344.155,52.844,348.478,52.708,348.588z"/>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      <path style={{opacity:0.3}} d="M201.662,345.584c0,0-28.02-21.33-34.701-29.552c-6.591-8.111-10.105-15.987-10.105-15.987
      L201.662,345.584z"/>
      </g>
      </g>
      </g>
      </g>
      </svg>
      
      
      
    );
  }
}
