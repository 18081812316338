import React, { Component } from "react";

export default class VlsiImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
	<svg
        id="1c51936f-ad83-4493-a1e8-5a7ca1846cda"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 3710 3710" x="0px" y="0px"
        width="864.81"
        height="658.45"
      >
<g id="Background">
	<g>
		<g>
			<rect style={{fill:'transparent'}} width="3710" height="3710"/>
		</g>
	</g>
</g>
<g id="Illustration">
	<g>
		<circle style={{fill:'#F4F7FA'}} cx="1832.194" cy="1855" r="1400"/>
		<g>
			<g>
				<path style={{fill:'#97A6B7'}} d="M1950.083,3001.284c-31.268-10.371-54.638-36.908-61.229-69.524l-45.51-225.242l-389.303,13.824
					l-47.912,234.651c-6.71,32.863,20.151,65.832,51.086,77.936l162.876,63.727c30.294,11.853,62.857,16.574,95.222,13.805
					l344.215-29.447c10.063-0.861,18.134-8.77,19.305-18.918c1.171-10.147,8.221-21.542-1.374-24.724L1950.083,3001.284z"/>
				<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1750.4595" y1="2794.4053" x2="1750.4595" y2="3011.4775">
					<stop  offset="0.494" style={{stopColor:'#EBEFF2'}}/>
					<stop  offset="1" style={{stopColor:'#FFFFFF'}}/>
				</linearGradient>
				<path style={{fill:'url(#SVGID_1_)'}} d="M1953.935,2987.585c-31.236-10.241-54.583-36.445-61.167-68.651l-45.464-222.41
					l-379.813,13.665l-47.864,231.701c-6.703,32.449,11.033,64.99,41.937,76.941l162.712,62.926
					c30.264,11.704,62.794,16.366,95.127,13.632l343.868-29.077c10.052-0.85,18.115-8.66,19.285-18.68
					c1.17-10.02-4.877-19.478-14.463-22.621L1953.935,2987.585z"/>
			</g>
			<g>
				<g>
					<path style={{fill:'#97A6B7'}} d="M658.555,2856.224c-42.217,2.517-76.755-24.871-76.755-61.065V1490.648
						c0-36.194,34.538-64.144,76.755-62.315l1960.843,135.748c30.995,1.343,47.625-23.678,47.625,7.104v1109.478
						c0,30.783-25.097,57.323-56.092,59.171L658.555,2856.224z"/>
					<path style={{fill:'#FFFFFF'}} d="M676.101,2856.224c-42.217,2.517-76.755-24.871-76.755-61.065V1490.648
						c0-36.194,34.538-64.144,76.755-62.315l1952.377,84.595c30.995,1.343,56.092,27.475,56.092,58.257v1109.478
						c0,30.783-25.097,57.323-56.092,59.171L676.101,2856.224z"/>
					<path style={{opacity:0.3,fill:theme.jacketColor}} d="M2628.478,1539.038l-1952.376-80.317c-22.57-0.928-40.919,13.984-40.919,33.311
						v1014.566v286.593c0,19.327,18.35,33.94,40.919,32.645l1952.376-112.114c16.613-0.954,30.047-15.114,30.047-31.63v-253.623
						v-858.29C2658.524,1553.664,2645.091,1539.722,2628.478,1539.038z"/>
				</g>
				<g>
					
						<linearGradient id="SVGID_00000106835127194584108970000015747529088950742405_" gradientUnits="userSpaceOnUse" x1="1155.4949" y1="1222.6432" x2="2078.2573" y2="2915.1021">
						<stop  offset="0" style={{stopColor:theme.dark}}/>
						<stop  offset="0.9964" style={{stopColor:theme.dark}}/>
					</linearGradient>
					<path style={{fill:'url(#SVGID_00000106835127194584108970000015747529088950742405_)'}} d="M2613.97,2619.843l-1916.709,94.614
						c-20.118,0.993-36.468-12.305-36.468-29.706v-1175.63c0-17.4,16.35-30.899,36.468-30.152l1916.709,71.146
						c15.042,0.559,27.209,13.165,27.209,28.16V2591.35C2641.18,2606.344,2629.012,2619.101,2613.97,2619.843z"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,1603.125 737.679,1590.25 737.679,1548.515 1175.411,1562.672 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1597.642 1280.434,1594.582 1280.434,1566.069 
						1381.554,1569.339 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1636.967 1280.434,1634.199 1280.434,1605.686 
						1381.554,1608.664 					"/>
					
						<linearGradient id="SVGID_00000092446123678367467210000000276154111166366373_" gradientUnits="userSpaceOnUse" x1="1647.5763" y1="1377.2799" x2="1813.218" y2="2386.9048">
						<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
						<stop  offset="1" style={{stopColor:theme.text}}/>
					</linearGradient>
					<polygon style={{fill:'url(#SVGID_00000092446123678367467210000000276154111166366373_)'}} points="1885.478,1650.759 
						1493.177,1640.022 1493.177,1611.951 1885.478,1623.505 					"/>
					
						<linearGradient id="SVGID_00000133505044600170778390000017003567687450327181_" gradientUnits="userSpaceOnUse" x1="1561.4072" y1="1671.4834" x2="1949.8074" y2="1671.4834">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000133505044600170778390000017003567687450327181_)'}} points="
						1949.807,1690.202 1561.407,1680.694 1561.407,1652.765 1949.807,1663.081 					"/>
					
						<linearGradient id="SVGID_00000067922328115404347780000006576356238482709427_" gradientUnits="userSpaceOnUse" x1="1561.4072" y1="1709.7261" x2="1949.8074" y2="1709.7261">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000067922328115404347780000006576356238482709427_)'}} points="
						1949.807,1727.883 1561.407,1719.498 1561.407,1691.569 1949.807,1700.762 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1949.807,1765.564 1561.407,1758.302 1561.407,1730.374 1949.807,1738.444 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1676.291 1280.434,1673.816 1280.434,1645.302 
						1381.554,1647.988 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1715.615 1280.434,1713.432 1280.434,1684.919 
						1381.554,1687.312 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1754.94 1280.434,1753.049 1280.434,1724.535 1381.554,1726.637 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1794.264 1280.434,1792.666 1280.434,1764.152 
						1381.554,1765.961 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1652.522,1798.547 1403.62,1794.613 1403.62,1766.356 1652.522,1770.807 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1833.588 1280.434,1832.282 1280.434,1803.769 
						1381.554,1805.285 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1872.913 1280.434,1871.899 1280.434,1843.385 
						1381.554,1844.609 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1912.237 1280.434,1911.516 1280.434,1883.002 
						1381.554,1883.934 					"/>
					
						<linearGradient id="SVGID_00000000213909415921132770000003642866708741980553_" gradientUnits="userSpaceOnUse" x1="1493.1768" y1="1822.7764" x2="1769.1375" y2="1822.7764">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000000213909415921132770000003642866708741980553_)'}} points="
						1769.137,1838.594 1493.177,1835.03 1493.177,1806.959 1769.137,1811.097 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1912.504,1878.235 1561.407,1874.715 1561.407,1846.786 1912.504,1851.036 
											"/>
					
						<linearGradient id="SVGID_00000115500386215362527480000013906618797147458199_" gradientUnits="userSpaceOnUse" x1="1651.1042" y1="1377.8884" x2="1802.6643" y2="2301.6812">
						<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
						<stop  offset="1" style={{stopColor:theme.text}}/>
					</linearGradient>
					<polygon style={{fill:'url(#SVGID_00000115500386215362527480000013906618797147458199_)'}} points="1912.504,1916.024 
						1561.407,1913.52 1561.407,1885.591 1912.504,1888.826 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1951.561 1280.434,1951.132 1280.434,1922.619 
						1381.554,1923.258 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,1990.885 1280.434,1990.749 1280.434,1962.235 
						1381.554,1962.582 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2030.21 1280.434,2030.365 1280.434,2001.852 1381.554,2001.907 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2069.534 1280.434,2069.982 1280.434,2041.469 
						1381.554,2041.231 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2108.858 1280.434,2109.599 1280.434,2081.085 
						1381.554,2080.555 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2148.183 1280.434,2149.216 1280.434,2120.702 1381.554,2119.88 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2187.507 1280.434,2188.832 1280.434,2160.319 
						1381.554,2159.204 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2226.831 1280.434,2228.449 1280.434,2199.935 
						1381.554,2198.528 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2266.156 1280.434,2268.066 1280.434,2239.552 
						1381.554,2237.853 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2305.48 1280.434,2307.682 1280.434,2279.169 1381.554,2277.177 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2344.804 1280.434,2347.299 1280.434,2318.785 
						1381.554,2316.501 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2384.128 1280.434,2386.915 1280.434,2358.402 
						1381.554,2355.825 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2423.453 1280.434,2426.532 1280.434,2398.018 1381.554,2395.15 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2462.777 1280.434,2466.148 1280.434,2437.635 
						1381.554,2434.474 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2502.101 1280.434,2505.765 1280.434,2477.252 
						1381.554,2473.798 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2541.426 1280.434,2545.382 1280.434,2516.868 
						1381.554,2513.123 					"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2580.75 1280.434,2584.999 1280.434,2556.485 1381.554,2552.447 
											"/>
					<polygon style={{opacity:0.36,fill:'#FFFFFF'}} points="1381.554,2620.074 1280.434,2624.615 1280.434,2596.102 
						1381.554,2591.771 					"/>
					
						<linearGradient id="SVGID_00000091705181898683414270000013221890763546955422_" gradientUnits="userSpaceOnUse" x1="1402.9332" y1="1588.333" x2="1678.7139" y2="1588.333">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000091705181898683414270000013221890763546955422_)'}} points="
						1678.714,1606.635 1402.933,1598.289 1402.933,1570.031 1678.714,1578.95 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1885.478,2408.108 1493.177,2420.054 1493.177,2391.983 1885.478,2380.853 
											"/>
					
						<linearGradient id="SVGID_00000163765367811229770730000011953705312868872075_" gradientUnits="userSpaceOnUse" x1="1561.4072" y1="2436.7451" x2="1949.8074" y2="2436.7451">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000163765367811229770730000011953705312868872075_)'}} points="
						1949.807,2443.83 1561.407,2456.781 1561.407,2428.852 1949.807,2416.709 					"/>
					
						<linearGradient id="SVGID_00000083063758561242979630000008264318061174053013_" gradientUnits="userSpaceOnUse" x1="1561.4072" y1="2474.9878" x2="1949.8074" y2="2474.9878">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000083063758561242979630000008264318061174053013_)'}} points="
						1949.807,2481.511 1561.407,2495.585 1561.407,2467.656 1949.807,2454.391 					"/>
					
						<linearGradient id="SVGID_00000175285285931413711080000016531039305852832386_" gradientUnits="userSpaceOnUse" x1="1738.8356" y1="2506.6594" x2="2117.1799" y2="2506.6594">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000175285285931413711080000016531039305852832386_)'}} points="
						2117.18,2512.644 1738.836,2527.447 1738.836,2499.887 2117.18,2485.872 					"/>
					
						<linearGradient id="SVGID_00000078011673365736740210000002744764141647583142_" gradientUnits="userSpaceOnUse" x1="1768.8773" y1="1574.4669" x2="1887.261" y2="2296.0425">
						<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
						<stop  offset="1" style={{stopColor:theme.text}}/>
					</linearGradient>
					<polygon style={{fill:'url(#SVGID_00000078011673365736740210000002744764141647583142_)'}} points="2117.18,2549.842 
						1738.836,2565.738 1738.836,2538.179 2117.18,2523.069 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="2117.18,2587.039 1738.836,2604.03 1738.836,2576.47 2117.18,2560.267 
											"/>
					
						<linearGradient id="SVGID_00000086649650196989264350000015695709650963528382_" gradientUnits="userSpaceOnUse" x1="1402.9332" y1="2365.8967" x2="1678.7139" y2="2365.8967">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000086649650196989264350000015695709650963528382_)'}} points="
						1678.714,2375.938 1402.933,2383.54 1402.933,2355.281 1678.714,2348.254 					"/>
					
						<linearGradient id="SVGID_00000095299277917752182420000017858113525188401811_" gradientUnits="userSpaceOnUse" x1="1493.1768" y1="1977.2656" x2="1885.4783" y2="1977.2656">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000095299277917752182420000017858113525188401811_)'}} points="
						1885.478,1991.566 1493.177,1991.036 1493.177,1962.965 1885.478,1964.312 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1949.807,2029.334 1561.407,2029.933 1561.407,2002.004 1949.807,2002.213 
											"/>
					
						<linearGradient id="SVGID_00000128468915467898772320000000190329084940826502_" gradientUnits="userSpaceOnUse" x1="1561.4072" y1="2054.3159" x2="1949.8074" y2="2054.3159">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000128468915467898772320000000190329084940826502_)'}} points="
						1949.807,2067.016 1561.407,2068.737 1561.407,2040.808 1949.807,2039.895 					"/>
					
						<linearGradient id="SVGID_00000072247330465380703520000011946171165921957817_" gradientUnits="userSpaceOnUse" x1="1561.4072" y1="2092.5591" x2="1949.8074" y2="2092.5591">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000072247330465380703520000011946171165921957817_)'}} points="
						1949.807,2104.697 1561.407,2107.542 1561.407,2079.613 1949.807,2077.576 					"/>
					
						<linearGradient id="SVGID_00000005964417084723826940000010236276244268586907_" gradientUnits="userSpaceOnUse" x1="1403.6202" y1="2132.8164" x2="1652.5217" y2="2132.8164">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000005964417084723826940000010236276244268586907_)'}} points="
						1652.522,2145.415 1403.62,2147.957 1403.62,2119.7 1652.522,2117.676 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1769.137,2182.427 1493.177,2186.044 1493.177,2157.973 1769.137,2154.931 
											"/>
					
						<linearGradient id="SVGID_00000052782252598835035850000014488586477068713397_" gradientUnits="userSpaceOnUse" x1="1610.8713" y1="1439.0336" x2="1748.4508" y2="2277.6121">
						<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
						<stop  offset="1" style={{stopColor:theme.text}}/>
					</linearGradient>
					<polygon style={{fill:'url(#SVGID_00000052782252598835035850000014488586477068713397_)'}} points="1912.504,2218.338 
						1561.407,2223.954 1561.407,2196.025 1912.504,2191.14 					"/>
					
						<linearGradient id="SVGID_00000137108432844289156210000011868295224669145735_" gradientUnits="userSpaceOnUse" x1="1561.4072" y1="2245.8438" x2="1912.5043" y2="2245.8438">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000137108432844289156210000011868295224669145735_)'}} points="
						1912.504,2256.127 1561.407,2262.759 1561.407,2234.83 1912.504,2228.929 					"/>
					
						<linearGradient id="SVGID_00000003807530198622519440000011692668432238438585_" gradientUnits="userSpaceOnUse" x1="1643.4659" y1="2282.4805" x2="1990.3384" y2="2282.4805">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000003807530198622519440000011692668432238438585_)'}} points="
						1990.338,2292.221 1643.466,2299.776 1643.466,2272.018 1990.338,2265.185 					"/>
					
						<linearGradient id="SVGID_00000049206326442694936860000007265128818699622839_" gradientUnits="userSpaceOnUse" x1="1643.4659" y1="2320.5464" x2="1990.3384" y2="2320.5464">
						<stop  offset="0" style={{stopColor:theme.text}}/>
						<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
					</linearGradient>
					<polygon style={{opacity:0.5,fill:'url(#SVGID_00000049206326442694936860000007265128818699622839_)'}} points="
						1990.338,2329.786 1643.466,2338.343 1643.466,2310.585 1990.338,2302.75 					"/>
					
						<linearGradient id="SVGID_00000176026162711964627940000000945666711264339380_" gradientUnits="userSpaceOnUse" x1="1452.9738" y1="1402.9296" x2="1605.4386" y2="2332.2375">
						<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
						<stop  offset="1" style={{stopColor:theme.text}}/>
					</linearGradient>
					<polygon style={{fill:'url(#SVGID_00000176026162711964627940000000945666711264339380_)'}} points="1678.714,1952.821 
						1402.933,1951.652 1402.933,1923.393 1678.714,1925.137 					"/>
					<polygon style={{fill:'#FFFFFF'}} points="1175.411,1666.332 924.863,1660.109 924.863,1618.923 1175.411,1625.879 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,1658.5 907.248,1659.672 907.248,1618.434 860.058,1617.124 					
						"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,1724.483 924.863,1719.315 924.863,1678.129 1175.411,1684.03 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,1717.979 907.248,1718.952 907.248,1677.714 860.058,1676.602 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,1782.633 924.863,1778.521 924.863,1737.334 1175.411,1742.18 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,1777.457 907.248,1778.232 907.248,1736.994 860.058,1736.081 
											"/>
					<polygon style={{fill:'#FFFFFF'}} points="1175.411,2195.4 737.679,2201.301 737.679,2159.566 1175.411,2154.948 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2258.607 924.863,2263.132 924.863,2221.945 1175.411,2218.155 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2264.302 907.248,2263.449 907.248,2222.212 860.058,2222.926 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2316.758 924.863,2322.337 924.863,2281.151 1175.411,2276.305 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2323.781 907.248,2322.73 907.248,2281.491 860.058,2282.404 					
						"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2374.908 924.863,2381.543 924.863,2340.357 1175.411,2334.456 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2383.259 907.248,2382.01 907.248,2340.771 860.058,2341.883 					
						"/>
					<polygon style={{fill:'#FFFFFF'}} points="1175.411,1841.177 737.679,1835.849 737.679,1794.115 1175.411,1800.725 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,1904.385 924.863,1902.482 924.863,1861.295 1175.411,1863.932 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,1901.99 907.248,1902.348 907.248,1861.11 860.058,1860.613 					
						"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,1962.535 924.863,1961.688 924.863,1920.501 1175.411,1922.083 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,1961.468 907.248,1961.628 907.248,1920.39 860.058,1920.092 					
						"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2079.591 924.863,2080.868 924.863,2039.681 1175.411,2039.139 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2081.198 907.248,2080.958 907.248,2039.719 860.058,2039.822 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2137.742 924.863,2140.073 924.863,2098.887 1175.411,2097.29 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2140.677 907.248,2140.238 907.248,2098.999 860.058,2099.3 					
						"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2020.686 924.863,2020.893 924.863,1979.707 1175.411,1980.233 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2020.947 907.248,2020.908 907.248,1979.669 860.058,1979.571 
											"/>
					<polygon style={{fill:'#FFFFFF'}} points="1175.411,2433.039 737.679,2446.474 737.679,2404.739 1175.411,2392.586 					"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2496.246 924.863,2505.083 924.863,2463.896 1175.411,2455.794 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2507.368 907.248,2505.704 907.248,2464.466 860.058,2465.992 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2554.396 924.863,2564.289 924.863,2523.102 1175.411,2513.944 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2566.847 907.248,2564.984 907.248,2523.746 860.058,2525.47 					
						"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1175.411,2612.547 924.863,2623.494 924.863,2582.308 1175.411,2572.095 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="860.058,2626.325 907.248,2624.264 907.248,2583.026 860.058,2584.949 
											"/>
					<polygon style={{opacity:0.6,fill:'#FFFFFF'}} points="1228.605,2610.424 1218.964,2610.845 1218.964,1564.081 1228.605,1564.393 
											"/>
				</g>
				<path style={{opacity:0.6,fill:'#FFFFFF'}} d="M2424.657,2659.087c-425.004,8.886-520.58,69.57-641.345-3.142
					c-165.943-99.913-90.37-277.644-252.049-370.873c-203.265-117.207-437.619,97.451-543.706-7.201
					c-105.648-104.219,93.927-349.504-21.604-622.922c-46.363-109.724-125.308-180.99-179.843-221.849l-110.009-4.767
					c-42.218-1.829-76.755,26.121-76.755,62.315v1304.511c0,36.194,34.538,63.582,76.755,61.065l1952.376-116.391
					c30.995-1.848,56.092-28.388,56.092-59.172v-19.725C2626.054,2658.862,2535.416,2656.771,2424.657,2659.087z"/>
			</g>
		</g>
		<g>
			
				<linearGradient id="SVGID_00000150095530775926392030000015864123801150095551_" gradientUnits="userSpaceOnUse" x1="118.2748" y1="1170.8759" x2="303.0978" y2="1170.8759" gradientTransform="matrix(-1 0 0 1 1470.2181 0)">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000150095530775926392030000015864123801150095551_)'}} d="M1197.998,979.072
				c23.271-6.797,54.371,35.932,80.226,71.454c38.68,53.142,83.872,115.231,71.697,189.786
				c-11.978,73.351-74.863,130.478-92.785,122.307c-15.328-6.989,14.143-56.323-8.435-122.307
				c-18.218-53.243-48.404-53.262-68.647-105.462C1154.933,1070.067,1169.37,987.434,1197.998,979.072z"/>
			
				<linearGradient id="SVGID_00000131334973651722510240000007442310047026307982_" gradientUnits="userSpaceOnUse" x1="801.5812" y1="1517.8958" x2="1159.9915" y2="1517.8958" gradientTransform="matrix(-1 0 0 1 2471.8074 0)">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.imageHighlight}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000131334973651722510240000007442310047026307982_)'}} d="M1209.513,1316.936
				c119.854,29.098,198.8,75.807,244.907,108.847c24.987,17.907,36.53,29.075,42.761,45.353
				c35.503,92.767-146.904,233.031-166.342,247.72c-8.491-19.798-16.982-39.596-25.472-59.395
				c18.848-103.455,50.228-135.911,76.488-145.563c7.345-2.7,19.676-5.595,19.437-9.071c-0.566-8.219-69.849,3.494-136.06-20.732
				c-66.021-24.158-132.353-85.281-120.509-125.693C1146.885,1351.025,1155.941,1329.701,1209.513,1316.936z"/>
			
				<linearGradient id="SVGID_00000124853928767519473240000012180538647519807659_" gradientUnits="userSpaceOnUse" x1="2035.2477" y1="1790.7972" x2="2050.7397" y2="2069.6553" gradientTransform="matrix(0.9565 0.2918 -0.2918 0.9565 -61.8632 -392.2555)">
				<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="1" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000124853928767519473240000012180538647519807659_)'}} d="M1325.732,1994.999
				c0,0-11.157,3.974-11.198,12.798c-0.041,8.823,11.301,47.689,23.622,61.499c12.321,13.81,13.423-6.793,12.661-20.065
				c-0.761-13.272-3.992-42.981-7.038-48.726C1340.732,1994.762,1325.732,1994.999,1325.732,1994.999z"/>
			
				<linearGradient id="SVGID_00000129172936893534302230000010841716103391150262_" gradientUnits="userSpaceOnUse" x1="970.057" y1="1531.3806" x2="1328.4668" y2="1531.3806" gradientTransform="matrix(-1 0 0 1 2471.8074 0)">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.imageHighlight}}/>
			</linearGradient>
			<path style={{opacity:0.3,fill:'url(#SVGID_00000129172936893534302230000010841716103391150262_)'}} d="M1361.554,1452.563
				c21.777-0.427,72.801-4.49,86.386,20.732c11.334,21.044-8.521,52.944-20.734,72.565c-33.197,53.341-65.419,58.798-88.114,91.57
				c-8.784,12.686-16.743,30.404-19.277,55.72c-4.817-11.231-9.633-22.46-14.448-33.69
				c18.848-103.453,50.228-135.911,76.488-145.562c7.345-2.7,19.676-5.595,19.437-9.071c-0.566-8.22-69.849,3.494-136.06-20.732
				c-60.183-22.022-120.422-74.717-121.669-114.483C1238.863,1437.966,1310.591,1453.562,1361.554,1452.563z"/>
			
				<linearGradient id="SVGID_00000137819996065905596450000000350956940512098447_" gradientUnits="userSpaceOnUse" x1="5066.1865" y1="1999.5177" x2="5089.4927" y2="1999.5177" gradientTransform="matrix(-1 0 0 1 6408.7778 0)">
				<stop  offset="0" style={{stopColor:theme.text}}/>
				<stop  offset="1" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000137819996065905596450000000350956940512098447_)'}} d="M1319.285,1957.507
				c0.657,13.597,1.812,24.965,2.852,33.407c2.302,18.677,4.419,26.067,7.318,32.798c2.529,5.865,8.849,18.659,11.867,17.772
				c3.067-0.9-0.088-15.075-2.477-54.498c-0.348-5.722-1.401-23.938-1.401-23.938l0,0L1319.285,1957.507z"/>
			
				<linearGradient id="SVGID_00000026852600459241831470000018208310707332700844_" gradientUnits="userSpaceOnUse" x1="992.9595" y1="1295.3624" x2="1314.3187" y2="1685.8308" gradientTransform="matrix(-1 0 0 1 2471.8074 0)">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.imageHighlight}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000026852600459241831470000018208310707332700844_)'}} d="M1140.403,1324.711
				c69.056,41.635,113.884,88.974,139.946,120.941c30.767,37.738,77.567,95.142,94.511,180.737
				c9.032,45.624,4.588,86.55-4.669,166.538c-9.195,79.444-22.374,144.124-32.685,188.297c-5.31,2.132-10.62,4.264-15.929,6.395
				c-14.224-26.132-32.83-67.5-39.5-120.399c-10.912-86.547,21.465-114.05,5.924-175.427
				c-13.462-53.17-52.469-85.772-130.481-150.976c-64.097-53.572-123.198-85.355-145.136-152.176
				c-3.727-11.347-6.892-25.267-0.819-38.013C1024.653,1323.159,1074.731,1313.575,1140.403,1324.711z"/>
			
				<linearGradient id="SVGID_00000000911198800812932130000018180816431412367763_" gradientUnits="userSpaceOnUse" x1="1943.9574" y1="830.7046" x2="1518.9324" y2="1186.6205" gradientTransform="matrix(-0.9987 -0.0509 -0.0509 0.9987 2565.9993 27.2223)">
				<stop  offset="0" style={{stopColor:theme.dark}}/>
				<stop  offset="0.9964" style={{stopColor:theme.dark}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000000911198800812932130000018180816431412367763_)'}} d="M1085.279,984.023
				c17.925-3.855,47.402-3.089,75.504-25.219c8.46-6.662,38.432-30.264,34.792-64.032c-3.204-29.729-31.38-45.706-34.838-47.609
				c-19.947-10.979-39.833-8.539-61.887-5.523c-28.598,3.91-38.097,11.723-57.969,17.08
				c-49.162,13.251-65.788-14.408-115.285-12.074c-63.264,2.982-68.736,49.705-121.963,50.871
				c-59.754,1.308-71.205-57.172-130.201-67.08c-81.888-13.753-205.788,74.401-203.292,176.025
				c1.441,58.694,45.146,125.961,100.97,146.155c32.539,11.771,67.651,8.032,77.673,29.192c7.133,15.058-8.902,26.66-7.387,52.898
				c1.949,33.826,31.072,57.563,35.775,61.395c43.655,35.583,141.529,17.609,208.366-32.741
				C1006.062,1172.566,987.822,1004.992,1085.279,984.023z"/>
			
				<linearGradient id="SVGID_00000127762128416110800680000000619250996916299175_" gradientUnits="userSpaceOnUse" x1="-1649.8599" y1="1306.9373" x2="-1304.6929" y2="996.6032" gradientTransform="matrix(1 0 0 1 2574.7561 0)">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000127762128416110800680000000619250996916299175_)'}} d="M946.838,971.958
				c40.594-5.002,87.416-8.809,139.446-9.516c109.942-1.493,125.681,12.447,129.708,22.768c7.508,19.237-5.353,28.879,1.95,62.423
				c4.367,20.056,0.239,29.196,8.681,50.746c8.227,21.001,1.774,32.293-5.501,81.539c-9.302,62.971-13.593,94.975,4.028,120.361
				c12.244,17.638,25.942,19.132,24.637,27.511c-2.781,17.851-64.501,8.006-142.642,42.274
				c-22.621,9.919-43.442,22.144-60.903,32.396c-40.885,24.002-48.449,33.182-67.382,44.056
				c-59.876,34.394-149.82,37.708-196.961-3.887c-17.575-15.508-30.384-38.304-24.62-46.648
				c7.849-11.361,42.948,14.445,75.156-1.296c21.337-10.429,17.284-27.3,41.952-47.178c29.687-23.924,50.328-11.388,70.535-26.954
				c31.522-24.282,17.182-82.421,8.333-179.896C949.592,1100.303,945.917,1042.682,946.838,971.958z"/>
			
				<linearGradient id="SVGID_00000086661230192099020060000007968609655515345578_" gradientUnits="userSpaceOnUse" x1="5263.2339" y1="950.7075" x2="5384.9321" y2="950.7075" gradientTransform="matrix(-1 0 0 1 6408.7778 0)">
				<stop  offset="0" style={{stopColor:theme.text}}/>
				<stop  offset="1" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000086661230192099020060000007968609655515345578_)'}} d="M1065.817,949.312
				c-14.995,9.302-39.233,21.46-41.903,24.761c-2.67,3.296,73.431,16.327,120.783-7.569c0,0,7.341-4.078-21.205-9.787
				c-6.082-1.217-4.893-13.049-4.078-25.283l-5.709-10.603C1113.705,920.832,1080.809,940.005,1065.817,949.312z"/>
			
				<linearGradient id="SVGID_00000028311259556821489700000000064433364521720476_" gradientUnits="userSpaceOnUse" x1="5278.6436" y1="1266.1346" x2="5276.0181" y2="1025.9604" gradientTransform="matrix(-1 0 0 1 6408.7778 0)">
				<stop  offset="0" style={{stopColor:theme.text}}/>
				<stop  offset="1" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000028311259556821489700000000064433364521720476_)'}} d="M1103.645,913.628
				c0,0-5.882,24.953,16.094,37.285c19.677,11.045,52.967-34.227,46.749-51.051c-2.073-5.612-9.483-12.825-17.227-15.249
				c-5.447-1.706-26.055-1.048-34.881,16.303c-2.312-5.073-6.452-7.732-9.233-6.898c-3.529,1.054-4.048,7.48-4.168,8.934
				C1100.57,907.997,1102.605,911.911,1103.645,913.628z"/>
			
				<linearGradient id="SVGID_00000030476835978739021800000003167337166267456431_" gradientUnits="userSpaceOnUse" x1="-1638.5205" y1="1319.5492" x2="-1293.3521" y2="1009.2138" gradientTransform="matrix(1 0 0 1 2574.7561 0)">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{opacity:0.3,fill:'url(#SVGID_00000030476835978739021800000003167337166267456431_)'}} d="M1137.719,1313.633
				c-22.64,9.384-25.696,29.664-57,41.167c-29.862,10.973-39.882-2.777-69.667,6.333c-44.735,13.684-61.84,56.85-68.611,52.778
				c-6.204-3.73-0.254-45.024,28.5-71.778c33.023-30.725,68.591-16.655,89.722-48.555c8.222-12.412,14.561-32.24,7.389-47.5
				c-9.719-20.679-35.104-13.508-48.556-30.612c-24.169-30.728,32.389-86.151,16.889-133c-6.186-18.697-25.883-42.131-89.404-57.817
				c0.862,46.96,3.568,86.204,6.273,116.006c8.848,97.475,23.188,155.615-8.333,179.896c-20.207,15.566-40.847,3.031-70.535,26.955
				c-24.669,19.878-20.615,36.749-41.952,47.177c-32.208,15.742-67.307-10.064-75.156,1.296c-5.764,8.345,7.045,31.141,24.62,46.649
				c47.142,41.594,137.085,38.281,196.961,3.888c18.933-10.875,26.497-20.055,67.382-44.057
				c17.461-10.252,38.282-22.476,60.903-32.396c54.838-24.049,101.555-26.376,125.523-31.793
				C1185.083,1306.539,1156.095,1306.017,1137.719,1313.633z"/>
			<g>
				
					<linearGradient id="SVGID_00000060721645733350944100000002659842096691931271_" gradientUnits="userSpaceOnUse" x1="-1524.7733" y1="1368.8135" x2="-1210.8306" y2="1368.8135" gradientTransform="matrix(1 0 0 1 2574.7561 0)">
					<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
					<stop  offset="1" style={{stopColor:theme.text}}/>
				</linearGradient>
				<path style={{fill:'url(#SVGID_00000060721645733350944100000002659842096691931271_)'}} d="M1356.236,1345.481l-287.228,13.977
					c-3.953,0.194-7.559,2.311-9.651,5.67l-7.948,12.756c-3.955,6.35,0.797,14.54,8.275,14.255l304.241-11.59L1356.236,1345.481z"/>
				
					<linearGradient id="SVGID_00000159457404471992050120000006775365139821047960_" gradientUnits="userSpaceOnUse" x1="-1375.4957" y1="1286.3628" x2="-944.5851" y2="1286.3628" gradientTransform="matrix(1 0 0 1 2574.7561 0)">
					<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
					<stop  offset="1" style={{stopColor:theme.text}}/>
				</linearGradient>
				<path style={{fill:'url(#SVGID_00000159457404471992050120000006775365139821047960_)'}} d="M1619.914,1186.8l-296.737,5.75
					c-8.781,0.17-16.821,4.953-21.159,12.587l-102.757,180.79l313.391-12.486c8.602-0.343,16.451-5,20.872-12.387l95.204-159.051
					C1632.781,1195.234,1627.804,1186.647,1619.914,1186.8z"/>
				
					<linearGradient id="SVGID_00000060007349169196130130000018187470738670539939_" gradientUnits="userSpaceOnUse" x1="2785.406" y1="1172.0436" x2="2991.2358" y2="1376.2269" gradientTransform="matrix(-1 0 0 1 4315.0347 0)">
					<stop  offset="0.0036" style={{stopColor:theme.imageHighlight}}/>
					<stop  offset="1" style={{stopColor:theme.text}}/>
				</linearGradient>
				<path style={{opacity:0.3,fill:'url(#SVGID_00000060007349169196130130000018187470738670539939_)'}} d="M1302.016,1205.139
					c4.34-7.635,12.38-12.418,21.161-12.588l6.503-0.126c0.97,9.579,3.909,18.341,9.77,25.415
					c18.297,22.086,48.697,8.463,71.355,31.837c24.889,25.674,1.896,56.239,24.149,80.135c23.424,25.151,61.3,4.673,104.84,20.768
					l-6.27,10.474c-4.421,7.388-12.27,12.045-20.872,12.388l-313.391,12.486L1302.016,1205.139z"/>
			</g>
			<g>
				
					<linearGradient id="SVGID_00000126310641961946803570000004276490604362342844_" gradientUnits="userSpaceOnUse" x1="-1506.8287" y1="1304.6179" x2="-1770.4208" y2="1045.2437" gradientTransform="matrix(1 0 0 1 2574.7561 0)">
					<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
					<stop  offset="0.9964" style={{stopColor:theme.text}}/>
				</linearGradient>
				<path style={{fill:'url(#SVGID_00000126310641961946803570000004276490604362342844_)'}} d="M1116.06,1345.633
					c3.237-8.547,6.474-17.092,9.71-25.642c-35.771-9.807-85.244-24.297-135.988-42.429c-12.539-4.48-17.128-10.272-20.482-14.72
					c-26.016-34.504,40.192-95.374,39.005-174.358c-0.821-54.75-34.015-117.379-63.262-118.089
					c-59.452-1.445-190.535,250.818-80.132,358.485C919.709,1382.318,1022.085,1388.52,1116.06,1345.633z"/>
			</g>
			
				<linearGradient id="SVGID_00000035492563329619436750000018308784417385375131_" gradientUnits="userSpaceOnUse" x1="5204.7764" y1="1335.0891" x2="5294.8735" y2="1335.0891" gradientTransform="matrix(-1 0 0 1 6408.7778 0)">
				<stop  offset="0" style={{stopColor:theme.text}}/>
				<stop  offset="1" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000035492563329619436750000018308784417385375131_)'}} d="M1125.771,1319.992
				c22.457-11.929,49.338,0.299,51.07,1.118c4.935,2.329,12.116,6.479,19.372,13.89c0.821,1.119,10.563,14.617,7.013,18.943
				c-2.844,3.466-13.86,0.593-27.1-7.641c-5.55-0.779-13.811-1.493-23.709-0.581c-18.176,1.677-26.502,7.419-33.1,2.462
				c-4.832-3.628-6.478-11.299-4.74-17.003C1116.444,1325.051,1121.97,1322.012,1125.771,1319.992z"/>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M2040.303,1176.766c-7.115-0.085-13.932-4.359-17.123-11.518l-106.685-239.535
					c-1.155-2.602-1.758-5.422-1.758-8.26V669.716c0-10.81,8.514-19.299,19.012-18.957c10.489,0.341,18.984,9.37,18.984,20.165
					V913.99l104.648,235.335c4.361,9.815,0.257,21.332-9.18,25.732C2045.641,1176.251,2042.952,1176.798,2040.303,1176.766z"/>
				<path style={{fill:theme.jacketColor}} d="M2315.74,1180.402c-10.169-0.123-18.417-8.851-18.417-19.491v-124.049l-97.431-103.592
					c-3.486-3.705-5.442-8.667-5.442-13.798V679.364c0-10.683,8.315-19.075,18.567-18.741c10.243,0.333,18.54,9.253,18.54,19.92
					v231.756l97.189,103.544c3.45,3.674,5.385,8.602,5.385,13.705v131.839C2334.13,1172.011,2325.901,1180.526,2315.74,1180.402z"/>
				<path style={{fill:theme.jacketColor}} d="M2463.753,1181.953c-10.067-0.122-18.232-8.805-18.232-19.392V952.707
					c0-5.084,1.921-9.918,5.344-13.439l60.554-62.319V688.787c0-10.559,8.122-18.855,18.137-18.53
					c10.006,0.326,18.11,9.138,18.11,19.682v195.8c0,5.064-1.905,9.874-5.303,13.376l-60.405,62.295v201.621
					C2481.959,1173.604,2473.813,1182.076,2463.753,1181.953z"/>
				<path style={{fill:theme.jacketColor}} d="M1898.01,2445.954c-10.596,0.392-19.191-8.096-19.191-18.958V2230.49
					c0-5.216,2.022-10.275,5.625-14.066l80.88-85.121v-174.433c0-10.825,8.537-19.751,19.063-19.934
					c10.517-0.183,19.035,8.433,19.035,19.242v182.284c0,5.192-2.003,10.22-5.574,13.984l-80.676,85.08v188.08
					C1917.173,2436.451,1908.598,2445.562,1898.01,2445.954z"/>
				<path style={{fill:theme.jacketColor}} d="M2203.985,2434.63c-4.792,0.177-9.589-1.543-13.247-5.2c-7.324-7.321-7.324-19.629,0-27.481
					l105.539-113.171v-337.915c0-10.687,8.32-19.496,18.577-19.674c10.25-0.178,18.55,8.329,18.55,19v345.427
					c0,5.125-1.952,10.101-5.432,13.839l-110.755,119.001C2213.568,2432.379,2208.776,2434.452,2203.985,2434.63z"/>
				<path style={{fill:theme.jacketColor}} d="M2502.807,2423.57c-4.68,0.173-9.366-1.528-12.939-5.145c-7.154-7.239-7.154-19.402,0-27.159
					L2606,2265.31v-84.455l-168.903-173.324c-7.184-7.37-7.184-19.559,0-27.216c7.173-7.651,18.81-7.873,25.972-0.504
					l173.861,178.783c3.396,3.491,5.301,8.294,5.301,13.356v100.148c0,5.063-1.904,9.977-5.301,13.666l-121.198,131.708
					C2512.167,2421.349,2507.486,2423.396,2502.807,2423.57z"/>
				<path style={{fill:theme.jacketColor}} d="M2807.887,1386.568l-183.759-0.751c-10.01-0.041-18.128-8.633-18.128-19.189
					c0-10.556,8.119-19.07,18.128-19.014l176.362,0.989l107.288-113.326c3.32-3.507,7.821-5.45,12.514-5.401l175.635,1.829
					c9.63,0.1,17.429,8.568,17.429,18.911c0,10.344-7.799,18.661-17.429,18.575l-168.309-1.497l-107.117,113.375
					C2817.158,1384.608,2812.621,1386.587,2807.887,1386.568z"/>
				<path style={{fill:theme.jacketColor}} d="M2653.531,1759.416c-4.625,0.05-9.255-1.764-12.784-5.453c-7.069-7.382-7.069-19.473,0-26.997
					l175.231-186.556c3.344-3.561,7.879-5.566,12.607-5.575l267.344-0.496c9.63-0.018,17.429,8.354,17.429,18.698
					c0,10.343-7.799,18.756-17.429,18.789l-259.963,0.877l-169.662,180.99C2662.78,1757.453,2658.155,1759.365,2653.531,1759.416z"
					/>
				<path style={{fill:theme.jacketColor}} d="M2768.276,1979.639l-144.148,2.832c-10.01,0.197-18.128-8.203-18.128-18.759
					c0-10.556,8.119-19.263,18.128-19.444l136.727-2.478l88.833-95.79c3.335-3.597,7.858-5.655,12.573-5.722l233.667-3.301
					c9.63-0.136,17.429,8.14,17.429,18.484s-7.799,18.852-17.429,19.003l-226.307,3.54l-88.69,95.828
					C2777.577,1977.456,2773.025,1979.546,2768.276,1979.639z"/>
				<path style={{fill:theme.jacketColor}} d="M3170.729,1251.168c0,44.394-33.433,80.169-74.801,79.905
					c-41.507-0.265-75.257-36.688-75.257-81.353c0-44.665,33.751-80.437,75.257-79.9
					C3137.296,1170.353,3170.729,1206.774,3170.729,1251.168z"/>
				<path style={{fill:theme.jacketColor}} d="M3170.729,1552.887c0,44.394-33.433,80.579-74.801,80.822
					c-41.507,0.244-75.257-35.765-75.257-80.43c0-44.665,33.751-80.851,75.257-80.824
					C3137.296,1472.483,3170.729,1508.493,3170.729,1552.887z"/>
				<path style={{fill:theme.jacketColor}} d="M3170.729,1854.607c0,44.394-33.433,80.989-74.801,81.74
					c-41.507,0.753-75.257-34.842-75.257-79.507c0-44.665,33.751-81.265,75.257-81.747
					C3137.296,1774.613,3170.729,1810.213,3170.729,1854.607z"/>
				<path style={{fill:theme.jacketColor}} d="M1933.748,586.174c45.055,1.579,81.463,40.41,81.463,86.735s-36.408,82.842-81.463,81.557
					c-45.213-1.289-81.982-40.131-81.982-86.752C1851.766,621.094,1888.536,584.589,1933.748,586.174z"/>
				<path style={{fill:theme.jacketColor}} d="M2213.015,596.799c44.001,1.542,79.559,39.902,79.559,85.684
					c0,45.782-35.558,81.882-79.559,80.627c-44.154-1.259-80.06-39.63-80.06-85.7
					C2132.955,631.34,2168.861,595.251,2213.015,596.799z"/>
				<path style={{fill:theme.jacketColor}} d="M2529.556,607.176c42.984,1.507,77.721,39.407,77.721,84.658
					c0,45.251-34.738,80.944-77.721,79.718c-43.131-1.23-78.205-39.141-78.205-84.673
					C2451.351,641.346,2486.425,605.664,2529.556,607.176z"/>
				<path style={{fill:theme.jacketColor}} d="M1898.011,2341.758c45.479-1.5,82.229,35.018,82.229,81.561s-36.75,85.726-82.229,87.523
					c-45.639,1.803-82.756-34.701-82.756-81.542C1815.255,2382.459,1852.371,2343.263,1898.011,2341.758z"/>
				<path style={{fill:theme.jacketColor}} d="M2203.985,2331.666c44.41-1.465,80.299,34.637,80.299,80.631s-35.888,84.698-80.299,86.453
					c-44.565,1.761-80.806-34.328-80.806-80.613C2123.179,2371.852,2159.42,2333.136,2203.985,2331.666z"/>
				<path style={{fill:theme.jacketColor}} d="M2502.807,2321.81c43.379-1.431,78.435,34.264,78.435,79.722
					c0,45.458-35.056,83.694-78.435,85.409c-43.528,1.72-78.926-33.962-78.926-79.704
					C2423.881,2361.494,2459.278,2323.245,2502.807,2321.81z"/>
			</g>
			
				<linearGradient id="SVGID_00000132777798512615397350000017537039984066251907_" gradientUnits="userSpaceOnUse" x1="2361.3291" y1="1507.0763" x2="1873.7848" y2="1568.0192">
				<stop  offset="0" style={{stopColor:theme.text}}/>
				<stop  offset="1" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000132777798512615397350000017537039984066251907_)'}} d="M2607.17,1075.88l-754.166-22.868
				c-34.267-1.039-62.148,27.413-62.148,63.553v835.117c0,36.139,27.882,64.662,62.148,63.709l754.166-20.971
				c31.09-0.865,56.203-29.323,56.203-63.565v-791.27C2663.373,1105.343,2638.26,1076.823,2607.17,1075.88z"/>
			
				<linearGradient id="SVGID_00000178900342371550296560000002254413429573575082_" gradientUnits="userSpaceOnUse" x1="2901.0671" y1="515.2657" x2="1432.2749" y2="2823.2517">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.imageHighlight}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000178900342371550296560000002254413429573575082_)'}} d="M2639.246,1075.88l-754.165-22.868
				c-34.267-1.039-62.149,27.413-62.149,63.553v835.117c0,36.139,27.882,64.662,62.149,63.709l754.165-20.971
				c31.09-0.865,56.203-29.323,56.203-63.565v-791.27C2695.449,1105.343,2670.336,1076.823,2639.246,1075.88z"/>
			
				<linearGradient id="SVGID_00000090269257223773658900000004751168384832724902_" gradientUnits="userSpaceOnUse" x1="2320.5623" y1="1508.0928" x2="1974.149" y2="1617.1489">
				<stop  offset="0" style={{stopColor:theme.text}}/>
				<stop  offset="1" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000090269257223773658900000004751168384832724902_)'}} d="M2485.264,1246.395l-469.656-9.094
				c-20.951-0.406-37.974,17.327-37.974,39.608v514.863c0,22.281,17.023,40.057,37.974,39.704l469.656-7.913
				c19.719-0.332,35.668-18.067,35.668-39.611v-497.854C2520.932,1264.551,2504.983,1246.776,2485.264,1246.395z"/>
			<g style={{opacity:0.3}}>
				
					<linearGradient id="SVGID_00000136399809014256077320000015612677686760320187_" gradientUnits="userSpaceOnUse" x1="2776.9468" y1="436.281" x2="1308.1553" y2="2744.2659">
					<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
					<stop  offset="0.9964" style={{stopColor:theme.imageHighlight}}/>
				</linearGradient>
				<path style={{fill:'url(#SVGID_00000136399809014256077320000015612677686760320187_)'}} d="M2340.237,1571.803
					c-36.626-189.661-240.26-206.934-293.253-398.961c-10.692-38.745-13.25-77.479-10.177-115.229l-151.726-4.601
					c-34.267-1.039-62.149,27.413-62.149,63.554v835.117c0,36.14,27.882,64.663,62.149,63.71l428.043-11.903
					c-7.878-26.302-13.894-54.275-17.216-83.875C2276.834,1749.679,2365.825,1704.299,2340.237,1571.803z"/>
			</g>
			
				<linearGradient id="SVGID_00000142868340827780198760000002457955592390888608_" gradientUnits="userSpaceOnUse" x1="2507.0029" y1="1313.458" x2="1746.8192" y2="2044.7744">
				<stop  offset="0" style={{stopColor:theme.text}}/>
				<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000142868340827780198760000002457955592390888608_)'}} d="M2517.339,1246.395l-469.656-9.094
				c-20.951-0.406-37.974,17.327-37.974,39.608v514.863c0,22.281,17.023,40.057,37.974,39.704l469.656-7.913
				c19.719-0.332,35.668-18.067,35.668-39.611v-497.854C2553.008,1264.551,2537.059,1246.776,2517.339,1246.395z"/>
			<g style={{opacity:0.3}}>
				
					<linearGradient id="SVGID_00000030460611906680421350000002061606288955371708_" gradientUnits="userSpaceOnUse" x1="2469.7261" y1="1274.7098" x2="1709.5411" y2="2006.0273">
					<stop  offset="0" style={{stopColor:theme.text}}/>
					<stop  offset="1" style={{stopColor:theme.imageHighlight}}/>
				</linearGradient>
				<path style={{fill:'url(#SVGID_00000030460611906680421350000002061606288955371708_)'}} d="M2416.689,1571.803
					c-31.89-165.136-190.375-199.604-265.811-332.504l-103.195-1.998c-20.951-0.406-37.974,17.327-37.974,39.608v514.863
					c0,22.28,17.023,40.056,37.974,39.703l325.068-5.477C2386.014,1726.441,2436.829,1676.088,2416.689,1571.803z"/>
			</g>
		</g>
		<g>
			
				<linearGradient id="SVGID_00000076584912025988229210000011344592656842825399_" gradientUnits="userSpaceOnUse" x1="3596.0496" y1="3476.1125" x2="2681.626" y2="2681.4348">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000076584912025988229210000011344592656842825399_)'}} d="M2371.045,2739.617l-1.67,49.053
				c-0.49,14.392,11.054,25.525,25.775,24.869l26.751-1.192c2.555,23.316,7.677,45.711,15.117,66.914l-22.597,13.554
				c-12.979,7.783-17.885,23.908-10.954,36.01l23.604,41.21c6.918,12.08,40.323,19.26,53.266,11.458l4.08-16.612
				c14.306,17.876,30.685,33.996,48.808,48.067l-12.365,20.449c-7.728,12.782-3.933,28.425,8.473,34.936l42.225,22.152
				c12.367,6.488,52.52,7.496,60.212-5.264l-12.051-25.745c21.924,7.481,45.083,12.573,69.171,15.019l-0.74,21.733
				c-0.486,14.262,10.849,25.192,25.307,24.418l49.196-2.634c14.406-0.772,26.469-12.922,26.954-27.142l0.738-21.669
				c24.172-4.951,47.534-12.435,69.775-22.152l10.393,18.358c6.743,11.911,48.148,18.039,60.776,10.3l17.284-29.094
				c12.589-7.718,17.351-23.603,10.643-35.497l-10.745-19.044c18.693-15.734,35.781-33.295,50.933-52.36l19.96,10.687
				c12.06,6.457,47.78,9.759,55.288-2.816l5.709-51.09c7.495-12.553,3.826-27.971-8.199-34.449l-20.978-11.304
				c8.617-21.571,15.063-44.094,19.084-67.287l25.762-1.148c14.143-0.63,25.989-12.563,26.469-26.655l1.635-48.031
				c0.48-14.093-10.587-25.036-24.729-24.445l-27.275,1.138c-3.102-22.304-8.578-43.717-16.188-63.998l24.964-15.048
				c12.502-7.54,17.232-23.32,10.573-35.262l4.75-47.729c-6.672-11.963-49.713-8.58-62.249-1.062l-26.271,15.757
				c-13.647-16.497-29.101-31.45-46.094-44.614l16.18-26.839c7.54-12.505,3.847-28.028-8.253-34.677l-17.997-26.04
				c-12.137-6.669-51.436,1.407-59.011,13.937l-16.63,27.502c-20.279-6.954-41.635-11.896-63.85-14.56l1.072-31.485
				c0.485-14.22-10.786-25.363-25.181-24.892l-49.161,1.612c-14.448,0.473-26.561,12.416-27.047,26.677l-1.075,31.575
				c-22.605,4.219-44.552,10.685-65.598,19.12l9.769-33.311c-6.844-12.133-47.576-9.119-60.423-1.542l-43.86,25.87
				c-12.886,7.6-17.76,23.62-10.881,35.769l14.745,26.042c-18.359,14.617-35.293,30.964-50.503,48.772l1.11-16.687
				c-12.448-6.706-55.976,0.817-63.739,13.531l-26.484,43.372c-7.776,12.736-3.956,28.485,8.53,35.172l24.878,13.317
				c-9.317,21.321-16.506,43.635-21.293,66.672l-28.321,1.183C2383.873,2713.062,2371.535,2725.226,2371.045,2739.617z
				 M2552.575,2761.207c3.675-107.922,95.549-198.776,204.603-202.906c108.392-4.105,192.833,79.114,189.198,185.854
				c-3.635,106.74-94.002,197.526-202.434,202.81C2634.848,2952.28,2548.9,2869.129,2552.575,2761.207z"/>
			
				<linearGradient id="SVGID_00000074434638040013601340000016264800370197050754_" gradientUnits="userSpaceOnUse" x1="2622.2739" y1="2510.7937" x2="3323.8154" y2="3565.5242">
				<stop  offset="0" style={{stopColor:theme.imageHighlight}}/>
				<stop  offset="0.9964" style={{stopColor:theme.text}}/>
			</linearGradient>
			<path style={{fill:'url(#SVGID_00000074434638040013601340000016264800370197050754_)'}} d="M2403.992,2739.201l-1.67,49.053
				c-0.49,14.392,11.054,25.524,25.774,24.869l26.752-1.192c2.555,23.316,7.677,45.71,15.116,66.913l-22.596,13.555
				c-12.979,7.783-17.885,23.908-10.955,36.01l23.604,41.21c6.918,12.08,23.022,15.539,35.965,7.737l21.381-12.891
				c14.305,17.876,30.684,33.996,48.808,48.068l-12.365,20.448c-7.729,12.782-3.932,28.426,8.473,34.937l42.225,22.152
				c12.368,6.488,28.625,1.398,36.317-11.362l11.845-19.647c21.924,7.481,45.083,12.573,69.171,15.02l-0.74,21.734
				c-0.486,14.26,10.849,25.191,25.306,24.417l49.196-2.634c14.406-0.771,26.469-12.921,26.954-27.142l0.738-21.67
				c24.171-4.951,47.534-12.435,69.775-22.152l10.393,18.358c6.743,11.912,22.452,15.285,35.079,7.547l42.98-26.34
				c12.589-7.718,17.352-23.603,10.643-35.496l-10.744-19.045c18.693-15.734,35.781-33.295,50.933-52.36l19.96,10.688
				c12.06,6.456,27.919,1.491,35.427-11.084l25.57-42.823c7.494-12.553,3.826-27.971-8.198-34.449l-20.978-11.303
				c8.617-21.572,15.063-44.095,19.083-67.288l25.761-1.148c14.144-0.63,25.99-12.563,26.47-26.655l1.635-48.032
				c0.48-14.093-10.587-25.035-24.73-24.445l-27.275,1.139c-3.101-22.305-8.578-43.717-16.187-63.999l24.964-15.048
				c12.501-7.539,17.232-23.32,10.572-35.262l-22.724-40.739c-6.672-11.963-22.239-15.572-34.775-8.053l-26.271,15.757
				c-13.648-16.497-29.101-31.45-46.095-44.614l16.18-26.839c7.54-12.506,3.847-28.029-8.253-34.678l-41.31-22.705
				c-12.137-6.669-28.122-1.928-35.697,10.602l-16.63,27.502c-20.279-6.954-41.634-11.896-63.85-14.56l1.073-31.485
				c0.484-14.22-10.786-25.364-25.182-24.892l-49.162,1.613c-14.447,0.474-26.561,12.416-27.046,26.677l-1.075,31.575
				c-22.604,4.22-44.552,10.686-65.598,19.121l-15.006-26.598c-6.844-12.133-22.802-15.833-35.649-8.256l-43.859,25.871
				c-12.887,7.6-17.761,23.62-10.881,35.769l14.746,26.042c-18.36,14.617-35.294,30.965-50.503,48.771l-26.03-14.024
				c-12.448-6.706-28.836-1.845-36.598,10.869l-26.484,43.372c-7.776,12.737-3.956,28.485,8.53,35.172l24.878,13.318
				c-9.317,21.321-16.506,43.635-21.293,66.672l-28.322,1.183C2416.82,2712.645,2404.482,2724.808,2403.992,2739.201z
				 M2585.521,2760.79c3.675-107.922,95.549-198.775,204.602-202.906c108.392-4.105,192.833,79.114,189.198,185.854
				c-3.635,106.74-94.002,197.526-202.434,202.81C2667.795,2951.863,2581.846,2868.712,2585.521,2760.79z"/>
		</g>
	</g>
</g>
</svg>

    );
  }
}
