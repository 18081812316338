import React, { Component } from "react";

class FeelingProud extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        id="b52d7e2d-d80f-4111-b6ed-d15502ee1edd"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="650"
        height="600"
        viewBox="0 0 500 500"
      >
        <g id="BACKGROUND">
          <rect style={{ fill: "transparent" }} width="500" height="500" />
        </g>
        <g id="OBJECTS">
          <g>
            <g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight, opacity: 0.3 }}
                    d="M326.352,115.105c0.338-0.734,1.24-1.187,0.559-1.944c-2.326-2.023-5.679-0.592-8.015-2.583
						c0.123-0.421,0.248-0.845,0.372-1.269c-2.317-0.498-4.783-0.894-7.267-1.355c-4.006-2.064-7.926-4.261-11.832-6.464
						c0.301-0.387,0.533-0.765,0.198-1.105c-1.556-1.077-4.09-1.721-6.586-2.674c-0.332-0.226-0.663-0.452-0.99-0.674
						c-0.164-0.379-0.053-0.83,0.014-1.106c-1.126-0.438-2.162-0.75-3.132-1.016c-11.017-7.558-22.271-15.403-34.874-23.326
						c-2.472-2.189-4.96-4.385-7.571-5.932c-3.98-1.987-9.595-3.028-13.703-4.309c2.143-4.146-7.156-9.655-3.692-13.409
						c-2.78-0.854-6.99-2.023-6.803-3.337c0.552-3.911-0.181-8.95-6.97-13.513c-2.773-1.424-7.75-1.223-8.554-2.297
						c-1.27-1.697,4.593-1.482,3.328-3.179c11.167,2.603,21.835,6.176,32.409,10.024c10.548,3.93,20.997,8.154,31.732,12.315
						c1.652,1.158,5.91,3.367,11.595,6.01c5.658,2.708,12.712,5.931,19.903,9.276c14.405,6.636,29.323,13.822,35.609,16.389
						l1.076,0.601c3.359,1.544,7.024,3.368,10.414,5.414c3.383,2.059,6.526,4.266,8.885,6.485c2.02,2.309,3.964,4.843,5.905,7.324
						c-1.544,0.037-2.998,0.122-4.298,0.278c2.629,1.295,5.267,2.579,5.854,3.854c4.511,4.744,0.296,5.053-3.282,6.665
						c-2.153,0.388-1.132,3.333-4.148,3.307c1.727,0.838,2.572,1.296,4.297,2.161c-0.429,1.245-4.871-3.438-4.724-0.91
						c1.87,0.428,3.289,2.148,5.001,3.009c-1.433,1.229-6.135-2.62-6.714-0.93c0.724,0.821,5.853,0.5,5.136,2.58
						c-0.147,0.418-1.853-0.449-2.707-0.881c4.841,3.434,6.648,6.8,0.821,6.294c1.654,0.838,4.399,1.301,5.973,2.51
						c-0.008,0.01-0.02,0.014-0.031,0.023c-0.893-0.41-2.591-0.832-3.396-1.243c0.992,0.503,1.987,1.008,2.98,1.512
						c-0.302,0.182-0.624,0.349-0.954,0.51c-0.857-0.168-1.693-0.382-2.467-0.773c0.657,0.333,1.315,0.667,1.977,1.002
						c-0.829,0.351-1.72,0.643-2.641,0.866c-2.98-0.042-6.161,0.55-7.422-0.088C347.09,124.66,336.629,119.992,326.352,115.105z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight, opacity: 0.3 }}
                    d="M317.796,149.643c0.293-0.755,1.916-0.898,0.075-2.155c-5.967-3.589-12.152-3.451-18.122-7.045
						c0.031-0.456,0.061-0.927,0.093-1.394c-5.1-1.735-10.474-3.406-15.907-5.23c-9.455-4.56-18.811-9.203-28.071-13.949
						c0.445-0.32,0.745-0.665-0.122-1.256c-3.782-2.125-9.415-4.212-15.11-6.682c-0.801-0.453-1.602-0.905-2.391-1.349
						c-0.523-0.552-0.496-1.044-0.482-1.347c-2.566-1.133-4.885-2.063-7.043-2.901c-6.498-3.706-13.021-7.425-19.598-11.176
						c-1.609-0.927-3.222-1.857-4.838-2.788c-0.4-0.373-0.844-0.767-1.331-1.182c-0.489-0.411-1.015-0.863-1.602-1.3
						c-0.575-0.437-1.328-0.753-1.979-0.587c-0.65,0.141-1.157,0.532-1.689,0.938c-0.536,0.411-1.102,0.863-1.696,1.361l-0.91,0.777
						l-0.059,0.049l-0.117,0.129l-0.242,0.27l-0.482,0.541l-0.963,1.086c-1.279,1.457-2.563,2.919-3.852,4.387l-1.923,2.221
						c-0.605,0.697-1.412,1.676-1.635,1.835c-0.703,0.683-1.438,1.414-2.2,2.196c-0.764,0.781-1.555,1.613-2.376,2.495l-1.253,1.362
						l-0.642,0.71l-0.326,0.362l-0.163,0.183l-0.083,0.092l-0.042,0.046c0.005,0.013,0.019,0.024,0.027,0.037
						c0.169,0.229,0.393,0.504,0.624,0.784c0.773,1.448,1.793,3.108,3.09,4.88c0.648,0.885,1.355,1.803,2.127,2.719
						c0.302,0.388,0.991,1.097,1.581,1.704c0.608,0.613,1.213,1.223,1.815,1.831c7.419,6.869,17.898,14.418,25.626,20.512
						c-4.121,2.643,13.181,19.503,6.536,20.122c5.195,4.054,13.043,10.084,12.634,11.422c-1.223,4.012-0.066,10.732,12.371,23.396
						c5.096,4.667,14.49,9.789,15.937,11.89c2.272,3.319-8.761-3.237-6.491,0.081c-17.824-12.903-34.855-26.282-51.579-40.01
						c-8.362-6.865-16.646-13.818-24.915-20.849c-12.83-10.979-25.706-21.998-38.678-33.099c3.881-5.774,7.793-11.593,11.727-17.444
						c1.975-2.92,3.974-5.843,6.001-8.765c1.628-2.314,3.259-4.634,4.892-6.957c1.511-1.677,3.943-4.671,7.132-8.633
						c1.389-1.778,2.863-3.665,4.415-5.65c0.807-1.06,1.669-2.094,2.552-3.144c0.886-1.044,1.748-2.199,2.783-3.053
						c0.499-0.464,1.003-0.933,1.513-1.406c0.508-0.475,1.017-0.969,1.564-1.354c1.083-0.81,2.211-1.544,3.419-2.192
						c1.204-0.651,2.493-1.221,3.888-1.687c0.695-0.233,1.419-0.437,2.158-0.592c0.733-0.185,1.483-0.319,2.234-0.395
						c3.009-0.38,5.875-0.173,9.017,0.561c1.571,0.373,3.196,0.901,4.833,1.578c1.638,0.675,3.275,1.499,4.934,2.272
						c2.661,1.265,5.376,2.557,8.14,3.872c1.804,0.869,3.639,1.752,5.501,2.648c7.461,3.554,15.342,7.308,23.313,11.105
						c31.929,15.096,65.458,30.522,79.352,36.417c0.824,0.4,1.645,0.799,2.464,1.198c15.059,6.65,33.072,15.325,44.994,22.742
						c5.351,3.656,10.701,7.571,16.044,11.432c-3.05-0.567-5.898-1.04-8.405-1.368c6.024,2.662,12.045,5.322,13.993,7.137
						c11.912,7.652,3.733,6.429-2.48,7.027c-4.098-0.373-0.214,3.734-6.317,2.468c4.005,1.769,6.012,2.655,10.035,4.432
						c-0.089,1.397-11.996-6.254-10.123-3.037c4.043,1.31,8.003,4.012,12.012,5.782c-2.12,0.976-14.077-5.741-14.191-3.878
						c1.98,1.355,12.184,2.993,12.045,5.321c-0.031,0.466-4.043-1.308-6.05-2.194c11.986,6.25,17.877,11.243,5.69,8.247
						c3.915,1.729,9.795,3.42,13.781,5.582c-0.01,0.01-0.032,0.011-0.046,0.018c-2.083-0.877-5.808-2.096-7.715-2.94
						c2.345,1.036,4.699,2.076,7.048,3.114c-0.495,0.109-1.037,0.19-1.602,0.262c-1.853-0.559-3.695-1.168-5.526-1.976
						c1.556,0.688,3.116,1.376,4.681,2.068c-1.456,0.109-3.077,0.118-4.81,0.026c-6.108-1.266-12.256-1.831-15.265-3.16
						C366.491,171.088,341.96,160.602,317.796,149.643z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight, opacity: 0.3 }}
                    d="M302.864,244.753c0.493-1.109,2.032-1.703,0.705-2.977c-4.448-3.469-10.25-1.642-14.734-5.058
						c0.155-0.639,0.315-1.292,0.474-1.941c-4.225-1.072-8.698-1.999-13.244-3.041c-7.519-3.747-14.896-7.671-22.278-11.647
						c0.491-0.564,0.854-1.124,0.201-1.7c-2.963-1.891-7.63-3.231-12.28-5.063c-0.631-0.401-1.263-0.801-1.884-1.194
						c-0.35-0.617-0.208-1.303-0.122-1.726c-2.099-0.846-4.018-1.482-5.811-2.035c-21.042-13.56-42.418-27.335-65.58-42.259
						c-4.587-3.946-9.171-7.89-13.946-10.851c-7.259-3.958-17.404-7.016-24.858-10.041c3.907-5.834-12.563-16.891-6.129-21.68
						c-4.939-2.008-12.292-5.152-11.854-7.101c0.657-2.927,0.758-6.354-0.709-10.18c-1.456-3.841-4.533-8.01-10.077-12.621
						c-4.586-3.084-13.242-4.444-14.474-6.341c-1.929-2.996,8.194-0.7,6.262-3.697c4.69,1.924,9.325,3.903,13.918,5.928
						c4.602,2.017,9.221,4.009,13.78,6.077c9.098,4.152,18.255,8.272,27.317,12.539c18.252,8.417,36.553,16.99,55.662,25.133
						c5.883,4.214,30.672,15.931,56.954,28.069c13.177,6.065,26.651,12.268,38.24,17.601c5.826,2.659,11.177,5.102,15.779,7.203
						c4.616,2.09,8.51,3.77,11.395,4.972c0.67,0.36,1.336,0.719,2.001,1.077c6.2,2.85,12.985,6.145,19.315,9.812
						c6.32,3.703,12.237,7.595,16.757,11.419c3.926,3.951,7.742,8.246,11.544,12.454c-2.736-0.175-5.305-0.258-7.59-0.207
						c4.85,2.442,9.721,4.852,10.936,6.969c8.632,8.273,1.205,8.054-4.889,10.023c-3.751,0.27-1.539,5.076-6.881,4.583
						c3.182,1.566,4.714,2.46,7.875,4.101c-0.302,0.955-2.557-0.606-4.667-1.906c-2.104-1.311-4.112-2.263-3.79-0.286
						c3.372,0.946,6.086,3.926,9.221,5.559c-1.185,0.857-3.971-0.428-6.559-1.609c-2.575-1.205-5.024-2.187-5.403-0.926
						c1.409,1.374,10.385,1.72,9.396,4.89c-0.204,0.638-3.32-1.011-4.879-1.833c8.975,6.221,12.575,11.824,2.263,10.088
						c3.013,1.59,7.903,2.764,10.824,4.926c-0.013,0.015-0.034,0.02-0.05,0.032c-1.624-0.791-4.665-1.732-6.133-2.51
						c1.808,0.954,3.622,1.912,5.434,2.867c-0.51,0.238-1.054,0.449-1.615,0.65c-1.53-0.402-3.025-0.875-4.435-1.616
						c1.197,0.631,2.397,1.264,3.602,1.899c-1.415,0.42-2.943,0.738-4.534,0.943c-5.244-0.544-10.748-0.11-13.038-1.318
						C341.155,262.45,321.973,253.708,302.864,244.753z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight, opacity: 0.3 }}
                    d="M303.702,187.586c-0.445,1.129-2.417,1.576-0.35,3.11c6.754,4.292,14.215,3.118,20.997,7.338
						c-0.084,0.661-0.171,1.346-0.258,2.024c5.961,1.65,12.237,3.191,18.592,4.859c10.92,4.936,21.772,9.887,32.541,14.936
						c-0.553,0.547-0.935,1.104,0.065,1.79c4.394,2.322,11.013,4.217,17.694,6.604c0.937,0.486,1.873,0.971,2.793,1.448
						c0.598,0.688,0.544,1.402,0.514,1.843c2.374,0.865,4.571,1.582,6.512,2.17l23.285,7.05l-7.949,26.196
						c-0.264,0.869-0.474,1.564-0.681,2.245l-2.083,4.294c-0.879,1.827-1.843,3.597-2.791,5.36
						c-1.947,3.491-3.974,6.893-6.171,10.074c-2.189,3.181-4.438,6.253-6.809,9.079c-2.362,2.842-4.776,5.505-7.16,8.169
						c-2.39,2.635-4.786,5.275-7.186,7.923l-0.451,0.492c-0.076,0.079-0.148,0.17-0.228,0.239l-0.332-0.125l-1.327-0.502
						l-2.646-1.003c-1.746-0.666-3.495-1.334-5.245-2.002c-3.428-1.327-6.864-2.657-10.311-3.991
						c-0.845-0.346-1.707-0.634-2.53-1.025l-2.452-1.195c-1.618-0.791-3.238-1.583-4.862-2.376c-3.176-1.559-6.363-3.122-9.56-4.691
						l-2.356-1.16l-0.571-0.282l-0.379-0.202l-0.759-0.404c-1.013-0.539-2.028-1.079-3.044-1.62
						c-4.068-2.16-8.159-4.363-12.313-6.515c-8.292-4.347-16.759-8.693-25.443-13.037c-7.048-4.493-14.156-8.913-21.316-12.314
						c-10.788-4.531-25.3-8.146-36.193-11.435c3.861-6.073-21.648-17.807-14.264-22.767c-3.673-1.07-8.268-2.406-11.944-3.731
						c-3.681-1.306-6.447-2.593-6.444-3.618c0.004-6.121-3.646-14.391-22.363-23.508c-7.48-3.053-20.025-3.966-22.426-5.882
						c-3.789-3.013,11.13-1.178,7.348-4.19c14.549,3.503,28.845,7.44,42.982,11.681c14.142,4.224,28.121,8.773,42.033,13.547
						c13.918,4.755,27.771,9.737,41.659,14.87c13.894,5.118,27.829,10.366,41.905,15.705c2.159,1.206,5.895,2.98,10.837,5.243
						c2.332,1.048,4.944,2.222,7.796,3.503c0.638,0.407,1.371,0.846,2.193,1.311c0.207,0.116,0.419,0.233,0.632,0.354
						c0.143,0.091,0.18,0.141,0.282,0.214c0.173,0.138,0.363,0.278,0.567,0.423l0.316,0.221l0.165,0.112
						c0.027,0.014,0.06,0.05,0.082,0.045l0.061-0.041c0.163-0.107,0.329-0.236,0.496-0.382c0.334-0.292,0.673-0.658,0.995-1.092
						c0.161-0.217,0.319-0.449,0.469-0.698l0.112-0.189c0.047-0.088,0.029-0.016,0.216-0.474c0.353-0.824,0.671-1.663,0.999-2.501
						l15.961,34.601c-19.997-8.013-40.703-16.55-58.52-24.034c-17.819-7.479-32.772-13.86-41.365-17.324
						c-0.972-0.465-1.938-0.927-2.904-1.39c-17.809-7.529-38.888-17.95-52.447-27.448c-6.04-4.727-12.012-9.838-17.935-14.923
						c3.653,0.555,7.079,0.966,10.11,1.19c-6.98-3.231-13.909-6.568-15.998-8.988c-13.269-10.026-3.411-8.92,4.28-10.304
						c4.951,0.162,0.929-5.371,8.162-4.119c-4.581-2.161-6.866-3.265-11.45-5.475c0.184-1.006,3.555,0.983,6.671,2.666
						c3.126,1.662,5.993,3.019,5.133,0.798c-4.71-1.488-9.037-5.046-13.6-7.24c1.386-0.789,5.409,0.969,9.146,2.588
						c3.744,1.606,7.212,3.054,7.444,1.716c-2.177-1.733-14.307-3.197-13.703-6.554c0.124-0.671,4.68,1.527,6.959,2.625
						c-13.457-7.918-19.601-14.622-5.381-11.325c-4.429-2.127-11.228-4.111-15.651-6.883c0.015-0.015,0.04-0.017,0.06-0.029
						c2.358,1.092,6.644,2.543,8.793,3.6c-2.643-1.298-5.298-2.601-7.947-3.9c0.624-0.194,1.3-0.354,2.001-0.499
						c2.141,0.652,4.255,1.378,6.32,2.388c-1.754-0.86-3.511-1.722-5.274-2.587c1.791-0.271,3.761-0.413,5.843-0.421
						c7.146,1.341,14.495,1.564,17.876,3.202C248.022,163.208,275.934,175.428,303.702,187.586z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight, opacity: 0.3 }}
                    d="M282.668,303.826c0.5-1.107,1.928-1.754,0.779-2.942c-3.887-3.196-9.213-1.199-13.113-4.363
						c0.173-0.632,0.351-1.276,0.527-1.918c-3.774-0.883-7.789-1.615-11.83-2.473c-6.589-3.392-13.052-6.965-19.468-10.587
						c0.472-0.57,0.829-1.132,0.272-1.673c-2.563-1.743-6.695-2.886-10.774-4.506c-0.545-0.367-1.091-0.734-1.626-1.096
						c-0.282-0.591-0.118-1.269-0.02-1.687c-1.838-0.746-3.526-1.294-5.105-1.767c-18.104-12.287-36.484-25.093-56.901-38.228
						c-4.003-3.549-8.024-7.105-12.242-9.686c-6.428-3.377-15.49-5.504-22.124-7.844c3.509-6.07-11.4-15.344-5.726-20.673
						c-4.465-1.56-11.215-3.778-10.878-5.745c0.996-5.864-0.055-13.536-10.78-21.155c-4.394-2.449-12.39-2.687-13.638-4.395
						c-1.967-2.699,7.434-1.736,5.471-4.435c17.779,5.155,34.753,11.607,51.599,18.425c16.816,6.907,33.495,14.201,50.677,21.322
						c2.643,1.892,9.473,5.538,18.588,9.963c9.085,4.497,20.418,9.86,31.998,15.365c23.189,10.95,47.336,22.529,57.491,26.691
						c0.585,0.319,1.167,0.637,1.748,0.955c5.441,2.473,11.38,5.395,16.907,8.621c5.519,3.242,10.667,6.705,14.575,10.158
						c3.379,3.571,6.658,7.486,9.938,11.323c-2.458,0.017-4.771,0.109-6.835,0.312c4.293,2.047,8.593,4.079,9.63,6.037
						c7.577,7.337,0.886,7.725-4.713,10.1c-3.416,0.538-1.542,5.069-6.374,4.941c2.833,1.338,4.23,2.05,7.065,3.416
						c-0.586,1.895-8.091-5.379-7.648-1.515c3.029,0.712,5.452,3.369,8.27,4.731c-2.193,1.844-10.059-4.162-10.846-1.603
						c1.227,1.287,9.432,0.924,8.456,4.088c-0.199,0.635-3.01-0.735-4.417-1.419c8.057,5.376,11.267,10.582,1.862,9.65
						c2.732,1.328,7.181,2.11,9.817,4.003c-0.012,0.016-0.032,0.022-0.047,0.035c-1.472-0.65-4.235-1.344-5.565-1.993
						c1.638,0.797,3.282,1.597,4.922,2.395c-0.469,0.271-0.969,0.518-1.485,0.756c-1.392-0.279-2.754-0.631-4.033-1.25
						c1.086,0.528,2.175,1.058,3.267,1.589c-1.3,0.515-2.703,0.939-4.163,1.255c-4.796-0.148-9.869,0.667-11.959-0.349
						C316.855,319.366,299.597,311.813,282.668,303.826z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight, opacity: 0.3 }}
                    d="M288.975,349.409c0.057-1.212,0.823-2.373-0.206-3.005c-3.28-1.381-6.234,2.529-9.514,1.164
						c-0.041-0.652-0.082-1.311-0.123-1.969c-2.648,0.673-5.415,1.588-8.228,2.363c-5.066-0.561-10.097-1.316-15.105-2.202
						c0.166-0.708,0.26-1.363-0.229-1.647c-2.073-0.623-5.011-0.081-8.031-0.013c-0.44-0.132-0.88-0.264-1.313-0.394
						c-0.324-0.438-0.383-1.127-0.421-1.551c-1.364,0.01-2.584,0.147-3.716,0.311c-14.634-4.462-29.57-9.454-45.901-14.209
						c-3.438-1.851-6.891-3.702-10.233-4.573c-4.958-0.813-11.305,0.442-16.142,0.64c0.775-6.92-11.079-10.3-8.734-17.296
						c-3.254,0.139-8.138,0.416-8.403-1.543c-0.794-5.852-3.349-12.635-12.12-16.057c-3.428-0.777-8.631,1.768-9.854,0.597
						c-1.928-1.852,4.356-4.201,2.431-6.055c12.708-1.28,25.215-1.228,37.728-0.851c12.515,0.425,25.038,1.185,37.843,1.45
						c4.328,1.63,21.161,3.137,38.774,4.464c17.616,1.283,36.021,2.421,43.592,2.229c0.455,0.06,0.909,0.12,1.361,0.18
						c8.236,0.164,18.176,1.432,24.901,4.599c3.05,1.886,6.116,4.126,9.164,6.296c-1.588,1.012-3.064,2.034-4.351,3.057
						c3.273,0.123,6.547,0.237,7.691,1.595c6.676,3.569,2.432,6.664-0.624,11.12c-2.084,1.886,0.219,5.254-2.94,7.1
						c2.154,0.088,3.233,0.133,5.397,0.222c0.076,1.969-6.535-1.584-5.317,1.749c2.131-0.578,4.341,0.835,6.493,0.923
						c-0.978,2.579-7.516,0.334-7.41,2.976c1.103,0.686,6.331-3.018,6.459,0.264c0.023,0.66-2.128,0.565-3.203,0.521
						c6.511,1.589,9.839,5.01,3.524,8.017c2.088,0.089,5.158-1.023,7.319-0.381c-0.005,0.019-0.015,0.032-0.022,0.051
						c-1.109,0.011-3.065,0.513-4.083,0.467c1.253,0.055,2.509,0.108,3.763,0.162c-0.239,0.439-0.503,0.868-0.781,1.296
						c-0.968,0.316-1.934,0.555-2.911,0.516c0.83,0.035,1.661,0.07,2.497,0.106c-0.719,1.001-1.525,1.962-2.395,2.849
						c-3.141,1.831-6.229,4.651-7.825,4.585C314.797,349.967,301.826,350.036,288.975,349.409z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight, opacity: 0.3 }}
                    d="M282.194,377.129c0.167-0.368,0.933-0.422,0.121-1.061c-2.65-1.833-5.567-1.788-8.229-3.602
						l0.095-0.686c-2.328-0.887-4.779-1.753-7.27-2.657c-4.284-2.266-8.507-4.595-12.721-6.902c0.218-0.158,0.368-0.329-0.021-0.619
						c-1.714-1.039-4.294-2.066-6.896-3.267c-0.366-0.219-0.73-0.438-1.089-0.652c-0.231-0.269-0.207-0.511-0.193-0.66
						c-1.176-0.545-2.241-0.99-3.233-1.393c-12.11-7.287-24.436-14.72-38.013-22.424c-2.759-1.877-5.525-3.756-8.314-5.289
						c-4.197-2.122-9.878-4.088-14.109-5.829c1.556-1.847-8.311-7.228-5.356-8.513c-2.835-1.157-7.124-2.822-7.093-3.519
						c0.082-2.067-1.225-5.087-8.361-9.234c-2.866-1.446-7.728-2.51-8.627-3.297c-1.417-1.242,4.356,0.261,2.941-0.982
						c11.189,4.06,21.963,8.578,32.686,13.251c10.703,4.726,21.35,9.617,32.25,14.579c3.5,2.154,17.695,9.054,32.462,16.469
						c14.789,7.368,30.13,15.284,36.496,18.341l1.121,0.623c3.432,1.744,7.208,3.726,10.772,5.789
						c3.558,2.073,6.929,4.18,9.583,6.115c2.358,1.914,4.702,3.955,7.042,5.966c-1.415-0.343-2.737-0.636-3.905-0.85
						c2.716,1.438,5.435,2.866,6.263,3.807c5.225,4.06,1.426,3.253-1.524,3.399c-1.909-0.277-0.287,1.823-3.099,1.075
						c1.793,0.961,2.69,1.442,4.491,2.408c-0.11,0.679-5.316-3.354-4.602-1.726c1.839,0.724,3.554,2.158,5.346,3.119
						c-1.045,0.426-6.313-3.153-6.467-2.228c0.869,0.692,5.568,1.747,5.383,2.886c-0.039,0.229-1.83-0.737-2.725-1.221
						c5.302,3.354,7.795,5.94,2.236,4.186c1.744,0.939,4.414,1.908,6.168,3.061c-0.005,0.005-0.016,0.005-0.023,0.008
						c-0.93-0.48-2.614-1.165-3.463-1.624c1.045,0.564,2.094,1.129,3.14,1.693c-0.238,0.042-0.497,0.069-0.767,0.091
						c-0.84-0.317-1.671-0.66-2.487-1.099c0.694,0.373,1.388,0.748,2.086,1.124c-0.689,0.019-1.451-0.015-2.259-0.101
						c-2.796-0.763-5.643-1.181-6.981-1.9C304.071,388.289,293.083,382.732,282.194,377.129z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight, opacity: 0.3 }}
                    d="M253.25,371.26c0.07-0.4,1.128-0.529-0.308-1.115c-2.277-0.855-4.496-1.063-6.673-1.363
						c-2.191-0.266-4.355-0.587-6.694-1.285c-0.066-0.234-0.134-0.473-0.201-0.71c-3.759-0.495-7.646-1.045-11.66-1.473
						c-7.238-1.518-14.481-2.94-21.733-4.303c0.227-0.206,0.351-0.409-0.362-0.607c-1.504-0.321-3.316-0.588-5.297-0.85
						c-0.496-0.064-1.001-0.13-1.516-0.196c-0.346-0.047-0.281-0.028-0.374-0.035l-0.186-0.005c-0.242-0.01-0.49-0.021-0.74-0.031
						c-0.163-0.062-0.325-0.122-0.485-0.183c-0.176-0.168-0.316-0.419-0.403-0.573c-0.539-0.054-1.059-0.065-1.165-0.006
						c-1.141-0.478-2.804-1.038-5.054-1.67c-1.125-0.319-2.396-0.655-3.821-1.011c-0.75-0.183-1.5-0.366-2.254-0.55l-0.6-0.143
						l-0.305-0.072l-0.154-0.036c-0.066-0.018-0.014,0.011-0.027,0.014c0.022,0.07,0.051,0.146,0.088,0.227
						c0.581,1.294,3.098,3.977,7.976,8.311c0.447,0.497,0.939,1.026,1.479,1.583l0.413,0.421l0.686,0.61l1.439,1.257
						c1.92,1.664,3.844,3.282,5.777,4.807c5.816,4.386,13.665,9.372,19.519,13.388c-2.217,1.263,11.293,12.119,7.122,12.042
						c3.897,2.674,9.755,6.689,9.676,7.433c-0.23,2.228,1.445,6.159,11.036,14.326c3.86,3.046,10.512,6.707,11.7,8.024
						c1.866,2.079-6.011-2.535-4.145-0.457c-15.142-10.191-29.78-20.505-44.396-30.908c-7.303-5.208-14.612-10.422-21.965-15.666
						c-13.13-9.262-26.338-18.579-39.69-27.999c-2.143-1.69-6.004-4.595-11.121-8.383c-2.561-1.886-5.431-4-8.565-6.308
						c-1.566-1.147-3.199-2.342-4.891-3.58c-0.845-0.618-1.706-1.246-2.58-1.885c-0.438-0.319-0.878-0.641-1.322-0.965l-0.668-0.487
						c-0.112-0.063,0.012-0.033,0.084-0.025l0.226,0.029l0.454,0.059c9.493,1.214,20.119,2.573,31.379,4.013
						c5.499,0.701,11.153,1.423,16.901,2.155c2.857,0.367,5.737,0.737,8.633,1.108c1.433,0.187,2.869,0.373,4.308,0.561
						c1.377,0.194,2.757,0.39,4.139,0.585c2.757,0.389,5.521,0.778,8.287,1.168l4.138,0.582l1.979,0.28l1.191,0.19
						c3.175,0.506,6.334,1.009,9.447,1.505c6.225,1.075,12.259,2.104,17.835,3.213c1.395,0.266,2.761,0.525,4.094,0.778
						c1.331,0.281,2.63,0.556,3.892,0.822c2.527,0.53,4.907,1.029,7.108,1.491c4.396,1.009,8.077,1.853,10.781,2.474
						c0.649,0.186,1.296,0.37,1.943,0.556c2.935,0.771,6.027,1.589,9.156,2.474c3.112,0.97,6.267,1.98,9.353,3.005
						c6.134,2.208,11.996,4.493,16.617,6.867c1.048,0.563,2.101,1.13,3.158,1.698l1.577,0.863l1.551,0.938
						c2.055,1.257,4.117,2.519,6.174,3.777c-2.186-0.554-4.215-1.035-5.985-1.408c2.221,0.955,4.442,1.911,6.297,2.77
						c1.865,0.84,3.292,1.744,4.041,2.323c8.769,5.475,2.982,3.877-1.184,3.556c-2.8-0.63,0.108,1.999-4.086,0.689
						c2.816,1.367,4.228,2.052,7.057,3.426c0.028,0.744-8.5-4.612-7.014-2.676c2.801,1.118,5.643,2.981,8.45,4.343
						c-1.379,0.306-9.823-4.526-9.768-3.522c1.404,0.92,8.355,2.84,8.399,4.073c0.005,0.246-2.79-1.11-4.188-1.789
						c2.1,1.147,3.95,2.19,5.38,3.115c1.404,0.975,2.423,1.774,2.93,2.316c1.017,1.086-0.017,1.145-4.051-0.442
						c2.62,1.436,6.54,3.107,9.232,4.786c-0.007,0.004-0.02,0.002-0.03,0.004c-1.399-0.742-3.887-1.88-5.165-2.58
						c1.574,0.86,3.153,1.724,4.729,2.585c-0.324-0.007-0.681-0.033-1.05-0.069c-1.236-0.538-2.461-1.103-3.686-1.772
						c1.041,0.569,2.085,1.14,3.131,1.712c-0.955-0.13-2.017-0.332-3.15-0.601c-2.016-0.719-4.003-1.371-5.763-1.919
						c-1.789-0.502-3.313-0.967-4.333-1.461c-8.255-3.957-16.887-7.207-25.568-10.287c-4.395-1.423-8.74-2.954-13.205-4.195
						c-2.225-0.639-4.418-1.355-6.661-1.942L253.25,371.26z"
                  />
                </g>
              </g>
              <path
                style={{ fill: theme.imageHighlight, opacity: 0.5 }}
                d="M350.131,424.8c-11.566-0.858-22.948-1.135-34.345-1.252c-11.4-0.065-22.817,0.044-34.487,0.105
				c-1.473,0.197-4.518,0.388-8.584,0.584c6.128-1.16,12.41-2.337,18.983-3.496c2.558-0.734,5.121-1.468,7.604-1.872
				c3.689-0.438,8.433-0.227,12.045-0.32c-0.66-2.281,8.102-3.786,6.247-6.04c2.413-0.06,6.039-0.117,6.199-0.777
				c0.484-1.972,2.253-4.313,8.636-5.717c2.505-0.361,6.394,0.329,7.267-0.098c1.375-0.674-3.305-1.267-1.93-1.941
				c-18.729-0.088-36.701,1.571-55.507,2.624c-3.173,0.692-15.543,1.777-28.496,2.873c-12.954,1.076-26.492,2.178-32.044,2.411
				l-1.002,0.115c-6.047,0.381-13.363,1.209-18.386,2.55c-2.29,0.762-4.607,1.643-6.914,2.5c1.137,0.274,2.192,0.557,3.11,0.847
				c-2.414,0.179-4.827,0.353-5.711,0.859c-5.03,1.486-2.012,2.349,0.107,3.718c1.482,0.546-0.331,1.777,1.952,2.265
				c-1.6,0.123-2.401,0.184-4.008,0.307c-0.121,0.666,4.895-0.814,3.887,0.36c-1.564-0.104-3.248,0.469-4.848,0.591
				c0.64,0.826,5.568-0.213,5.405,0.683c-0.843,0.277-4.604-0.743-4.807,0.367c-0.04,0.224,1.561,0.099,2.362,0.037
				c-2.668,0.445-4.644,1.035-5.15,1.599c-2.05,0.289-3.983,0.613-5.7,0.98c-2.86,0.772-5.757,1.665-8.639,2.533
				c1.395,0.271,2.688,0.549,3.812,0.835c-2.988,0.19-5.976,0.372-7.086,0.888c-6.263,1.543-2.563,2.374,0.003,3.725
				c1.809,0.534-0.47,1.778,2.329,2.256c-1.976,0.137-2.967,0.205-4.953,0.342c-0.171,0.665,6.064-0.862,4.78,0.326
				c-1.924-0.099-4.02,0.5-5.997,0.637c0.76,0.819,6.87-0.268,6.639,0.637c-1.049,0.273-5.649-0.703-5.937,0.41
				c-0.057,0.224,1.921,0.082,2.91,0.012c-6.051,0.869-9.298,2.196-3.655,2.89c-1.925,0.137-4.685-0.082-6.705,0.245
				c0.003,0.007,0.012,0.011,0.017,0.017c1.018-0.053,2.789,0.017,3.727-0.051c-1.154,0.082-2.312,0.165-3.467,0.247
				c0.196,0.136,0.416,0.268,0.646,0.398c0.874,0.057,1.749,0.088,2.649,0.025c-0.766,0.055-1.532,0.109-2.302,0.164
				c0.606,0.302,1.297,0.585,2.049,0.841c2.791,0.458,5.49,1.252,6.966,1.146c11.952-0.482,23.936-1.02,35.85-1.645
				c-0.002-0.405-0.662-0.769,0.315-1.017c3.087-0.58,5.663,0.66,8.751,0.102l0.189-0.664c2.422,0.149,4.947,0.368,7.522,0.567
				c4.711-0.305,9.394-0.691,14.06-1.066c-0.13-0.233-0.196-0.451,0.266-0.555c1.938-0.245,4.64-0.121,7.433-0.146
				c0.412-0.05,0.823-0.1,1.227-0.148c0.313-0.15,0.388-0.381,0.435-0.523c1.263-0.014,2.387,0.017,3.429,0.058
				c13.671-1.676,27.591-3.401,42.732-4.873c3.202-0.583,6.411-1.166,9.501-1.424c4.577-0.219,10.41,0.31,14.867,0.451
				c-0.646-2.322,10.272-3.201,8.158-5.582c2.982,0.105,7.459,0.334,7.709-0.313c0.752-1.921,3.12-4.143,11.12-5.017
				c3.125-0.151,7.871,0.862,8.985,0.511C354.108,426.34,348.377,425.356,350.131,424.8z"
              />
            </g>
            <g>
              <g>
                <g>
                  <path
                    style={{ fill: "#A1CEEC" }}
                    d="M283.415,258.494c-0.881,3.195-0.488,6.517-0.918,9.796c-1.018,7.696-1.994,14.827-5.042,22.114
						c-1.348,3.222-1.999,7.13-2.498,10.609c-0.33,2.318,1.076,5.598,0.016,7.634c0.446,0.33,0,4.706,0,5.781
						c0,0.692-0.267,1.773-0.157,2.419c0.231,1.38,0.94,1.301,0.814,2.765c-0.084,0.955-1.128,2.613-0.609,3.536
						c0.792,1.391,2.303,0.735,2.409,2.976c0.089,1.973,0.251,3.431,0.692,5.383c1.962,8.594,0.96,18.894-2.052,27.209
						c-1.532,4.219-2.355,8.746-3.546,13.069c-0.976,3.542,1.616,6.512,0.745,10.194c-0.283,1.207-1.301,2.167-1.621,3.279
						c-0.357,1.238,0.2,1.495,0.483,2.613c0.687,2.696-1.889,3.457-1.669,5.949c0.121,1.38,1.181,2.781,1.458,4.202
						c0.425,2.199-0.96,2.75-2.293,4.276c-1.096,1.265-1.076,1.952-1.086,3.547c-0.016,1.852-0.651,2.067-1.464,3.841
						c-2.497-0.441-5.215-0.798-7.765-0.992c-3.085-0.235-6.212,0.593-9.187,0.515c-0.96-0.026-2.765-0.767-3.321-1.895
						c-0.986-1.988,1.044-1.935,1.915-2.995c0.986-1.191,0.052-1.784-0.163-3.201c-0.231-1.5,0.388-2.402,1.149-3.625
						c1.616-2.613,2.03-3.768,0.845-6.554c-1.186-2.785-1.595-4.59-0.934-7.623c0.389-1.81,0.404-3.615,0.835-5.425
						c0.278-1.181,0.913-2.293,1.312-3.426c0.136-0.399,0.247-0.803,0.299-1.212c0.179-1.364-0.43-2.718-0.524-4.066
						c-0.168-2.382-0.451-4.732-0.688-7.099c-0.482-4.858,0.845-9.633,1.338-14.513c0.137-1.327,0.331-2.696,0.52-4.076
						c0.357-2.598,0.692-5.236,0.583-7.744c-0.058-1.391,0.126-4.146-0.352-5.42c-1.658,0.624-3.216-2.771-3.148-4.161
						c0.105-2.015,0.803-3.605,0.698-5.677c-0.152-3.048,0.278-6.217,0.005-9.233c-0.257-2.844-1.07-5.897-1.527-8.768
						c-0.446-2.807-1.191-4.58-0.934-7.319c0.178-1.899-0.257-3.552-0.168-5.451c0.121-2.487-0.357-4.885-0.352-7.313
						c0-2.063,0.268-3.573-0.184-5.635c-0.356-1.632-0.724-2.907-1.422-4.287c-0.661-1.311-0.572-3.725-1.364-4.942
						c0.142,0.157-1.548,1.548-2.115,2.109c-0.503,3.447-1.432,7.319-2.476,10.698c-2.413,7.833-1.81,16.028-3.096,23.919
						c-0.446,2.733,0.289,4.79-0.708,7.356c-0.729,1.856,0.729,4.14-2.072,4.742c-0.241,1.05,0.441,1.758,0.331,2.766
						c0,0.021-0.005,0.047-0.01,0.068c-0.184,1.274-1.542,2.56-1.574,3.956c0.54-0.068,1.039,0.928,1.485,2.413
						c0.168,0.556,0.331,1.181,0.478,1.847c0.813,3.552,1.38,8.2,1.589,9.266c0.714,3.6,0.645,7.063,0.824,10.798
						c0.105,2.214,0.54,4.386,1.259,6.49c0.734,2.167,1.716,3.032,1.406,5.43c-0.294,2.267-0.43,3.353,0.089,5.63
						c0.398,1.731,0.346,3.83,0.226,5.608c-0.226,3.206-0.797,4.78-3.683,5.992c-1.773,0.745-3.295,2.245-5.068,2.97
						c-0.708,0.294-1.458,0.462-2.277,0.403c-2.072-0.142-6.826-1.558-7.781-3.688c-0.351-0.771,0.252-1.642,0.053-2.617
						c-0.284-1.391-1.443-2.708-1.931-4.056c-0.482-1.328-0.619-2.592-0.834-3.894c-0.063-0.378-0.131-0.766-0.22-1.154
						c-0.236-1.055-0.976-1.747-1.27-2.691c-0.598-1.915,2.099-4.748-0.608-5.692c-1.742-0.608-2.813-9.749-3.499-12.319
						c-1.322-4.964-4.297-9.57-3.856-15.137c0.158-1.968,0.52-2.271,0.011-4.171c-0.404-1.517-1.333-1.831-1.396-3.699
						c-0.052-1.495,0.047-2.691,0.105-3.861c0.068-1.207,0.094-2.382-0.116-3.835c-0.535-3.673-1.343-7.524-1.779-11.611
						c-0.189-1.747-0.31-3.536-0.31-5.367c0-6.238,0.593-12.456,0-18.61c-0.404-4.202-2.917-5.902-3.867-9.711
						c-1.118-4.486,0.839-11.149,1.055-15.777c0.21-4.475,0.876-8.741,1.212-13.164c0.121-1.579,0.2-3.174,0.2-4.811
						c0-0.944,0.514-1.789,0.173-2.645c0.646-0.409,1.217-0.708,1.747-0.997c0.068-0.042,0.131-0.079,0.2-0.116
						c0.834-0.467,1.574-0.939,2.387-1.841c2.686-2.97,6.653-4.958,10.666-5.682c5.551-1.003,11.569,0.734,17.183,0.792
						c3.998,0.042,7.991-0.184,11.994-0.178c4.56,0.01,10.011-1.034,14.439,0.01c2.781,0.656,5.063,1.836,7.985,2.183
						c2.309,0.273,4.271,0.761,6.464,1.522c4.093,1.411,4.502,3.221,6.338,6.71c0.624,1.186,0.929,2.481,1.018,3.835
						c0.278,3.946-1.217,8.405-1.684,12.162c-0.021,0.121-0.037,0.241-0.047,0.356C282.854,253.216,284.25,255.478,283.415,258.494z
						"
                  />
                </g>
                <g>
                  <g>
                    <path
                      style={{ fill: "#89BEE3" }}
                      d="M284.911,237.738c-0.089-1.354-0.394-2.649-1.018-3.835c-1.836-3.489-2.245-5.299-6.338-6.71
							c-2.193-0.761-4.155-1.249-6.464-1.522c-2.922-0.346-5.205-1.526-7.985-2.183c-4.428-1.044-9.879,0-14.439-0.01
							c-4.003-0.005-7.996,0.22-11.994,0.178c-5.01-0.052-10.347-1.443-15.383-1.028c-0.604,0.053-1.207,0.126-1.8,0.236
							c-4.013,0.724-7.98,2.712-10.666,5.682c-0.813,0.902-1.553,1.374-2.387,1.841c-0.582,0.331-1.217,0.651-1.947,1.112
							c0.341,0.855-0.173,1.7-0.173,2.645c0,6.212-1.128,11.905-1.412,17.975c-0.215,4.628-2.172,11.291-1.055,15.777
							c0.95,3.809,3.463,5.509,3.867,9.711c0.593,6.154,0,12.372,0,18.61c0,6.134,1.328,11.736,2.088,16.978
							c0.415,2.859-0.094,4.664,0.01,7.696c0.063,1.868,0.992,2.183,1.396,3.699c0.509,1.899,0.147,2.203-0.011,4.171
							c-0.441,5.566,2.534,10.173,3.856,15.137c0.687,2.57,1.758,11.711,3.499,12.319c2.707,0.944,0.011,3.777,0.608,5.692
							c0.294,0.944,1.034,1.637,1.27,2.691c0.388,1.716,0.43,3.332,1.054,5.048c0.488,1.348,1.648,2.665,1.931,4.056
							c0.199,0.976-0.404,1.847-0.053,2.617c0.955,2.131,5.709,3.547,7.781,3.688c0.819,0.059,1.569-0.109,2.277-0.403
							c-1.679-4.166-4.177-8.421-4.791-10.782c-2.513-9.648-3.567-20.834-0.425-30.315c1.349-4.071,6.569-6.962,4.811-11.148
							c0.105-0.09,0.21-0.168,0.315-0.247c1.175,0.068,2.33,0.131,3.447,0.31c-0.147-0.666-0.31-1.291-0.478-1.847
							c-0.446-1.485-0.945-2.481-1.485-2.413c0.032-1.396,1.391-2.682,1.574-3.956c0.005-0.022,0.01-0.048,0.01-0.068
							c0.11-1.008-0.572-1.716-0.331-2.766c2.802-0.603,1.343-2.886,2.072-4.742c0.997-2.566,0.263-4.623,0.708-7.356
							c0.976-5.976,0.866-12.125,1.8-18.158c0.304-1.937,0.708-3.857,1.296-5.761c1.044-3.379,1.973-7.251,2.476-10.698
							c0.567-0.562,2.256-1.952,2.115-2.109c0.792,1.218,0.703,3.631,1.364,4.942c0.698,1.38,1.066,2.655,1.422,4.287
							c0.452,2.061,0.184,3.572,0.184,5.635c-0.005,2.429,0.472,4.826,0.352,7.313c-0.089,1.9,0.346,3.552,0.168,5.451
							c-0.257,2.739,0.488,4.513,0.934,7.319c0.457,2.87,1.27,5.924,1.527,8.768c0.272,3.017-0.158,6.186-0.005,9.233
							c0.105,2.072-0.593,3.662-0.698,5.677c-0.068,1.391,1.49,4.785,3.148,4.161c0.477,1.274,0.294,4.029,0.352,5.42
							c0.068,1.542-0.032,3.132-0.205,4.732c-0.105,1.002-0.241,2.009-0.378,3.012c2.183-0.525,4.481-1.548,6.721-1.905
							c4.522-0.729,9.79,0.714,13.961-1.484c2.346,11.154-3.835,24.197-8.762,33.852c-2.382,4.664-1.695,4.029-5.797,3.022
							c-2.146-0.524-4.696-1.522-7.067-2.518c-0.399,1.133-1.034,2.245-1.312,3.426c-0.431,1.809-0.446,3.615-0.835,5.425
							c-0.661,3.033-0.252,4.838,0.934,7.623c0.892,2.1,0.876,3.27,0.126,4.838c-0.247,0.515-0.572,1.07-0.971,1.716
							c-0.761,1.223-1.38,2.125-1.149,3.625c0.215,1.417,1.149,2.01,0.163,3.201c-0.871,1.06-2.901,1.007-1.915,2.995
							c0.556,1.128,2.361,1.868,3.321,1.895c2.975,0.078,6.102-0.75,9.187-0.515c2.55,0.194,5.268,0.551,7.765,0.992
							c0.813-1.773,1.448-1.988,1.464-3.841c0.01-1.595-0.01-2.282,1.086-3.547c1.333-1.526,2.718-2.077,2.293-4.276
							c-0.278-1.421-1.338-2.822-1.458-4.202c-0.221-2.492,2.355-3.253,1.669-5.949c-0.283-1.118-0.84-1.375-0.483-2.613
							c0.32-1.112,1.338-2.072,1.621-3.279c0.871-3.683-1.721-6.652-0.745-10.194c1.191-4.323,2.015-8.851,3.546-13.069
							c3.012-8.315,4.014-18.615,2.052-27.209c-0.1-0.436-0.184-0.845-0.257-1.238c-0.252-1.385-0.367-2.613-0.435-4.145
							c-0.105-2.24-1.616-1.585-2.409-2.976c-0.519-0.923,0.525-2.581,0.609-3.536c0.126-1.464-0.582-1.385-0.814-2.765
							c-0.11-0.646,0.157-1.727,0.157-2.419c0-1.075,0.446-5.451,0-5.781c0.163-0.315,0.268-0.661,0.331-1.028
							c0.335-1.999-0.625-4.644-0.347-6.605c0.499-3.479,1.149-7.388,2.498-10.609c3.048-7.287,4.024-14.418,5.042-22.114
							c0.43-3.279,0.037-6.6,0.918-9.796c0.834-3.017-0.561-5.278-0.236-8.237c0.011-0.115,0.032-0.236,0.047-0.356
							c0.163-1.322,0.456-2.729,0.75-4.171c0.005-0.026,0.011-0.047,0.016-0.073c0.021-0.089,0.042-0.184,0.058-0.278
							c0.047-0.225,0.094-0.451,0.142-0.682c0-0.021,0.005-0.047,0.01-0.073c0.047-0.236,0.095-0.472,0.142-0.708
							c0.005-0.026,0.011-0.052,0.016-0.079c0.042-0.236,0.084-0.467,0.126-0.703c0.005-0.031,0.016-0.063,0.021-0.1
							c0.037-0.226,0.079-0.451,0.11-0.677c0.011-0.042,0.016-0.089,0.021-0.131c0.037-0.215,0.068-0.435,0.095-0.65
							c0.01-0.052,0.016-0.105,0.021-0.157c0.026-0.205,0.052-0.415,0.073-0.619c0.011-0.063,0.011-0.126,0.021-0.194
							c0.016-0.194,0.037-0.388,0.047-0.582c0.005-0.074,0.01-0.152,0.016-0.226c0.01-0.184,0.021-0.362,0.026-0.54
							c0.005-0.105,0-0.205,0-0.305c0.005-0.152,0.011-0.309,0.005-0.461C284.936,238.237,284.926,237.985,284.911,237.738z
							 M262.544,328.391c-3.72-3.516-0.294-2.808-2.051-6.826c-2.135-4.9-5.562-2.12-5.283-9.155
							c0.152-3.872-1.259-9.874,0.383-13.405c0.797-1.721,4.234-2.439,3.777-4.958c-0.456-2.529-5.11-2.45-6.547-4.701
							c-1.957-3.08-3.201-11.868,1.259-13.007c-0.398-2.964-3.94-4.155-3.504-7.125c4.779-0.86,15.604-6.936,16.065-12.103
							c-8.289,3.756-17.246,2.833-26.354,3.725c-4.202,0.409-7.061,1.123-11.469,0.289c-3.305-0.625-6.217-2.047-9.654-0.96
							c4.014,2.508,6.915,6.411,11.385,7.911c5.278,1.769,7.886,0.336,5.64,7.23c-1.868,5.739-3.132,9.218-2.502,15.331
							c0.593,5.781-1.537,9.14-2.639,14.68c-0.955,4.796-0.451,8.39-1.726,12.209c-0.976-0.288-1.962-0.535-2.97-0.682
							c-5.205-0.767-10.168,1.657-14.297-2.467c-5.263-5.262-2.938-16.778-2.608-23.599c0.393-8.19,0.383-14.99-0.409-22.692
							c-0.373-3.609,1.28-6.909,0.535-10.515c-0.734-3.578-2.198-3.62-1.175-7.45c0.992-3.725,2.524-3.384,1.044-7.729
							c-0.829-2.434-3.001-4.255-3.463-6.978c-0.294-1.773,0.079-3.374,0.446-4.984l1.039-0.394c2.35,3.327,6.081,5.331,9.775,6.821
							c3.798,1.532,7.597,1.149,11.532,2.093c4.229,1.018,5.803,3.762,10.441,3.426c4.087-0.294,7.56-2.004,11.647-1.202
							c3.72,0.729,7.649,0.499,11.29-0.619c3.537-1.091,6.364-0.577,9.465,1.653c1.438,1.039,2.739,2.225,4.071,3.363
							c-0.121,0.761-0.215,1.537-0.278,2.319c-0.982,11.895,0.037,22.744-2.493,34.513c-0.944,4.381-2.67,8.242-3.011,12.66
							c-0.331,4.255,0.614,8.474,0.65,12.713c0.037,4.737-1.569,9.313-1.181,14.118c0.305,3.699,1.81,6.559,2.587,10.158
							C268.845,331.701,265.078,330.782,262.544,328.391z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    style={{ fill: "#5EA1C6" }}
                    d="M284.942,238.489c0.005,0.152,0,0.31-0.005,0.461c0,0.1,0.005,0.2,0,0.305
						c-0.005,0.178-0.016,0.356-0.026,0.54c-0.005,0.074-0.011,0.152-0.016,0.226c-0.011,0.194-0.032,0.388-0.047,0.582
						c-0.01,0.068-0.01,0.131-0.021,0.194c-0.021,0.205-0.047,0.414-0.073,0.619c-0.005,0.052-0.011,0.105-0.021,0.157
						c-0.026,0.215-0.058,0.436-0.095,0.65c-0.005,0.042-0.01,0.089-0.021,0.131c-0.031,0.226-0.073,0.451-0.11,0.677
						c-0.005,0.037-0.016,0.068-0.021,0.1c-0.042,0.236-0.084,0.467-0.126,0.703c-0.005,0.026-0.011,0.053-0.016,0.079
						c-0.047,0.236-0.094,0.472-0.142,0.708c-0.005,0.026-0.01,0.052-0.01,0.073c-0.047,0.231-0.094,0.457-0.142,0.682
						c-0.016,0.095-0.037,0.189-0.058,0.278c-0.005,0.026-0.01,0.047-0.016,0.073c-0.294,1.443-0.587,2.849-0.75,4.171
						c-0.016,0.121-0.037,0.241-0.047,0.356c-0.325,2.959,1.07,5.221,0.236,8.237c-0.881,3.195-0.488,6.517-0.918,9.796
						c-1.018,7.696-1.994,14.827-5.042,22.114c-1.348,3.222-1.999,7.13-2.498,10.609c-0.278,1.962,0.682,4.606,0.347,6.605
						c-0.037-0.101-0.074-0.2-0.116-0.3c-0.976-2.393-2.582-5.325-2.807-7.754c-0.325-3.526,0.761-8.285,1.837-11.642
						c0.787-2.455,2.833-4.423,3.442-6.895c0.493-1.993,0.278-3.939,0.929-6.013c0.944-3.001,2.351-5.551,2.372-8.689
						c0.016-2.145-0.604-4.244-0.525-6.464c0.1-2.849,1.07-5.535,1.044-8.326c-0.032-3.956-2.23-4.69-4.323-7.624
						c-3.033-4.249-2.802-6.401-8.667-6.988c-5.194-0.52-10.179,1.516-15.32,1.768c-10.504,0.514-20.32,1.039-28.001-7.697
						l-3.877-8.369c5.037-0.414,10.373,0.976,15.383,1.028c3.998,0.042,7.991-0.184,11.994-0.178
						c4.56,0.01,10.011-1.034,14.439,0.01c2.781,0.656,5.063,1.836,7.985,2.183c2.309,0.273,4.271,0.761,6.464,1.522
						c4.093,1.411,4.502,3.221,6.338,6.71c0.624,1.186,0.929,2.481,1.018,3.835C284.926,237.985,284.936,238.237,284.942,238.489z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#5EA1C6" }}
                    d="M272.523,371.785c-0.976,3.542,1.616,6.512,0.745,10.194c-0.283,1.207-1.301,2.167-1.621,3.279
						c-0.357,1.238,0.2,1.495,0.483,2.613c0.687,2.696-1.889,3.457-1.669,5.949c0.121,1.38,1.181,2.781,1.458,4.202
						c0.425,2.199-0.96,2.75-2.293,4.276c-1.096,1.265-1.076,1.952-1.086,3.547c-0.016,1.852-0.651,2.067-1.464,3.841
						c-2.497-0.441-5.215-0.798-7.765-0.992c-3.085-0.235-6.212,0.593-9.187,0.515c-0.96-0.026-2.765-0.767-3.321-1.895
						c-0.986-1.988,1.044-1.935,1.915-2.995c0.986-1.191,0.052-1.784-0.163-3.201c-0.231-1.5,0.388-2.402,1.149-3.625
						c0.398-0.646,0.724-1.201,0.971-1.716c1.206-0.431,2.004-0.619,3.316-0.729c2.224-0.188,5.22,0.499,6.826-0.834
						c0.278-0.23,0.163-0.798,0.714-1.144c0.892-0.557,2.261,0.215,3.174-0.509c2.377-1.879-2.162-2.934-3.583-3.327
						c-1.805-0.509-6.097-1.07-6.044-3.609c2.959-0.157,5.897,0.005,8.841-0.372c1.102-0.142,3.237-0.221,4.077-0.877
						c0.949-0.739,0.614-1.243,1.044-2.398c0.671-1.8,0.871-1.815,0.692-3.725c-0.084-0.913-0.026-1.89-0.005-2.808
						c0.053-2.303,1.354-3.83,1.763-6.033c0.446-2.414,1.333-4.538,2.072-6.899c0.63-1.988,0.677-4.208,1.049-6.302
						c0.913-5.136,1.28-10.509,1.496-15.755c0.1-2.361,0.577-4.502,0.561-6.753c-0.01-1.296,0.556-2.387,1.196-3.437
						c0.074,0.394,0.158,0.803,0.257,1.238c1.962,8.594,0.96,18.894-2.052,27.209C274.538,362.935,273.714,367.462,272.523,371.785z
						"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#CCEBF9" }}
                    d="M217.433,241.317c-7.498,6.39,19.367,8.856,19.87,10.402c-4.469,1.341-10.048-0.801-14.055,2.57
						c7.35,4.09,18.172,2.437,26.218,1.73c3.702-0.325,9.849-1.534,12.969-3.672c3.994-2.735,3.696-2.005,0.144-3.659
						c-3.787-1.762-9.285-1.353-13.168-1.757c-5.029-0.524-10.254-1.211-15.332-1.955
						C229.56,244.315,219.864,237.899,217.433,241.317z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#CCEBF9" }}
                    d="M214.81,276.994c-0.003,4.884,1.122,9.716,1.061,14.471c-0.055,4.223-1.439,7.535-1.033,11.772
						c0.383,3.992,0.861,8.097,5.886,7.858c4.627-0.22,4.624-4.363,5.655-8.814c2.008-8.671,7.448-19.056,1.43-27.399
						C221.947,266.755,218.381,273.03,214.81,276.994z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#CCEBF9" }}
                    d="M262.555,268.6c-1.45,1.544-1.736,2.941-1.132,4.646c-12.791-0.914,2.867,20.88,2.442,23.174
						c-0.374,2.02-3.325,3.794-4.091,5.756c-1.267,3.246-0.03,4.143,0.576,7.318c0.79,4.139,0.566,11.271,4.828,13.603
						c2.822-3.664,0.553-10.903,1.03-15.41c0.406-3.831,1.382-7.271,1.594-11.28c0.549-10.381,3.926-20.516,3.069-30.878
						C268.102,264.332,263.964,265.109,262.555,268.6z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#CCEBF9" }}
                    d="M231.304,337.65c-0.659-0.057-1.414,0.563-2.273,2.21c-1.546,2.967-2.64,7.853-2.144,11.21
						c0.426,2.884,1.56,5.585,2.305,8.368c0.381,1.42-0.085,5.771,2.202,5.933c3.479,0.246,4.062-15.091,3.569-17.969
						C234.563,345.058,233.434,337.831,231.304,337.65z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#CCEBF9" }}
                    d="M265.965,344.414c-2.698,0.641-6.709-0.508-8.879,1.798c-1.729,1.837-1.672,5.813-1.87,8.149
						c-0.265,3.121,0.264,6.106-0.006,9.209c-0.175,2.009-0.788,4.954,1.057,6.327c2.478-1.998,2.774-6.032,4.713-8.475
						c1.316,1.526-1.073,6.425,1.569,5.896c2.892-0.579,4.884-9.729,5.522-12.076c0.558-2.052,2.625-7.762,1.972-9.76
						C269.471,343.729,267.023,343.274,265.965,344.414z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#5EA1C6" }}
                    d="M207.611,284.853c0.163,5.577-0.121,10.241-1.354,15.656c-0.084,0.361-0.163,0.719-0.231,1.075
						c-0.189-1.747-0.31-3.536-0.31-5.367c0-6.238,0.593-12.456,0-18.61c-0.404-4.202-2.917-5.902-3.867-9.711
						c-1.118-4.486,0.839-11.149,1.055-15.777c0.21-4.475,0.876-8.741,1.212-13.164h0.346c-0.394,2.172,1.868,4.958,1.852,7.54
						c-0.016,2.235-0.32,4.706-0.524,6.941c-0.399,4.197-1.616,8.541-1.28,12.755c0.226,2.88,1.412,6.07,2.051,8.919
						C207.265,278.237,207.517,281.59,207.611,284.853z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#5EA1C6" }}
                    d="M230.141,323.427c-0.247,1.265-6.878,2.397-8.085,3.085c1.28,0.53,3.578,0.541,3.62,2.114
						c-3.143,0.656-9.501-0.361-10.462,2.97c-0.703,2.45,0.939,4.748,1.58,6.915c0.813,2.755,1.595,4.791,1.442,7.461
						c-0.178,3.101,0.331,5.441,0.787,8.684c0.572,4.034,0.761,7.455-0.367,11.102c-0.063-0.378-0.131-0.766-0.22-1.154
						c-0.236-1.055-0.976-1.747-1.27-2.691c-0.598-1.915,2.099-4.748-0.608-5.692c-1.742-0.608-2.813-9.749-3.499-12.319
						c-1.322-4.964-4.297-9.57-3.856-15.137c0.158-1.968,0.52-2.271,0.011-4.171c-0.404-1.517-1.333-1.831-1.396-3.699
						c-0.052-1.495,0.047-2.691,0.105-3.861c2.707,3.08,9.585,5.656,13.342,5.876C222.145,322.96,230.744,320.326,230.141,323.427z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: "#5EA1C6" }}
                    d="M272.135,336.911c-3.809,0.031-7.25,1.322-10.892,2.088c-1.196,0.252-4.927,1.06-8.159,1.49
						c0.173-1.601,0.272-3.19,0.205-4.732c-0.058-1.391,0.126-4.146-0.352-5.42c-1.658,0.624-3.216-2.771-3.148-4.161
						c0.105-2.015,0.803-3.605,0.698-5.677c-0.152-3.048,0.278-6.217,0.005-9.233c-0.257-2.844-1.07-5.897-1.527-8.768
						c-0.446-2.807-1.191-4.58-0.934-7.319c0.178-1.899-0.257-3.552-0.168-5.451c0.121-2.487-0.357-4.885-0.352-7.313
						c0-2.063,0.268-3.573-0.184-5.635c-0.356-1.632-0.724-2.907-1.422-4.287c-0.661-1.311-0.572-3.725-1.364-4.942
						c0.142,0.157-1.548,1.548-2.115,2.109c-0.503,3.447-1.432,7.319-2.476,10.698c-0.588,1.904-0.992,3.824-1.296,5.761
						c0.199-4.155,0.902-9.098,0.986-10.887c0.094-1.941,1.569-5.604,0.367-7.335c-1.338-1.921-5.163-0.315-5.919-2.565
						c6.506,0.356,12.718-0.059,19.087-1.758c-1.438,1.842-5.761,2.172-6.202,4.491c-0.23,1.217,1.317,1.568,1.563,2.34
						c0.362,1.139,0.341,2.277,0.546,3.441c0.829,4.706,0.105,8.71,0,13.436c-0.147,6.706,2.141,12.319,2.812,18.741
						c0.362,3.474,0.53,6.884,1.007,10.31c0.2,1.422,0.378,2.776,0.362,4.219c-0.011,0.729-0.43,3.049-0.357,3.468
						c0.635,3.746,3.531,4.507,5.976,6.674c3.263,2.891,0.646,1.674-1.37,4.104C261.243,337.168,269.559,333.154,272.135,336.911z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      style={{ fill: "#F0D4D1" }}
                      d="M309.318,254.711c-0.398,2.277-0.813,4.733-2.015,6.711c-0.063,0.104-0.131,0.215-0.205,0.33
							c-0.845,1.354-2.141,3.117-3.882,2.912c-1.076,0.588-1.831,1.585-2.849,2.309c-1.558,1.107-2.896-0.011-4.497,0.667
							c-1.805,0.761-2.655,1.007-4.632,0.435c-0.782,0.305-1.999,0.331-2.629-0.325c-0.797-0.834,0.215-1.19,0.876-1.773
							c0.955-0.839,1.841-1.82,2.229-3.021c0.431-1.327,0.488-2.707,0.614-4.103c0.147-1.621,0.719-4.034,0.131-5.556
							c-2.392,1.422-2.392,5.068-3.431,7.345c-0.483,1.066-3.494,3.248-3.935,1.569c-0.247-0.929,0.583-2.13,0.897-2.97
							c0.477-1.259,0.808-2.607,1.133-3.898c0.394-1.584,0.43-3.441,0.981-4.947c0.283-0.787,0.939-1.563,1.317-2.33
							c0.372-0.761,0.724-1.532,1.118-2.288c0.855-1.658,1.06-3.269,1.396-5.053c0.136-0.713,0.85-3.011,1.747-2.817
							c0.131-0.21,0.499-0.367,0.981-0.493c1.349-0.331,3.62-0.378,4.413-0.446c0.189-0.016,0.378-0.032,0.562-0.047
							c1.238-0.105,2.429-0.184,3.699-0.084c0.839,0.068,2.251,0.535,3.006,0.205c0.918,1.857,1.112,4.229,1.742,6.202
							c0.325,1.013,0.887,1.789,0.992,2.88c0.031,0.32,0.042,0.651,0.042,0.986c0,0.724-0.058,1.464-0.152,2.141
							c-0.152,1.044-0.299,2.157,0.042,3.169C309.308,253.3,309.48,253.757,309.318,254.711z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FAECE5" }}
                      d="M308.909,247.933c0.074-0.273,0.147-0.551,0.21-0.824c0-0.335-0.011-0.666-0.042-0.986
							c-0.105-1.091-0.667-1.868-0.992-2.88c-0.629-1.973-0.824-4.344-1.742-6.202c-0.755,0.331-2.167-0.136-3.006-0.205
							c-1.27-0.1-2.46-0.021-3.699,0.084l1.61,1.38c0.646,2.12,0.693,2.744,0.242,4.832c-1.501,0.047-1.658,0.053-1.947,1.475
							c-0.226,1.128-0.656,2.518-0.661,3.678c-0.01,1.395,0.541,1.878-0.388,2.733c-0.587,0.546-1.18,0.625-1.605,1.479
							c-0.392,0.789-0.517,1.619-0.356,2.167c-0.148,0.345-0.249,0.723-0.326,1.054c-0.216,0.921-0.519,1.89-0.664,2.824
							c-0.123,0.784-0.029,1.619,0.014,2.396c0.02,0.36-0.113,0.737-0.073,1.097c0.614,0.097,0.825-0.425,1.051-0.908
							c0.566-1.207,0.71-2.646,1.053-3.936c0.218-0.822,0.858-1.693,1.09-2.586c0.351-0.387,0.931-1.191,1.123-1.232
							c0.855-0.167,0.692,0.688,1.055,1.254c0.176,0.277,0.621,0.78,1.103,1.181c-0.116,0.424-0.219,0.852-0.401,1.116
							c-0.519,0.755-0.733,1.431-1.05,2.273c-0.528,1.407-1.034,2.814-1.528,4.222c-0.129,0.367-0.259,0.636,0.212,0.588
							c0.508-0.05,0.858-0.765,1.128-1.133c1.034-1.408,1.014-3.26,1.929-4.711c0.543-0.861,1.587-1.661,1.984-2.637
							c0.345-0.44,0.644-0.933,0.784-1.183c0.483,0.871-0.367,2.718-0.488,3.699c-0.136,1.107-0.299,2.188-0.241,3.321
							c1.626,0.146,2.225-4.318,2.959-5.488c0.336-0.535,1.118-1.274,1.223-1.836c0.073-0.389-0.352-1.008-0.409-1.417
							c-0.089-0.635,0.058-1.307,0.146-1.931C308.348,249.685,308.647,248.872,308.909,247.933z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#D1ABA6" }}
                      d="M307.099,261.752c-0.845,1.354-2.141,3.117-3.882,2.912c-1.076,0.588-1.831,1.585-2.849,2.309
							c-1.558,1.107-2.896-0.011-4.497,0.667c-1.805,0.761-2.655,1.007-4.632,0.435c-0.782,0.305-1.999,0.331-2.629-0.325
							c-0.797-0.834,0.215-1.19,0.876-1.773c0.955-0.839,1.841-1.82,2.229-3.021c0.431-1.327,0.488-2.707,0.614-4.103
							c0.147-1.621,0.719-4.034,0.131-5.556c-2.392,1.422-2.392,5.068-3.431,7.345c-0.483,1.066-3.494,3.248-3.935,1.569
							c-0.247-0.929,0.583-2.13,0.897-2.97c0.477-1.259,0.808-2.607,1.133-3.898c0.394-1.584,0.43-3.441,0.981-4.947
							c0.283-0.787,0.939-1.563,1.317-2.33c0.372-0.761,0.724-1.532,1.118-2.288c0.855-1.658,1.06-3.269,1.396-5.053
							c0.136-0.713,0.85-3.011,1.747-2.817c0.131-0.21,0.499-0.367,0.981-0.493c-0.026,0.776,0.011,1.564-0.073,2.209
							c-0.158,1.322-0.089,2.838-0.425,4.103c-0.404,1.532-1.815,2.55-2.334,3.987c-0.252,0.698-0.436,2.492,0.005,3.153
							c0.419,0.619,1.4,0.666,1.841,1.359c1.186,1.857-0.016,5.346-0.304,7.393c-0.262,1.862-2.02,3.567,0.215,4.779
							c2.115,1.154,2.456-1.432,3.038-2.875c0.1,1.081-0.042,2.576,0.897,3.342c1.438,1.171,2.624-0.85,3.101-2.024
							c0.97,2.76,3.353,0.094,3.93-1.443c0.65,0.467,1.548,0.446,2.335,0.383C306.962,261.773,307.031,261.763,307.099,261.752z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      style={{ fill: "#F0D4D1" }}
                      d="M175.105,249.761c0.009,2.312,0.003,4.802,0.854,6.954c0.044,0.114,0.093,0.234,0.146,0.36
							c0.604,1.478,1.585,3.434,3.336,3.525c0.961,0.761,1.538,1.87,2.419,2.756c1.35,1.354,2.857,0.478,4.32,1.415
							c1.651,1.054,2.447,1.44,4.493,1.21c0.719,0.433,1.915,0.663,2.646,0.123c0.926-0.688-0.012-1.21-0.565-1.895
							c-0.8-0.988-1.508-2.106-1.689-3.355c-0.2-1.381-0.024-2.75,0.087-4.147c0.129-1.622-0.028-4.098,0.808-5.499
							c2.119,1.806,1.504,5.399,2.143,7.819c0.296,1.131,2.896,3.79,3.614,2.21c0.399-0.874-0.215-2.198-0.384-3.079
							c-0.258-1.321-0.356-2.706-0.46-4.033c-0.121-1.628,0.156-3.465-0.133-5.042c-0.146-0.824-0.662-1.7-0.905-2.518
							c-0.239-0.813-0.455-1.632-0.716-2.443c-0.564-1.778-0.493-3.4-0.523-5.216c-0.014-0.726-0.33-3.112-1.247-3.071
							c-0.094-0.229-0.429-0.446-0.884-0.652c-1.273-0.553-3.505-0.983-4.274-1.184c-0.184-0.047-0.367-0.095-0.546-0.141
							c-1.203-0.313-2.363-0.591-3.631-0.706c-0.839-0.074-2.309,0.148-2.998-0.305c-1.218,1.676-1.81,3.98-2.763,5.819
							c-0.491,0.943-1.175,1.614-1.463,2.672c-0.085,0.31-0.151,0.634-0.208,0.965c-0.122,0.714-0.19,1.453-0.211,2.136
							c-0.026,1.055-0.069,2.176-0.576,3.116C175.353,248.372,175.105,248.792,175.105,249.761z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FAECE5" }}
                      d="M176.651,243.148c-0.026-0.281-0.052-0.568-0.068-0.847c0.057-0.331,0.123-0.655,0.208-0.965
							c0.288-1.058,0.972-1.729,1.463-2.672c0.953-1.838,1.544-4.143,2.763-5.819c0.689,0.453,2.159,0.231,2.998,0.305
							c1.268,0.116,2.429,0.394,3.631,0.706l-1.82,1.088c-0.993,1.98-1.145,2.588-1.053,4.722c1.471,0.3,1.626,0.331,1.671,1.782
							c0.032,1.15,0.222,2.593,0.031,3.737c-0.225,1.377-0.85,1.76-0.078,2.76c0.487,0.637,1.058,0.814,1.333,1.729
							c0.253,0.844,0.237,1.683-0.015,2.195c0.088,0.365,0.124,0.754,0.144,1.094c0.058,0.945,0.192,1.95,0.178,2.896
							c-0.012,0.793-0.245,1.601-0.418,2.358c-0.081,0.352-0.013,0.745-0.113,1.094c-0.622-0.01-0.741-0.558-0.883-1.073
							c-0.355-1.285-0.254-2.728-0.374-4.057c-0.076-0.847-0.561-1.814-0.638-2.732c-0.281-0.441-0.717-1.332-0.899-1.403
							c-0.815-0.31-0.799,0.561-1.251,1.058c-0.22,0.243-0.744,0.664-1.287,0.977c0.044,0.438,0.072,0.876,0.208,1.168
							c0.384,0.831,0.482,1.533,0.651,2.417c0.283,1.477,0.545,2.949,0.794,4.42c0.065,0.384,0.148,0.669-0.309,0.544
							c-0.492-0.136-0.716-0.899-0.921-1.308c-0.782-1.563-0.45-3.384-1.106-4.969c-0.39-0.941-1.284-1.905-1.512-2.933
							c-0.265-0.493-0.477-1.029-0.573-1.298c-0.622,0.777-0.096,2.741-0.143,3.728c-0.052,1.114-0.074,2.207-0.322,3.314
							c-1.628-0.129-1.465-4.631-1.991-5.908c-0.241-0.584-0.887-1.445-0.896-2.016c-0.007-0.395,0.516-0.934,0.642-1.328
							c0.195-0.61,0.163-1.297,0.181-1.928C176.909,244.971,176.751,244.119,176.651,243.148z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#D1ABA6" }}
                      d="M176.105,257.075c0.604,1.478,1.585,3.434,3.336,3.525c0.961,0.761,1.538,1.87,2.419,2.756
							c1.35,1.354,2.857,0.478,4.32,1.415c1.651,1.054,2.447,1.44,4.493,1.21c0.719,0.433,1.915,0.663,2.646,0.123
							c0.926-0.688-0.012-1.21-0.565-1.895c-0.8-0.988-1.508-2.106-1.689-3.355c-0.2-1.381-0.024-2.75,0.087-4.147
							c0.129-1.622-0.028-4.098,0.808-5.499c2.119,1.806,1.504,5.399,2.143,7.819c0.296,1.131,2.896,3.79,3.614,2.21
							c0.399-0.874-0.215-2.198-0.384-3.079c-0.258-1.321-0.356-2.706-0.46-4.033c-0.121-1.628,0.156-3.465-0.133-5.042
							c-0.146-0.824-0.662-1.7-0.905-2.518c-0.239-0.813-0.455-1.632-0.716-2.443c-0.564-1.778-0.493-3.4-0.523-5.216
							c-0.014-0.726-0.33-3.112-1.247-3.071c-0.094-0.229-0.429-0.446-0.884-0.652c-0.105,0.77-0.274,1.54-0.3,2.189
							c-0.068,1.33-0.391,2.813-0.273,4.116c0.14,1.578,1.359,2.819,1.629,4.324c0.13,0.73,0.009,2.53-0.537,3.107
							c-0.518,0.539-1.493,0.42-2.044,1.029c-1.482,1.631-0.886,5.272-0.947,7.338c-0.056,1.88,1.39,3.857-1.018,4.675
							c-2.279,0.781-2.179-1.825-2.509-3.346c-0.281,1.048-0.393,2.546-1.448,3.143c-1.615,0.911-2.443-1.28-2.715-2.518
							c-1.422,2.556-3.32-0.473-3.63-2.085c-0.72,0.351-1.601,0.179-2.366-0.017C176.236,257.119,176.17,257.098,176.105,257.075z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: "#CCEBF9" }}
                    d="M240.519,390.752c-0.063,1.511,0.047,3.153,0.126,4.685c0.152,3.027-0.205,5.934-0.556,8.956
						c-0.493,4.244-1.941,6.957-4.717,10.199c-2.135,2.497-6.123,6.097-9.643,5.734c-0.567-0.052-1.202-0.126-1.863-0.225
						c-1.485-0.23-3.069-0.629-4.108-1.422c-1.243-0.944-2.015-3.063-2.46-4.517c-0.997-3.264-2.083-7.293-1.438-10.729
						c0.629-3.348,0.997-6.932,2.854-9.833c1.375-2.145,2.792-5.577,2.786-8.132c-0.755-0.126-1.028-1.732-1.343-2.461
						c-0.667-1.553-1.626-2.875-2.723-4.171c-0.614-0.724-1.689-1.811-0.619-2.786c0.671-0.613,2.644-0.283,3.374,0.105
						c0.372-1.139,0.608-2.472,0.787-3.673c0.278,0.294,0.32,0.688,0.446,1.223c0.273,1.175,0.614,2.659,1.815,3.337
						c1.663,0.929,4.307,1.763,6.306,1.741c0.074,0,0.147,0,0.221-0.005c2.408-0.105,5.005-2.282,7.083-3.442
						c0.666-0.372,1.143-0.786,1.505-1.254c0.855-1.106,1.034-2.486,1.354-4.191c0.425-2.272-0.341-7.267-0.1-8.217
						c0.005-0.016,0.011-0.031,0.016-0.047c0.503,0.246,0.729,0.31,1.264,0.493c3.264,1.107,5.793,3.321,6.622,6.826
						c0.866,3.662-0.079,7.192-1.873,10.462C243.682,382.966,240.692,386.481,240.519,390.752z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M235.45,402.583c-0.472,0.729-1.343,1.459-2.036,1.999c-1.249,0.966-2.435,1.16-2.519,2.912
						c-0.173,3.684,2.713,5.944-1.259,8.888c-1.695,1.254-3.563,2.063-5.257,3.353c-0.168,0.126-0.336,0.247-0.514,0.367
						c-1.485-0.23-3.069-0.629-4.108-1.422c-1.243-0.944-2.015-3.063-2.46-4.517c-0.997-3.264-2.083-7.293-1.438-10.729
						c0.629-3.348,0.997-6.932,2.854-9.833c1.375-2.145,2.792-5.577,2.786-8.132c-0.755-0.126-1.028-1.732-1.343-2.461
						c-0.667-1.553-1.626-2.875-2.723-4.171c-0.614-0.724-1.689-1.811-0.619-2.786c0.671-0.613,2.644-0.283,3.374,0.105
						c0.372-1.139,0.608-2.472,0.787-3.673c0.278,0.294,0.32,0.688,0.446,1.223c0.273,1.175,0.614,2.659,1.815,3.337
						c1.663,0.929,4.307,1.763,6.306,1.741c0.95,1.842,1.684,4.04,1.884,5.635c0.23,1.811-1.181,2.016-1.585,3.354
						c-0.231,0.766-0.026,2.256-0.215,3.205c-0.289,1.459,0.147,3.421-0.299,4.775c-0.436,1.322-1.962,1.333-2.624,2.791
						c-0.939,2.057-0.509,4.098,2.052,3.637c1.705-0.31,7.508-5.168,8.085-2.918C236.94,399.64,235.729,402.158,235.45,402.583z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M241.044,370.421c-0.467,1.034-1.464,2.797-2.692,3.662c0.855-1.106,1.034-2.486,1.354-4.191
						c0.425-2.272-0.341-7.267-0.1-8.217c0.761,0.96,1.291,2.23,1.538,3.069C241.678,366.555,241.846,368.653,241.044,370.421z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M243.536,367.761c-0.768,3.019-0.851,5.798-3.506,7.874c-1.31,1.024-3.411,1.507-4.465,2.78
						c-0.588,0.71-1.272,2.252-1.344,3.151c-0.096,1.183,0.691,1.419,1.032,2.223c0.35,0.822,0.151,2.153,0.151,3.094
						c0,1.728-0.135,4.978,1.498,6.196c2.18,1.626,1.655-3.736,1.879-4.859c0.488-2.443,1.057-4.778,2.364-6.919
						c1.064-1.741,2.593-3.302,3.446-5.171c0.846-1.851,2.213-4.857,1.708-6.933c-0.185-0.76-2.966-4.529-2.934-2.111
						C243.205,367.121,243.043,367.128,243.536,367.761z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: "#CCEBF9" }}
                    d="M269.548,430.895c0.294,0.734,1.343,1.223,0.74,2.146c-1.38,2.114-4.397,3.452-6.684,4.071
						c-1.663,0.446-3.295,0.656-5.047,0.692c-1.737,0.037-3.442,0.068-5.179,0c-1.516-0.058-2.854-0.719-4.292-0.876
						c-0.688-0.755-2.419-0.808-2.964-1.752c-0.388-0.677-0.21-2.005-0.184-2.76c0.01-0.31,0.021-0.619,0.026-0.929
						c0.026-1.191,0.037-2.393,0.215-3.563c0.121-0.766,0.047-1.333,0.451-1.983c0.362-0.577,1.454-1.033,1.406-1.778
						c0.163,0.246,0.152-0.525,0.178-0.798c0.021-0.263,0-0.535,0-0.803c-0.005-0.478-0.021-0.96,0-1.438
						c0.053-1.159,0.483-2.141,0.829-3.248c0.493-1.573,0.503-2.875,0.482-4.491c-0.01-0.519-0.01-0.839,0.189-1.29
						c0.226-0.509,0.436-0.824,0.682-1.401c0.541-1.259-0.304-3.793-0.834-4.984c-0.583-1.312-1.553-2.733-1.003-4.191
						c0.499-1.338,2.01-3.405,3.489-3.568c1.28-0.142,2.655-0.22,4.024-0.22c1.286,0.005,2.566,0.084,3.762,0.241
						c2.03,0.272,5.845,0.482,7.298,2.24c0.52,0.624,0.504,1.789,0.409,2.639c-0.157,1.369-1.521,3.374-0.991,4.723
						c0.173,0.44,0.74,0.87,0.981,1.301c0.341,0.604,0.593,1.259,0.876,1.895c0.11,0.257,0.226,0.514,0.352,0.76
						c0.661,1.297,1.223,3.096-0.173,4.24c-0.682,0.556-0.829,1.516-0.703,2.523c0.152,1.248,0.713,2.561,1.175,3.289
						c0.719,1.134,1.427,2.45,1.543,3.805C270.734,426.897,270.32,429.603,269.548,430.895z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M269.548,430.895c0.294,0.734,1.343,1.223,0.74,2.146c-1.38,2.114-4.397,3.452-6.684,4.071
						c-1.663,0.446-3.295,0.656-5.047,0.692c-1.737,0.037-3.442,0.068-5.179,0c-1.516-0.058-2.854-0.719-4.292-0.876
						c-0.688-0.755-2.419-0.808-2.964-1.752c-0.388-0.677-0.21-2.005-0.184-2.76c0.01-0.31,0.021-0.619,0.026-0.929
						c0.026,0.011,0.047,0.016,0.074,0.021c3.095,0.835,6.254,1.401,9.46,1.401c2.476-0.006,5.425,0.1,7.76-0.876
						c1.191-0.493,2.371-1.532,3.494-2.209c1.411-0.851,2.434-3.604,2.036-5.226c-0.315-1.291-0.929-2.524-1.091-3.846
						c-0.121-0.961-0.037-1.706,0.189-2.461c0.152,1.248,0.713,2.561,1.175,3.289c0.719,1.134,1.427,2.45,1.543,3.805
						C270.734,426.897,270.32,429.603,269.548,430.895z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M252.324,421.89c-0.719-0.113-2.116,0.406-2.009,1.307c0.079,0.674,1.201,0.934,1.663,1.323
						c-0.475,0.391-4.123,2.853-2.673,3.833c0.798,0.54,3.724-0.724,4.615-0.868c1.61-0.262,3.261-0.35,4.888-0.416
						c1.069-0.044,2.102,0.051,3.137,0.328c0.998,0.267,2.145,0.796,3.205,0.69c0.99-0.099,1.031-0.6,0.46-1.162
						c-0.5-0.493-1.76-0.427-1.461-1.435c0.814-0.188,3.231-0.365,1.902-1.454c-0.51-0.419-1.524-0.643-2.17-0.761
						c-0.122-0.578,0.397-0.877,0.686-1.311c0.502-0.753,0.794-1.744,1.009-2.603c0.259-1.037,0.17-2.229-0.478-3.112
						c-0.309-0.422-0.726-0.892-1.272-0.704c-0.817,0.283-0.288,0.972-0.349,1.609c-0.075,0.782-0.201,1.511-0.55,2.197
						c-0.604,1.188-1.541,2.962-3.004,3.147c-1.578,0.198-3.431-0.949-5.061-1.05C254.029,421.397,252.943,421.401,252.324,421.89z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M267.675,414.199c-0.131,0.614-0.629,0.541-1.133,0.473c-0.75-0.11-1.469-0.283-2.23-0.341
						c-0.918-0.074-1.92,0.025-2.801-0.278c-0.11-1.018,2.376-0.703,0.845-1.711c-0.866-0.577-1.627-0.551-0.688-1.626
						c0.593-0.677,0.976-0.897,0.005-1.506c-0.829-0.52-1.994-0.614-2.938-0.74c-1.086-0.142-3.951-0.089-3.169-1.825
						c0.53-1.186,2.891-0.656,3.909-0.599c0.976,0.064,1.983-0.278,2.833-0.75c0.85-0.478,0.866-1.275,0.839-2.183
						c-0.042-1.207-0.142-2.959-1.359-3.494c-1.307-0.577-3.017-0.918-4.428-1.112c-0.703-0.1-1.438-0.341-1.286-0.776
						c1.286,0.005,2.566,0.084,3.762,0.241c2.03,0.272,5.845,0.482,7.298,2.24c0.52,0.624,0.504,1.789,0.409,2.639
						c-0.157,1.369-1.521,3.374-0.991,4.723c0.173,0.44,0.74,0.87,0.981,1.301c0.341,0.604,0.593,1.259,0.876,1.895
						c-0.981-0.231-2.686-0.462-2.849,0.54C265.367,412.505,267.948,412.977,267.675,414.199z"
                  />
                </g>
              </g>
              <g>
                <path
                  style={{ fill: theme.text }}
                  d="M314.292,240.068c-0.273,0.304-0.929,0.876-1.564,1.307c0.503-1.695,0.656-3.405,0.147-5.614
					c-0.603-2.628-0.488-5.698-1.685-8.158c-0.482-0.997-3.316-3.09-2.786-4.486c0.677-1.763,3.609-0.567,5.147-1.301
					c-0.284,2.25-0.378,4.517-0.031,6.988c0.372,2.686,1.117,5.556,1.505,8.038C315.347,238.867,315.688,238.52,314.292,240.068z"
                />
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight }}
                    d="M315.026,236.841c0.32,2.025,0.661,1.679-0.734,3.227c-0.273,0.304-0.929,0.876-1.564,1.307
						c-0.451,0.31-0.892,0.54-1.175,0.545c-1.915,0.042-4.995-2.586-7.288-2.907c-2.477-0.341-5.504-0.199-7.87,0.525
						c-1.742,0.535-3.332,1.595-5.058,1.967c-0.923,0.2-2.434-0.262-3.709-0.451c-0.845-0.121-1.584-0.126-1.989,0.263
						c1.108,0.635-1.616,7.288-2.864,8.132c-1.291,0.876-2.33,0.845-3.258,1.453c-0.499,0.32-0.965,0.829-1.422,1.752
						c-1.118,2.273-1.663,4.423-3.94,5.945c-0.614-1.48-1.018-3.012-1.353-4.56c-0.472-2.225-0.792-4.491-1.385-6.7
						c-0.498-1.852-1.448-3.578-1.726-5.493c-0.226-1.553,0.289-3.264-0.362-4.691c-0.414-0.903-1.564-1.658-2.041-2.66
						c-0.562-1.175-0.535-2.377-0.981-3.615c-1.238-3.437-2.744-6.369-3.646-9.995c-1.112-4.47-2.298-8.043-2.225-12.602
						c0.094-5.52-0.588-11.286-1.018-16.789c-0.262-3.426-1.7-6.742-1.831-10.215c-0.11-2.985-0.115-5.672-0.409-8.726
						c-0.446-4.612-0.21-10.467-2.198-15.005c-0.583-1.333-1.359-2.55-2.393-3.588c0-4.192-0.792-7.965-0.709-11.979
						c0.021-0.902,0.084-1.82,0.21-2.754c0.897-6.537,3.505-12.802,4.334-19.344c0.635-5.011,1.28-9.938,1.941-14.911
						c0.409-3.059,1.212-5.929,1.6-8.987c0.294-2.33-0.105-4.759,0.241-7.063c0.231-1.532,2.544-3.452,1.826-5.005
						c3.94,0.058,5.446,2.996,8.652,4.14c2.429,0.865,3.94,0.577,6.039,2.392c2.156,1.868,3.468,4.439,5.377,6.48
						c2.067,2.209,4.318,2.24,6.296,4.14c0.965,0.928,2.807,2.287,3.641,3.29c1.228,1.469,0.624,3.253,2.051,4.549
						c1.27,1.154,3.499,1.333,4.874,2.555c1.375,1.222,2.649,3.085,3.41,4.727c0.855,1.857,0.881,3.678,1.369,5.583
						c0.478,1.868,1.826,3.436,2.172,5.451c0.073,0.435,0.11,0.881,0.11,1.328c0.005,3.652-2.094,7.586-1.196,11.17
						c0.346,1.37,1.285,2.529,2.02,3.736c0.215,0.341,0.41,0.692,0.567,1.049c0.74,1.647,0.682,3.122,0.719,4.738
						c0.016,0.503,0.037,1.023,0.089,1.569c0.084,0.76,0.247,1.495,0.446,2.219c0.357,1.301,0.829,2.581,1.107,3.935
						c0.688,3.326-0.226,6.123,0,9.402c0.137,1.941,1.343,3.547,1.311,5.43c-0.021,1.007-0.865,2.503-1.049,3.41
						c1.333,2.083,2.372,3.799,3.065,5.74c0.157,0.43,0.299,0.871,0.425,1.333c0.231,0.881,0.398,1.826,0.504,2.875
						c0.545,5.593,2.335,10.814,2.623,16.506c0.231,4.496-1.28,8.521-1.936,12.87c-0.257,1.726-0.566,3.357-0.829,4.968
						c-0.126,0.777-0.242,1.542-0.336,2.314c-0.284,2.25-0.378,4.517-0.031,6.988C313.893,231.49,314.638,234.359,315.026,236.841z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M279.517,250.902c-0.499,0.32-0.965,0.829-1.422,1.752c-1.118,2.273-1.663,4.423-3.94,5.945
						c-0.614-1.48-1.018-3.012-1.353-4.56c-0.472-2.225-0.792-4.491-1.385-6.7c-0.498-1.852-1.448-3.578-1.726-5.493
						c-0.226-1.553,0.289-3.264-0.362-4.691c-0.414-0.903-1.564-1.658-2.041-2.66c-0.562-1.175-0.535-2.377-0.981-3.615
						c-1.238-3.437-2.744-6.369-3.646-9.995c-1.112-4.47-2.298-8.043-2.225-12.602c0.094-5.52-0.588-11.286-1.018-16.789
						c-0.262-3.426-1.7-6.742-1.831-10.215c-0.11-2.985-0.115-5.672-0.409-8.726c-0.446-4.612-0.21-10.467-2.198-15.005
						c0.294-0.524,0.74-1.29,1.453-2.45c2.456,5.462,3.232,11.968,5.325,17.592c1.889,5.068,3.474,8.73,3.426,14.533
						c-0.031,4.518-1.978,7.765-0.965,12.219c1.128,4.958,2.954,9.581,4.386,14.418c1.332,4.491,0.042,9.187,1.018,13.657
						c0.398,1.831,1.663,3.41,2.125,5.205c0.645,2.534,0.032,4.979,1.296,7.235C275.199,243.814,278.803,246.112,279.517,250.902z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M306.023,128.55c-2.109-3.673-3.626-7.666-6.249-10.745c-1.595-1.878-8.342-8.71-10.394-5.903
						c-1.275,1.742,0.32,9.087-1.915,9.717c-1.836,0.52-1.789-4.512-1.873-5.635c-0.194-2.461,0.194-5.283-0.478-7.676
						c-0.719-2.571-2.135-4.071-3.72-6.238c-3.856-5.278-6.6-9.245-8.872-1.364c-1.438,4.979-3.374,9.454-4.313,14.806
						c-1.055,6.018-4.853,9.271-8.033,14.575c-2.13,3.552-6.217,7.529-8.3,11.894c0.021-0.902,0.084-1.82,0.21-2.754
						c0.897-6.537,3.505-12.802,4.334-19.344c0.635-5.011,1.28-9.938,1.941-14.911c0.409-3.059,1.212-5.929,1.6-8.987
						c0.294-2.33-0.105-4.759,0.241-7.063c0.231-1.532,2.544-3.452,1.826-5.005c3.94,0.058,5.446,2.996,8.652,4.14
						c2.429,0.865,3.94,0.577,6.039,2.392c2.156,1.868,3.468,4.439,5.377,6.48c2.067,2.209,4.318,2.24,6.296,4.14
						c0.965,0.928,2.807,2.287,3.641,3.29c1.228,1.469,0.624,3.253,2.051,4.549c1.27,1.154,3.499,1.333,4.874,2.555
						c1.375,1.222,2.649,3.085,3.41,4.727c0.855,1.857,0.881,3.678,1.369,5.583c0.478,1.868,1.826,3.436,2.172,5.451
						C305.986,127.658,306.023,128.104,306.023,128.55z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight }}
                    d="M236.988,90.339c-2.524,2.225-3.085,4.564-4.518,7.418c-1.716,3.442-1.91,7.786-1.569,11.527
						c0.247,2.786-1.086,6.223,0.037,8.873c0.667,1.568,1.563,2.838,2.015,4.612c0.603,2.345,0.43,4.827,0.918,6.967
						c-1.128,1.616-1.747,2.985-2.833,4.68c-0.199,0.31-0.377,0.625-0.545,0.944c-0.331,0.64-0.604,1.296-0.85,1.962
						c-0.535,1.438-0.949,2.927-1.585,4.407c-1.684,3.935-2.487,8.169-4.056,12.13c-0.766,1.914-1.621,3.819-2.156,5.829
						c-0.394,1.464-0.131,2.938-0.566,4.407c-1.034,3.526-2.283,7.083-3.458,10.583c-0.404,1.207-0.918,2.188-1.086,3.432
						c-0.231,1.673,0.981,3.563,0,4.968c-0.86,1.228-1.123,3.778-1.448,5.284c-0.63,2.933-0.268,6.076-0.561,9.056
						c-0.567,5.687-1.711,11.506-3.117,16.973c-0.577,2.256-0.782,4.879-0.645,7.209c0.105,1.878,1.233,3.683,1.049,5.551
						c-0.2,1.946-1.936,3.347-2.587,5.121c-0.451,1.238-0.331,2.54-0.876,3.809c-0.755,1.763-2.429,3.064-3.751,4.412
						c-1.889,1.926-1.044,5.908-1.721,8.956c-0.226-0.378-0.535-0.96-0.887-1.674c-1.291-2.603-3.106-6.936-3.164-9.25
						c-0.005-0.142-0.005-0.273,0.005-0.399c-0.126,0.357-0.304,0.719-0.52,1.086v0.005c-0.42,0.708-0.965,1.417-1.537,2.052
						c-0.719,0.797-1.49,1.479-2.104,1.904c-0.482-1.175-0.273-5.535-1.249-6.742c-0.95-1.181-3.405-1.884-4.879-2.115
						c-2.193-0.341-7.251-0.698-9.056,1.097c-0.467-0.084-0.824-1.055-1.039-1.527c0.315-0.356,0.729-1.547,1.076-2.66
						c0.199-0.64,0.378-1.254,0.499-1.664c0.1-0.346,0.188-0.661,0.262-0.96c0.451-1.91,0.32-3.048,0-5.268
						c-0.42-2.917,0.115-4.764,1.286-7.351c0.75-1.653,1.841-4.203,2.781-5.1c1.81-1.726,1.81-7.109,1.532-9.549
						c-0.299-2.644-0.671-5.477-0.352-8.116c0.263-2.104,1.06-4.061,1.443-6.144c0.357-1.936,0.656-3.909,1.122-5.824
						c0.992-4.092,2.818-8.012,2.204-12.303c-0.645-4.512-0.163-8.426,0.882-12.828c0.53-2.225,0.755-4.156,0.855-6.433
						c0.079-1.768-0.189-4.664,0.887-6.133c-0.535-1.459-0.331-3.164,0.1-4.916c0.572-2.356,1.542-4.806,1.643-6.878
						c0.136-2.765,0.267-6.385,1.054-9.014c1.097-3.667,3.369-6.973,4.591-10.593c0.436-1.29,0.735-2.97,1.202-4.533
						c0.451-1.505,1.049-2.906,2.078-3.767c2.66-2.225,6.842-4.837,10.294-5.829c2.618-0.75,6.107,0.682,7.907-1.149
						c1.684-1.716,2.639-2.382,4.837-3.374c2.025-0.913,3.169-1.973,4.549-3.646c1.065-1.291,2.036-3.185,3.331-4.234
						c1.653-1.343,2.896-0.43,4.932-0.304C242.108,87.291,238.247,89.227,236.988,90.339z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M229.642,137.323c-0.535,1.438-0.949,2.927-1.585,4.407c-1.684,3.935-2.487,8.169-4.056,12.13
						c-0.766,1.914-1.621,3.819-2.156,5.829c-0.394,1.464-0.131,2.938-0.566,4.407c-1.034,3.526-2.283,7.083-3.458,10.583
						c-0.404,1.207-0.918,2.188-1.086,3.432c-0.231,1.673,0.981,3.563,0,4.968c-0.86,1.228-1.123,3.778-1.448,5.284
						c-0.63,2.933-0.268,6.076-0.561,9.056c-0.567,5.687-1.711,11.506-3.117,16.973c-0.577,2.256-0.782,4.879-0.645,7.209
						c0.105,1.878,1.233,3.683,1.049,5.551c-0.2,1.946-1.936,3.347-2.587,5.121c-0.451,1.238-0.331,2.54-0.876,3.809
						c-0.755,1.763-2.429,3.064-3.751,4.412c-1.889,1.926-1.044,5.908-1.721,8.956c-0.226-0.378-0.535-0.96-0.887-1.674
						c0.026-2.204,0.294-4.334,0.745-5.624c0.802-2.319,2.009-4.564,2.875-6.926c0.661-1.794,1.469-3.038,1.653-4.995
						c0.236-2.523-0.42-4.932-0.525-7.419c-0.121-2.817,0.771-5.367,0.787-8.216c0.021-3.368-0.378-7.508,0.273-10.797
						c1.212-6.123,4.281-12.078,5.236-18.237c0.326-2.078,0.257-4.03,0-6.023c-0.278-2.156-1.5-4.795-1.017-6.999
						c0.142-0.656-0.074-1.631,0.671-2.036c0.252-0.131,1.962-0.121,1.962,0.168c0.944-2.98,0.524-6.763,1.154-9.937
						c0.65-3.311,1.757-6.517,3.525-9.402C222.669,146.2,225.356,140.959,229.642,137.323z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M236.988,90.339c-2.524,2.225-3.085,4.564-4.518,7.418c-1.716,3.442-1.91,7.786-1.569,11.527
						c0.247,2.786-1.086,6.223,0.037,8.873c0.667,1.568,1.563,2.838,2.015,4.612c0.603,2.345,0.43,4.827,0.918,6.967
						c-1.128,1.616-1.747,2.985-2.833,4.68c-0.199,0.31-0.377,0.625-0.545,0.944c-0.257-1.165-0.419-2.177-0.524-2.681
						c-0.661-3.059,0.608-5.493,0.461-8.421c-0.11-2.177-1.28-2.634-2.502-4.218c-1.978-2.555-0.032-4.848-0.394-7.55
						c-0.168-1.223-0.483-2.188-1.595-1.118c-1.044,1.007-0.85,2.466-1.527,3.484c-0.692,1.049-1.516,0.283-1.794-0.929
						c-0.22-0.976-0.068-2.109,0.085-3.085c0.283-1.868,2.728-3.757,2.009-5.693c-0.409-1.091-1.479-1.359-1.642-2.644
						c-0.095-0.798,0.54-1.926-0.268-2.466c-0.667-0.435-2.996,0.257-3.788,0.178c-1.999-0.2-3.174-0.136-5.11,0.456
						c-1.742,0.53-3.851,0.373-5.268,1.605c-1.133,0.986-2.083,1.689-3.432,2.455c-0.54,0.305-2.046,1.884-3.489,2.886
						c0.451-1.505,1.049-2.906,2.078-3.767c2.66-2.225,6.842-4.837,10.294-5.829c2.618-0.75,6.107,0.682,7.907-1.149
						c1.684-1.716,2.639-2.382,4.837-3.374c2.025-0.913,3.169-1.973,4.549-3.646c1.065-1.291,2.036-3.185,3.331-4.234
						c1.653-1.343,2.896-0.43,4.932-0.304C242.108,87.291,238.247,89.227,236.988,90.339z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M288.804,220.314c-1.286,3.924-2.104,4.627-2.104,8.924c0,3.311,0.656,7.597,0.929,11.815
						c-0.845-0.121-1.584-0.126-1.989,0.263c1.108,0.635-1.616,7.288-2.864,8.132c-1.983,1.343-3.369,0.551-4.68,3.205
						c-1.118,2.273-1.663,4.423-3.94,5.945c-0.614-1.48-1.018-3.012-1.353-4.56c0.646-1.579,1.275-3.069,3.001-4.722
						c2.813-2.691,4.539-3.043,5.772-7.345c0.986-3.474,0.74-7.004,0.918-10.887c0.205-4.439,1.91-7.094,2.755-11.281
						c0.934-4.664-1.118-5.163-2.256-9.533c-1.065-4.071-0.625-8.594-1.023-12.765c-1.994-20.672,0.377-42.571-2.624-62.697
						c0.493-3.547-3.263-8.08-1.049-10.546c2.193,2.833,2.655,6.081,4.187,8.982c2.708,5.147,1.49,1.469,5.703,1.117
						c0.776,4.03-1.784,6.338-2.676,9.879c-0.897,3.573-0.955,8.762-0.929,12.451c0.058,8.61,0.278,16.815-0.514,25.452
						c-0.693,7.539-1.422,18.159,1.443,25.184C287.366,211.873,290.598,214.826,288.804,220.314z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M251.468,151.006c0.2,0.372,0.393,0.745,0.593,1.118
						C251.835,151.751,251.636,151.378,251.468,151.006z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M306.543,204.097c1.186,7.818-14.743,8.432-13.452,15.131c2.361-2.387,5.566-0.587,8.783-0.351
						c-2.209,1.327-5.766,1.742-8.121,3.326c-3.08,2.083-4.696,6.773-7.508,8.027c-0.582-7.382,4.066-10.866,0.855-17.13
						c-2.582-5.042-3.237-8.699-3.558-14.807c-0.28-5.402-2.746-10.188,0.076-15.282c1.561-2.818,3.71-5.551,5.443-8.299
						c0.793-1.258,10.135-16.221,11.255-15.231c1.094,0.966-2.503,10.61-2.959,12.415c-1.138,4.508-4.197,7.404-7.447,10.237
						c-3.951,3.443-1.982,5.512-1.751,10.274c0.173,3.431,0.158,4.659,1.422,8.227c0.724,2.04,0.325,6.238,1.307,8.038
						C293.609,213.651,302.456,207.014,306.543,204.097z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M311.343,179.139c-1.191-1.238-5.178-3.337-5.163-5.126c0.016-1.37,2.828-2.891,3.3-4.334
						c0.782-2.382-0.168-5.325-0.761-7.639c-0.787-3.08-0.472-5.981-0.052-9.008c0.357,1.301,0.829,2.581,1.107,3.935
						c0.688,3.326-0.226,6.123,0,9.402c0.137,1.941,1.343,3.547,1.311,5.43c-0.021,1.007-0.865,2.503-1.049,3.41
						c1.333,2.083,2.372,3.799,3.065,5.74C312.513,180.34,311.91,179.731,311.343,179.139z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.text }}
                    d="M211.174,131.53c-0.551,1.642-0.824,3.295-0.913,5.058c-0.079,1.643,0.021,3.264-0.393,4.869
						c-0.451,1.758-1.133,3.321-1.139,5.21c-0.01,2.634,1.039,5.289,0.624,8.043c-0.467,3.101-0.598,6.464-1.359,9.481
						c-0.703,2.791-0.677,4.544-0.341,7.335c0.179,1.506-0.341,1.674-0.976,2.76c-0.782,1.343-0.782,1.338-0.614,3.022
						c0.294,2.933-0.672,5.11-1.574,7.733c-0.488,1.401-0.225,2.471-0.509,3.788c-0.173,0.824-0.971,1.343-1.259,2.151
						c-0.52,1.459-0.016,2.881-0.855,4.339c-1.244,2.167-0.504,4.622,0,6.999c0.802,3.762-0.551,6.973-0.389,10.803
						c0.226,5.152-0.372,10.168-0.834,15.079c-0.33,3.478,0.231,6.894-1.537,10.189c-0.026,0.047-0.047,0.089-0.079,0.136
						c-0.142,0.236-0.32,0.467-0.514,0.688v0.005c-0.572,0.651-1.264,1.27-1.537,2.052c-0.719,0.797-1.49,1.479-2.104,1.904
						c-0.482-1.175-0.273-5.535-1.249-6.742c-0.95-1.181-3.405-1.884-4.879-2.115c-2.193-0.341-7.251-0.698-9.056,1.097
						c-0.467-0.084-0.824-1.055-1.039-1.527c0.315-0.356,0.729-1.547,1.076-2.66c1.412-0.016,2.98,0.708,4.554,0.808
						c2.518,0.158,4.549-0.54,7.093,0.268c1.742,0.556,2.975,1.034,4.035,2.356c0.472,0.593-0.346,1.244,0.908,1.212
						c1.081-0.032,1.286-1.443,1.364-2.162c0.11-1.007-0.499-2.193-0.194-3.232c0.43-1.464,1.228-2.366,1.412-4.14
						c0.205-1.983,0.21-3.861,0.215-5.85c0-1.773,0.509-3.442,0.488-5.241c-0.021-1.815,0.037-3.636-0.042-5.457
						c-0.047-1.013,0.142-3.08-0.331-4.003c-0.299-0.577-1.202-0.189-1.401-1.165c-0.2-0.971,1.112-2.02,1.243-2.985
						c0.152-1.139-0.582-2.639-0.493-3.898c0.089-1.212,0.383-2.482,0.703-3.651c0.735-2.681,1.448-5.236,2.282-7.86
						c0.588-1.868,1.763-2.99,2.571-4.533c0.944-1.799,1.196-3.153,0.525-4.953c-0.32-0.855-1.196-1.92-0.572-2.723
						c-1.307-0.577-7.225-1.165-6.916-3.148c1.118-0.283,2.277-0.477,3.442-0.556c0.871-0.058,3.631,0.782,4.386,0.357
						c1.695-0.96-2.849-2.524-3.127-3.342c0.147,0.425,3.169-0.703,3.321-1.564c0.477-2.749,0.126-5.897,0.688-8.704
						c0.477-2.372,1.27-4.633,1.059-7.099c-0.142-1.695-1.327-3.117-1.385-4.785c-0.068-1.994,0.682-4.392,0.688-6.521
						c0-2.817-0.021-5.787-0.278-8.667c-0.184-2.051-0.604-4.15-0.252-6.196c0.241-1.412,0.787-2.739,0.902-4.171
						c0.314-0.042,0.708,0.021,0.997,0.178c0.095,1.736,0.173,5.299,1.354,6.705c1.128,1.343,3.374-0.467,3.762,1.039
						C212.916,128.288,211.442,130.738,211.174,131.53z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight }}
                    d="M272.173,115.222c-1.313,5.439-4.728,12.666-7.686,17.506
						c-3.115,5.097-3.931,10.751-4.22,16.786c-0.282,5.892,2.131,11.532,3.147,17.11c0.655,3.598,2.115,12.342,6.219,13.61
						c2.18-4.598-0.332-11.913-0.257-16.847c0.04-2.607-0.4-5.219-0.549-7.819c-0.049-0.854-0.725-5.67,1.082-5.363
						c0.852,0.145,1.25,2.801,1.552,3.495c0.51,1.17,1.247,2.593,2.756,2.398c2.037-9.449-3.193-17.161-2.748-26.37
						c0.165-3.408,2.176-6.7,2.888-10.061C274.764,117.752,274.203,111.164,272.173,115.222z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight }}
                    d="M295.084,123.267c-1.071,1.873-1.953,5.012-1.045,7.063c2.356-0.215,4.163-2.719,5.638,0.314
						c0.555,1.141-0.078,2.48-0.138,3.615c-0.111,2.097,0.007,3.079,0.779,5.049c1.106,2.824,1.621,5.835,2.139,8.809
						c0.146,0.839,0.616,6.803,2.329,5.193c1.614-1.516-2.379-10.348-2.88-12.261c-0.797-3.04,0.528-5.714,0.261-8.863
						c-0.152-1.792-0.581-4.838-1.309-6.427C300.17,124.258,296.959,120.669,295.084,123.267z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight }}
                    d="M314.717,214.533c-0.257,1.726-0.566,3.357-0.829,4.968c-0.446,0.257-0.987,0.456-1.664,0.572
						c-1.899,0.331-4.218,0.32-6.107,0.467c2.02-1.506-3.762-2.162-1.076-4.481c0.913-0.787,2.083-0.409,2.954-1.432
						c1.112-1.307,0.955-3.321,1.49-4.832c0.64-1.794,1.558-2.944,1.821-4.896c0.236-1.726-0.147-2.555,0.619-4.375
						c1.757-4.176,2.078-5.231,0.897-9.696c-0.341-1.301-1.705-5.451-1.49-6.679c0.236-1.333,1.113-1.757,2.193-1.868
						c0.231,0.881,0.398,1.826,0.504,2.875c0.545,5.593,2.335,10.814,2.623,16.506C316.884,206.159,315.373,210.183,314.717,214.533
						z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight }}
                    d="M271.474,191.211c-2.351,4.804-0.068,12.791,1.17,17.645c0.745,2.922,0.315,5.377,0.399,8.367
						c0.131,4.679,3.167,8.808,3.538,13.454c0.054,0.675-0.242,7.805,2.094,3.864c0.432-0.729-0.341-4.217-0.299-5.175
						c0.108-2.48,0.441-4.972,0.904-7.41c0.752-3.964,1.809-7.518-0.201-11.343c-1.165-2.218-2.321-2.815-2.367-5.778
						c-0.044-2.799,0.356-5.553,0.213-8.382C276.793,193.843,275.59,186.546,271.474,191.211z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight }}
                    d="M206.94,106.74c-2.494,1.898-3.94,4.513-5.254,7.281c-0.986,2.078-1.803,5.054,1.364,3.667
						c2.785-1.22,3.621-6.489,7.574-3.961c2.181,1.396,1.529,2.512,1.583,4.684c0.085,3.435,1.572,2.694,3.414,4.936
						c4.085,4.971-0.31,11.644-0.81,16.748c-0.1,1.021-1.156,6.883-0.235,7.499c1.705,1.141,2.939-1.987,3.487-2.855
						c2.409-3.814,8.583-9.372,8.253-14.068c-0.233-3.335-3.412-5.468-3.911-8.593c-0.124-0.778,0.637-1.736,0.292-2.489
						c-0.637-1.386-2.502-0.987-3.255-2.132c-1.802-2.737,1.609-6.505,1.678-9.132C221.266,102.76,210.669,103.438,206.94,106.74z"
                  />
                </g>
                <g>
                  <path
                    style={{ fill: theme.imageHighlight }}
                    d="M195.14,149.999c-0.079,2.109-1.007,3.893-1.055,6.039c-0.068,3.295,1.087,4.89,0.179,8.179
						c-1.973,7.12-1.044,15.006-3.589,22.036c-1.018,2.817-1.92,4.68-1.018,7.712c0.309,1.05,0.976,1.863,1.044,3.017
						c0.084,1.458-0.908,2.933-0.818,4.465c0.073,1.311,0.488,3.059,1.175,4.182c0.682,1.107,2.996,2.046,3.306,3.153
						c0.797,2.807-3.967,0.866-5.588,2.345c-0.918,0.839-0.755,2.062-1.27,3.038c-0.451,0.856-1.863,1.784-2.03,2.813
						c-0.635,3.893,6.815,2.277,6.469,6.731c-1.957,0.682-4.711-0.96-6.348-0.268c-1.616,0.682-1.37,2.964-2.513,3.977
						c-0.509,0.451-1.537,0.85-2.597,1.186c0.451-1.91,0.32-3.048,0-5.268c-0.42-2.917,0.115-4.764,1.286-7.351
						c0.75-1.653,1.841-4.203,2.781-5.1c1.81-1.726,1.81-7.109,1.532-9.549c-0.299-2.644-0.671-5.477-0.352-8.116
						c0.263-2.104,1.06-4.061,1.443-6.144c0.357-1.936,0.656-3.909,1.122-5.824c0.992-4.092,2.818-8.012,2.204-12.303
						c-0.645-4.512-0.163-8.426,0.882-12.828c0.53-2.225,0.755-4.156,0.855-6.433c0.079-1.768-0.189-4.664,0.887-6.133
						c-0.535-1.459-0.331-3.164,0.1-4.916C194.117,142.433,195.282,146.195,195.14,149.999z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        style={{ fill: theme.jacketColor }}
                        d="M262.948,121.158c-2.571,9.396-6.485,17.419-5.661,27.283c0.22,2.67-0.467,5.283-0.184,7.954
								c0.252,2.367,1.307,4.313,2.036,6.517c1.249,3.767,2.34,8.159,2.309,12.052c-0.074,9.433-1.129,18.856,0.325,28.342
								c0.456,2.975,0.362,6.238,1.055,9.166c0.614,2.581,1.658,4.785,2.057,7.45c0.325,2.146,2.193,10.902,1.931,13.096
								c-3.605-0.042-5.934,1.637-9.418,2.523c-6.259,1.59-11.763,1.763-18.132,0.981c-9.533-1.17-20.897-0.556-29.439-5.173
								c-0.835-1.448,0.976-2.912,0.661-4.323c-0.357-1.611-1.585-1.768-1.71-3.678c-0.173-2.566,1.301-5.446,1.584-8.038
								c0.404-3.704,1.653-7.146,1.894-10.929c0.226-3.51,0.598-6.805,0.971-10.305c0.283-2.712,0.519-5.551,1.049-8.232
								c0.482-2.424,1.81-4.627,2.114-7.067c0.168-1.343-0.126-2.571,0.257-3.972c0.719-2.597,2.246-4.491,2.817-7.198
								c1.028-4.895,1.275-10.068,2.949-14.816c1.684-4.801,3.599-9.554,5.514-14.271c1.233-3.038,2.287-7.062,4.024-9.832
								c1.417-2.256,1.317-4.774,0.215-7.125c-0.499-1.06-1.506-2.025-1.8-3.19c-0.52-2.062,0.357-4.711,0.535-6.752
								c0.215-2.502-0.251-5.205-0.173-7.744c0.016-0.629,0.063-1.243,0.167-1.847c0.41-2.387,1.601-5.86,2.576-8.106
								c1.086-2.498,4.313-6.133,7.046-6.506c1.055,3.636,4.837,5.808,9.428,7.23c6.27,1.941,14.045,2.476,18.379,3.436
								c0.545,0.845,0.205,3.221-0.294,5.635c-0.451,2.198-1.039,4.428-1.206,5.566C266.154,113.76,264.128,116.845,262.948,121.158
								z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: theme.dark }}
                        d="M249.947,94.647l-0.782,3.961l-13.499-1.312c-0.089,0.021-3.893-6.385,4.853-9.879
								C241.573,91.052,245.356,93.225,249.947,94.647z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      style={{ fill: theme.jacketColor }}
                      d="M264.884,219.921c0.325,2.146,2.193,10.902,1.931,13.096c-3.605-0.042-5.934,1.637-9.418,2.523
							c-6.259,1.59-11.763,1.763-18.132,0.981c-9.533-1.17-20.897-0.556-29.439-5.173c-0.835-1.448,0.976-2.912,0.661-4.323
							c-0.357-1.611-1.585-1.768-1.71-3.678c-0.173-2.566,1.301-5.446,1.584-8.038c0.404-3.704,1.653-7.146,1.894-10.929
							c0.226-3.51,0.598-6.805,0.971-10.305c0.283-2.712,0.519-5.551,1.049-8.232c0.482-2.424,1.81-4.627,2.114-7.067
							c0.168-1.343-0.126-2.571,0.257-3.972c0.719-2.597,2.246-4.491,2.817-7.198c1.028-4.895,1.275-10.068,2.949-14.816
							c1.684-4.801,3.599-9.554,5.514-14.271c1.233-3.038,2.287-7.062,4.024-9.832c1.417-2.256,1.317-4.774,0.215-7.125
							c-0.499-1.06-1.506-2.025-1.8-3.19c-0.52-2.062,0.357-4.711,0.535-6.752c0.215-2.502-0.251-5.205-0.173-7.744
							c1.553,1.794,3.888,3.374,5.052,4.833c3.285,4.118-0.199,5.215,0.588,10.1c0.965,6.034,5.378,7.387,2.844,14.108
							c-2.088,5.535-5.798,10.614-7.041,16.37c-0.929,4.286-1.196,7.151-3.064,11.259c-1.668,3.683-4.265,8.085-2.781,12.566
							c1.113,3.357,7.199,6.186,5.168,10.388c-1.873,3.877-10.656,2.781-7.004,9.549c1.931,3.573,13.164,6.191,10.503,11.821
							c-2.46,5.194-13.626,1.191-17.088,7.067c3.027,3.945,12.052,3.216,6.611,8.011c-3.914,3.453-11.028,2.046-11.212,8.663
							c7.424,4.428,17.508,2.786,25.651,1.574c6.469-0.96,21.307,3.09,22.602-5.64c0.677-4.554-3.384-9.402-4.266-13.62
							c-0.797-3.83,0.656-5.635,1.086-9.213c0.53-4.433-1.915-7.13-3.038-11.16c-0.929-3.342-2.004-8.573-1.742-12.12
							c0.268-3.636,2.498-6.863,1.637-10.693c-1.102-4.89-4.501-8.851-5.509-13.773c-0.514-2.508,0.021-4.743,0.031-7.251
							c0.016-2.644-0.524-5.383,0.262-7.969c1.302-4.26,2.041-7.891,2.246-12.34c0.22-4.806,0.079-9.701,0.844-14.439
							c0.215-1.333,0.441-2.781,1.249-3.924c1.8-2.534,9.224-3.001,12.309-3.903c0.42-0.126,0.881-0.268,1.364-0.42
							c-0.451,2.198-1.039,4.428-1.206,5.566c-0.672,4.476-2.697,7.561-3.878,11.874c-2.571,9.396-6.485,17.419-5.661,27.283
							c0.22,2.67-0.467,5.283-0.184,7.954c0.252,2.367,1.307,4.313,2.036,6.517c1.249,3.767,2.34,8.159,2.309,12.052
							c-0.074,9.433-1.129,18.856,0.325,28.342c0.456,2.975,0.362,6.238,1.055,9.166
							C263.441,215.052,264.485,217.255,264.884,219.921z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: theme.jacketColor }}
                      d="M233.173,104.903c5.169,3.722,10.355,6.428,16.612,7.895c2.819,0.661,6.059-0.039,6.392,3.045
							c-6.484,0.304-13.386,0.662-19.448-1.496C233.384,113.157,227.365,108.169,233.173,104.903z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: theme.jacketColor }}
                      d="M233.597,151.526c-0.564,0.225-1.17,0.78-1.722,1.652c-1.42,2.243-1.859,4.974-2.737,7.439
							c-1.322,3.711-2.534,7.72-2.259,11.706c0.224,3.243,3.984,6.24,3.453,9.182c-0.924,5.112-8.379,3.879-8.7,9.561
							c-0.327,5.781,7.978,9.714,13.022,10.531c1.585,0.257,4.932,1.082,6.31-0.262c3.399-3.317-1.763-3.979-4.14-5.268
							c-5.861-3.175-4.52-3.081-1.878-7.474c5.357-8.909-2.99-9.658-2.833-18.108c0.105-5.631,3.99-10.816,3.607-16.574
							C235.582,151.862,234.663,151.103,233.597,151.526z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: theme.jacketColor }}
                      d="M259.582,217.182c-2.118,4.565-5.96,6.341-10.756,7.175c-4.779,0.831-9.483,1.595-14.325,1.967
							c-4.424,0.34-12.793-0.526-16.016,3.266c2.406,3.865,12.224,1.063,16.022,1.058c6.811-0.007,13.539,1.749,20.358,0.249
							c4.615-1.016,6.716-4.346,6.814-8.77C261.732,219.809,262.207,218.224,259.582,217.182z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#F0D4D1" }}
                          d="M271.18,63.255c0.052,1.007-0.409,2.178-0.961,2.996c-0.414,0.624-0.918,1.149-1.396,1.721
									c-0.451,0.541-1.18,1.175-1.438,1.831c-0.352,0.887-0.572,1.81-0.955,2.691c-0.137,0.31-0.31,0.598-0.488,0.882
									c-0.288,0.446-0.587,0.881-0.771,1.369c-0.2,0.52-0.184,0.992-0.126,1.563c0.026,0.278-0.163,0.698-0.147,0.976
									c0.016,0.257-0.026,0.503,0,0.761c0.058,0.713,0.042,1.432,0.026,2.151c-0.037,1.448-0.137,2.917,0.083,4.36
									c0.263,1.752,0.745,3.358,1.322,5.047c0.016,0.047,0.032,0.1,0.047,0.147c0.929,2.708,2.078,5.976,2.078,8.856
									c-1.102,1.569-4.801,2.072-6.558,2.393c-2.844,0.52-5.641,1.081-8.537,1.285c-3.116,0.221-6.805-0.357-9.832-1.075
									c-2.487-0.593-6.443-1.821-7.954-3.893c0.037-0.011,0.047-0.011,0.047-0.011c0.005,0,0.021-0.005,0.042-0.01
									c0.031-0.063,0.058-0.121,0.084-0.184c0.698-1.517,0.845-3.216,1.296-4.801c0.026-0.084,0.047-0.173,0.073-0.262
									c0.567-2.004,1.296-4.539,1.433-6.559c-0.645-0.703-0.776-1.7-1.705-2.099c-1.176-0.503-3.148,0.373-4.302-0.687
									c-0.441-0.404-0.619-1.097-0.724-1.826c-0.1-0.713-0.126-1.453-0.252-1.978c-0.352-1.506-0.688-2.786-1.265-4.187
									c-0.042-0.11-0.089-0.226-0.142-0.341c-0.839-1.925-0.939-4.077-0.929-6.206c0.005-1.506,0.278-2.922,0.787-4.323
									c0.462-1.28,1.144-2.015,0.761-3.395c-0.357-1.27-0.971-2.345-0.625-3.683c0.294-1.139,1.139-2.104,1.611-3.248
									c0.672-1.637,0.31-3.258,0.624-4.953c0.131-0.703,0.367-1.338,0.692-1.915c0.131-0.247,0.284-0.477,0.451-0.703
									c2.046-2.775,6.186-3.972,9.292-4.554c2.382-0.446,4.738-1.028,7.188-0.908c1.453,0.074,3.006,0.357,4.407,0.735
									c1.679,0.451,3.342,0.986,4.591,2.167c0.089,1.496-0.478,3.033-0.567,4.539c-0.063,1.076-0.451,2.922,0.084,3.898
									c0.488,0.887,1.454,2.292,2.031,3.153c0.572,0.855,1.238,1.742,1.585,2.728c0.377,1.07,0.215,2.34,0.482,3.452
									c0.582-0.419,1.034-0.766,1.726-1.044c1.044-0.425,2.235-0.682,3.358-0.425c0.792,0.189,1.632,0.551,2.183,1.175
									C270.503,61.55,271.127,62.301,271.18,63.255z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#D1ABA6" }}
                            d="M248.378,83.214c-1.542,1.637-2.691,3.494-4.05,5.252c-2.072,2.686-4.465,3.169-7.209,3.584
										c0.567-2.004,1.296-4.539,1.433-6.559c-0.645-0.703-0.776-1.7-1.705-2.099c-1.176-0.503-3.148,0.373-4.302-0.687
										c-0.441-0.404-0.619-1.097-0.724-1.826c2.398,2.34,8.053,0.583,10.808-0.073c3.185-0.751,6.196-1.936,9.224-2.817
										C252.927,79.788,249.433,82.091,248.378,83.214z"
                          />
                        </g>
                        <g>
                          <path
                            style={{ fill: "#D1ABA6" }}
                            d="M268.457,98.607c-1.102,1.569-4.801,2.072-6.558,2.393c-2.844,0.52-5.641,1.081-8.537,1.285
										c-3.116,0.221-6.805-0.357-9.832-1.075c-2.487-0.593-6.443-1.821-7.954-3.893c0.037-0.011,0.047-0.011,0.047-0.011
										c0.005,0,0.021-0.005,0.042-0.01c0.031-0.063,0.058-0.121,0.084-0.184c2.586,1.936,6.779,2.492,9.664,2.896
										c3.657,0.52,7.813-0.11,11.113-1.752c1.878-0.944,3.804-2.062,5.503-3.274c1.821-1.301,2.876-3.636,4.302-5.378
										c0.016,0.047,0.032,0.1,0.047,0.147C267.308,92.459,268.457,95.727,268.457,98.607z"
                          />
                        </g>
                        <g>
                          <path
                            style={{ fill: "#D1ABA6" }}
                            d="M271.18,63.255c0.052,1.007-0.409,2.178-0.961,2.996c-0.414,0.624-0.918,1.149-1.396,1.721
										c-0.188,0.226-0.425,0.467-0.656,0.719c-0.32,0.357-0.629,0.729-0.782,1.112c-0.22,0.551-0.388,1.118-0.577,1.679v0.005
										c-0.115,0.341-0.236,0.677-0.378,1.007c-0.137,0.31-0.31,0.598-0.488,0.882c-0.084-0.43-0.131-0.866-0.142-1.312
										c-0.016-0.352-0.005-0.713,0.026-1.07c0.037-0.446,0.294-1.568,0.482-2.838c0.404-2.697,0.509-6.049-2.382-4.968
										c-2.786,1.039-3.059,5.304-6.469,5.892c0.037-0.991,0.462-1.931,0.766-2.896c0.215-0.692,0.278-1.511,0.546-2.172
										c0.272-0.661,0.965-1.254,1.044-1.988c0.105-0.976-0.588-1.207-0.939-2.004c-0.488-1.108-0.268-2.393-0.656-3.552
										c-0.934-2.807-5.094-5.981-3.935-8.987c0.357-0.918,1.758-2.424,1.322-3.442c-0.503-1.196-2.786-0.776-3.809-0.656
										c-3.882,0.456-6.999,3.122-10.797,4.035c-1.296,0.315-2.151,0.425-3.463,0.163c-1.49-0.299-2.676-1.06-4.003-1.632
										c2.046-2.775,6.186-3.972,9.292-4.554c2.382-0.446,4.738-1.028,7.188-0.908c1.453,0.074,3.006,0.357,4.407,0.735
										c1.679,0.451,3.342,0.986,4.591,2.167c0.089,1.496-0.478,3.033-0.567,4.539c-0.063,1.076-0.451,2.922,0.084,3.898
										c0.488,0.887,1.454,2.292,2.031,3.153c0.572,0.855,1.238,1.742,1.585,2.728c0.377,1.07,0.556,2.618,0.824,3.73
										c0.357-1.029,0.692-1.045,1.385-1.322c1.044-0.425,2.235-0.682,3.358-0.425c0.792,0.189,1.632,0.551,2.183,1.175
										C270.503,61.55,271.127,62.301,271.18,63.255z"
                          />
                        </g>
                        <g>
                          <path
                            style={{ fill: "#D1ABA6" }}
                            d="M268.168,68.691c-0.32,0.357-0.629,0.729-0.782,1.112c-0.22,0.551-0.388,1.118-0.577,1.679
										v0.005c-0.31,0.226-0.65,0.415-1.007,0.577c-0.168,0.074-0.336,0.147-0.509,0.21c-0.897,0.346-1.878,0.562-2.828,0.409
										c-0.955-0.147-1.878-0.703-2.282-1.579c1.353,0.477,2.922,0.315,4.15-0.436c1.091-0.666,1.333-1.59,1.978-2.513
										c0.042-0.063,0.095-0.131,0.142-0.194C267.203,66.981,268.006,67.72,268.168,68.691z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          style={{ fill: "#FAECE5" }}
                          d="M232.381,56.43c0.074,1.485,0.309,2.439-0.131,3.882c-0.226,0.755-0.708,1.496-0.95,2.246
									c-0.792,2.492,1.065,3.757,0.761,6.144c-0.22,1.752-1.238,3.384-1.611,5.115c-0.063,0.299-0.11,0.598-0.147,0.897
									c-0.042-0.11-0.089-0.226-0.142-0.341c-0.839-1.925-0.939-4.077-0.929-6.206c0.005-1.506,0.278-2.922,0.787-4.323
									c0.462-1.28,1.144-2.015,0.761-3.395c-0.357-1.27-0.971-2.345-0.625-3.683c0.294-1.139,1.139-2.104,1.611-3.248
									c0.672-1.637,0.31-3.258,0.624-4.953c0.131-0.703,0.367-1.338,0.692-1.915c0.735,0.876,1.013,2.886,1.007,3.689
									C234.081,52.531,232.276,54.236,232.381,56.43z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#FAECE5" }}
                          d="M251.537,82.081c-0.562,1.327-2.316,2.727-3.351,3.778c-0.886,0.898-1.629,1.872-2.49,2.784
									c-0.929,0.983-2.672,2.159-2.131,3.64c0.575,1.572,3.516,4.845,5.15,4.491c2.161-0.469,4.522-3.258,4.657-5.418
									c0.087-1.393-0.321-2.489-0.757-3.664c-0.421-1.133-0.768-2.502,0.628-2.987c0.879-0.306,1.412,0.153,2.261-0.516
									c1.065-0.839,1.528-2.886,1.997-4.076c0.522-1.325,1.755-3.645,1.065-5.054c-0.496-1.015-0.959-0.989-1.911-0.204
									c-1.507,1.242-1.953,3.41-2.916,5.028C253.235,80.732,251.998,81.768,251.537,82.081z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#FAECE5" }}
                          d="M255.865,60.832c-3.885-0.446-4.496,3.016-5.08,5.932c-0.446,2.226-2.132,5.021-1.467,7.361
									c0.9,3.17,5.018-2.035,5.884-3.374c1.475-2.282,1.812-4.607,2.434-7.099c0.122-0.488,0.676-1.233,0.468-1.774
									C257.866,61.261,256.849,61.167,255.865,60.832z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#6D5F5D" }}
                          d="M278.977,52.61c-0.776-0.771-1.794-1.27-2.954-1.296c0.205,0.829,1.207,1.207,1.589,1.915
									c0.336,0.635,0.315,1.831,0.179,2.54c-0.378,1.978-2.062,3.337-2.818,5.152c-0.818,1.967-0.776,4.182-1.883,6.07
									c-1.265,2.167-2.576,4.161-4.035,6.139c-0.378,0.519-0.766,1.039-1.17,1.558c-0.724,0.934-1.228,1.642-1.663,2.754
									c-0.32,0.824-0.883,2.242-1.303,2.977c-0.572-2.697-0.544-5.459,0.836-8.008c0.829-1.548,1.532-3.437,2.608-4.801
									c0.976-1.249,2.261-2.697,2.061-4.329c-0.184-1.485-1.259-2.938-2.728-3.216c-1.217-0.231-4.124,0.11-4.727,1.37
									c-0.614-0.682-0.478-1.868-0.85-2.792c-0.608-1.485-1.574-2.817-2.519-4.108c-1.013-1.391-2.376-2.702-2.12-4.454
									c0.289-1.946,0.688-3.714,1.422-5.541c0.346-0.86,0.451-0.845-0.189-1.39c-0.419-0.357-0.986-0.609-1.495-0.793
									c-1.794-0.645-4.638-0.703-6.359,0c0.199-0.299,0.436-0.582,0.583-0.908c-1.59,0.509-3.248,1.186-4.659,2.062
									c-2.099,1.307-4.051,2.691-6.521,3.206c-2.681,0.551-6.154,0.357-8.426-1.38c-1.296-0.991-2.235-2.66-2.078-4.281
									c0.026-0.284,0.089-0.572,0.189-0.85c0.341-0.021,0.692,0,1.028-0.026c-0.341-2.025-0.52-5.262,1.075-6.968
									c0.625,0.2,1.207,0.357,1.873,0.341c-0.708,0-0.734-2.151-0.503-2.513c-0.084-0.393,1.149-0.011,1.469,0.026
									c0.781,0.089,1.579,0.073,2.355-0.058c-0.74-0.021-1.186-0.892-1.674-1.375c2.377,0.404,5.037-0.278,7.482-0.283
									c2.051,0,3.898,0.504,5.787,1.275c-0.441-0.315-0.624-0.839-1.039-1.175c1.07,0.142,2.088,0.446,3.201,0.514
									c0.803,0.047,1.616,0.383,2.429,0.325c0.273-0.016,2.099-0.54,2.209-0.251c-0.053-0.131-0.021-0.389-0.068-0.509
									c3.29,1.165,6.773,0.43,9.958,2.099c2.487,1.301,3.615,3.09,4.895,5.478c0.273-0.577,0.347-1.689-0.063-2.235
									c0.945-0.073,2.54,1.128,3.243,1.668c1.165,0.892,1.926,2.277,2.377,3.651c0.514,1.569,0.787,3.646-0.152,5.137
									c0.493-0.383,1.202-0.598,1.763-0.908c0.624-0.341,1.049-0.887,1.585-1.254c0.672,1.506,0.352,3.059-0.482,4.413
									c-0.336,0.551-1.238,1.453-1.884,1.359C278.21,49.871,279.271,50.878,278.977,52.61z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#998781" }}
                          d="M278.977,52.61c-0.776-0.771-1.794-1.27-2.954-1.296c0.205,0.829,1.207,1.207,1.589,1.915
									c0.336,0.635,0.315,1.831,0.179,2.54c-0.2,1.049-0.771,1.925-1.375,2.791c-0.53,0.761-1.091,1.511-1.443,2.361
									c-0.818,1.967-0.776,4.182-1.883,6.07c-1.265,2.167-2.576,4.161-4.035,6.139c0.031-0.41,0.136-0.824,0.315-1.217
									c0.178-0.394,2.697-3.919,0.976-3.835c0.498-0.845,0.923-1.884,1.333-2.796c0.367-0.808,0.205-0.839-0.042-1.391
									c-0.294-0.671-0.467-1.122-0.236-2.004c0.247-0.965,1.254-1.527,1.532-2.345c-1.643-1.154-1.548-3.342-1.905-5.084
									c-0.908,0.184-1.81,0.629-2.686,0.771c-0.163-0.645,0.005-2.812-0.981-2.954c-0.666-0.1-2.025,1.92-2.461,2.403
									c-2.177-1.521-2.23-3.998-1.805-6.542c0.163-0.976,0.383-2.067,0.703-3.006c0.257-0.766,0.771-1.506,0.865-2.314
									c0.194-1.716-1.705-1.695-2.98-1.757c0.672-2.088,0.231-2.466-1.847-3.159c-2.272-0.756-4.837-1.086-7.177-0.456
									c0.094-0.493,0.362-0.955,0.44-1.432c-4.759,0.414-8.295,4.265-13.18,2.177c0.068,0.404,0.1,0.85,0.278,1.228
									c0.142,0.304,0.734,0.719,0.755,1.049c0.047,0.74-1.202,0.992-1.784,1.086c-0.85,0.137-5,0.457-5.121-0.892
									c-0.173,1.002,1.364,1.212,0.336,1.899c-1.212,0.808-3.468-0.226-4.628-1.5c0.026-0.284,0.089-0.572,0.189-0.85
									c0.341-0.021,0.692,0,1.028-0.026c-0.226-1.348-0.383-3.243,0.016-4.853c0.194-0.808,0.53-1.548,1.06-2.115
									c0.625,0.2,1.207,0.357,1.873,0.341c-0.708,0-0.734-2.151-0.503-2.513c-0.084-0.393,1.149-0.011,1.469,0.026
									c0.619,0.068,1.243,0.073,1.862,0.01c0.168-0.016,0.331-0.042,0.493-0.068c-0.74-0.021-1.186-0.892-1.674-1.375
									c1.605,0.273,3.342,0.053,5.047-0.126c0.824-0.084,1.643-0.157,2.435-0.157c2.051,0,3.898,0.504,5.787,1.275
									c-0.441-0.315-0.624-0.839-1.039-1.175c1.07,0.142,2.088,0.446,3.201,0.514c0.803,0.047,1.616,0.383,2.429,0.325
									c0.273-0.016,2.099-0.54,2.209-0.251c-0.053-0.131-0.021-0.389-0.068-0.509c2.891,1.023,5.929,0.583,8.783,1.59
									c0.399,0.136,0.787,0.304,1.175,0.509c0.619,0.326,1.154,0.677,1.626,1.065c1.433,1.165,2.309,2.618,3.268,4.412
									c0.273-0.577,0.347-1.689-0.063-2.235c0.945-0.073,2.54,1.128,3.243,1.668c0.131,0.1,0.257,0.205,0.378,0.32
									c0.955,0.882,1.6,2.109,1.999,3.331c0.042,0.126,0.079,0.257,0.121,0.389c0.43,1.511,0.593,3.379-0.273,4.748
									c0.493-0.383,1.202-0.598,1.763-0.908c0.624-0.341,1.049-0.887,1.585-1.254c0.672,1.506,0.352,3.059-0.482,4.413
									c-0.336,0.551-1.238,1.453-1.884,1.359C278.21,49.871,279.271,50.878,278.977,52.61z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#D7BDB6" }}
                            d="M245.802,35.522c-0.745,1.286-3.872,0.651-5.095,0.519c-2.03-0.21-3.641-0.782-5.551-1.307
										c0.068,0.693,1.632,2.361,1.312,2.729c-0.629,0.729-2.901-0.86-3.4-1.149c0.016,1.128,1.097,1.742,1.139,2.733
										c-2.104-0.047-2.792-1.931-3.216-3.72c0.194-0.808,0.53-1.548,1.06-2.115c0.625,0.2,1.207,0.357,1.873,0.341
										c-0.708,0-0.734-2.151-0.503-2.513c-0.084-0.393,1.149-0.011,1.469,0.026c0.619,0.068,1.243,0.073,1.862,0.01
										c0.163,0.173,0.325,0.331,0.493,0.472c0.95,0.803,1.926,1.196,3.027,1.737c0.782,0.383,1.516,0.881,2.356,1.16
										C243.624,34.782,245.094,34.667,245.802,35.522z"
                          />
                        </g>
                        <g>
                          <path
                            style={{ fill: "#D7BDB6" }}
                            d="M265.435,33.512c-1.28,0.273-2.445-0.682-3.657-0.866c0.462,0.881,1.836,1.29,1.144,2.597
										c-3.363-1.433-6.338-2.991-10.047-3.552c0.446,0.215,0.835,0.514,1.023,0.944c-1.333,0.719-2.964-0.167-4.286-0.372
										c0.619,0.398,1.458,0.897,1.794,1.532c-1.715,0.053-3.662-0.283-5.262-0.913c-1.454-0.572-3.966-1.905-5.525-3.374
										c0.824-0.084,1.643-0.157,2.435-0.157c2.051,0,3.898,0.504,5.787,1.275c-0.441-0.315-0.624-0.839-1.039-1.175
										c1.07,0.142,2.088,0.446,3.201,0.514c0.803,0.047,1.616,0.383,2.429,0.325c0.273-0.016,2.099-0.54,2.209-0.251
										c-0.053-0.131-0.021-0.389-0.068-0.509c2.891,1.023,5.929,0.583,8.783,1.59C265.036,31.928,265.514,32.762,265.435,33.512z
										"
                          />
                        </g>
                        <g>
                          <path
                            style={{ fill: "#D7BDB6" }}
                            d="M273.982,36.859c-0.037,0.625-0.095,1.259-0.131,1.894
										c-0.115,1.936-0.436,4.166-1.128,5.945c-0.583,1.495-2.849,3.416-2.303,0.808c0.346-1.673,1.249-4.092-0.357-5.399
										c0.016,0.918-0.818,1.448-1.605,0.918c-0.729-0.493-0.524-1.669-0.524-2.419c-0.005-1.747-0.005-3.327-0.488-5.032
										c-0.079-0.289-0.184-0.588-0.289-0.881c1.433,1.165,2.309,2.618,3.268,4.412c0.273-0.577,0.347-1.689-0.063-2.235
										c0.945-0.073,2.54,1.128,3.243,1.668C273.735,36.639,273.861,36.744,273.982,36.859z"
                          />
                        </g>
                        <g>
                          <path
                            style={{ fill: "#D7BDB6" }}
                            d="M278.977,52.61c-0.776-0.771-1.794-1.27-2.954-1.296c0.205,0.829,1.207,1.207,1.589,1.915
										c0.336,0.635,0.315,1.831,0.179,2.54c-0.2,1.049-0.771,1.925-1.375,2.791c-0.724-0.089-1.448-0.247-1.826-0.619
										c-0.95-0.923-0.115-1.742,1.07-2.12c-0.052-0.787-1.778-1.233-2.46-1.438c0.005-0.047-0.005-0.293-0.011-0.346
										c0.52-0.163,1.663-0.671,1.416-1.396c-0.142-0.42-1.28-0.525-1.673-0.75c0.136-0.834,1.123-1.417,0.614-2.34
										c-0.661,0.567-1.574,1.102-2.429,0.503c0.876-1.207,2.083-2.177,2.828-3.521c0.525-0.955,0.761-2.046,1.233-3.038
										c0.415-0.881,0.524-2.025,0.923-2.917c0.43,1.511,0.593,3.379-0.273,4.748c0.493-0.383,1.202-0.598,1.763-0.908
										c0.624-0.341,1.049-0.887,1.585-1.254c0.672,1.506,0.352,3.059-0.482,4.413c-0.336,0.551-1.238,1.453-1.884,1.359
										C278.21,49.871,279.271,50.878,278.977,52.61z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      style={{ fill: theme.dark }}
                      d="M234.223,53.793c-2.46-0.793-5.689-0.304-8.001,0.656c0,0-0.7,7.826,1.005,9.05
							c1.705,1.224,2.947,0.515,3.454,0c0.506-0.515,3.061-3.872,3.41-4.712C234.441,57.946,234.223,53.793,234.223,53.793z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: theme.dark }}
                      d="M249.165,55.366c0,0-0.645,0.35-1.214,0.7c-0.568,0.35-0.962,5.028-1.721,5.815
							c-0.759,0.787-0.902,1.399-5.274,1.662c-4.373,0.262-4.81-5.688-4.81-5.688s-0.131-0.958-0.438-0.932
							c-0.306,0.026-1.618,1.863-1.618,1.863l0.131-4.994c0,0,1.705-0.252,2.197,0c0,0,1.804-1.454,4.361-1.552
							c2.558-0.099,8.384,0.656,8.384,0.656s12.799,3.41,14.833,4.82c2.033,1.41,2.426,2.294,2.426,2.294l-2.222,0.455
							c0,0-0.237-1.077-2.598-0.552C259.243,60.438,249.165,55.366,249.165,55.366z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FFCC7C" }}
                      d="M229.435,63.551c-1.241,0.381-2.316,0.148-2.618-3.311c-0.112-1.238-0.122-2.887-0.007-5.071
							c0,0,4.241-2.447,6.32-0.283c0.243,0.253,0.401,0.559,0.486,0.9C234.284,58.374,230.92,63.094,229.435,63.551z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#B6792A" }}
                      d="M229.435,63.551c-1.241,0.381-2.316,0.148-2.618-3.311c0.394,0.808,1.176,1.475,2.001,1.39
							c0.607-0.063,1.222-0.611,1.613-1.058c0.611-0.696,0.913-0.976,1.606-1.465c0.89-0.634,1.455-2.017,1.58-3.321
							C234.284,58.374,230.92,63.094,229.435,63.551z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FFF09D" }}
                      d="M227.558,55.618c-0.307,1.308-1.015,2.945,0.11,4.063c0.388-0.474,0.694-1.231,0.979-1.787
							c1.003,1.097,2.584-1.15,3.167-2.036c-0.798-0.958-3.36-1.422-4.255-0.35"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FDFFFF" }}
                      d="M229.765,54.551c-0.179,0.052-0.361,0.1-0.547,0.129c-0.363,0.057-0.695,0.097-1.014,0.302
							c-0.371,0.238-0.714,0.527-0.807,0.973c-0.073,0.351-0.044,0.907,0.109,1.238c0.076,0.163,0.305,0.448,0.518,0.419
							c0.242-0.032,0.175-0.365,0.261-0.558c0.22-0.496,0.583-0.487,1.038-0.472c0.24,0.008,0.85-0.044,1.011-0.281
							c0.143-0.211-0.137-0.386-0.076-0.61c0.056-0.205,0.366-0.237,0.542-0.291c0.374-0.114,0.788-0.109,1.153-0.235
							c0.491-0.169-0.222-0.677-0.482-0.767C230.867,54.19,230.326,54.39,229.765,54.551z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FFCC7C" }}
                      d="M245.218,61.705c-0.982,0.962-4.152,1.75-6.382,0.634c-0.801-0.401-1.307-1.212-1.623-2.089
							c-0.568-1.564-0.542-3.327-0.542-3.327s-0.174-3.196,2.536-3.699c1.971-0.365,4.376-0.197,5.745,0.22
							c0.516,0.151,0.884,0.341,1.031,0.548C246.529,54.754,246.204,60.742,245.218,61.705z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#B6792A" }}
                      d="M245.218,61.705c-0.982,0.962-4.152,1.75-6.382,0.634c-0.801-0.401-1.307-1.212-1.623-2.089
							c0.752,0.969,1.912,1.82,2.993,1.62c0.729-0.135,0.933-0.647,1.363-1.1c0.5-0.529,0.755-0.525,1.294-0.906
							c1.297-0.92,0.489-2.151,0.834-3.558c0.247-1.015,1.196-1.807,1.255-2.861c0.516,0.151,0.884,0.341,1.031,0.548
							C246.529,54.754,246.204,60.742,245.218,61.705z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FFF09D" }}
                      d="M238.397,54.852c-0.147-0.022-0.292-0.018-0.436,0.014c-0.191,1.22-0.975,1.966-0.76,3.34
							c0.089,0.572,0.394,1.135,0.555,1.663c0.678,0.336,0.539-0.159,0.906-0.525c0.827-0.824,0.689-0.626,1.657-0.981
							c1.303-0.479,1.646-2.022,2.238-3.178C243.893,52.58,238.974,54.093,238.397,54.852z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FDFFFF" }}
                      d="M238.18,54.963c-0.185,0.249-0.32,0.542-0.389,0.877c-0.077,0.378-0.086,0.776,0.014,1.151
							c0.099,0.371,0.304,0.704,0.598,0.951c0.093,0.078,1.164,0.624,1.179,0.509c0.082-0.614-0.517-1.053-0.624-1.609
							c1.126,0.057,1.245-0.182,1.514-1.159c0.068-0.247-0.221-0.364,0.065-0.787c0.304-0.448,0.928-0.272,1.02-0.904
							C240.393,53.666,238.906,53.981,238.18,54.963z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: theme.dark }}
                        d="M233.771,66.834c0,0-0.305-0.141-0.773-0.403c-0.234-0.131-0.508-0.293-0.805-0.482
								c-0.292-0.191-0.631-0.396-0.945-0.72c-0.317-0.315-0.524-0.781-0.511-1.189c0.002-0.408,0.138-0.73,0.26-0.977
								c0.127-0.247,0.256-0.426,0.346-0.548c0.09-0.122,0.145-0.185,0.145-0.185s-0.024,0.081-0.067,0.225
								c-0.042,0.144-0.105,0.351-0.161,0.604c-0.051,0.251-0.098,0.553-0.052,0.851c0.042,0.3,0.192,0.572,0.445,0.834
								c0.251,0.261,0.557,0.492,0.822,0.728c0.266,0.231,0.498,0.449,0.69,0.636C233.55,66.581,233.771,66.834,233.771,66.834z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: theme.dark }}
                        d="M237.046,66.139c0,0-0.169-0.089-0.428-0.154c-0.26-0.068-0.607-0.098-1.003-0.12
								c-0.398-0.027-0.743-0.046-1.001-0.002c-0.129,0.02-0.235,0.055-0.308,0.086c-0.073,0.031-0.116,0.053-0.116,0.053
								s0.014-0.044,0.058-0.119c0.043-0.074,0.121-0.178,0.249-0.275c0.251-0.201,0.696-0.337,1.154-0.303
								c0.462,0.024,0.877,0.221,1.104,0.435c0.116,0.105,0.189,0.208,0.232,0.281C237.029,66.096,237.046,66.139,237.046,66.139z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: theme.dark }}
                        d="M237.905,66.294c0,0,0.159-0.11,0.398-0.265c0.24-0.156,0.531-0.369,0.691-0.649
								c0.082-0.141,0.12-0.262,0.124-0.414c0.003-0.151-0.023-0.315-0.05-0.448c-0.056-0.269-0.127-0.449-0.127-0.449
								s0.042,0.021,0.112,0.072c0.069,0.05,0.166,0.131,0.266,0.252c0.098,0.124,0.203,0.279,0.267,0.502
								c0.067,0.22,0.038,0.535-0.109,0.768c-0.142,0.239-0.349,0.396-0.548,0.489c-0.198,0.092-0.383,0.125-0.534,0.142
								C238.092,66.322,237.905,66.294,237.905,66.294z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      style={{ fill: "#F0A09F" }}
                      d="M240.716,72.067c-1.417-0.883-2.911-1.646-4.471-2.239c-0.487-0.185-1.047-0.351-1.511-0.113
							c-0.192,0.099-0.36,0.264-0.575,0.289c-0.295,0.035-0.57-0.209-0.865-0.175c-0.323,0.037-0.513,0.377-0.622,0.684
							c-0.242,0.679-0.357,1.402-0.34,2.123c0.021,0.837,0.982,1.472,1.82,1.461c0.659-0.008,1.256-0.371,1.894-0.54
							c0.541-0.143,1.108-0.144,1.667-0.149c0.56-0.006,1.127-0.018,1.663-0.18C239.911,73.065,240.468,72.568,240.716,72.067z"
                    />
                  </g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: theme.dark }}
                          d="M240.295,72.063c0,0-0.48-0.086-1.257-0.171c-0.777-0.088-1.854-0.152-3.046-0.19
									c-0.596-0.017-1.167-0.046-1.679-0.046c-0.257,0.001-0.499,0.009-0.723,0.033c-0.229,0.025-0.417,0.061-0.563,0.181
									c-0.296,0.229-0.467,0.51-0.589,0.688c-0.117,0.185-0.183,0.288-0.183,0.288s0.048-0.112,0.145-0.31
									c0.103-0.193,0.236-0.491,0.54-0.775c0.077-0.069,0.171-0.133,0.284-0.175c0.111-0.04,0.22-0.063,0.338-0.085
									c0.233-0.042,0.482-0.066,0.744-0.08c0.525-0.026,1.095-0.015,1.695,0.002c1.2,0.039,2.286,0.174,3.058,0.332
									C239.833,71.908,240.295,72.063,240.295,72.063z"
                        />
                      </g>
                    </g>
                    <g>
                      <path
                        style={{ fill: theme.dark }}
                        d="M240.275,72.259c0.335,0.111,0.727,0.028,0.987-0.211c-0.655-0.342-1.443-0.42-2.153-0.213
								C239.574,71.884,239.844,72.116,240.275,72.259z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M236.953,75.104c0,0-0.115,0.102-0.311,0.249c-0.096,0.075-0.217,0.157-0.366,0.238
								c-0.074,0.041-0.148,0.087-0.234,0.124c-0.089,0.033-0.186,0.057-0.285,0.071c-0.403,0.05-0.745-0.063-0.973-0.15
								c-0.23-0.091-0.364-0.166-0.364-0.166s0.154,0.008,0.396,0.03c0.241,0.019,0.569,0.051,0.904,0.008
								c0.083-0.012,0.164-0.027,0.24-0.048c0.076-0.029,0.15-0.058,0.222-0.086c0.144-0.05,0.279-0.099,0.394-0.144
								C236.804,75.144,236.953,75.104,236.953,75.104z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M262.544,69.623c0,0-0.027-0.037-0.073-0.113c-0.045-0.076-0.109-0.191-0.168-0.351
								c-0.128-0.318-0.178-0.833-0.106-1.433c0.057-0.604,0.211-1.322,0.512-2.076c0.303-0.751,0.76-1.535,1.388-2.238
								c0.313-0.349,0.651-0.672,1.035-0.927c0.383-0.254,0.812-0.435,1.255-0.479c0.439-0.042,0.863,0.051,1.205,0.247
								c0.346,0.192,0.583,0.512,0.685,0.82c0.108,0.31,0.105,0.602,0.084,0.843c-0.024,0.243-0.071,0.445-0.113,0.608
								c-0.087,0.325-0.151,0.498-0.151,0.498s0.031-0.183,0.065-0.514c0.024-0.321,0.085-0.831-0.144-1.33
								c-0.116-0.244-0.319-0.461-0.603-0.586c-0.28-0.126-0.625-0.174-0.968-0.117c-0.696,0.112-1.352,0.655-1.931,1.31
								c-1.179,1.311-1.798,2.843-2.041,3.985c-0.137,0.572-0.158,1.044-0.086,1.365c0.032,0.161,0.075,0.283,0.106,0.364
								C262.527,69.581,262.544,69.623,262.544,69.623z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M264.176,67.706c0,0,0.159-0.249,0.401-0.629c0.235-0.383,0.533-0.916,0.501-1.503
								c-0.011-0.286-0.104-0.547-0.254-0.715c-0.152-0.168-0.381-0.268-0.578-0.29c-0.199-0.024-0.371,0.018-0.478,0.077
								c-0.108,0.059-0.158,0.117-0.161,0.114c0.004,0.002,0.019-0.072,0.104-0.181c0.083-0.108,0.26-0.241,0.52-0.289
								c0.255-0.048,0.597-0.015,0.905,0.216c0.306,0.235,0.485,0.636,0.5,1.044c0.018,0.408-0.111,0.785-0.289,1.066
								c-0.175,0.283-0.382,0.487-0.56,0.643c-0.18,0.155-0.335,0.263-0.443,0.336C264.238,67.666,264.176,67.706,264.176,67.706z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M231.572,53.966c0,0-0.02-0.021-0.056-0.063c-0.034-0.043-0.097-0.104-0.148-0.199
						c-0.12-0.183-0.242-0.503-0.254-0.91c-0.02-0.407,0.064-0.878,0.195-1.352c0.13-0.477,0.305-0.954,0.411-1.421
						c0.111-0.481,0.188-0.983,0.283-1.456c0.094-0.473,0.211-0.915,0.354-1.272c0.142-0.358,0.304-0.629,0.43-0.801
						c0.129-0.171,0.21-0.254,0.21-0.254s-0.017,0.115-0.048,0.319c-0.025,0.203-0.045,0.492-0.043,0.843
						c0.002,0.352,0.027,0.769,0.038,1.248c0.008,0.479,0.002,1.029-0.134,1.625c-0.143,0.612-0.406,1.098-0.621,1.512
						c-0.221,0.415-0.41,0.768-0.52,1.08c-0.116,0.311-0.147,0.585-0.134,0.783c-0.002,0.102,0.019,0.177,0.025,0.232
						C231.568,53.938,231.572,53.966,231.572,53.966z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M251.921,40.997c0,0-0.088,0.039-0.256,0.113c-0.17,0.067-0.411,0.189-0.716,0.349
						c-0.61,0.325-1.428,0.892-2.384,1.672c-0.965,0.768-2.061,1.782-3.537,2.655c-0.739,0.437-1.591,0.783-2.491,1.03
						c-0.902,0.249-1.858,0.382-2.831,0.446c-0.974,0.055-1.92,0.04-2.823-0.016c-0.898-0.063-1.771-0.104-2.64-0.312
						c-0.429-0.105-0.851-0.255-1.231-0.472c-0.384-0.212-0.709-0.496-0.977-0.789c-0.533-0.598-0.849-1.241-1.112-1.814
						c-0.501-1.153-0.866-2.083-1.128-2.732c-0.254-0.649-0.395-1.008-0.395-1.008s0.197,0.332,0.552,0.931
						c0.347,0.603,0.848,1.475,1.468,2.551c0.313,0.523,0.674,1.083,1.162,1.537c0.484,0.46,1.105,0.746,1.858,0.873
						c0.742,0.131,1.603,0.135,2.488,0.165c0.883,0.024,1.79,0.021,2.71-0.031c1.838-0.107,3.542-0.479,4.942-1.19
						c1.409-0.712,2.598-1.611,3.658-2.277c1.056-0.679,1.976-1.144,2.639-1.377c0.333-0.115,0.592-0.196,0.773-0.235
						C251.827,41.02,251.921,40.997,251.921,40.997z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M257.337,40.851c0,0-0.107,0.022-0.297,0.063c-0.188,0.034-0.459,0.095-0.782,0.168
						c-0.65,0.146-1.499,0.382-2.415,0.665c-0.915,0.286-1.749,0.573-2.367,0.821c-0.308,0.122-0.565,0.226-0.74,0.305
						c-0.179,0.074-0.28,0.116-0.28,0.116s0.057-0.092,0.17-0.252c0.112-0.163,0.291-0.383,0.539-0.634
						c0.491-0.501,1.304-1.1,2.346-1.425c1.042-0.322,2.051-0.29,2.74-0.155c0.347,0.066,0.619,0.146,0.804,0.217
						C257.238,40.808,257.337,40.851,257.337,40.851z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M261.163,57.222c0,0-0.78-0.943-1.967-2.516c-0.58-0.789-1.344-1.713-1.879-3.008
						c-0.258-0.644-0.426-1.411-0.386-2.194c0.032-0.781,0.233-1.55,0.496-2.283c0.266-0.73,0.572-1.414,0.907-2.059
						c0.086-0.16,0.171-0.318,0.255-0.475c0.087-0.157,0.178-0.307,0.237-0.425c0.123-0.248,0.13-0.363,0.066-0.529
						c-0.068-0.161-0.254-0.34-0.478-0.475c-0.224-0.14-0.47-0.245-0.732-0.334c-0.536-0.188-1.043-0.345-1.512-0.475
						c-0.936-0.262-1.714-0.419-2.254-0.52c-0.543-0.092-0.844-0.143-0.844-0.143s0.305-0.025,0.859-0.031
						c0.554-0.004,1.36,0.015,2.351,0.146c0.495,0.064,1.036,0.157,1.611,0.288c0.295,0.065,0.616,0.16,0.933,0.316
						c0.313,0.156,0.659,0.375,0.902,0.79c0.118,0.206,0.187,0.463,0.182,0.714c-0.005,0.259-0.08,0.488-0.159,0.674
						c-0.082,0.192-0.164,0.34-0.236,0.491c-0.073,0.152-0.146,0.307-0.221,0.462c-0.29,0.623-0.567,1.285-0.814,1.964
						c-0.243,0.676-0.426,1.337-0.479,1.97c-0.059,0.631,0.031,1.233,0.207,1.812c0.366,1.154,0.991,2.154,1.458,3.019
						C260.618,56.126,261.163,57.222,261.163,57.222z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M260.949,72.316c0,0,0.374-0.021,1.049-0.06c0.337-0.016,0.75-0.035,1.23-0.058
						c0.24-0.01,0.496-0.02,0.768-0.031c0.273-0.013,0.563-0.012,0.827-0.053c0.272-0.042,0.487-0.132,0.669-0.318
						c0.186-0.186,0.342-0.453,0.497-0.76c0.314-0.604,0.58-1.295,0.885-2.039c0.309-0.74,0.658-1.548,1.219-2.309
						c0.569-0.773,1.286-1.329,1.558-2.001c0.295-0.673,0.23-1.486-0.058-2.168c-0.291-0.691-0.793-1.242-1.408-1.562
						c-0.615-0.332-1.313-0.457-1.964-0.437c-0.654,0.015-1.267,0.171-1.788,0.396c-0.133,0.052-0.253,0.12-0.379,0.181
						c-0.055,0.029-0.16,0.088-0.25,0.12c-0.092,0.034-0.187,0.053-0.28,0.05c-0.396-0.003-0.597-0.451-0.685-0.607
						c-0.232-0.42-0.433-0.781-0.597-1.077c-0.321-0.595-0.499-0.924-0.499-0.924s0.23,0.295,0.645,0.829
						c0.205,0.269,0.456,0.598,0.747,0.979c0.036,0.047,0.072,0.096,0.11,0.145c0.039,0.052,0.057,0.069,0.089,0.101
						c0.057,0.056,0.136,0.098,0.219,0.082c0.04-0.007,0.079-0.024,0.114-0.044c0.038-0.023,0.055-0.039,0.122-0.084
						c0.124-0.08,0.253-0.173,0.392-0.249c0.551-0.317,1.222-0.57,1.983-0.659c0.756-0.093,1.616-0.021,2.436,0.351
						c0.827,0.36,1.564,1.079,1.973,1.976c0.413,0.887,0.551,2.014,0.107,3.046c-0.221,0.52-0.561,0.921-0.876,1.265
						c-0.318,0.344-0.621,0.635-0.859,0.944c-0.494,0.621-0.86,1.341-1.209,2.048c-0.348,0.709-0.684,1.411-1.083,2.033
						c-0.194,0.306-0.411,0.626-0.722,0.877c-0.154,0.125-0.336,0.227-0.522,0.286c-0.185,0.06-0.365,0.083-0.532,0.089
						c-0.337,0.011-0.621-0.028-0.891-0.049c-0.271-0.025-0.526-0.048-0.765-0.07c-0.478-0.047-0.889-0.088-1.225-0.121
						C261.321,72.357,260.949,72.316,260.949,72.316z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M266.332,89.604c0,0-0.254-0.991-0.664-2.584c-0.202-0.795-0.437-1.744-0.718-2.777
						c-0.141-0.521-0.301-1.029-0.493-1.593c-0.185-0.562-0.382-1.188-0.455-1.873c-0.074-0.676-0.043-1.327,0.026-1.935
						c0.072-0.608,0.175-1.175,0.289-1.717c0.23-1.082,0.52-2.034,0.805-2.815c0.283-0.783,0.559-1.396,0.758-1.814
						c0.199-0.419,0.323-0.643,0.323-0.643s-0.056,0.249-0.158,0.7c-0.099,0.45-0.24,1.1-0.383,1.903
						c-0.145,0.803-0.293,1.76-0.413,2.822c-0.109,1.063-0.257,2.238-0.134,3.377c0.059,0.564,0.211,1.118,0.367,1.686
						c0.161,0.564,0.299,1.158,0.39,1.697c0.186,1.088,0.283,2.068,0.348,2.893C266.349,88.578,266.332,89.604,266.332,89.604z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M273.127,65.903c0,0-1.468,3.766-3.88,8.074c-2.413,4.308-4.858,7.527-4.858,7.527
						s1.468-3.766,3.881-8.074C270.683,69.122,273.127,65.903,273.127,65.903z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M234.056,33.755c0,0-0.532-0.181-1.345-0.546c-0.062-0.03-0.073-0.027-0.102-0.036l-0.07-0.015
						c-0.039-0.015-0.093,0.003-0.108,0.063c-0.009,0.025-0.014,0.049-0.02,0.07l-0.006,0.031c-0.004,0.013,0.003,0.009-0.017,0.048
						c-0.059,0.12-0.12,0.244-0.182,0.37c-0.244,0.506-0.49,1.061-0.704,1.649c-0.439,1.169-0.618,2.285-0.596,3.136
						c0.008,0.422,0.056,0.772,0.101,1.015c0.021,0.122,0.042,0.217,0.058,0.281c0.014,0.065,0.021,0.1,0.021,0.1
						s-0.022-0.027-0.065-0.079c-0.043-0.051-0.104-0.129-0.176-0.235c-0.144-0.211-0.335-0.537-0.489-0.98
						c-0.152-0.442-0.269-1.001-0.267-1.629c-0.003-0.628,0.115-1.321,0.362-1.994c0.246-0.674,0.59-1.262,0.946-1.752
						c0.089-0.122,0.179-0.24,0.27-0.351l0.095-0.124l0.112-0.126c0.075-0.079,0.139-0.175,0.22-0.216
						c0.077-0.048,0.168-0.082,0.264-0.08c0.095,0.002,0.174,0.033,0.253,0.075c0.08,0.042,0.157,0.102,0.228,0.16
						c0.067,0.054,0.15,0.119,0.18,0.149C233.677,33.339,234.056,33.755,234.056,33.755z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M237.567,31.124c0,0-0.086,0.059-0.26,0.144c-0.174,0.083-0.442,0.179-0.794,0.227
						c-0.351,0.048-0.782,0.041-1.237-0.059c-0.229-0.047-0.462-0.118-0.695-0.207c-0.117-0.044-0.233-0.095-0.349-0.15
						l-0.087-0.042l-0.038-0.019h-0.002l-0.001,0.001c0,0,0.001-0.006-0.009-0.017c-0.013-0.012-0.029-0.016-0.05,0
						c-0.005,0.004-0.011,0.009-0.017,0.016l-0.021,0.14c-0.013,0.093-0.026,0.184-0.039,0.272c-0.047,0.358-0.09,0.68-0.126,0.95
						c-0.068,0.553-0.109,0.895-0.109,0.895s-0.185-0.298-0.372-0.843c-0.093-0.272-0.184-0.606-0.246-0.99
						c-0.015-0.096-0.028-0.195-0.039-0.296c-0.005-0.051-0.01-0.103-0.015-0.155c-0.004-0.093-0.004-0.188-0.003-0.284
						c-0.007-0.198,0.024-0.383,0.072-0.551c0.061-0.15,0.158-0.278,0.319-0.37c0.224-0.111,0.453-0.146,0.777,0.038l0.158,0.072
						l0.159,0.092l0.193,0.108c0.097,0.054,0.193,0.107,0.288,0.159c0.189,0.103,0.372,0.201,0.549,0.286
						c0.353,0.175,0.683,0.307,0.976,0.396c0.292,0.089,0.547,0.138,0.729,0.159C237.463,31.114,237.567,31.124,237.567,31.124z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M248.523,30.806c0,0-0.049-0.015-0.142-0.043c-0.093-0.027-0.226-0.076-0.404-0.117
						c-0.35-0.095-0.861-0.202-1.494-0.253c-0.632-0.057-1.384-0.056-2.22-0.003c-0.839,0.053-1.755,0.158-2.747,0.25
						c-1.173,0.107-2.318,0.143-3.317,0.041c-1.001-0.098-1.839-0.339-2.414-0.585l0.238-0.282c0.153,0.448,0.365,0.766,0.518,0.97
						c0.156,0.204,0.253,0.301,0.253,0.301s-0.123-0.058-0.337-0.215c-0.211-0.159-0.518-0.425-0.799-0.882l-0.212-0.345l0.45,0.063
						c0.592,0.082,1.393,0.134,2.332,0.093c0.94-0.039,2.019-0.167,3.192-0.274c0.987-0.091,1.96-0.131,2.845-0.08
						c0.886,0.052,1.685,0.197,2.33,0.399c0.647,0.198,1.136,0.447,1.453,0.642c0.162,0.091,0.277,0.18,0.357,0.235
						C248.481,30.776,248.523,30.806,248.523,30.806z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M269.527,36.001c0,0-0.026-0.082-0.077-0.24c-0.05-0.157-0.124-0.389-0.229-0.681
						c-0.216-0.567-0.542-1.456-1.39-2.058c-0.42-0.306-0.956-0.556-1.55-0.814c-0.591-0.256-1.231-0.505-1.915-0.731
						c-1.368-0.453-2.913-0.812-4.557-0.997c-1.644-0.197-3.22-0.152-4.696-0.097c-1.472,0.054-2.827,0.074-3.959-0.045
						c-1.134-0.114-2.031-0.355-2.621-0.581c-0.298-0.105-0.518-0.214-0.667-0.286c-0.148-0.071-0.227-0.109-0.227-0.109
						s0.083,0.022,0.243,0.064c0.159,0.043,0.392,0.11,0.698,0.163c0.608,0.122,1.497,0.217,2.595,0.19
						c1.099-0.022,2.401-0.178,3.871-0.338c1.463-0.16,3.146-0.282,4.888-0.073c1.741,0.196,3.362,0.648,4.759,1.224
						c0.699,0.287,1.344,0.604,1.928,0.931c0.579,0.324,1.128,0.657,1.567,1.068c0.445,0.407,0.727,0.895,0.894,1.32
						c0.167,0.431,0.248,0.813,0.307,1.123c0.056,0.311,0.086,0.552,0.106,0.716C269.517,35.915,269.527,36.001,269.527,36.001z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M270.231,34.565c0,0,0.071,0.05,0.185,0.158c0.115,0.108,0.269,0.281,0.418,0.528
						c0.299,0.482,0.547,1.331,0.289,2.234l0,0.003l-0.389,1.47l-0.655-1.386c-0.676-1.429-0.718-2.827-0.718-2.827
						s1.053,0.919,1.73,2.348l-1.043,0.084l0.001-0.002c0.182-0.629,0.259-1.263,0.25-1.763c-0.003-0.249-0.019-0.462-0.037-0.611
						C270.241,34.651,270.231,34.565,270.231,34.565z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M275.738,45.521c0,0,0.016-0.044,0.047-0.129c0.031-0.083,0.073-0.208,0.111-0.369
						c0.081-0.321,0.14-0.795,0.091-1.365c-0.047-0.573-0.21-1.225-0.48-1.94c-0.262-0.714-0.63-1.506-0.933-2.394
						c-0.298-0.869-0.551-1.646-0.94-2.231c-0.376-0.585-0.913-0.987-1.415-1.279c-0.502-0.293-0.953-0.489-1.261-0.63
						c-0.31-0.141-0.482-0.22-0.482-0.22s0.188,0.012,0.528,0.051c0.339,0.042,0.832,0.102,1.438,0.285
						c0.301,0.093,0.631,0.222,0.969,0.415c0.335,0.193,0.679,0.463,0.971,0.803c0.594,0.681,0.951,1.576,1.249,2.438
						c0.29,0.852,0.598,1.678,0.782,2.494c0.19,0.809,0.23,1.595,0.133,2.227c-0.094,0.636-0.305,1.117-0.489,1.412
						c-0.09,0.149-0.172,0.256-0.23,0.326C275.769,45.484,275.738,45.521,275.738,45.521z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M276.687,42.477c0,0,0.154,0.174,0.27,0.505c0.117,0.327,0.171,0.828-0.022,1.316
						c-0.194,0.488-0.577,0.816-0.885,0.974c-0.311,0.163-0.543,0.184-0.543,0.184s0.042-0.23,0.105-0.533
						c0.066-0.306,0.138-0.676,0.282-1.037c0.142-0.361,0.344-0.68,0.505-0.948C276.56,42.673,276.687,42.477,276.687,42.477z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M276.227,48.764c0,0,0.15-0.077,0.421-0.215c0.265-0.14,0.661-0.338,1.05-0.65
						c0.191-0.157,0.379-0.342,0.511-0.565c0.136-0.221,0.214-0.466,0.27-0.787c0.055-0.341,0.112-0.698,0.171-1.068
						c0.055-0.369,0.107-0.743,0.126-1.113l0.006-0.115c0.001-0.034,0.002-0.091,0.002-0.073c0,0,0.002,0,0.003-0.001
						c0.002-0.003,0.006-0.009,0.011-0.021c0.004-0.012,0.008-0.03,0.011-0.057c0.003-0.035,0.001-0.044-0.003-0.048
						c-0.01-0.01-0.038-0.005-0.092,0.02c0.004-0.019-0.15,0.123-0.253,0.197c-0.109,0.087-0.225,0.16-0.333,0.233
						c-0.447,0.279-0.877,0.446-1.238,0.542c-0.362,0.096-0.655,0.123-0.854,0.134c-0.199,0.005-0.305,0.001-0.305,0.001
						s0.099-0.04,0.28-0.113c0.178-0.079,0.433-0.197,0.727-0.38c0.293-0.182,0.623-0.43,0.947-0.749
						c0.078-0.083,0.162-0.163,0.238-0.255c0.088-0.1,0.121-0.142,0.306-0.367c0.078-0.091,0.15-0.222,0.23-0.336l0.119-0.18
						l0.124-0.221c0.176-0.315,0.355-0.636,0.538-0.962c0.035,0.064,0.065,0.121,0.098,0.189l0.024,0.052l0.032,0.084l0.063,0.172
						c0.08,0.221,0.161,0.447,0.244,0.677c0.035,0.125,0.074,0.249,0.105,0.386c0.022,0.147,0.059,0.285,0.069,0.447
						c0.011,0.16,0.03,0.318,0.028,0.488l-0.002,0.179l-0.007,0.139c-0.024,0.449-0.103,0.866-0.192,1.256
						c-0.096,0.394-0.188,0.736-0.31,1.107c-0.119,0.366-0.32,0.751-0.585,1.021c-0.259,0.275-0.555,0.445-0.82,0.566
						c-0.538,0.234-0.983,0.293-1.287,0.337C276.396,48.755,276.227,48.764,276.227,48.764z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M276.483,48.968c0,0,0.11,0.048,0.311,0.156c0.201,0.108,0.49,0.286,0.825,0.568
						c0.335,0.281,0.707,0.675,1.045,1.205l0.061,0.102l0.081,0.145c0.055,0.099,0.096,0.203,0.147,0.31
						c0.053,0.108,0.095,0.216,0.133,0.327c0.041,0.112,0.084,0.228,0.113,0.339c0.067,0.227,0.127,0.463,0.189,0.723l0.046,0.202
						l0.023,0.103l0.012,0.052c0.004,0.017-0.001,0.015-0.001,0.024c-0.008,0.053-0.019,0.087-0.014,0.201
						c-0.34-0.238-0.767-0.557-1.14-0.797c-0.373-0.237-0.799-0.415-0.955-0.461c-0.22-0.075-0.45-0.138-0.687-0.186
						c-0.074-0.008-0.09,0.001-0.12,0.007c-0.009,0.035,0.023,0.081,0.042,0.102l0.013,0.016l0.058,0.086
						c0.038,0.058,0.086,0.119,0.13,0.18c0.095,0.123,0.2,0.249,0.311,0.383c0.219,0.272,0.477,0.566,0.673,0.965
						c0.199,0.391,0.307,0.831,0.332,1.262c0.05,0.983-0.239,1.837-0.543,2.59c-0.319,0.757-0.655,1.417-0.968,2.072
						c-0.631,1.298-1.22,2.458-1.725,3.427c-1.01,1.938-1.683,3.116-1.683,3.116s0.417-1.291,1.171-3.342
						c0.377-1.026,0.838-2.242,1.373-3.582c0.261-0.668,0.571-1.378,0.833-2.079c0.257-0.702,0.465-1.443,0.424-2.133
						c-0.018-0.304-0.087-0.585-0.209-0.838c-0.113-0.252-0.314-0.508-0.523-0.782c-0.106-0.137-0.216-0.28-0.323-0.432
						c-0.053-0.079-0.107-0.154-0.158-0.24l-0.076-0.128l-0.101-0.206c-0.126-0.28-0.208-0.583-0.191-0.834
						c-0.007-0.132,0.015-0.252,0.021-0.378c0.013-0.127,0.018-0.273,0.042-0.365c0.253,0.033,0.487,0.06,0.725,0.097
						c0.23,0.031,0.488,0.09,0.693,0.142c0.273,0.077,0.533,0.172,0.779,0.282c0.315,0.14,0.318,0.161,0.388,0.209
						c0.065,0.048,0.14,0.107,0.221,0.178l0.106,0.093c0.013,0.013,0.014,0.008,0.013,0l0.001-0.018l0.003-0.039
						c0.002-0.053,0-0.113-0.01-0.179c-0.009-0.066-0.023-0.138-0.045-0.213c-0.012-0.038-0.021-0.078-0.036-0.117l-0.022-0.062
						l-0.042-0.1c-0.24-0.522-0.528-0.944-0.8-1.26c-0.272-0.317-0.519-0.54-0.693-0.681
						C276.578,49.042,276.483,48.968,276.483,48.968z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M241.406,46.244c0,0-0.17,0.054-0.476,0.151c-0.155,0.042-0.348,0.084-0.574,0.134
						c-0.227,0.033-0.487,0.086-0.779,0.103c-1.154,0.11-2.852-0.057-4.471-0.921c-1.609-0.855-2.753-2.179-3.168-3.322
						c-0.216-0.565-0.259-1.058-0.242-1.384c0.012-0.165,0.025-0.289,0.044-0.373c0.017-0.083,0.025-0.126,0.025-0.126
						s0.008,0.044,0.023,0.127c0.011,0.083,0.042,0.202,0.08,0.352c0.087,0.299,0.259,0.71,0.566,1.146
						c0.599,0.881,1.768,1.83,3.197,2.591c1.429,0.764,2.875,1.18,3.965,1.35c0.272,0.052,0.521,0.07,0.743,0.103
						c0.222,0.018,0.414,0.033,0.571,0.045C241.23,46.236,241.406,46.244,241.406,46.244z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M242.745,46.572c0,0-0.109,0.051-0.317,0.121c-0.208,0.07-0.519,0.15-0.916,0.192
						c-0.787,0.091-1.949-0.034-3.036-0.633c-1.085-0.598-1.808-1.468-2.219-2.126c-0.208-0.332-0.349-0.615-0.437-0.814
						c-0.088-0.2-0.128-0.314-0.128-0.314s0.096,0.074,0.267,0.206c0.169,0.129,0.413,0.316,0.711,0.544
						c0.597,0.447,1.416,1.013,2.345,1.523c0.926,0.509,1.83,0.87,2.538,1.057c0.352,0.095,0.651,0.157,0.861,0.192
						C242.626,46.553,242.745,46.572,242.745,46.572z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M245.106,45.214c0,0-0.099,0.051-0.287,0.133c-0.188,0.08-0.469,0.182-0.831,0.258
						c-0.717,0.159-1.796,0.181-2.874-0.238c-0.536-0.206-1.008-0.498-1.392-0.811c-0.382-0.317-0.674-0.658-0.891-0.957
						c-0.238-0.326-0.335-0.667-0.288-0.903c0.051-0.232,0.185-0.284,0.175-0.287c0.007-0.003-0.06,0.111,0,0.28
						c0.055,0.159,0.238,0.351,0.502,0.507c0.293,0.178,0.636,0.377,1.023,0.571c0.389,0.191,0.818,0.379,1.276,0.556
						c0.913,0.354,1.787,0.599,2.461,0.722c0.335,0.065,0.618,0.104,0.816,0.129C244.995,45.199,245.106,45.214,245.106,45.214z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M247.484,43.292c0,0-0.08,0.052-0.232,0.138c-0.153,0.082-0.381,0.19-0.681,0.283
						c-0.591,0.187-1.498,0.294-2.435,0.013c-0.466-0.138-0.882-0.353-1.224-0.597c-0.337-0.241-0.625-0.515-0.811-0.794
						c-0.19-0.276-0.273-0.545-0.288-0.726c-0.017-0.182,0.006-0.275,0.006-0.275s0.049,0.087,0.156,0.208
						c0.105,0.122,0.279,0.263,0.513,0.389c0.466,0.246,1.2,0.491,1.967,0.722c0.774,0.231,1.506,0.405,2.075,0.502
						c0.283,0.048,0.521,0.082,0.689,0.103C247.389,43.279,247.484,43.292,247.484,43.292z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M250.03,42.231c0,0-0.055,0.049-0.163,0.128c-0.108,0.081-0.273,0.182-0.494,0.277
						c-0.436,0.191-1.127,0.322-1.847,0.134c-0.719-0.19-1.256-0.645-1.541-1.026c-0.145-0.192-0.24-0.36-0.293-0.484
						c-0.055-0.123-0.079-0.192-0.079-0.192s0.072,0.023,0.197,0.063c0.121,0.043,0.297,0.097,0.501,0.158
						c0.413,0.126,0.941,0.25,1.501,0.399c0.56,0.146,1.081,0.298,1.502,0.392c0.208,0.047,0.389,0.088,0.515,0.109
						C249.956,42.216,250.03,42.231,250.03,42.231z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M252.162,40.989c0,0-0.161,0.191-0.493,0.379c-0.328,0.188-0.848,0.352-1.415,0.274
						c-0.568-0.08-1.023-0.38-1.287-0.65c-0.269-0.271-0.371-0.499-0.371-0.499s0.251-0.012,0.594-0.014
						c0.346,0,0.774-0.008,1.218,0.055c0.443,0.061,0.854,0.185,1.186,0.28C251.924,40.909,252.162,40.989,252.162,40.989z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M247.792,38.39c0,0-0.212,0.112-0.604,0.299c-0.392,0.187-0.962,0.449-1.691,0.725
						c-0.728,0.277-1.616,0.567-2.638,0.78c-1.021,0.21-2.182,0.338-3.407,0.253c-1.223-0.082-2.374-0.379-3.351-0.807
						c-0.979-0.424-1.773-0.987-2.354-1.533c-0.592-0.53-0.971-1.13-1.038-1.588c-0.041-0.227-0.015-0.406,0.017-0.517
						c0.032-0.112,0.059-0.166,0.059-0.166c0.014-0.005-0.075,0.254,0.092,0.633c0.16,0.38,0.58,0.806,1.216,1.198
						c0.626,0.403,1.418,0.799,2.343,1.09c0.923,0.295,1.975,0.498,3.093,0.573c2.237,0.15,4.316-0.183,5.816-0.455
						c0.752-0.138,1.363-0.266,1.787-0.352C247.558,38.438,247.792,38.39,247.792,38.39z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M258.425,40.675c0,0-0.064-0.061-0.185-0.177c-0.12-0.116-0.292-0.29-0.542-0.483
						c-0.483-0.399-1.239-0.907-2.258-1.33c-1.019-0.424-2.299-0.746-3.746-0.92c-1.45-0.177-3.056-0.173-4.779-0.143
						c-1.719,0.027-3.389,0.036-4.91-0.126c-1.522-0.16-2.88-0.49-3.958-0.938c-1.081-0.448-1.85-1.025-2.299-1.486
						c-0.229-0.228-0.38-0.426-0.481-0.561c-0.095-0.138-0.145-0.211-0.145-0.211s0.063,0.062,0.181,0.179
						c0.123,0.113,0.303,0.279,0.559,0.46c0.504,0.369,1.306,0.797,2.37,1.09c1.063,0.294,2.376,0.474,3.84,0.525
						c1.467,0.053,3.093-0.023,4.828-0.051c1.73-0.03,3.428,0.035,4.939,0.329c1.511,0.29,2.834,0.773,3.847,1.351
						c1.017,0.577,1.715,1.223,2.134,1.707c0.218,0.236,0.359,0.441,0.457,0.578C258.374,40.604,258.425,40.675,258.425,40.675z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M265.561,47.939c0,0,0.038-0.126,0.11-0.366c0.082-0.236,0.16-0.597,0.244-1.06
						c0.138-0.925,0.189-2.316-0.299-3.9c-0.245-0.787-0.619-1.614-1.161-2.402c-0.536-0.792-1.262-1.518-2.144-2.102
						c-0.893-0.578-1.938-1.035-3.081-1.342c-1.133-0.333-2.346-0.555-3.613-0.676c-2.529-0.263-5.003-0.223-7.265-0.364
						c-1.13-0.057-2.21-0.174-3.213-0.321c-1.002-0.171-1.931-0.372-2.753-0.646c-0.829-0.252-1.541-0.585-2.159-0.877
						c-0.609-0.316-1.104-0.641-1.487-0.931c-0.383-0.291-0.656-0.545-0.834-0.722c-0.178-0.177-0.265-0.277-0.265-0.277
						s0.099,0.088,0.296,0.241c0.197,0.153,0.495,0.371,0.9,0.613c0.405,0.241,0.918,0.505,1.537,0.754
						c0.629,0.224,1.342,0.482,2.16,0.66c0.812,0.197,1.721,0.323,2.699,0.425c0.983,0.078,2.035,0.132,3.152,0.136
						c2.229,0.037,4.712-0.078,7.349,0.194c1.316,0.126,2.604,0.378,3.809,0.765c1.21,0.36,2.339,0.898,3.303,1.588
						c0.962,0.703,1.726,1.565,2.252,2.467c0.535,0.898,0.868,1.818,1.059,2.673c0.377,1.723,0.172,3.157-0.072,4.079
						c-0.137,0.461-0.258,0.813-0.37,1.04C265.615,47.818,265.561,47.939,265.561,47.939z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M267.708,47.24c0,0,0.094-0.078,0.271-0.225c0.174-0.15,0.412-0.394,0.665-0.743
						c0.491-0.697,0.985-1.911,0.87-3.422c-0.017-0.375-0.096-0.761-0.193-1.154c-0.11-0.392-0.265-0.777-0.463-1.169
						c-0.409-0.774-0.983-1.526-1.713-2.198c-0.726-0.675-1.588-1.283-2.544-1.814c-0.957-0.532-1.996-1.009-3.09-1.359
						c-2.188-0.704-4.454-0.756-6.521-0.839c-1.037-0.039-2.03-0.074-2.964-0.159c-0.933-0.085-1.8-0.209-2.581-0.387
						c-1.564-0.346-2.761-0.885-3.51-1.36c-0.19-0.116-0.354-0.224-0.491-0.327c-0.139-0.098-0.255-0.184-0.343-0.26
						c-0.179-0.147-0.273-0.225-0.273-0.225s0.104,0.064,0.301,0.186c0.096,0.063,0.221,0.133,0.37,0.211
						c0.146,0.084,0.32,0.17,0.519,0.26c0.786,0.375,1.992,0.765,3.525,0.963c0.766,0.104,1.613,0.155,2.525,0.174
						c0.911,0.018,1.893-0.007,2.933-0.021c1.04-0.013,2.143-0.022,3.297,0.064c1.151,0.083,2.362,0.271,3.554,0.653
						c1.189,0.381,2.282,0.904,3.284,1.497c1.004,0.594,1.913,1.289,2.67,2.068c0.762,0.774,1.352,1.653,1.743,2.544
						c0.189,0.447,0.333,0.905,0.419,1.349c0.074,0.447,0.123,0.878,0.105,1.289c-0.023,1.658-0.7,2.887-1.293,3.545
						c-0.306,0.329-0.579,0.544-0.773,0.672C267.811,47.175,267.708,47.24,267.708,47.24z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M269.805,48.606c0,0,0.07-0.129,0.197-0.361c0.131-0.227,0.321-0.555,0.553-0.958
						c0.459-0.801,1.094-1.875,1.424-3.107c0.167-0.616,0.242-1.212,0.207-1.753c-0.031-0.54-0.171-1.045-0.314-1.473
						c-0.145-0.428-0.288-0.78-0.382-1.025c-0.093-0.247-0.145-0.385-0.145-0.385s0.108,0.1,0.292,0.29
						c0.184,0.191,0.447,0.47,0.731,0.862c0.28,0.391,0.59,0.911,0.75,1.584c0.162,0.668,0.144,1.444-0.06,2.191
						c-0.2,0.749-0.56,1.397-0.939,1.915c-0.381,0.522-0.781,0.93-1.129,1.251c-0.349,0.322-0.648,0.559-0.857,0.721
						C269.922,48.518,269.805,48.606,269.805,48.606z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M269.928,50.806c0,0,0.07-0.097,0.194-0.269c0.124-0.168,0.304-0.411,0.525-0.708
						c0.43-0.585,1.017-1.382,1.662-2.258c0.653-0.871,1.246-1.664,1.681-2.245c0.221-0.297,0.4-0.539,0.526-0.707
						c0.129-0.169,0.201-0.263,0.201-0.263s-0.01,0.118-0.039,0.33c-0.027,0.212-0.084,0.515-0.183,0.883
						c-0.195,0.735-0.592,1.731-1.287,2.669c-0.697,0.937-1.536,1.604-2.183,2.003c-0.324,0.201-0.598,0.343-0.793,0.431
						C270.038,50.762,269.928,50.806,269.928,50.806z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M261.937,48.348c0,0-0.026,0.133-0.073,0.372c-0.044,0.239-0.112,0.58-0.176,0.998
						c-0.131,0.836-0.247,1.966-0.273,3.202c-0.035,1.229,0.042,2.379,0.075,3.24c0.039,0.858,0.027,1.406,0.027,1.406
						s-0.26-0.479-0.575-1.292c-0.307-0.808-0.683-1.997-0.646-3.38c0.03-1.375,0.453-2.571,0.862-3.351
						c0.204-0.394,0.405-0.691,0.546-0.892C261.851,48.452,261.937,48.348,261.937,48.348z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M263.832,51.599c0,0-0.1,0.331-0.253,0.843c-0.146,0.504-0.297,1.167-0.458,1.881
						c-0.157,0.715-0.3,1.379-0.38,1.898c-0.077,0.529-0.126,0.871-0.126,0.871s-0.052-0.07-0.136-0.205
						c-0.087-0.134-0.193-0.338-0.294-0.604c-0.204-0.528-0.346-1.34-0.156-2.202c0.192-0.861,0.665-1.537,1.073-1.929
						c0.204-0.199,0.386-0.339,0.522-0.423C263.755,51.641,263.832,51.599,263.832,51.599z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M269.908,48.721c0,0-0.434,0.541-1.163,1.361c-0.725,0.825-1.737,1.923-2.71,3.193
						c-0.971,1.266-1.681,2.531-2.051,3.532c-0.188,0.498-0.31,0.921-0.377,1.22c-0.065,0.302-0.102,0.47-0.102,0.47
						s-0.037-0.168-0.072-0.48c-0.037-0.312-0.054-0.771,0.007-1.339c0.109-1.133,0.639-2.694,1.706-4.082
						c1.064-1.384,2.319-2.316,3.232-2.936C269.298,49.044,269.908,48.721,269.908,48.721z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M270.164,54.094c0,0-0.09,0.072-0.251,0.202c-0.163,0.124-0.394,0.309-0.673,0.531
						c-0.563,0.444-1.297,1.056-2.101,1.723c-0.801,0.669-1.538,1.28-2.076,1.752c-0.269,0.234-0.493,0.428-0.645,0.565
						c-0.157,0.135-0.245,0.211-0.245,0.211s0.031-0.111,0.099-0.308c0.066-0.199,0.179-0.478,0.347-0.811
						c0.332-0.665,0.924-1.537,1.803-2.27c0.881-0.732,1.847-1.154,2.56-1.36c0.358-0.104,0.653-0.165,0.86-0.194
						C270.049,54.105,270.164,54.094,270.164,54.094z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M273.032,55.375c0,0-0.002,0.031-0.006,0.09c-0.003,0.06-0.014,0.147-0.036,0.26
						c-0.045,0.226-0.163,0.551-0.4,0.904c-0.236,0.351-0.59,0.717-1.021,1.045c-0.431,0.326-0.923,0.63-1.472,0.895
						c-1.099,0.524-2.169,0.77-2.945,0.905c-0.778,0.134-1.276,0.159-1.276,0.159s0.407-0.29,1.084-0.685
						c0.677-0.396,1.617-0.895,2.652-1.389c0.516-0.243,1.008-0.475,1.458-0.687c0.439-0.211,0.817-0.417,1.114-0.628
						c0.295-0.211,0.512-0.424,0.646-0.593c0.067-0.084,0.119-0.153,0.149-0.202C273.014,55.4,273.032,55.375,273.032,55.375z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M273.794,58.518c0,0-0.022,0.08-0.072,0.223c-0.049,0.144-0.138,0.343-0.276,0.576
						c-0.272,0.463-0.789,1.049-1.538,1.419c-0.75,0.367-1.529,0.419-2.063,0.351c-0.269-0.032-0.481-0.084-0.625-0.133
						c-0.144-0.047-0.221-0.079-0.221-0.079s0.301-0.135,0.765-0.342c0.455-0.206,1.028-0.499,1.65-0.803
						c0.621-0.307,1.202-0.582,1.643-0.816C273.504,58.674,273.794,58.518,273.794,58.518z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M261.514,46.661c0,0,0.024-0.119,0.068-0.331c0.043-0.21,0.09-0.515,0.121-0.879
						c0.065-0.732,0.02-1.71-0.251-2.706c-0.271-0.996-0.728-1.862-1.154-2.46c-0.211-0.299-0.407-0.537-0.55-0.697
						c-0.145-0.161-0.227-0.251-0.227-0.251s0.118,0.029,0.326,0.107c0.208,0.077,0.503,0.213,0.839,0.438
						c0.668,0.437,1.504,1.318,1.846,2.569c0.339,1.252,0.065,2.435-0.29,3.15c-0.176,0.365-0.361,0.631-0.501,0.803
						C261.601,46.576,261.514,46.661,261.514,46.661z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M252.632,77.183c0,0-0.104,0.098-0.302,0.285c-0.203,0.182-0.483,0.475-0.891,0.797
						c-0.79,0.672-2.007,1.583-3.669,2.447c-1.657,0.864-3.748,1.7-6.163,2.267c-1.206,0.285-2.488,0.521-3.828,0.675
						c-0.67,0.072-1.353,0.141-2.047,0.178c-0.347,0.023-0.694,0.047-1.073,0.044c-0.376-0.003-0.779-0.034-1.191-0.155
						c-0.414-0.119-0.815-0.349-1.129-0.647c-0.316-0.3-0.544-0.659-0.709-1.016c-0.328-0.721-0.443-1.436-0.545-2.106
						c-0.093-0.677-0.174-1.287-0.299-1.905c-0.121-0.614-0.263-1.215-0.411-1.802c-0.606-2.342-1.232-4.501-1.389-6.383
						c-0.183-1.875,0.035-3.412,0.354-4.406c0.152-0.5,0.317-0.871,0.434-1.117c0.125-0.243,0.191-0.37,0.191-0.37
						s-0.05,0.134-0.146,0.389c-0.089,0.257-0.211,0.639-0.31,1.142c-0.211,1.001-0.281,2.497,0.032,4.29
						c0.288,1.8,1.025,3.848,1.745,6.205c0.176,0.591,0.347,1.204,0.494,1.84c0.151,0.631,0.262,1.314,0.364,1.952
						c0.109,0.638,0.236,1.273,0.489,1.803c0.249,0.533,0.629,0.908,1.152,1.059c0.532,0.157,1.204,0.117,1.887,0.08
						c0.677-0.028,1.342-0.083,1.996-0.139c1.306-0.12,2.558-0.307,3.741-0.533c2.368-0.449,4.441-1.129,6.121-1.849
						c1.683-0.718,2.958-1.498,3.805-2.082c0.436-0.277,0.743-0.535,0.964-0.693C252.518,77.268,252.632,77.183,252.632,77.183z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M235.479,97.555c0,0-0.016-0.21-0.019-0.59c-0.002-0.38,0.007-0.933,0.08-1.613
						c0.071-0.68,0.206-1.488,0.444-2.366c0.232-0.88,0.606-1.82,1-2.748c0.394-0.931,0.747-1.796,0.942-2.606
						c0.197-0.803,0.236-1.565,0.163-2.196c-0.069-0.634-0.26-1.131-0.427-1.46c-0.089-0.161-0.162-0.289-0.221-0.368
						c-0.055-0.083-0.084-0.127-0.084-0.127s0.041,0.033,0.12,0.095c0.082,0.06,0.19,0.161,0.328,0.299
						c0.262,0.281,0.607,0.756,0.83,1.437c0.228,0.675,0.338,1.542,0.222,2.487c-0.112,0.941-0.438,1.92-0.84,2.874
						c-0.407,0.956-0.818,1.806-1.137,2.615c-0.325,0.805-0.587,1.548-0.792,2.181c-0.206,0.633-0.354,1.157-0.455,1.521
						C235.534,97.353,235.479,97.555,235.479,97.555z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M268.268,98.82c0,0-0.44,0.228-1.237,0.64c-0.395,0.216-0.902,0.427-1.487,0.686
						c-0.581,0.271-1.266,0.517-2.022,0.796c-1.522,0.527-3.374,1.087-5.488,1.434c-2.11,0.362-4.472,0.563-6.947,0.478
						c-2.476-0.082-4.819-0.44-6.9-0.946c-2.085-0.504-3.888-1.199-5.374-1.825c-0.743-0.329-1.395-0.69-1.922-1.073
						c-0.529-0.381-0.926-0.79-1.193-1.161c-0.269-0.372-0.404-0.702-0.485-0.924c-0.069-0.228-0.098-0.348-0.098-0.348
						s0.049,0.113,0.143,0.329c0.107,0.208,0.279,0.509,0.576,0.832c0.296,0.323,0.712,0.666,1.251,0.975
						c0.538,0.311,1.189,0.594,1.935,0.85c1.51,0.485,3.318,1.033,5.372,1.423c2.051,0.392,4.332,0.67,6.734,0.75
						c4.811,0.17,9.166-0.514,12.233-1.328c0.766-0.21,1.465-0.386,2.063-0.594c0.602-0.195,1.128-0.348,1.542-0.515
						C267.803,98.991,268.268,98.82,268.268,98.82z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M267.06,91.011c-0.004,0.006,0.109,0.029,0.272,0.176c0.161,0.145,0.324,0.449,0.412,0.809
						c0.099,0.353,0.151,0.787,0.302,1.079c0.065,0.134,0.176,0.254,0.342,0.478c0.173,0.227,0.324,0.539,0.408,0.824
						c0.168,0.589,0.191,1.079,0.215,1.553c0.014,0.469-0.01,0.893-0.049,1.248c-0.08,0.71-0.21,1.14-0.21,1.14
						s-0.149-0.424-0.347-1.091c-0.099-0.333-0.213-0.727-0.328-1.162c-0.12-0.426-0.226-0.937-0.351-1.361
						c-0.068-0.219-0.13-0.381-0.235-0.567c-0.105-0.19-0.279-0.465-0.343-0.773c-0.116-0.588,0.006-0.995,0.039-1.326
						c0.051-0.33,0.05-0.577-0.001-0.757C267.131,91.106,267.055,91.013,267.06,91.011z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M261.439,112.178c0,0-0.171,0.029-0.496,0.082c-0.326,0.051-0.806,0.14-1.428,0.225
						c-1.245,0.151-3.061,0.337-5.319,0.308c-2.254-0.028-4.949-0.286-7.871-0.952c-1.465-0.317-2.972-0.778-4.512-1.318
						c-1.533-0.562-3.086-1.238-4.626-2.036c-0.384-0.199-0.756-0.418-1.13-0.625c-0.186-0.105-0.372-0.21-0.558-0.314
						c-0.18-0.113-0.36-0.225-0.539-0.336c-0.715-0.45-1.421-0.873-2.088-1.41c-0.662-0.525-1.286-1.181-1.663-1.965
						c-0.39-0.771-0.549-1.602-0.587-2.387c-0.063-1.582,0.32-3.013,0.761-4.275c0.446-1.271,1.014-2.393,1.617-3.357
						c1.211-1.932,2.526-3.238,3.49-4.043c0.239-0.206,0.458-0.379,0.653-0.523c0.191-0.148,0.355-0.27,0.493-0.361
						c0.272-0.188,0.415-0.286,0.415-0.286s-0.133,0.111-0.385,0.323c-0.128,0.104-0.281,0.239-0.457,0.403
						c-0.18,0.159-0.382,0.35-0.601,0.572c-0.884,0.873-2.071,2.255-3.124,4.195c-0.525,0.968-1.009,2.078-1.374,3.313
						c-0.366,1.231-0.654,2.592-0.546,3.979c0.058,0.687,0.218,1.375,0.548,1.981c0.314,0.612,0.818,1.124,1.421,1.587
						c0.597,0.465,1.306,0.88,2.003,1.308c0.175,0.107,0.351,0.215,0.526,0.323c0.181,0.1,0.362,0.2,0.543,0.301
						c0.365,0.199,0.726,0.412,1.1,0.605c1.495,0.775,2.998,1.448,4.479,2.023c1.489,0.553,2.945,1.045,4.364,1.404
						c2.829,0.752,5.454,1.144,7.671,1.311c2.219,0.168,4.028,0.123,5.277,0.077C260.748,112.226,261.439,112.178,261.439,112.178z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M258.328,114.997c0,0-0.38,0.007-1.066,0.02c-0.344,0.008-0.766,0.01-1.253-0.016
						c-0.488-0.022-1.047-0.031-1.66-0.097c-0.307-0.029-0.629-0.059-0.964-0.09c-0.335-0.034-0.681-0.092-1.041-0.141
						c-0.722-0.091-1.483-0.249-2.286-0.409c-1.597-0.356-3.333-0.852-5.099-1.534c-1.76-0.696-3.373-1.509-4.788-2.331
						c-0.699-0.427-1.365-0.827-1.957-1.249c-0.298-0.208-0.591-0.4-0.861-0.603c-0.267-0.205-0.525-0.4-0.77-0.588
						c-0.495-0.367-0.912-0.74-1.286-1.055c-0.376-0.312-0.685-0.599-0.932-0.838c-0.496-0.475-0.77-0.738-0.77-0.738
						s0.314,0.214,0.879,0.602c0.279,0.198,0.623,0.435,1.035,0.692c0.41,0.26,0.863,0.573,1.391,0.877
						c0.26,0.157,0.533,0.322,0.817,0.493c0.284,0.171,0.59,0.331,0.899,0.507c0.616,0.36,1.298,0.699,2.006,1.07
						c1.433,0.712,3.03,1.44,4.743,2.117c1.718,0.664,3.386,1.212,4.921,1.661c0.772,0.206,1.503,0.421,2.2,0.574
						c0.348,0.08,0.681,0.17,1.005,0.237c0.325,0.067,0.637,0.13,0.935,0.191c0.595,0.135,1.14,0.212,1.617,0.299
						c0.478,0.09,0.891,0.149,1.23,0.194C257.952,114.941,258.328,114.997,258.328,114.997z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M254.396,110.402c0,0-0.197,0.056-0.552,0.158c-0.356,0.101-0.877,0.222-1.53,0.335
						c-0.652,0.116-1.438,0.207-2.316,0.247c-0.878,0.032-1.847,0.01-2.855-0.108c-1.008-0.123-1.956-0.331-2.801-0.569
						c-0.844-0.247-1.586-0.52-2.193-0.787c-0.607-0.265-1.085-0.505-1.408-0.687c-0.321-0.182-0.499-0.283-0.499-0.283
						s0.2,0.042,0.56,0.12c0.357,0.083,0.877,0.19,1.513,0.317c1.274,0.256,3.024,0.544,4.96,0.778
						c1.936,0.229,3.704,0.362,5.003,0.414c0.648,0.027,1.178,0.046,1.545,0.049C254.191,110.396,254.396,110.402,254.396,110.402z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M218.833,168.306c0,0,0.153-0.958,0.461-2.683c0.308-1.726,0.773-4.22,1.41-7.288
						c0.636-3.069,1.446-6.712,2.442-10.735c0.499-2.011,1.044-4.117,1.635-6.293c0.595-2.166,1.199-4.453,2.13-6.753
						c0.461-1.145,1.016-2.272,1.643-3.309c0.616-1.028,1.252-1.969,1.778-2.899c0.533-0.926,0.953-1.844,1.145-2.739
						c0.098-0.448,0.116-0.884,0.099-1.314c-0.046-0.427-0.126-0.838-0.29-1.256c-0.314-0.839-0.846-1.67-1.334-2.563
						c-0.244-0.447-0.479-0.915-0.668-1.41c-0.191-0.492-0.325-1.029-0.386-1.539c-0.125-1.029-0.022-1.975,0.097-2.839
						c0.127-0.862,0.259-1.66,0.382-2.437c0.995-6.183,1.717-9.995,1.717-9.995s-0.165,3.876-0.604,10.123
						c-0.055,0.778-0.113,1.593-0.173,2.442c-0.049,0.831-0.064,1.675,0.094,2.469c0.145,0.786,0.52,1.519,1.026,2.319
						c0.495,0.798,1.12,1.637,1.597,2.715c0.242,0.535,0.408,1.148,0.496,1.772c0.061,0.626,0.054,1.267-0.055,1.885
						c-0.215,1.237-0.71,2.365-1.266,3.401c-0.555,1.045-1.185,2.016-1.749,2.99c-0.562,0.96-1.044,1.959-1.476,3.027
						c-0.863,2.127-1.49,4.346-2.145,6.502c-0.649,2.151-1.276,4.226-1.873,6.203c-1.197,3.956-2.274,7.518-3.179,10.511
						C219.962,164.6,218.833,168.306,218.833,168.306z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M213.824,228.352c0,0-0.049,0.004-0.146,0.013c-0.097,0.006-0.243,0.027-0.434,0.021
						c-0.381,0.003-0.958-0.044-1.662-0.285c-0.704-0.234-1.526-0.682-2.313-1.419c-0.39-0.371-0.768-0.815-1.099-1.34
						c-0.164-0.261-0.324-0.55-0.446-0.889c-0.124-0.337-0.186-0.732-0.181-1.104c0.004-0.759,0.215-1.447,0.354-2.116
						c0.154-0.68,0.313-1.386,0.462-2.122c0.31-1.467,0.609-3.04,0.885-4.699c0.566-3.316,1.025-6.983,1.339-10.843
						c0.158-1.93,0.257-3.819,0.322-5.645c0.039-0.914,0.047-1.81,0.068-2.691c0.012-0.885,0.022-1.77,0.075-2.647
						c0.052-0.876,0.15-1.749,0.323-2.584c0.171-0.832,0.399-1.611,0.641-2.346c0.495-1.467,1.008-2.763,1.46-3.971
						c1.832-4.809,3.066-7.751,3.066-7.751s-0.702,3.112-2,8.092c-0.317,1.243-0.707,2.614-1.055,4.048
						c-0.17,0.718-0.321,1.458-0.417,2.213c-0.097,0.752-0.132,1.534-0.13,2.355c0.001,0.821,0.039,1.676,0.069,2.57
						c0.022,0.896,0.051,1.816,0.042,2.751c-0.004,1.87-0.071,3.807-0.233,5.785c-0.321,3.955-0.933,7.694-1.719,11.036
						c-0.386,1.672-0.812,3.247-1.254,4.706c-0.216,0.73-0.444,1.431-0.666,2.102c-0.219,0.684-0.458,1.282-0.515,1.814
						c-0.031,0.269-0.023,0.507,0.035,0.754c0.057,0.247,0.155,0.497,0.271,0.742c0.231,0.487,0.514,0.921,0.819,1.297
						c0.617,0.748,1.311,1.267,1.932,1.58c0.622,0.321,1.164,0.454,1.534,0.511c0.185,0.037,0.329,0.04,0.426,0.05
						C213.774,228.348,213.824,228.352,213.824,228.352z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M265.551,234.307c0,0-0.214,0.029-0.62,0.084c-0.403,0.076-0.995,0.184-1.732,0.415
						c-0.739,0.225-1.62,0.563-2.634,0.996c-1.017,0.425-2.162,0.958-3.51,1.433c-1.352,0.48-2.904,0.827-4.579,1.024
						c-1.675,0.187-3.471,0.224-5.327,0.186c-1.858-0.034-3.775-0.126-5.737-0.109c-2.004,0.015-4.086,0.016-6.224-0.04
						c-4.274-0.11-8.377-0.449-12.054-1.159c-1.838-0.349-3.571-0.763-5.162-1.26c-0.8-0.226-1.555-0.513-2.285-0.76
						c-0.719-0.277-1.415-0.549-2.058-0.818c-1.282-0.55-2.441-1.047-3.46-1.484c-0.313-0.127-0.642-0.31-0.794-0.599
						c-0.157-0.284-0.133-0.633-0.111-0.892c0.04-0.488,0.122-0.926,0.214-1.313c0.19-0.773,0.433-1.34,0.613-1.711
						c0.181-0.372,0.294-0.556,0.294-0.556s-0.082,0.201-0.205,0.591c-0.122,0.39-0.276,0.975-0.358,1.736
						c-0.038,0.381-0.059,0.805-0.039,1.265c0.011,0.208,0.015,0.381,0.126,0.543c0.119,0.157,0.302,0.214,0.504,0.27
						c1.06,0.323,2.267,0.691,3.6,1.098c0.667,0.203,1.354,0.398,2.076,0.604c0.73,0.175,1.478,0.389,2.271,0.546
						c1.572,0.356,3.274,0.639,5.071,0.875c3.595,0.484,7.592,0.677,11.813,0.786c2.118,0.046,4.186,0.091,6.183,0.135
						c2.046,0.046,3.989,0.228,5.809,0.364c1.821,0.141,3.532,0.224,5.125,0.175c1.59-0.059,3.059-0.248,4.374-0.57
						c1.322-0.321,2.52-0.722,3.592-1.028c1.069-0.313,2.011-0.542,2.791-0.669c0.779-0.133,1.393-0.155,1.804-0.17
						C265.334,234.302,265.551,234.307,265.551,234.307z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M261.566,176.671c0,0-0.05-1.156-0.141-3.245c-0.02-0.523-0.042-1.103-0.066-1.739
						c-0.025-0.635-0.042-1.322-0.138-2.031c-0.092-0.714-0.288-1.422-0.66-2.127c-0.363-0.711-0.867-1.417-1.389-2.211
						c-2.071-3.185-3.959-7.466-4.434-12.61c-0.108-1.285-0.072-2.643,0.092-3.974c0.158-1.335,0.421-2.656,0.683-3.973
						c0.525-2.635,1.082-5.36,1.671-8.148c0.588-2.768,1.148-5.569,2.118-8.18c0.943-2.596,2.093-4.945,3.116-7.175
						c1.032-2.227,1.93-4.346,2.551-6.394c0.617-2.053,1.002-4.049,1.366-5.879c1.442-7.332,2.439-11.852,2.439-11.852
						s-0.444,4.606-1.334,12.027c-0.227,1.856-0.476,3.909-0.994,6.101c-0.524,2.194-1.348,4.455-2.296,6.749
						c-0.94,2.299-1.99,4.652-2.826,7.129c-0.851,2.455-1.355,5.135-1.95,7.936c-0.588,2.788-1.18,5.506-1.765,8.128
						c-0.292,1.312-0.579,2.574-0.765,3.802c-0.191,1.23-0.268,2.411-0.221,3.59c0.095,2.354,0.566,4.617,1.183,6.657
						c0.629,2.044,1.442,3.882,2.325,5.486c0.443,0.802,0.919,1.587,1.257,2.408c0.171,0.408,0.304,0.828,0.392,1.243
						c0.086,0.415,0.129,0.817,0.146,1.201c0.035,0.769-0.014,1.466-0.049,2.101c-0.038,0.635-0.072,1.215-0.103,1.737
						C261.641,175.517,261.566,176.671,261.566,176.671z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M266.745,235.144c0,0-0.063-0.755-0.178-2.121c-0.072-0.679-0.19-1.508-0.306-2.466
						c-0.172-0.955-0.313-2.042-0.571-3.232c-0.462-2.386-1.145-5.194-1.952-8.316c-0.798-3.124-1.712-6.572-2.418-10.282
						c-0.186-0.923-0.316-1.853-0.468-2.761c-0.122-0.915-0.238-1.819-0.328-2.71c-0.034-0.448-0.068-0.891-0.102-1.331
						c-0.035-0.439-0.067-0.873-0.07-1.308c-0.016-0.866-0.056-1.711-0.029-2.538c0.086-3.304,0.513-6.213,0.876-8.638
						c0.746-4.846,1.314-7.831,1.314-7.831s-0.012,3.04-0.2,7.937c-0.085,2.445-0.226,5.347-0.102,8.517
						c0.025,0.792,0.113,1.601,0.169,2.427c0.023,0.413,0.074,0.83,0.126,1.251c0.049,0.42,0.098,0.845,0.147,1.274
						c0.116,0.857,0.252,1.727,0.386,2.611c0.164,0.883,0.297,1.78,0.48,2.686c0.69,3.627,1.462,7.093,2.062,10.286
						c0.607,3.191,1.028,6.109,1.217,8.574c0.122,1.229,0.125,2.352,0.168,3.329c-0.013,0.983-0.018,1.825-0.05,2.513
						C266.806,234.385,266.745,235.144,266.745,235.144z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M255.031,207.704c0,0-0.561,0.027-1.574,0.076c-0.254,0.011-0.536,0.023-0.846,0.037
						c-0.309,0.004-0.645-0.012-1.006-0.019c-0.722-0.026-1.55-0.016-2.455-0.127c-0.454-0.044-0.93-0.091-1.426-0.14
						c-0.496-0.053-1.004-0.156-1.535-0.239c-1.067-0.149-2.18-0.434-3.359-0.714c-2.334-0.637-4.855-1.535-7.367-2.775
						c-2.498-1.267-4.728-2.747-6.636-4.234c-0.931-0.776-1.826-1.496-2.584-2.261c-0.384-0.375-0.771-0.721-1.111-1.085
						c-0.336-0.368-0.659-0.721-0.966-1.058c-0.631-0.658-1.119-1.327-1.573-1.889c-0.222-0.285-0.436-0.544-0.618-0.794
						c-0.175-0.256-0.335-0.489-0.478-0.699c-0.568-0.84-0.882-1.305-0.882-1.305s0.363,0.427,1.02,1.198
						c0.163,0.193,0.344,0.408,0.542,0.643c0.204,0.229,0.441,0.466,0.686,0.728c0.499,0.515,1.033,1.13,1.706,1.73
						c0.327,0.309,0.669,0.632,1.025,0.969c0.358,0.334,0.762,0.648,1.16,0.993c0.787,0.703,1.704,1.361,2.644,2.078
						c1.929,1.371,4.135,2.751,6.567,3.986c2.446,1.207,4.873,2.145,7.126,2.868c1.138,0.323,2.214,0.662,3.248,0.871
						c0.515,0.113,1.008,0.248,1.491,0.333c0.483,0.083,0.947,0.164,1.39,0.24c0.884,0.179,1.696,0.237,2.408,0.329
						c0.356,0.039,0.688,0.087,0.994,0.113c0.307,0.018,0.587,0.034,0.839,0.048C254.472,207.669,255.031,207.704,255.031,207.704z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M233.363,167.972c0,0-0.201,0.045-0.585,0.131c-0.381,0.095-0.944,0.229-1.653,0.453
						c-1.415,0.442-3.445,1.205-5.682,2.607l-0.21,0.134l-0.107,0.072c-0.062,0.044-0.124,0.089-0.187,0.134l-0.022,0.016
						c-0.001,0.005,0.015,0.012,0.022,0.018l0.054,0.036l0.105,0.069l0.101,0.066l0.169,0.116c0.598,0.412,1.221,0.841,1.865,1.285
						c1.294,0.88,2.686,1.806,4.163,2.759c0.742,0.472,1.502,0.955,2.278,1.449c0.425,0.265,0.855,0.532,1.289,0.802
						c0.396,0.243,0.763,0.481,1.141,0.724l0.568,0.366c0.092,0.036-0.019,0.047-0.06,0.066l-0.161,0.054l-0.323,0.107l-0.645,0.213
						c-0.217,0.074-0.43,0.142-0.638,0.198l-0.316,0.091l-0.155,0.044l-0.091,0.023l-0.366,0.09c-0.491,0.115-0.99,0.208-1.497,0.28
						c-0.506,0.071-1.02,0.123-1.539,0.146c-0.259,0.012-0.52,0.021-0.781,0.02c-0.131,0.002-0.262,0.001-0.392-0.003l-0.188-0.004
						h0.004l-0.001-0.001c0,0-0.002-0.003,0.001,0.004l0.213,0.383c0.077,0.138,0.152,0.276,0.235,0.409
						c0.321,0.539,0.653,1.064,1.006,1.565c0.701,1.007,1.458,1.937,2.248,2.784c1.579,1.695,3.284,3.047,4.914,4.083l2.669,1.697
						l-2.998-0.952c-4.264-1.354-7.671-2.762-10.008-3.808c-1.169-0.523-2.072-0.956-2.685-1.254
						c-0.61-0.303-0.934-0.463-0.934-0.463s0.335,0.134,0.967,0.388c0.633,0.251,1.563,0.617,2.76,1.064
						c2.394,0.892,5.855,2.101,10.133,3.285l-0.329,0.746c-1.729-1.002-3.548-2.349-5.232-4.079c-0.842-0.864-1.65-1.82-2.396-2.863
						c-0.376-0.52-0.729-1.065-1.068-1.627c-0.088-0.139-0.167-0.283-0.248-0.426l-0.261-0.462
						c-0.296-0.573-0.593-1.148-0.892-1.726c0.493,0.035,1.012,0.073,1.532,0.11c0.131,0.008,0.255,0.019,0.389,0.021l0.193,0.005
						c0.123,0.004,0.245,0.004,0.367,0.003c0.244,0.002,0.488-0.006,0.73-0.016c0.485-0.02,0.966-0.066,1.441-0.128
						c0.475-0.064,0.944-0.146,1.405-0.247c0.161-0.038,0.321-0.076,0.48-0.113l0.031-0.007l0.008-0.001l0.018-0.003
						c0.005-0.001,0.017-0.002,0.02-0.004l-0.009-0.009l-0.027-0.025c-0.361-0.235-0.718-0.469-1.071-0.699
						c-0.773-0.51-1.524-1.017-2.253-1.518c-1.457-1.002-2.821-1.983-4.083-2.921c-0.63-0.469-1.235-0.927-1.813-1.373
						c-0.201-0.154-0.401-0.307-0.598-0.458c-0.107-0.083-0.215-0.165-0.321-0.247l-0.158-0.121c-0.018-0.02-0.07-0.041-0.06-0.06
						l0.088-0.054c0.231-0.142,0.459-0.282,0.684-0.42c0.108-0.067,0.227-0.136,0.326-0.193l0.222-0.125
						c2.364-1.313,4.468-1.95,5.92-2.294c0.729-0.174,1.303-0.266,1.69-0.331C233.159,168.001,233.363,167.972,233.363,167.972z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M263.918,228.749c0,0-0.639,0.243-1.794,0.683c-0.29,0.109-0.612,0.229-0.965,0.362
						c-0.355,0.125-0.747,0.244-1.165,0.38c-0.839,0.262-1.787,0.594-2.864,0.858c-0.536,0.142-1.098,0.29-1.684,0.444
						c-0.587,0.151-1.207,0.266-1.844,0.406c-1.273,0.296-2.654,0.5-4.105,0.732c-2.911,0.395-6.143,0.649-9.539,0.621
						c-3.396-0.055-6.62-0.389-9.521-0.854c-1.445-0.268-2.82-0.505-4.086-0.832c-0.634-0.156-1.25-0.286-1.833-0.451
						c-0.581-0.169-1.14-0.331-1.672-0.485c-1.07-0.291-2.01-0.646-2.842-0.929c-0.415-0.146-0.803-0.274-1.155-0.408
						c-0.349-0.141-0.668-0.27-0.956-0.386c-1.144-0.468-1.777-0.726-1.777-0.726s0.653,0.2,1.835,0.561
						c0.294,0.091,0.621,0.193,0.98,0.304c0.36,0.104,0.756,0.202,1.179,0.316c0.848,0.218,1.801,0.505,2.88,0.726
						c0.536,0.121,1.098,0.248,1.683,0.38c0.585,0.13,1.203,0.226,1.837,0.348c1.265,0.26,2.634,0.434,4.067,0.646
						c2.877,0.356,6.054,0.615,9.396,0.669c3.342,0.028,6.524-0.153,9.409-0.438c1.438-0.177,2.811-0.318,4.082-0.546
						c0.636-0.107,1.256-0.188,1.844-0.303c0.588-0.118,1.153-0.231,1.691-0.339c1.084-0.194,2.044-0.457,2.897-0.655
						c0.425-0.104,0.824-0.191,1.186-0.287c0.361-0.103,0.69-0.196,0.987-0.28C263.259,228.933,263.918,228.749,263.918,228.749z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M223.813,208.135c0,0-0.103-0.016-0.303-0.046c-0.201-0.026-0.499-0.064-0.891-0.087
						c-0.392-0.022-0.877-0.03-1.445,0.01c-0.284,0.02-0.588,0.055-0.911,0.104c-0.279,0.045-0.498,0.095-0.734,0.16
						c0.117,0.152,0.258,0.304,0.393,0.441c0.092,0.09,0.187,0.182,0.283,0.276c0.11,0.104,0.226,0.207,0.347,0.309
						c0.241,0.203,0.502,0.4,0.783,0.587c0.141,0.093,0.286,0.184,0.436,0.271l0.113,0.065l0.171,0.093
						c0.123,0.064,0.247,0.13,0.371,0.196c0.061,0.082,0.065,0.237,0.057,0.374c0.002,0.14-0.053,0.277-0.121,0.405
						c-0.036,0.064-0.087,0.121-0.133,0.18c-0.059,0.049-0.11,0.109-0.177,0.151c-0.067,0.041-0.133,0.094-0.205,0.123
						c-0.065,0.03-0.119,0.056-0.18,0.076c-0.239,0.084-0.484,0.123-0.735,0.126c-0.126,0.003-0.253-0.006-0.382-0.026
						c-0.125-0.017-0.276-0.06-0.354-0.082c-0.159-0.038-0.3-0.075-0.426-0.06c-0.062,0.009-0.121,0.034-0.159,0.083
						c-0.044,0.027,0.036,0.043,0.061,0.063l0.104,0.055c0.081,0.042,0.166,0.087,0.254,0.133l0.086,0.05
						c0.073,0.045,0.146,0.092,0.22,0.138c0.161,0.104,0.324,0.208,0.49,0.315c0.164,0.109,0.33,0.22,0.498,0.331l0.29,0.199
						c0.15,0.106,0.301,0.213,0.452,0.32c0.617,0.443,1.207,0.893,1.849,1.372c-0.339,0.037-0.738,0.067-1.098,0.099
						c-0.375,0.029-0.748,0.068-1.177,0.073c-0.209,0.007-0.422,0.013-0.649,0.003l-0.223-0.006l-0.182-0.009
						c-0.244-0.012-0.49-0.032-0.739-0.06c-0.996-0.108-2.026-0.339-3.031-0.705l0.431-1.013c0.831,0.394,1.808,0.88,2.785,1.395
						c1.677,0.883,3.282,1.807,4.799,2.751c0.377,0.239,0.751,0.476,1.122,0.711l0.275,0.178l0.442,0.311l0.445,0.314l0.055,0.039
						l0.014,0.01l-0.009,0.016l-0.017,0.033l-0.069,0.131c-0.046,0.087-0.091,0.176-0.139,0.259l-0.143,0.251l-0.181,0.239
						c-0.066,0.093-0.114,0.13-0.157,0.176c-0.045,0.046-0.091,0.091-0.14,0.131c-0.189,0.17-0.396,0.301-0.603,0.418
						c-0.417,0.229-0.844,0.374-1.261,0.488c-0.841,0.213-1.616,0.328-2.397,0.444c-0.775,0.109-1.532,0.188-2.264,0.239
						c-1.464,0.103-2.833,0.104-4.076,0.034c-0.608-0.013-1.285-0.173-1.486,0.114c-0.113,0.126-0.1,0.325,0.001,0.531
						c0.1,0.202,0.254,0.388,0.421,0.541c0.162,0.155,0.36,0.275,0.55,0.406c0.189,0.125,0.372,0.245,0.546,0.361
						c0.352,0.227,0.677,0.427,0.973,0.604c0.592,0.352,1.063,0.608,1.383,0.78c0.322,0.169,0.492,0.257,0.492,0.257
						s-0.176-0.073-0.512-0.213c-0.335-0.144-0.826-0.362-1.446-0.67c-0.31-0.154-0.653-0.332-1.022-0.535
						c-0.184-0.104-0.376-0.212-0.575-0.324c-0.099-0.058-0.2-0.117-0.303-0.177c-0.106-0.064-0.215-0.137-0.316-0.22
						c-0.204-0.165-0.396-0.362-0.547-0.62c-0.075-0.129-0.135-0.282-0.153-0.459c-0.019-0.178,0.03-0.381,0.145-0.536
						c0.106-0.158,0.283-0.273,0.454-0.327c0.173-0.057,0.342-0.072,0.501-0.076c0.296,0.001,0.599,0.002,0.91,0.003
						c1.224-0.001,2.564-0.071,3.99-0.237c0.713-0.083,1.447-0.19,2.199-0.326c0.743-0.139,1.54-0.292,2.275-0.504
						c0.366-0.111,0.722-0.249,1.033-0.433c0.153-0.093,0.301-0.193,0.423-0.31c0.033-0.027,0.061-0.058,0.089-0.088
						c0.029-0.032,0.062-0.064,0.055-0.06c0.006-0.009,0.01-0.018,0.014-0.026c-0.002-0.01,0.018-0.014-0.004-0.027l-0.071-0.041
						l-0.077-0.045l-0.276-0.17c-0.371-0.225-0.744-0.452-1.122-0.681c-1.515-0.906-3.107-1.803-4.763-2.675
						c-0.965-0.508-1.925-0.993-2.742-1.392l0.431-1.014c0.916,0.344,1.859,0.57,2.773,0.688c0.228,0.03,0.455,0.054,0.679,0.069
						c0.099,0.006,0.196,0.013,0.293,0.019l0.027,0.005c0.038,0.005,0.105,0.013,0.155,0.009c-0.023-0.024-0.054-0.056-0.096-0.094
						l-0.041-0.035l-0.202-0.148c-0.161-0.115-0.32-0.229-0.477-0.341c-0.158-0.11-0.314-0.219-0.468-0.326
						c-0.085-0.057-0.168-0.114-0.251-0.17c-0.127-0.084-0.253-0.167-0.378-0.25c-0.539-0.35-1.086-0.684-1.589-0.994
						c0.03-0.058,0.083-0.137,0.088-0.177l0.015-0.068c0.01-0.015-0.013-0.015,0.023-0.042l0.088-0.071
						c0.23-0.186,0.429-0.309,0.675-0.369c0.241-0.057,0.508-0.069,0.831-0.023c0.322,0.044,0.691,0.16,1.035,0.274
						c0.135,0.044,0.197,0.065,0.289,0.083c0.087,0.018,0.179,0.03,0.27,0.032c0.181,0.007,0.364-0.013,0.524-0.061
						c0.04-0.009,0.082-0.027,0.111-0.039c0.026-0.007,0.042-0.019,0.064-0.029c0.041-0.022,0.078-0.051,0.108-0.087
						c0.028-0.037,0.055-0.079,0.062-0.126c0.009-0.044,0.021-0.104-0.032-0.117l-0.112-0.071l-0.182-0.119
						c-0.155-0.101-0.304-0.207-0.447-0.314c-0.287-0.215-0.55-0.44-0.79-0.669c-0.12-0.115-0.235-0.23-0.344-0.347
						c-0.112-0.12-0.199-0.216-0.343-0.387c-0.255-0.307-0.469-0.598-0.652-0.867c0.246-0.046,0.48-0.087,0.7-0.122
						c0.217-0.031,0.453-0.064,0.626-0.078c0.333-0.028,0.646-0.042,0.936-0.041c0.581,0.001,1.07,0.046,1.462,0.1
						c0.392,0.054,0.688,0.117,0.886,0.16C223.711,208.111,223.813,208.135,223.813,208.135z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M256.636,103.73c0,0-0.325,0.123-0.929,0.32c-0.609,0.172-1.507,0.4-2.649,0.508
						c-1.14,0.11-2.516,0.093-4.018-0.109c-1.502-0.198-3.127-0.579-4.776-1.117c-0.827-0.266-1.619-0.558-2.378-0.854
						c-0.76-0.298-1.494-0.616-2.171-0.976c-1.357-0.71-2.471-1.638-3.135-2.61c-0.684-0.959-0.943-1.897-1.016-2.53
						c-0.033-0.32-0.032-0.568-0.021-0.734c0.014-0.166,0.02-0.253,0.02-0.253s0.01,0.086,0.027,0.251
						c0.02,0.164,0.063,0.404,0.145,0.702c0.171,0.592,0.548,1.419,1.282,2.216c0.718,0.809,1.794,1.531,3.112,2.11
						c0.658,0.292,1.374,0.556,2.129,0.821c0.758,0.263,1.543,0.534,2.35,0.793c3.215,1.068,6.212,1.686,8.431,1.732
						c1.108,0.034,2.007-0.052,2.631-0.12C256.292,103.783,256.636,103.73,256.636,103.73z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M184.114,233.463c0,0,0.378,0.005,1.097,0.052c0.718,0.05,1.779,0.148,3.127,0.407
						c0.674,0.129,1.418,0.3,2.223,0.528c0.401,0.118,0.819,0.242,1.249,0.391c0.428,0.145,0.882,0.303,1.324,0.559
						c0.441,0.247,0.857,0.653,1.095,1.154c0.249,0.494,0.378,1.03,0.527,1.556c0.295,1.061,0.606,2.183,0.934,3.36
						c0.326,1.178,0.667,2.413,1.022,3.699c0.177,0.645,0.357,1.302,0.541,1.972c0.179,0.676,0.348,1.387,0.413,2.141
						c0.131,1.528-0.26,3.021-0.356,4.49c-0.097,1.48,0.047,3.02,0.465,4.525c0.1,0.361,0.246,0.776,0.32,1.227
						c0.037,0.227,0.061,0.464,0.046,0.722c-0.016,0.255-0.072,0.541-0.233,0.813c-0.165,0.273-0.42,0.485-0.72,0.598
						c-0.299,0.112-0.602,0.116-0.871,0.073c-0.267-0.043-0.524-0.134-0.74-0.252c-0.113-0.054-0.22-0.116-0.323-0.188
						c-0.107-0.065-0.204-0.144-0.299-0.222c-0.772-0.646-1.188-1.532-1.486-2.382c-0.298-0.866-0.475-1.723-0.691-2.57
						c-0.204-0.834-0.41-1.675-0.617-2.522c-0.008-0.015-0.012-0.04-0.015-0.015l-0.001,0.017v0.001l-0.018,0.075L192.046,254
						l-0.149,0.66c-0.188,0.882-0.328,1.773-0.387,2.668c-0.112,1.777,0.146,3.573,0.933,5.069c0.197,0.371,0.421,0.725,0.68,1.049
						c0.284,0.361,0.597,0.744,0.808,1.277c0.098,0.266,0.183,0.602,0.087,0.979c-0.092,0.371-0.348,0.683-0.66,0.853
						c-0.307,0.168-0.6,0.209-0.855,0.233c-0.262,0.021-0.477,0.017-0.697,0.021c-0.885,0.014-1.781,0.012-2.678-0.09
						c-0.895-0.1-1.798-0.308-2.628-0.704c-0.833-0.397-1.492-0.92-2.174-1.186c-0.345-0.137-0.652-0.201-1.021-0.193
						c-0.373-0.002-0.818,0.057-1.32-0.048c-0.509-0.111-0.958-0.428-1.255-0.778c-0.302-0.35-0.509-0.717-0.71-1.055
						c-0.394-0.68-0.77-1.265-1.346-1.43c-0.301-0.103-0.684-0.136-1.103-0.263c-0.207-0.061-0.429-0.162-0.621-0.296
						c-0.192-0.133-0.355-0.294-0.491-0.464c-0.272-0.343-0.444-0.714-0.586-1.071c-0.137-0.359-0.25-0.704-0.359-1.058
						c-0.856-2.816-0.939-5.613-0.54-8.06c0.096-0.614,0.235-1.204,0.384-1.775c0.162-0.559,0.32-1.102,0.474-1.631
						c0.305-1.04,0.342-2.058,0.381-3.032c0.039-0.969,0.102-1.896,0.354-2.713c0.244-0.823,0.687-1.476,1.091-2.026
						c0.409-0.552,0.794-1.019,1.095-1.449c0.304-0.427,0.523-0.813,0.67-1.14c0.137-0.331,0.214-0.595,0.248-0.775
						c0.033-0.182,0.049-0.274,0.049-0.274s-0.013,0.094-0.037,0.277c-0.026,0.183-0.091,0.451-0.216,0.792
						c-0.134,0.336-0.34,0.736-0.629,1.179c-0.285,0.445-0.654,0.931-1.038,1.492c-0.38,0.561-0.781,1.208-0.983,1.996
						c-0.212,0.787-0.244,1.683-0.249,2.651c-0.005,0.485-0.006,0.991-0.042,1.517c-0.034,0.526-0.12,1.076-0.252,1.618
						c-0.129,0.544-0.29,1.088-0.423,1.645c-0.128,0.558-0.246,1.134-0.322,1.731c-0.319,2.379-0.166,5.072,0.715,7.746
						c0.231,0.658,0.451,1.372,0.847,1.847c0.203,0.239,0.418,0.39,0.721,0.472c0.305,0.091,0.688,0.116,1.119,0.244
						c0.446,0.122,0.862,0.43,1.146,0.76c0.294,0.333,0.51,0.69,0.716,1.025c0.206,0.335,0.402,0.656,0.628,0.907
						c0.226,0.251,0.474,0.402,0.757,0.467c0.294,0.062,0.67,0.02,1.112,0.008c0.218-0.008,0.469,0.008,0.706,0.049
						c0.239,0.04,0.466,0.112,0.684,0.193c0.867,0.337,1.544,0.867,2.248,1.181c1.418,0.659,3.122,0.686,4.855,0.644
						c0.448-0.007,0.871-0.017,1.043-0.127c0.078-0.043,0.117-0.11,0.134-0.171c0.021-0.058,0.005-0.181-0.052-0.329
						c-0.118-0.303-0.369-0.638-0.645-0.978c-0.307-0.379-0.572-0.791-0.801-1.221c-0.917-1.731-1.191-3.726-1.072-5.656
						c0.063-0.967,0.214-1.912,0.412-2.83l0.156-0.686l0.083-0.339l0.151-0.587c0.231-0.881,0.473-1.72,0.71-2.57l0.178-0.636
						l0.089-0.318l0.022-0.079l0.011-0.039l0.01,0.04l0.04,0.159c0.105,0.422,0.209,0.844,0.314,1.265
						c0.104,0.428,0.208,0.855,0.312,1.282c0.103,0.432,0.207,0.863,0.309,1.294c0.211,0.891,0.42,1.775,0.627,2.651
						c0.209,0.857,0.381,1.713,0.639,2.483c0.257,0.77,0.613,1.489,1.15,1.941c0.265,0.221,0.586,0.405,0.871,0.453
						c0.295,0.052,0.479-0.03,0.588-0.194c0.112-0.169,0.131-0.52,0.074-0.871c-0.052-0.36-0.17-0.718-0.28-1.136
						c-0.421-1.618-0.544-3.263-0.41-4.835c0.143-1.579,0.543-3.009,0.45-4.368c-0.042-0.681-0.183-1.355-0.343-2.022
						c-0.167-0.672-0.33-1.332-0.49-1.979c-0.32-1.295-0.628-2.539-0.921-3.726c-0.292-1.187-0.57-2.317-0.833-3.387
						c-0.133-0.537-0.244-1.052-0.45-1.494c-0.199-0.447-0.521-0.784-0.917-1.028c-0.391-0.247-0.823-0.416-1.243-0.574
						c-0.419-0.162-0.828-0.3-1.221-0.43c-0.788-0.254-1.52-0.451-2.183-0.605c-1.328-0.308-2.381-0.449-3.095-0.53
						C184.491,233.486,184.114,233.463,184.114,233.463z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M186.879,251.224c0,0,0.566,3.624,0.574,8.097c0.002,1.118-0.031,2.184-0.082,3.152
						c-0.027,0.486-0.053,0.914-0.004,1.29c0.044,0.375,0.172,0.691,0.359,0.968c0.379,0.55,0.88,0.899,1.205,1.14
						c0.337,0.236,0.525,0.366,0.525,0.366s-0.219-0.064-0.608-0.212c-0.382-0.158-0.966-0.37-1.552-0.936
						c-0.287-0.279-0.549-0.693-0.678-1.165c-0.135-0.469-0.165-0.968-0.192-1.449c-0.054-0.969-0.091-2.034-0.093-3.152
						C186.326,254.85,186.879,251.224,186.879,251.224z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M181.451,253.187c0,0,0.005,0.043,0.014,0.124c0.014,0.08,0.041,0.195,0.099,0.332
						c0.109,0.284,0.38,0.604,0.681,1.119c0.303,0.535,0.438,1.284,0.437,2.043c0.004,0.381-0.016,0.77-0.029,1.154
						c-0.007,0.379-0.014,0.767,0.009,1.165c0.062,1.591,0.421,3,0.921,3.936c0.245,0.47,0.513,0.818,0.704,1.061
						c0.207,0.231,0.323,0.36,0.323,0.36s-0.156-0.072-0.436-0.227c-0.267-0.17-0.656-0.438-1.049-0.873
						c-0.797-0.854-1.517-2.423-1.582-4.208c-0.025-0.444,0.001-0.88,0.042-1.293c0.046-0.409,0.108-0.783,0.155-1.132
						c0.098-0.694,0.115-1.288-0.014-1.778c-0.138-0.495-0.322-0.959-0.331-1.288c-0.011-0.165,0.007-0.291,0.023-0.373
						C181.439,253.229,181.451,253.187,181.451,253.187z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M292.37,253.521c0,0-0.023,0.019-0.07,0.058c-0.045,0.038-0.116,0.092-0.198,0.174
						c-0.169,0.158-0.4,0.411-0.631,0.78c-0.23,0.368-0.453,0.853-0.63,1.442c-0.182,0.589-0.305,1.28-0.444,2.061
						c-0.145,0.778-0.3,1.66-0.704,2.568c-0.208,0.458-0.49,0.902-0.859,1.307c-0.372,0.397-0.835,0.765-1.413,0.982
						c-0.286,0.107-0.598,0.182-0.919,0.194c-0.333,0.016-0.673-0.033-1.003-0.164c-0.326-0.13-0.66-0.355-0.883-0.711
						c-0.227-0.354-0.287-0.797-0.228-1.167c0.119-0.744,0.492-1.263,0.728-1.758c0.257-0.508,0.495-1.034,0.71-1.577
						c0.429-1.087,0.749-2.188,0.959-3.271c0.211-1.075,0.308-2.191,0.603-3.255c0.292-1.077,0.808-1.996,1.316-2.79
						c0.515-0.796,1.034-1.489,1.474-2.137c0.889-1.284,1.416-2.44,1.607-3.294c0.102-0.425,0.133-0.766,0.146-0.998
						c0.004-0.233,0.007-0.355,0.007-0.355s0.014,0.122,0.042,0.354c0.017,0.233,0.029,0.581-0.022,1.029
						c-0.09,0.895-0.509,2.163-1.304,3.558c-0.389,0.699-0.852,1.435-1.292,2.234c-0.436,0.801-0.85,1.668-1.057,2.629
						c-0.219,0.967-0.271,2.053-0.465,3.216c-0.192,1.156-0.515,2.334-0.972,3.492c-0.229,0.578-0.487,1.137-0.768,1.674
						c-0.288,0.547-0.59,1.015-0.651,1.407c-0.034,0.191-0.008,0.34,0.061,0.463c0.069,0.125,0.205,0.24,0.381,0.319
						c0.176,0.079,0.382,0.12,0.583,0.119c0.214,0.003,0.427-0.038,0.632-0.102c0.832-0.271,1.499-0.992,1.901-1.735
						c0.411-0.769,0.635-1.596,0.845-2.353c0.206-0.761,0.403-1.468,0.657-2.059c0.248-0.593,0.538-1.072,0.821-1.424
						c0.283-0.353,0.552-0.579,0.743-0.716c0.093-0.071,0.171-0.115,0.222-0.147C292.344,253.536,292.37,253.521,292.37,253.521z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M308.794,249.817c0,0-0.001,0.043-0.004,0.127c-0.006,0.084-0.004,0.208,0.007,0.371
						c0.022,0.325,0.125,0.794,0.331,1.377c0.105,0.293,0.223,0.618,0.351,0.975c0.123,0.361,0.248,0.765,0.302,1.225
						c0.063,0.461,0.021,0.964-0.085,1.471c-0.106,0.508-0.273,1.021-0.45,1.55c-0.356,1.062-0.795,2.21-1.339,3.413
						c-0.275,0.605-0.563,1.213-0.901,1.847c-0.344,0.629-0.748,1.274-1.324,1.846c-0.288,0.283-0.622,0.546-1.013,0.745
						c-0.382,0.201-0.853,0.322-1.277,0.353c-0.197,0.016-0.356,0.031-0.461,0.066c-0.1,0.032-0.198,0.095-0.305,0.19
						c-0.218,0.192-0.423,0.518-0.722,0.859c-0.576,0.652-1.283,1.219-2.144,1.583c-0.43,0.179-0.902,0.302-1.4,0.321
						c-0.494,0.023-1.02-0.082-1.475-0.282c-0.221-0.104-0.333-0.128-0.347-0.118c-0.034-0.003-0.162,0.06-0.329,0.189
						c-0.372,0.288-0.837,0.55-1.296,0.709c-0.926,0.334-1.955,0.362-2.854,0.101c-0.208-0.058-0.383-0.105-0.529-0.121
						c-0.134-0.017-0.278-0.001-0.481,0.031c-0.192,0.032-0.452,0.068-0.698,0.061c-0.247-0.002-0.487-0.04-0.718-0.102
						c-0.462-0.121-0.882-0.346-1.233-0.642c-0.089-0.072-0.171-0.152-0.251-0.234c-0.042-0.039-0.077-0.085-0.115-0.128
						c-0.04-0.043-0.105-0.123-0.148-0.194l-0.147-0.214l-0.117-0.219c-0.074-0.144-0.159-0.3-0.219-0.439
						c0.363-0.21,0.724-0.418,1.08-0.623c0.219-0.131,0.433-0.264,0.634-0.402c0.402-0.278,0.764-0.58,1.05-0.921
						c0.586-0.662,0.798-1.557,1.065-2.404c0.518-1.684,0.933-3.164,1.163-4.413c0.236-1.248,0.307-2.254,0.308-2.943
						c-0.001-0.344-0.007-0.609-0.021-0.788c-0.01-0.179-0.015-0.271-0.015-0.271s0.014,0.092,0.041,0.27
						c0.031,0.177,0.062,0.441,0.094,0.787c0.061,0.691,0.076,1.714-0.068,2.997c-0.138,1.283-0.457,2.815-0.879,4.532
						c-0.107,0.428-0.204,0.873-0.355,1.343c-0.148,0.465-0.378,0.963-0.712,1.387c-0.327,0.431-0.733,0.797-1.164,1.124
						c-0.216,0.163-0.439,0.315-0.666,0.463l-0.171,0.109l-0.043,0.027l-0.009,0.006l-0.015,0.012l-0.054,0.041
						c0.008,0.026,0.036,0.057,0.055,0.077l0.111,0.114c0.058,0.055,0.116,0.108,0.18,0.157c0.251,0.196,0.552,0.344,0.868,0.416
						c0.158,0.037,0.319,0.058,0.479,0.054c0.161-0.001,0.307-0.022,0.519-0.063c0.202-0.037,0.471-0.082,0.752-0.056
						c0.27,0.024,0.5,0.089,0.698,0.138c0.723,0.194,1.492,0.153,2.209-0.108c0.363-0.135,0.676-0.309,1.006-0.565
						c0.186-0.132,0.436-0.341,0.853-0.402c0.207-0.028,0.415,0.007,0.567,0.056c0.156,0.048,0.276,0.105,0.373,0.147
						c0.627,0.283,1.335,0.236,2.004-0.038c0.667-0.272,1.273-0.734,1.763-1.27c0.238-0.251,0.459-0.606,0.833-0.936
						c0.188-0.161,0.427-0.314,0.701-0.393c0.269-0.075,0.5-0.079,0.685-0.092c0.657-0.02,1.218-0.315,1.71-0.768
						c0.488-0.448,0.883-1.014,1.23-1.589c0.343-0.58,0.664-1.18,0.958-1.752c0.592-1.151,1.088-2.253,1.504-3.275
						c0.421-1.021,0.76-1.95,0.707-2.797c-0.045-0.846-0.323-1.573-0.467-2.179c-0.159-0.605-0.221-1.095-0.211-1.425
						c0.004-0.165,0.014-0.29,0.028-0.373C308.788,249.86,308.794,249.817,308.794,249.817z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M293.504,240.042c0,0,0.281-0.175,0.81-0.466c0.534-0.277,1.329-0.655,2.37-0.979
						c1.039-0.323,2.328-0.583,3.783-0.639c0.364-0.021,0.737-0.006,1.119-0.002c0.38,0.027,0.771,0.039,1.164,0.097
						c0.79,0.086,1.6,0.26,2.41,0.5c0.201,0.064,0.401,0.128,0.6,0.191c0.195,0.074,0.389,0.148,0.581,0.222
						c0.187,0.073,0.383,0.14,0.633,0.298c0.119,0.077,0.249,0.191,0.343,0.314c0.095,0.121,0.159,0.241,0.21,0.348
						c0.098,0.217,0.154,0.372,0.229,0.553c0.07,0.175,0.139,0.349,0.207,0.521c0.135,0.344,0.267,0.681,0.396,1.01
						c0.255,0.663,0.492,1.281,0.71,1.885c0.108,0.306,0.202,0.616,0.259,0.928c0.06,0.313,0.07,0.625,0.058,0.902
						c-0.029,0.561-0.1,1.021-0.107,1.44c-0.014,0.418-0.002,0.782,0.01,1.08c0.03,0.596,0.061,0.928,0.061,0.928
						s-0.099-0.317-0.234-0.904c-0.065-0.294-0.138-0.657-0.19-1.087c-0.059-0.429-0.058-0.936-0.099-1.443
						c-0.034-0.511-0.197-0.998-0.465-1.552c-0.272-0.564-0.564-1.167-0.87-1.801c-0.295-0.633-0.605-1.296-0.926-1.984
						c-0.034-0.065-0.067-0.114-0.093-0.145c-0.026-0.03-0.045-0.044-0.09-0.073c-0.082-0.052-0.259-0.121-0.443-0.185
						c-0.18-0.066-0.36-0.131-0.542-0.197c-0.185-0.058-0.371-0.116-0.559-0.174c-0.752-0.223-1.501-0.402-2.235-0.511
						c-0.365-0.069-0.73-0.097-1.085-0.143c-0.357-0.024-0.707-0.063-1.049-0.068c-1.372-0.049-2.617,0.06-3.653,0.239
						c-1.037,0.178-1.864,0.417-2.433,0.595C293.817,239.934,293.504,240.042,293.504,240.042z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M297.69,257.647c0,0-0.013,0.043-0.038,0.124c-0.026,0.08-0.063,0.197-0.097,0.351
						c-0.074,0.306-0.134,0.749-0.166,1.306c-0.039,0.559-0.037,1.234-0.198,2.027c-0.082,0.395-0.22,0.82-0.452,1.232
						c-0.233,0.409-0.531,0.752-0.8,1.073c-0.278,0.327-0.482,0.594-0.678,0.93c-0.195,0.329-0.405,0.699-0.637,1.019
						c-0.459,0.643-0.967,1.141-1.423,1.51c-0.458,0.369-0.864,0.611-1.15,0.763c-0.286,0.151-0.452,0.218-0.452,0.218
						s0.124-0.128,0.346-0.357c0.218-0.23,0.522-0.572,0.855-1.014c0.332-0.442,0.693-0.985,1.021-1.616
						c0.167-0.314,0.309-0.648,0.5-1.036c0.184-0.386,0.475-0.806,0.76-1.135c0.286-0.339,0.552-0.629,0.745-0.916
						c0.19-0.285,0.337-0.6,0.455-0.926c0.234-0.651,0.379-1.322,0.557-1.874c0.174-0.558,0.379-0.995,0.555-1.271
						c0.084-0.141,0.162-0.24,0.215-0.307C297.662,257.683,297.69,257.647,297.69,257.647z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M302.48,258.144c0,0-0.114,0.607-0.377,1.556c-0.132,0.475-0.299,1.032-0.512,1.646
						c-0.214,0.612-0.481,1.287-0.857,1.965c-0.755,1.371-1.835,2.345-2.673,2.891c-0.42,0.279-0.782,0.464-1.036,0.58
						s-0.399,0.169-0.399,0.169s0.106-0.111,0.297-0.311c0.19-0.199,0.459-0.489,0.767-0.857c0.624-0.733,1.384-1.778,2.066-3.017
						c0.681-1.245,1.303-2.462,1.835-3.289C302.11,258.639,302.48,258.144,302.48,258.144z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M307.279,256.977c0,0-0.051,0.131-0.13,0.366c-0.08,0.234-0.182,0.573-0.292,0.993
						c-0.11,0.422-0.226,0.925-0.391,1.492c-0.162,0.564-0.401,1.202-0.748,1.814c-0.692,1.231-1.65,2.112-2.398,2.623
						c-0.373,0.26-0.7,0.431-0.924,0.546c-0.229,0.109-0.357,0.163-0.357,0.163s0.089-0.107,0.249-0.3
						c0.156-0.195,0.386-0.469,0.647-0.815c0.529-0.69,1.186-1.658,1.809-2.768c0.314-0.555,0.586-1.091,0.843-1.608
						c0.257-0.516,0.511-0.99,0.757-1.372c0.246-0.383,0.48-0.672,0.654-0.858C307.171,257.066,307.279,256.977,307.279,256.977z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M222.849,324.745c0,0-0.092,0.027-0.271,0.081c-0.09,0.023-0.201,0.06-0.328,0.113
						c-0.129,0.048-0.276,0.109-0.437,0.193c-0.325,0.16-0.706,0.394-1.135,0.706c-0.433,0.307-0.919,0.693-1.553,1.027
						c-0.63,0.33-1.458,0.548-2.338,0.486c-0.862-0.036-1.806-0.19-2.771-0.532c-0.965-0.341-1.938-0.882-2.849-1.596
						c-0.236-0.184-0.436-0.349-0.664-0.52c-0.228-0.169-0.459-0.34-0.693-0.513c-0.475-0.348-0.973-0.708-1.467-1.124
						c-0.492-0.418-0.993-0.885-1.408-1.489c-0.102-0.152-0.206-0.304-0.29-0.475c-0.095-0.162-0.169-0.34-0.241-0.52
						c-0.078-0.178-0.117-0.374-0.175-0.564c-0.037-0.186-0.078-0.375-0.096-0.563c-0.176-1.509,0.145-2.944,0.179-4.302
						c0.006-0.17,0.004-0.336-0.001-0.501c-0.005-0.157-0.017-0.333-0.033-0.517c-0.025-0.36-0.063-0.724-0.1-1.09
						c-0.079-0.731-0.183-1.475-0.302-2.228c-0.238-1.506-0.554-3.053-0.903-4.64c-0.18-0.796-0.362-1.601-0.546-2.415
						c-0.196-0.858-0.333-1.752-0.407-2.648c-0.15-1.798-0.058-3.629,0.168-5.444c0.113-0.908,0.258-1.815,0.421-2.719
						c0.087-0.461,0.154-0.886,0.228-1.322c0.081-0.434,0.124-0.877,0.186-1.318c0.215-1.771,0.306-3.588,0.274-5.424
						c-0.07-3.668-0.613-7.254-1.744-10.475c-0.283-0.805-0.605-1.583-0.969-2.328l-0.132-0.282l-0.15-0.307
						c-0.103-0.205-0.2-0.412-0.292-0.621c-0.188-0.415-0.359-0.836-0.514-1.259c-0.626-1.691-1.007-3.42-1.191-5.115
						c-0.093-0.848-0.137-1.688-0.14-2.516c-0.003-0.409,0.01-0.829,0.034-1.248c0.031-0.414,0.071-0.822,0.124-1.222
						c0.217-1.597,0.57-3.087,0.927-4.508c0.357-1.421,0.731-2.773,1.026-4.069c0.298-1.251,0.372-2.52,0.468-3.757
						c0.089-1.234,0.18-2.431,0.389-3.563c0.201-1.127,0.538-2.208,1.133-3.088c0.586-0.849,1.222-1.502,1.746-2.151
						c0.531-0.639,0.945-1.266,1.231-1.879c0.29-0.609,0.427-1.204,0.563-1.721c0.132-0.518,0.266-0.957,0.42-1.293
						c0.15-0.337,0.311-0.57,0.425-0.718c0.122-0.142,0.185-0.215,0.185-0.215s-0.056,0.078-0.164,0.23
						c-0.1,0.157-0.237,0.399-0.356,0.74c-0.123,0.339-0.218,0.776-0.307,1.3c-0.094,0.521-0.186,1.145-0.443,1.813
						c-0.249,0.664-0.642,1.363-1.14,2.058c-0.487,0.697-1.083,1.409-1.561,2.216c-0.46,0.787-0.709,1.774-0.831,2.848
						c-0.131,1.074-0.152,2.237-0.171,3.475c-0.026,1.232-0.027,2.57-0.284,3.968c-0.237,1.355-0.548,2.73-0.837,4.148
						c-0.289,1.416-0.567,2.876-0.71,4.36c-0.035,0.371-0.057,0.742-0.071,1.113c-0.006,0.366-0.005,0.738,0.011,1.127
						c0.031,0.767,0.098,1.542,0.208,2.317c0.22,1.553,0.613,3.112,1.215,4.622c0.149,0.378,0.311,0.753,0.489,1.121
						c0.086,0.187,0.178,0.37,0.274,0.552l0.295,0.584c0.421,0.817,0.791,1.666,1.114,2.536c1.289,3.486,1.892,7.288,1.968,11.166
						c0.034,1.941-0.071,3.861-0.316,5.741c-0.069,0.469-0.12,0.938-0.212,1.401c-0.083,0.462-0.165,0.935-0.252,1.363
						c-0.17,0.876-0.322,1.742-0.443,2.6c-0.244,1.714-0.36,3.395-0.265,5.017c0.047,0.813,0.147,1.602,0.301,2.383
						c0.161,0.826,0.319,1.643,0.477,2.449c0.302,1.62,0.569,3.214,0.755,4.78c0.093,0.782,0.17,1.558,0.22,2.324
						c0.023,0.384,0.045,0.765,0.056,1.144c0.008,0.186,0.013,0.378,0.011,0.588c-0.002,0.202-0.01,0.401-0.026,0.595
						c-0.124,1.544-0.475,2.891-0.386,4.09c0.006,0.15,0.031,0.292,0.05,0.435c0.037,0.131,0.05,0.268,0.102,0.395
						c0.044,0.13,0.088,0.26,0.153,0.384c0.053,0.127,0.128,0.248,0.2,0.369c0.293,0.484,0.701,0.92,1.129,1.325
						c0.861,0.805,1.844,1.526,2.662,2.292c0.803,0.713,1.659,1.264,2.527,1.638c0.868,0.374,1.738,0.581,2.555,0.674
						c0.802,0.112,1.531-0.025,2.147-0.285c0.616-0.266,1.128-0.609,1.587-0.884c0.458-0.279,0.867-0.487,1.21-0.62
						c0.17-0.072,0.324-0.121,0.458-0.158c0.132-0.044,0.246-0.07,0.339-0.086C222.755,324.765,222.849,324.745,222.849,324.745z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M221.974,376.206c0,0-0.093-0.06-0.273-0.175c-0.044-0.031-0.099-0.061-0.151-0.103
						c-0.049-0.046-0.103-0.097-0.161-0.151c-0.058-0.056-0.123-0.113-0.189-0.181c-0.057-0.074-0.117-0.153-0.182-0.237
						c-0.537-0.673-0.933-1.902-1.169-3.459c-0.123-0.778-0.208-1.647-0.342-2.561c-0.033-0.229-0.075-0.457-0.121-0.685
						c-0.026-0.111-0.052-0.224-0.078-0.337c-0.033-0.115-0.066-0.232-0.1-0.35c-0.144-0.475-0.31-0.969-0.49-1.476
						c-0.363-1.015-0.787-2.088-1.282-3.184c-0.254-0.542-0.517-1.102-0.824-1.625c-0.072-0.138-0.157-0.255-0.235-0.385
						l-0.06-0.096l-0.03-0.048l-0.062-0.075l-0.249-0.302c-0.132-0.192-0.415-0.43-0.667-0.661c-0.132-0.122-0.246-0.22-0.364-0.322
						c-0.109-0.101-0.258-0.209-0.328-0.305c0.253-0.111,0.525-0.23,0.813-0.357c0.29-0.138,0.61-0.249,0.834-0.422
						c0.06-0.041,0.114-0.088,0.17-0.133l0.152-0.128c0.062-0.055,0.125-0.109,0.188-0.165c0.134-0.116,0.281-0.242,0.475-0.377
						l0.217,1.638c-0.37-0.113-0.667-0.286-0.914-0.406c-0.247-0.125-0.437-0.187-0.546-0.186l-0.9,0.008l0.021-0.986
						c0.006-0.287,0.092-0.624,0.277-0.874c0.086-0.13,0.184-0.226,0.284-0.317c0.093-0.075,0.201-0.16,0.289-0.206
						c0.378-0.218,0.699-0.296,1.005-0.364c0.609-0.122,1.168-0.146,1.732-0.157c0.562-0.008,1.123,0.009,1.677,0.028
						c0.271,0.008,0.543,0.017,0.817,0.024l0.38,0.002c0.11-0.003,0.236-0.011,0.259-0.018l-0.157,2.106
						c-0.686-0.206-1.502-0.349-2.419-0.527c-0.459-0.091-0.946-0.192-1.459-0.35c-0.508-0.145-1.053-0.374-1.574-0.694
						c-0.153-0.107-0.312-0.2-0.456-0.324c-0.143-0.126-0.286-0.252-0.422-0.383c-0.124-0.145-0.246-0.292-0.365-0.439
						c-0.116-0.149-0.201-0.314-0.299-0.473c-0.103-0.159-0.17-0.323-0.238-0.488c-0.063-0.165-0.15-0.331-0.188-0.495
						c-0.091-0.327-0.187-0.663-0.23-0.963c-0.212-1.231-0.198-2.396-0.216-3.457c-0.016-1.063-0.06-2.043-0.287-2.918
						c-0.05-0.226-0.122-0.426-0.196-0.648c-0.082-0.223-0.167-0.443-0.262-0.662c-0.188-0.438-0.394-0.868-0.618-1.299
						c-0.443-0.859-0.933-1.717-1.348-2.615c-0.421-0.893-0.749-1.834-0.944-2.765c-0.358-1.757-0.604-3.418-0.741-4.938
						c-0.07-0.76-0.115-1.483-0.144-2.168c-0.018-0.333-0.023-0.703-0.007-1.022c0.012-0.325,0.032-0.637,0.051-0.937
						c0.081-1.197,0.152-2.219,0.163-3.055c0.014-0.836-0.032-1.484-0.14-1.908c-0.052-0.212-0.122-0.366-0.188-0.456
						c-0.063-0.094-0.114-0.126-0.111-0.129c-0.003,0.003,0.053,0.028,0.131,0.113c0.081,0.083,0.176,0.232,0.259,0.441
						c0.171,0.42,0.307,1.076,0.398,1.921c0.095,0.846,0.146,1.885,0.197,3.079c0.031,0.603,0.046,1.222,0.154,1.868
						c0.096,0.666,0.208,1.367,0.346,2.099c0.27,1.464,0.643,3.046,1.119,4.73c0.23,0.787,0.56,1.567,0.995,2.355
						c0.427,0.79,0.948,1.592,1.465,2.476c0.259,0.44,0.513,0.906,0.75,1.395c0.12,0.244,0.231,0.496,0.338,0.754
						c0.102,0.262,0.215,0.547,0.291,0.828c0.342,1.135,0.42,2.299,0.47,3.396c0.046,1.103,0.067,2.153,0.252,3.127
						c0.041,0.255,0.111,0.455,0.169,0.671c0.023,0.113,0.081,0.194,0.116,0.294c0.04,0.097,0.077,0.195,0.135,0.272
						c0.189,0.351,0.445,0.611,0.796,0.828c0.582,0.369,1.413,0.582,2.283,0.762c0.87,0.183,1.777,0.354,2.629,0.634l4.452,1.467
						l-4.608,0.64c-0.258,0.036-0.415,0.031-0.587,0.034c-0.163-0.002-0.316-0.007-0.464-0.014c-0.286-0.017-0.57-0.034-0.852-0.051
						c-0.55-0.036-1.076-0.068-1.576-0.08c-0.496-0.012-0.978-0.002-1.356,0.058c-0.185,0.026-0.346,0.078-0.39,0.099
						c-0.007,0.002-0.015,0.004-0.009,0.003c0.008,0.01-0.018-0.027-0.034-0.048c-0.018-0.023-0.037-0.046-0.057-0.067
						c-0.04-0.043-0.049-0.063-0.081-0.091c-0.028-0.027-0.068-0.054-0.072-0.059c-0.013-0.012-0.029-0.021-0.048-0.029
						c-0.002-0.001,0.006,0.001,0.006,0.001s0-0.001,0.001-0.001c0.012,0.004,0.024,0.007,0.036,0.009
						c0.023,0.004,0.044,0.004,0.063,0.002l-0.586-0.974c0.55,0.061,0.965,0.286,1.255,0.447c0.292,0.169,0.493,0.287,0.599,0.328
						l1.796,0.694l-1.58,0.943c-0.091,0.054-0.208,0.141-0.332,0.236c-0.065,0.051-0.129,0.101-0.192,0.15
						c-0.044,0.033-0.037,0.024-0.059,0.038c-0.059,0.034-0.123,0.068-0.193,0.089c-0.039,0.004,0.003,0.021,0.007,0.033
						c0.012,0.013,0.025,0.025,0.034,0.042c0.021,0.033,0.037,0.055,0.083,0.135l0.059,0.102l0.111,0.202
						c0.082,0.157,0.175,0.313,0.244,0.469c0.308,0.619,0.539,1.226,0.766,1.817c0.432,1.187,0.774,2.318,1.056,3.393
						c0.14,0.537,0.264,1.061,0.368,1.574c0.024,0.13,0.048,0.259,0.072,0.387c0.018,0.133,0.035,0.265,0.052,0.395
						c0.027,0.257,0.047,0.507,0.058,0.747c0.044,0.962,0.035,1.818,0.071,2.588c0.062,1.532,0.29,2.726,0.709,3.425
						C221.568,375.973,221.994,376.19,221.974,376.206z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M232.046,314.673c0,0,0.179,0.219,0.52,0.636c0.334,0.422,0.866,1.017,1.392,1.925
						c0.25,0.453,0.521,1.013,0.542,1.704c0.036,0.675-0.164,1.395-0.397,2.078c-0.112,0.345-0.225,0.69-0.28,1.029
						c-0.03,0.17-0.036,0.336-0.032,0.499c0.01,0.15,0.05,0.344,0.087,0.534c0.152,0.759,0.449,1.541,0.82,2.348
						c0.37,0.81,0.818,1.643,1.273,2.544c0.453,0.899,0.922,1.876,1.238,2.996c0.598,2.167,0.81,4.557,0.828,7.005
						c0.03,2.451-0.104,4.983-0.001,7.534c0.019,0.317,0.029,0.639,0.054,0.956c0.027,0.319,0.054,0.641,0.082,0.963
						c0.061,0.667,0.122,1.34,0.183,2.018c0.121,1.334,0.268,2.653,0.578,3.905c0.155,0.625,0.35,1.225,0.596,1.785
						c0.29,0.663,0.56,1.382,0.733,2.128c0.181,0.746,0.245,1.526,0.243,2.252c-0.003,0.729-0.06,1.405-0.102,2.053
						c-0.044,0.646-0.078,1.276-0.087,1.892c-0.009,0.619,0.004,1.247,0.018,1.875c0.03,1.258,0.065,2.52,0.01,3.771
						c-0.054,1.249-0.202,2.501-0.567,3.701c-0.359,1.193-0.981,2.344-1.88,3.185c-0.443,0.421-0.934,0.768-1.443,1.041
						c-0.515,0.26-1.031,0.473-1.558,0.612c-1.024,0.271-1.983,0.41-2.91,0.524c-1.849,0.211-3.534,0.247-5.011,0.129
						c-1.473-0.12-2.746-0.4-3.697-0.869c-0.473-0.237-0.856-0.527-1.129-0.831c-0.285-0.297-0.415-0.629-0.511-0.883
						c-0.068-0.267-0.101-0.471-0.096-0.611c-0.005-0.138-0.008-0.208-0.008-0.208s0.011,0.069,0.033,0.206
						c0.011,0.137,0.068,0.334,0.161,0.58c0.121,0.233,0.274,0.525,0.568,0.77c0.282,0.252,0.66,0.48,1.122,0.656
						c0.929,0.345,2.16,0.495,3.583,0.481c1.424-0.015,3.052-0.189,4.813-0.527c0.875-0.175,1.803-0.385,2.69-0.688
						c0.43-0.145,0.845-0.354,1.241-0.586c0.39-0.244,0.748-0.536,1.064-0.875c0.637-0.675,1.064-1.567,1.312-2.573
						c0.25-1.007,0.336-2.118,0.334-3.28c0.001-1.163-0.079-2.377-0.152-3.646c-0.036-0.635-0.07-1.283-0.08-1.954
						c-0.01-0.676,0.009-1.348,0.04-2.013c0.029-0.665,0.067-1.313,0.058-1.92c-0.01-0.609-0.067-1.181-0.213-1.753
						c-0.142-0.576-0.356-1.142-0.62-1.737c-0.312-0.698-0.547-1.422-0.728-2.149c-0.36-1.457-0.501-2.888-0.611-4.256
						c-0.051-0.675-0.102-1.345-0.152-2.01c-0.022-0.344-0.045-0.686-0.068-1.028c-0.02-0.343-0.023-0.683-0.035-1.021
						c-0.044-2.705,0.181-5.239,0.253-7.618c0.087-2.378,0-4.604-0.424-6.617c-0.208-0.966-0.576-1.9-0.958-2.809
						c-0.382-0.907-0.779-1.793-1.103-2.675c-0.326-0.879-0.583-1.759-0.684-2.629c-0.023-0.217-0.052-0.424-0.047-0.674
						c0.012-0.235,0.044-0.463,0.1-0.671c0.108-0.419,0.266-0.778,0.408-1.112c0.294-0.662,0.514-1.254,0.541-1.831
						c0.042-0.562-0.141-1.091-0.335-1.545c-0.409-0.913-0.873-1.57-1.156-2.024C232.199,314.911,232.046,314.673,232.046,314.673z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M242.996,268.249c0,0-0.007,0.175-0.02,0.51c-0.016,0.335-0.06,0.83-0.153,1.463
						c-0.097,0.632-0.245,1.403-0.453,2.285c-0.206,0.882-0.47,1.876-0.734,2.967c-0.258,1.09-0.532,2.279-0.656,3.541
						c-0.015,0.157-0.029,0.316-0.044,0.476c-0.012,0.169-0.024,0.338-0.036,0.509c-0.024,0.343-0.049,0.691-0.075,1.044
						c-0.053,0.706-0.107,1.434-0.163,2.18c-0.237,2.983-0.54,6.281-1.143,9.728c-0.302,1.723-0.669,3.397-1.107,4.998
						c-0.115,0.398-0.229,0.794-0.341,1.186c-0.112,0.38-0.219,0.735-0.315,1.103c-0.194,0.729-0.357,1.452-0.496,2.167
						c-0.277,1.43-0.467,2.82-0.638,4.134c-0.085,0.658-0.167,1.295-0.246,1.906c-0.072,0.59-0.123,1.161-0.146,1.718
						c-0.057,1.108-0.045,2.17-0.249,3.105c-0.181,0.94-0.602,1.675-0.991,2.19c-0.209,0.253-0.386,0.478-0.568,0.646
						c-0.173,0.177-0.321,0.322-0.453,0.428c-0.255,0.217-0.389,0.331-0.389,0.331s0.11-0.137,0.319-0.397
						c0.109-0.126,0.227-0.294,0.363-0.494c0.146-0.191,0.278-0.437,0.433-0.703c0.283-0.547,0.547-1.248,0.584-2.1
						c0.06-0.849-0.083-1.842-0.169-2.992c-0.046-0.574-0.065-1.188-0.059-1.831c0.015-0.619,0.03-1.264,0.045-1.931
						c0.041-1.338,0.111-2.78,0.297-4.306c0.093-0.763,0.217-1.546,0.383-2.341c0.081-0.396,0.182-0.804,0.277-1.195
						c0.092-0.38,0.186-0.765,0.28-1.152c0.363-1.555,0.684-3.181,0.979-4.857c0.586-3.355,1.026-6.598,1.471-9.555
						c0.113-0.74,0.224-1.462,0.332-2.163c0.057-0.351,0.112-0.696,0.167-1.036c0.028-0.171,0.056-0.34,0.083-0.507
						c0.034-0.175,0.066-0.349,0.099-0.521c0.275-1.37,0.702-2.566,1.1-3.64c0.405-1.07,0.8-2.014,1.128-2.848
						c0.33-0.832,0.591-1.555,0.788-2.153c0.193-0.599,0.322-1.072,0.399-1.397C242.956,268.42,242.996,268.249,242.996,268.249z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M267.248,254.335c0,0-0.262,0.322-0.755,0.893c-0.249,0.283-0.565,0.619-0.932,1.011
						c-0.378,0.378-0.804,0.818-1.304,1.271c-0.99,0.92-2.232,1.954-3.696,2.984c-1.461,1.033-3.15,2.05-4.97,2.989
						c-1.819,0.94-3.591,1.716-5.177,2.427c-1.588,0.709-2.989,1.365-4.119,2.007c-1.133,0.635-1.984,1.267-2.551,1.742
						c-0.141,0.121-0.266,0.228-0.374,0.321c-0.104,0.1-0.192,0.184-0.263,0.251c-0.145,0.136-0.22,0.207-0.22,0.207
						s0.049-0.092,0.144-0.266c0.048-0.087,0.108-0.195,0.179-0.323c0.08-0.122,0.171-0.264,0.274-0.424
						c0.423-0.63,1.124-1.51,2.159-2.417c1.028-0.915,2.367-1.858,3.896-2.772c1.529-0.918,3.241-1.82,5.025-2.742
						c1.785-0.922,3.479-1.781,5.002-2.606c1.525-0.82,2.885-1.595,4.02-2.273c0.572-0.332,1.075-0.656,1.53-0.927
						c0.448-0.283,0.834-0.526,1.149-0.726C266.891,254.554,267.248,254.335,267.248,254.335z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M255.697,339.417c0,0-0.041-0.073-0.124-0.219c-0.077-0.149-0.19-0.378-0.278-0.707
						c-0.081-0.325-0.16-0.768-0.013-1.284c0.067-0.259,0.216-0.525,0.438-0.754c0.173-0.18,0.387-0.316,0.624-0.395
						c0.064-0.023,0.131-0.036,0.197-0.055l0.049-0.013c0.019-0.004-0.021-0.026-0.029-0.038l-0.092-0.086
						c-0.122-0.114-0.243-0.231-0.374-0.335c-0.263-0.228-0.481-0.37-0.903-0.665c-0.769-0.523-1.714-0.999-2.668-1.763
						c-0.471-0.382-0.939-0.84-1.328-1.386c-0.39-0.542-0.729-1.154-1.003-1.793c-0.278-0.644-0.502-1.382-0.571-2.175
						c-0.072-0.795,0.03-1.581,0.114-2.305c0.088-0.731,0.161-1.446,0.18-2.186c0.015-0.753,0.022-1.521-0.012-2.309
						c-0.053-1.572-0.211-3.208-0.417-4.905c-0.203-1.696-0.456-3.455-0.663-5.283c-0.049-0.456-0.099-0.915-0.148-1.376
						c-0.053-0.432-0.1-0.866-0.18-1.304c-0.143-0.874-0.341-1.758-0.573-2.655c-0.636-2.49-1.464-4.977-1.865-7.547
						c-0.205-1.274-0.287-2.592-0.237-3.836c0.043-1.243,0.18-2.442,0.344-3.588c0.335-2.291,0.731-4.38,0.8-6.338
						c0.043-0.979,0.008-1.921-0.102-2.82c-0.043-0.439-0.12-0.926-0.169-1.373c-0.049-0.451-0.098-0.893-0.145-1.324
						c-0.174-1.713-0.382-3.227-0.756-4.517c-0.175-0.651-0.412-1.201-0.664-1.739c-0.25-0.532-0.47-1.047-0.595-1.529
						c-0.128-0.479-0.186-0.926-0.166-1.311c0.02-0.383,0.091-0.695,0.144-0.943c0.055-0.248,0.085-0.438,0.072-0.565
						c-0.014-0.129-0.057-0.186-0.053-0.187c-0.004,0.002,0.047,0.054,0.078,0.183c0.03,0.13,0.023,0.331-0.001,0.584
						c-0.023,0.254-0.056,0.566-0.033,0.927c0.023,0.359,0.123,0.766,0.293,1.204c0.166,0.439,0.419,0.893,0.721,1.396
						c0.301,0.497,0.631,1.097,0.876,1.753c0.52,1.308,0.879,2.88,1.193,4.581c0.081,0.423,0.163,0.855,0.247,1.298
						c0.084,0.445,0.185,0.866,0.272,1.359c0.19,0.961,0.298,1.98,0.32,3.032c0.061,2.108-0.234,4.317-0.455,6.554
						c-0.108,1.12-0.189,2.253-0.188,3.399c-0.003,1.145,0.102,2.271,0.318,3.443c0.42,2.337,1.251,4.741,1.921,7.351
						c0.243,0.938,0.453,1.891,0.601,2.854c0.083,0.479,0.131,0.966,0.182,1.449c0.042,0.454,0.083,0.907,0.124,1.356
						c0.17,1.794,0.378,3.563,0.525,5.302c0.149,1.737,0.243,3.443,0.218,5.095c-0.005,0.825-0.056,1.637-0.116,2.428
						c-0.065,0.806-0.194,1.592-0.329,2.32c-0.134,0.733-0.255,1.384-0.242,1.991c0.011,0.608,0.138,1.206,0.339,1.792
						c0.204,0.594,0.45,1.129,0.743,1.618c0.291,0.49,0.651,0.907,1.039,1.283c0.777,0.748,1.68,1.323,2.431,1.946
						c0.191,0.167,0.351,0.289,0.575,0.507c0.103,0.097,0.204,0.191,0.303,0.285c0.089,0.096,0.177,0.191,0.264,0.284
						c0.174,0.181,0.328,0.367,0.475,0.541l0.108,0.128c0.01,0.018,0.053,0.049,0.035,0.057l-0.048,0.026
						c-0.062,0.039-0.135,0.06-0.191,0.108c-0.484,0.107-0.916,0.251-1.176,0.452c-0.199,0.168-0.348,0.373-0.429,0.591
						c-0.176,0.434-0.155,0.857-0.111,1.177c0.047,0.325,0.132,0.563,0.189,0.718C255.665,339.34,255.697,339.417,255.697,339.417z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M248.799,399.199c0,0,0.1-0.232,0.29-0.676c0.184-0.443,0.462-1.1,0.708-1.967
						c0.236-0.865,0.405-1.962,0.17-3.179c-0.11-0.607-0.33-1.231-0.594-1.914c-0.262-0.678-0.566-1.392-0.876-2.153
						c-0.308-0.764-0.629-1.572-0.903-2.457c-0.137-0.442-0.259-0.904-0.359-1.389c-0.043-0.245-0.094-0.49-0.119-0.748
						c-0.013-0.13-0.032-0.253-0.039-0.391c-0.003-0.144-0.006-0.287-0.008-0.432c0.053-1.122,0.366-2.139,0.734-3.143
						c0.374-1.003,0.83-1.987,1.323-2.971c0.124-0.244,0.249-0.489,0.375-0.736c0.068-0.128,0.119-0.236,0.169-0.342
						c0.053-0.107,0.104-0.217,0.145-0.328c0.182-0.442,0.311-0.904,0.386-1.387c0.153-0.965,0.083-2.02-0.071-3.141
						c-0.323-2.27-0.804-4.669-1.126-7.191c-0.325-2.522-0.414-5.09-0.067-7.534c0.085-0.621,0.185-1.184,0.288-1.764
						c0.103-0.576,0.214-1.15,0.339-1.72c0.252-1.138,0.561-2.259,0.985-3.326c0.107-0.267,0.218-0.529,0.339-0.788l0.16-0.35
						c0.051-0.109,0.1-0.22,0.143-0.332c0.181-0.445,0.331-0.901,0.448-1.364c0.237-0.927,0.366-1.872,0.447-2.805
						c0.083-0.936,0.116-1.849,0.165-2.751c0.049-0.898,0.111-1.756,0.171-2.571c0.06-0.814,0.117-1.591,0.17-2.324
						c0.024-0.366,0.047-0.723,0.07-1.067c0.025-0.348,0.029-0.669,0.071-1.027c0.088-0.682,0.243-1.254,0.29-1.783
						c0.055-0.526-0.001-0.996-0.203-1.36c-0.203-0.369-0.504-0.647-0.756-0.885c-0.257-0.237-0.484-0.436-0.656-0.607
						c-0.173-0.17-0.29-0.316-0.366-0.413c-0.07-0.103-0.106-0.154-0.106-0.154s0.043,0.047,0.126,0.138
						c0.089,0.086,0.22,0.213,0.411,0.357c0.189,0.146,0.435,0.313,0.723,0.52c0.283,0.212,0.629,0.453,0.921,0.858
						c0.297,0.41,0.425,0.986,0.425,1.567c0.009,0.584-0.088,1.2-0.103,1.813c-0.005,0.289,0.022,0.641,0.031,0.986
						c0.01,0.349,0.021,0.708,0.032,1.078c0.012,0.738,0.025,1.519,0.038,2.338c0.013,0.815,0.025,1.669,0.039,2.559
						c0.02,0.881,0.056,1.825,0.035,2.81c-0.018,0.986-0.089,2.026-0.297,3.1c-0.102,0.536-0.247,1.079-0.433,1.62
						c-0.044,0.137-0.094,0.271-0.149,0.405l-0.151,0.384c-0.094,0.23-0.18,0.467-0.263,0.706c-0.329,0.96-0.568,1.992-0.764,3.071
						c-0.098,0.54-0.185,1.092-0.268,1.655c-0.081,0.56-0.167,1.148-0.226,1.694c-0.256,2.228-0.148,4.565,0.163,6.987
						c0.309,2.428,0.753,4.814,1.032,7.211c0.133,1.207,0.195,2.489-0.055,3.758c-0.123,0.631-0.323,1.247-0.587,1.817
						c-0.063,0.145-0.134,0.284-0.209,0.42c-0.074,0.14-0.147,0.275-0.213,0.386c-0.134,0.235-0.267,0.468-0.399,0.7
						c-0.522,0.924-1.002,1.832-1.397,2.728c-0.393,0.891-0.709,1.787-0.8,2.588l-0.015,0.286c-0.002,0.1,0.007,0.213,0.01,0.318
						c0.005,0.214,0.035,0.431,0.056,0.645c0.057,0.43,0.135,0.857,0.231,1.276c0.189,0.839,0.436,1.648,0.674,2.425
						c0.24,0.777,0.481,1.522,0.685,2.241c0.203,0.713,0.38,1.427,0.434,2.109c0.121,1.37-0.198,2.527-0.541,3.384
						c-0.355,0.861-0.714,1.479-0.953,1.898C248.928,398.981,248.799,399.199,248.799,399.199z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M266.7,382.118c0,0,0.336,0.159,0.933,0.463c0.593,0.313,1.466,0.745,2.45,1.534
						c0.486,0.399,0.999,0.904,1.43,1.578c0.429,0.674,0.763,1.51,0.827,2.464c0.082,0.953-0.145,1.903-0.366,2.729
						c-0.228,0.83-0.417,1.558-0.373,2.191c0.035,0.649,0.303,1.315,0.649,2.099c0.17,0.393,0.357,0.835,0.481,1.325
						c0.126,0.49,0.173,1.027,0.12,1.533c-0.07,0.501-0.206,0.965-0.398,1.356c-0.18,0.397-0.384,0.72-0.592,1.036
						c-0.421,0.62-0.855,1.125-1.241,1.574c-0.782,0.885-1.341,1.612-1.661,2.188c-0.161,0.283-0.276,0.515-0.342,0.676
						c-0.071,0.161-0.109,0.246-0.109,0.246s0.005-0.093,0.015-0.27c0.008-0.178,0.04-0.439,0.109-0.776
						c0.127-0.671,0.496-1.631,1.06-2.689c0.273-0.531,0.575-1.094,0.831-1.689c0.123-0.294,0.25-0.611,0.325-0.909
						c0.09-0.294,0.114-0.583,0.119-0.859c-0.023-0.547-0.247-1.072-0.65-1.822c-0.385-0.739-0.879-1.712-0.949-2.88
						c-0.035-0.581,0.04-1.138,0.155-1.629c0.116-0.493,0.266-0.931,0.404-1.329c0.275-0.792,0.495-1.45,0.525-2.068
						c0.098-1.25-0.49-2.411-1.169-3.293c-0.684-0.89-1.392-1.558-1.855-2.033C266.955,382.391,266.7,382.118,266.7,382.118z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M274.255,321.516c0,0-0.026,0.052-0.076,0.153c-0.021,0.051-0.026,0.123-0.037,0.201
						c0.011,0.081,0.016,0.173,0.063,0.271c0.165,0.397,0.672,0.821,1.36,1.29c0.175,0.119,0.359,0.245,0.553,0.377
						c0.198,0.134,0.403,0.299,0.599,0.456c0.202,0.161,0.413,0.333,0.629,0.532c0.214,0.199,0.438,0.44,0.611,0.732
						c0.353,0.592,0.506,1.254,0.67,1.923c0.158,0.677,0.29,1.396,0.404,2.154c0.226,1.516,0.372,3.183,0.484,4.961
						c0.115,1.777,0.195,3.657,0.433,5.584c0.062,0.502,0.112,1.085,0.098,1.644c-0.012,0.565-0.075,1.137-0.178,1.696
						c-0.207,1.123-0.566,2.174-0.877,3.189c-0.633,1.954-0.893,4.105-1.118,6.37c-0.03,0.293-0.059,0.586-0.088,0.878
						c-0.037,0.301-0.079,0.598-0.126,0.892c-0.095,0.586-0.208,1.154-0.324,1.708c-0.232,1.107-0.466,2.156-0.611,3.173
						c-0.034,0.254-0.066,0.506-0.09,0.756c-0.033,0.281-0.066,0.562-0.099,0.84c-0.082,0.563-0.185,1.112-0.311,1.644
						c-0.25,1.063-0.565,2.063-0.881,3.019c-0.641,1.908-1.261,3.651-1.607,5.318c-0.169,0.831-0.262,1.64-0.294,2.417
						c-0.02,0.764,0.064,1.521,0.156,2.27c0.09,0.747,0.18,1.488,0.174,2.205c-0.003,0.716-0.098,1.398-0.277,2.006
						c-0.353,1.183-0.796,2.135-1.203,2.888c-0.408,0.751-0.79,1.293-1.059,1.649c-0.272,0.354-0.429,0.528-0.429,0.528
						s0.129-0.196,0.348-0.581c0.216-0.387,0.518-0.967,0.822-1.745c0.302-0.775,0.621-1.755,0.83-2.901
						c0.099-0.559,0.119-1.163,0.057-1.804c-0.061-0.643-0.21-1.321-0.369-2.061c-0.16-0.738-0.327-1.546-0.379-2.43
						c-0.039-0.869-0.006-1.772,0.112-2.691c0.241-1.836,0.78-3.714,1.308-5.615c0.26-0.953,0.514-1.918,0.701-2.896
						c0.096-0.489,0.168-0.98,0.222-1.474c0.021-0.251,0.043-0.503,0.064-0.757c0.018-0.286,0.046-0.57,0.076-0.855
						c0.129-1.137,0.356-2.25,0.567-3.339c0.105-0.544,0.206-1.084,0.289-1.62c0.041-0.269,0.077-0.535,0.109-0.803
						c0.027-0.276,0.055-0.555,0.082-0.833c0.112-1.16,0.251-2.315,0.453-3.458c0.101-0.571,0.218-1.14,0.359-1.704
						c0.139-0.563,0.319-1.139,0.496-1.652c0.355-1.048,0.716-2.01,0.924-2.936c0.105-0.463,0.175-0.919,0.207-1.373
						c0.031-0.464,0.018-0.89-0.016-1.38c-0.144-2.044-0.098-3.958-0.083-5.725c0.019-1.766,0.013-3.402-0.068-4.888
						c-0.042-0.741-0.101-1.446-0.186-2.105c-0.089-0.66-0.179-1.277-0.397-1.747c-0.213-0.469-0.611-0.852-0.966-1.218
						c-0.185-0.187-0.344-0.352-0.518-0.503c-0.176-0.154-0.344-0.302-0.502-0.44c-0.634-0.561-1.113-1.075-1.235-1.536
						c-0.037-0.111-0.029-0.217-0.027-0.305c0.023-0.084,0.04-0.157,0.069-0.206C274.222,321.565,274.255,321.516,274.255,321.516z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M273.762,322.173c-0.012-0.019,0.3,0.035,0.677-0.284c0.375-0.315,0.662-1.008,0.806-1.924
						c0.151-0.92,0.186-2.07,0.139-3.403c-0.012-0.334-0.03-0.679-0.051-1.036c-0.011-0.179-0.022-0.361-0.034-0.546
						c-0.005-0.093-0.011-0.186-0.018-0.281c-0.008-0.082-0.017-0.166-0.025-0.25c-0.046-0.333-0.151-0.644-0.312-0.975
						c-0.156-0.332-0.396-0.707-0.546-1.203c-0.163-0.486-0.158-1.054-0.099-1.529c0.057-0.473,0.145-0.925,0.212-1.366
						c0.067-0.44,0.113-0.87,0.093-1.273c-0.012-0.201-0.04-0.389-0.086-0.578c-0.056-0.224-0.119-0.448-0.188-0.68
						c-0.136-0.46-0.305-0.962-0.425-1.52c-0.124-0.554-0.179-1.161-0.181-1.727c-0.013-1.137,0.132-2.269,0.239-3.359
						c0.359-4.508,1.405-9.555,3.565-14.408c0.134-0.304,0.272-0.605,0.413-0.904c0.139-0.299,0.265-0.561,0.388-0.846
						c0.247-0.561,0.474-1.127,0.684-1.698c0.421-1.144,0.778-2.308,1.128-3.472c0.087-0.292,0.175-0.582,0.262-0.872
						c0.083-0.285,0.184-0.591,0.25-0.852c0.156-0.535,0.276-1.08,0.377-1.627c0.198-1.096,0.301-2.203,0.363-3.302
						c0.126-2.196,0.119-4.348,0.322-6.383c0.103-1.017,0.234-2.003,0.46-2.94c0.096-0.468,0.244-0.948,0.392-1.371
						c0.148-0.43,0.294-0.817,0.388-1.191c0.396-1.468-0.085-2.988-0.168-4.377c-0.083-1.359-0.111-2.525-0.082-3.483
						c0.025-0.957,0.115-1.698,0.216-2.192c0.052-0.247,0.098-0.433,0.142-0.554c0.04-0.122,0.061-0.184,0.061-0.184
						s-0.013,0.064-0.037,0.19c-0.027,0.125-0.05,0.314-0.071,0.562c-0.039,0.496-0.04,1.229,0.039,2.169
						c0.076,0.938,0.225,2.09,0.435,3.409c0.107,0.643,0.299,1.326,0.459,2.099c0.078,0.388,0.147,0.793,0.173,1.23
						c0.03,0.438,0.009,0.903-0.071,1.36c-0.077,0.458-0.204,0.896-0.312,1.316c-0.111,0.426-0.202,0.823-0.26,1.268
						c-0.144,0.879-0.201,1.815-0.236,2.787c-0.068,1.947,0.061,4.063,0.038,6.35c-0.011,1.143-0.07,2.334-0.245,3.558
						c-0.09,0.611-0.204,1.23-0.364,1.852c-0.074,0.322-0.155,0.591-0.233,0.888c-0.079,0.292-0.159,0.586-0.239,0.881
						c-0.323,1.183-0.671,2.402-1.104,3.631c-0.217,0.614-0.456,1.23-0.721,1.841c-0.128,0.303-0.278,0.62-0.409,0.904
						c-0.129,0.285-0.259,0.571-0.39,0.857c-1.021,2.31-1.825,4.639-2.427,6.949c-0.616,2.315-1.02,4.56-1.31,6.781
						c-0.167,1.134-0.351,2.171-0.395,3.162c-0.024,0.496-0.011,0.939,0.061,1.398c0.068,0.456,0.192,0.927,0.307,1.433
						c0.057,0.251,0.11,0.507,0.156,0.763c0.051,0.291,0.071,0.589,0.066,0.868c-0.014,0.562-0.112,1.069-0.219,1.537
						c-0.107,0.468-0.226,0.9-0.307,1.306c-0.077,0.397-0.106,0.72-0.028,1.052c0.063,0.329,0.239,0.681,0.396,1.092
						c0.159,0.409,0.27,0.863,0.287,1.294c0.001,0.106,0.002,0.212,0.002,0.317c-0.002,0.095-0.005,0.188-0.008,0.28
						c-0.006,0.186-0.012,0.369-0.017,0.55c-0.012,0.362-0.028,0.715-0.049,1.055c-0.084,1.361-0.243,2.535-0.505,3.47
						c-0.254,0.931-0.644,1.636-1.089,1.925C274.051,322.254,273.752,322.15,273.762,322.173z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M237.959,268.468c0,0-0.337-0.028-0.946-0.078c-0.609-0.053-1.488-0.143-2.571-0.287
						c-2.165-0.287-5.154-0.802-8.358-1.785c-1.599-0.498-3.092-1.072-4.421-1.659c-0.66-0.307-1.288-0.591-1.857-0.898
						c-0.286-0.15-0.564-0.291-0.826-0.438c-0.259-0.15-0.508-0.294-0.746-0.432c-0.478-0.27-0.89-0.547-1.256-0.783
						c-0.367-0.232-0.673-0.449-0.919-0.632c-0.492-0.362-0.765-0.563-0.765-0.563s0.305,0.146,0.854,0.412
						c0.272,0.137,0.606,0.299,1.002,0.473c0.394,0.177,0.834,0.39,1.337,0.594c0.249,0.105,0.509,0.217,0.781,0.332
						c0.274,0.109,0.559,0.223,0.854,0.34c0.587,0.243,1.227,0.467,1.893,0.719c1.343,0.479,2.827,0.977,4.398,1.466
						c3.145,0.965,6.035,1.745,8.137,2.305C236.654,268.117,237.959,268.468,237.959,268.468z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M240.587,268.905c0,0-0.08-0.072-0.231-0.209c-0.148-0.14-0.377-0.336-0.672-0.585
						c-0.302-0.243-0.671-0.541-1.1-0.886c-0.425-0.352-0.928-0.761-1.359-1.366c-0.225-0.295-0.393-0.656-0.539-1.039
						c-0.123-0.39-0.213-0.809-0.241-1.239c-0.013-0.216-0.032-0.437-0.02-0.655c0.005-0.221,0.009-0.45,0.022-0.657
						c0.021-0.428,0.037-0.867,0.041-1.318c0.011-0.901-0.016-1.848-0.065-2.834c-0.04-0.993-0.138-1.996-0.135-3.106
						c0.037-2.183,0.427-4.162,0.529-5.949c0.117-1.782-0.032-3.413-0.149-4.79c-0.126-1.378-0.214-2.504-0.235-3.282
						c-0.023-0.778-0.006-1.208-0.006-1.208s0.053,0.427,0.18,1.19c0.126,0.765,0.35,1.858,0.615,3.222
						c0.255,1.362,0.554,3.017,0.539,4.9c-0.003,1.876-0.323,3.882-0.354,5.929c-0.004,1.003,0.077,2.059,0.087,3.058
						c0.019,1.004,0.002,1.981-0.062,2.909c-0.031,0.464-0.076,0.915-0.128,1.353c-0.03,0.224-0.046,0.422-0.066,0.621
						c-0.027,0.2-0.024,0.394-0.028,0.585c-0.007,0.384,0.041,0.743,0.113,1.083c0.095,0.332,0.202,0.65,0.373,0.926
						c0.318,0.564,0.751,1.014,1.128,1.408c0.383,0.392,0.717,0.733,0.981,1.021c0.262,0.288,0.46,0.517,0.586,0.678
						C240.52,268.822,240.587,268.905,240.587,268.905z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M245.624,267.373c0,0-0.145-1.522-0.277-3.987c-0.065-1.232-0.123-2.702-0.132-4.337
						c-0.004-0.817,0.005-1.676,0.034-2.566c0.028-0.886,0.075-1.824,0.183-2.768c0.209-1.891,0.533-3.645,0.727-5.24
						c0.099-0.803,0.177-1.55,0.188-2.257c0.016-0.71-0.002-1.38-0.017-1.996c-0.037-1.231-0.032-2.243,0.032-2.935
						c0.029-0.346,0.063-0.613,0.093-0.793c0.03-0.18,0.045-0.274,0.045-0.274s0.001,0.095,0.003,0.277
						c0,0.181,0.01,0.449,0.033,0.791c0.042,0.687,0.175,1.666,0.351,2.888c0.085,0.612,0.174,1.286,0.224,2.024
						c0.056,0.741,0.036,1.541-0.013,2.359c-0.093,1.649-0.35,3.431-0.554,5.276c-0.105,0.923-0.167,1.816-0.225,2.7
						c-0.055,0.881-0.109,1.729-0.16,2.539c-0.095,1.623-0.181,3.085-0.253,4.314C245.759,265.849,245.624,267.373,245.624,267.373z
						"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M206.894,254.203c0,0-0.094-0.041-0.273-0.118c-0.089-0.04-0.203-0.085-0.333-0.148
						c-0.125-0.072-0.27-0.155-0.433-0.249c-0.161-0.097-0.323-0.231-0.511-0.368c-0.088-0.074-0.173-0.161-0.266-0.248
						c-0.094-0.086-0.188-0.179-0.27-0.29c-0.351-0.414-0.702-0.993-0.756-1.739c0.003-0.185-0.026-0.379,0.024-0.571
						c0.018-0.097,0.036-0.195,0.055-0.294c0.027-0.097,0.07-0.189,0.106-0.286c0.144-0.391,0.415-0.731,0.721-1.041
						c0.158-0.148,0.337-0.272,0.513-0.412c0.186-0.114,0.382-0.221,0.579-0.333c0.203-0.092,0.413-0.173,0.625-0.262
						c0.1-0.049,0.232-0.068,0.35-0.102c0.122-0.025,0.245-0.072,0.369-0.077c1.009-0.126,1.996,0.145,2.905,0.451
						c0.92,0.307,1.819,0.666,2.728,0.827c0.189,0.046,0.371,0.043,0.553,0.069c0.181,0.013,0.355-0.002,0.527,0.002
						c0.078-0.011,0.154-0.022,0.23-0.034c0.001,0.002,0.002,0.003,0.005,0.003c0.004,0.001,0.011,0.001,0.02,0.001
						c0.001-0.004,0.001-0.01-0.002-0.02c-0.002-0.004,0.003,0-0.007-0.014l-0.089-0.125l-0.185-0.247
						c-0.25-0.326-0.509-0.645-0.764-0.96c-0.513-0.627-1.013-1.237-1.459-1.844c-0.891-1.205-1.582-2.433-1.754-3.592
						c-0.09-0.573-0.046-1.109,0.088-1.541c0.133-0.434,0.329-0.767,0.506-1.017c0.185-0.246,0.341-0.421,0.459-0.529
						c0.113-0.11,0.172-0.168,0.172-0.168s-0.049,0.066-0.143,0.193c-0.098,0.123-0.225,0.319-0.367,0.579
						c-0.135,0.264-0.272,0.601-0.342,1.007c-0.069,0.406-0.049,0.88,0.089,1.379c0.268,1.006,1.001,2.071,1.947,3.167
						c0.473,0.551,1,1.117,1.55,1.722c0.274,0.304,0.555,0.618,0.833,0.953l0.208,0.256l0.103,0.133
						c0.055,0.072,0.124,0.163,0.181,0.246c0.229,0.34,0.456,0.689,0.686,1.049c-0.317,0.104-0.648,0.211-0.988,0.322
						c-0.083,0.027-0.168,0.054-0.253,0.082c-0.094,0.016-0.188,0.032-0.283,0.048c-0.145,0.023-0.231,0.039-0.351,0.055
						c-0.223,0.003-0.455,0.021-0.683,0.006c-0.224-0.027-0.461-0.029-0.679-0.081c-1.084-0.195-2.017-0.599-2.891-0.918
						c-0.872-0.33-1.701-0.573-2.447-0.524c-0.096-0.004-0.181,0.029-0.271,0.04c-0.09,0.021-0.176,0.019-0.271,0.059
						c-0.187,0.063-0.371,0.125-0.553,0.187c-0.17,0.081-0.338,0.161-0.503,0.24c-0.145,0.102-0.303,0.19-0.438,0.296
						c-0.538,0.427-0.834,1.016-0.852,1.601c-0.024,0.589,0.209,1.131,0.482,1.545c0.061,0.111,0.135,0.207,0.212,0.297
						c0.075,0.091,0.144,0.183,0.217,0.264c0.159,0.15,0.295,0.302,0.436,0.416c0.144,0.113,0.272,0.214,0.383,0.302
						c0.116,0.079,0.22,0.141,0.301,0.193C206.807,254.148,206.894,254.203,206.894,254.203z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M235.961,263.071c0,0-0.526-0.204-1.463-0.605c-0.469-0.196-1.041-0.444-1.713-0.698
						c-0.672-0.251-1.445-0.519-2.312-0.691c-0.433-0.089-0.89-0.136-1.357-0.142c-0.467,0.011-0.946,0.066-1.402,0.229
						c-0.056,0.015-0.057,0.021-0.079,0.032l-0.052,0.028l-0.048,0.027c-0.017,0.008,0.012,0.034,0.015,0.05
						c0.02,0.038,0.034,0.066,0.058,0.103c0.058,0.103,0.116,0.229,0.191,0.338c0.276,0.452,0.625,0.885,1.024,1.296
						c0.799,0.825,1.794,1.561,2.863,2.278c1.071,0.719,2.228,1.413,3.406,2.163c1.176,0.751,2.324,1.52,3.348,2.412
						c0.129,0.11,0.254,0.224,0.377,0.342l0.184,0.174l0.218,0.223c0.223,0.222,0.409,0.448,0.613,0.673l0.149,0.168l0.075,0.083
						l0.037,0.041c0.005,0.007,0.014,0.015,0.017,0.021l-0.024,0.013l-0.38,0.19c-0.25,0.125-0.499,0.249-0.747,0.373l-0.184,0.092
						l-0.091,0.045c-0.024,0.011-0.041,0.015-0.061,0.022l-0.235,0.079c-0.312,0.104-0.616,0.22-0.933,0.271
						c-1.251,0.277-2.465,0.235-3.552,0.07c-1.093-0.163-2.068-0.461-2.935-0.772c-1.736-0.625-3.047-1.314-3.956-1.777
						c-0.904-0.473-1.397-0.746-1.397-0.746s0.524,0.209,1.47,0.587c0.948,0.366,2.315,0.926,4.051,1.402
						c0.866,0.237,1.826,0.457,2.861,0.546c1.031,0.093,2.142,0.066,3.233-0.229c0.278-0.054,0.539-0.171,0.809-0.271l0.202-0.076
						l0.048-0.017l0.011-0.007l0.022-0.014l0.082-0.051l0.038-0.023c0.001-0.006-0.011-0.011-0.016-0.016
						c-0.011-0.009-0.023-0.018-0.03-0.021l-0.002-0.001l-0.039-0.038l-0.083-0.079l-0.173-0.155
						c-0.114-0.105-0.233-0.207-0.355-0.307c-0.971-0.808-2.09-1.541-3.253-2.282c-1.165-0.741-2.325-1.458-3.411-2.224
						c-1.084-0.768-2.109-1.576-2.949-2.52c-0.418-0.471-0.788-0.975-1.078-1.512c-0.08-0.133-0.134-0.267-0.205-0.413
						c-0.105-0.212-0.178-0.444-0.265-0.66l-0.063-0.164c-0.017-0.053-0.068-0.118,0.02-0.128l0.326-0.133l0.32-0.131
						c0.102-0.04,0.219-0.095,0.296-0.106c0.567-0.162,1.129-0.188,1.654-0.163c0.523,0.042,1.014,0.132,1.465,0.262
						c0.903,0.253,1.667,0.595,2.324,0.91c0.656,0.318,1.202,0.622,1.649,0.866C235.468,262.8,235.961,263.071,235.961,263.071z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M235.961,354.71c0,0-0.028-0.137-0.082-0.402c-0.052-0.266-0.12-0.664-0.191-1.187
						c-0.145-1.046-0.282-2.595-0.444-4.576c-0.174-1.978-0.343-4.402-0.819-7.145c-0.246-1.364-0.565-2.822-1.16-4.24
						c-0.299-0.704-0.674-1.4-1.189-1.975c-0.131-0.141-0.259-0.287-0.412-0.4l-0.221-0.183l-0.243-0.149
						c-0.081-0.051-0.16-0.105-0.25-0.136l-0.288-0.123c-0.375-0.116-0.752-0.192-1.112-0.301c0.159-0.232,0.377-0.518,0.539-0.8
						c0.04-0.067,0.085-0.152,0.111-0.21l0.057-0.123c0.038-0.087,0.076-0.175,0.115-0.265c0.078-0.184,0.16-0.381,0.258-0.588
						l0.078-0.159c0.011-0.021,0.022-0.041,0.035-0.059c-0.003-0.015-0.039-0.023-0.056-0.034l-0.079-0.034
						c-0.198-0.092-0.407-0.178-0.627-0.264c-0.439-0.172-0.914-0.339-1.409-0.548c-0.491-0.214-1.019-0.462-1.495-0.899
						c-0.058-0.057-0.119-0.109-0.174-0.17l-0.157-0.198c-0.054-0.065-0.104-0.134-0.142-0.213l-0.062-0.116
						c-0.028-0.055-0.059-0.108-0.083-0.166c-0.066-0.237-0.117-0.475-0.163-0.717l-0.042-0.368
						c-0.013-0.122-0.032-0.256-0.032-0.35c0.296,0.022,0.646,0.064,0.967,0.067c0.077-0.003,0.155-0.006,0.234-0.009l0.122-0.01
						c0.083-0.008,0.168-0.017,0.254-0.025c0.179-0.021,0.363-0.043,0.551-0.064c0.136-0.016,0.092-0.012,0.111-0.014l0.021-0.004
						l-0.001-0.026l0-0.018l0.003-0.174c0.004-0.231,0.009-0.465,0.014-0.7c0.013-0.471,0.026-0.948,0.039-1.433
						c0.028-0.968,0.069-1.961,0.116-2.974c0.093-2.024,0.218-4.126,0.359-6.26c0.219-3.137,0.432-6.2,0.638-9.159
						c0.092-1.478,0.183-2.93,0.271-4.351c0.081-1.418,0.135-2.818,0.244-4.19c0.212-2.736,0.605-5.335,1.237-7.707
						c0.619-2.374,1.496-4.508,2.521-6.325c1.022-1.801,2.018-3.375,2.747-4.829c0.744-1.446,1.156-2.793,1.379-3.918
						c0.226-1.126,0.28-2.021,0.33-2.625c0.031-0.606,0.047-0.925,0.047-0.925s0.001,0.319,0.001,0.927
						c-0.019,0.606-0.029,1.506-0.205,2.653c-0.173,1.145-0.535,2.541-1.232,4.04c-0.681,1.506-1.623,3.125-2.575,4.944
						c-0.939,1.808-1.729,3.909-2.27,6.244c-0.553,2.332-0.88,4.885-1.037,7.586c-0.082,1.349-0.112,2.741-0.171,4.174
						c-0.06,1.429-0.13,2.887-0.217,4.369c-0.167,2.966-0.382,6.03-0.59,9.168c-0.142,2.129-0.274,4.224-0.381,6.239
						c-0.054,1.008-0.104,1.995-0.142,2.958c-0.019,0.481-0.038,0.956-0.056,1.424c-0.02,0.628-0.04,1.245-0.059,1.854
						c-0.189,0.01-0.377,0.021-0.565,0.031c-0.177,0.01-0.411,0.034-0.463,0.04c-0.191,0.02-0.378,0.039-0.561,0.058
						c-0.095,0.008-0.188,0.016-0.28,0.023l-0.139,0.008h-0.022c-0.019,0-0.035-0.002-0.053-0.005
						c-0.018,0.011-0.004,0.053,0.003,0.08l0.01,0.017l0.034,0.066c0.018,0.046,0.048,0.088,0.081,0.128l0.093,0.124
						c0.035,0.04,0.078,0.076,0.116,0.114c0.314,0.306,0.754,0.539,1.203,0.74c0.452,0.202,0.919,0.378,1.373,0.568
						c0.226,0.093,0.452,0.192,0.669,0.299c0.344,0.164,0.71,0.386,1.018,0.57c-0.118,0.161-0.248,0.334-0.361,0.499l-0.086,0.125
						l-0.063,0.111l-0.059,0.105l-0.033,0.066c-0.089,0.176-0.172,0.363-0.256,0.549c-0.044,0.095-0.087,0.188-0.129,0.278
						l-0.068,0.138l-0.022,0.039c-0.025,0.041-0.051,0.081-0.081,0.117c0.031,0.022,0.11,0.035,0.148,0.054l0.303,0.139
						c0.112,0.044,0.21,0.112,0.309,0.177l0.295,0.194c0.091,0.073,0.176,0.151,0.263,0.227c0.182,0.146,0.328,0.318,0.477,0.487
						c0.581,0.685,0.96,1.455,1.258,2.212c0.585,1.521,0.861,3.019,1.066,4.414c0.391,2.797,0.466,5.227,0.559,7.214
						c0.08,1.987,0.146,3.535,0.239,4.584c0.046,0.525,0.093,0.925,0.132,1.192C235.941,354.572,235.961,354.71,235.961,354.71z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M222.659,323.671c0,0-0.276,0.2-0.774,0.561c-0.509,0.347-1.262,0.839-2.293,1.287
						c-0.26,0.107-0.528,0.232-0.829,0.315c-0.148,0.046-0.3,0.093-0.454,0.141c-0.157,0.043-0.322,0.069-0.487,0.105
						c-0.33,0.082-0.687,0.095-1.053,0.127c-0.369-0.009-0.755-0.009-1.145-0.096c-0.781-0.143-1.577-0.5-2.252-1.038
						c-0.68-0.534-1.243-1.224-1.706-1.97c-0.466-0.748-0.815-1.52-1.102-2.275c-0.288-0.756-0.521-1.494-0.71-2.202
						c-0.386-1.415-0.634-2.701-0.841-3.778c-0.396-2.156-0.641-3.494-0.641-3.494s0.129,0.315,0.342,0.893
						c0.214,0.577,0.502,1.42,0.841,2.463c0.342,1.041,0.725,2.287,1.201,3.636c0.234,0.677,0.5,1.375,0.8,2.081
						c0.299,0.705,0.642,1.417,1.06,2.088c0.416,0.671,0.891,1.268,1.433,1.729c0.54,0.464,1.149,0.78,1.781,0.946
						c0.313,0.097,0.637,0.12,0.951,0.158c0.318-0.003,0.629,0.02,0.933-0.025c0.151-0.018,0.302-0.023,0.449-0.048
						c0.147-0.028,0.291-0.056,0.432-0.083c0.286-0.043,0.551-0.131,0.81-0.199c1.028-0.299,1.839-0.659,2.395-0.912
						C222.352,323.816,222.659,323.671,222.659,323.671z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M208.864,295.096c0,0-0.028-0.815-0.08-2.285c-0.053-1.471-0.084-3.601-0.267-6.216
						c-0.189-2.607-0.556-5.715-1.595-8.98c-0.526-1.636-1.186-3.324-1.879-5.085c-0.688-1.763-1.406-3.604-1.972-5.564
						c-0.564-1.956-0.93-3.951-0.984-5.89c-0.07-1.938,0.114-3.803,0.436-5.538c0.685-3.471,1.793-6.421,2.622-8.911
						c0.853-2.484,1.453-4.521,1.773-5.954c0.188-0.711,0.269-1.276,0.352-1.655c0.076-0.38,0.115-0.58,0.115-0.58
						s-0.023,0.202-0.067,0.587c-0.053,0.384-0.091,0.957-0.228,1.682c-0.218,1.459-0.689,3.551-1.41,6.085
						c-0.7,2.538-1.666,5.525-2.227,8.908c-0.26,1.691-0.392,3.481-0.295,5.326c0.081,1.847,0.443,3.732,0.99,5.63
						c1.108,3.792,2.72,7.291,3.679,10.685c0.971,3.408,1.198,6.595,1.25,9.229c0.044,2.644-0.065,4.771-0.118,6.242
						C208.898,294.282,208.864,295.096,208.864,295.096z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M260.596,265.534c0,0-0.752,0.321-2,0.729c-1.247,0.41-2.981,0.9-4.895,1.363
						c-0.956,0.229-1.866,0.447-2.695,0.646c-0.824,0.209-1.571,0.389-2.191,0.572c-0.313,0.083-0.593,0.17-0.839,0.248
						c-0.248,0.073-0.462,0.14-0.633,0.202c-0.349,0.115-0.543,0.18-0.543,0.18s0.165-0.12,0.464-0.336
						c0.149-0.11,0.339-0.233,0.565-0.368c0.223-0.14,0.481-0.294,0.776-0.444c0.583-0.32,1.305-0.64,2.121-0.956
						c0.819-0.306,1.735-0.592,2.708-0.832c1.95-0.471,3.745-0.687,5.044-0.823C259.78,265.583,260.596,265.534,260.596,265.534z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M258.625,281.793c0,0-0.062,0.004-0.18,0.012c-0.06,0.004-0.135,0.008-0.223,0.013
						c-0.087-0.008-0.189-0.018-0.302-0.027c-0.456-0.051-1.107-0.214-1.866-0.515c-1.522-0.592-3.473-1.721-5.478-3.094
						c-0.249-0.168-0.502-0.344-0.73-0.489c-0.238-0.15-0.486-0.297-0.732-0.445c-0.493-0.295-0.977-0.595-1.423-0.93
						c-0.438-0.345-0.868-0.698-1.142-1.23c-0.134-0.262-0.201-0.6-0.125-0.897c0.073-0.298,0.242-0.509,0.401-0.676
						c0.337-0.314,0.678-0.486,0.979-0.617c0.301-0.132,0.565-0.22,0.782-0.284c0.216-0.07,0.388-0.106,0.502-0.138
						c0.115-0.027,0.176-0.043,0.176-0.043s-0.055,0.03-0.158,0.088c-0.103,0.06-0.259,0.136-0.45,0.253
						c-0.193,0.111-0.426,0.253-0.678,0.433c-0.253,0.175-0.528,0.402-0.733,0.677c-0.092,0.14-0.163,0.288-0.168,0.423
						c-0.007,0.134,0.039,0.263,0.138,0.407c0.2,0.289,0.589,0.566,1.009,0.825c0.428,0.261,0.908,0.509,1.417,0.777
						c0.254,0.136,0.516,0.275,0.784,0.434c0.277,0.167,0.52,0.332,0.777,0.505c2.016,1.381,3.75,2.7,5.101,3.52
						c0.671,0.414,1.241,0.697,1.655,0.846c0.105,0.036,0.198,0.068,0.278,0.096c0.083,0.017,0.154,0.031,0.211,0.042
						C258.564,281.78,258.625,281.793,258.625,281.793z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M259.61,286.228c0,0-0.52-0.135-1.326-0.374c-0.807-0.242-1.909-0.572-3.086-1.072
						c-1.178-0.499-2.196-1.105-2.889-1.611c-0.696-0.503-1.074-0.885-1.074-0.885s0.519,0.14,1.323,0.384
						c0.805,0.248,1.903,0.584,3.079,1.083c1.176,0.499,2.196,1.099,2.893,1.599C259.228,285.849,259.61,286.228,259.61,286.228z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M253.698,335.495c0,0-0.028-0.341-0.079-0.957c-0.051-0.617-0.1-1.511-0.14-2.616
						c-0.086-2.209-0.03-5.262-0.015-8.594c0.001-1.666-0.035-3.246-0.122-4.683c-0.06-0.716-0.1-1.4-0.184-2.034
						c-0.06-0.64-0.155-1.228-0.236-1.77c-0.068-0.544-0.185-1.024-0.259-1.455c-0.073-0.432-0.162-0.796-0.233-1.097
						c-0.135-0.603-0.21-0.937-0.21-0.937s0.134,0.315,0.378,0.884c0.121,0.286,0.27,0.636,0.407,1.055
						c0.139,0.418,0.325,0.889,0.464,1.428c0.152,0.535,0.318,1.124,0.445,1.768c0.151,0.64,0.254,1.333,0.369,2.063
						c0.195,1.463,0.303,3.084,0.301,4.779c0.002,1.695-0.101,3.3-0.208,4.754c-0.111,1.455-0.233,2.759-0.337,3.857
						C253.829,334.134,253.698,335.495,253.698,335.495z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M270.368,310.293c0,0,0.002,0.07,0.007,0.204c0.013,0.134,0.041,0.329,0.13,0.563
						c0.086,0.234,0.236,0.501,0.449,0.79c0.211,0.293,0.491,0.593,0.775,0.998c0.136,0.205,0.29,0.449,0.327,0.794
						c0.045,0.351-0.145,0.731-0.374,0.958c-0.115,0.122-0.239,0.228-0.369,0.319l-0.099,0.07l-0.132,0.075
						c-0.087,0.042-0.177,0.08-0.264,0.104c-0.35,0.105-0.681,0.125-1.013,0.137c-0.66,0.016-1.324-0.041-1.985-0.047
						c-0.328-0.003-0.658,0.007-0.946,0.06c-0.141,0.027-0.269,0.068-0.354,0.116c-0.045,0.023-0.075,0.048-0.097,0.07
						c-0.016,0.009-0.016,0.023-0.027,0.033c-0.006,0.004-0.007,0.011-0.01,0.018c-0.001,0.002,0.002-0.005,0.003-0.008
						c0.001,0.003,0.003,0.005,0.004,0.007c0.005,0.024,0.045,0.107,0.097,0.186c0.104,0.164,0.276,0.352,0.457,0.535
						c0.548,0.54,1.18,1.039,1.781,1.52c0.611,0.483,1.2,0.95,1.761,1.394c1.124,0.9,2.117,1.753,2.889,2.542
						c0.773,0.785,1.321,1.516,1.581,2.086c0.131,0.284,0.189,0.521,0.211,0.683c0.011,0.08,0.014,0.142,0.014,0.184
						c0.001,0.041,0.001,0.061,0.001,0.061s-0.004-0.02-0.013-0.06c-0.008-0.04-0.022-0.099-0.048-0.174
						c-0.051-0.15-0.147-0.362-0.316-0.606c-0.336-0.492-0.959-1.097-1.803-1.757c-0.842-0.663-1.898-1.389-3.078-2.2
						c-0.59-0.406-1.21-0.834-1.847-1.299c-0.635-0.471-1.29-0.958-1.923-1.581c-0.213-0.218-0.422-0.44-0.605-0.729
						c-0.09-0.147-0.176-0.299-0.232-0.521c-0.061-0.245-0.036-0.498,0.063-0.708c0.167-0.334,0.405-0.513,0.646-0.636
						c0.239-0.117,0.465-0.167,0.676-0.199c0.42-0.057,0.797-0.042,1.156-0.019c0.715,0.051,1.365,0.154,1.958,0.191
						c0.293,0.019,0.578,0.02,0.801-0.024c0.058-0.009,0.104-0.024,0.151-0.039l0.061-0.024l0.082-0.047
						c0.111-0.061,0.21-0.13,0.293-0.2c0.173-0.144,0.257-0.28,0.265-0.445c0.009-0.169-0.066-0.378-0.164-0.571
						c-0.2-0.391-0.443-0.75-0.613-1.086c-0.175-0.336-0.287-0.648-0.333-0.911c-0.049-0.262-0.039-0.468-0.026-0.603
						C270.356,310.363,270.368,310.293,270.368,310.293z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M283.259,255.681c0,0-0.226,0.307-0.619,0.739c-0.393,0.434-0.946,0.994-1.617,1.523
						c-0.67,0.528-1.338,0.936-1.843,1.227c-0.504,0.289-0.848,0.452-0.848,0.452s0.139-0.354,0.461-0.858
						c0.32-0.502,0.835-1.147,1.536-1.7c0.701-0.553,1.441-0.903,1.997-1.108C282.885,255.75,283.259,255.681,283.259,255.681z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M235.961,255.681c0,0-0.15,0.001-0.42,0.001c-0.271,0-0.664-0.026-1.144-0.091
						c-0.962-0.124-2.267-0.453-3.609-1.003c-1.341-0.55-2.483-1.169-3.32-1.614c-0.418-0.226-0.758-0.409-0.993-0.537
						c-0.235-0.131-0.367-0.204-0.367-0.204s0.15,0.013,0.419,0.037c0.27,0.026,0.657,0.083,1.13,0.178
						c0.946,0.186,2.223,0.559,3.553,1.104c1.33,0.545,2.481,1.115,3.342,1.502C235.418,255.439,235.961,255.681,235.961,255.681z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M250.742,304.456c0,0,0.065-0.383,0.184-1.075c0.072-0.342,0.117-0.769,0.174-1.261
						c0.029-0.246,0.061-0.509,0.094-0.787c0.02-0.28,0.041-0.576,0.064-0.887c0.104-1.239,0.129-2.723,0.136-4.366
						c-0.005-1.646-0.061-3.455-0.121-5.357c-0.139-3.806-0.245-7.273-0.209-9.789c0.004-0.628,0.02-1.197,0.038-1.697
						c0.016-0.499,0.034-0.929,0.059-1.28c0.048-0.701,0.074-1.09,0.074-1.09s0.148,1.547,0.388,4.043
						c0.244,2.493,0.628,5.935,0.769,9.775c0.061,1.92,0.047,3.755-0.054,5.423c-0.112,1.668-0.28,3.167-0.524,4.41
						c-0.06,0.31-0.116,0.606-0.17,0.886c-0.069,0.277-0.134,0.538-0.195,0.782c-0.122,0.486-0.229,0.904-0.352,1.236
						C250.869,304.088,250.742,304.456,250.742,304.456z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M225.615,294.11c0,0,0.745-0.531,1.957-1.352c1.212-0.821,2.89-1.936,4.788-3.09
						c1.9-1.15,3.665-2.119,4.953-2.815c1.287-0.695,2.098-1.119,2.098-1.119s-0.645,0.649-1.785,1.583
						c-1.139,0.935-2.77,2.149-4.685,3.31c-1.913,1.162-3.747,2.044-5.1,2.623C226.486,293.829,225.615,294.11,225.615,294.11z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M233.991,281.301c0,0,0.273-0.249,0.758-0.557c0.485-0.308,1.189-0.669,2.023-0.88
						c0.834-0.211,1.628-0.227,2.201-0.182c0.574,0.044,0.931,0.14,0.931,0.14s-0.322,0.183-0.836,0.412
						c-0.516,0.229-1.22,0.513-2.021,0.715c-0.8,0.203-1.557,0.287-2.122,0.326C234.36,281.315,233.991,281.301,233.991,281.301z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M259.118,330.076c0,0,0.03,0.067,0.088,0.2c0.029,0.066,0.065,0.149,0.108,0.247
						c0.046,0.097,0.12,0.198,0.192,0.318c0.3,0.473,0.901,1.08,1.869,1.535c0.478,0.236,1.031,0.452,1.646,0.671
						c0.615,0.221,1.291,0.447,2.002,0.758c0.09,0.035,0.177,0.082,0.267,0.123c0.097,0.047,0.195,0.095,0.294,0.143
						c0.116,0.064,0.233,0.131,0.351,0.196l0.179,0.102l0.09,0.051l0.046,0.026c0.015,0.009-0.013,0.026-0.018,0.039
						c-0.159,0.219-0.316,0.457-0.491,0.682c-0.079,0.083-0.158,0.167-0.239,0.251c-0.078,0.082-0.153,0.168-0.247,0.234
						c-0.699,0.597-1.608,0.972-2.544,1.232c-0.935,0.271-1.93,0.463-2.823,0.888c-0.438,0.219-0.843,0.498-1.157,0.869
						c-0.306,0.362-0.501,0.84-0.636,1.352c-0.265,1.035-0.294,2.196-0.378,3.405c-0.043,0.606-0.092,1.231-0.226,1.879
						c-0.136,0.607-0.273,1.22-0.413,1.842c-0.545,2.473-1.051,5.076-1.193,7.794c-0.072,1.357-0.054,2.742,0.068,4.14
						c0.114,1.396,0.365,2.804,0.604,4.258c0.29,1.704,0.565,3.382,1.038,4.944c0.219,0.788,0.525,1.528,0.853,2.248
						c0.189,0.347,0.35,0.707,0.566,1.032l0.308,0.497l0.34,0.473c0.914,1.253,2.025,2.327,3.18,3.284
						c0.565,0.484,1.168,0.906,1.768,1.363c0.3,0.23,0.6,0.467,0.885,0.745c0.282,0.275,0.562,0.602,0.729,1.035
						c0.17,0.432,0.147,0.947-0.007,1.337c-0.146,0.398-0.366,0.722-0.595,1.013c-0.466,0.576-0.987,1.035-1.506,1.463
						c-0.25,0.211-0.541,0.422-0.833,0.605c-0.149,0.082-0.298,0.165-0.446,0.246c-0.152,0.068-0.303,0.137-0.453,0.204
						c-0.607,0.246-1.211,0.433-1.803,0.567c-1.186,0.276-2.317,0.403-3.37,0.5c-0.264,0.02-0.522,0.04-0.777,0.06
						c-0.12,0.008-0.239,0.015-0.357,0.022c-0.073,0.003-0.144,0.006-0.215,0.01c-0.318,0.012-0.624,0.025-0.915,0.036l-0.049,0.002
						c0.001,0.005,0.02,0.014,0.029,0.021l0.067,0.044l0.137,0.089c0.097,0.058,0.191,0.115,0.285,0.171
						c0.101,0.057,0.2,0.112,0.297,0.167c0.112,0.059,0.223,0.117,0.332,0.174c0.583,0.292,1.137,0.544,1.656,0.768
						c1.04,0.443,1.934,0.777,2.657,1.036c1.449,0.513,2.21,0.743,2.21,0.743s-0.773-0.186-2.248-0.628
						c-0.736-0.225-1.647-0.518-2.712-0.919c-0.532-0.203-1.102-0.434-1.703-0.706c-0.088-0.041-0.177-0.083-0.267-0.125
						c-0.098-0.047-0.196-0.096-0.295-0.144c-0.196-0.103-0.395-0.206-0.598-0.311c-0.199-0.111-0.401-0.225-0.607-0.339
						c-0.105-0.062-0.211-0.124-0.319-0.186l-0.163-0.095c-0.025-0.016-0.06-0.033-0.077-0.046l0.003-0.027
						c0.604-0.053,1.235-0.108,1.893-0.166c0.299-0.029,0.605-0.06,0.917-0.09c0.25-0.029,0.505-0.059,0.765-0.09
						c1.034-0.135,2.14-0.302,3.266-0.606c1.122-0.315,2.282-0.724,3.197-1.579c0.484-0.423,0.964-0.876,1.35-1.382
						c0.189-0.252,0.353-0.52,0.44-0.782c0.092-0.267,0.086-0.505-0.006-0.739c-0.096-0.235-0.292-0.475-0.535-0.697
						c-0.241-0.226-0.521-0.439-0.814-0.653c-0.585-0.426-1.225-0.855-1.83-1.353c-1.21-0.967-2.401-2.075-3.406-3.413l-0.373-0.507
						l-0.34-0.534c-0.238-0.352-0.419-0.739-0.626-1.114c-0.363-0.774-0.699-1.575-0.937-2.405
						c-0.511-1.654-0.794-3.364-1.087-5.079c-0.12-0.729-0.242-1.438-0.348-2.168c-0.112-0.728-0.196-1.456-0.254-2.182
						c-0.118-1.45-0.125-2.89-0.034-4.294c0.178-2.814,0.742-5.467,1.342-7.944c0.152-0.616,0.303-1.225,0.451-1.824
						c0.13-0.564,0.194-1.16,0.25-1.753c0.116-1.189,0.168-2.384,0.498-3.54c0.169-0.572,0.415-1.148,0.831-1.613
						c0.408-0.454,0.913-0.774,1.42-1.006c1.028-0.443,2.04-0.591,2.957-0.825c0.919-0.221,1.754-0.536,2.4-1.044
						c0.164-0.114,0.304-0.284,0.436-0.401c0.09-0.099,0.165-0.205,0.25-0.311c0.02-0.016,0.006-0.023-0.01-0.031l-0.04-0.025
						l-0.081-0.051c-0.055-0.034-0.108-0.07-0.165-0.102c-0.077-0.041-0.154-0.082-0.23-0.123c-0.085-0.044-0.166-0.092-0.253-0.13
						c-1.36-0.65-2.635-1.052-3.581-1.596c-0.965-0.512-1.552-1.17-1.828-1.664c-0.067-0.126-0.135-0.231-0.176-0.332
						c-0.038-0.101-0.069-0.186-0.095-0.254C259.144,330.146,259.118,330.076,259.118,330.076z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M230.514,386.498c0,0,0.042-0.118,0.122-0.344c0.034-0.114,0.104-0.243,0.134-0.414
						c0.036-0.166,0.077-0.358,0.123-0.574c0.014-0.222,0.052-0.46,0.055-0.724c-0.013-0.266,0.004-0.548-0.052-0.851
						c-0.064-0.603-0.253-1.274-0.564-1.954c-0.311-0.679-0.754-1.372-1.347-1.976c-0.59-0.603-1.335-1.109-2.169-1.367
						c-0.184-0.072-0.473-0.135-0.721-0.204c-0.257-0.063-0.518-0.128-0.781-0.193c-0.528-0.121-1.067-0.243-1.617-0.347
						c-1.1-0.214-2.242-0.385-3.412-0.483c-0.721-0.061-1.431-0.1-2.124-0.099c-0.173-0.005-0.345,0.001-0.516,0.003
						c-0.246,0.006-0.146-0.003-0.178-0.005c-0.017-0.008-0.042-0.017-0.078-0.023c-0.049,0.002-0.047,0.063-0.031,0.133
						c0.001,0.005,0.002,0.009,0.005,0.015l0.004,0.009l0.024,0.034c0.035,0.052,0.068,0.103,0.109,0.153
						c0.08,0.102,0.155,0.205,0.243,0.308c0.166,0.205,0.352,0.412,0.536,0.621c0.369,0.42,0.758,0.853,1.09,1.367
						c0.357,0.564,0.506,1.024,0.7,1.484c0.359,0.919,0.63,1.768,0.838,2.526c0.052,0.189,0.1,0.373,0.142,0.551
						c0.021,0.09,0.042,0.177,0.062,0.263l0.029,0.128l0.014,0.087c0.019,0.124,0.038,0.243,0.055,0.356
						c0.008,0.063,0.017,0.123,0.025,0.183l0.012,0.091c0.007,0.025-0.002,0.079,0.03,0.06c0.255-0.042,0.501-0.055,0.762-0.054
						c0.284-0.002,0.518,0.019,0.703,0.043c0.37,0.05,0.546,0.102,0.546,0.102s-0.186-0.001-0.548,0.036
						c-0.181,0.02-0.406,0.052-0.668,0.11c-0.284,0.058-0.678,0.179-1.137,0.354c-0.041,0.014-0.042-0.046-0.059-0.077l-0.044-0.114
						c-0.03-0.08-0.062-0.161-0.094-0.245c-0.065-0.172-0.138-0.357-0.22-0.556l-0.063-0.151l-0.053-0.114
						c-0.036-0.077-0.073-0.155-0.11-0.235c-0.077-0.159-0.157-0.324-0.24-0.495c-0.338-0.68-0.742-1.442-1.215-2.255
						c-0.238-0.396-0.495-0.863-0.75-1.147c-0.282-0.33-0.661-0.669-1.078-1.045c-0.208-0.19-0.425-0.385-0.647-0.607
						c-0.111-0.106-0.222-0.228-0.333-0.347c-0.056-0.058-0.111-0.125-0.166-0.19l-0.108-0.132
						c-0.043-0.052-0.085-0.108-0.122-0.165l-0.223-0.348l-0.112-0.176l-0.082-0.182l-0.166-0.367l-0.099-0.365
						c-0.075-0.247-0.095-0.484-0.145-0.737c-0.041-0.257-0.094-0.547-0.116-0.76c0.641-0.083,1.303-0.161,1.983-0.23
						c0.171-0.017,0.34-0.038,0.516-0.051l0.372-0.021c0.19-0.011,0.38-0.024,0.572-0.025c0.768-0.026,1.553,0.003,2.343,0.069
						c1.284,0.107,2.519,0.331,3.685,0.622c0.584,0.143,1.149,0.31,1.699,0.48c0.273,0.092,0.544,0.178,0.809,0.272
						c0.27,0.102,0.508,0.171,0.813,0.314c1.167,0.499,2.051,1.306,2.676,2.151c0.628,0.851,1.013,1.751,1.232,2.592
						c0.22,0.843,0.281,1.631,0.215,2.31c-0.008,0.339-0.091,0.649-0.141,0.931c-0.067,0.28-0.164,0.527-0.233,0.749
						c-0.096,0.215-0.191,0.399-0.269,0.559c-0.073,0.162-0.178,0.278-0.24,0.381C230.585,386.395,230.514,386.498,230.514,386.498z
						"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M241.901,383.269c0,0-0.069,0.296-0.199,0.859c-0.128,0.564-0.276,1.403-0.405,2.487
						c-0.109,1.087-0.178,2.42-0.144,3.969c0.03,1.549,0.16,3.309,0.324,5.267c0.161,1.956,0.34,4.119,0.352,6.469
						c0.003,1.176-0.042,2.407-0.214,3.682c-0.171,1.268-0.482,2.603-1.038,3.889c-0.55,1.282-1.314,2.505-2.22,3.615
						c-0.903,1.118-1.936,2.13-3.002,3.098c-0.531,0.482-1.084,0.959-1.609,1.424c-0.535,0.472-1.083,0.965-1.677,1.448
						c-0.595,0.482-1.236,0.957-1.962,1.366c-0.722,0.408-1.538,0.741-2.411,0.909c-0.219,0.034-0.437,0.074-0.656,0.098
						c-0.22,0.014-0.44,0.031-0.659,0.037l-0.653-0.02l-0.584-0.053c-0.775-0.081-1.542-0.2-2.303-0.371
						c-0.761-0.172-1.516-0.393-2.258-0.707c-0.739-0.316-1.478-0.723-2.13-1.345c-0.327-0.305-0.613-0.689-0.844-1.109
						c-0.174-0.351-0.316-0.672-0.474-1.007c-0.137-0.337-0.272-0.673-0.407-1.007c-0.119-0.338-0.237-0.675-0.355-1.01
						c-0.438-1.349-0.747-2.696-0.961-4.02c-0.421-2.647-0.496-5.198-0.281-7.561c0.11-1.182,0.266-2.314,0.52-3.4
						c0.254-1.08,0.584-2.088,0.952-3.023c0.368-0.936,0.776-1.799,1.193-2.596c0.417-0.798,0.864-1.523,1.255-2.186
						c0.787-1.337,1.406-2.522,1.821-3.526c0.416-1.003,0.628-1.833,0.602-2.402c-0.013-0.58-0.286-0.81-0.268-0.811
						c0,0,0.013,0.015,0.038,0.042c0.023,0.03,0.07,0.065,0.106,0.132c0.09,0.121,0.178,0.335,0.22,0.628
						c0.09,0.589-0.042,1.467-0.368,2.528c-0.325,1.066-0.844,2.324-1.525,3.742c-0.344,0.713-0.717,1.443-1.06,2.245
						c-0.343,0.801-0.672,1.66-0.96,2.577c-0.287,0.916-0.533,1.89-0.706,2.912c-0.169,1.016-0.254,2.108-0.294,3.23
						c-0.077,2.247,0.116,4.663,0.612,7.128c0.251,1.232,0.585,2.476,1.026,3.702c0.117,0.303,0.234,0.608,0.352,0.914
						c0.131,0.3,0.263,0.601,0.395,0.903c0.146,0.288,0.296,0.602,0.438,0.874c0.118,0.187,0.251,0.376,0.449,0.547
						c0.379,0.349,0.907,0.646,1.492,0.882c0.586,0.237,1.229,0.419,1.893,0.56c0.665,0.141,1.355,0.243,2.052,0.311l0.524,0.045
						l0.462,0.012l0.458-0.026c0.152-0.018,0.302-0.048,0.454-0.068c1.207-0.226,2.331-0.939,3.416-1.804
						c0.543-0.431,1.08-0.899,1.631-1.37c0.545-0.464,1.085-0.924,1.621-1.38c1.047-0.908,2.026-1.822,2.864-2.796
						c0.843-0.971,1.538-2.004,2.058-3.09c1.037-2.164,1.315-4.577,1.444-6.833c0.119-2.271,0.083-4.411,0.061-6.379
						c-0.025-1.966-0.017-3.761,0.086-5.333c0.097-1.573,0.291-2.921,0.506-4.007c0.235-1.084,0.471-1.909,0.658-2.459
						C241.8,383.556,241.901,383.269,241.901,383.269z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M241.901,384.746c0,0,0.181-0.368,0.56-1.006c0.372-0.64,0.979-1.532,1.641-2.628
						c0.328-0.549,0.654-1.154,0.941-1.815c0.275-0.657,0.547-1.417,0.771-2.199c0.456-1.574,0.761-3.339,0.786-5.195
						c0.01-0.928-0.047-1.834-0.163-2.705c-0.054-0.445-0.138-0.841-0.218-1.234c-0.054-0.189-0.107-0.378-0.16-0.564
						c-0.063-0.181-0.136-0.354-0.2-0.531c-0.568-1.382-1.637-2.377-2.645-3.147c-1.02-0.761-1.927-1.343-2.525-1.775
						c-0.604-0.428-0.924-0.684-0.924-0.684s0.386,0.133,1.085,0.372c0.695,0.248,1.706,0.565,2.94,1.128
						c0.614,0.29,1.285,0.656,1.951,1.179c0.665,0.519,1.312,1.218,1.803,2.073c0.113,0.216,0.235,0.436,0.341,0.662
						c0.092,0.231,0.186,0.466,0.28,0.703c0.151,0.48,0.294,0.981,0.385,1.465c0.197,0.982,0.301,2.023,0.288,3.089
						c-0.027,2.136-0.531,4.137-1.248,5.808c-0.36,0.843-0.758,1.587-1.203,2.286c-0.451,0.689-0.933,1.269-1.393,1.761
						c-0.926,0.979-1.729,1.667-2.262,2.174C242.197,384.466,241.901,384.746,241.901,384.746z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M267.522,410.695c0,0-0.097-0.11-0.281-0.32c-0.094-0.104-0.209-0.236-0.32-0.412
						c-0.118-0.171-0.257-0.369-0.37-0.619c-0.249-0.484-0.473-1.156-0.491-1.961c-0.026-0.803,0.139-1.7,0.379-2.631
						c0.239-0.934,0.543-1.909,0.73-2.938c0.188-0.977-0.061-1.567-0.935-2.075c-0.858-0.495-2.074-0.768-3.333-0.94
						c-1.272-0.17-2.633-0.242-4.037-0.279c-0.703-0.017-1.401-0.025-2.094-0.03c-0.349-0.003-0.695-0.005-1.038-0.008
						c-0.348-0.004-0.691-0.005-1.075-0.026c-0.741-0.046-1.391-0.154-1.818-0.115c-0.217,0.016-0.382,0.062-0.558,0.147
						c-0.087,0.041-0.177,0.1-0.263,0.16c-0.079,0.057-0.172,0.139-0.265,0.227c-0.378,0.369-0.746,0.825-1.079,1.27
						c-0.333,0.447-0.638,0.903-0.892,1.343c-0.26,0.456-0.407,0.796-0.427,1.172c-0.023,0.371,0.088,0.776,0.249,1.186
						c0.164,0.41,0.363,0.82,0.529,1.234c0.17,0.422,0.284,0.822,0.379,1.195c0.194,0.754,0.249,1.445,0.194,1.991
						c-0.055,0.547-0.181,0.948-0.273,1.212c-0.094,0.265-0.155,0.397-0.155,0.397s0.028-0.145,0.061-0.419
						c0.033-0.274,0.068-0.683,0.018-1.19c-0.052-0.509-0.215-1.103-0.514-1.763c-0.151-0.333-0.319-0.683-0.526-1.021
						c-0.208-0.348-0.463-0.712-0.717-1.146c-0.247-0.434-0.51-0.968-0.571-1.63c-0.023-0.325-0.009-0.673,0.075-0.997
						c0.077-0.33,0.189-0.617,0.305-0.896c0.238-0.553,0.515-1.085,0.833-1.62c0.324-0.535,0.668-1.068,1.136-1.613
						c0.122-0.137,0.251-0.275,0.415-0.421c0.16-0.137,0.327-0.265,0.518-0.38c0.376-0.232,0.836-0.394,1.267-0.454
						c0.872-0.119,1.552-0.009,2.164-0.012c0.292,0.004,0.633-0.008,0.974-0.016c0.346-0.007,0.694-0.015,1.045-0.023
						c0.706-0.012,1.428-0.013,2.162,0.006c1.467,0.04,2.912,0.148,4.317,0.401c0.703,0.129,1.396,0.293,2.073,0.523
						c0.676,0.23,1.344,0.521,1.964,0.957c0.311,0.214,0.601,0.484,0.861,0.786c0.12,0.165,0.244,0.326,0.341,0.507
						c0.089,0.188,0.193,0.365,0.241,0.566c0.258,0.782,0.12,1.6-0.063,2.161c-0.348,1.122-0.797,2.072-1.154,2.925
						c-0.36,0.852-0.633,1.615-0.733,2.312c-0.107,0.694-0.027,1.311,0.11,1.799c0.056,0.251,0.146,0.461,0.224,0.646
						c0.068,0.188,0.15,0.338,0.218,0.458C267.452,410.566,267.522,410.695,267.522,410.695z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "none",
                      stroke: "#191616",
                      strokeWidth: 2,
                      strokeMiterlimit: 10,
                    }}
                    d="M266.536,407.575"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M268.014,420.55c0,0-0.033-0.194-0.092-0.545c-0.053-0.352-0.146-0.857-0.151-1.518
						c-0.001-0.33,0.027-0.701,0.125-1.096c0.047-0.197,0.115-0.399,0.2-0.602c0.083-0.198,0.175-0.386,0.276-0.58
						c0.201-0.385,0.447-0.762,0.597-1.103c0.15-0.336,0.196-0.681,0.18-1.108c-0.081-1.757-0.767-3.334-1.24-4.497
						c-0.239-0.583-0.426-1.064-0.543-1.4c-0.117-0.337-0.172-0.526-0.172-0.526s0.117,0.158,0.328,0.444
						c0.208,0.287,0.515,0.695,0.879,1.215c0.359,0.522,0.789,1.156,1.155,1.947c0.37,0.791,0.656,1.725,0.711,2.779
						c0.028,0.527-0.073,1.138-0.334,1.612c-0.253,0.471-0.529,0.798-0.754,1.126c-0.112,0.162-0.223,0.326-0.317,0.48
						c-0.092,0.149-0.173,0.301-0.24,0.455c-0.138,0.306-0.229,0.617-0.295,0.912c-0.129,0.592-0.179,1.106-0.23,1.457
						C268.049,420.355,268.014,420.55,268.014,420.55z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M269.164,421.535c0,0,0.104,0.179,0.287,0.528c0.181,0.351,0.445,0.872,0.699,1.589
						c0.252,0.716,0.476,1.641,0.528,2.736c0.032,0.54,0.014,1.155-0.088,1.775c-0.096,0.623-0.263,1.255-0.391,1.884
						c-0.063,0.302-0.1,0.612-0.068,0.819c0.019,0.101,0.047,0.173,0.113,0.304c0.065,0.128,0.163,0.303,0.25,0.489
						c0.088,0.188,0.167,0.388,0.232,0.588c0.064,0.203,0.122,0.39,0.163,0.649c0.084,0.484,0.031,1.042-0.176,1.53
						c-0.203,0.491-0.527,0.9-0.879,1.234c-0.355,0.336-0.743,0.61-1.144,0.843c-0.804,0.47-1.659,0.809-2.54,1.095
						c-1.764,0.562-3.645,0.912-5.596,1.134c-1.94,0.222-3.989,0.355-6.113,0.212c-2.119-0.143-4.221-0.578-6.11-1.419
						c-0.48-0.213-0.977-0.46-1.454-0.819c-0.477-0.353-0.931-0.873-1.191-1.445c-0.267-0.571-0.365-1.121-0.433-1.604
						c-0.065-0.485-0.1-0.93-0.145-1.36c-0.086-0.861-0.199-1.756-0.174-2.684c0.018-0.933,0.262-1.896,0.66-2.674
						c0.191-0.394,0.425-0.749,0.643-1.078c0.178-0.261,0.324-0.541,0.442-0.836c0.226-0.597,0.34-1.246,0.424-1.895
						c0.085-0.648,0.15-1.295,0.266-1.909c0.109-0.616,0.292-1.198,0.47-1.694c0.358-1.014,0.712-1.855,0.985-2.55
						c0.556-1.383,0.795-2.175,0.795-2.175s-0.026,0.205-0.097,0.593c-0.07,0.389-0.187,0.96-0.365,1.689
						c-0.174,0.728-0.413,1.612-0.638,2.637c-0.116,0.521-0.212,1.04-0.248,1.617c-0.042,0.576-0.036,1.2-0.054,1.881
						c-0.017,0.678-0.062,1.427-0.277,2.221c-0.114,0.395-0.275,0.795-0.491,1.18c-0.172,0.319-0.337,0.625-0.461,0.95
						c-0.257,0.647-0.37,1.304-0.335,2.043c0.033,0.745,0.183,1.556,0.327,2.44c0.146,0.879,0.24,1.797,0.528,2.312
						c0.14,0.26,0.321,0.459,0.607,0.658c0.284,0.197,0.648,0.37,1.049,0.531c1.645,0.669,3.494,1.026,5.43,1.157
						c1.938,0.132,3.871,0.045,5.747-0.109c1.865-0.151,3.653-0.402,5.28-0.815c0.809-0.209,1.578-0.462,2.244-0.79
						c0.665-0.322,1.216-0.745,1.453-1.196c0.119-0.226,0.168-0.459,0.152-0.723c-0.002-0.117-0.036-0.295-0.071-0.45
						c-0.035-0.156-0.078-0.305-0.13-0.452c-0.053-0.149-0.118-0.297-0.196-0.494c-0.079-0.195-0.154-0.464-0.163-0.712
						c-0.01-0.493,0.102-0.859,0.205-1.2c0.21-0.661,0.433-1.24,0.584-1.794c0.157-0.553,0.236-1.072,0.271-1.592
						c0.077-1.024-0.014-1.916-0.158-2.637c-0.146-0.721-0.326-1.271-0.45-1.643C269.232,421.73,269.164,421.535,269.164,421.535z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M236.974,409.218c0,0-0.212-0.311-0.594-0.87c-0.388-0.555-0.955-1.354-1.727-2.268
						c-0.381-0.46-0.833-0.931-1.329-1.417c-0.509-0.473-1.072-0.951-1.703-1.396c-0.637-0.439-1.335-0.849-2.09-1.217
						c-0.759-0.361-1.576-0.668-2.429-0.948c-1.713-0.539-3.405-0.886-4.906-0.787c-0.374,0.024-0.732,0.084-1.071,0.164
						c-0.329,0.081-0.657,0.22-0.97,0.351c-0.623,0.281-1.183,0.607-1.667,0.94c-0.487,0.332-0.888,0.686-1.203,1.027
						c-0.166,0.166-0.286,0.344-0.409,0.503c-0.053,0.083-0.098,0.166-0.145,0.245c-0.049,0.077-0.089,0.154-0.114,0.232
						c-0.258,0.609-0.151,1.013-0.173,1.007c0.025,0.004-0.163-0.37,0.007-1.064c0.16-0.69,0.756-1.566,1.691-2.39
						c0.47-0.411,1.022-0.817,1.668-1.185c0.329-0.175,0.668-0.355,1.067-0.49c0.39-0.127,0.799-0.225,1.222-0.28
						c1.699-0.222,3.538,0.094,5.339,0.659c0.902,0.296,1.771,0.642,2.571,1.059c0.798,0.423,1.528,0.904,2.178,1.419
						c0.645,0.522,1.202,1.078,1.688,1.622c0.473,0.558,0.887,1.094,1.223,1.609c0.681,1.024,1.131,1.904,1.431,2.516
						C236.824,408.873,236.974,409.218,236.974,409.218z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M237.138,399.035c0,0,0.044,0.111,0.127,0.324c0.044,0.106,0.093,0.239,0.141,0.4
						c0.053,0.159,0.112,0.345,0.162,0.558c0.225,0.843,0.397,2.128,0.244,3.705c-0.075,0.786-0.223,1.642-0.45,2.538
						c-0.223,0.895-0.515,1.85-0.917,2.82c-0.8,1.934-2.064,3.967-3.912,5.596c-0.916,0.816-1.914,1.493-2.935,2.028
						c-1.062,0.566-2.217,0.814-3.282,0.87c-1.073,0.059-2.082-0.057-3.001-0.249c-0.92-0.194-1.739-0.502-2.467-0.813
						c-1.443-0.667-2.455-1.479-3.06-2.109c-0.148-0.161-0.279-0.305-0.393-0.428c-0.1-0.136-0.184-0.25-0.252-0.343
						c-0.135-0.185-0.205-0.282-0.205-0.282s0.083,0.087,0.242,0.251c0.079,0.082,0.176,0.184,0.29,0.303
						c0.127,0.107,0.272,0.231,0.436,0.37c0.663,0.541,1.722,1.21,3.15,1.719c0.721,0.232,1.519,0.459,2.395,0.579
						c0.874,0.117,1.817,0.163,2.787,0.061c0.97-0.103,1.954-0.36,2.85-0.871c0.938-0.525,1.852-1.166,2.7-1.921
						c1.708-1.506,2.939-3.349,3.794-5.145c0.429-0.9,0.765-1.792,1.045-2.647c0.282-0.852,0.49-1.658,0.631-2.405
						c0.283-1.494,0.258-2.744,0.139-3.595c-0.023-0.214-0.058-0.401-0.09-0.564c-0.027-0.164-0.058-0.302-0.087-0.412
						C237.166,399.151,237.138,399.035,237.138,399.035z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M244.528,378.177c0,0-0.124,0.267-0.375,0.738c-0.25,0.471-0.647,1.136-1.169,1.931
						c-1.025,1.597-2.63,3.67-3.338,6.338c-0.741,2.727-1.107,5.291-1.338,7.163c-0.216,1.879-0.35,3.046-0.35,3.046
						s-0.029-0.293-0.083-0.822c-0.047-0.53-0.103-1.298-0.127-2.252c-0.047-1.898,0.044-4.576,0.817-7.43
						c0.205-0.728,0.474-1.417,0.788-2.054c0.319-0.635,0.683-1.211,1.05-1.736c0.747-1.044,1.509-1.886,2.135-2.593
						c0.629-0.706,1.133-1.279,1.469-1.688C244.343,378.405,244.528,378.177,244.528,378.177z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M233.196,377.685c0,0-0.091,0.086-0.27,0.236c-0.087,0.08-0.204,0.158-0.342,0.251
						c-0.137,0.096-0.299,0.192-0.486,0.286c-0.37,0.202-0.837,0.379-1.374,0.526c-0.539,0.13-1.15,0.22-1.798,0.204
						c-0.648-0.024-1.265-0.14-1.801-0.334c-0.533-0.206-0.99-0.457-1.314-0.748c-0.082-0.071-0.16-0.137-0.23-0.202
						c-0.063-0.074-0.122-0.144-0.179-0.209c-0.111-0.127-0.197-0.241-0.249-0.351c-0.116-0.206-0.169-0.317-0.169-0.317
						s0.106,0.07,0.295,0.196c0.086,0.068,0.207,0.131,0.348,0.197c0.067,0.035,0.139,0.071,0.216,0.11
						c0.08,0.03,0.166,0.061,0.256,0.095c0.347,0.143,0.788,0.232,1.266,0.315c0.485,0.072,1.023,0.105,1.592,0.128
						c0.57,0.014,1.117,0,1.622-0.018c0.501-0.035,0.962-0.065,1.347-0.125c0.195-0.023,0.372-0.05,0.524-0.082
						c0.155-0.028,0.293-0.049,0.4-0.08C233.072,377.713,233.196,377.685,233.196,377.685z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M240.587,373.414c0,0,0.003,0.042,0.009,0.121c0.001,0.079,0.021,0.199,0.009,0.353
						c-0.004,0.308-0.073,0.767-0.316,1.293c-0.242,0.522-0.687,1.081-1.284,1.549c-0.599,0.469-1.34,0.849-2.144,1.119
						c-1.618,0.534-3.144,0.574-4.223,0.529c-0.541-0.024-0.977-0.077-1.277-0.121c-0.3-0.044-0.464-0.08-0.464-0.08
						s0.652-0.137,1.694-0.356c1.032-0.23,2.446-0.546,3.913-1.033c0.731-0.246,1.404-0.52,1.97-0.84
						c0.564-0.32,1.022-0.687,1.341-1.066c0.317-0.379,0.515-0.754,0.618-1.028c0.062-0.135,0.085-0.248,0.114-0.322
						C240.573,373.454,240.587,373.414,240.587,373.414z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M229.912,389.345c0,0-0.589,0.097-1.473,0.121c-0.886,0.024-2.054-0.01-3.26-0.232
						c-0.605-0.108-1.166-0.252-1.683-0.401c-0.514-0.137-0.985-0.295-1.391-0.408c-0.205-0.064-0.392-0.116-0.56-0.162
						c-0.168-0.051-0.317-0.094-0.439-0.122c-0.246-0.063-0.392-0.108-0.392-0.108s0.113-0.091,0.335-0.237
						c0.111-0.076,0.251-0.155,0.419-0.236c0.167-0.086,0.363-0.176,0.583-0.252c0.442-0.169,0.984-0.29,1.591-0.357
						c0.604-0.056,1.267-0.039,1.938,0.081c1.344,0.25,2.441,0.848,3.181,1.364C229.505,388.911,229.912,389.345,229.912,389.345z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M229.747,389.181c0,0-0.117,0.119-0.341,0.316c-0.223,0.201-0.562,0.466-0.999,0.756
						c-0.873,0.584-2.158,1.211-3.564,1.635c-1.404,0.424-2.675,0.652-3.649,0.872c-0.482,0.11-0.893,0.194-1.175,0.266
						c-0.285,0.067-0.454,0.097-0.454,0.097s0.085-0.142,0.26-0.387c0.171-0.25,0.448-0.582,0.832-0.957
						c0.386-0.368,0.886-0.774,1.488-1.129c0.6-0.355,1.297-0.675,2.042-0.902c1.495-0.449,2.864-0.519,3.876-0.559
						c0.506-0.019,0.927-0.016,1.221-0.019C229.577,389.171,229.747,389.181,229.747,389.181z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M230.568,394.436c0,0-0.174-0.011-0.471-0.046c-0.292-0.031-0.712-0.077-1.23-0.133
						c-1.018-0.12-2.374-0.263-3.829-0.596c-1.456-0.33-2.758-0.801-3.725-1.177c-0.963-0.378-1.584-0.677-1.584-0.677
						s0.159-0.061,0.451-0.151c0.292-0.089,0.717-0.2,1.247-0.285c1.056-0.178,2.539-0.248,4.11,0.107
						c1.57,0.358,2.895,1.08,3.754,1.742c0.217,0.16,0.406,0.32,0.566,0.468c0.161,0.145,0.295,0.273,0.398,0.389
						C230.463,394.304,230.568,394.436,230.568,394.436z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M231.554,393.779c0,0-0.719,0.397-1.911,0.85c-1.193,0.458-2.838,0.971-4.634,1.355
						c-0.898,0.198-1.749,0.354-2.532,0.492c-0.772,0.155-1.488,0.27-2.08,0.412c-0.304,0.059-0.572,0.129-0.813,0.19
						c-0.244,0.056-0.455,0.109-0.623,0.164c-0.342,0.1-0.545,0.149-0.545,0.149s0.118-0.162,0.357-0.444
						c0.117-0.146,0.272-0.31,0.464-0.487c0.189-0.183,0.412-0.387,0.677-0.582c0.52-0.418,1.196-0.819,1.979-1.199
						c0.786-0.359,1.682-0.672,2.639-0.883c1.918-0.41,3.681-0.372,4.95-0.275C230.752,393.622,231.554,393.779,231.554,393.779z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M234.51,399.199c0,0-1.041,0.058-2.68,0.009c-1.641-0.048-3.873-0.195-6.285-0.54
						c-2.412-0.343-4.579-0.799-6.174-1.147c-0.796-0.174-1.445-0.332-1.896-0.439c-0.451-0.109-0.702-0.183-0.702-0.183
						s0.25-0.067,0.706-0.167c0.456-0.103,1.119-0.226,1.942-0.33c1.645-0.21,3.949-0.305,6.438,0.049
						c2.488,0.355,4.658,1.063,6.194,1.66C233.591,398.711,234.51,399.199,234.51,399.199z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M232.868,400.021c0,0-0.26,0.012-0.713,0.015c-0.226-0.001-0.501,0-0.82,0.021
						c-0.319,0.017-0.674,0.015-1.069,0.052c-0.393,0.025-0.819,0.05-1.276,0.1c-0.455,0.031-0.94,0.099-1.446,0.146
						c-1.014,0.125-2.128,0.262-3.296,0.406c-2.343,0.296-4.53,0.488-6.147,0.558c-1.615,0.073-2.642,0.018-2.642,0.018
						s0.9-0.49,2.4-1.111c1.499-0.615,3.624-1.37,6.116-1.687c1.245-0.151,2.455-0.159,3.549-0.072
						c0.548,0.057,1.066,0.113,1.548,0.215c0.481,0.083,0.925,0.201,1.324,0.317c0.4,0.104,0.753,0.247,1.061,0.361
						c0.309,0.111,0.566,0.232,0.774,0.337C232.646,399.897,232.868,400.021,232.868,400.021z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M230.404,387.538c0,0-0.067-0.303-0.188-0.851c-0.127-0.544-0.304-1.335-0.614-2.269
						c-0.15-0.468-0.344-0.967-0.589-1.473c-0.246-0.504-0.551-1.019-0.954-1.453c-0.107-0.099-0.206-0.212-0.32-0.305
						c-0.12-0.083-0.227-0.191-0.356-0.26c-0.248-0.157-0.517-0.271-0.84-0.377c-0.644-0.205-1.353-0.368-2.078-0.502
						c-0.726-0.132-1.442-0.228-2.122-0.261c-0.341-0.02-0.669-0.014-0.978,0.012c-0.154,0.012-0.295,0.042-0.435,0.066
						l-0.092,0.029c-0.02,0.005-0.017,0.001-0.027,0.002c-0.013,0-0.025,0.006-0.039,0.01c-0.042,0.003-0.018,0.04-0.028,0.068
						c-0.005-0.004,0.019,0.104,0.03,0.168c0.015,0.072,0.024,0.145,0.044,0.216c0.069,0.286,0.149,0.565,0.242,0.836
						c0.181,0.542,0.384,1.049,0.56,1.52c0.363,0.94,0.587,1.744,0.669,2.306c0.037,0.282,0.061,0.5,0.061,0.648
						c0.005,0.148,0.007,0.227,0.007,0.227s-0.018-0.076-0.055-0.22c-0.031-0.144-0.096-0.351-0.183-0.613
						c-0.183-0.525-0.523-1.243-1.008-2.114c-0.238-0.438-0.507-0.92-0.763-1.47c-0.129-0.273-0.248-0.569-0.357-0.883
						c-0.029-0.078-0.049-0.161-0.074-0.242c-0.022-0.09-0.049-0.138-0.076-0.298c-0.023-0.142-0.047-0.284-0.07-0.428
						c-0.008-0.071-0.032-0.15-0.027-0.216l0.185-0.118c0.124-0.077,0.25-0.162,0.378-0.236c0.118-0.045,0.237-0.091,0.357-0.137
						c0.189-0.051,0.382-0.102,0.571-0.129c0.378-0.056,0.757-0.08,1.135-0.074c0.758,0.005,1.524,0.09,2.298,0.229
						c0.774,0.144,1.517,0.331,2.233,0.593c0.355,0.133,0.732,0.311,1.056,0.548c0.168,0.108,0.301,0.252,0.448,0.376
						c0.138,0.132,0.252,0.28,0.375,0.417c0.453,0.577,0.732,1.192,0.939,1.769c0.205,0.579,0.339,1.128,0.426,1.631
						c0.184,1.005,0.221,1.821,0.245,2.384C230.409,387.228,230.404,387.538,230.404,387.538z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M242.01,372.265c0,0-0.019-0.037-0.054-0.107c-0.032-0.072-0.094-0.176-0.139-0.321
						c-0.112-0.285-0.211-0.727-0.247-1.274c-0.037-0.547-0.006-1.193,0.036-1.88c0.038-0.688,0.084-1.403-0.033-2.103
						c-0.245-1.525-0.552-2.909-0.9-3.87c-0.172-0.481-0.349-0.863-0.499-1.113c-0.068-0.132-0.138-0.221-0.179-0.288
						c-0.043-0.066-0.066-0.102-0.066-0.102s0.035,0.023,0.1,0.067c0.064,0.046,0.167,0.107,0.279,0.209
						c0.238,0.192,0.548,0.517,0.862,0.968c0.633,0.901,1.246,2.311,1.51,3.955c0.068,0.433,0.074,0.883,0.038,1.281
						c-0.038,0.402-0.104,0.774-0.176,1.122c-0.148,0.691-0.313,1.284-0.414,1.788c-0.102,0.502-0.145,0.917-0.14,1.209
						c-0.007,0.147,0.011,0.259,0.014,0.338C242.008,372.223,242.01,372.265,242.01,372.265z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M268.069,423.397c0,0,0.129,0.404,0.362,1.136c0.11,0.368,0.245,0.822,0.334,1.374
						c0.041,0.276,0.074,0.578,0.064,0.903c-0.004,0.324-0.048,0.674-0.154,1.027c-0.099,0.351-0.265,0.721-0.477,1.044
						c-0.105,0.163-0.204,0.337-0.322,0.501c-0.123,0.161-0.248,0.326-0.375,0.493c-0.536,0.647-1.203,1.262-1.978,1.8
						c-1.549,1.081-3.502,1.843-5.594,2.279c-0.523,0.112-1.045,0.196-1.56,0.268c-0.528,0.071-1.062,0.098-1.575,0.088
						c-1.03-0.019-1.994-0.169-2.89-0.324c-0.445-0.078-0.878-0.155-1.299-0.23c-0.209-0.035-0.415-0.069-0.617-0.104
						c-0.207-0.036-0.424-0.074-0.628-0.118c-0.822-0.188-1.552-0.485-2.171-0.818c-1.232-0.683-2.084-1.394-2.708-1.837
						c-0.616-0.454-0.967-0.693-0.967-0.693s0.385,0.179,1.061,0.546c0.679,0.354,1.626,0.956,2.854,1.479
						c0.616,0.254,1.309,0.462,2.069,0.57c0.192,0.025,0.381,0.043,0.585,0.064c0.21,0.02,0.422,0.039,0.638,0.059
						c0.427,0.048,0.866,0.097,1.317,0.148c0.894,0.102,1.818,0.2,2.758,0.188c0.471-0.006,0.939-0.04,1.417-0.113
						c0.491-0.076,0.987-0.16,1.485-0.267c1.989-0.416,3.827-1.064,5.306-1.974c0.739-0.452,1.385-0.968,1.924-1.518
						c0.128-0.144,0.255-0.283,0.38-0.422c0.121-0.141,0.225-0.291,0.335-0.432c0.226-0.283,0.381-0.563,0.5-0.858
						c0.245-0.585,0.283-1.189,0.268-1.715c-0.021-0.529-0.093-0.992-0.152-1.369C268.137,423.815,268.069,423.397,268.069,423.397z
						"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M268.945,425.148c0,0-0.059-0.061-0.17-0.178c-0.115-0.113-0.265-0.296-0.488-0.502
						c-0.43-0.428-1.093-1.003-1.984-1.587c-0.889-0.589-2.032-1.142-3.356-1.59c-1.322-0.439-2.853-0.775-4.439-0.989
						c-1.568-0.165-3.129-0.059-4.508,0.148c-1.384,0.213-2.607,0.56-3.568,0.999c-0.965,0.434-1.657,0.968-2.057,1.413
						c-0.204,0.22-0.342,0.411-0.428,0.546c-0.086,0.135-0.132,0.206-0.132,0.206s0.031-0.079,0.09-0.229
						c0.061-0.149,0.163-0.368,0.332-0.63c0.33-0.527,0.969-1.208,1.93-1.799c0.957-0.596,2.212-1.103,3.654-1.434
						c1.445-0.323,3.073-0.514,4.815-0.333c1.706,0.229,3.268,0.649,4.645,1.218c1.367,0.576,2.514,1.294,3.358,2.024
						c0.849,0.728,1.426,1.42,1.782,1.922c0.187,0.243,0.303,0.454,0.394,0.587C268.899,425.077,268.945,425.148,268.945,425.148z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M262.485,423.397c0,0-0.046-0.026-0.133-0.076c-0.088-0.048-0.207-0.131-0.38-0.206
						c-0.331-0.173-0.837-0.362-1.469-0.546c-1.261-0.355-3.079-0.688-5.028-0.714c-0.243-0.003-0.477,0.009-0.706,0.022
						c-0.25,0.017-0.497,0.033-0.74,0.05c-0.479,0.027-0.941,0.054-1.382,0.079c-0.879,0.052-1.669,0.105-2.327,0.183
						c-0.657,0.077-1.183,0.181-1.517,0.322c-0.167,0.07-0.288,0.147-0.352,0.22c-0.067,0.069-0.087,0.122-0.09,0.119
						c0.004,0.002,0.004-0.054,0.05-0.147c0.042-0.097,0.145-0.217,0.3-0.342c0.311-0.25,0.829-0.499,1.484-0.719
						c0.656-0.221,1.452-0.416,2.34-0.574c0.444-0.079,0.912-0.149,1.396-0.209c0.239-0.027,0.483-0.056,0.731-0.084
						c0.27-0.024,0.543-0.043,0.814-0.04c2.167,0.033,4.008,0.675,5.237,1.315c0.613,0.327,1.075,0.653,1.356,0.92
						c0.149,0.121,0.243,0.242,0.313,0.314C262.45,423.357,262.485,423.397,262.485,423.397z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M265.879,417.484c0,0-0.051-0.029-0.147-0.085c-0.045-0.026-0.102-0.059-0.169-0.097
						c-0.069-0.033-0.156-0.06-0.246-0.094c-0.367-0.131-0.91-0.2-1.583-0.166c-0.672,0.026-1.479,0.156-2.379,0.313
						c-0.458,0.081-0.938,0.166-1.435,0.254c-0.523,0.088-1.038,0.154-1.565,0.224c-2.112,0.269-4.034,0.452-5.459,0.637
						c-0.711,0.091-1.296,0.175-1.706,0.233c-0.412,0.061-0.641,0.095-0.641,0.095s0.172-0.156,0.503-0.416
						c0.333-0.255,0.832-0.606,1.488-0.974c1.307-0.737,3.271-1.509,5.527-1.796c0.565-0.073,1.119-0.109,1.628-0.134
						c0.523-0.024,1.046-0.029,1.545-0.009c1.002,0.048,1.923,0.198,2.674,0.469c0.752,0.26,1.312,0.646,1.602,0.982
						c0.072,0.087,0.136,0.157,0.183,0.227c0.04,0.074,0.075,0.137,0.103,0.187C265.853,417.433,265.879,417.484,265.879,417.484z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M263.799,416.827c0,0-0.043-0.017-0.126-0.051c-0.081-0.032-0.193-0.083-0.349-0.123
						c-0.3-0.092-0.732-0.167-1.266-0.196c-0.534-0.032-1.165-0.029-1.887-0.031c-0.36-0.003-0.742-0.008-1.15-0.029
						c-0.405-0.021-0.861-0.071-1.277-0.14c-1.7-0.282-3.137-0.782-4.212-1.133c-1.094-0.367-1.777-0.597-1.777-0.597
						s0.169-0.076,0.487-0.185c0.317-0.107,0.785-0.236,1.371-0.335c1.169-0.205,2.806-0.241,4.488,0.038
						c0.43,0.07,0.802,0.145,1.199,0.226c0.393,0.082,0.785,0.176,1.159,0.287c0.748,0.218,1.428,0.511,1.959,0.838
						c0.535,0.324,0.91,0.692,1.113,0.974c0.107,0.135,0.168,0.258,0.208,0.337C263.778,416.785,263.799,416.827,263.799,416.827z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M264.127,412.119c-0.002,0.003-0.033-0.045-0.11-0.07c-0.078-0.025-0.2-0.023-0.357,0.004
						c-0.315,0.056-0.769,0.213-1.326,0.418c-0.564,0.204-1.205,0.46-1.99,0.707c-0.197,0.054-0.399,0.109-0.605,0.165
						c-0.199,0.051-0.402,0.102-0.608,0.153c-0.413,0.101-0.842,0.193-1.283,0.277c-1.764,0.334-3.405,0.456-4.627,0.511
						c-1.219,0.052-2.013,0.024-2.013,0.024s0.615-0.504,1.709-1.102c1.09-0.601,2.672-1.284,4.512-1.633
						c0.459-0.088,0.912-0.149,1.351-0.191c0.22-0.02,0.436-0.037,0.648-0.048c0.107-0.005,0.212-0.009,0.316-0.014
						c0.096-0.002,0.19-0.004,0.284-0.006c0.779-0.033,1.547-0.027,2.178,0.043c0.635,0.067,1.161,0.186,1.511,0.35
						c0.174,0.08,0.303,0.177,0.362,0.267C264.136,412.067,264.123,412.121,264.127,412.119z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M264.784,412.338c0,0-0.043-0.028-0.125-0.083c-0.072-0.058-0.202-0.116-0.355-0.184
						c-0.321-0.123-0.797-0.219-1.402-0.27c-0.604-0.057-1.327-0.08-2.146-0.123c-0.411-0.022-0.844-0.047-1.327-0.109
						c-0.442-0.055-0.895-0.12-1.354-0.192c-1.835-0.299-3.455-0.636-4.693-0.796c-0.617-0.088-1.13-0.13-1.494-0.167
						c-0.361-0.024-0.57-0.046-0.57-0.046s0.17-0.113,0.498-0.297c0.33-0.17,0.822-0.41,1.465-0.605
						c0.641-0.198,1.431-0.364,2.311-0.428c0.88-0.063,1.849-0.031,2.833,0.126c0.493,0.078,0.966,0.184,1.417,0.302
						c0.41,0.111,0.844,0.225,1.254,0.355c0.825,0.257,1.571,0.571,2.165,0.92c0.596,0.343,1.034,0.737,1.263,1.059
						c0.111,0.169,0.183,0.296,0.212,0.397C264.768,412.289,264.784,412.338,264.784,412.338z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M264.237,408.397c0,0-0.044,0-0.126,0.002c-0.08-0.002-0.193,0.002-0.338,0.028
						c-0.285,0.045-0.671,0.16-1.138,0.34c-0.47,0.186-1.027,0.408-1.644,0.654c-0.632,0.233-1.332,0.501-2.222,0.629
						c-1.574,0.211-2.942,0.148-3.974,0.168c-0.515,0.003-0.945,0.012-1.248,0.023c-0.308,0.011-0.479,0.017-0.479,0.017
						s0.115-0.13,0.347-0.34c0.232-0.21,0.589-0.49,1.069-0.776c0.954-0.582,2.418-1.102,3.974-1.309
						c0.676-0.094,1.432-0.222,2.142-0.261c0.711-0.043,1.38-0.017,1.945,0.079c0.565,0.092,1.023,0.263,1.303,0.435
						c0.142,0.079,0.238,0.163,0.299,0.224C264.206,408.366,264.237,408.397,264.237,408.397z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M262.814,400.513c0,0-0.069,0.013-0.2,0.036c-0.132,0.022-0.328,0.044-0.583,0.057
						c-0.509,0.024-1.253-0.024-2.145-0.146c-0.446-0.057-0.931-0.13-1.438-0.176c-0.512-0.042-1.075-0.086-1.647-0.105
						c-1.145-0.032-2.389,0.025-3.48,0.579c-0.547,0.27-1.034,0.637-1.464,1.049c-0.105,0.106-0.209,0.212-0.313,0.315
						c-0.069,0.081-0.134,0.162-0.176,0.256c-0.09,0.183-0.128,0.408-0.123,0.655c0.013,0.498,0.168,1.031,0.308,1.527
						c0.146,0.5,0.292,0.979,0.391,1.432c0.102,0.451,0.151,0.879,0.138,1.255c-0.012,0.377-0.093,0.698-0.202,0.936
						c-0.107,0.24-0.229,0.401-0.315,0.503c-0.09,0.098-0.138,0.149-0.138,0.149s0.034-0.061,0.099-0.176
						c0.06-0.116,0.139-0.294,0.189-0.529c0.116-0.475,0.02-1.171-0.307-1.977c-0.158-0.405-0.362-0.844-0.574-1.333
						c-0.205-0.495-0.443-1.031-0.531-1.718c-0.038-0.341-0.022-0.737,0.132-1.126c0.074-0.194,0.188-0.374,0.318-0.538
						c0.113-0.127,0.227-0.255,0.341-0.384c0.475-0.501,1.054-0.967,1.733-1.302c0.678-0.339,1.408-0.513,2.097-0.569
						c0.691-0.059,1.344-0.018,1.95,0.054c0.608,0.075,1.162,0.18,1.691,0.29c0.533,0.117,1.005,0.261,1.436,0.385
						c0.857,0.258,1.548,0.439,2.04,0.52c0.245,0.039,0.439,0.061,0.57,0.069C262.744,400.509,262.814,400.513,262.814,400.513z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M265.77,408.615c0,0-0.063-0.061-0.171-0.186c-0.105-0.125-0.249-0.317-0.384-0.576
						c-0.281-0.514-0.527-1.295-0.542-2.164c-0.012-0.869,0.202-1.641,0.429-2.168c0.225-0.53,0.45-0.818,0.45-0.818
						s0.046,0.361,0.103,0.905c0.054,0.545,0.127,1.273,0.138,2.064c0.014,0.79-0.032,1.506-0.035,2.043
						c-0.007,0.27-0.001,0.492,0.004,0.65C265.767,408.524,265.77,408.615,265.77,408.615z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M267.74,420.55c0.006-0.005-0.1-0.042-0.235-0.186c-0.139-0.142-0.298-0.399-0.406-0.737
						c-0.112-0.338-0.173-0.749-0.202-1.186c-0.015-0.219-0.02-0.443-0.026-0.661c-0.009-0.219-0.018-0.444-0.026-0.676
						c-0.034-0.943,0.004-1.847,0.107-2.505c0.098-0.658,0.241-1.057,0.241-1.057s0.137,0.398,0.317,1.022
						c0.175,0.625,0.415,1.487,0.453,2.504c0.007,0.255,0.004,0.499-0.022,0.755c-0.025,0.244-0.064,0.465-0.102,0.671
						c-0.075,0.41-0.152,0.768-0.185,1.068c-0.036,0.3-0.031,0.545,0.004,0.718C267.69,420.454,267.747,420.546,267.74,420.55z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M223.506,116.057c0,0-0.022-0.139-0.063-0.403c-0.035-0.264-0.105-0.65-0.204-1.143
						c-0.197-0.987-0.579-2.38-1.13-4.087c-0.537-1.713-1.274-3.743-1.743-6.179c-0.226-1.213-0.381-2.55-0.288-3.953
						c0.042-0.701,0.148-1.418,0.323-2.137c0.092-0.359,0.198-0.719,0.328-1.075c0.068-0.182,0.129-0.346,0.218-0.541
						c0.098-0.194,0.182-0.4,0.306-0.58c0.458-0.75,1.076-1.33,1.686-1.774c0.614-0.445,1.228-0.784,1.803-1.097
						c0.575-0.312,1.115-0.596,1.589-0.896c0.121-0.074,0.228-0.153,0.342-0.228c0.135-0.094,0.27-0.187,0.403-0.28
						c0.271-0.182,0.537-0.347,0.793-0.504c0.512-0.313,0.993-0.598,1.415-0.896c0.432-0.3,0.768-0.593,1.114-0.964
						c0.681-0.723,1.28-1.531,1.925-2.218c0.319-0.345,0.655-0.661,0.99-0.93c0.329-0.263,0.646-0.493,0.949-0.7
						c0.605-0.414,1.153-0.731,1.62-0.954c0.467-0.224,0.854-0.348,1.121-0.398c0.268-0.054,0.412-0.029,0.41-0.038
						c0.002,0.008-0.142,0.02-0.381,0.129c-0.24,0.106-0.573,0.303-0.967,0.605c-0.394,0.301-0.852,0.705-1.352,1.201
						c-0.249,0.248-0.511,0.52-0.773,0.815c-0.256,0.29-0.5,0.608-0.742,0.969c-0.49,0.715-0.954,1.579-1.62,2.494
						c-0.323,0.452-0.764,0.941-1.224,1.334c-0.462,0.406-0.953,0.763-1.429,1.116c-0.237,0.176-0.471,0.35-0.694,0.526
						c-0.111,0.09-0.222,0.18-0.334,0.271c-0.136,0.106-0.273,0.22-0.412,0.319c-0.555,0.407-1.127,0.748-1.677,1.081
						c-0.549,0.333-1.08,0.657-1.543,1.011c-0.462,0.355-0.837,0.739-1.098,1.165c-0.076,0.102-0.114,0.22-0.175,0.328
						c-0.056,0.115-0.115,0.271-0.168,0.405c-0.107,0.28-0.197,0.567-0.279,0.855c-0.156,0.579-0.267,1.168-0.33,1.761
						c-0.137,1.185-0.087,2.36,0.022,3.504c0.231,2.284,0.69,4.392,0.962,6.19c0.286,1.8,0.4,3.288,0.389,4.31
						c-0.005,0.512-0.022,0.906-0.049,1.174C223.518,115.917,223.506,116.057,223.506,116.057z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M208.725,195.547c0,0,0.036-0.276,0.104-0.803c0.063-0.527,0.148-1.304,0.226-2.308
						c0.073-1.006,0.163-2.236,0.267-3.665c0.113-1.432,0.265-3.072,0.621-4.878c0.178-0.901,0.434-1.847,0.749-2.803
						c0.315-0.945,0.669-1.914,1.04-2.909c0.371-0.995,0.76-2.015,1.133-3.065c0.382-1.057,0.713-2.115,1.058-3.269
						c1.383-4.559,2.954-9.546,4.488-14.799c0.766-2.627,1.484-5.197,2.131-7.689c0.325-1.246,0.628-2.473,0.915-3.677
						c0.289-1.239,0.658-2.465,1.061-3.602c0.808-2.288,1.699-4.355,2.353-6.326c0.33-0.984,0.605-1.942,0.81-2.873
						c0.21-0.933,0.315-1.827,0.365-2.698c0.086-1.741-0.118-3.342-0.335-4.745c-0.225-1.405-0.456-2.623-0.601-3.625
						c-0.154-1.001-0.218-1.787-0.256-2.317c-0.023-0.531-0.035-0.811-0.035-0.811s0.044,0.275,0.13,0.799
						c0.099,0.521,0.249,1.287,0.503,2.259c0.246,0.975,0.594,2.154,0.952,3.552c0.349,1.397,0.707,3.03,0.761,4.898
						c0.02,0.928-0.025,1.922-0.177,2.923c-0.148,1.004-0.372,2.033-0.651,3.078c-0.555,2.091-1.344,4.235-2.023,6.473
						c-0.342,1.126-0.634,2.252-0.872,3.451c-0.25,1.231-0.521,2.485-0.82,3.755c-0.596,2.541-1.289,5.151-2.063,7.802
						c-1.548,5.303-3.259,10.267-4.842,14.761c-1.528,4.599-3.765,8.359-4.687,11.719c-0.476,1.7-0.765,3.296-1.006,4.703
						c-0.24,1.409-0.436,2.63-0.624,3.624c-0.182,0.994-0.355,1.761-0.478,2.278C208.794,195.276,208.725,195.547,208.725,195.547z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M203.246,234.968c0,0,0.027-0.11,0.08-0.318c0.045-0.21,0.147-0.51,0.212-0.912
						c0.175-0.793,0.318-1.958,0.402-3.401c0.089-1.444,0.087-3.173,0.18-5.112c0.055-0.97,0.126-1.992,0.288-3.053
						c0.164-1.059,0.415-2.157,0.799-3.243c0.77-2.121,1.671-4.018,2.147-5.828c0.246-0.903,0.391-1.778,0.465-2.611
						c0.068-0.835,0.081-1.628,0.097-2.357c0.021-1.459,0.105-2.658,0.27-3.465c0.075-0.404,0.157-0.713,0.22-0.919
						c0.063-0.206,0.096-0.314,0.096-0.314s-0.017,0.112-0.049,0.324c-0.032,0.211-0.071,0.525-0.093,0.93
						c-0.059,0.81-0.003,1.975,0.116,3.428c0.054,0.727,0.112,1.531,0.108,2.408c-0.011,0.878-0.104,1.826-0.311,2.796
						c-0.404,1.952-1.269,3.931-2.013,5.987c-0.357,1.012-0.608,2.031-0.795,3.039c-0.185,1.008-0.297,2.001-0.403,2.951
						c-0.195,1.903-0.334,3.644-0.564,5.097c-0.226,1.453-0.511,2.615-0.79,3.388c-0.117,0.393-0.261,0.681-0.335,0.882
						C203.289,234.864,203.246,234.968,203.246,234.968z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M195.258,241.861c0,0,0.18-0.36,0.505-0.953c0.085-0.148,0.177-0.31,0.278-0.486
						c0.08-0.15,0.278-0.422,0.508-0.714c0.232-0.291,0.505-0.605,0.815-0.928c0.161-0.165,0.326-0.335,0.495-0.508
						c0.089-0.089,0.18-0.18,0.272-0.271l0.142-0.139c0.046-0.043,0.085-0.07,0.129-0.108c0.114,0.311,0.233,0.652,0.362,1.04
						c0.065,0.199,0.13,0.401,0.197,0.607c0.034,0.105,0.067,0.211,0.102,0.317c0.037,0.095,0.073,0.191,0.11,0.288
						c0.074,0.198,0.148,0.397,0.223,0.6c0.044,0.126,0.059,0.143,0.083,0.202l0.069,0.154c0.091,0.208,0.184,0.419,0.278,0.634
						c0.675,1.538,1.271,3.059,1.594,4.232c0.333,1.169,0.406,1.946,0.406,1.946s-0.571-0.523-1.384-1.402
						c-0.8-0.884-1.898-2.139-2.667-3.88c-0.104-0.242-0.203-0.483-0.284-0.728l-0.062-0.182c-0.017-0.053-0.049-0.145-0.041-0.133
						l0.001-0.073c-0.004-0.068,0.019-0.075,0.025-0.138c0.01-0.054,0.02-0.127,0.025-0.219c0-0.013,0.003-0.035,0.001-0.043
						l-0.01-0.014c-0.007-0.009-0.015-0.018-0.024-0.025c-0.019-0.015-0.042-0.026-0.07-0.033c-0.057-0.014-0.133-0.01-0.226,0.011
						c-0.094,0.023-0.196,0.052-0.394,0.18c-0.176,0.102-0.338,0.196-0.485,0.281C195.637,241.714,195.258,241.861,195.258,241.861z
						"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M208.068,132.152c0,0,0.13,0.363,0.378,1.054c0.135,0.343,0.25,0.783,0.402,1.299
						c0.165,0.514,0.284,1.124,0.444,1.807c0.274,1.374,0.521,3.079,0.61,5.074c0.094,1.994,0.033,4.272-0.177,6.771
						c-0.203,2.497-0.577,5.223-0.953,8.109c-0.179,1.423-0.271,2.904-0.293,4.434c-0.019,1.531,0.036,3.112,0.132,4.74
						c0.026,0.408,0.052,0.818,0.078,1.23c0.012,0.214,0.024,0.429,0.036,0.645c0.012,0.22,0.021,0.441,0.02,0.664
						c0.012,0.889-0.042,1.792-0.163,2.691c-0.237,1.805-0.74,3.564-1.265,5.284c-0.131,0.428-0.264,0.858-0.396,1.29l-0.197,0.645
						l-0.166,0.622c-0.23,0.827-0.38,1.692-0.513,2.567c-0.251,1.757-0.321,3.592-0.355,5.469c-0.032,1.878-0.015,3.76-0.078,5.639
						c-0.036,0.94-0.081,1.877-0.173,2.812c-0.044,0.468-0.091,0.933-0.163,1.399c-0.062,0.471-0.133,0.918-0.207,1.357
						c-0.578,3.538-1.16,6.938-1.664,10.18c-0.508,3.241-0.94,6.325-1.262,9.219c-0.16,1.448-0.297,2.845-0.397,4.19
						c-0.1,1.349-0.179,2.652-0.283,3.901c-0.104,1.249-0.228,2.441-0.404,3.564c-0.171,1.12-0.405,2.181-0.654,3.137
						c-0.461,1.911-0.834,3.587-1.215,4.931c-0.37,1.348-0.731,2.378-1.047,3.045c-0.078,0.167-0.148,0.314-0.224,0.433
						c-0.074,0.12-0.123,0.226-0.188,0.297c-0.116,0.148-0.176,0.224-0.176,0.224s0.052-0.081,0.154-0.238
						c0.057-0.074,0.098-0.186,0.16-0.309c0.064-0.122,0.121-0.273,0.184-0.444c0.257-0.683,0.53-1.726,0.796-3.088
						c0.277-1.363,0.525-3.037,0.863-5c0.387-1.929,0.578-4.135,0.632-6.612c0.023-1.244,0.048-2.547,0.074-3.905
						c0.033-1.369,0.104-2.792,0.199-4.26c0.194-2.938,0.508-6.068,0.916-9.345c0.404-3.278,0.901-6.704,1.418-10.246l0.097-0.665
						l0.081-0.648c0.06-0.429,0.098-0.866,0.133-1.306c0.075-0.878,0.108-1.777,0.135-2.685c0.045-1.819,0.02-3.693,0.052-5.612
						c0.035-1.919,0.114-3.844,0.402-5.749c0.152-0.951,0.329-1.898,0.597-2.823l0.197-0.692l0.209-0.651
						c0.139-0.429,0.278-0.856,0.416-1.282c0.549-1.694,1.046-3.322,1.298-4.934c0.128-0.806,0.197-1.607,0.21-2.403
						c0.007-0.199,0.005-0.398-0.001-0.597c-0.005-0.203-0.01-0.406-0.015-0.607c-0.014-0.419-0.027-0.836-0.041-1.251
						c-0.044-1.66-0.043-3.29,0.037-4.877c0.085-1.586,0.245-3.127,0.498-4.6c0.511-2.9,1.017-5.559,1.355-8.012
						c0.345-2.448,0.543-4.675,0.58-6.637c0.043-1.961-0.079-3.651-0.247-5.025c-0.107-0.683-0.178-1.297-0.3-1.816
						c-0.109-0.523-0.188-0.97-0.293-1.321C208.167,132.524,208.068,132.152,208.068,132.152z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M193.944,219.854c0,0-0.034-0.021-0.099-0.064c-0.067-0.039-0.156-0.111-0.292-0.177
						c-0.259-0.15-0.666-0.325-1.201-0.502c-1.072-0.351-2.688-0.67-4.689-0.787c-0.999-0.051-2.096-0.064-3.247,0.041
						c-1.14,0.102-2.379,0.305-3.424,0.785c-0.523,0.232-0.934,0.574-1.131,0.909c-0.095,0.212-0.108,0.253-0.094,0.414
						c0.017,0.159,0.101,0.409,0.232,0.693c0.13,0.288,0.305,0.608,0.482,0.996c0.089,0.192,0.178,0.414,0.255,0.663
						c0.033,0.141,0.075,0.258,0.093,0.424l0.028,0.233l0.007,0.18c0.032,0.972-0.214,1.837-0.381,2.559
						c-0.099,0.419-0.174,0.785-0.215,1.161c-0.05,0.435-0.1,0.865-0.149,1.292c-0.104,0.86-0.22,1.71-0.366,2.541
						c-0.146,0.831-0.318,1.644-0.541,2.431c-0.059,0.198-0.11,0.392-0.178,0.586l-0.024,0.072
						c-0.004,0.012-0.005,0.009-0.007,0.015l-0.012,0.021l-0.024,0.04c-0.035,0.057-0.076,0.126-0.132,0.206
						c-0.068,0.073,0.012,0.058,0.041,0.072c0.041,0.009,0.087,0.014,0.138,0.017c0.052,0.003,0.109,0.003,0.172,0.001
						c0.022-0.003,0.016,0.002,0.063-0.006l0.157-0.027c0.105-0.02,0.21-0.041,0.313-0.061c0.832-0.166,1.635-0.362,2.398-0.541
						c1.524-0.363,2.894-0.653,4.05-0.752c1.155-0.11,2.095,0.004,2.683,0.223c0.293,0.113,0.501,0.23,0.632,0.322
						c0.127,0.095,0.193,0.143,0.193,0.143s-0.074-0.034-0.217-0.099c-0.143-0.061-0.364-0.131-0.655-0.183
						c-0.587-0.095-1.458-0.04-2.526,0.24c-1.071,0.272-2.333,0.742-3.797,1.296c-0.733,0.274-1.518,0.568-2.379,0.839
						c-0.108,0.033-0.217,0.066-0.327,0.1l-0.167,0.047l-0.263,0.067c-0.372,0.081-0.762,0.172-1.139,0.215
						c-0.195,0.031-0.381,0.048-0.566,0.059c-0.188,0.013-0.377,0.026-0.567,0.04c-0.357,0.019-0.864,0.07-1.102,0.028
						c0.149-0.32,0.299-0.644,0.451-0.971c0.168-0.348,0.295-0.673,0.442-1.016l0.108-0.257l0.081-0.251
						c0.029-0.085,0.05-0.167,0.073-0.251l0.05-0.186c0.045-0.17,0.079-0.351,0.12-0.527c0.15-0.717,0.267-1.48,0.368-2.272
						c0.1-0.792,0.183-1.615,0.264-2.462c0.041-0.427,0.083-0.858,0.124-1.292c0.048-0.493,0.149-0.992,0.254-1.436
						c0.191-0.792,0.36-1.438,0.348-1.996c0.051-0.299-0.302-0.938-0.623-1.705c-0.155-0.391-0.32-0.841-0.35-1.406
						c-0.007-0.285,0.017-0.588,0.11-0.881c0.09-0.314,0.224-0.509,0.352-0.742c0.592-0.853,1.401-1.302,2.125-1.571
						c1.477-0.53,2.847-0.586,4.103-0.571c1.257,0.024,2.404,0.182,3.436,0.378c2.062,0.407,3.66,1.016,4.688,1.577
						c0.513,0.282,0.887,0.541,1.117,0.744c0.121,0.093,0.196,0.185,0.253,0.237C193.915,219.826,193.944,219.854,193.944,219.854z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M192.302,178.466c0,0-0.095,0.117-0.275,0.34c-0.182,0.221-0.442,0.552-0.771,0.981
						c-0.65,0.861-1.582,2.124-2.483,3.781c-0.443,0.829-0.875,1.763-1.154,2.779c-0.27,1.016-0.417,2.103-0.116,3.117
						c0.136,0.529,0.412,1.195,0.514,2.022c0.111,0.826-0.02,1.689-0.252,2.449c-0.229,0.75-0.552,1.448-0.844,2.089
						c-0.288,0.639-0.536,1.222-0.642,1.799c-0.054,0.29-0.09,0.576-0.085,0.871c0.006,0.302,0.019,0.636,0.06,0.957
						c0.069,0.648,0.197,1.303,0.339,1.97c0.287,1.331,0.62,2.752,0.567,4.198c-0.031,0.72-0.156,1.423-0.361,2.075
						c-0.053,0.162-0.118,0.317-0.177,0.474c-0.06,0.158-0.12,0.311-0.187,0.445c-0.128,0.279-0.258,0.55-0.395,0.811
						c-0.538,1.048-1.111,1.958-1.628,2.78c-1.046,1.631-1.837,2.954-2.291,3.934c-0.12,0.241-0.214,0.461-0.297,0.652
						c-0.086,0.19-0.151,0.354-0.202,0.487c-0.105,0.267-0.16,0.406-0.16,0.406s0.023-0.148,0.067-0.432
						c0.021-0.143,0.051-0.318,0.098-0.523c0.043-0.206,0.092-0.445,0.165-0.709c0.26-1.067,0.83-2.574,1.633-4.342
						c0.393-0.886,0.831-1.842,1.216-2.874c0.1-0.257,0.191-0.521,0.281-0.788c0.051-0.136,0.084-0.264,0.117-0.391
						c0.033-0.128,0.073-0.254,0.102-0.384c0.106-0.521,0.15-1.06,0.129-1.621c-0.049-1.125-0.399-2.331-0.763-3.701
						c-0.178-0.687-0.35-1.419-0.452-2.2c-0.057-0.392-0.083-0.783-0.095-1.208c-0.011-0.435,0.037-0.882,0.12-1.316
						c0.167-0.88,0.519-1.644,0.834-2.306c0.321-0.659,0.61-1.244,0.813-1.818c0.194-0.562,0.299-1.077,0.266-1.587
						c-0.02-0.513-0.2-1.09-0.336-1.81c-0.062-0.355-0.112-0.729-0.108-1.102c-0.005-0.186,0.019-0.359,0.029-0.537
						c0.013-0.176,0.023-0.352,0.061-0.518c0.031-0.167,0.062-0.333,0.093-0.499c0.035-0.163,0.087-0.318,0.13-0.475
						c0.08-0.316,0.202-0.604,0.311-0.893c0.462-1.138,1.064-2.074,1.656-2.878c1.198-1.6,2.359-2.689,3.171-3.419
						c0.41-0.362,0.735-0.633,0.96-0.811C192.184,178.56,192.302,178.466,192.302,178.466z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M194.273,141.678c0,0-0.024,0.148-0.055,0.427c-0.03,0.279-0.05,0.692-0.03,1.221
						c0.018,0.529,0.09,1.171,0.17,1.932c0.078,0.757,0.164,1.656,0.07,2.669c-0.081,1.01-0.381,2.116-0.79,3.193
						c-0.411,1.081-0.853,2.152-1.194,3.312c-0.344,1.161-0.612,2.401-0.799,3.714c-0.191,1.312-0.307,2.698-0.417,4.139
						c-0.056,0.726-0.103,1.426-0.175,2.171c-0.075,0.73-0.176,1.441-0.288,2.132c-0.225,1.383-0.495,2.682-0.725,3.909
						c-0.114,0.613-0.222,1.209-0.312,1.788c-0.043,0.288-0.088,0.574-0.123,0.854c-0.018,0.141-0.036,0.279-0.053,0.417
						c-0.023,0.155-0.046,0.31-0.069,0.462c-0.215,1.201-0.569,2.194-0.869,3.111c-0.623,1.814-1.104,3.26-1.25,4.304
						c-0.046,0.26-0.05,0.491-0.071,0.695c0.007,0.2,0.002,0.375,0.015,0.514c0.044,0.278,0.067,0.423,0.067,0.423
						s-0.056-0.139-0.162-0.404c-0.044-0.137-0.077-0.311-0.127-0.518c-0.022-0.207-0.067-0.452-0.073-0.726
						c-0.063-1.102,0.164-2.686,0.516-4.567c0.163-0.937,0.373-1.971,0.433-3.004c0.004-0.127,0.008-0.255,0.013-0.384
						c0.002-0.147,0.005-0.296,0.008-0.446c0.005-0.299,0.02-0.602,0.036-0.909c0.033-0.614,0.087-1.24,0.152-1.879
						c0.131-1.275,0.315-2.593,0.472-3.937c0.078-0.672,0.151-1.351,0.206-2.036c0.048-0.704,0.098-1.418,0.147-2.14
						c0.113-1.468,0.268-2.922,0.537-4.325c0.263-1.402,0.647-2.746,1.126-3.975c0.479-1.235,1.073-2.337,1.578-3.311
						c0.505-0.975,0.88-1.872,1.087-2.756c0.214-0.877,0.274-1.72,0.327-2.479c0.05-0.759,0.1-1.426,0.188-1.965
						c0.085-0.54,0.194-0.95,0.285-1.22C194.215,141.815,194.273,141.678,194.273,141.678z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M221.2,97.809c-0.434-0.139-1.095-0.248-1.957-0.341c-0.863-0.054-1.938-0.085-3.165,0.101
						c-0.308,0.037-0.628,0.077-0.95,0.152c-0.324,0.068-0.661,0.131-1.004,0.212c-0.339,0.095-0.688,0.192-1.047,0.292
						c-0.361,0.096-0.714,0.24-1.086,0.364c-1.472,0.534-3.036,1.266-4.609,2.242c-1.571,0.978-3.159,2.192-4.62,3.735
						c-0.701,0.748-3.552,8.949-3.55,10.732c0.042-0.204,3.672-7.921,4.961-9.476c1.245-1.462,2.653-2.695,4.062-3.72
						c1.411-1.026,2.832-1.844,4.182-2.479c0.341-0.149,0.667-0.319,0.999-0.443c0.332-0.129,0.655-0.254,0.969-0.375
						c0.318-0.109,0.632-0.201,0.934-0.299c0.302-0.104,0.601-0.174,0.891-0.241c1.154-0.306,2.183-0.396,3.026-0.448
						c0.842-0.012,1.506,0.007,1.948,0.085c0.447,0.058,0.682,0.088,0.682,0.088S221.635,97.928,221.2,97.809z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M206.097,165.656c0,0-0.106,0.077-0.32,0.215c-0.219,0.127-0.56,0.301-1.035,0.434
						c-0.473,0.134-1.076,0.226-1.773,0.263c-0.32,0.014-0.662,0.029-1.026,0.045c-0.204,0.016-0.38,0.034-0.521,0.063
						c0.09,0.078,0.189,0.155,0.274,0.217c0.092,0.07,0.151,0.105,0.251,0.175c0.413,0.316,0.867,0.639,1.347,0.981
						c0.48,0.343,0.986,0.707,1.495,1.123c0.254,0.208,0.51,0.427,0.757,0.669c0.406,0.406,0.824,0.915,1.24,1.404
						c-0.215,0.062-0.435,0.126-0.659,0.19c-0.113,0.032-0.227,0.064-0.341,0.098c-0.108,0.024-0.217,0.048-0.327,0.072
						c-0.11,0.024-0.222,0.049-0.333,0.073l-0.169,0.037c-0.059,0.013-0.117,0.026-0.144,0.026
						c-0.143,0.013-0.287,0.025-0.433,0.039c-0.583,0.031-1.183-0.005-1.77-0.092c-1-0.152-1.927-0.403-2.802-0.598
						c-0.875-0.196-1.706-0.337-2.485-0.344l0.054-0.974c0.819,0.133,1.536,0.415,2.187,0.725c0.649,0.314,1.24,0.655,1.767,1.016
						c1.064,0.709,1.908,1.448,2.569,2.091c0.658,0.648,1.133,1.205,1.429,1.607c0.156,0.194,0.256,0.359,0.331,0.464
						c0.072,0.107,0.11,0.163,0.11,0.163s-0.046-0.049-0.133-0.144c-0.09-0.093-0.211-0.24-0.391-0.411
						c-0.344-0.355-0.881-0.839-1.593-1.396c-0.715-0.554-1.608-1.187-2.685-1.779c-0.534-0.303-1.121-0.582-1.743-0.833
						c-0.622-0.248-1.293-0.46-1.976-0.534l0.054-0.974c0.892-0.039,1.815,0.085,2.729,0.264c0.915,0.179,1.827,0.41,2.744,0.549
						c0.535,0.079,1.057,0.112,1.548,0.095c0.122-0.008,0.243-0.017,0.362-0.025l0.101-0.01l0.037-0.004l0.009,0.001h0.023
						c-0.001-0.004-0.002-0.011-0.005-0.019l-0.006-0.013c-0.009-0.012,0.011,0.006-0.029-0.035
						c-0.213-0.227-0.441-0.44-0.671-0.647c-0.46-0.413-0.933-0.797-1.389-1.169c-0.456-0.372-0.895-0.734-1.298-1.097
						c-0.302-0.27-0.632-0.575-0.877-0.832c-0.253-0.26-0.503-0.521-0.716-0.752c0.719-0.01,1.324-0.011,1.913,0.017
						c0.422,0.025,0.818,0.048,1.189,0.069c0.683,0.037,1.264,0.016,1.733-0.054c0.469-0.07,0.819-0.191,1.052-0.285
						C205.979,165.712,206.097,165.656,206.097,165.656z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M218.251,162.372c0,0-0.075-0.045-0.22-0.132c-0.076-0.039-0.159-0.104-0.255-0.184
						c-0.103-0.073-0.204-0.178-0.315-0.301c-0.216-0.249-0.444-0.599-0.6-1.055c-0.159-0.455-0.249-1.005-0.266-1.617
						c-0.036-1.226,0.21-2.689,0.625-4.293c0.209-0.803,0.458-1.645,0.728-2.519c0.263-0.872,0.572-1.792,0.766-2.71l0.83,0.402
						c-0.379,0.493-0.736,1.025-1.072,1.593c-0.332,0.572-0.646,1.176-0.956,1.801c-0.62,1.253-1.217,2.598-1.981,3.929
						c-0.19,0.338-0.398,0.668-0.607,0.993c-0.066,0.09-0.324,0.494-0.533,0.757c-0.217,0.284-0.434,0.567-0.65,0.849l-0.161,0.21
						l-0.02,0.026c-0.007,0.008-0.009-0.016-0.014-0.023l-0.024-0.063l-0.048-0.126l-0.192-0.499l-0.19-0.494
						c-0.061-0.163-0.126-0.313-0.176-0.495l-0.125-0.415c-0.026-0.092-0.043-0.188-0.065-0.281
						c-0.04-0.188-0.089-0.372-0.112-0.561c-0.257-1.504-0.098-2.967,0.181-4.286c0.29-1.322,0.71-2.521,1.123-3.623
						c0.417-1.101,0.832-2.108,1.187-3.033c0.725-1.844,1.167-3.381,1.344-4.475c0.085-0.546,0.125-0.976,0.139-1.268
						c0.012-0.292,0.018-0.447,0.018-0.447s0.011,0.154,0.031,0.447c0.017,0.294,0.02,0.728-0.014,1.289
						c-0.074,1.119-0.389,2.728-0.988,4.638c-0.293,0.956-0.643,1.99-0.993,3.1c-0.347,1.109-0.696,2.296-0.919,3.556
						c-0.214,1.256-0.31,2.595-0.052,3.918c0.022,0.167,0.071,0.329,0.109,0.494c0.021,0.082,0.037,0.166,0.062,0.247l0.035,0.109
						l0.001-0.002c0.003-0.001,0.008,0.009,0.01,0.001l0.002-0.008c0.01-0.017-0.044,0.063,0.102-0.154
						c0.194-0.306,0.389-0.615,0.57-0.939c0.728-1.267,1.336-2.591,1.996-3.856c0.331-0.633,0.674-1.252,1.042-1.844
						c0.374-0.59,0.777-1.15,1.21-1.667l1.34-1.601l-0.51,2.002c-0.259,1.02-0.609,1.905-0.917,2.769
						c-0.314,0.858-0.608,1.672-0.862,2.446c-0.505,1.546-0.847,2.938-0.909,4.109c-0.032,0.583,0.008,1.109,0.118,1.551
						c0.107,0.443,0.29,0.793,0.473,1.052c0.095,0.127,0.182,0.24,0.274,0.321c0.086,0.088,0.161,0.161,0.23,0.207
						C218.181,162.319,218.251,162.372,218.251,162.372z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M278.69,127.554c0,0,0.093,0.104,0.276,0.307c0.09,0.103,0.209,0.225,0.337,0.382
						c0.114,0.168,0.247,0.363,0.397,0.584c0.149,0.223,0.266,0.501,0.423,0.793c0.079,0.147,0.126,0.314,0.196,0.482
						c0.06,0.17,0.142,0.343,0.192,0.533c0.235,0.751,0.413,1.643,0.538,2.654c0.124,1.012,0.182,2.14,0.26,3.365
						c0.085,1.221,0.162,2.555,0.488,3.91c0.165,0.66,0.414,1.381,0.62,2.124c0.218,0.742,0.408,1.52,0.592,2.323
						c0.352,1.611,0.609,3.349,0.707,5.183c0.103,1.834,0.06,3.755-0.062,5.738c-0.122,1.971-0.232,4.003-0.289,6.117
						c-0.12,4.224-0.052,8.738,0.31,13.466c0.354,4.731,1.015,9.662,1.926,14.756c0.9,5.098,1.994,10.376,2.847,15.861
						c0.208,1.407,0.424,2.78,0.573,4.201c0.17,1.413,0.238,2.824,0.286,4.222c0.059,2.797-0.111,5.54-0.379,8.202
						c-0.276,2.661-0.644,5.244-0.986,7.759c-0.171,1.258-0.335,2.499-0.485,3.724c-0.149,1.226-0.274,2.43-0.408,3.649
						c-0.271,2.421-0.67,4.914-1.818,7.12c-0.568,1.097-1.326,2.091-2.218,2.896c-0.486,0.454-1.069,0.772-1.605,1.004
						c-0.543,0.237-1.073,0.413-1.579,0.584c-1.007,0.336-1.96,0.658-2.604,1.192c-0.312,0.255-0.564,0.597-0.766,0.969
						c-0.188,0.383-0.318,0.807-0.415,1.252c-0.096,0.446-0.154,0.921-0.22,1.398l-0.067,0.509l-0.089,0.535
						c-0.026,0.182-0.064,0.348-0.106,0.508c-0.043,0.158-0.074,0.333-0.125,0.475c-0.186,0.598-0.424,1.056-0.679,1.496
						c-0.146,0.23-0.262,0.442-0.434,0.681l-0.239,0.347l-0.178-0.004c-0.462-1.181-0.853-2.175-1.158-3.354
						c-0.066-0.286-0.131-0.57-0.196-0.852l-0.044-0.199l-0.028-0.154l-0.057-0.307c-0.074-0.407-0.138-0.81-0.196-1.204
						c-0.234-1.581-0.396-3.056-0.66-4.409c-0.06-0.34-0.14-0.667-0.209-0.992c-0.082-0.324-0.162-0.643-0.241-0.955
						c-0.17-0.637-0.28-1.257-0.383-1.849c-0.195-1.186-0.313-2.265-0.478-3.223c-0.16-0.957-0.368-1.792-0.624-2.49
						c-0.244-0.704-0.567-1.249-0.827-1.687c-0.286-0.421-0.518-0.735-0.696-0.927c-0.17-0.2-0.257-0.302-0.257-0.302
						s0.093,0.097,0.275,0.286c0.19,0.182,0.441,0.482,0.755,0.891c0.287,0.426,0.647,0.962,0.935,1.665
						c0.3,0.697,0.558,1.539,0.77,2.499c0.217,0.96,0.39,2.038,0.639,3.193c0.13,0.576,0.266,1.177,0.46,1.783
						c0.094,0.312,0.19,0.629,0.289,0.953c0.087,0.33,0.185,0.664,0.262,1.008c0.333,1.373,0.563,2.861,0.859,4.405
						c0.074,0.387,0.152,0.776,0.241,1.169l0.067,0.294l0.034,0.148l0.026,0.102c0.032,0.118,0.065,0.236,0.097,0.356
						c0.128,0.441,0.281,0.852,0.458,1.22l0.066,0.139l0.094-0.164c0.068-0.105,0.108-0.226,0.16-0.335
						c0.083-0.231,0.142-0.463,0.176-0.69c0.016-0.114,0.031-0.227,0.041-0.337c0.01-0.123,0.021-0.247,0.031-0.371
						c0.048-0.48,0.09-0.978,0.182-1.5c0.094-0.52,0.234-1.063,0.474-1.595c0.255-0.524,0.597-1.03,1.066-1.446
						c0.936-0.812,2.025-1.171,3.017-1.549c0.994-0.373,1.977-0.727,2.592-1.342c0.732-0.702,1.337-1.544,1.795-2.49
						c0.928-1.9,1.262-4.164,1.47-6.543c0.104-1.191,0.206-2.431,0.332-3.674c0.127-1.244,0.269-2.499,0.419-3.764
						c0.299-2.529,0.625-5.099,0.865-7.708c0.231-2.609,0.369-5.26,0.296-7.939c-0.055-1.337-0.125-2.685-0.292-4.029
						c-0.144-1.342-0.365-2.732-0.568-4.108c-0.841-5.414-1.898-10.69-2.747-15.843c-0.86-5.152-1.446-10.189-1.699-14.995
						c-0.262-4.809-0.207-9.389,0.046-13.654c0.123-2.131,0.304-4.192,0.495-6.154c0.188-1.948,0.297-3.816,0.267-5.595
						c-0.025-1.778-0.205-3.464-0.481-5.047c-0.146-0.79-0.298-1.558-0.48-2.295c-0.171-0.743-0.378-1.436-0.53-2.178
						c-0.279-1.456-0.288-2.809-0.315-4.04c-0.018-1.232-0.019-2.352-0.089-3.353c-0.072-1-0.199-1.882-0.389-2.629
						c-0.039-0.189-0.109-0.361-0.16-0.531c-0.058-0.167-0.096-0.336-0.165-0.483c-0.137-0.294-0.237-0.575-0.371-0.803
						c-0.135-0.227-0.255-0.427-0.358-0.6c-0.118-0.163-0.229-0.29-0.312-0.398C278.776,127.663,278.69,127.554,278.69,127.554z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M280.66,164.342c0,0,0.023,0.223,0.067,0.649c0.047,0.425,0.118,1.052,0.227,1.858
						c0.109,0.807,0.257,1.792,0.434,2.942c0.175,1.151,0.38,2.466,0.547,3.947l0.167,1.481l-1.261-0.926
						c-0.217-0.16-0.589-0.297-1.13-0.6l1.115-0.853c0.473,1.279,0.989,2.711,1.53,4.258c0.141,0.4,0.255,0.75,0.403,1.125
						c0.146,0.374,0.306,0.757,0.474,1.149c0.335,0.784,0.697,1.598,1.049,2.449l1.104,2.668l-2.621-1.325
						c-0.121-0.061-0.354-0.147-0.636-0.246c-0.284-0.104-0.621-0.219-1.004-0.425l1.543-1.424c0.281,0.606,0.765,1.314,1.311,2.116
						c0.467,0.693,0.953,1.458,1.29,2.328l1.695,4.368l-3.454-3.125c-0.626-0.567-1.46-1.077-2.239-1.837l1.7-1.226
						c0.799,1.802,1.134,3.551,1.518,5.07c0.369,1.527,0.805,2.852,1.399,3.955l-1.296,0.486c-0.078-0.658-0.302-1.338-0.55-1.962
						c-0.254-0.626-0.533-1.213-0.807-1.758c-0.556-1.088-1.09-2.01-1.528-2.763c-0.885-1.507-1.375-2.342-1.375-2.342
						s0.158,0.183,0.46,0.532c0.301,0.349,0.744,0.864,1.299,1.543c0.554,0.681,1.224,1.521,1.938,2.559
						c0.355,0.521,0.719,1.092,1.071,1.732c0.344,0.646,0.686,1.353,0.886,2.231l0.79,3.47l-2.086-2.983
						c-0.817-1.169-1.456-2.636-1.958-4.189c-0.516-1.55-0.948-3.183-1.686-4.669l-2.572-5.181l4.271,3.955
						c0.558,0.517,1.377,0.992,2.224,1.71l-1.759,1.243c-0.25-0.612-0.637-1.228-1.088-1.895c-0.52-0.765-1.096-1.609-1.462-2.446
						l-1.368-3.124l2.91,1.7c0.168,0.098,0.42,0.201,0.693,0.307c0.275,0.109,0.572,0.218,0.898,0.397l-1.517,1.343
						c-0.32-0.848-0.634-1.68-0.94-2.489c-0.151-0.413-0.297-0.827-0.432-1.247c-0.136-0.417-0.251-0.864-0.354-1.24
						c-0.426-1.563-0.807-3.021-1.139-4.335l-0.518-2.051l1.633,1.198c0.305,0.223,0.826,0.488,1.165,0.811l-1.094,0.556
						c-0.009-1.458-0.054-2.785-0.075-3.951c-0.022-1.167-0.024-2.173-0.005-2.992c0.019-0.819,0.057-1.453,0.087-1.88
						C280.642,164.566,280.66,164.342,280.66,164.342z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M308.252,146.933c0,0-0.195-0.209-0.567-0.607c-0.192-0.196-0.378-0.479-0.627-0.794
						c-0.129-0.155-0.228-0.348-0.355-0.542c-0.12-0.198-0.263-0.402-0.373-0.639c-0.503-0.919-0.979-2.131-1.282-3.598
						c-0.307-1.465-0.449-3.175-0.401-5.055c0.032-0.938,0.102-1.92,0.218-2.937c0.132-1.039,0.273-2.008,0.286-3.046
						c0.046-2.068-0.363-4.261-1.159-6.47c-0.394-1.106-0.877-2.22-1.418-3.344c-0.273-0.562-0.557-1.127-0.853-1.696
						c-0.285-0.546-0.592-1.059-0.947-1.564c-0.704-1.008-1.566-1.944-2.529-2.85c-0.481-0.454-0.985-0.902-1.502-1.354
						c-0.496-0.43-1.107-0.952-1.619-1.542c-0.52-0.586-0.966-1.234-1.34-1.876c-0.375-0.642-0.687-1.268-1.001-1.856
						c-0.314-0.588-0.631-1.144-0.977-1.673c-0.174-0.272-0.349-0.511-0.525-0.747l-0.284-0.347l-0.302-0.334
						c-0.827-0.876-1.813-1.63-2.834-2.353c-1.025-0.733-2.073-1.412-3.083-2.244c-1.019-0.846-1.831-1.811-2.554-2.738
						c-0.725-0.93-1.366-1.835-2.036-2.64c-0.666-0.807-1.353-1.528-2.1-2.052c-0.771-0.532-1.609-0.957-2.435-1.33
						c-1.653-0.747-3.244-1.343-4.547-2.097c-1.283-0.737-2.339-1.434-3.203-2c-1.719-1.142-2.682-1.755-2.682-1.755
						s1.033,0.492,2.859,1.453c0.916,0.475,2.031,1.064,3.35,1.671c1.297,0.604,2.887,1.053,4.647,1.691
						c0.879,0.321,1.807,0.702,2.734,1.252c0.945,0.573,1.761,1.338,2.525,2.155c1.531,1.634,2.838,3.557,4.621,4.915
						c1.822,1.382,4.223,2.531,6.28,4.586l0.375,0.397l0.36,0.42c0.23,0.293,0.456,0.594,0.651,0.887
						c0.402,0.592,0.769,1.214,1.107,1.83c0.338,0.616,0.649,1.223,0.978,1.777c0.33,0.555,0.684,1.061,1.096,1.527
						c0.412,0.472,0.865,0.864,1.414,1.347c0.525,0.465,1.048,0.938,1.557,1.431c1.018,0.981,1.981,2.055,2.77,3.242
						c0.394,0.588,0.747,1.216,1.046,1.832c0.288,0.594,0.565,1.19,0.829,1.785c0.526,1.193,0.993,2.393,1.366,3.6
						c0.754,2.409,1.084,4.871,0.894,7.136c-0.081,1.129-0.315,2.226-0.501,3.172c-0.18,0.974-0.315,1.913-0.415,2.813
						c-0.183,1.799-0.184,3.44-0.017,4.865c0.163,1.426,0.505,2.629,0.899,3.564c0.083,0.242,0.201,0.451,0.297,0.655
						c0.104,0.2,0.18,0.401,0.29,0.564c0.208,0.332,0.362,0.633,0.528,0.846C308.083,146.704,308.252,146.933,308.252,146.933z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M297.084,218.54c0,0,0.049-0.032,0.146-0.093c0.096-0.064,0.242-0.154,0.445-0.253
						c0.402-0.204,1.032-0.448,1.878-0.628c1.693-0.364,4.22-0.422,7.271-0.089c1.527,0.161,3.189,0.413,4.955,0.719
						c0.247,0.044,0.495,0.088,0.746,0.132c0.384,0.072,0.772,0.145,1.163,0.218c1.267-0.119,0.453,0.967,0.307,1.706l-0.092,0.301
						l-0.075,0.256c-0.032,0.111-0.064,0.223-0.09,0.336c-0.055,0.227-0.117,0.452-0.167,0.683
						c-0.105,0.459-0.199,0.926-0.278,1.401c-0.319,1.899-0.398,3.92-0.177,5.991c0.054,0.518,0.13,1.039,0.217,1.563
						c0.073,0.435,0.235,0.935,0.406,1.455c0.348,1.042,0.75,2.139,1.075,3.308c0.186,0.678,0.354,1.369,0.443,2.102
						c0.08,0.733,0.126,1.506-0.1,2.349c-0.1,0.415-0.314,0.85-0.597,1.227c-0.292,0.384-0.68,0.671-1.069,0.879
						c-0.194,0.09-0.394,0.174-0.586,0.235c-0.189,0.047-0.386,0.11-0.567,0.133c-0.181,0.022-0.367,0.056-0.54,0.063
						c-0.176,0.005-0.351,0.011-0.524,0.016c-0.221-0.018-0.475-0.049-0.622-0.107c-0.081-0.026-0.175-0.053-0.246-0.081
						l-0.205-0.088c-0.268-0.119-0.495-0.244-0.713-0.371c-0.434-0.253-0.83-0.512-1.21-0.767c-0.758-0.505-1.483-1.017-2.155-1.387
						l-0.239-0.121l-0.206-0.088c-0.183-0.058-0.371-0.119-0.558-0.167c-0.375-0.1-0.75-0.181-1.128-0.235
						c-0.753-0.111-1.504-0.132-2.23-0.079c-0.363,0.028-0.72,0.076-1.07,0.137c-0.351,0.067-0.682,0.144-1.039,0.246
						c-0.701,0.187-1.375,0.388-2.026,0.596c-1.302,0.409-2.517,0.839-3.677,1.113c-1.156,0.281-2.243,0.381-3.174,0.335
						c-0.467-0.008-0.893-0.081-1.284-0.127c-0.387-0.078-0.739-0.14-1.048-0.22c-0.308-0.085-0.58-0.161-0.815-0.226
						c-0.231-0.079-0.426-0.145-0.583-0.199c-0.313-0.109-0.475-0.165-0.475-0.165s0.169,0.033,0.494,0.097
						c0.162,0.032,0.362,0.071,0.6,0.117c0.239,0.034,0.515,0.073,0.827,0.117c1.243,0.161,3.094,0.196,5.213-0.573
						c1.065-0.367,2.213-0.9,3.5-1.43c0.641-0.268,1.319-0.533,2.029-0.783c0.346-0.129,0.739-0.255,1.131-0.359
						c0.396-0.1,0.802-0.186,1.219-0.247c0.835-0.122,1.711-0.155,2.604-0.08c0.448,0.036,0.898,0.105,1.351,0.199
						c0.228,0.046,0.45,0.103,0.68,0.164c0.132,0.044,0.275,0.09,0.398,0.139l0.351,0.154c0.874,0.426,1.623,0.914,2.392,1.378
						c0.381,0.233,0.761,0.46,1.132,0.657c0.184,0.099,0.369,0.189,0.528,0.252c0.169,0.079,0.292,0.084,0.272,0.085
						c0.441-0.013,0.913-0.094,1.198-0.255c0.277-0.169,0.43-0.351,0.534-0.751c0.104-0.387,0.091-0.936,0.019-1.499
						c-0.073-0.569-0.219-1.174-0.388-1.786c-0.295-1.064-0.673-2.122-1.024-3.225c-0.17-0.554-0.354-1.125-0.455-1.788
						c-0.084-0.574-0.153-1.146-0.196-1.716c-0.18-2.28,0.003-4.508,0.457-6.548c0.113-0.51,0.243-1.009,0.384-1.497
						c0.068-0.244,0.149-0.483,0.223-0.721c0.037-0.12,0.078-0.237,0.119-0.354c0.027-0.079,0.025-0.067,0.033-0.085l0.018-0.04
						c0.029-0.093,0.186-0.268,0.007-0.267l-0.183-0.063c-0.024-0.007-0.013-0.007-0.085-0.024l-0.164-0.041l-0.326-0.081
						c-1.728-0.432-3.332-0.815-4.808-1.109c-2.947-0.6-5.38-0.819-7.052-0.672c-0.834,0.072-1.472,0.226-1.892,0.371
						c-0.212,0.069-0.368,0.137-0.471,0.187C297.138,218.516,297.084,218.54,297.084,218.54z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M309.566,173.54c0,0,0.003,0.167,0.028,0.474c0.04,0.307,0.133,0.754,0.404,1.257
						c0.262,0.509,0.718,1.042,1.282,1.669c0.28,0.315,0.589,0.66,0.885,1.073c0.298,0.41,0.575,0.905,0.778,1.443
						c0.208,0.54,0.349,1.144,0.419,1.758c0.073,0.614,0.092,1.238,0.127,1.842c0.036,0.611,0.091,1.194,0.218,1.804
						c0.131,0.645,0.265,1.309,0.403,1.99c0.573,2.719,1.253,5.706,2.032,8.807c0.398,1.555,0.79,3.129,1.021,4.698
						c0.241,1.568,0.303,3.14,0.159,4.616c-0.143,1.473-0.491,2.852-0.893,4.058c-0.402,1.217-0.875,2.326-1.388,3.292
						c-0.51,0.968-1.063,1.785-1.573,2.472c-0.51,0.687-0.981,1.247-1.368,1.701c-0.776,0.905-1.222,1.39-1.222,1.39
						s0.333-0.567,0.936-1.593c0.592-1.027,1.486-2.498,2.206-4.432c0.359-0.967,0.674-2.049,0.932-3.236
						c0.262-1.196,0.453-2.441,0.483-3.768c0.028-1.324-0.106-2.706-0.386-4.144c-0.272-1.442-0.675-2.929-1.079-4.507
						c-0.797-3.174-1.349-6.232-1.724-9.038c-0.091-0.702-0.174-1.387-0.242-2.056c-0.081-0.703-0.078-1.413-0.047-2.047
						c0.031-0.641,0.078-1.223,0.078-1.764c0.002-0.54-0.052-1.039-0.163-1.509c-0.21-0.944-0.695-1.709-1.159-2.422
						c-0.46-0.706-0.856-1.365-1.039-1.96c-0.194-0.592-0.2-1.078-0.179-1.392C309.534,173.7,309.566,173.54,309.566,173.54z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M305.295,143.977c0,0,0.075,0.082,0.217,0.24c0.072,0.079,0.161,0.178,0.249,0.308
						c0.091,0.127,0.205,0.273,0.302,0.455c0.219,0.352,0.423,0.826,0.589,1.397c0.166,0.571,0.273,1.241,0.351,1.962
						c0.082,0.72,0.132,1.495,0.257,2.274c0.12,0.757,0.346,1.607,0.575,2.504c0.233,0.897,0.492,1.848,0.724,2.865
						c0.116,0.508,0.225,1.031,0.317,1.571c0.094,0.533,0.163,1.129,0.236,1.558c0.342,1.875,1.319,3.712,1.854,5.644
						c0.273,0.958,0.436,1.932,0.412,2.871c-0.02,0.928-0.212,1.736-0.4,2.44c-0.4,1.402-0.773,2.458-0.913,3.237
						c-0.043,0.193-0.07,0.366-0.091,0.517c-0.026,0.151-0.042,0.279-0.048,0.384c-0.02,0.211-0.031,0.321-0.031,0.321
						s-0.022-0.109-0.065-0.316c-0.023-0.105-0.045-0.234-0.063-0.39c-0.022-0.155-0.042-0.335-0.051-0.538
						c-0.071-0.813,0.046-2.012,0.159-3.403c0.045-0.694,0.08-1.438-0.025-2.183c-0.099-0.731-0.336-1.516-0.673-2.333
						c-0.33-0.818-0.76-1.677-1.189-2.629c-0.427-0.953-0.852-2.018-1.077-3.215c-0.107-0.64-0.148-1.111-0.221-1.621
						c-0.07-0.504-0.15-1.003-0.232-1.499c-0.164-0.989-0.338-1.962-0.474-2.908c-0.131-0.945-0.247-1.852-0.249-2.763
						c0-0.888,0.092-1.672,0.15-2.372c0.062-0.7,0.097-1.317,0.069-1.858c-0.027-0.539-0.099-1.007-0.211-1.377
						c-0.044-0.191-0.111-0.35-0.162-0.493c-0.048-0.145-0.104-0.261-0.149-0.355C305.342,144.078,305.295,143.977,305.295,143.977z
						"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M265.222,167.298c0,0-0.122,0.005-0.353,0.016c-0.116,0.003-0.261,0.012-0.432,0.014
						c-0.171-0.005-0.369-0.011-0.592-0.017c-0.223-0.012-0.471-0.043-0.746-0.072c-0.27-0.053-0.571-0.089-0.886-0.185
						c-0.631-0.168-1.356-0.457-2.053-0.991c-0.703-0.523-1.322-1.324-1.74-2.264c-0.422-0.942-0.657-2.004-0.783-3.095
						c-0.066-0.547-0.101-1.104-0.123-1.666c-0.007-0.28-0.015-0.564-0.022-0.849l-0.002-0.213l-0.001-0.094l-0.003-0.007
						c-0.004-0.009-0.009-0.017-0.013-0.023c-0.033-0.049-0.056,0-0.064,0.025l-0.001,0.004c0,0,0-0.002-0.001-0.004
						c-0.003,0.001,0.004-0.007-0.009,0.001l-0.169,0.112c-0.095,0.065-0.206,0.122-0.309,0.181l-1.326,0.755l-0.274-1.523
						c-0.158-0.877-0.031-1.753,0.212-2.479c0.242-0.733,0.578-1.349,0.906-1.905c0.663-1.105,1.316-2.011,1.661-2.872l1.211,0.913
						c-0.573,0.326-1.067,0.439-1.503,0.568c-0.432,0.12-0.816,0.228-1.083,0.366l-1.901,0.986l1.026-1.742
						c0.43-0.729,0.765-1.367,0.969-1.956c0.052-0.146,0.095-0.289,0.131-0.427c0.038-0.148,0.057-0.222,0.08-0.336
						c0.033-0.206,0.064-0.439,0.034-0.626c-0.22-0.086-0.44-0.154-0.646-0.203c-0.201-0.049-0.418-0.081-0.569-0.096
						c-0.316-0.029-0.48-0.005-0.48-0.005s0.15-0.067,0.476-0.116c0.169-0.023,0.354-0.042,0.629-0.038
						c0.272,0.003,0.599,0.034,0.995,0.095c0.042,0.039,0.039,0.122,0.061,0.185c0.018,0.069,0.033,0.14,0.046,0.214
						c0.032,0.146,0.048,0.309,0.066,0.483c0.002,0.089,0.004,0.181,0.007,0.274c0.003,0.098-0.006,0.19-0.007,0.268
						c-0.008,0.165-0.023,0.336-0.049,0.512c-0.097,0.704-0.344,1.479-0.666,2.292l-0.875-0.756
						c0.418-0.319,0.857-0.506,1.273-0.697c0.413-0.186,0.831-0.373,1.096-0.586l1.831-1.471l-0.62,2.383
						c-0.311,1.199-0.937,2.269-1.45,3.331c-0.257,0.529-0.487,1.059-0.622,1.571c-0.136,0.514-0.169,0.995-0.074,1.455l-1.6-0.768
						c0.04-0.029,0.082-0.046,0.125-0.08l0.065-0.045l0.174-0.152c0.248-0.212,0.489-0.525,0.731-0.871
						c0.48-0.724,1.046-1.798,1.642-2.835c0.372-0.656,0.741-1.31,1.047-1.851c0.001,1.064,0.003,2.116,0.004,3.15
						c-0.003,0.557-0.007,1.109-0.01,1.657c-0.008,0.554-0.03,1.124-0.054,1.68c-0.035,0.736-0.07,1.459-0.103,2.167
						c-0.023,0.531-0.038,1.046-0.029,1.54c0.011,0.989,0.104,1.903,0.333,2.698c0.229,0.793,0.592,1.469,1.074,1.982
						c0.475,0.52,1.037,0.89,1.568,1.155c0.262,0.144,0.528,0.235,0.768,0.339c0.247,0.081,0.473,0.164,0.681,0.225
						c0.21,0.057,0.396,0.107,0.559,0.151c0.165,0.036,0.304,0.066,0.416,0.091C265.103,167.273,265.222,167.298,265.222,167.298z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M273.762,253.687c0,0-0.037-0.265-0.107-0.769c-0.039-0.251-0.107-0.559-0.176-0.923
						c-0.091-0.359-0.179-0.778-0.32-1.24c-0.262-0.927-0.653-2.039-1.139-3.321c-0.481-1.284-1.06-2.742-1.54-4.424
						c-0.23-0.843-0.447-1.738-0.569-2.694c-0.125-0.936-0.265-1.893-0.45-2.887c-0.362-1.986-0.865-4.088-1.408-6.306
						c-0.271-1.109-0.55-2.249-0.817-3.419c-0.262-1.172-0.538-2.342-0.849-3.545c-0.612-2.4-1.314-4.885-1.859-7.492
						c-0.335-1.572-0.576-3.154-0.74-4.698c-0.162-1.544-0.264-3.055-0.352-4.518c-0.17-2.925-0.317-5.666-0.618-8.139l0.87-0.042
						c-0.041,0.792-0.109,1.598-0.399,2.376c-0.146,0.386-0.359,0.762-0.653,1.07c-0.094,0.09-0.221,0.194-0.328,0.289
						c-0.118,0.083-0.241,0.154-0.359,0.231c-0.238,0.116-0.478,0.217-0.73,0.322l-0.092,0.038l-0.013-0.096l-0.026-0.192
						l-0.052-0.38c-0.02-0.267-0.016-0.553-0.024-0.822c0.008-0.392,0.033-0.715,0.066-1.064c0.069-0.683,0.174-1.336,0.284-1.963
						c0.223-1.254,0.461-2.409,0.6-3.486c0.067-0.538,0.111-1.054,0.125-1.55c0.013-0.497,0.003-0.974-0.006-1.428
						c-0.021-0.909-0.04-1.727,0.002-2.438c0.042-0.711,0.134-1.314,0.268-1.787c0.131-0.474,0.279-0.82,0.393-1.046
						c0.119-0.222,0.181-0.337,0.181-0.337s-0.054,0.118-0.159,0.347c-0.099,0.23-0.225,0.583-0.327,1.057
						c-0.105,0.473-0.161,1.07-0.166,1.772c-0.005,0.702,0.056,1.509,0.122,2.417c0.031,0.455,0.064,0.935,0.075,1.444
						c0.01,0.51-0.012,1.044-0.056,1.597c-0.094,1.104-0.287,2.283-0.459,3.53c-0.085,0.624-0.164,1.267-0.206,1.925
						c-0.008,0.165-0.016,0.33-0.024,0.497c-0.001,0.16-0.003,0.32-0.005,0.482l0.012,0.201l0.01,0.086l0.005,0.042l0.002,0.021
						l0.022-0.011c0.122-0.059,0.204-0.113,0.298-0.226c0.202-0.227,0.354-0.507,0.462-0.821c0.217-0.631,0.27-1.368,0.287-2.127
						l0.87-0.043c0.376,2.501,0.587,5.269,0.8,8.185c0.216,2.918,0.468,5.974,1.14,9.058c0.534,2.557,1.215,5.036,1.802,7.461
						c0.297,1.208,0.563,2.42,0.802,3.587c0.254,1.167,0.503,2.309,0.745,3.423c0.493,2.232,0.941,4.37,1.243,6.394
						c0.154,1.01,0.265,1.999,0.355,2.938c0.083,0.92,0.26,1.797,0.451,2.629c0.401,1.662,0.912,3.14,1.332,4.448
						c0.425,1.307,0.761,2.448,0.974,3.395c0.117,0.471,0.181,0.898,0.252,1.264c0.048,0.371,0.098,0.683,0.121,0.937
						C273.742,253.42,273.762,253.687,273.762,253.687z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M282.303,144.634c0,0-0.053-0.134-0.155-0.392c-0.098-0.259-0.227-0.65-0.356-1.167
						c-0.262-1.033-0.491-2.575-0.615-4.513c-0.126-1.941-0.161-4.257-0.28-6.871c-0.101-2.616-0.219-5.531-0.189-8.664l1.037,0.197
						c-0.509,1.441-0.844,3.213-1.002,5.06c-0.303,3.659-0.106,7.213-0.225,10.469c-0.01,0.406-0.04,0.81-0.058,1.207
						c-0.024,0.391-0.043,0.767-0.051,1.144c-0.016,0.752,0.003,1.484,0.043,2.193c0.081,1.418,0.243,2.743,0.379,3.958
						c0.167,1.225,0.147,2.356,0.139,3.322c-0.012,0.971-0.017,1.803,0.028,2.482c0.042,0.679,0.118,1.203,0.193,1.554
						c0.075,0.351,0.127,0.533,0.127,0.533s-0.068-0.176-0.174-0.521c-0.106-0.346-0.226-0.867-0.321-1.548
						c-0.097-0.681-0.154-1.521-0.208-2.491c-0.058-0.973-0.106-2.061-0.339-3.251c-0.205-1.205-0.439-2.529-0.588-3.976
						c-0.074-0.723-0.126-1.477-0.14-2.256c-0.007-0.389-0.001-0.788,0.008-1.184c0.004-0.39,0.02-0.783,0.016-1.184
						c0.016-3.204-0.256-6.766,0.055-10.548c0.164-1.91,0.531-3.784,1.114-5.333l1.123-2.985l-0.086,3.182
						c-0.084,3.098-0.06,6.01-0.07,8.628c0.009,2.62-0.083,4.949-0.078,6.871c0.003,1.925,0.117,3.46,0.295,4.501
						c0.087,0.52,0.182,0.918,0.257,1.184C282.262,144.496,282.303,144.634,282.303,144.634z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M282.303,95.035c0,0,0.025,0.092,0.075,0.272c0.063,0.175,0.152,0.438,0.337,0.743
						c0.183,0.307,0.456,0.651,0.826,1.024c0.368,0.376,0.836,0.779,1.336,1.304l0.418,0.439l-0.642,0.17
						c-0.331,0.088-0.722,0.048-0.884,0.135l0.211-0.733c0.366,0.368,0.767,0.807,1.035,1.461c0.13,0.325,0.215,0.719,0.176,1.14
						c-0.034,0.422-0.205,0.841-0.444,1.183c-0.486,0.693-1.161,1.131-1.852,1.491c-0.174,0.09-0.352,0.173-0.533,0.25
						c-0.09,0.04-0.181,0.077-0.272,0.114l-0.255,0.1c-0.062,0.034-0.125,0.068-0.188,0.104c0.025,0.064,0.077,0.131,0.108,0.177
						l0.013,0.017c0.007,0.011,0-0.008,0.026,0.047l0.118,0.242c0.082,0.167,0.165,0.335,0.249,0.504
						c0.167,0.354,0.371,0.751,0.492,1.305c0.03,0.135,0.043,0.287,0.06,0.432c0.004,0.147,0.004,0.3-0.005,0.447
						c-0.018,0.142-0.035,0.291-0.064,0.427c-0.033,0.131-0.066,0.266-0.105,0.394c-0.164,0.498-0.379,0.9-0.596,1.301
						c-0.263,0.445-0.564,0.833-0.879,1.185c-0.634,0.694-1.319,1.272-2.002,1.829c-0.614,0.497-1.217,0.961-1.768,1.421
						c-0.278,0.23-0.536,0.46-0.774,0.688c-0.211,0.206-0.487,0.508-0.74,0.769c-1.025,1.065-2.02,2.052-2.95,3.008
						c-1.862,1.904-3.462,3.672-4.646,5.277c-1.188,1.599-1.938,3.04-2.275,4.109c-0.095,0.265-0.146,0.508-0.204,0.718
						c-0.04,0.212-0.078,0.394-0.101,0.541c-0.034,0.298-0.052,0.455-0.052,0.455s-0.015-0.158-0.045-0.459
						c-0.007-0.151-0.007-0.339-0.008-0.563c0.017-0.221,0.022-0.482,0.068-0.769c0.141-1.158,0.667-2.802,1.672-4.632
						c0.997-1.833,2.443-3.855,4.187-5.941c0.869-1.044,1.816-2.109,2.777-3.194c0.246-0.275,0.457-0.529,0.766-0.855
						c0.289-0.296,0.586-0.573,0.885-0.833c0.598-0.521,1.206-1,1.799-1.479c0.656-0.532,1.28-1.055,1.788-1.586
						c0.251-0.266,0.463-0.532,0.63-0.787c0.185-0.313,0.362-0.625,0.461-0.887c0.096-0.27,0.127-0.428,0.097-0.639
						c-0.027-0.215-0.142-0.529-0.28-0.867c-0.072-0.177-0.144-0.351-0.215-0.525c-0.094-0.24-0.188-0.478-0.281-0.714
						c-0.221-0.625-0.439-1.244-0.614-1.817c0.702-0.154,1.405-0.29,2.027-0.467l0.267-0.075c0.081-0.023,0.161-0.047,0.239-0.073
						c0.158-0.049,0.311-0.104,0.459-0.161c0.59-0.226,1.113-0.521,1.415-0.861c0.309-0.334,0.384-0.691,0.285-1.128
						c-0.102-0.432-0.361-0.858-0.627-1.222l-0.425-0.582l0.636-0.151c0.255-0.061,0.48-0.042,0.639-0.029
						c0.163,0.015,0.274,0.023,0.363,0.019l-0.224,0.609c-0.388-0.553-0.793-1.034-1.107-1.486c-0.317-0.45-0.542-0.87-0.671-1.224
						c-0.132-0.355-0.168-0.64-0.195-0.826C282.309,95.131,282.303,95.035,282.303,95.035z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M281.974,217.883c0,0-0.297-0.127-0.862-0.368c-0.547-0.276-1.377-0.671-2.316-1.395
						c-0.939-0.717-1.971-1.771-2.958-3.115c-0.501-0.667-0.998-1.398-1.526-2.156c-0.132-0.189-0.267-0.38-0.403-0.574
						c-0.068-0.096-0.137-0.193-0.206-0.291c-0.041-0.06-0.058-0.079-0.075-0.1l-0.054-0.067c-0.149-0.188-0.268-0.324-0.372-0.427
						c-0.059,0.119-0.122,0.245-0.188,0.375l-0.042,0.085l-0.02,0.041l-0.01,0.021c-0.002,0.004-0.006,0.011-0.023,0.038
						c-0.106,0.173-0.218,0.35-0.337,0.528c-0.236,0.356-0.497,0.722-0.796,1.082c-0.076,0.094-0.145,0.173-0.238,0.275
						c-0.125,0.145-0.269,0.273-0.406,0.408c-0.278,0.27-0.574,0.503-0.877,0.764c-0.087-0.025-0.184-0.074-0.282-0.125
						l-0.293-0.148l-0.28-0.163c-0.09-0.063-0.195-0.093-0.274-0.183l-0.254-0.236l-0.128-0.119
						c-0.039-0.043-0.071-0.093-0.106-0.139l-0.21-0.284l-0.104-0.141l-0.061-0.107c-0.333-0.575-0.549-1.159-0.717-1.746
						c-0.337-1.174-0.517-2.372-0.644-3.593c-0.249-2.442-0.267-4.988-0.241-7.575c0.009-1.198,0.04-2.408,0.003-3.316l2.09,0.429
						c-0.857,1.692-1.422,3.587-2.068,5.41c-0.322,0.915-0.663,1.82-1.06,2.693c-0.2,0.436-0.406,0.866-0.642,1.281l-0.172,0.311
						l-0.31,0.483l-0.309,0.479c-0.048,0.086-0.105,0.148-0.164,0.206l-0.17,0.182l-0.337,0.36l-0.083,0.089
						c-0.029,0.021-0.05,0.086-0.093,0.04l-0.228-0.135c-0.307-0.186-0.582-0.343-0.849-0.551l-0.376-0.355
						c-0.114-0.134-0.215-0.286-0.321-0.426c-0.051-0.072-0.107-0.139-0.152-0.217l-0.112-0.217
						c-0.053-0.112-0.117-0.224-0.154-0.336c-0.353-0.899-0.466-1.761-0.519-2.571c-0.088-1.622,0.073-3.07,0.235-4.378
						c0.359-2.61,0.813-4.652,1.063-6.07c0.267-1.415,0.323-2.205,0.323-2.205s0.066,0.794-0.017,2.24
						c-0.065,1.445-0.271,3.538-0.366,6.118c-0.032,1.285-0.052,2.706,0.149,4.157c0.104,0.719,0.276,1.449,0.576,2.067
						c0.032,0.082,0.082,0.146,0.122,0.219c0.033,0.056,0.041,0.067,0.063,0.09c0.036,0.044,0.076,0.079,0.117,0.11
						c0.043,0.028,0.084,0.054,0.129,0.066l0.032,0.011c0.01,0.003,0.014-0.021,0.021-0.031l0.037-0.073
						c0.022-0.044,0.046-0.099,0.068-0.149l0.033-0.077l0.135-0.284c0.186-0.375,0.351-0.773,0.512-1.181
						c0.32-0.815,0.603-1.683,0.88-2.586c0.564-1.806,1.069-3.771,2.031-5.764l1.84-3.814l0.249,4.243
						c0.065,1.094,0.039,2.263,0.031,3.473c-0.026,2.573-0.052,5.058,0.107,7.371c0.079,1.153,0.207,2.267,0.441,3.266
						c0.115,0.499,0.27,0.964,0.459,1.338l0.067,0.138c0.016,0.032,0.026,0.044,0.041,0.069c0.028,0.035,0.072,0.096,0.117,0.094
						c0.035-0.02,0.072-0.042,0.11-0.07c0.019-0.014,0.038-0.027,0.059-0.044c0.056-0.054,0.134-0.132,0.198-0.201
						c0.268-0.283,0.519-0.585,0.756-0.893c0.119-0.154,0.234-0.309,0.348-0.465c0.185-0.268,0.366-0.532,0.545-0.791
						c0.35-0.507,0.688-0.995,1.009-1.46c0.238,0.354,0.473,0.703,0.705,1.048c0.232,0.344,0.427,0.659,0.632,0.976l0.15,0.234
						c0.051,0.079,0.103,0.159,0.126,0.203c0.062,0.105,0.123,0.21,0.184,0.314c0.12,0.208,0.239,0.414,0.355,0.616
						c0.463,0.811,0.882,1.575,1.301,2.266c0.822,1.392,1.674,2.482,2.489,3.279c0.813,0.8,1.568,1.292,2.069,1.639
						C281.699,217.715,281.974,217.883,281.974,217.883z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M299.383,204.744c0,0,0.444-0.203,1.269-0.525c0.415-0.158,0.927-0.345,1.551-0.51
						c0.156-0.04,0.319-0.081,0.49-0.115c0.077-0.02,0.224-0.04,0.343-0.054c0.126-0.015,0.258-0.037,0.391-0.046
						c0.542-0.05,1.09-0.025,1.737,0c0.026,0.062,0.074,0.098,0.079,0.187c0.005,0.091,0.01,0.182,0.015,0.274
						c0.005,0.177,0.009,0.36,0.004,0.545c-0.018,0.374-0.128,0.796-0.341,1.245c-0.13,0.218-0.243,0.448-0.414,0.673l-0.088,0.126
						c-0.025,0.038-0.052,0.07-0.078,0.105l-0.16,0.21c-0.221,0.271-0.457,0.534-0.707,0.786c-1,1.001-2.197,1.876-3.451,2.7
						c-0.739,0.489-1.489,0.937-2.095,1.362c-0.648,0.459-1.262,0.933-1.846,1.4c-1.177,0.964-2.238,1.832-3.129,2.562
						c-0.913,0.733-1.685,1.296-2.232,1.663c-0.272,0.185-0.49,0.321-0.637,0.412c-0.148,0.091-0.226,0.138-0.226,0.138
						s0.878-1.147,2.296-2.997c0.711-0.921,1.565-2.022,2.649-3.167c0.543-0.572,1.145-1.153,1.818-1.708
						c0.72-0.586,1.439-1.048,2.173-1.535c1.229-0.807,2.397-1.55,3.352-2.302c0.238-0.188,0.458-0.377,0.663-0.564
						c0.091-0.089,0.182-0.177,0.271-0.264c0.025-0.01,0.058-0.037,0.09-0.061c0.064-0.051,0.134-0.122,0.197-0.214
						c0.032-0.046,0.059-0.098,0.086-0.154l0.032-0.088c0.011-0.03-0.021-0.025-0.03-0.04c-0.096-0.055-0.227-0.102-0.386-0.136
						c-0.042-0.008-0.083-0.016-0.124-0.023c-0.05-0.007-0.07-0.016-0.159-0.018c-0.158-0.007-0.313-0.007-0.464-0.008
						c-0.604,0.001-1.136,0.034-1.572,0.061C299.875,204.723,299.383,204.744,299.383,204.744z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M285.259,175.839c0,0,0.102-0.089,0.294-0.257c0.196-0.165,0.486-0.408,0.876-0.701
						c0.771-0.597,1.957-1.371,3.386-2.34c0.352-0.245,0.733-0.504,1.088-0.783c0.359-0.285,0.719-0.609,1.089-0.953
						c0.371-0.344,0.753-0.71,1.165-1.079c0.207-0.184,0.422-0.368,0.648-0.55c0.116-0.089,0.228-0.182,0.35-0.268l0.223-0.158
						c0.108-0.073,0.219-0.133,0.33-0.198l0.336-0.194l0.331-0.156c0.231-0.107,0.464-0.215,0.699-0.324l0.045-0.021
						c0.002,0.01-0.002,0.03-0.002,0.044l-0.008,0.094l-0.016,0.188l-0.031,0.381c-0.022,0.258-0.044,0.518-0.067,0.778
						c-0.014,0.136-0.034,0.275-0.064,0.415l-0.053,0.284c-0.031,0.155-0.051,0.314-0.094,0.467
						c-0.083,0.308-0.145,0.626-0.253,0.932c-0.385,1.24-0.956,2.462-1.659,3.619c-1.411,2.321-3.08,4.237-4.457,6.046
						c-1.393,1.798-2.497,3.509-3.156,5.082c-0.342,0.781-0.549,1.526-0.71,2.186c-0.143,0.664-0.224,1.249-0.257,1.73
						c-0.014,0.241-0.027,0.455-0.038,0.641c0.001,0.186,0.002,0.344,0.003,0.472c0.001,0.256,0.001,0.391,0.001,0.391
						s-0.017-0.133-0.049-0.387c-0.016-0.128-0.035-0.285-0.059-0.472c-0.01-0.188-0.021-0.404-0.033-0.648
						c-0.019-0.489,0.001-1.09,0.083-1.786c0.1-0.691,0.246-1.484,0.534-2.322c0.551-1.687,1.587-3.552,2.918-5.442
						c1.316-1.902,2.918-3.854,4.259-6.066c0.671-1.104,1.214-2.234,1.599-3.37c0.108-0.281,0.171-0.571,0.256-0.852
						c0.045-0.14,0.067-0.285,0.1-0.426l0.023-0.106l0.011-0.049l0.002-0.005l0.009-0.022c0.008-0.027,0.024-0.072,0.029-0.109
						c-0.026,0.004-0.059,0.012-0.098,0.029l-0.031,0.014c0.007-0.007-0.082,0.052-0.129,0.082
						c-0.113,0.072-0.222,0.154-0.332,0.229c-0.218,0.158-0.431,0.321-0.64,0.488c-0.419,0.343-0.826,0.676-1.218,0.999
						c-0.399,0.325-0.794,0.636-1.199,0.912c-0.412,0.277-0.803,0.5-1.182,0.721c-1.52,0.861-2.76,1.503-3.586,2.011
						c-0.416,0.248-0.73,0.455-0.944,0.595C285.37,175.763,285.259,175.839,285.259,175.839z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{ fill: theme.dark }}
                    d="M246.718,90.984c0,0-0.082,0.016-0.242,0.031c-0.161,0.023-0.4,0.008-0.693-0.083
						c-0.292-0.095-0.623-0.304-0.895-0.623c-0.263-0.321-0.464-0.735-0.561-1.178c-0.179-0.895,0.026-1.686,0.261-2.196
						c0.113-0.258,0.241-0.452,0.335-0.579c0.096-0.126,0.154-0.19,0.154-0.19s0.004,0.087,0.013,0.242
						c0.008,0.152,0.02,0.367,0.049,0.624c0.047,0.514,0.145,1.189,0.282,1.864c0.076,0.336,0.16,0.635,0.261,0.901
						c0.109,0.259,0.241,0.483,0.396,0.659C246.389,90.808,246.729,90.971,246.718,90.984z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default FeelingProud;
