import React, { useState } from "react";
import "./watch.css";
import { useEffect } from "react";
import moment from "moment";

export default function Watch(props) {
  const [clkInt, setClkInt] = useState("");
  const clock = () => {
    const weeks = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const clkInterval = setInterval(() => {
      let hours = document.getElementById("hours");
      let minutes = document.getElementById("minutes");
      let seconds = document.getElementById("seconds");
      let ampm = document.getElementById("ampm");

      let hh = document.getElementById("hh");
      let mm = document.getElementById("mm");
      let ss = document.getElementById("ss");

      let hr_dot = document.querySelector(".hr_dot");
      let min_dot = document.querySelector(".min_dot");
      let sec_dot = document.querySelector(".sec_dot");

      let w = weeks[new Date().getDay()];
      let ww = document.querySelector("." + w);
      // ww.style.color = props.theme.text;
      ww.style.opacity = 1;
      //  ww.style.textShadow = "0px 0px 5px " + 'black';
      let dt = document.querySelector("#date");
      dt.innerHTML = moment().format("MMMM D, YYYY");

      let h = new Date().getHours();
      let m = new Date().getMinutes();
      let s = new Date().getSeconds();
      let am = h >= 12 ? "PM" : "AM";
      // convert 24hr clock to 12hr clock
      if (h > 12) {
        h = h - 12;
      }
      // add zero before single digit number
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      hours.innerHTML = h + "<br/><span>Hours</span>";
      minutes.innerHTML = m + "<br/><span>Minutes</span>";
      seconds.innerHTML = s + "<br/><span>Seconds</span>";
      ampm.innerHTML = am;

      hh.style.strokeDashoffset = 252 - (252 * h) / 12;
      mm.style.strokeDashoffset = 252 - (252 * m) / 60;
      ss.style.strokeDashoffset = 252 - (252 * s) / 60;

      hr_dot.style.transform = `rotate(${h * 30}deg)`;
      min_dot.style.transform = `rotate(${m * 6}deg)`;
      sec_dot.style.transform = `rotate(${s * 6}deg)`;
    });
    setClkInt(clkInterval);
  };

  useEffect(() => {
    // clearInterval(clkInt);
    clock();
  }, [props.theme]);

  return (
    <div className="watch" style={{ color: props.theme.text }}>
      <div>
        <div className="weeks">
          <div className="sunday">SUN</div>
          <div className="monday">MON</div>
          <div className="tuesday">TUE</div>
          <div className="wednesday">WED</div>
          <div className="thursday">THU</div>
          <div className="friday">FRI</div>
          <div className="saturday">SAT</div>
        </div>
        <div id="time">
          <div className="circle" style={{ "--clr": "#ff2972" }}>
            <div className="dots hr_dot"></div>
            <svg>
              <circle cx="45" cy="45" r="40"></circle>
              <circle cx="45" cy="45" r="40" id="hh"></circle>
            </svg>
            <div id="hours">
              00
              <br />
              <span>Hours</span>
            </div>
          </div>
          <div className="circle" style={{ "--clr": "#fee800" }}>
            <div className="dots min_dot"></div>
            <svg>
              <circle cx="45" cy="45" r="40"></circle>
              <circle cx="45" cy="45" r="40" id="mm"></circle>
            </svg>
            <div id="minutes">
              00
              <br />
              <span>Minutes</span>
            </div>
          </div>
          <div className="circle" style={{ "--clr": "#04fc43" }}>
            <div className="dots sec_dot"></div>
            <svg>
              <circle cx="45" cy="45" r="40"></circle>
              <circle cx="45" cy="45" r="40" id="ss"></circle>
            </svg>
            <div id="seconds">
              00
              <br />
              <span>Seconds</span>
            </div>
          </div>
          <div className="ap">
            <div id="ampm">AM</div>
          </div>
        </div>
        <div id="date"></div>
      </div>
    </div>
  );
}
