import React, { Component } from "react";

export default class EducationImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        id="eb113788-f1f1-4c1f-be62-f1d0ea2e1eb6"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        width="795.39431"
        height="574.03802"
        viewBox="0 0 500 500"
      >

<g id="Background_Complete">
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:'#FAFAFA'}} d="M68.335,406.932c-0.811-2.164-1.638-4.359-3.001-6.226c-1.364-1.867-3.357-3.395-5.651-3.676
						c-2.294-0.281-4.836,0.957-5.584,3.145c-0.329-4.929-1.028-9.986-3.495-14.265c-2.467-4.28-7.071-7.642-12.002-7.34
						c-4.649,0.284-8.629,3.738-10.841,7.837c-2.212,4.099-2.971,8.801-3.697,13.402c-1.762-2.163-5.484-1.483-7.323,0.615
						c-1.839,2.098-2.305,5.029-2.696,7.791h55.446L68.335,406.932z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.jacketColor}} d="M453.541,190.738c-1.288-3.437-2.6-6.922-4.766-9.886c-2.165-2.964-5.33-5.391-8.973-5.837
						c-3.643-0.446-7.68,1.52-8.867,4.994c-0.523-7.827-1.632-15.856-5.549-22.652c-3.918-6.796-11.228-12.134-19.057-11.655
						c-7.382,0.451-13.703,5.935-17.215,12.444c-3.512,6.509-4.718,13.975-5.871,21.281c-2.798-3.434-8.707-2.355-11.628,0.976
						c-2.921,3.331-3.661,7.986-4.281,12.372h88.043L453.541,190.738z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.jacketColor}} d="M125.138,374.554c-1.144-3.055-2.311-6.152-4.236-8.786c-1.924-2.634-4.737-4.792-7.975-5.188
						c-3.238-0.396-6.825,1.351-7.88,4.438c-0.465-6.956-1.45-14.093-4.932-20.132c-3.482-6.04-9.979-10.784-16.937-10.359
						c-6.561,0.401-12.178,5.275-15.3,11.06c-3.121,5.785-4.193,12.421-5.218,18.914c-2.487-3.052-7.739-2.093-10.334,0.867
						c-2.596,2.96-3.253,7.098-3.805,10.996h78.25L125.138,374.554z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.jacketColor}} d="M384.073,98.052c-1.144-3.055-2.311-6.152-4.236-8.786c-1.924-2.634-4.737-4.792-7.975-5.188
						c-3.238-0.396-6.825,1.351-7.881,4.438c-0.464-6.956-1.45-14.093-4.932-20.132c-3.482-6.04-9.979-10.784-16.937-10.359
						c-6.561,0.401-12.178,5.275-15.3,11.06c-3.122,5.785-4.193,12.421-5.217,18.914c-2.487-3.052-7.739-2.093-10.334,0.867
						c-2.596,2.96-3.254,7.098-3.805,10.996h78.25L384.073,98.052z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.jacketColor}} d="M424.596,446.991c-1.144-3.055-2.311-6.152-4.236-8.786c-1.924-2.634-4.737-4.791-7.975-5.188
						c-3.238-0.396-6.825,1.351-7.88,4.438c-0.465-6.956-1.45-14.093-4.932-20.132c-3.482-6.04-9.979-10.784-16.937-10.359
						c-6.561,0.401-12.178,5.275-15.3,11.06c-3.121,5.785-4.193,12.421-5.217,18.914c-2.487-3.052-7.739-2.093-10.334,0.867
						c-2.596,2.96-3.254,7.098-3.805,10.996h78.25L424.596,446.991z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:'#F5F5F5'}} d="M121.027,267.309c-0.731-1.95-1.475-3.928-2.704-5.609c-1.229-1.682-3.024-3.059-5.091-3.312
						c-2.067-0.253-4.357,0.863-5.031,2.833c-0.297-4.441-0.926-8.997-3.149-12.852c-2.223-3.856-6.371-6.885-10.813-6.613
						c-4.189,0.256-7.775,3.367-9.768,7.061c-1.993,3.693-2.677,7.929-3.331,12.075c-1.588-1.948-4.94-1.336-6.597,0.554
						c-1.657,1.89-2.077,4.531-2.429,7.02h49.955L121.027,267.309z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:'#F5F5F5'}} d="M484.077,250.361c-0.731-1.95-1.476-3.928-2.704-5.609c-1.228-1.682-3.024-3.059-5.091-3.312
						c-2.067-0.253-4.357,0.863-5.031,2.833c-0.297-4.441-0.926-8.997-3.149-12.853c-2.223-3.856-6.37-6.885-10.813-6.613
						c-4.189,0.256-7.775,3.368-9.768,7.061c-1.993,3.693-2.677,7.929-3.331,12.074c-1.588-1.948-4.94-1.336-6.597,0.554
						c-1.657,1.89-2.077,4.531-2.429,7.02h49.955L484.077,250.361z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:'#F5F5F5'}} d="M266.147,241.903c-0.731-1.95-1.476-3.928-2.704-5.609c-1.229-1.682-3.024-3.059-5.091-3.312
						c-2.067-0.253-4.357,0.863-5.031,2.833c-0.297-4.441-0.926-8.997-3.149-12.853c-2.223-3.856-6.37-6.885-10.813-6.613
						c-4.189,0.256-7.775,3.368-9.768,7.061c-1.993,3.693-2.677,7.929-3.331,12.074c-1.588-1.948-4.94-1.336-6.597,0.554
						c-1.657,1.89-2.077,4.531-2.429,7.02h49.955L266.147,241.903z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:'#F5F5F5'}} d="M124.206,91.062c-0.73-1.95-1.476-3.928-2.704-5.609c-1.229-1.682-3.024-3.059-5.091-3.312
						s-4.357,0.863-5.031,2.833c-0.297-4.441-0.926-8.997-3.149-12.852c-2.223-3.856-6.37-6.885-10.813-6.613
						c-4.189,0.256-7.775,3.368-9.768,7.061c-1.993,3.693-2.677,7.929-3.331,12.075c-1.588-1.948-4.94-1.336-6.597,0.554
						c-1.657,1.89-2.077,4.531-2.429,7.02h49.955L124.206,91.062z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M189.592,221.985l4.622,1.738l-4.013,1.138c-1.339,0.38-2.402,1.401-2.835,2.724l-1.452,4.438
					l-1.786-4.451c-0.443-1.105-1.336-1.969-2.455-2.375l-4.059-1.474l4.397-1.806c1.064-0.437,1.901-1.293,2.314-2.367l1.589-4.127
					l1.139,3.837C187.426,220.516,188.366,221.524,189.592,221.985z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M84.387,266.727l4.622,1.738l-4.013,1.138c-1.339,0.38-2.402,1.401-2.835,2.724l-1.452,4.438
					l-1.785-4.451c-0.443-1.105-1.336-1.969-2.455-2.375l-4.059-1.474l4.397-1.806c1.064-0.437,1.901-1.293,2.314-2.367l1.589-4.127
					l1.139,3.837C82.221,265.257,83.161,266.266,84.387,266.727z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M112.82,438.833l4.622,1.738l-4.013,1.138c-1.339,0.38-2.402,1.401-2.835,2.724l-1.452,4.438
					l-1.785-4.451c-0.443-1.105-1.336-1.969-2.455-2.375l-4.059-1.474l4.397-1.806c1.064-0.437,1.901-1.293,2.314-2.367l1.589-4.127
					l1.139,3.837C110.654,437.364,111.594,438.372,112.82,438.833z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M377.035,369.983l4.265,1.603l-3.703,1.05c-1.236,0.351-2.217,1.293-2.616,2.514l-1.34,4.096
					l-1.648-4.108c-0.409-1.02-1.233-1.817-2.266-2.192l-3.746-1.36l4.058-1.667c0.982-0.403,1.754-1.193,2.136-2.184l1.466-3.808
					l1.052,3.541C375.036,368.627,375.903,369.557,377.035,369.983z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M263.672,45.807l4.265,1.604l-3.703,1.05c-1.236,0.35-2.217,1.293-2.616,2.514l-1.34,4.096
					l-1.648-4.108c-0.409-1.02-1.233-1.817-2.266-2.192l-3.746-1.36l4.058-1.667c0.982-0.403,1.754-1.193,2.136-2.184l1.466-3.808
					l1.052,3.541C261.673,44.451,262.54,45.382,263.672,45.807z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M459.663,235.503l7.049,2.65l-6.12,1.736c-2.043,0.579-3.663,2.137-4.323,4.154l-2.215,6.768
					l-2.723-6.788c-0.676-1.685-2.038-3.002-3.744-3.622l-6.191-2.248l6.706-2.755c1.623-0.667,2.899-1.972,3.53-3.609l2.423-6.294
					l1.738,5.852C456.36,233.262,457.793,234.8,459.663,235.503z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M422.373,368.936l7.049,2.65l-6.12,1.736c-2.043,0.579-3.663,2.137-4.323,4.154l-2.214,6.768
					l-2.723-6.788c-0.676-1.685-2.038-3.002-3.744-3.622l-6.191-2.248l6.706-2.755c1.623-0.667,2.899-1.972,3.53-3.609l2.423-6.294
					l1.738,5.852C419.071,366.695,420.503,368.233,422.373,368.936z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M285.824,76.204l7.049,2.65l-6.12,1.736c-2.043,0.579-3.663,2.137-4.323,4.154l-2.214,6.768
					l-2.723-6.788c-0.676-1.685-2.038-3.002-3.744-3.622l-6.191-2.248l6.706-2.755c1.623-0.667,2.899-1.972,3.53-3.61l2.423-6.294
					l1.738,5.852C282.521,73.963,283.954,75.502,285.824,76.204z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M260.632,238.121l9.607,3.611l-8.341,2.365c-2.784,0.789-4.992,2.912-5.892,5.661l-3.018,9.224
					l-3.711-9.251c-0.921-2.296-2.777-4.092-5.103-4.936l-8.437-3.064l9.139-3.755c2.212-0.909,3.951-2.688,4.81-4.919l3.302-8.577
					l2.368,7.976C256.131,235.067,258.083,237.163,260.632,238.121z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M101.718,71.706l9.607,3.611l-8.341,2.365c-2.784,0.789-4.992,2.912-5.892,5.661l-3.018,9.224
					l-3.711-9.251c-0.921-2.296-2.777-4.092-5.103-4.936l-8.437-3.064l9.138-3.755c2.212-0.909,3.951-2.688,4.81-4.919l3.302-8.577
					l2.368,7.975C97.217,68.652,99.17,70.748,101.718,71.706z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M82.563,407.935l9.607,3.611l-8.341,2.365c-2.784,0.789-4.992,2.912-5.892,5.661l-3.018,9.224
					l-3.711-9.251c-0.921-2.296-2.777-4.092-5.103-4.936l-8.437-3.064l9.138-3.755c2.212-0.908,3.951-2.688,4.81-4.919l3.302-8.577
					l2.368,7.975C78.062,404.881,80.014,406.977,82.563,407.935z"/>
			</g>
		</g>
	</g>
</g>
<g id="Graduation_Hats">
	<g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M425.329,296.22c-2.077-3.27-5.349-5.761-9.054-6.893c0.438,4.621-1.519,9.404-5.07,12.392
				c0.531-3.368,0.509-6.822-0.064-10.182c-0.147-0.862-0.368-1.785-1.037-2.349c-0.717-0.604-1.744-0.627-2.681-0.596
				c-3.848,0.128-7.682,0.663-11.418,1.596c2.023,5.422,1.143,11.834-2.265,16.512c-2.441,3.35-5.966,5.719-8.954,8.591
				c-2.989,2.872-5.585,6.685-5.249,10.816l1.362,4.18c9.3,0.576,18.516-3.081,25.952-8.696s13.271-13.081,18.403-20.858
				c0.411-0.623,0.83-1.282,0.899-2.025C426.235,297.822,425.806,296.972,425.329,296.22z"/>
			<g>
				<g>
					<path style={{fill:theme.imageHighlight}} d="M377.748,327.562c0.209,0.275-0.542,1.288-2.11,2.527c-1.561,1.238-3.975,2.648-6.869,3.638
						c-2.897,0.983-5.67,1.341-7.662,1.313c-1.999-0.024-3.212-0.368-3.214-0.714c0.004-0.754,4.683-1.205,10.037-3.064
						C373.302,329.467,377.284,326.967,377.748,327.562z"/>
				</g>
				<g>
					<path style={{fill:theme.imageHighlight}} d="M381.025,329.643c-0.435,2.915-3.151,4.926-6.066,4.491c-2.915-0.435-4.926-3.151-4.491-6.066
						c0.435-2.915,3.151-4.926,6.066-4.491C379.45,324.013,381.46,326.728,381.025,329.643z"/>
					<g>
						<path style={{fill:theme.dark}} d="M381.026,329.643c-0.052-0.005,0.03-0.476-0.074-1.303c-0.103-0.812-0.471-2.029-1.517-3.075
							c-1.006-1.043-2.798-1.805-4.698-1.4c-1.877,0.352-3.699,2.022-4.01,4.241c-0.351,2.214,0.904,4.343,2.597,5.228
							c1.699,0.943,3.636,0.736,4.902,0.033c1.306-0.695,2.013-1.751,2.348-2.498C380.915,330.108,380.974,329.633,381.026,329.643
							c0.018,0.003,0.012,0.12-0.016,0.345c-0.023,0.224-0.096,0.554-0.248,0.958c-0.29,0.798-0.983,1.954-2.358,2.743
							c-1.333,0.796-3.434,1.074-5.309,0.067c-0.925-0.486-1.761-1.282-2.29-2.296c-0.549-1.004-0.763-2.229-0.594-3.431
							c0.189-1.198,0.751-2.307,1.57-3.107c0.802-0.815,1.834-1.332,2.86-1.527c2.087-0.416,4.015,0.463,5.057,1.614
							c1.085,1.156,1.41,2.463,1.455,3.312c0.027,0.431,0.001,0.767-0.043,0.989C381.072,329.532,381.043,329.646,381.026,329.643z"
							/>
					</g>
				</g>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M391.727,320.962c0.073,0.119-2.278,1.681-5.349,3.311c-3.069,1.635-5.678,2.712-5.736,2.585
					c-0.062-0.134,2.436-1.417,5.491-3.045C389.19,322.191,391.651,320.836,391.727,320.962z"/>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M404.872,314.293c0.029,0.022-0.195,0.356-0.627,0.943c-0.424,0.593-1.099,1.406-1.985,2.362
					c-1.765,1.917-4.502,4.323-7.94,6.354c-3.446,2.022-6.878,3.247-9.411,3.86c-1.266,0.31-2.304,0.506-3.028,0.589
					c-0.723,0.092-1.123,0.126-1.129,0.091c-0.015-0.094,1.577-0.331,4.062-1.036c2.484-0.699,5.843-1.96,9.242-3.953
					c3.391-2.004,6.124-4.329,7.94-6.161C403.817,315.511,404.797,314.235,404.872,314.293z"/>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M386.599,317.951c0.107,0.103-1.446,1.871-3.272,4.118c-1.832,2.244-3.249,4.122-3.372,4.038
					c-0.115-0.077,1.12-2.103,2.968-4.367C384.766,319.473,386.5,317.853,386.599,317.951z"/>
			</g>
			<path style={{fill:theme.imageHighlight}} d="M399.08,300.197c-0.818-2.471-2.509-4.582-4.491-6.268c-0.904-0.769-1.909-1.477-3.068-1.732
				c-1.999-0.441-4.01,0.528-5.828,1.469c3.092,1.111,5.534,3.874,6.257,7.079c0.723,3.205-0.296,6.749-2.611,9.081
				c-1.868,1.882-4.424,2.94-6.371,4.741c-2.825,2.613-4.106,6.786-3.235,10.534l18.621-17.386
				C399.76,305.523,399.898,302.668,399.08,300.197z"/>
		</g>
		<g>
			<polygon style={{fill:theme.dark}} points="97.209,326.602 219.519,274.7 385.795,354.036 304.891,378.738 175.329,359.983 			"/>
			<g>
				<polygon style={{fill:theme.dark}} points="97.549,319.894 97.209,326.602 219.519,274.7 221.243,266.221 				"/>
				<polygon style={{fill:theme.dark}} points="385.795,354.036 387.286,346.705 221.243,266.221 219.519,274.7 				"/>
				<polygon style={{opacity:0.3,fill:'#FFFFFF'}} points="385.795,354.036 387.286,346.705 221.243,266.221 219.519,274.7 				"/>
			</g>
			<polygon style={{opacity:0.4}} points="97.209,326.602 219.519,274.7 385.795,354.036 304.891,378.738 175.329,359.983 			"/>
			<g>
				<polygon style={{fill:theme.dark}} points="150.502,390.321 163.024,328.734 316.775,359.996 305.881,421.249 				"/>
				
					<ellipse transform="matrix(0.1851 -0.9827 0.9827 0.1851 -141.7588 515.6715)" style={{fill:theme.dark}} cx="240.045" cy="343.309" rx="26.475" ry="78.454"/>
			</g>
			<path style={{opacity:0.3,fill:'#FFFFFF'}} d="M299.711,336.147l-14.788,65.441l20.793,20.291l10.972-59.497
				c1.152-5.666-0.421-11.567-4.32-15.837C309.119,342.989,304.741,338.928,299.711,336.147z"/>
			<path style={{opacity:0.2}} d="M150.534,390.327l12.326-60.623c0.026-0.304,0.062-0.607,0.118-0.908
				c2.706-14.369,39.418-19.517,81.998-11.499l-8.594,42.265c-2.338,11.5-13.075,19.289-24.736,17.967
				c-10.482-1.188-22.945-2.098-32.2-1.218C152.87,378.839,150.534,390.327,150.534,390.327"/>
			<path style={{opacity:0.2}} d="M316.504,359.941"/>
			<path style={{fill:theme.imageHighlight}} d="M308.4,407.288c-0.394,2.092-2.258,12.495-2.417,12.956c-4.513,13.078-43.121,4.368-79.339-2.453
				c-36.438-6.862-69.936-14.456-76.352-26.602c0,0,2.56-10.788,2.941-12.811c2.523-13.398,39.628-17.188,82.548-9.105
				C278.702,377.356,310.923,393.89,308.4,407.288z"/>
			<g>
				<path style={{fill:theme.dark}} d="M305.921,421.053c-2.523,13.398-39.625,17.393-82.546,9.31
					c-42.921-8.083-75.669-25.496-73.146-38.895c2.523-13.398,39.362-17.707,82.283-9.624
					C275.433,389.928,308.444,407.655,305.921,421.053z"/>
				<g>
					<path style={{fill:'#455A64'}} d="M306.606,421.192c-0.085,0.004,0.335-1.237-0.185-3.508c-0.498-2.27-2.332-5.303-5.522-8.342
						c-6.414-6.093-17.806-12.384-32.644-17.726c-7.422-2.692-15.732-5.145-24.777-7.242c-9.044-2.098-18.83-3.834-29.207-4.969
						c-10.371-1.115-21.362-1.668-32.686-0.846c-5.65,0.434-11.404,1.217-17.019,2.851c-2.794,0.836-5.559,1.913-8.05,3.481
						c-0.641,0.366-1.202,0.841-1.794,1.276c-0.534,0.499-1.112,0.964-1.558,1.545c-0.943,1.107-1.697,2.412-1.968,3.852
						c-0.558,2.906,0.648,5.852,2.378,8.185c1.734,2.379,3.908,4.399,6.199,6.204c4.623,3.582,9.69,6.422,14.796,8.885
						c10.244,4.901,20.684,8.394,30.748,11.136c10.077,2.731,19.828,4.665,29.012,6.026c9.185,1.363,17.814,2.14,25.706,2.387
						c15.762,0.525,28.674-1.047,36.9-4.302c4.092-1.631,6.923-3.765,8.219-5.695C306.47,422.467,306.527,421.157,306.606,421.192
						c0,0-0.063,0.298-0.213,0.878c-0.053,0.298-0.212,0.635-0.394,1.04c-0.154,0.42-0.439,0.85-0.759,1.34
						c-1.283,1.971-4.125,4.154-8.229,5.823c-8.252,3.331-21.194,4.961-36.987,4.482c-7.908-0.224-16.554-0.981-25.757-2.328
						c-9.203-1.344-18.972-3.266-29.074-5.99c-10.09-2.736-20.556-6.225-30.845-11.14c-5.128-2.471-10.223-5.321-14.894-8.937
						c-2.316-1.824-4.522-3.868-6.302-6.308c-0.872-1.224-1.662-2.546-2.117-4.017c-0.475-1.452-0.651-3.039-0.349-4.574
						c0.29-1.541,1.094-2.928,2.082-4.087c0.471-0.61,1.067-1.091,1.623-1.609c0.61-0.449,1.192-0.94,1.847-1.314
						c2.555-1.606,5.358-2.694,8.181-3.538c5.672-1.648,11.454-2.43,17.128-2.863c11.37-0.818,22.385-0.253,32.777,0.878
						c10.397,1.15,20.197,2.905,29.253,5.025c9.057,2.119,17.375,4.597,24.805,7.316c14.852,5.397,26.251,11.754,32.659,17.926
						c3.186,3.078,5.013,6.161,5.483,8.464c0.119,0.573,0.225,1.078,0.216,1.525c0.022,0.444,0.047,0.816-0.012,1.112
						C306.655,420.892,306.606,421.192,306.606,421.192z"/>
				</g>
				
					<ellipse transform="matrix(0.1851 -0.9827 0.9827 0.1851 -212.799 555.9113)" style={{opacity:0.4}} cx="228.787" cy="406.263" rx="20.968" ry="74.641"/>
				<path style={{opacity:0.2}} d="M155.436,392.449c0,0,12.215,6.302,32.531,9.344c20.315,3.041,65.749-11.264,65.749-11.264
					S167.727,368.62,155.436,392.449z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M243.498,170.555c1.711-2.821,4.453-5,7.588-6.029c-0.292,3.942,1.459,7.98,4.535,10.462
				c-0.511-2.858-0.554-5.799-0.125-8.671c0.11-0.737,0.282-1.526,0.842-2.018c0.6-0.527,1.474-0.565,2.272-0.555
				c3.279,0.041,6.553,0.43,9.75,1.158c-1.627,4.653-0.765,10.097,2.22,14.019c2.138,2.809,5.18,4.765,7.776,7.158
				c2.596,2.393,4.873,5.594,4.66,9.117l-1.086,3.583c-7.909,0.655-15.82-2.297-22.251-6.947
				c-6.431-4.65-11.531-10.904-16.037-17.436c-0.361-0.523-0.729-1.077-0.801-1.708
				C242.754,171.934,243.105,171.203,243.498,170.555z"/>
			<g>
				<g>
					<path style={{fill:theme.imageHighlight}} d="M284.564,196.403c0.451-0.598,3.914,1.372,8.484,2.793c4.554,1.478,8.518,1.867,8.541,2.616
						c0.005,0.347-1.023,0.71-2.736,0.8c-1.706,0.093-4.094-0.143-6.594-0.935c-2.497-0.796-4.583-1.981-5.923-3.042
						C284.99,197.574,284.359,196.684,284.564,196.403z"/>
				</g>
				<g>
					<path style={{fill:theme.imageHighlight}} d="M281.81,198.233c0.422,2.474,2.77,4.139,5.244,3.717c2.475-0.422,4.138-2.77,3.717-5.244
						c-0.422-2.474-2.77-4.139-5.244-3.717C283.052,193.411,281.388,195.759,281.81,198.233z"/>
					<g>
						<path style={{fill:theme.dark}} d="M281.81,198.233c-0.018,0.003-0.047-0.093-0.086-0.283c-0.043-0.188-0.074-0.474-0.061-0.843
							c0.018-0.726,0.269-1.852,1.175-2.863c0.87-1.006,2.506-1.798,4.304-1.479c1.774,0.266,3.548,1.8,3.885,3.896
							c0.377,2.09-0.789,4.126-2.375,4.965c-1.592,0.896-3.398,0.691-4.551,0.03c-1.19-0.654-1.799-1.633-2.057-2.312
							c-0.134-0.344-0.2-0.624-0.222-0.816C281.796,198.337,281.792,198.236,281.81,198.233c0.049-0.01,0.113,0.391,0.419,1.029
							c0.302,0.626,0.924,1.505,2.043,2.067c1.086,0.57,2.726,0.707,4.143-0.121c1.413-0.779,2.434-2.598,2.099-4.46
							c-0.301-1.867-1.866-3.245-3.457-3.511c-1.611-0.311-3.113,0.361-3.949,1.258c-0.869,0.901-1.165,1.937-1.242,2.627
							C281.786,197.826,281.859,198.227,281.81,198.233z"/>
					</g>
				</g>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M272.545,191.03c0.074-0.128,2.201,0.967,4.83,2.295c2.629,1.332,4.771,2.395,4.712,2.53
					c-0.056,0.129-2.304-0.727-4.947-2.065C274.496,192.455,272.474,191.151,272.545,191.03z"/>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M261.235,185.583c0.071-0.058,0.935,1.005,2.523,2.526c1.582,1.522,3.95,3.448,6.869,5.092
					c2.925,1.635,5.802,2.653,7.926,3.21c2.125,0.562,3.482,0.746,3.47,0.837c-0.004,0.036-0.347,0.019-0.965-0.043
					c-0.619-0.053-1.508-0.198-2.593-0.437c-2.171-0.471-5.12-1.451-8.093-3.114c-2.967-1.671-5.34-3.678-6.873-5.285
					c-0.769-0.802-1.356-1.485-1.724-1.986C261.399,185.889,261.206,185.605,261.235,185.583z"/>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M276.858,188.375c0.098-0.1,1.616,1.238,3.226,3.137c1.614,1.896,2.689,3.611,2.575,3.691
					c-0.121,0.086-1.373-1.477-2.972-3.354C278.094,189.969,276.753,188.48,276.858,188.375z"/>
			</g>
			<path style={{fill:theme.imageHighlight}} d="M265.918,173.478c0.653-2.118,2.055-3.945,3.714-5.416c0.756-0.67,1.6-1.291,2.582-1.529
				c1.694-0.41,3.424,0.379,4.989,1.148c-2.613,1.001-4.644,3.396-5.203,6.138c-0.559,2.742,0.371,5.741,2.383,7.686
				c1.624,1.569,3.819,2.425,5.508,3.924c2.451,2.175,3.616,5.705,2.94,8.912l-16.162-14.475
				C265.433,178.026,265.265,175.596,265.918,173.478z"/>
		</g>
		<g>
			<polygon style={{fill:theme.dark}} points="366.794,221.014 273.513,223.87 397.106,118.209 482.956,124.372 366.794,221.014 			"/>
			<path style={{fill:theme.dark}} d="M394.156,112.04c-1.105,0.702-123.62,106.668-123.573,107.22c0.046,0.553,2.93,4.611,2.93,4.611
				l209.443-99.498l-1.41-4.827L394.156,112.04z"/>
			<polygon style={{opacity:0.4}} points="366.794,221.014 273.513,223.87 397.106,118.209 482.956,124.372 366.794,221.014 			"/>
			<polygon style={{fill:theme.dark}} points="397.106,118.209 394.156,112.04 481.546,119.546 482.956,124.372 			"/>
			<polygon style={{opacity:0.3,fill:'#FFFFFF'}} points="397.106,118.209 394.156,112.04 481.546,119.546 482.956,124.372 			"/>
			<g>
				<polygon style={{fill:theme.dark}} points="355.015,253.618 328.656,209.848 437.93,144.042 465.081,186.717 				"/>
				
					<ellipse transform="matrix(0.8491 -0.5282 0.5282 0.8491 -35.3284 228.7897)" style={{fill:theme.dark}} cx="382.8" cy="176.232" rx="63.783" ry="21.525"/>
				<path style={{opacity:0.3,fill:'#FFFFFF'}} d="M414.598,139.14l27.121,47.325l23.609,0.722l-26.139-41.667
					c-2.425-4.027-6.612-6.697-11.294-7.11C423.995,138.065,419.14,138.04,414.598,139.14z"/>
				<path style={{opacity:0.2}} d="M355.038,253.604l-25.947-43.086c-0.151-0.196-0.297-0.398-0.428-0.609
					c-6.279-10.094,12.878-33.361,42.79-51.968l18.09,30.039c4.922,8.173,2.774,18.742-4.937,24.361
					c-6.931,5.051-14.896,11.358-19.955,16.973C350.121,245.439,355.038,253.604,355.038,253.604"/>
				<path style={{opacity:0.2}} d="M437.738,144.157"/>
				<path style={{fill:theme.imageHighlight}} d="M460.464,179.434c0.828,1.332,5,7.903,5.138,8.235c3.93,9.428-23.917,25.542-49.351,41.381
					c-25.589,15.935-49.769,29.896-59.85,26.848c0,0-4.319-7.182-5.119-8.47c-5.299-8.529,15.637-30.493,45.778-49.263
					C427.202,179.395,455.165,170.905,460.464,179.434z"/>
				<path style={{fill:theme.dark}} d="M465.977,188.135c5.299,8.529-15.159,30.632-45.3,49.402
					c-30.141,18.77-58.872,27.072-64.171,18.544c-5.299-8.529,14.839-30.659,44.981-49.429
					C431.628,187.883,460.678,179.607,465.977,188.135z"/>
				<g>
					<path style={{fill:'#455A64'}} d="M465.958,188.156c-0.05,0.055-0.473-0.926-2.079-1.889c-0.817-0.437-1.894-0.855-3.232-1.102
						c-1.339-0.229-2.91-0.32-4.675-0.252c-7.082,0.299-17.087,3.122-28.663,8.217c-11.58,5.102-24.833,12.471-38.399,22.285
						c-6.763,4.922-13.616,10.467-20.035,16.919c-3.183,3.242-6.295,6.699-8.912,10.586c-1.289,1.947-2.503,3.992-3.279,6.217
						c-0.415,1.102-0.658,2.262-0.731,3.423c-0.067,1.158,0.137,2.347,0.746,3.328c0.594,0.99,1.548,1.719,2.605,2.194
						c1.061,0.473,2.211,0.772,3.381,0.895c2.337,0.299,4.709,0.136,7.027-0.161c4.647-0.62,9.125-1.882,13.441-3.303
						c8.628-2.904,16.632-6.603,24.035-10.497c7.406-3.903,14.208-8.05,20.392-12.195c6.187-4.142,11.741-8.31,16.608-12.358
						c9.724-8.086,16.747-15.746,20.199-21.937c0.856-1.545,1.481-2.989,1.879-4.288c0.376-1.307,0.488-2.457,0.46-3.383
						C466.58,188.99,465.886,188.177,465.958,188.156c0,0,0.125,0.209,0.349,0.635c0.212,0.43,0.428,1.122,0.525,2.058
						c0.045,0.938-0.051,2.107-0.416,3.432c-0.385,1.317-1.002,2.78-1.85,4.342c-3.421,6.262-10.426,13.982-20.141,22.122
						c-4.863,4.074-10.415,8.268-16.603,12.433c-6.185,4.168-12.992,8.337-20.409,12.261c-7.416,3.915-15.434,7.633-24.098,10.556
						c-4.336,1.431-8.836,2.704-13.535,3.333c-2.345,0.3-4.75,0.47-7.16,0.163c-1.203-0.127-2.405-0.434-3.534-0.938
						c-1.123-0.504-2.175-1.299-2.833-2.398c-0.674-1.089-0.891-2.393-0.82-3.619c0.077-1.231,0.335-2.441,0.761-3.574
						c0.801-2.291,2.035-4.362,3.339-6.332c2.649-3.93,5.779-7.401,8.98-10.656c6.453-6.475,13.329-12.025,20.116-16.948
						c13.611-9.814,26.907-17.162,38.533-22.233c11.62-5.062,21.672-7.843,28.803-8.082c1.777-0.053,3.361,0.054,4.711,0.302
						c1.349,0.266,2.437,0.706,3.256,1.164c0.795,0.503,1.32,1.005,1.611,1.385C465.826,187.951,465.958,188.156,465.958,188.156z"
						/>
				</g>
				
					<ellipse transform="matrix(0.8488 -0.5287 0.5287 0.8488 -54.986 251.1598)" style={{opacity:0.4}} cx="411.674" cy="221.726" rx="60.684" ry="15.443"/>
				<path style={{opacity:0.2}} d="M360.162,253.804c0,0,10.618-3.2,24.485-12.504c13.867-9.304,34.12-41.41,34.12-41.41
					S355.468,234.436,360.162,253.804z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M60.541,189.059c0.051,0.332-2.007,1.014-5.467,1.436c-1.727,0.218-3.802,0.327-6.109,0.304
				c-2.305-0.048-4.841-0.242-7.473-0.656c-2.631-0.431-5.1-1.04-7.304-1.717c-2.198-0.7-4.135-1.454-5.707-2.201
				c-3.153-1.484-4.894-2.776-4.742-3.076c0.343-0.702,8.024,2.847,18.166,4.423C52.027,189.25,60.435,188.285,60.541,189.059z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M24.028,176.548c0.611-2.7,2.213-5.163,4.433-6.816c0.817,3.214,3.285,5.961,6.393,7.117
				c-1.167-2.138-1.984-4.467-2.407-6.866c-0.109-0.615-0.182-1.289,0.133-1.83c0.337-0.579,1.022-0.842,1.66-1.046
				c2.619-0.84,5.328-1.402,8.065-1.674c-0.056,4.135,2.079,8.237,5.497,10.564c2.448,1.666,5.39,2.412,8.092,3.625
				c2.702,1.213,5.366,3.154,6.134,6.014l0.089,3.14c-6.118,2.626-13.198,2.383-19.552,0.395c-6.354-1.988-12.076-5.607-17.4-9.605
				c-0.427-0.32-0.867-0.663-1.092-1.146C23.803,177.843,23.888,177.168,24.028,176.548z"/>
			<g>
				<path style={{fill:theme.dark}} d="M52.588,185.108c0.029-0.144,2.016,0.143,4.46,0.499c2.445,0.36,4.43,0.655,4.416,0.801
					c-0.012,0.14-2.031,0.077-4.492-0.285C54.512,185.764,52.559,185.246,52.588,185.108z"/>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M42.139,183.784c0.048-0.074,1.024,0.532,2.693,1.312c1.666,0.783,4.059,1.678,6.815,2.209
					c2.758,0.522,5.314,0.571,7.15,0.457c1.839-0.11,2.969-0.315,2.986-0.228c0.007,0.035-0.269,0.118-0.776,0.239
					c-0.506,0.127-1.252,0.253-2.18,0.356c-1.854,0.21-4.466,0.221-7.278-0.312c-2.81-0.541-5.234-1.512-6.88-2.39
					c-0.825-0.437-1.472-0.829-1.896-1.134C42.346,183.993,42.12,183.814,42.139,183.784z"/>
			</g>
			<g>
				<path style={{fill:theme.dark}} d="M55.313,181.848c0.061-0.126,1.635,0.518,3.423,1.601c1.791,1.08,3.092,2.175,3.009,2.287
					c-0.087,0.119-1.509-0.775-3.279-1.842C56.701,182.825,55.248,181.98,55.313,181.848z"/>
			</g>
		</g>
		<path style={{fill:theme.dark}} d="M221.213,175.743c-0.157-0.297-0.337-0.58-0.539-0.848c-0.274-3.279-18.114-30.796-18.114-30.796
			L96.472,204.008l17.437,29.459c0,0,0.026-0.014,0.075-0.04c6.221,8.238,33.529,2.994,62.343-12.199
			C206.088,205.535,226.184,185.171,221.213,175.743z"/>
		<path style={{fill:theme.imageHighlight}} d="M221.222,175.781c-0.157-0.297-0.961-2.453-1.301-3.208c-1.104-1.949-0.21-0.992-5.046-8.775
			c4.971,9.428-15.671,30.312-45.433,46.004c-28.77,15.17-56.037,20.419-62.312,12.234l6.788,11.468c0,0,0.027-0.014,0.075-0.04
			c6.221,8.237,33.529,2.994,62.343-12.199C206.097,205.573,226.193,185.209,221.222,175.781z"/>
		<g style={{opacity:0.4}}>
			<path d="M113.482,232.768l-15.663-26.483l45.363-21.558c-0.986,17.374-9.432,34.199-22.774,45.37
				c-1.847,1.546-3.978,3.057-6.386,3.031"/>
		</g>
		<g>
			<g style={{opacity:0.1}}>
				<path style={{fill:'#FFFFFF'}} d="M205.296,202.104c-6.941-14.757-15.743-28.579-24.516-42.34l-15.44,5.354l-10.332,5.835
					c8.494,14.422,16.988,28.844,25.483,43.265c0.692,1.175,1.42,2.372,2.245,3.469
					C191.427,212.673,199.097,207.329,205.296,202.104z"/>
			</g>
		</g>
		<path style={{fill:theme.dark}} d="M66.108,154.139l-9.13,65.796l2.256,4.362l159.769-74.581l-4.411-61.401l-3.81-5.577L69.24,149.87
			C67.543,150.674,66.366,152.279,66.108,154.139z"/>
		<path style={{opacity:0.1,fill:'#FFFFFF'}} d="M56.978,219.934l9.13-65.796c0.258-1.86,1.435-3.465,3.132-4.269l141.543-67.133
			l5.382,61.779L56.978,219.934z"/>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M64.058,188.229c10.142,2.622,20.423,0.174,29.599-4.44c9.324-4.689,17.835-11.247,25.503-18.281
				c4.357-3.996,8.455-8.266,12.301-12.755c1.042-1.215-0.719-2.992-1.768-1.768c-6.567,7.663-13.885,14.718-21.933,20.818
				c-7.894,5.984-16.608,11.579-26.332,13.968c-5.509,1.353-11.2,1.47-16.706,0.047C63.164,185.415,62.499,187.826,64.058,188.229
				L64.058,188.229z"/>
		</g>
		<polygon style={{opacity:0.3,fill:'#FFFFFF'}} points="210.783,82.737 214.592,88.314 219.004,149.715 216.165,144.516 		"/>
		<g style={{opacity:0.3}}>
			<path style={{fill:'#FFFFFF'}} d="M219.004,149.715c0.061,0.13-35.437,16.83-79.276,37.294
				c-43.855,20.472-79.444,36.956-79.505,36.826c-0.061-0.13,35.43-16.826,79.284-37.298
				C183.346,166.073,218.943,149.585,219.004,149.715z"/>
		</g>
		<g style={{opacity:0.3}}>
			<path d="M136.733,150.574c-2.757,2.003-4.388,5.158-6.029,8.145c-9.596,17.463-19.902,30.986-35.66,43.181l19.573-9.229
				C120.587,184.477,133.674,164.134,136.733,150.574z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M146.707,143.414c0.318,0.094,0.295,1.52-0.678,3.561c-0.946,2.018-3,4.594-6.133,6.223
				c-3.135,1.628-6.424,1.827-8.619,1.441c-2.229-0.378-3.408-1.178-3.303-1.492c0.109-0.367,1.417-0.23,3.409-0.346
				c1.983-0.103,4.7-0.558,7.312-1.914c2.61-1.358,4.544-3.318,5.769-4.882C145.704,144.441,146.344,143.292,146.707,143.414z"/>
		</g>
		<g>
			<path style={{fill:theme.dark}} d="M145.97,143.66c1.054,2.23-2.1,5.933-7.045,8.271c-4.945,2.338-9.809,2.426-10.863,0.197
				s2.1-5.932,7.045-8.271C140.052,141.518,144.915,141.43,145.97,143.66z"/>
			<path style={{fill:theme.dark}} d="M140.144,139.18c-2.271-0.476-4.523,0.08-6.698,0.89c-2.19,0.816-4.037,2.513-5.035,4.626
				c-0.998,2.113-1.135,4.618-0.373,6.828l17.932-7.865C144.712,141.545,142.424,139.658,140.144,139.18z"/>
		</g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M68.962,188.039c-0.441,2.63-2.93,4.404-5.56,3.963c-2.63-0.441-4.404-2.93-3.963-5.56
				c0.441-2.63,2.93-4.404,5.56-3.963C67.629,182.92,69.403,185.41,68.962,188.039z"/>
			<g>
				<path style={{fill:theme.dark}} d="M68.962,188.039c-0.05-0.006,0.028-0.431-0.055-1.179c-0.083-0.734-0.398-1.837-1.325-2.795
					c-0.891-0.954-2.493-1.668-4.21-1.332c-1.695,0.288-3.36,1.761-3.676,3.752c-0.352,1.986,0.742,3.922,2.25,4.747
					c1.513,0.877,3.26,0.725,4.414,0.114c1.189-0.603,1.846-1.542,2.164-2.209C68.848,188.456,68.912,188.029,68.962,188.039
					c0.018,0.003,0.012,0.11-0.015,0.313c-0.023,0.203-0.093,0.501-0.236,0.865c-0.273,0.72-0.918,1.758-2.178,2.454
					c-1.221,0.703-3.134,0.924-4.822-0.022c-1.681-0.885-2.922-3.037-2.528-5.251c0.351-2.22,2.225-3.849,4.103-4.137
					c1.903-0.343,3.639,0.49,4.565,1.552c0.964,1.068,1.235,2.261,1.259,3.03c0.016,0.391-0.015,0.695-0.059,0.895
					C69.009,187.939,68.98,188.042,68.962,188.039z"/>
			</g>
		</g>
	</g>
</g>
      </svg>
    );
  }
}
