import React, { Component } from "react";

export default class Design extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        id="1c51936f-ad83-4493-a1e8-5a7ca1846cda"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="864.81"
        height="658.45"
      >
<g id="Background_Simple">
	<g>
		<path style={{fill:theme.imageHighlight}} d="M78.813,209.97c0,0-14.984,64.026,26.751,119.811s114.487,77.463,172.963,96.579
			c58.476,19.116,113.25-2.955,133.795-52.097s-20.954-75.617-30.105-139.804c-9.151-64.188-1.846-82.673-44.531-131.544
			s-130.278-47.708-189.499,2.469C88.965,155.561,78.813,209.97,78.813,209.97z"/>
		<path style={{opacity:0.7,fill:'#FFFFFF'}} d="M78.813,209.97c0,0-14.984,64.026,26.751,119.811s114.487,77.463,172.963,96.579
			c58.476,19.116,113.25-2.955,133.795-52.097s-20.954-75.617-30.105-139.804c-9.151-64.188-1.846-82.673-44.531-131.544
			s-130.278-47.708-189.499,2.469C88.965,155.561,78.813,209.97,78.813,209.97z"/>
	</g>
</g>
<g id="Plants">
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.imageHighlight}} d="M408.899,258.533c0,0,0-4.182-5.855-10.037c-5.855-5.855-9.619-9.2-9.619-9.2
						s-2.927,9.618,2.509,15.473c5.436,5.855,12.964,11.291,12.964,11.291V258.533z"/>
					<path style={{fill:theme.imageHighlight}} d="M400.402,284.65c0,0,0-4.182-5.855-10.037c-5.855-5.855-9.618-9.2-9.618-9.2
						s-2.927,9.618,2.509,15.473c5.437,5.855,12.964,11.291,12.964,11.291V284.65z"/>
					<path style={{fill:theme.imageHighlight}} d="M408.531,265.656c0,0,0.442-4.158,6.883-9.362c6.441-5.203,10.537-8.133,10.537-8.133
						s1.895,9.874-4.13,15.121c-6.025,5.247-14.085,9.858-14.085,9.858L408.531,265.656z"/>
					<path style={{fill:theme.imageHighlight}} d="M398.266,296.5c0,0,0.876-4.089,7.828-8.587c6.952-4.498,11.333-6.981,11.333-6.981
						s0.847,10.018-5.695,14.604c-6.543,4.586-15.042,8.324-15.042,8.324L398.266,296.5z"/>
					<path style={{fill:theme.imageHighlight}} d="M400.196,330.129c0,0,0-4.182,5.855-10.037c5.855-5.855,9.619-9.2,9.619-9.2
						s2.927,9.618-2.509,15.473c-5.436,5.855-12.964,11.291-12.964,11.291V330.129z"/>
					<path style={{fill:theme.imageHighlight}} d="M400.098,338.337c0,0,0.594-4.14-4.369-10.767c-4.963-6.627-8.214-10.474-8.214-10.474
						s-4.264,9.105,0.285,15.673c4.549,6.568,11.228,13.019,11.228,13.019L400.098,338.337z"/>
					<path style={{fill:theme.imageHighlight}} d="M394.325,362.664c0,0,0-4.182,5.855-10.036c5.855-5.855,9.618-9.2,9.618-9.2
						s2.927,9.618-2.509,15.473c-5.436,5.855-12.964,11.291-12.964,11.291V362.664z"/>
					<path style={{fill:theme.imageHighlight}} d="M398.235,384.72c0,0-1.059-4.046,3.122-11.192c4.181-7.147,6.975-11.336,6.975-11.336
						s5.268,8.564,1.491,15.604c-3.776,7.041-9.682,14.207-9.682,14.207L398.235,384.72z"/>
				</g>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M409.429,257.461c-0.343,4.47-0.851,9.095-1.603,12.985c-2.315,11.957-8.1,19.672-10.415,30.472
					c-2.314,10.8,4.33,24.714,2.551,38.587c-1.779,13.872-7.179,21.587-5.636,30.844c1.543,9.257,9.643,34.33,9.643,34.33"/>
				
					<line style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="408.473" y1="268.435" x2="414.783" y2="263.593"/>
				
					<line style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="408.767" y1="262.859" x2="406.126" y2="258.164"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M397.762,300.423c0,0,5.283-3.962,10.711-7.923"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M400.402,292.178c0,0-4.401-8.629-9.39-14.352"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M400.403,336.078c0,0,4.109-8.07,11.298-15.113"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M399.376,341.948c0,0-2.641-6.309-5.722-10.858c-3.082-4.549-3.522-5.136-3.522-5.136"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M394.325,370.35c0,0,2.116-5.365,4.757-9.62c2.641-4.255,6.163-8.07,6.163-8.07"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M400.141,392.002c0,0-0.618-5.008,1.583-9.263c2.201-4.255,4.695-9.831,5.722-12.032"/>
			</g>
			<g>
				<g>
					<path style={{fill:theme.imageHighlight}} d="M370.56,314.402c0,0-0.454-2.622,2.582-6.929c3.036-4.307,5.033-6.814,5.033-6.814
						s2.88,5.714,0.106,9.975c-2.774,4.262-6.904,8.488-6.904,8.488L370.56,314.402z"/>
					<path style={{fill:theme.imageHighlight}} d="M378.724,329.857c0,0-0.454-2.622,2.582-6.93c3.036-4.307,5.033-6.814,5.033-6.814
						s2.88,5.714,0.106,9.975c-2.774,4.262-6.904,8.488-6.904,8.488L378.724,329.857z"/>
					<path style={{fill:theme.imageHighlight}} d="M371.564,318.828c0,0-0.728-2.56-5.332-5.124c-4.604-2.564-7.49-3.956-7.49-3.956
						s-0.116,6.397,4.231,9.034c4.348,2.637,9.902,4.653,9.902,4.653L371.564,318.828z"/>
					<path style={{fill:theme.imageHighlight}} d="M381.349,337.056c0,0-0.993-2.469-5.841-4.535c-4.848-2.066-7.864-3.147-7.864-3.147
						s0.556,6.374,5.157,8.54c4.6,2.165,10.336,3.587,10.336,3.587L381.349,337.056z"/>
					<path style={{fill:theme.imageHighlight}} d="M383.79,358.353c0,0-0.454-2.622-4.761-5.658c-4.307-3.036-7.03-4.725-7.03-4.725
						s-0.792,6.349,3.253,9.43c4.045,3.081,9.355,5.673,9.355,5.673L383.79,358.353z"/>
					<path style={{fill:theme.imageHighlight}} d="M384.742,363.49c0,0-0.822-2.531,1.571-7.226c2.393-4.695,4.013-7.459,4.013-7.459
						s3.663,5.246,1.523,9.859c-2.14,4.612-5.628,9.383-5.628,9.383L384.742,363.49z"/>
					<path style={{fill:theme.imageHighlight}} d="M392.091,384.643c0,0,0.304-2.644,4.43-5.921c4.127-3.276,6.749-5.119,6.749-5.119
						s1.153,6.294-2.71,9.601c-3.862,3.307-9.016,6.198-9.016,6.198L392.091,384.643z"/>
					<path style={{fill:theme.imageHighlight}} d="M391.003,378.118c0,0-0.454-2.622-4.761-5.658c-4.307-3.036-7.03-4.725-7.03-4.725
						s-0.792,6.349,3.253,9.431c4.045,3.081,9.355,5.673,9.355,5.673L391.003,378.118z"/>
					<path style={{fill:theme.imageHighlight}} d="M390.946,392.373c0,0,0.225-2.652-3.173-6.68c-3.398-4.028-5.604-6.352-5.604-6.352
						s-2.374,5.942,0.759,9.947c3.133,4.005,7.613,7.858,7.613,7.858L390.946,392.373z"/>
				</g>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M370.111,313.787c0.7,2.766,1.521,5.611,2.415,7.968c2.749,7.247,7.215,11.457,9.839,17.978
					c2.624,6.521-0.032,15.968,2.589,24.474c2.622,8.506,6.845,12.757,6.883,18.73c0.037,5.973-2.32,22.574-2.32,22.574"/>
				
					<line style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="371.902" y1="320.565" x2="367.42" y2="318.213"/>
				
					<line style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="371.113" y1="317.1" x2="372.259" y2="313.869"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M382.091,339.461c0,0-3.743-1.911-7.577-3.806"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M379.541,334.577c0,0,1.823-5.889,4.33-10.019"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M384.306,362.106c0,0-3.453-4.615-8.726-8.251"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M385.587,365.675c0,0,0.971-4.243,2.41-7.43c1.439-3.187,1.651-3.603,1.651-3.603"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M391.634,387.468c0,0,2.971-3.642,4.82-5.004c1.849-1.363,3.13-2.722,3.13-2.722"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M391.838,382.937c0,0-1.909-3.135-4.028-5.516c-2.118-2.382-4.741-4.392-4.741-4.392"/>
				<path style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M390.541,397.146c0,0-0.156-3.207-1.998-5.637c-1.842-2.429-4.012-5.655-4.895-6.924"/>
			</g>
		</g>
		<g>
			<path style={{fill:theme.dark}} d="M412.814,434.359h-32.216c-1.68,0-3.041-1.362-3.041-3.041v-28.702h38.299v28.702
				C415.855,432.998,414.493,434.359,412.814,434.359z"/>
			<path style={{fill:theme.dark}} d="M415.522,407.612h-37.966c-1.655,0-2.997-1.342-2.997-2.997l0,0c0-1.655,1.342-2.997,2.997-2.997
				h37.966c1.655,0,2.997,1.342,2.997,2.997l0,0C418.519,406.27,417.177,407.612,415.522,407.612z"/>
		</g>
	</g>
</g>
<g id="Clock">
	<g>
		<circle style={{fill:theme.dark}} cx="342.667" cy="106.667" r="34"/>
		
			<ellipse transform="matrix(0.9267 -0.3759 0.3759 0.9267 -14.9652 136.6283)" style={{fill:'#FFFFFF'}} cx="342.667" cy="106.667" rx="29.49" ry="29.49"/>
		<polyline style={{fill:'none',stroke:theme.dark,strokeWidth:2,strokeMiterlimit:10}} points="323.539,112.09 342.667,106.667 
			342.667,79.911 		"/>
	</g>
</g>
<g id="Graphics">
	<g>
		<polygon style={{fill:theme.dark}} points="177.701,258.67 140.373,258.67 91.503,127.514 128.831,127.514 		"/>
		<polygon style={{opacity:0.2,fill:'#FFFFFF'}} points="177.701,258.67 140.373,258.67 91.503,127.514 128.831,127.514 		"/>
		<path style={{fill:theme.dark}} d="M118.859,159.545h-9.598c-1.197,0-2.528-0.97-2.974-2.167l-3.706-9.946
			c-0.446-1.197,0.163-2.167,1.359-2.167h9.598c1.197,0,2.528,0.97,2.974,2.167l3.706,9.946
			C120.664,158.575,120.055,159.545,118.859,159.545z"/>
		<polygon style={{fill:theme.dark}} points="131.301,134.143 93.973,134.143 91.503,127.514 128.831,127.514 		"/>
		<g>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="106.727" y1="135.229" x2="107.799" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="108.166" y1="135.229" x2="109.238" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="109.604" y1="135.229" x2="110.676" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="111.042" y1="135.229" x2="112.114" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="112.481" y1="135.229" x2="113.553" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="113.919" y1="135.229" x2="114.991" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="115.358" y1="135.229" x2="116.43" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="116.796" y1="135.229" x2="117.868" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="118.234" y1="135.229" x2="119.306" y2="138.105"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="119.673" y1="135.229" x2="120.745" y2="138.105"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF'}} d="M115.754,153.309l-9.037-5.731c-0.213-0.135-0.45-0.159-0.599-0.06
				c-0.15,0.098-0.185,0.299-0.087,0.51l4.141,8.934c0.101,0.219,0.324,0.402,0.557,0.458c0.049,0.012,0.096,0.017,0.14,0.017
				c0.168,0,0.299-0.081,0.345-0.224l0.954-2.938l3.603-0.041c0.218-0.003,0.361-0.141,0.357-0.348
				C116.124,153.679,115.975,153.449,115.754,153.309z"/>
			<g>
				<polygon style={{fill:theme.dark}} points="115.57,153.711 106.533,147.98 110.674,156.914 111.699,153.755 				"/>
			</g>
		</g>
		<polygon style={{fill:'#FFFFFF'}} points="132.462,153.711 123.425,147.98 127.566,156.914 128.591,153.755 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="137.092,158.792 136.277,156.952 135.214,158.499 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="158.933,217.407 158.118,215.568 157.054,217.115 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="165.636,235.395 164.82,233.556 163.757,235.103 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="172.509,253.843 171.694,252.003 170.631,253.551 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="134.019,196.638 133.204,194.798 132.141,196.345 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="141.758,217.407 140.943,215.568 139.88,217.115 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="148.461,235.395 147.646,233.556 146.582,235.103 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="155.335,253.843 154.52,252.003 153.456,253.551 		"/>
		<g>
			<polygon style={{fill:'#FFFFFF'}} points="115.749,178.528 117.321,179.544 121.638,170.528 120.066,169.512 			"/>
			<path style={{fill:'#FFFFFF'}} d="M117.687,169.704c-2.285-1.786-2.446-2.142-1.886-4.163c-1.183,1.619-1.528,1.648-4.039,0.334
				c2.285,1.786,2.445,2.141,1.886,4.163C114.831,168.419,115.176,168.39,117.687,169.704z"/>
			<path style={{fill:'#FFFFFF'}} d="M125.227,170.674c-1.412-1.104-1.511-1.323-1.165-2.573c-0.731,1.001-0.944,1.018-2.496,0.207
				c1.412,1.104,1.511,1.323,1.165,2.573C123.462,169.88,123.675,169.862,125.227,170.674z"/>
			<path style={{fill:'#FFFFFF'}} d="M124.799,174.911c-1.008-0.788-1.079-0.945-0.832-1.836c-0.522,0.714-0.674,0.727-1.781,0.148
				c1.008,0.788,1.079,0.944,0.832,1.836C123.54,174.345,123.692,174.332,124.799,174.911z"/>
		</g>
		<polygon style={{fill:'#FFFFFF'}} points="147.461,214.881 148.433,215.567 152.75,206.55 151.777,205.864 		"/>
		<g>
			<g>
				<polygon style={{fill:'#FFFFFF'}} points="143.841,176.204 135.867,171.147 139.521,179.03 140.426,176.243 				"/>
			</g>
			<g>
				<path style={{fill:'#FFFFFF'}} d="M137.843,176.665c-2.923,0-5.943-1.723-6.733-3.841c-0.789-2.118,0.947-3.841,3.87-3.841
					c2.923,0,5.943,1.723,6.732,3.841C142.502,174.942,140.766,176.665,137.843,176.665z M135.152,169.443
					c-2.669,0-4.275,1.516-3.581,3.381c0.695,1.864,3.431,3.38,6.1,3.38s4.275-1.516,3.581-3.38
					C140.557,170.96,137.821,169.443,135.152,169.443z"/>
			</g>
			<g>
				<path style={{fill:'#FFFFFF'}} d="M134.33,175.897c-0.72,0-1.524-0.586-1.793-1.306c-0.268-0.72,0.099-1.306,0.819-1.306
					c0.72,0,1.525,0.586,1.793,1.306C135.418,175.311,135.05,175.897,134.33,175.897z M133.528,173.746
					c-0.466,0-0.704,0.379-0.53,0.845c0.174,0.466,0.694,0.845,1.16,0.845c0.466,0,0.704-0.379,0.53-0.845
					C134.515,174.125,133.994,173.746,133.528,173.746z"/>
			</g>
			<g>
				<path style={{fill:'#FFFFFF'}} d="M134.456,177.266l-0.317-0.434c0.576-0.237,0.89-0.587,0.934-1.04
					c0.056-0.574-0.339-1.117-0.343-1.122l0.374-0.158c0.022,0.03,0.531,0.731,0.458,1.476
					C135.509,176.557,135.136,176.987,134.456,177.266z"/>
			</g>
		</g>
		<g>
			<polygon style={{fill:'#FFFFFF'}} points="129.457,190.269 123.892,186.215 122.711,188.097 128.276,192.151 			"/>
			<path style={{fill:'#FFFFFF'}} d="M120.543,195.52l0.911,0.664l2.036-3.246c-0.23-0.244-0.315-0.559-0.185-0.767
				c0.157-0.25,0.561-0.25,0.904,0c0.343,0.25,0.493,0.654,0.337,0.904c-0.131,0.208-0.434,0.24-0.73,0.101l-2.037,3.246
				l0.846,0.617l5.033-1.413l0.314-2.97l-5.495-4.003l-2.529,1.356L120.543,195.52z"/>
		</g>
		
			<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="115.278" y1="182.793" x2="147.407" y2="182.793"/>
		<g>
			<polygon style={{fill:'#FFFFFF'}} points="143.222,186.215 142.041,188.097 147.606,192.151 148.787,190.269 			"/>
			<path style={{fill:'#FFFFFF'}} d="M139.279,190.008l0.593,5.512l0.911,0.664l2.037-3.246c-0.23-0.244-0.315-0.559-0.185-0.767
				c0.157-0.25,0.561-0.25,0.904,0c0.343,0.25,0.493,0.654,0.337,0.904c-0.131,0.208-0.434,0.24-0.73,0.101l-2.037,3.246
				l0.846,0.617l5.033-1.413l0.314-2.97l-5.495-4.003L139.279,190.008z"/>
			<path style={{fill:'#FFFFFF'}} d="M137.904,191.625c0.052-0.387,0.108-0.809,0.147-1.279c0.078-0.949-0.188-1.792-0.789-2.507
				c-0.952-1.131-2.383-1.495-2.444-1.51c-0.152-0.038-0.267,0.044-0.252,0.184c0.014,0.139,0.15,0.283,0.303,0.321
				c0.012,0.003,1.233,0.313,2.003,1.228c0.48,0.569,0.689,1.265,0.623,2.068c-0.038,0.464-0.094,0.879-0.145,1.259
				c-0.268,1.997-0.43,3.202,2.889,5.194c0.061,0.037,0.124,0.055,0.18,0.055c0.078,0,0.143-0.035,0.169-0.102
				c0.046-0.114-0.036-0.278-0.184-0.366C137.526,194.443,137.636,193.618,137.904,191.625z"/>
		</g>
		<path style={{fill:'#FFFFFF'}} d="M123.681,204.996l0.195,0.522c0.068,0.183,0.675,1.802,1.004,2.354l0.935-0.228
			c-0.151-0.253-0.453-0.984-0.694-1.603h3.969l3.321,8.914h-1.488l0.389,1.045h4.021l-0.389-1.045h-1.488l-3.321-8.914h3.862
			c0.21,0.564,0.536,1.442,0.697,1.876l1.046,0.003c-0.249-0.673-0.893-2.402-0.893-2.402l-0.195-0.522H123.681z"/>
		<g>
			<path style={{opacity:0.44,fill:'#FFFFFF'}} d="M144.563,229.725c0.854,2.292-1.026,4.15-4.2,4.15s-6.439-1.858-7.293-4.15
				c-0.854-2.292,1.027-4.15,4.2-4.15S143.709,227.432,144.563,229.725z"/>
			<path style={{fill:'none',stroke:'#FFFFFF',strokeMiterlimit:10}} d="M144.563,229.725c0.854,2.292-1.026,4.15-4.2,4.15
				s-6.439-1.858-7.293-4.15c-0.854-2.292,1.027-4.15,4.2-4.15S143.709,227.432,144.563,229.725z"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF'}} d="M156.393,229.843l3.767-6.848c0.156-0.284,0.559-0.344,0.963-0.145l0,0
				c0.48,0.236,0.79,0.749,0.687,1.134l-2.028,7.567L156.393,229.843z"/>
			<path style={{fill:'#FFFFFF'}} d="M155.771,230.418c1.483-0.197,4.382,1.068,4.463,2.985c0.02,0.48-0.303,0.807-0.673,1.032
				c-1.116,0.681-2.694,0.712-4.217,0.729c0.365-0.258,0.519-0.737,0.403-1.26c-0.118-0.537-0.492-1.08-0.686-1.622
				C154.787,231.513,154.932,230.476,155.771,230.418"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF'}} d="M147.923,245.449c-0.438-0.956-0.991-1.548-1.368-1.872l-1.137,1.813
				c0.441,0.37,1.081,1.042,1.576,2.121c0.442,0.964,0.842,1.651,1.133,2.09l1.115-1.777
				C148.877,247.312,148.399,246.488,147.923,245.449z"/>
			<path style={{fill:'#FFFFFF'}} d="M146.609,247.545c-0.438-0.956-0.991-1.548-1.368-1.872l-1.137,1.813
				c0.441,0.37,1.081,1.042,1.575,2.121c0.442,0.964,0.843,1.651,1.133,2.09l1.115-1.777
				C147.563,249.407,147.085,248.583,146.609,247.545z"/>
			<path style={{fill:'#FFFFFF'}} d="M148.308,245.415c0.442,0.964,0.842,1.651,1.133,2.09l1.642-2.618l-3.672-2.675l-0.679,1.083
				C147.173,243.665,147.814,244.337,148.308,245.415z"/>
			<path style={{fill:'#FFFFFF'}} d="M145.294,249.64c-0.438-0.956-0.99-1.548-1.368-1.872l-0.952,1.517l0.162,3.458
				c0.011,0.24,0.325,0.467,0.534,0.385l2.92-1.145C146.228,251.468,145.76,250.656,145.294,249.64z M144,251.95l-0.086-1.839
				l1.671,1.217L144,251.95z"/>
		</g>
		<path style={{fill:'#FFFFFF'}} d="M159.701,247.082l-1.253,1.997l6.462,4.708l1.253-1.997l0.392,0.285l2.954-4.708l-7.245-5.278
			l-2.954,4.708L159.701,247.082z M164.485,252.646l-4.895-3.566l0.895-1.427l4.896,3.566L164.485,252.646z"/>
		<g>
			<polygon style={{fill:'#FFFFFF'}} points="98.711,129.266 98.948,129.901 98.05,131.003 99.769,132.105 100.005,132.739 
				97.297,131.003 			"/>
			<polygon style={{fill:'#FFFFFF'}} points="96.454,129.266 96.69,129.901 95.792,131.003 97.511,132.105 97.748,132.739 
				95.039,131.003 			"/>
		</g>
	</g>
	<g>
		<polygon style={{fill:theme.dark}} points="250,206.171 172.791,206.171 149.189,140.333 226.398,140.333 		"/>
		<polygon style={{fill:theme.imageHighlight}} points="244.523,202.58 175.693,202.58 154.666,143.924 223.496,143.924 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="231.434,166.07 220.051,157.69 211.062,181.033 187.754,174.449 175.693,202.579 
			244.523,202.579 		"/>
		<path style={{fill:'#FFFFFF'}} d="M184.01,156.493c-1.6-4.462-6.514-8.08-10.977-8.08c-4.462,0-6.783,3.617-5.183,8.08
			c1.6,4.463,6.514,8.08,10.977,8.08C183.289,164.573,185.61,160.956,184.01,156.493z"/>
	</g>
	<g>
		<polygon style={{fill:theme.dark}} points="258,194.837 180.791,194.837 157.189,129 234.398,129 		"/>
		<polygon style={{fill:theme.imageHighlight}} points="252.523,191.246 183.693,191.246 162.666,132.591 231.496,132.591 		"/>
		<polygon style={{fill:'#FFFFFF'}} points="239.434,154.736 228.051,146.357 219.062,169.699 195.754,163.116 183.693,191.246 
			252.523,191.246 		"/>
		<path style={{fill:'#FFFFFF'}} d="M192.01,145.16c-1.6-4.462-6.514-8.08-10.977-8.08c-4.462,0-6.783,3.618-5.183,8.08
			c1.6,4.463,6.514,8.08,10.977,8.08C191.289,153.24,193.61,149.623,192.01,145.16z"/>
	</g>
	<g>
		<g>
			<path d="M258.346,118.67l1.859,4.503h-0.538c-0.394-0.793-0.743-1.359-1.046-1.699c-0.493-0.547-1.03-0.951-1.611-1.211
				c-0.581-0.259-1.268-0.389-2.061-0.389h-2.705l5.364,14.67c0.432,1.18,0.828,1.917,1.19,2.209
				c0.504,0.396,1.129,0.595,1.874,0.595h0.666l0.192,0.524h-8.142l-0.192-0.524h0.68c0.812,0,1.298-0.246,1.458-0.736
				c0.097-0.302-0.051-0.991-0.445-2.067l-5.364-14.67h-2.308c-0.897,0-1.51,0.066-1.839,0.198c-0.425,0.179-0.72,0.524-0.882,1.034
				c-0.163,0.51-0.119,1.199,0.133,2.067h-0.538l-1.42-4.503H258.346z"/>
		</g>
		
			<line style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} x1="270.036" y1="127.715" x2="275.914" y2="127.715"/>
		<polygon style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="275.721,143.284 246.159,143.284 
			235.21,113.343 264.772,113.343 		"/>
		<polygon style={{fill:'#FFFFFF',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="241.949,128.823 239.734,128.823 
			238.924,126.608 241.139,126.608 		"/>
		<polygon style={{fill:'#FFFFFF',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="271.548,128.823 269.333,128.823 
			268.523,126.608 270.738,126.608 		"/>
		<polygon style={{fill:'#FFFFFF',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="236.753,114.614 234.538,114.614 
			233.728,112.399 235.943,112.399 		"/>
		<polygon style={{fill:'#FFFFFF',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="266.353,114.614 264.137,114.614 
			263.327,112.399 265.543,112.399 		"/>
		<polygon style={{fill:'#FFFFFF',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="251.564,114.614 249.349,114.614 
			248.539,112.399 250.754,112.399 		"/>
		<polygon style={{fill:'#FFFFFF',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="247.577,144.214 245.362,144.214 
			244.552,141.998 246.767,141.998 		"/>
		<polygon style={{fill:'#FFFFFF',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="277.176,144.214 274.961,144.214 
			274.151,141.998 276.366,141.998 		"/>
		
			<line style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} x1="261.111" y1="143.811" x2="263.26" y2="149.689"/>
		<polygon style={{fill:'#FFFFFF',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} points="262.387,144.214 260.172,144.214 
			259.362,141.998 261.577,141.998 		"/>
		<polygon style={{fill:theme.dark}} points="264.795,150.796 262.58,150.796 261.77,148.581 263.985,148.581 		"/>
		
			<line style={{fill:'none',stroke:theme.dark,strokeWidth:0.5,strokeMiterlimit:10}} x1="244.386" y1="137.667" x2="269.219" y2="137.667"/>
		<path style={{fill:theme.dark}} d="M277.08,127.715c-0.236-0.644-0.949-1.167-1.593-1.167s-0.976,0.522-0.74,1.167
			c0.236,0.644,0.949,1.167,1.593,1.167C276.985,128.882,277.316,128.36,277.08,127.715z"/>
	</g>
</g>
<g id="Table">
	<g>
		<polygon style={{opacity:0.2}} points="140.034,286.486 166.824,309.448 141.293,434.359 143.02,434.359 171.416,311.744 
			197.44,333.558 160.7,434.209 162.997,433.826 201.649,335.855 309.189,433.826 374.631,433.826 298.09,283.807 		"/>
		<g>
			
				<polygon id="XMLID_50_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				143.02,434.359 141.293,434.359 138.701,284.35 145.612,284.35 			"/>
			
				<polygon id="XMLID_84_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				291.878,434.359 290.15,434.359 287.559,284.35 294.469,284.35 			"/>
			
				<rect id="XMLID_62_" x="136.063" y="282.032" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} width="166.36" height="3.894"/>
		</g>
	</g>
</g>
<g id="Paper_Bin">
	<g>
		<g>
			<path style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M98.496,362.569
				l-3.024,6.385c0,0,3.696,4.032,5.377,4.032s2.352-1.68,4.368-2.352c2.016-0.672,5.377-7.057,5.377-7.057s-2.016-3.36-4.032-3.696
				c-2.016-0.336-5.04-1.344-6.721-1.68C98.16,357.864,98.496,362.569,98.496,362.569z"/>
			<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M98.496,362.569
				l2.016,3.36v3.024c0,0,2.016,1.008,2.688,0c0.672-1.008,3.696-4.368,3.696-4.368l-1.344-3.024"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="100.512" y1="368.953" x2="96.815" y2="368.281"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
				M156.402,423.025l-6.792,1.945c0,0-0.587,5.439,0.522,6.7c1.109,1.262,2.815,0.657,4.651,1.727c1.836,1.07,8.85-0.622,8.85-0.622
				s1.192-3.733,0.113-5.469c-1.079-1.736-2.319-4.673-3.176-6.156C159.713,419.666,156.402,423.025,156.402,423.025z"/>
			<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M156.402,423.025
				l-1.192,3.733l-2.271,1.997c0,0,0.574,2.18,1.775,2.019c1.201-0.161,5.721-0.109,5.721-0.109l1.384-3.006"/>
			
				<line style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="152.939" y1="428.755" x2="151.003" y2="425.535"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M87.743,361.225
				c0,0-5.713,3.696-4.704,5.713s1.68,3.36,2.688,4.032c1.008,0.672,4.032,1.68,5.04,1.008c1.008-0.672,3.36-2.352,3.696-5.04
				c0.336-2.688,1.344-3.024,0.336-4.368C93.791,361.225,87.743,361.225,87.743,361.225z"/>
			<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M83.038,366.937
				l4.032,2.688l3.36-0.336l1.344-3.696c0,0,0-2.016-2.016-2.016c-2.016,0-3.024,1.008-3.024,1.008l0.672,2.016"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M112.609,356.52
				c0,0-5.041,4.704-4.369,8.065c0.672,3.36,7.057,6.049,7.057,6.049l7.729-4.368l-2.352-6.049l-5.04-2.688L112.609,356.52z"/>
			<polyline style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				108.24,364.585 113.617,366.601 119.666,364.585 117.313,359.208 			"/>
			<polyline style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				112.609,356.52 112.273,363.577 116.305,364.921 			"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M127.73,419.694
				c0,0-5.041,4.704-4.368,8.065c0.672,3.36,7.057,6.049,7.057,6.049l7.729-4.368l-2.352-6.049l-5.04-2.688L127.73,419.694z"/>
			<polyline style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				123.362,427.759 128.738,429.775 134.787,427.759 132.435,422.383 			"/>
			<polyline style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				127.73,419.694 127.394,426.751 131.427,428.095 			"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M94.127,351.48
				c0,0-5.04,4.704-4.368,8.065c0.672,3.36,7.057,6.049,7.057,6.049l7.729-4.368l-2.352-6.049l-5.04-2.688L94.127,351.48z"/>
			<polyline style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				89.759,359.544 95.135,361.561 101.184,359.544 98.832,354.168 			"/>
			<polyline style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				94.127,351.48 93.791,358.536 97.824,359.88 			"/>
		</g>
		<g>
			
				<polygon id="XMLID_37_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
				122.322,434.359 82.443,434.359 76.462,368.254 128.304,368.254 			"/>
		</g>
		<g>
			<path style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M95.807,423.391
				c0,0-5.712,3.696-4.704,5.713c1.008,2.016,1.68,3.36,2.688,4.032c1.008,0.672,4.032,1.68,5.04,1.008
				c1.008-0.672,3.36-2.352,3.696-5.04c0.336-2.688,1.344-3.024,0.336-4.368C101.856,423.391,95.807,423.391,95.807,423.391z"/>
			<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M91.103,429.103
				l4.032,2.688l3.36-0.336l1.344-3.696c0,0,0-2.016-2.016-2.016c-2.016,0-3.024,1.008-3.024,1.008l0.672,2.016"/>
		</g>
	</g>
</g>
<g id="Character">
	<g id="XMLID_66_">
		<path id="XMLID_65_" style={{fill:'#808080',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M201.544,236.315c0,1.044-0.99,1.89-2.212,1.89c-1.222,0-2.212-0.846-2.212-1.89c0-1.044,0.99-1.89,2.212-1.89
			C200.554,234.425,201.544,235.271,201.544,236.315z"/>
		<path id="XMLID_67_" style={{fill:'#808080',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M203.668,242.075c0,1.044-0.99,1.89-2.212,1.89s-2.212-0.846-2.212-1.89c0-1.044,0.99-1.89,2.212-1.89
			S203.668,241.031,203.668,242.075z"/>
		<path id="XMLID_68_" style={{fill:'#808080',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M205.792,247.835c0,1.044-0.991,1.89-2.212,1.89s-2.212-0.846-2.212-1.89c0-1.044,0.99-1.89,2.212-1.89
			S205.792,246.792,205.792,247.835z"/>
		<path id="XMLID_69_" style={{fill:'#808080',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M207.916,253.596c0,1.044-0.99,1.89-2.212,1.89c-1.222,0-2.212-0.846-2.212-1.89c0-1.044,0.99-1.89,2.212-1.89
			C206.925,251.706,207.916,252.552,207.916,253.596z"/>
	</g>
	<g>
		
			<polygon id="XMLID_112_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
			177.964,281.662 162.816,281.662 160.544,256.552 180.236,256.552 		"/>
		<polygon style={{fill:theme.imageHighlight,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
			179.489,264.807 162.093,273.669 162.816,281.662 170.924,281.662 178.782,272.626 		"/>
		
			<rect id="XMLID_114_" x="158.791" y="253.55" style={{fill:theme.imageHighlight,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} width="23.199" height="5.459"/>
	</g>
	<g>
		<g id="XMLID_71_">
			<path id="XMLID_72_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
				M222.781,281.948h-27.254c-1.909,0-3.455-1.547-3.455-3.455c0-1.908,1.546-3.455,3.455-3.455h27.254
				c1.909,0,3.455,1.547,3.455,3.455C226.236,280.401,224.69,281.948,222.781,281.948z"/>
		</g>
		
			<polygon id="XMLID_63_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
			295.258,282.032 211.371,282.032 191.364,227.573 275.252,227.573 		"/>
		
			<polygon id="XMLID_64_" style={{fill:'#808080',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="
			289.935,276.369 218.304,276.369 201.22,229.867 272.852,229.867 		"/>
		<g>
			<path id="XMLID_75_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
				M304.014,301.483c0,0-3.624,5.317-6.589,8.009c-2.965,2.691-12.055,11.111-19.582,23.858
				c-7.527,12.747-12.765,25.783-17.74,37.059c-4.975,11.276-13.956,32.104-18.516,38.439c-4.559,6.335-7.558,9.734-7.558,9.734
				s-7.45,0.171-16.272-0.61c-8.821-0.781-12.465-2.375-12.576-0.077c-0.111,2.298,5.962,8.616,10.338,9.714
				c4.377,1.098,9.965,2.786,11.319,4.091c1.354,1.306,3.054,2.806,5.226,1.848c2.173-0.958,8.822-10.204,16.217-16.579
				c7.396-6.375,16.206-12.682,24.189-27.534c7.983-14.852,8.394-23.337,13.979-28.913c5.585-5.577,11.502-14.327,18.635-18.943
				c7.134-4.616,28.455-13.152,35.263-22.036c6.809-8.884,3.256-19.687-3.29-23.548
				C330.513,292.134,309.141,290.746,304.014,301.483z"/>
			<g>
				
					<path id="XMLID_82_" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M246.857,399.483c-2.014,4.038-3.855,7.4-5.269,9.365c-4.559,6.335-7.558,9.734-7.558,9.734s-7.45,0.171-16.272-0.61
					c-8.821-0.781-12.465-2.375-12.576-0.077c-0.111,2.298,5.962,8.616,10.338,9.714c4.376,1.098,9.965,2.786,11.319,4.091
					c1.354,1.306,3.053,2.806,5.226,1.848c2.173-0.958,8.822-10.204,16.217-16.579c3.441-2.966,7.189-5.929,11.017-9.733
					C253.26,405.781,249.179,402.175,246.857,399.483z"/>
				
					<path id="XMLID_81_" style={{fill:theme.imageHighlight,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M232.064,433.548c0.665-0.293,1.753-1.368,3.149-2.894c-5.976-1.192-9.068-4.204-11.805-4.967
					c-3.846-1.072-7.111-3.179-7.111-3.179l2.979-4.41c-0.501-0.039-1.007-0.081-1.519-0.126
					c-8.821-0.781-12.465-2.375-12.576-0.077c-0.111,2.298,5.962,8.616,10.338,9.714c4.376,1.098,9.965,2.786,11.319,4.091
					C228.193,433.006,229.892,434.506,232.064,433.548z"/>
			</g>
		</g>
		<g>
			<path id="XMLID_70_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
				M242.426,329.639c0,0,1.593,7.433,5.132,14.512c3.54,7.079,11.327,14.512,16.99,18.76c5.663,4.247,23.892,20.529,32.918,33.449
				c1.133,1.622-3.186,7.079-8.495,11.858c-5.309,4.778-7.61,6.548-4.779,7.433c2.832,0.885,9.557,0.531,16.282-5.132
				c6.725-5.663,11.15-9.38,13.273-13.981c1.062-2.301-4.778-5.132-8.318-8.141c-3.539-3.009-16.636-22.83-20.706-32.033
				c-4.071-9.203-14.335-22.299-21.945-26.901"/>
			<g>
				
					<path id="XMLID_80_" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M313.749,396.537c1.062-2.301-4.778-5.133-8.318-8.141c-2.976-2.53-12.707-16.944-18.15-26.94l-12.047,10.648
					c7.487,6.928,16.631,16.238,22.233,24.256c1.133,1.622-3.186,7.079-8.495,11.858c-5.309,4.778-7.61,6.548-4.779,7.433
					c2.832,0.885,9.557,0.531,16.282-5.132C307.201,404.855,311.625,401.139,313.749,396.537z"/>
				
					<path id="XMLID_79_" style={{fill:theme.imageHighlight,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M310.111,391.842c-0.951,1.421-2.187,3.346-3.619,5.757c-3.363,5.663-12.565,12.919-13.804,11.681
					c-0.693-0.693-1.385-1.884-1.862-2.799c-0.599,0.584-1.219,1.165-1.855,1.737c-5.309,4.778-7.61,6.548-4.779,7.433
					c2.832,0.885,9.557,0.531,16.282-5.132c6.725-5.663,11.15-9.38,13.273-13.981C314.393,395.141,312.493,393.549,310.111,391.842z
					"/>
			</g>
		</g>
		
			<rect id="XMLID_77_" x="297.998" y="408.041" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} width="60.173" height="2.832"/>
		<path id="XMLID_76_" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M303.484,332.117L292.953,432.78c-0.047,0.476,0.327,0.889,0.806,0.889l0,0c0.402,0,0.742-0.294,0.801-0.692l15.65-100.861
			H303.484z"/>
		<path id="XMLID_78_" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M351.622,332.117l10.531,100.663c0.047,0.476-0.327,0.889-0.805,0.889l0,0c-0.402,0-0.742-0.294-0.801-0.692l-15.65-100.861
			H351.622z"/>
		<path id="XMLID_83_" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M332.508,332.117l0.527,100.739c0.002,0.449-0.361,0.814-0.809,0.814h-0.012c-0.43,0-0.786-0.337-0.808-0.767l-5.623-100.786
			H332.508z"/>
		<g>
			<defs>
				<path id="XMLID_74_" d="M354.971,333.179H299.25c-2.744,0-4.969-2.224-4.969-4.969v-6.345c0-2.744,2.224-4.969,4.969-4.969
					h55.722c2.744,0,4.969,2.225,4.969,4.969v6.345C359.94,330.954,357.715,333.179,354.971,333.179z"/>
			</defs>
			<use xlinkHref="#XMLID_74_"  style={{overflow:'visible',fill:theme.imageHighlight}}/>
			<clipPath id="XMLID_00000156568269524511682760000005915721401607856277_">
				<use xlinkHref="#XMLID_74_"  style={{overflow:'visible'}}/>
			</clipPath>
			<path style={{opacity:0.2,clipPath:"url(#XMLID_00000156568269524511682760000005915721401607856277_)"}} d="M354.971,316.897
				h-54.446l-1.625,3.407c0,0,50.279,0.872,50.279,3.778c0,2.906,0.872,6.685-9.01,6.685c-8,0-34.85,0.381-44.789,0.526
				c0.397,0.498,0.883,0.912,1.44,1.228l2.917,0.659h55.234c2.744,0,4.969-2.224,4.969-4.969v-6.345
				C359.94,319.121,357.715,316.897,354.971,316.897z"/>
			
				<use xlinkHref="#XMLID_74_"  style={{overflow:'visible',fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}}/>
		</g>
		<path id="XMLID_73_" style={{fill:theme.dark,stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
			M243.842,336.011c-1.239-4.071-6.371-12.212,18.229-29.202c13.041-9.007,41.236-15.928,41.236-15.928l2.655-3.009
			c0,0,7.61,7.964,22.476,7.433c14.866-0.531,24.069-7.079,25.839-6.548c1.77,0.531,1.947,5.132,1.239,13.804
			c-0.708,8.672-1.593,13.096-8.495,15.22c-6.902,2.124-22.122,2.832-33.095,2.655c-10.973-0.177-16.282-0.885-16.282-0.885
			s-23.538,14.866-38.227,15.928"/>
		<g>
			<g id="XMLID_51_">
				
					<path id="XMLID_60_" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M298.352,238.496c-1.068,0.336-33.449,6.725-36.28,6.194c-2.832-0.531-10.265-6.902-12.92-6.725
					c-2.655,0.177-7.964,1.062-8.495,2.478c-0.531,1.416,1.239,6.194,1.239,6.194s-1.77,2.655-0.885,3.894
					c0.885,1.239,6.017-1.77,6.017-1.77s5.84,4.247,8.495,3.363c2.655-0.885,5.486-1.947,5.486-1.947s41.59,2.478,42.121,2.655
					c0.531,0.177,5.663,1.239,6.194-6.194C309.855,239.204,304.546,236.549,298.352,238.496z"/>
				
					<line id="XMLID_61_" style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="241.895" y1="246.637" x2="249.152" y2="240.619"/>
				<polygon id="XMLID_58_" style={{fill:theme.dark}} points="244.55,243.982 248.444,231.77 250.036,232.124 248.09,240.973 				"/>
			</g>
			<g>
				<defs>
					<path id="XMLID_59_" d="M273.044,251.415c0,0-43.006,11.858-49.908,10.973c-6.902-0.885-9.026-3.893-10.619-3.893
						c-1.593,0-6.017,2.124-7.964,1.947c-1.947-0.177-4.248-0.708-4.248,0.531c0,1.239,1.77,3.186,2.832,3.186
						c1.062,0,5.309-0.177,5.309-0.177s-8.318,2.478-8.672,4.07c-0.354,1.593,0.708,3.009,2.301,3.363
						c1.593,0.354,2.301,1.062,2.301,1.062s0.708,2.655,1.77,3.893c1.062,1.239,4.248,1.77,7.787,1.062
						c3.539-0.708,9.734-3.894,11.15-4.779c1.416-0.885,44.421-4.778,47.607-5.486c3.186-0.708,7.079-0.708,8.318-2.832
						C282.247,262.211,273.044,251.415,273.044,251.415z"/>
				</defs>
				<use xlinkHref="#XMLID_59_"  style={{overflow:'visible',fill:'#FFFFFF'}}/>
				<clipPath id="XMLID_00000068642971759879699400000002199371956976440722_">
					<use xlinkHref="#XMLID_59_"  style={{overflow:'visible'}}/>
				</clipPath>
				<path style={{clipPath:"url(#XMLID_00000068642971759879699400000002199371956976440722_)",fill:theme.imageHighlight}} d="M221.036,270.952
					c-7.388,0-5.746-7.388-8.209-6.156c-2.148,1.074-10.542,2.149-12.922,4.858c0.351,0.867,1.148,1.533,2.17,1.76
					c1.593,0.354,2.301,1.062,2.301,1.062s0.708,2.655,1.77,3.893c1.062,1.239,4.248,1.77,7.787,1.062
					c3.539-0.708,9.734-3.893,11.15-4.778c1.416-0.885,44.422-4.778,47.607-5.486c2.574-0.572,5.61-0.683,7.334-1.825
					c0.611-0.617,0.984-1.007,0.984-1.007l-4.564-4.464C276.444,259.87,228.424,270.952,221.036,270.952z"/>
				<path style={{opacity:0.5,clipPath:"url(#XMLID_00000068642971759879699400000002199371956976440722_)",fill:'#FFFFFF'}} d="
					M221.036,270.952c-7.388,0-5.746-7.388-8.209-6.156c-2.148,1.074-10.542,2.149-12.922,4.858c0.351,0.867,1.148,1.533,2.17,1.76
					c1.593,0.354,2.301,1.062,2.301,1.062s0.708,2.655,1.77,3.893c1.062,1.239,4.248,1.77,7.787,1.062
					c3.539-0.708,9.734-3.893,11.15-4.778c1.416-0.885,44.422-4.778,47.607-5.486c2.574-0.572,5.61-0.683,7.334-1.825
					c0.611-0.617,0.984-1.007,0.984-1.007l-4.564-4.464C276.444,259.87,228.424,270.952,221.036,270.952z"/>
				
					<use xlinkHref="#XMLID_59_"  style={{overflow:'visible',fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}}/>
			</g>
			<g>
				<g>
					<g>
						<defs>
							<path id="XMLID_56_" d="M309.324,208.055c0,0-3.894,10.619-8.849,17.698c-4.955,7.079-7.079,14.512-3.893,22.476
								c3.186,7.964,10.088,9.734,10.088,9.734s-2.478,23.361-1.77,29.555c0.708,6.194,10.265,13.45,24.6,13.981
								c14.335,0.531,26.724-4.601,26.724-9.557c0-4.955-10.088-31.325-10.088-36.812c0-5.486,0.885-22.653-3.893-38.05
								C337.464,201.684,320.651,196.729,309.324,208.055z"/>
						</defs>
						<use xlinkHref="#XMLID_56_"  style={{overflow:'visible',fill:theme.imageHighlight}}/>
						<clipPath id="XMLID_00000054228979896953615640000005569129733775432111_">
							<use xlinkHref="#XMLID_56_"  style={{overflow:'visible'}}/>
						</clipPath>
						<path style={{opacity:0.2,clipPath:"url(#XMLID_00000054228979896953615640000005569129733775432111_)"}} d="M342.242,217.081
							c-2.574-8.295-8.643-13.552-15.464-14.873c-3.291,0.729-12.275,2.837-14.519,3.364c-1.009,0.721-1.993,1.54-2.935,2.483
							c0,0-3.894,10.619-8.849,17.698c-3.322,4.746-5.362,9.653-5.391,14.771c13.059,3.086,32.996,8.11,31.021,8.674
							c-2.873,0.821-15.186-5.746-11.492-1.642c3.694,4.104,23.394,22.984,17.648,21.342c-5.746-1.642-18.469-15.186-15.186-8.619
							c3.283,6.567,12.313,17.238,9.44,17.238c-2.873,0-18.059-15.186-12.723-6.156c5.336,9.029,29.551,23.394,21.753,25.446
							c-4.501,1.185-14.052,0.868-19.595,1.806c3.756,1.6,8.331,2.692,13.55,2.885c14.335,0.531,26.724-4.602,26.724-9.557
							c0-4.955-10.088-31.325-10.088-36.812C346.136,249.645,347.021,232.478,342.242,217.081z"/>
						
							<use xlinkHref="#XMLID_56_"  style={{overflow:'visible',fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}}/>
					</g>
					<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M311.5,251
						c0,0,13,21,32,27"/>
					<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M310,254.5
						c0,0,5,16.5,18,24.5"/>
					<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M312.682,241.281
						c0,0,11.746-5.12,16.564-8.433"/>
					<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M307.863,242.486
						c0,0,4.216,0.904,15.058,0"/>
				</g>
				<g>
					<defs>
						<path id="XMLID_57_" d="M308.262,210.71c0,0-3.363,1.947-7.433,6.194s-23.715,33.98-23.715,33.98s5.84,12.212,7.61,12.565
							c1.77,0.354,32.564-26.016,35.396-29.201c2.832-3.186,4.602-9.026,3.717-11.503"/>
					</defs>
					<use xlinkHref="#XMLID_57_"  style={{overflow:'visible',fill:theme.imageHighlight}}/>
					<clipPath id="XMLID_00000060719504099701721580000007952746654017141150_">
						<use xlinkHref="#XMLID_57_"  style={{overflow:'visible'}}/>
					</clipPath>
					<path style={{opacity:0.2,clipPath:"url(#XMLID_00000060719504099701721580000007952746654017141150_)"}} d="M320.12,234.248
						c2.832-3.186,4.602-9.026,3.717-11.503l-6.991-5.402l-2.232,2.307c0,0-0.155,9.062-8.208,13.954s-11.492,4.925-12.723,11.081
						c-1.231,6.156,2.873,7.798-2.052,8.619s-8.208,1.231-8.208,4.515c0,0.974,0.11,2.818,0.37,4.873
						c0.37,0.432,0.691,0.71,0.934,0.758C286.494,263.803,317.288,237.434,320.12,234.248z"/>
					
						<use xlinkHref="#XMLID_57_"  style={{overflow:'visible',fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}}/>
				</g>
				
					<path id="XMLID_54_" style={{fill:'#FFFFFF',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
					M270.389,252.654c0,0,5.486,13.096,6.725,14.512c1.239,1.416,7.787,0.531,9.911-1.062c2.124-1.593,1.77-4.778,1.77-4.778
					s-8.141-14.335-9.026-15.043s-1.239,0.885-4.955,2.124C271.097,249.645,269.681,250.176,270.389,252.654z"/>
			</g>
			<g>
				<g>
					<defs>
						<path id="XMLID_52_" d="M279.238,174.607c0,0-0.354,5.132,0.531,6.725c0.885,1.593,3.363,3.186,3.363,5.309
							c0,2.124-1.77,4.955-1.239,6.548c0.531,1.593,3.363,1.77,3.363,1.77s5.309,6.548,6.902,8.849
							c1.593,2.301,2.301,1.416,4.778,0.708c2.478-0.708,7.787-5.309,10.796-8.849c3.009-3.54-1.947-14.335-8.495-21.591
							c-6.548-7.256-16.282-7.079-18.406-4.955C278.707,171.244,279.238,174.607,279.238,174.607z"/>
					</defs>
					<use xlinkHref="#XMLID_52_"  style={{overflow:'visible',fill:'#FFFFFF'}}/>
					<clipPath id="XMLID_00000182508193777099177340000017972718553269799856_">
						<use xlinkHref="#XMLID_52_"  style={{overflow:'visible'}}/>
					</clipPath>
					<path style={{clipPath:'url(#XMLID_00000182508193777099177340000017972718553269799856_)',fill:theme.imageHighlight}} d="M293.27,169.647
						c-0.82,4.044-1.639,10.876,0.822,15.526c3.694,6.977,6.567,10.671,4.515,16.417c-0.366,1.023-0.744,1.924-1.119,2.723
						c2.647-1.136,7.442-5.349,10.244-8.646c3.009-3.54-1.947-14.335-8.495-21.591C297.417,172.059,295.35,170.633,293.27,169.647z"
						/>
					<path style={{opacity:0.5,clipPath:'url(#XMLID_00000182508193777099177340000017972718553269799856_)',fill:'#FFFFFF'}} d="
						M293.27,169.647c-0.82,4.044-1.639,10.876,0.822,15.526c3.694,6.977,6.567,10.671,4.515,16.417
						c-0.366,1.023-0.744,1.924-1.119,2.723c2.647-1.136,7.442-5.349,10.244-8.646c3.009-3.54-1.947-14.335-8.495-21.591
						C297.417,172.059,295.35,170.633,293.27,169.647z"/>
					
						<path id="XMLID_55_" style={{clipPath:'url(#XMLID_00000182508193777099177340000017972718553269799856_)',fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
						M283.132,182.217c0,0,0-2.655,3.186-0.885"/>
					
						<path id="XMLID_48_" style={{clipPath:'url(#XMLID_00000182508193777099177340000017972718553269799856_)',fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="
						M285.401,184.684l0.421,2.443c0,0,1.938,0.674,3.37-1.854"/>
					
						<use xlinkHref="#XMLID_52_"  style={{overflow:'visible',fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}}/>
				</g>
				<g>
					<defs>
						<path id="XMLID_53_" d="M273.752,166.819l-0.027,0.707l-0.327,8.673l9.557,1.239l2.124-3.716v4.07c0,0,4.07-0.531,8.672,1.239
							c4.601,1.77,10.442,14.335,10.088,19.468c-0.354,5.132-4.778,6.194-4.247,13.981c0.531,7.787,12.388,15.574,21.414,10.619
							s10.442-11.327,14.158-10.088c3.716,1.239,9.38,8.849,12.919-1.947s1.947-17.698-6.371-26.547
							c-8.318-8.849-14.866-8.141-15.928-15.22c-1.062-7.079-3.363-16.636-10.973-21.945c-7.61-5.309-18.583-5.663-27.963-1.593
							C277.468,149.829,273.752,162.218,273.752,166.819z"/>
					</defs>
					<use xlinkHref="#XMLID_53_"  style={{overflow:'visible',fill:theme.dark}}/>
					<clipPath id="XMLID_00000126320778673436722990000000836923817422024889_">
						<use xlinkHref="#XMLID_53_"  style={{overflow:'visible'}}/>
					</clipPath>
					<g style={{clipPath:'url(#XMLID_00000126320778673436722990000000836923817422024889_)'}}>
						<path style={{fill:'#575757'}} d="M279.074,170.884c-0.064,0-0.126-0.007-0.189-0.021c-1.024-0.23-1.166-2.004-1.262-6.306
							c-0.025-1.114-0.051-2.266-0.121-3.185c-0.008-0.091-0.017-0.167-0.025-0.23c-0.842,1.018-2.65,5.25-4.006,9.028l-0.941-0.338
							c3.618-10.08,4.798-9.933,5.184-9.876c0.689,0.093,0.76,0.999,0.785,1.34c0.073,0.946,0.1,2.112,0.125,3.239
							c0.039,1.776,0.104,4.692,0.482,5.305c0.146-0.114,0.496-0.492,1.115-1.73c1.042-2.084,1.961-3.043,2.919-2.991
							c1.185,0.054,1.798,1.623,2.245,2.769c0.134,0.342,0.272,0.695,0.365,0.835c0.439,0.117,2-1.476,2.835-2.333
							c0.246-0.253,0.491-0.505,0.729-0.743c0.376-0.376,0.873-0.795,1.421-0.714c0.555,0.084,0.829,0.603,1.12,1.152
							c0.268,0.507,0.571,1.083,1.125,1.525c0.316,0.253,0.482,0.214,0.546,0.199c0.722-0.174,1.593-2.114,2.603-4.362
							c0.235-0.523,0.484-1.078,0.751-1.656c0.33-0.716,0.783-1.55,1.519-1.479c0.938,0.07,1.361,1.531,2.004,3.741
							c0.523,1.802,1.116,3.846,1.993,4.973c0.221,0.284,0.537,0.606,0.824,0.569c0.784-0.121,1.645-2.435,2.159-3.818
							c0.315-0.848,0.587-1.579,0.86-2.035c0.281-0.468,0.64-0.556,0.854-0.547c0.741,0.025,1.244,1.006,1.939,2.364
							c0.52,1.016,1.232,2.407,1.822,2.643c0.117,0.046,0.161,0.025,0.186,0.014c0.388-0.196,0.725-1.483,0.995-2.517
							c0.314-1.201,0.64-2.442,1.218-3.309c0.351-0.526,0.809-0.838,1.363-0.927c1.306-0.21,2.765,0.919,3.737,1.676
							c-0.051-0.508-0.157-1.211-0.249-1.822c-0.501-3.323-0.645-4.939,0.187-5.394c0.388-0.212,0.85-0.09,1.373,0.367
							c0.694,0.608,1.6,1.621,2.558,2.694c1.833,2.054,4.61,5.158,5.732,4.857c0.313-0.083,0.477-0.56,0.559-0.947l0.979,0.21
							c-0.206,0.96-0.637,1.533-1.281,1.704c-1.606,0.425-3.801-1.872-6.734-5.158c-0.938-1.049-1.822-2.041-2.47-2.607
							c-0.108-0.095-0.188-0.149-0.242-0.18c-0.224,0.633,0.147,3.088,0.329,4.304c0.355,2.353,0.429,2.981-0.032,3.274
							c-0.202,0.128-0.452,0.119-0.669-0.025c-0.17-0.113-0.386-0.281-0.634-0.475c-0.785-0.611-2.104-1.626-2.982-1.493
							c-0.276,0.045-0.496,0.202-0.691,0.495c-0.485,0.728-0.789,1.887-1.082,3.008c-0.388,1.48-0.722,2.758-1.513,3.157
							c-0.215,0.108-0.565,0.199-1.007,0.023c-0.938-0.375-1.651-1.769-2.341-3.116c-0.313-0.61-0.768-1.499-1.041-1.764
							c-0.002,0.002-0.003,0.004-0.004,0.006c-0.226,0.376-0.495,1.101-0.78,1.868c-0.74,1.991-1.58,4.248-2.945,4.458
							c-0.609,0.092-1.205-0.224-1.765-0.943c-0.995-1.279-1.616-3.42-2.165-5.309c-0.343-1.181-0.81-2.79-1.141-3.021
							c0,0.041-0.196,0.213-0.513,0.899c-0.266,0.575-0.513,1.126-0.747,1.647c-1.254,2.791-2.082,4.635-3.28,4.924
							c-0.471,0.113-0.94-0.019-1.405-0.391c-0.716-0.572-1.102-1.304-1.385-1.838c-0.129-0.245-0.306-0.58-0.401-0.635
							c0.004,0.008-0.154,0.043-0.548,0.436c-0.235,0.235-0.477,0.483-0.721,0.734c-1.658,1.703-2.717,2.724-3.631,2.649
							c-0.31-0.029-0.571-0.188-0.752-0.459c-0.153-0.23-0.291-0.581-0.464-1.026c-0.288-0.737-0.824-2.109-1.36-2.133
							c-0.164-0.034-0.834,0.148-1.979,2.439C280.301,170.183,279.693,170.884,279.074,170.884z"/>
						<path style={{fill:'none',stroke:'#575757',strokeMiterlimit:10}} d="M298.963,211.063c0,0,2.477-9.232,4.954-6.305
							c2.477,2.927,4.279,3.828,4.504,1.351c0.225-2.477,0-6.08,2.477-2.702s8.557,6.755,9.007,1.576
							c0.45-5.179,1.126-12.16,4.053-8.557c2.927,3.603,5.404,6.305,5.63,4.279c0.225-2.027,2.252-3.603,3.152-1.351
							c0.901,2.252,1.576,4.504,2.252-0.676c0.676-5.179-2.477-10.809,0.901-9.457c3.378,1.351,4.954,5.855,4.954,3.378
							c0-2.477-4.053-5.404-0.45-4.504c3.603,0.901,4.053,3.152,4.504,1.801c0.45-1.351-2.027-4.504,1.126-4.504"/>
					</g>
					
						<use xlinkHref="#XMLID_53_"  style={{overflow:'visible',fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}}/>
				</g>
			</g>
		</g>
		<path style={{fill:'none',stroke:theme.dark,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M295.315,190.281"/>
	</g>
</g>
<g id="Ground">
	<line style={{fill:'none',stroke:theme.dark,strokeMiterlimit:10}} x1="67.226" y1="434.359" x2="433.168" y2="434.359"/>
</g>
</svg>

    );
  }
}
