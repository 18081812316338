import React from "react";
import ProjectLanguages from "../../components/projectLanguages/ProjectLanguages";
import "./GithubRepoCard.css";
import { Fade } from "react-reveal";

export default function GithubRepoCard({ repo, theme }) {
  function openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="card" style={{ backgroundColor: theme.highlight, color: theme.text }} key={repo.id} onClick={() => openRepoinNewTab(repo.url)}>
          <div className="border" style={{ borderColor: theme.text }}> 
            <p className="repo-description" style={{ color: theme.text }}>
              {repo.description}
            </p>
            <div className="repo-details">
              <ProjectLanguages
                  className="repo-languages"
                  logos={repo.languages}
              />
            </div>
          </div>
          <div className="content">
            {repo.icon && (
              <img className="repo-icon" src={require(`../../assests/images/${repo.icon}`)} alt={repo.name}></img>
            )}
            <div className="repo-name-div">
              <p className="repo-name" style={{ color: theme.text }}>
                {repo.name}
              </p>
            </div>
            <div className="repo-details">
              <ProjectLanguages
                  className="repo-languages"
                  logos={repo.languages}
              />
            </div>
          </div>
          <span className="bottom-text" style={{ backgroundColor: theme.highlight, color: theme.text }}>{repo.createdAt}</span>
      </div>
    </Fade>
  );
}
