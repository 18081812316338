import React, { Component } from "react";

export default class FullStack extends Component {
  render() {
    const theme = this.props.theme;
    return (

        <svg
        id="1c51936f-ad83-4493-a1e8-5a7ca1846cda"
        data-name="Layer 1" x="0px" y="0px"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        width="1164.07"
        height="855.93"
        viewBox="0 0 750 500"
      >
<g id="Background_Complete">
	<g>
		<g>
			<g>
				<path style={{fill:'#E0E0E0'}} d="M260.437,82.946c4.134-2.498,8.535-4.392,13.074-5.682l2.693-12.941l21.865-0.383l3.147,12.837
					c4.582,1.129,9.047,2.867,13.267,5.219l11.05-7.243l15.732,15.19l-6.849,11.296c2.499,4.135,4.393,8.538,5.682,13.077
					l12.94,2.693l0.384,21.864l-12.838,3.147c-1.129,4.581-2.867,9.045-5.219,13.265l7.242,11.051l-15.188,15.732l-11.297-6.85
					c-4.135,2.499-8.536,4.392-13.076,5.681l-2.694,12.941l-21.864,0.384l-3.147-12.838c-4.581-1.129-9.046-2.868-13.266-5.219
					l-11.05,7.243l-15.732-15.19l6.849-11.296c-2.498-4.135-4.394-8.536-5.682-13.076l-12.94-2.693l-0.384-21.864l12.838-3.148
					c1.129-4.581,2.869-9.046,5.219-13.265l-7.243-11.051l15.188-15.732L260.437,82.946L260.437,82.946z M272.092,112.073
					c-8.999,9.321-8.738,24.172,0.583,33.171c9.321,8.999,24.172,8.738,33.17-0.584c8.998-9.321,8.738-24.172-0.583-33.17
					C295.941,102.491,281.091,102.752,272.092,112.073z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path style={{fill:'#F5F5F5'}} d="M161.28,57.081c2.623-1.585,5.416-2.788,8.297-3.606l1.709-8.213l13.875-0.243l1.997,8.146
					c2.908,0.716,5.742,1.82,8.419,3.312l7.013-4.597l9.984,9.64l-4.347,7.168c1.586,2.624,2.788,5.418,3.605,8.299l8.212,1.709
					l0.244,13.875l-8.147,1.997c-0.716,2.907-1.82,5.74-3.312,8.418l4.596,7.013l-9.638,9.984l-7.169-4.347
					c-2.624,1.585-5.417,2.787-8.298,3.605l-1.709,8.213l-13.875,0.243l-1.997-8.147c-2.908-0.717-5.741-1.82-8.419-3.312
					l-7.013,4.597l-9.984-9.639l4.347-7.169c-1.586-2.624-2.788-5.417-3.606-8.298l-8.212-1.709l-0.243-13.875l8.147-1.998
					c0.716-2.907,1.82-5.741,3.312-8.418l-4.596-7.013l9.638-9.984L161.28,57.081L161.28,57.081z M168.677,75.565
					c-5.711,5.915-5.545,15.34,0.37,21.05c5.915,5.711,15.34,5.545,21.05-0.37c5.71-5.915,5.546-15.34-0.37-21.05
					C183.812,69.484,174.388,69.65,168.677,75.565z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path style={{fill:'#F5F5F5'}} d="M642.533,234.238c2.758-1.667,5.695-2.931,8.724-3.791l1.797-8.635l14.59-0.256l2.099,8.566
					c3.057,0.753,6.037,1.913,8.853,3.482l7.374-4.833l10.497,10.136l-4.57,7.537c1.668,2.759,2.931,5.697,3.791,8.726l8.634,1.797
					l0.256,14.589l-8.566,2.1c-0.753,3.057-1.913,6.036-3.482,8.851l4.832,7.374l-10.134,10.498l-7.538-4.571
					c-2.759,1.667-5.696,2.931-8.725,3.791l-1.797,8.635l-14.589,0.256l-2.1-8.567c-3.057-0.753-6.036-1.914-8.852-3.482
					l-7.374,4.833l-10.498-10.136l4.57-7.538c-1.667-2.759-2.932-5.696-3.792-8.725l-8.635-1.797l-0.256-14.589l8.566-2.1
					c0.753-3.057,1.914-6.036,3.482-8.851l-4.833-7.374l10.135-10.498L642.533,234.238L642.533,234.238z M650.31,253.674
					c-6.005,6.22-5.831,16.129,0.389,22.134c6.219,6.005,16.129,5.83,22.133-0.389c6.004-6.22,5.831-16.129-0.389-22.134
					C666.224,247.281,656.315,247.455,650.31,253.674z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path style={{fill:'#E0E0E0'}} d="M94.071,164.53c2.999-1.812,6.192-3.187,9.485-4.122l1.954-9.389l15.862-0.278l2.283,9.313
					c3.324,0.819,6.564,2.08,9.625,3.786l8.017-5.255l11.413,11.02l-4.969,8.195c1.813,3,3.187,6.194,4.122,9.487l9.388,1.954
					l0.279,15.862l-9.313,2.283c-0.819,3.323-2.08,6.562-3.786,9.624l5.254,8.017l-11.019,11.413l-8.196-4.969
					c-3,1.813-6.193,3.186-9.486,4.122l-1.954,9.389l-15.862,0.278l-2.283-9.314c-3.324-0.819-6.563-2.081-9.624-3.786l-8.017,5.255
					l-11.413-11.02l4.969-8.195c-1.813-3-3.187-6.193-4.122-9.486l-9.388-1.954l-0.278-15.862l9.314-2.284
					c0.819-3.323,2.081-6.563,3.786-9.624l-5.254-8.017l11.019-11.413L94.071,164.53L94.071,164.53z M102.527,185.661
					c-6.529,6.762-6.34,17.536,0.423,24.065c6.762,6.529,17.536,6.339,24.064-0.423c6.528-6.762,6.339-17.536-0.423-24.065
					C119.829,178.709,109.055,178.899,102.527,185.661z"/>
			</g>
		</g>
	</g>
</g>
<g id="Floor">
	<g>
		<g>
			<path style={{fill:theme.imageHighlight}} d="M43.353,443.483c219.673,0,439.346,0,659.019,0"/>
		</g>
		<g>
			<g>
				<path style={{fill:theme.dark}} d="M702.372,443.483c0,0.144-147.538,0.26-329.493,0.26c-182.019,0-329.525-0.117-329.525-0.26
					s147.507-0.26,329.525-0.26C554.834,443.222,702.372,443.339,702.372,443.483z"/>
			</g>
		</g>
	</g>
</g>
<g id="Plant">
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.jacketColor}} d="M634.836,335.069c-1.92-6.545-6.995-9.918-12.736-10.018c-5.19,1.127-7.01,6.356-7.419,11.652
						c-0.408,5.296,1.44,10.476,3.26,15.466c5.844,16.021,11.688,32.043,17.532,48.064c4.883-0.733,9.766-1.467,14.648-2.2
						C644.587,376.475,641.1,356.426,634.836,335.069z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M622.296,324.623c0.052,0.042-0.753,0.778-1.682,2.461c-0.927,1.665-1.873,4.356-1.929,7.789
							c-0.087,3.433,0.84,7.465,2.298,11.772c1.469,4.327,3.08,9.074,4.772,14.059c3.341,9.967,6.364,18.986,8.575,25.581
							c1.059,3.239,1.922,5.88,2.54,7.772c0.276,0.881,0.497,1.586,0.665,2.122c0.146,0.487,0.214,0.746,0.197,0.752
							c-0.018,0.006-0.121-0.241-0.3-0.717c-0.192-0.527-0.445-1.222-0.761-2.089c-0.658-1.878-1.576-4.5-2.703-7.717
							c-2.25-6.582-5.326-15.584-8.725-25.53c-1.678-4.991-3.277-9.744-4.734-14.077c-1.438-4.332-2.345-8.438-2.207-11.937
							c0.107-3.496,1.13-6.225,2.129-7.884C621.432,325.302,622.296,324.623,622.296,324.623z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M646.85,396.59c-0.017,0.008-0.141-0.23-0.361-0.692c-0.212-0.464-0.553-1.138-0.922-2.036
							c-0.775-1.78-1.781-4.407-2.846-7.711c-1.07-3.303-2.152-7.295-3.221-11.755c-1.034-4.47-2.169-9.375-3.361-14.524
							c-1.232-5.138-2.569-9.993-4.068-14.313c-1.495-4.321-3.13-8.109-4.747-11.164c-1.61-3.058-3.125-5.418-4.255-6.988
							c-0.539-0.804-1.023-1.38-1.314-1.8c-0.301-0.412-0.453-0.634-0.438-0.646c0.014-0.012,0.194,0.187,0.524,0.578
							c0.319,0.398,0.832,0.955,1.399,1.743c1.186,1.538,2.76,3.879,4.423,6.932c1.67,3.05,3.351,6.846,4.88,11.179
							c1.532,4.333,2.888,9.203,4.123,14.352c1.177,5.154,2.298,10.062,3.32,14.535c1.041,4.459,2.081,8.449,3.101,11.756
							c1.014,3.308,1.962,5.946,2.675,7.746c0.338,0.908,0.646,1.594,0.826,2.072C646.776,396.328,646.867,396.582,646.85,396.59z"
							/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M618.106,349.728c0.041,0.144-0.746,0.495-1.759,0.784c-1.013,0.29-1.867,0.408-1.908,0.264
							c-0.041-0.144,0.746-0.495,1.759-0.784C617.211,349.703,618.065,349.585,618.106,349.728z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M616.142,339.749c-0.002,0.152-1.111,0.259-2.471,0.369c-1.36,0.107-2.472,0.177-2.498,0.027
							c-0.026-0.145,1.066-0.458,2.455-0.567C615.016,339.466,616.145,339.603,616.142,339.749z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M617.43,330.511c-0.092,0.118-0.912-0.369-1.832-1.087c-0.92-0.719-1.591-1.397-1.499-1.514
							c0.092-0.118,0.912,0.369,1.832,1.088C616.851,329.716,617.522,330.394,617.43,330.511z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M621.575,321.84c0.151,0.028,0.138,0.744,0.151,1.61c0.007,0.866,0.035,1.582-0.114,1.613
							c-0.142,0.033-0.419-0.68-0.427-1.607C621.172,322.53,621.432,321.811,621.575,321.84z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M630.909,325.47c0.126,0.081-0.204,0.82-0.738,1.651c-0.533,0.831-1.068,1.439-1.194,1.359
							c-0.126-0.081,0.204-0.82,0.738-1.651C630.249,325.998,630.783,325.39,630.909,325.47z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M637.278,335.571c0.062,0.136-0.682,0.608-1.662,1.055c-0.98,0.446-1.824,0.698-1.886,0.561
							c-0.062-0.136,0.682-0.608,1.662-1.055C636.371,335.686,637.216,335.435,637.278,335.571z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M641.318,346.063c0.066,0.129-0.92,0.745-2.292,1.112c-1.371,0.372-2.533,0.335-2.54,0.19
							c-0.013-0.154,1.085-0.355,2.399-0.713C640.201,346.302,641.252,345.924,641.318,346.063z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M624.618,343.711c-0.145-0.039-0.071-0.778,0.165-1.651c0.235-0.873,0.543-1.549,0.688-1.51
							c0.144,0.039,0.071,0.778-0.165,1.651C625.07,343.073,624.762,343.75,624.618,343.711z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M627.469,356.973c-0.139-0.054,0.119-1.056,0.576-2.238c0.458-1.182,0.942-2.097,1.081-2.043
							c0.139,0.054-0.118,1.056-0.576,2.238C628.093,356.113,627.609,357.027,627.469,356.973z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M621.904,360.861c-0.052,0.143-0.995-0.074-2.138-0.38c-1.142-0.309-2.066-0.594-2.04-0.744
							c0.025-0.145,1.013-0.095,2.181,0.221C621.076,360.27,621.955,360.722,621.904,360.861z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M625.637,371.618c0.092,0.118-0.515,0.742-1.354,1.394c-0.84,0.652-1.594,1.084-1.686,0.966
							c-0.092-0.118,0.514-0.742,1.354-1.394C624.79,371.932,625.545,371.5,625.637,371.618z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M628.716,382.369c0.041,0.144-0.726,0.49-1.713,0.772c-0.987,0.283-1.821,0.395-1.862,0.252
							s0.726-0.489,1.713-0.772C627.841,382.337,628.675,382.225,628.716,382.369z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M641.975,355.122c0.08,0.122-0.565,0.681-1.521,1.084c-0.955,0.407-1.805,0.481-1.837,0.339
							c-0.036-0.149,0.718-0.45,1.626-0.837C641.151,355.325,641.893,354.993,641.975,355.122z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M644.704,371.931c-0.058,0.138-0.862-0.07-1.795-0.464c-0.934-0.394-1.643-0.825-1.585-0.963
							c0.058-0.138,0.862,0.07,1.796,0.464C644.053,371.362,644.762,371.793,644.704,371.931z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M648.807,383.872c-0.049,0.141-1.109-0.097-2.368-0.533c-1.259-0.436-2.24-0.904-2.191-1.045
							c0.049-0.141,1.109,0.097,2.368,0.533C647.875,383.263,648.856,383.731,648.807,383.872z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M629.77,394.125c-0.079-0.133,0.86-0.821,1.939-1.746c1.084-0.92,1.911-1.738,2.03-1.64
							c0.113,0.09-0.553,1.096-1.678,2.052C630.94,393.752,629.841,394.251,629.77,394.125z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M639.08,387.593c-0.153,0.024-0.393-0.779-0.801-1.708c-0.4-0.933-0.821-1.657-0.699-1.752
							c0.11-0.093,0.763,0.531,1.196,1.537C639.214,386.672,639.224,387.576,639.08,387.593z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M635.96,373.158c-0.082,0.125-0.711-0.144-1.405-0.601c-0.694-0.457-1.19-0.929-1.107-1.054
							c0.082-0.125,0.711,0.144,1.405,0.601C635.546,372.562,636.042,373.033,635.96,373.158z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M646.698,361.162c0.038,0.141-0.821,0.489-1.945,0.607c-1.124,0.122-2.036-0.035-2.029-0.181
							c0.006-0.153,0.892-0.239,1.972-0.357C645.775,361.118,646.66,361.014,646.698,361.162z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M647.588,352.434c-0.005,0.144-0.885,0.203-1.867-0.205c-0.984-0.402-1.568-1.063-1.47-1.169
							c0.099-0.119,0.781,0.305,1.677,0.668C646.818,352.101,647.601,352.28,647.588,352.434z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M649.282,376.324c-0.02,0.148-0.761,0.17-1.656,0.049c-0.894-0.121-1.603-0.339-1.583-0.487
							c0.02-0.148,0.761-0.17,1.656-0.049C648.594,375.958,649.302,376.176,649.282,376.324z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M676.197,360.285c-0.104,0.107-0.837-0.433-1.637-1.207c-0.8-0.775-1.365-1.489-1.261-1.597
							c0.104-0.108,0.837,0.433,1.637,1.207C675.736,359.463,676.301,360.177,676.197,360.285z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.jacketColor}} d="M703.129,327.219c0.453-3.284,0.865-6.656,0.118-9.886c-0.746-3.23-2.889-6.337-6.058-7.311
							c-3.616-1.111-7.615,0.861-9.978,3.816c-2.363,2.954-3.434,6.71-4.394,10.37c-6.618,25.216-10.623,51.116-11.932,77.152
							l14.893,3.538C694.958,378.437,699.302,354.963,703.129,327.219z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M695.105,309.708c0.008,0.005-0.023,0.084-0.091,0.234c-0.084,0.178-0.188,0.395-0.314,0.661
								c-0.306,0.615-0.724,1.453-1.251,2.513c-0.264,0.549-0.602,1.137-0.893,1.819c-0.292,0.684-0.609,1.424-0.948,2.217
								c-0.342,0.797-0.707,1.646-1.093,2.545c-0.336,0.923-0.69,1.898-1.061,2.919c-1.533,4.076-2.956,9.071-4.322,14.662
								c-1.351,5.599-2.479,11.835-3.522,18.401c-2.017,13.151-3.268,25.143-4.425,33.791c-0.57,4.325-1.049,7.823-1.444,10.232
								c-0.19,1.169-0.341,2.094-0.452,2.773c-0.053,0.289-0.096,0.525-0.131,0.719c-0.033,0.162-0.054,0.244-0.063,0.242
								c-0.009-0.001-0.006-0.086,0.008-0.251c0.021-0.196,0.046-0.434,0.078-0.727c0.086-0.682,0.203-1.612,0.351-2.786
								c0.33-2.416,0.751-5.92,1.272-10.249c1.06-8.656,2.25-20.655,4.27-33.827c1.045-6.577,2.19-12.825,3.572-18.434
								c1.397-5.602,2.863-10.604,4.447-14.678c0.385-1.021,0.753-1.993,1.101-2.916c0.401-0.897,0.78-1.744,1.136-2.538
								c0.354-0.789,0.686-1.525,0.991-2.206c0.306-0.677,0.659-1.261,0.938-1.803c0.567-1.041,1.015-1.866,1.344-2.47
								c0.145-0.255,0.264-0.464,0.361-0.635C695.05,309.773,695.097,309.703,695.105,309.708z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M696.503,309.848c0,0,0.836,1.037,1.55,3.296c0.747,2.244,1.02,5.727,0.457,9.893
								c-1.186,8.302-3.015,19.723-5.202,32.318c-2.193,12.597-4.379,23.963-6.051,32.172c-0.835,4.104-1.537,7.421-2.039,9.709
								c-0.249,1.107-0.447,1.984-0.593,2.631c-0.067,0.273-0.122,0.496-0.168,0.682c-0.041,0.153-0.066,0.23-0.075,0.228
								c-0.009-0.002-0.002-0.083,0.021-0.24c0.031-0.188,0.069-0.415,0.115-0.692c0.121-0.652,0.286-1.536,0.493-2.652
								c0.451-2.349,1.087-5.664,1.87-9.74c1.575-8.225,3.7-19.599,5.893-32.191c2.186-12.586,4.076-24.008,5.356-32.274
								c0.606-4.132,0.394-7.552-0.279-9.785C697.21,310.959,696.433,309.88,696.503,309.848z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M703.289,349.895c-0.063,0.135-1.313-0.316-2.791-1.009c-1.478-0.693-2.625-1.364-2.561-1.5
								c0.063-0.135,1.313,0.316,2.791,1.009C702.206,349.088,703.353,349.76,703.289,349.895z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M706.555,334.685c0.048,0.142-1.234,0.704-2.863,1.257c-1.63,0.553-2.99,0.886-3.038,0.744
								c-0.048-0.142,1.234-0.704,2.864-1.257C705.147,334.876,706.507,334.543,706.555,334.685z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M706.795,322.042c-0.024,0.148-1.037,0.104-2.261-0.097c-1.225-0.201-2.197-0.484-2.173-0.632
								c0.024-0.147,1.036-0.104,2.261,0.097C705.846,321.611,706.819,321.894,706.795,322.042z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M691.977,312.531c-0.134,0.068-0.717-0.818-1.303-1.978c-0.586-1.16-0.953-2.155-0.819-2.223
								c0.133-0.067,0.717,0.818,1.303,1.979C691.744,311.469,692.111,312.464,691.977,312.531z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M686.116,319.637c-0.017,0.149-0.987,0.159-2.165,0.022c-1.179-0.136-2.12-0.367-2.103-0.516
								c0.017-0.148,0.986-0.159,2.165-0.022C685.192,319.257,686.133,319.488,686.116,319.637z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M680.991,334.479c-0.069,0.133-1.053-0.239-2.197-0.831c-1.145-0.592-2.017-1.18-1.949-1.313
								c0.069-0.133,1.052,0.239,2.198,0.831C680.187,333.758,681.06,334.346,680.991,334.479z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M679.393,347.215c0.017,0.149-0.872,0.371-1.984,0.497c-1.113,0.126-2.028,0.108-2.045-0.041
								c-0.017-0.149,0.871-0.371,1.984-0.498C678.461,347.048,679.376,347.067,679.393,347.215z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M675.687,367.946c0.013,0.149-0.927,0.349-2.099,0.447c-1.172,0.098-2.132,0.057-2.144-0.092
								c-0.013-0.149,0.927-0.349,2.099-0.447C674.715,367.756,675.675,367.797,675.687,367.946z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M673.446,379.371c0.057,0.138-0.755,0.606-1.813,1.044c-1.059,0.439-1.964,0.682-2.021,0.544
								c-0.057-0.138,0.755-0.606,1.813-1.044C672.484,379.477,673.389,379.233,673.446,379.371z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M681.632,393.35c-0.136,0.062-0.713-0.916-1.289-2.184c-0.576-1.269-0.932-2.347-0.796-2.409
								c0.136-0.062,0.713,0.916,1.289,2.185C681.412,392.209,681.768,393.288,681.632,393.35z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M686.398,371.85c0.094,0.116-0.729,0.94-1.838,1.84c-1.11,0.9-2.085,1.536-2.179,1.42
								c-0.094-0.116,0.728-0.94,1.838-1.84C685.328,372.37,686.304,371.734,686.398,371.85z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M686.872,360.379c-0.119,0.091-0.751-0.536-1.413-1.4c-0.662-0.864-1.102-1.639-0.983-1.729
								c0.119-0.091,0.751,0.536,1.413,1.4C686.55,359.514,686.99,360.288,686.872,360.379z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M690.113,345.97c0.102,0.11-0.596,0.92-1.558,1.81c-0.962,0.89-1.825,1.522-1.926,1.412
								c-0.102-0.11,0.596-0.92,1.558-1.81C689.149,346.492,690.011,345.86,690.113,345.97z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M692.162,335.483c-0.114,0.096-0.91-0.66-1.777-1.689c-0.867-1.029-1.478-1.942-1.363-2.038
								c0.114-0.096,0.91,0.66,1.777,1.689C691.666,334.475,692.276,335.387,692.162,335.483z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M695.575,321.37c0.121,0.088-0.401,1.007-1.165,2.051c-0.765,1.045-1.482,1.82-1.603,1.732
								c-0.121-0.088,0.401-1.007,1.165-2.051C694.737,322.057,695.454,321.281,695.575,321.37z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M700.711,308.476c0.124,0.083-0.348,1.007-1.054,2.064c-0.707,1.057-1.38,1.846-1.505,1.763
								c-0.124-0.083,0.348-1.007,1.054-2.064C699.913,309.182,700.586,308.393,700.711,308.476z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M704.554,314.914c0.052,0.14-0.592,0.506-1.437,0.817c-0.846,0.311-1.573,0.449-1.625,0.309
								c-0.052-0.14,0.592-0.506,1.438-0.817C703.775,314.912,704.503,314.773,704.554,314.914z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M700.176,362.544c-0.043,0.137-1.394-0.18-2.885-0.993c-1.495-0.807-2.497-1.767-2.405-1.877
								c0.096-0.122,1.216,0.621,2.664,1.401C698.993,361.863,700.226,362.397,700.176,362.544z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M697.424,372.461c0.014,0.149-0.923,0.362-2.093,0.475c-1.171,0.113-2.131,0.085-2.145-0.064
								c-0.014-0.149,0.922-0.362,2.093-0.475C696.449,372.283,697.409,372.312,697.424,372.461z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M693.58,389.546c-0.098,0.104-1.177-0.794-2.108-2.234c-0.939-1.436-1.322-2.786-1.187-2.834
								c0.145-0.058,0.737,1.157,1.641,2.538C692.821,388.403,693.692,389.436,693.58,389.546z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M668.575,395.171c-0.086-0.122,0.591-0.748,1.513-1.397c0.922-0.649,1.739-1.076,1.825-0.954
								c0.086,0.122-0.591,0.748-1.513,1.397C669.479,394.866,668.661,395.293,668.575,395.171z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.jacketColor}} d="M665.52,349.587c-0.601-3.63-2.093-7.977-5.725-8.563c-2.155-0.348-4.325,0.917-5.62,2.674
					c-1.295,1.757-2.05,3.905-2.304,6.073c-1.675,14.325-1.597,31.001,1.159,46.584l13.319,1.953
					C668.6,381.778,668.246,366.047,665.52,349.587z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M659.795,341.024c0.051,0.053-0.633,0.518-1.405,1.719c-0.767,1.208-1.346,3.23-1.487,5.797
						c-0.113,2.569-0.27,5.661-0.336,9.063c-0.057,3.411,0.009,7.164,0.196,11.099c0.384,7.873,1.073,14.987,1.417,20.15
						c0.179,2.581,0.293,4.672,0.346,6.118c0.016,0.664,0.029,1.214,0.04,1.666c0.002,0.381-0.007,0.582-0.028,0.583
						c-0.021,0.001-0.054-0.197-0.096-0.576c-0.042-0.45-0.093-0.997-0.155-1.658c-0.136-1.536-0.319-3.596-0.541-6.098
						c-0.454-5.149-1.212-12.259-1.598-20.156c-0.188-3.947-0.236-7.715-0.148-11.14c0.034-1.713,0.129-3.338,0.213-4.859
						c0.097-1.51,0.138-2.93,0.256-4.226c0.196-2.596,0.862-4.687,1.72-5.894C659.052,341.41,659.795,341.024,659.795,341.024z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M666.733,345.063c0.08,0.15-0.695,0.72-1.732,1.273c-1.036,0.553-1.942,0.879-2.022,0.729
						c-0.08-0.15,0.695-0.72,1.732-1.272C665.748,345.239,666.653,344.913,666.733,345.063z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M668.998,355.955c-0.008,0.165-1.101,0.233-2.392-0.104c-1.292-0.332-2.215-0.921-2.142-1.069
						c0.074-0.158,1.067,0.158,2.297,0.473C667.988,355.576,669.011,355.781,668.998,355.955z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M668.766,367.257c-0.11,0.129-1.056-0.498-2.112-1.401c-1.056-0.903-1.823-1.74-1.712-1.869
						c0.11-0.129,1.056,0.498,2.112,1.401C668.11,366.291,668.876,367.128,668.766,367.257z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<rect x="622.891" y="391.717" style={{fill:theme.imageHighlight}} width="77.063" height="13.659"/>
				</g>
			</g>
			<g>
				<g>
					<polygon style={{fill:theme.imageHighlight}} points="628.646,402.666 638.017,443.483 683.947,443.483 694.199,402.666 					"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M699.912,405.393c0,0.15-17.383,0.271-38.82,0.271c-21.445,0-38.824-0.121-38.824-0.271
							c0-0.149,17.379-0.271,38.824-0.271C682.529,405.122,699.912,405.244,699.912,405.393z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M661.391,357.55c-0.156,0.068-0.647-0.709-1.097-1.737c-0.45-1.028-0.689-1.916-0.533-1.984
						c0.156-0.068,0.647,0.709,1.097,1.737C661.308,356.593,661.546,357.482,661.391,357.55z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M659.609,369.92c-0.152-0.077,0.204-1.092,0.796-2.267c0.592-1.176,1.195-2.067,1.346-1.99
						c0.152,0.077-0.204,1.091-0.796,2.267C660.363,369.106,659.76,369.997,659.609,369.92z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M661.937,383.523c-0.163,0.05-0.658-1.082-1.107-2.53c-0.449-1.448-0.681-2.662-0.519-2.713
						c0.162-0.05,0.658,1.082,1.107,2.53C661.867,382.258,662.1,383.473,661.937,383.523z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M648.44,389.151c-0.106-0.134,0.559-0.835,1.485-1.567c0.926-0.732,1.762-1.217,1.867-1.084
						c0.105,0.133-0.559,0.835-1.485,1.567C649.381,388.799,648.545,389.285,648.44,389.151z"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g id="Character_2">
	<g id="Device">
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path style={{fill:theme.jacketColor}} d="M590.91,436.59H165.193c-8.045,0-14.567-6.522-14.567-14.567V150.368
														c0-8.045,6.522-14.567,14.567-14.567H590.91c8.045,0,14.567,6.522,14.567,14.567v271.654
														C605.477,430.068,598.955,436.59,590.91,436.59z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path style={{fill:theme.jacketColor}} d="M607.849,443.483H150.647c-9.829,0-17.798-7.968-17.798-17.798v-8.521h492.797v8.521
														C625.646,435.514,617.678,443.483,607.849,443.483z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<polygon style={{fill:theme.dark}} points="333.213,414.343 339.328,424.07 425.376,424.07 431.055,414.343 													
														"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path style={{fill:'#EBEBEB'}} d="M580.767,400.95H174.948c-1.808,0-3.273-1.465-3.273-3.273V159.624
																	c0-1.808,1.465-3.273,3.273-3.273h405.819c1.807,0,3.273,1.465,3.273,3.272v238.055
																	C584.039,399.485,582.574,400.95,580.767,400.95z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<polygon style={{fill:'#FFFFFF'}} points="583.598,401.308 171.234,401.308 171.675,174.63 583.598,174.63 
																																	"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path style={{fill:theme.dark}} d="M205.495,165.797c0,1.819-1.475,3.293-3.293,3.293c-1.819,0-3.293-1.474-3.293-3.293
																	s1.474-3.293,3.293-3.293C204.021,162.504,205.495,163.978,205.495,165.797z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path style={{fill:theme.dark}} d="M185.535,165.797c0,1.819-1.474,3.293-3.294,3.293c-1.818,0-3.293-1.474-3.293-3.293
																	s1.475-3.293,3.293-3.293C184.061,162.504,185.535,163.978,185.535,165.797z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path style={{fill:theme.dark}} d="M195.116,165.797c0,1.819-1.474,3.293-3.293,3.293c-1.819,0-3.293-1.474-3.293-3.293
																	s1.474-3.293,3.293-3.293C193.642,162.504,195.116,163.978,195.116,165.797z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<path style={{fill:theme.dark}} d="M601.569,414.343c0,0.163-100.955,0.295-225.46,0.295
													c-124.549,0-225.482-0.132-225.482-0.295c0-0.162,100.933-0.294,225.482-0.294
													C500.614,414.049,601.569,414.181,601.569,414.343z"/>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M524.307,376.281h-6c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5.5v-5.5
						c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v6C524.807,376.057,524.584,376.281,524.307,376.281z"/>
				</g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M506.642,376.281h-11.665c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h11.665
						c0.276,0,0.5,0.224,0.5,0.5S506.919,376.281,506.642,376.281z M483.312,376.281h-11.665c-0.276,0-0.5-0.224-0.5-0.5
						s0.224-0.5,0.5-0.5h11.665c0.276,0,0.5,0.224,0.5,0.5S483.589,376.281,483.312,376.281z M459.982,376.281h-11.665
						c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h11.665c0.276,0,0.5,0.224,0.5,0.5S460.258,376.281,459.982,376.281z
						 M436.652,376.281h-11.665c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h11.665c0.276,0,0.5,0.224,0.5,0.5
						S436.928,376.281,436.652,376.281z"/>
				</g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M413.323,376.281h-6c-0.276,0-0.5-0.224-0.5-0.5v-6c0-0.276,0.224-0.5,0.5-0.5
						c0.276,0,0.5,0.224,0.5,0.5v5.5h5.5c0.276,0,0.5,0.224,0.5,0.5S413.599,376.281,413.323,376.281z"/>
				</g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M407.323,357.776c-0.276,0-0.5-0.224-0.5-0.5v-12.505c0-0.276,0.224-0.5,0.5-0.5
						c0.276,0,0.5,0.224,0.5,0.5v12.505C407.823,357.552,407.599,357.776,407.323,357.776z M407.323,332.765
						c-0.276,0-0.5-0.224-0.5-0.5V319.76c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v12.505
						C407.823,332.541,407.599,332.765,407.323,332.765z M407.323,307.755c-0.276,0-0.5-0.224-0.5-0.5v-12.506
						c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v12.506C407.823,307.532,407.599,307.755,407.323,307.755z"/>
				</g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M407.323,282.743c-0.276,0-0.5-0.224-0.5-0.5v-6c0-0.276,0.224-0.5,0.5-0.5h6
						c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5h-5.5v5.5C407.823,282.52,407.599,282.743,407.323,282.743z"/>
				</g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M506.643,276.743h-11.665c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h11.665
						c0.276,0,0.5,0.224,0.5,0.5C507.143,276.52,506.92,276.743,506.643,276.743z M483.313,276.743h-11.665
						c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h11.665c0.276,0,0.5,0.224,0.5,0.5
						C483.813,276.52,483.589,276.743,483.313,276.743z M459.983,276.743h-11.665c-0.276,0-0.5-0.224-0.5-0.5
						c0-0.276,0.224-0.5,0.5-0.5h11.665c0.276,0,0.5,0.224,0.5,0.5C460.483,276.52,460.259,276.743,459.983,276.743z
						 M436.653,276.743h-11.665c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h11.665c0.276,0,0.5,0.224,0.5,0.5
						C437.153,276.52,436.929,276.743,436.653,276.743z"/>
				</g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M524.307,282.743c-0.276,0-0.5-0.224-0.5-0.5v-5.5h-5.5c-0.276,0-0.5-0.224-0.5-0.5
						c0-0.276,0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5v6C524.807,282.52,524.584,282.743,524.307,282.743z"/>
				</g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M524.307,357.776c-0.276,0-0.5-0.224-0.5-0.5V344.77c0-0.276,0.224-0.5,0.5-0.5
						c0.276,0,0.5,0.224,0.5,0.5v12.506C524.807,357.552,524.584,357.776,524.307,357.776z M524.307,332.765
						c-0.276,0-0.5-0.224-0.5-0.5V319.76c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v12.505
						C524.807,332.541,524.584,332.765,524.307,332.765z M524.307,307.754c-0.276,0-0.5-0.224-0.5-0.5v-12.505
						c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v12.505C524.807,307.531,524.584,307.754,524.307,307.754z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M408.04,72.321c-2.54,5.04-2.391,11.314,0.384,16.185c1.348,2.366,3.351,4.847,2.622,7.485
								c-0.721,2.611-3.879,4.04-4.509,6.674c-0.741,3.101,2.341,5.735,3.014,8.84c0.632,2.914-1.251,6.173-4.108,7.108
								c4.365,1.065,9.036,0.899,13.335-0.474c0.414-1.806,0.829-3.613,1.243-5.419c-0.525,1.94,0.825,3.997,2.629,4.871
								c1.804,0.874,3.924,0.812,5.921,0.537c9.537-1.317,18.336-7.357,22.986-15.779"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M464.824,71.994c-1.507-1.59-3.37-2.843-4.699-4.583c-2.857-3.74-3.018-9.396-6.819-12.182
								c-2.706-1.983-6.41-1.827-9.75-1.338c-3.34,0.49-6.831,1.216-10.01,0.121c-2.331-0.804-4.251-2.521-6.579-3.333
								c-3.318-1.156-7.023-0.327-10.303,0.987c-3.367,1.35-6.606,3.264-8.876,6.09c-2.27,2.827-3.449,6.674-2.462,10.134
								c0.481,1.687,1.447,3.236,1.659,4.979c0.233,1.917-0.473,3.828-0.553,5.759c-0.17,4.082,2.831,8.05,6.81,9.006l36.028,18.162
								c5.554-0.465,15.988-8.018,18.009-13.203C469.3,87.409,468.63,76.012,464.824,71.994z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:'#AE7461'}} d="M412.113,83.696c-2.15-6.976-0.773-17.075,6.335-18.977l23.379-2.824
								c2.16-0.578,4.39,0.582,5.135,2.671l17.763,49.969c0.038-0.006,0.613,0.685,1.414-0.146c0.208-0.216,0.567-0.042,0.518,0.252
								c-0.782,4.643,0.133,15.475-13.869,16.396c-3.128,0.206-9.275,0.454-11.021-2.454l-3.32-7.968
								c-1.613-4.106-2.254-5.798-2.242-5.759c0,0-9.313,1.914-15.434-8.743C417.726,100.814,414.502,91.447,412.113,83.696z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<path style={{fill:theme.dark}} d="M419.432,92.754c0.264,0.779,1.147,1.194,1.971,0.927
																					c0.823-0.266,1.279-1.112,1.014-1.892c-0.264-0.779-1.147-1.195-1.97-0.928
																					C419.622,91.127,419.167,91.975,419.432,92.754z"/>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<path style={{fill:theme.dark}} d="M433.599,87.416c0.264,0.78,1.146,1.194,1.971,0.927
																					c0.823-0.266,1.279-1.113,1.014-1.892c-0.264-0.779-1.147-1.195-1.97-0.928
																					C433.79,85.79,433.335,86.637,433.599,87.416z"/>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<path style={{fill:theme.dark}} d="M415.438,88.16c0.275,0.184,1.204-1.265,2.965-1.807
																						c1.751-0.571,3.348,0.079,3.466-0.23c0.062-0.14-0.275-0.523-0.976-0.823
																						c-0.691-0.301-1.769-0.468-2.863-0.122c-1.094,0.348-1.879,1.104-2.27,1.748
																						C415.358,87.575,415.305,88.08,415.438,88.16z"/>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<path style={{fill:theme.dark}} d="M430.951,82.907c0.306,0.145,1.163-1.402,2.972-2.166
																						c1.797-0.791,3.535-0.365,3.633-0.684c0.052-0.146-0.332-0.477-1.099-0.679
																						c-0.754-0.205-1.904-0.226-3.028,0.258c-1.123,0.486-1.887,1.332-2.244,2.016
																						C430.82,82.343,430.806,82.846,430.951,82.907z"/>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<path style={{fill:theme.dark}} d="M430.265,95.898c-0.027-0.092-1.071,0.116-2.738,0.523
																						c-0.419,0.113-0.831,0.18-1.002-0.067c-0.205-0.253-0.2-0.748-0.181-1.289c0.012-1.113,0.024-2.28,0.037-3.503
																						c0.01-4.971-0.144-8.997-0.345-8.994c-0.201,0.003-0.373,4.035-0.383,9.007
																						c0.008,1.222,0.013,2.388,0.021,3.502c0.019,0.513-0.047,1.129,0.386,1.65c0.222,0.257,0.6,0.361,0.884,0.331
																						c0.288-0.021,0.516-0.104,0.72-0.168C429.298,96.363,430.293,95.99,430.265,95.898z"/>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<path style={{fill:'#6F4439'}} d="M436.205,114.856c0,0,7.881-2.456,13.779-9.925c0,0-0.829,9.443-12.678,12.767
																					L436.205,114.856z"/>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<path style={{fill:'#6F4439'}} d="M432.583,99.461c0.491-0.878,1.484-1.465,2.545-1.505
																					c0.736-0.028,1.517,0.213,2.038,0.736c0.522,0.523,0.73,1.343,0.407,1.961c-0.363,0.696-1.285,0.979-2.114,0.87
																					c-0.829-0.109-1.587-0.531-2.311-0.957c-0.2-0.118-0.405-0.241-0.545-0.422c-0.14-0.18-0.2-0.435-0.083-0.619"
																					/>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<path style={{fill:theme.dark}} d="M435.675,96.13c-0.262,0.084,0.379,1.785-0.629,3.555
																						c-1.004,1.772-2.903,2.281-2.826,2.523c0.016,0.116,0.532,0.175,1.31-0.1c0.766-0.264,1.779-0.928,2.396-2.01
																						c0.614-1.083,0.624-2.218,0.418-2.938C436.14,96.424,435.789,96.075,435.675,96.13z"/>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path style={{fill:theme.dark}} d="M428.034,77.987c0.379,0.301,1.704-0.768,3.515-1.606
																		c1.796-0.874,3.457-1.222,3.468-1.708c-0.002-0.228-0.463-0.448-1.244-0.485c-0.775-0.04-1.864,0.14-2.946,0.652
																		c-1.081,0.514-1.911,1.246-2.374,1.873C427.985,77.343,427.86,77.84,428.034,77.987z"/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<path style={{fill:theme.dark}} d="M413.533,83.363c0.459,0.27,1.773-0.493,3.469-0.971
																		c1.684-0.524,3.209-0.601,3.438-1.075c0.096-0.226-0.229-0.533-0.952-0.711c-0.713-0.179-1.826-0.183-2.988,0.161
																		c-1.161,0.345-2.086,0.951-2.577,1.488C413.423,82.794,413.326,83.226,413.533,83.363z"/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M447.037,61.356c-1.752-1.074-3.961-1.008-6.021-0.85c-8.885,0.679-17.697,2.416-26.188,5.162
								c-0.23,2.101,1.735,3.882,3.797,4.357c2.061,0.475,4.214,0.029,6.328-0.155c2.114-0.183,4.442-0.028,6.011,1.379
								c1.668,1.497,2.045,4.026,3.635,5.605c2.736,2.717,7.849,1.591,10.569,4.323c2.057,2.067,1.851,5.4,2.717,8.185
								c0.982,3.155,3.56,5.762,6.713,6.787c2.487,0.809,5.273,0.603,7.666-0.474c-3.882-9.673-7.764-19.345-11.646-29.018
								C449.808,64.641,448.89,62.493,447.037,61.356z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M477.348,76.09c-2.126-0.623-4.547-0.887-6.042-2.51c-2.177-2.365-1.464-6.692-4.102-8.536
								c-2.406-1.682-6.242,0.276-8.578-1.5c-1.825-1.387-1.49-3.536-2.615-4.979c-1.125-1.444-4.104-1.767-5.672-0.789
								l-4.193,24.765c1.737,6.82,2.092,10.72,5.166,13.736c1.686,1.654,3.573,3.151,4.86,5.131c1.287,1.979,1.87,4.636,0.715,6.714
								c8.033-3.064,13.401-10.523,18.282-17.589c1.722-2.493,3.444-4.985,5.166-7.478c0.537-0.778,1.092-1.6,1.193-2.536
								C481.765,78.318,479.473,76.714,477.348,76.09z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.jacketColor}} d="M474.478,88.557c0,0-0.228,0.233-0.754,0.549c-0.523,0.314-1.345,0.734-2.493,0.973
								c-1.137,0.236-2.62,0.305-4.213-0.205c-1.586-0.493-3.225-1.659-4.331-3.444c-0.548-0.891-0.96-1.931-1.133-3.068
								c-0.173-1.144-0.05-2.34,0.095-3.538c0.136-1.197,0.314-2.442,0.09-3.667c-0.218-1.203-0.814-2.474-1.949-3.063
								c-1.146-0.603-2.563-0.213-4.006-0.124c-0.725,0.047-1.534-0.031-2.224-0.432c-0.688-0.4-1.17-1.065-1.481-1.763
								c-0.645-1.415-0.646-2.988-1.003-4.421c-0.176-0.719-0.447-1.42-0.911-1.969c-0.46-0.551-1.119-0.912-1.852-1.03
								c-1.492-0.263-2.979,0.286-4.409,0.816c-1.429,0.556-3.019,0.93-4.542,0.724c-1.52-0.165-2.955-0.753-4.116-1.646
								c-1.163-0.909-1.983-2.088-2.743-3.207c-0.758-1.124-1.48-2.224-2.386-3.096c-0.903-0.867-1.988-1.477-3.105-1.862
								c-2.257-0.736-4.6-0.587-6.494,0.129c-1.909,0.715-3.349,1.987-4.333,3.263c-0.996,1.283-1.58,2.581-1.924,3.667
								c-0.674,2.197-0.536,3.505-0.611,3.496c-0.009,0-0.015-0.079-0.02-0.234c0.001-0.155-0.02-0.388,0.003-0.691
								c0.022-0.607,0.125-1.507,0.443-2.625c0.32-1.112,0.889-2.449,1.888-3.78c0.988-1.324,2.452-2.657,4.431-3.423
								c1.958-0.763,4.386-0.943,6.751-0.192c1.17,0.394,2.318,1.027,3.277,1.938c0.957,0.912,1.701,2.038,2.461,3.153
								c0.76,1.113,1.564,2.244,2.655,3.09c1.094,0.834,2.448,1.387,3.88,1.538c1.444,0.187,2.892-0.153,4.319-0.705
								c1.416-0.522,2.998-1.13,4.666-0.841c0.821,0.128,1.614,0.557,2.143,1.198c0.536,0.638,0.825,1.413,1.011,2.167
								c0.368,1.522,0.371,3.043,0.968,4.343c0.285,0.642,0.707,1.213,1.282,1.549c0.576,0.336,1.266,0.416,1.957,0.373
								c0.693-0.031,1.394-0.16,2.112-0.191c0.711-0.039,1.477,0.035,2.128,0.382c1.323,0.704,1.939,2.111,2.167,3.389
								c0.23,1.323,0.031,2.599-0.111,3.793c-0.154,1.196-0.279,2.351-0.124,3.434c0.156,1.083,0.538,2.081,1.055,2.941
								c1.042,1.724,2.6,2.866,4.119,3.365c1.528,0.516,2.971,0.481,4.087,0.278C473.46,89.442,474.446,88.474,474.478,88.557z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M478.582,70.42c0,0,0.244,0.115,0.551,0.508c0.305,0.384,0.628,1.103,0.564,2.063
								c-0.043,0.473-0.179,1.007-0.505,1.503c-0.324,0.492-0.822,0.936-1.45,1.221c-1.228,0.597-3.028,0.547-4.4-0.532
								c-0.683-0.521-1.258-1.238-1.655-2.082c-0.398-0.849-0.583-1.814-0.65-2.798c-0.168-1.954,0.186-4.103-0.705-6.059
								c-0.422-0.952-1.126-1.87-2.143-2.217c-1.017-0.367-2.164-0.032-3.228,0.46c-1.079,0.467-2.172,1.149-3.518,1.243
								c-0.663,0.039-1.358-0.131-1.922-0.5c-0.568-0.363-1.015-0.872-1.365-1.428c-0.707-1.131-0.958-2.399-1.328-3.55
								c-0.34-1.165-0.911-2.202-1.67-3.082c-1.521-1.753-3.686-2.804-5.816-2.988c-2.152-0.21-4.147,0.601-5.947,1.325
								c-1.806,0.742-3.56,1.438-5.266,1.554c-1.691,0.148-3.226-0.11-4.492-0.524c-1.275-0.406-2.28-0.985-3.042-1.522
								c-1.522-1.1-2.07-2.003-2.07-2.003c0.045-0.046,0.652,0.812,2.181,1.844c0.765,0.504,1.765,1.046,3.017,1.417
								c1.244,0.38,2.742,0.605,4.376,0.439c1.642-0.133,3.336-0.824,5.142-1.585c1.793-0.737,3.849-1.606,6.144-1.401
								c2.253,0.183,4.53,1.277,6.144,3.124c0.802,0.919,1.422,2.05,1.772,3.25c0.377,1.181,0.632,2.412,1.278,3.437
								c0.621,1.031,1.671,1.796,2.842,1.7c1.163-0.07,2.239-0.709,3.343-1.193c1.081-0.497,2.384-0.901,3.586-0.464
								c1.194,0.42,1.955,1.459,2.402,2.477c0.462,1.035,0.615,2.156,0.638,3.199c0.035,1.049-0.004,2.056,0.058,3.015
								c0.056,0.957,0.226,1.872,0.587,2.668c0.363,0.794,0.894,1.473,1.522,1.965c1.259,1.018,2.911,1.088,4.07,0.558
								c0.587-0.252,1.056-0.653,1.366-1.099c0.311-0.448,0.453-0.944,0.509-1.389c0.089-0.904-0.185-1.602-0.453-1.989
								C478.782,70.589,478.563,70.441,478.582,70.42z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M399.07,113.657c0.07-0.039-0.072,0.936,0.971,1.998c0.505,0.493,1.354,0.984,2.373,0.858
								c0.995-0.115,2.085-0.906,2.481-2.117c0.203-0.598,0.163-1.28-0.091-1.925c-0.253-0.645-0.689-1.248-1.212-1.805
								c-1.024-1.107-2.53-2.126-3.199-3.883c-0.681-1.69-0.632-3.772,0.267-5.558c0.44-0.893,1.063-1.712,1.822-2.404
								c0.756-0.705,1.675-1.217,2.575-1.699c0.902-0.485,1.801-0.94,2.548-1.546c0.746-0.597,1.307-1.385,1.48-2.272
								c0.176-0.886,0.004-1.805-0.316-2.631c-0.322-0.835-0.793-1.58-1.316-2.276c-1.04-1.386-2.207-2.659-2.811-4.168
								c-0.61-1.496-0.66-3.058-0.373-4.39c0.28-1.352,0.962-2.421,1.53-3.311c0.579-0.895,1.069-1.663,1.368-2.339
								c0.628-1.358,0.436-2.228,0.508-2.219c0.009-0.001,0.019,0.05,0.031,0.15c0.007,0.101,0.038,0.252,0.022,0.45
								c0,0.397-0.092,0.991-0.383,1.693c-0.279,0.707-0.756,1.504-1.31,2.41c-0.547,0.906-1.177,1.947-1.421,3.234
								c-0.253,1.274-0.181,2.758,0.41,4.162c0.583,1.415,1.723,2.647,2.798,4.053c0.538,0.704,1.046,1.492,1.391,2.377
								c0.345,0.879,0.547,1.884,0.352,2.896c-0.192,1.017-0.843,1.92-1.647,2.563c-0.807,0.654-1.73,1.117-2.627,1.601
								c-0.899,0.482-1.767,0.967-2.476,1.626c-0.715,0.65-1.302,1.419-1.716,2.253c-0.845,1.664-0.897,3.6-0.277,5.175
								c0.593,1.616,2.01,2.607,3.077,3.774c0.538,0.587,0.999,1.233,1.267,1.947c0.271,0.709,0.307,1.5,0.066,2.17
								c-0.471,1.348-1.678,2.18-2.787,2.285c-1.139,0.108-2.028-0.457-2.541-0.998c-0.531-0.557-0.734-1.144-0.811-1.529
								C399.025,113.866,399.07,113.657,399.07,113.657z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:'#F5F5F5'}} d="M518.359,308.607H404.855c-2.537,0-4.593-2.056-4.593-4.593v-89.321
						c0-2.537,2.056-4.593,4.593-4.593h113.504c2.537,0,4.593,2.057,4.593,4.593v89.321
						C522.952,306.551,520.896,308.607,518.359,308.607z"/>
				</g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M518.359,308.607c-0.002-0.024,0.386,0.01,1.103-0.167c0.699-0.177,1.782-0.647,2.618-1.829
							c0.407-0.585,0.72-1.352,0.789-2.231c0.033-0.882,0.002-1.867,0.006-2.922c-0.007-2.118-0.015-4.559-0.024-7.306
							c-0.02-11.005-0.048-26.909-0.083-46.559c-0.01-9.83-0.02-20.595-0.032-32.152c0.004-0.734,0.016-1.423-0.212-2.087
							c-0.217-0.659-0.589-1.269-1.083-1.765c-0.501-0.488-1.116-0.862-1.789-1.075c-0.676-0.217-1.387-0.196-2.145-0.193
							c-2.986,0.002-6.019,0.004-9.097,0.006c-6.156,0.004-12.492,0.007-18.99,0.011c-25.991,0.006-54.564,0.013-84.555,0.021
							c-1.788-0.032-3.486,1.175-4.074,2.842c-0.341,0.819-0.257,1.726-0.27,2.667l0,2.782c0,3.704-0.001,7.383-0.001,11.035
							c-0.001,7.305-0.003,14.502-0.004,21.574c-0.005,14.144-0.01,27.788-0.015,40.785c-0.002,3.249-0.004,6.458-0.005,9.624
							c0,0.791,0,1.58,0,2.365c0.012,0.745,0.197,1.489,0.569,2.132c0.726,1.297,2.143,2.163,3.61,2.203
							c6.154,0.008,12.128,0.015,17.904,0.022c11.557,0.011,22.323,0.022,32.153,0.032c19.651,0.035,35.554,0.063,46.557,0.083
							c5.489,0.021,9.753,0.038,12.658,0.049c1.439,0.011,2.539,0.02,3.292,0.026C517.979,308.59,518.359,308.607,518.359,308.607
							s-0.38,0.018-1.122,0.027c-0.753,0.006-1.853,0.015-3.292,0.026c-2.905,0.011-7.169,0.028-12.658,0.049
							c-11.003,0.02-26.906,0.048-46.557,0.083c-9.829,0.01-20.595,0.02-32.153,0.032c-5.782,0.001-11.762,0.002-17.922,0.003
							c-1.621-0.043-3.19-0.997-3.994-2.431c-0.412-0.71-0.619-1.535-0.632-2.359c-0.001-0.786-0.002-1.576-0.003-2.368
							c-0.002-3.166-0.003-6.375-0.005-9.624c-0.005-12.997-0.01-26.641-0.015-40.785c-0.001-7.072-0.003-14.27-0.004-21.574
							c0-3.652-0.001-7.331-0.001-11.035l0-2.782c0.013-0.901-0.08-1.913,0.301-2.847c0.658-1.867,2.563-3.218,4.563-3.183
							c29.991,0.007,58.564,0.014,84.555,0.02c6.497,0.004,12.833,0.007,18.99,0.011c3.078,0.002,6.111,0.004,9.097,0.006
							c0.729-0.002,1.53-0.026,2.276,0.216c0.741,0.235,1.418,0.648,1.967,1.186c0.543,0.546,0.951,1.217,1.189,1.943
							c0.25,0.726,0.235,1.516,0.229,2.22c-0.011,11.557-0.022,22.322-0.032,32.152c-0.035,19.65-0.063,35.555-0.083,46.559
							c-0.009,2.747-0.017,5.188-0.024,7.306c-0.012,1.06,0.011,2.03-0.031,2.932c-0.079,0.903-0.409,1.687-0.832,2.28
							c-0.87,1.199-1.98,1.658-2.691,1.82C518.745,308.654,518.359,308.607,518.359,308.607z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											
												<rect x="419.898" y="204.158" transform="matrix(0.0042 -1 1 0.0042 199.9276 720.8906)" style={{fill:theme.jacketColor}} width="84.039" height="111.81"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g style={{opacity:0.2}}>
						<g>
							<g>
								<g>
									<g>
										<g>
											<polygon points="405.838,301.849 445.108,249.775 469.162,282.558 485.643,265.155 517.647,302.316 											"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g style={{opacity:0.2}}>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path d="M479.594,243.519c-0.02,4.862-3.978,8.787-8.84,8.767c-4.862-0.021-8.787-3.978-8.767-8.84
														c0.02-4.863,3.978-8.787,8.84-8.767C475.69,234.699,479.614,238.657,479.594,243.519z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.imageHighlight}} d="M407.237,136.765c0,0-0.613-1.231,2.879-5.149c3.492-3.917,27.61-12.841,27.61-12.841
					s9.379,11.341,18.095,3.553c6.345-5.67,7.34-12.538,7.34-12.538s21.29-1.194,21.741,0.201l5.699,26.766l-10.001,0.058
					L407.237,136.765z"/>
			</g>
			<g>
				<path style={{fill:'#AE7461'}} d="M457.98,198.001l37.064-37.017l-4.725-20.247l19.938-5.865c0,0,5.905,28.273,5.115,33.251
					c-1.528,9.635-47.01,37.166-47.01,37.166L457.98,198.001z"/>
			</g>
			<g>
				<path style={{fill:theme.imageHighlight}} d="M483.873,109.59c0,0,8.938,0.528,16.198,8.004c7.681,7.911,16.871,28.651,16.871,28.651
					l-26.035,6.345L483.873,109.59z"/>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:'#AE7461'}} d="M453.248,202.046l-10.404,6.494c0,0-5.245,8.728-5.99,10.916
							c-0.745,2.188-1.475,7.667-1.475,7.667s2.266,2.07,3.191-1.122l1.065-2.446l-0.898,5.681c0,0,2.297,1.78,3.423-1.244
							s1.616-5.897,1.616-5.897l3.556-4.886l-3.585,11.684c0,0-0.681,2.352,1.414,2.145c0.828-0.082,2.681-4.195,2.681-4.195
							s1.518,1.974,2.785,0.196c1.286-1.805,6.194-8.748,6.194-8.748s6.638-8.204,6.661-8.421c0.005-0.044,4.881-4.58,4.881-4.58
							l-10.382-7.289l-4.494,3.819C453.423,201.91,453.342,201.988,453.248,202.046z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:'#6F4439'}} d="M438.77,229.031c0.015,0.003,0.069-0.231,0.153-0.657c0.087-0.464,0.201-1.072,0.338-1.805
								c0.137-0.776,0.297-1.682,0.473-2.681c0.087-0.506,0.178-1.036,0.272-1.585c0.103-0.539,0.291-1.08,0.514-1.622
								c0.925-2.164,2.141-3.945,3.022-5.211c0.445-0.633,0.823-1.133,1.082-1.481c0.263-0.344,0.402-0.54,0.391-0.549
								c-0.012-0.01-0.174,0.167-0.458,0.496c-0.28,0.332-0.678,0.819-1.141,1.443c-0.917,1.249-2.16,3.022-3.099,5.219
								c-0.227,0.551-0.418,1.107-0.522,1.665c-0.09,0.55-0.177,1.081-0.26,1.588c-0.159,1.003-0.304,1.913-0.427,2.691
								c-0.109,0.738-0.2,1.35-0.269,1.818C438.78,228.79,438.755,229.029,438.77,229.031z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:'#6F4439'}} d="M452.674,216.792c-0.013-0.007-0.098,0.114-0.241,0.342c-0.143,0.227-0.345,0.56-0.591,0.974
								c-0.492,0.829-1.16,1.984-1.894,3.277l-0.004,0.006l-0.003,0.007c-0.03,0.073-0.061,0.151-0.094,0.229
								c-0.582,1.429-1.098,2.728-1.461,3.672c-0.181,0.472-0.325,0.855-0.419,1.122c-0.095,0.267-0.14,0.417-0.126,0.423
								c0.014,0.006,0.086-0.134,0.205-0.391c0.119-0.257,0.284-0.631,0.484-1.096c0.4-0.929,0.939-2.218,1.521-3.647
								c0.032-0.079,0.064-0.157,0.094-0.23l-0.006,0.013c0.732-1.294,1.378-2.462,1.835-3.31c0.229-0.424,0.41-0.768,0.531-1.008
								C452.626,216.934,452.687,216.799,452.674,216.792z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:'#AE7461'}} d="M394.319,134.259c0.048,0.106,0.092,0.214,0.132,0.324c0.181,0.498,0.279,1.024,0.315,1.552
								c0.017,0.242-0.019,0.511,0.035,0.748c0.036,0.16,0.144,0.29,0.206,0.44c0.397,0.954,0.306,2.083,0.273,3.09
								c-0.036,1.136-0.022,2.341,0.571,3.31c0.593,0.969,1.974,1.544,2.908,0.897c-0.142,0.709,0.413,1.441,1.112,1.623
								c0.7,0.182,1.465-0.109,1.981-0.615c0.517-0.506,0.815-1.197,0.993-1.898c-0.05,0.848,0.786,1.576,1.636,1.588
								c0.849,0.012,1.629-0.553,2.077-1.274c0.449-0.721,0.617-1.58,0.716-2.424c0.098-0.844,0.293-2.425,0.514-3.245
								c0,0,1.066,0.171,1.552,0.218c0.659,0.063,0.142,0.014,0.142,0.014c0.358-0.35,0.627-0.963,1.02-1.771
								c0.68-1.399,1.198-2.286,1.718-3.555c0.389-0.951,0.642-1.901,0.054-2.52c-1.416-1.49-4.184,1.54-4.184,1.54
								c-0.391-1.055-3.609-1.227-4.234,0.179c-0.255-0.774-0.158-1.223-0.785-1.743c-0.627-0.52-1.494-0.789-2.28-0.574
								c-0.786,0.215-1.428,0.973-1.384,1.787c-0.662-1.067-2.033-1.64-3.257-1.361c-0.736,0.168-1.387,0.637-1.817,1.254
								c-0.216,0.31-0.37,0.654-0.465,1.02c-0.034,0.13-0.091,0.284-0.076,0.421c0.008,0.067,0.035,0.126,0.066,0.185
								C394.005,133.738,394.192,133.977,394.319,134.259z"/>
						</g>
					</g>
					<g>
						<g>
							<path style={{fill:'#AE7461'}} d="M390.922,132.374c-0.7-0.025-1.463,0.12-1.931,0.642c-0.554,0.619-0.666,1.848-0.603,2.677
								c0.971,0.183,1.473,0.22,2.445,0.403c0.204,0.039,0.376-0.013,0.545,0.108c0.448,0.318,0.375,1.389,0.555,1.908
								c0.313,0.9,1.466,1.34,2.359,1.009c0.893-0.331,1.469-1.281,1.514-2.233s-0.374-1.882-0.987-2.612
								C393.862,133.136,392.41,132.427,390.922,132.374z"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path style={{fill:'#6F4439'}} d="M399.232,131.72c0.182,0.009,0.181,2.915-0.002,6.49c-0.183,3.576-0.478,6.467-0.66,6.457
									c-0.182-0.009-0.181-2.915,0.002-6.491C398.755,134.601,399.05,131.71,399.232,131.72z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path style={{fill:'#6F4439'}} d="M403.885,132.356c0.174-0.008,0.411,2.638,0.126,5.891c-0.275,3.255-0.961,5.821-1.131,5.783
									c-0.186-0.037,0.203-2.629,0.475-5.84C403.635,134.982,403.695,132.361,403.885,132.356z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:'#6F4439'}} d="M408.246,132.356c0.185,0,0.263,2.2-0.079,4.866c-0.34,2.662-0.75,4.785-0.922,4.77
								c-0.19-0.018-0.071-2.2,0.267-4.848C407.852,134.499,408.067,132.355,408.246,132.356z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:'#6F4439'}} d="M392.322,132.92c-0.005-0.078,0.466-0.183,1.137,0.124c0.664,0.287,1.424,1.064,1.785,2.088
								c0.357,1.034,0.473,1.924,0.444,2.673c-0.08,0.805-0.664,1.038-0.677,0.934c-0.051-0.073,0.265-0.351,0.211-0.949
								c-0.079-0.58-0.278-1.536-0.594-2.426c-0.326-0.909-0.888-1.567-1.4-1.917C392.723,133.094,392.313,133.021,392.322,132.92z"
								/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
<g id="Code">
	<g>
		<g>
			<g>
				<path style={{fill:'#FFFFFF'}} d="M376.772,377.704h-141.12c-4.777,0-8.65-3.873-8.65-8.65v-70.772c0-4.777,3.873-8.65,8.65-8.65
					h141.12c4.777,0,8.65,3.873,8.65,8.65v70.772C385.422,373.831,381.55,377.704,376.772,377.704z"/>
			</g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M376.772,377.704c-0.002-0.025,0.421-0.004,1.225-0.124c0.798-0.115,2.007-0.408,3.349-1.256
						c1.327-0.832,2.792-2.309,3.536-4.511c0.39-1.096,0.493-2.339,0.45-3.682c-0.004-1.341-0.008-2.769-0.012-4.281
						c-0.02-12.107-0.048-29.609-0.083-51.233c-0.003-2.704-0.006-5.472-0.009-8.302c-0.001-1.415-0.003-2.846-0.004-4.291
						c-0.003-1.453,0.062-2.903-0.394-4.282c-0.862-2.751-3.259-5.062-6.209-5.69c-0.735-0.161-1.475-0.217-2.25-0.208
						c-0.774,0.001-1.552,0.001-2.333,0.002c-1.562,0.001-3.138,0.002-4.728,0.004c-12.718,0.008-26.307,0.016-40.606,0.024
						c-28.6,0.006-60.041,0.013-93.043,0.021c-2.005,0.004-3.985,0.74-5.49,2.045c-1.518,1.29-2.528,3.143-2.808,5.098
						c-0.148,0.967-0.092,1.99-0.103,3.007c0,1.015,0,2.029,0,3.041c0,2.024,0,4.041-0.001,6.05
						c-0.001,8.038-0.003,15.958-0.004,23.74c-0.002,7.782-0.004,15.426-0.006,22.913c-0.001,3.743-0.003,7.447-0.004,11.109
						c0.013,0.908-0.029,1.845,0.028,2.722c0.068,0.879,0.236,1.757,0.579,2.569c0.656,1.633,1.827,3.04,3.3,3.966
						c1.468,0.943,3.203,1.379,4.942,1.315c1.765,0.001,3.519,0.002,5.261,0.003c3.484,0.002,6.921,0.004,10.308,0.006
						c6.775,0.004,13.352,0.009,19.711,0.013c12.718,0.011,24.565,0.022,35.381,0.032c21.624,0.035,39.125,0.063,51.23,0.083
						c6.041,0.021,10.734,0.038,13.928,0.049c1.585,0.011,2.796,0.02,3.622,0.026C376.354,377.686,376.772,377.704,376.772,377.704
						s-0.418,0.018-1.234,0.027c-0.826,0.006-2.037,0.014-3.622,0.026c-3.194,0.011-7.887,0.028-13.928,0.049
						c-12.106,0.019-29.606,0.048-51.23,0.083c-10.816,0.01-22.663,0.02-35.381,0.032c-6.359,0.004-12.937,0.009-19.711,0.013
						c-3.387,0.002-6.825,0.004-10.308,0.006c-1.742,0.001-3.496,0.002-5.261,0.003c-1.785,0.069-3.658-0.39-5.199-1.385
						c-1.557-0.977-2.797-2.463-3.492-4.19c-0.363-0.859-0.544-1.788-0.616-2.717c-0.06-0.941-0.017-1.832-0.031-2.754
						c-0.001-3.662-0.003-7.366-0.004-11.109c-0.002-7.486-0.004-15.131-0.006-22.913c-0.001-7.782-0.003-15.702-0.004-23.74
						c0-2.009,0-4.026-0.001-6.05c0-1.012,0-2.026,0-3.041c0.012-1.01-0.049-2.033,0.109-3.084c0.297-2.077,1.371-4.045,2.982-5.415
						c1.596-1.386,3.704-2.169,5.83-2.172c33.002,0.007,64.443,0.014,93.043,0.021c14.299,0.008,27.887,0.016,40.606,0.024
						c1.59,0.001,3.166,0.002,4.728,0.004c0.781,0.001,1.559,0.001,2.333,0.002c0.773-0.009,1.578,0.051,2.341,0.222
						c3.096,0.666,5.603,3.092,6.501,5.977c0.477,1.451,0.404,2.977,0.405,4.403c-0.001,1.446-0.003,2.876-0.004,4.291
						c-0.003,2.83-0.006,5.599-0.009,8.302c-0.035,21.624-0.063,39.126-0.083,51.233c-0.004,1.512-0.008,2.94-0.012,4.281
						c0.036,1.334-0.078,2.627-0.483,3.732c-0.774,2.237-2.275,3.723-3.626,4.55c-1.368,0.845-2.592,1.121-3.398,1.22
						c-0.404,0.073-0.716,0.055-0.922,0.069C376.877,377.707,376.772,377.704,376.772,377.704z"/>
				</g>
			</g>
		</g>
		<g>
			<rect x="243.903" y="304.512" style={{fill:theme.imageHighlight}} width="23.532" height="4.438"/>
		</g>
		<g>
			<rect x="243.903" y="358.902" style={{fill:theme.imageHighlight}} width="23.532" height="4.438"/>
		</g>
		<g>
			<rect x="243.903" y="315.517" style={{fill:'#E0E0E0'}} width="40.514" height="4.438"/>
		</g>
		<g>
			<rect x="291.594" y="315.517" style={{fill:theme.imageHighlight}} width="17.906" height="4.438"/>
		</g>
		<g>
			<rect x="351.083" y="315.517" style={{fill:theme.dark}} width="11.773" height="4.438"/>
		</g>
		<g>
			<rect x="315.795" y="315.517" style={{fill:theme.jacketColor}} width="31.18" height="4.438"/>
		</g>
		<g>
			
				<rect x="322.343" y="326.012" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 685.1992 656.4629)" style={{fill:'#E0E0E0'}} width="40.514" height="4.438"/>
		</g>
		<g>
			
				<rect x="297.259" y="326.012" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 612.4245 656.4629)" style={{fill:theme.jacketColor}} width="17.906" height="4.438"/>
		</g>
		<g>
			
				<rect x="243.903" y="326.012" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 499.5803 656.4629)" style={{fill:theme.dark}} width="11.773" height="4.438"/>
		</g>
		<g>
			
				<rect x="259.785" y="326.012" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 550.7507 656.4629)" style={{fill:theme.imageHighlight}} width="31.18" height="4.438"/>
		</g>
		<g>
			<rect x="243.903" y="336.791" style={{fill:'#E0E0E0'}} width="40.514" height="4.438"/>
		</g>
		<g>
			<rect x="291.594" y="336.791" style={{fill:theme.dark}} width="17.906" height="4.438"/>
		</g>
		<g>
			<rect x="351.083" y="336.791" style={{fill:theme.dark}} width="11.773" height="4.438"/>
		</g>
		<g>
			<rect x="282.391" y="347.774" style={{fill:theme.imageHighlight}} width="11.774" height="4.438"/>
		</g>
		<g>
			<rect x="315.795" y="336.791" style={{fill:theme.jacketColor}} width="31.18" height="4.438"/>
		</g>
		<g>
			<rect x="243.053" y="347.774" style={{fill:theme.jacketColor}} width="31.18" height="4.438"/>
		</g>
	</g>
</g>
<g id="Text">
	<g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path style={{fill:'#FAFAFA'}} d="M526.03,384.029h-49.323c-4.474,0-8.102-3.627-8.102-8.102v-47.43
									c0-4.474,3.627-8.102,8.102-8.102h49.323c4.474,0,8.102,3.627,8.102,8.102v47.43
									C534.132,380.402,530.505,384.029,526.03,384.029z"/>
							</g>
							<g>
								<g>
									<path style={{fill:theme.dark}} d="M526.03,384.029c-0.008-0.052,0.875,0.043,2.429-0.427c0.765-0.256,1.686-0.686,2.604-1.429
										c0.912-0.74,1.801-1.826,2.379-3.241c0.289-0.707,0.466-1.501,0.544-2.342c0.061-0.839,0.012-1.752,0.022-2.689
										c-0.007-1.882-0.014-3.926-0.021-6.121c-0.011-4.395-0.024-9.396-0.039-14.922c-0.01-5.53-0.02-11.585-0.032-18.085
										c-0.002-1.625-0.004-3.278-0.007-4.958c-0.006-0.845,0.032-1.688-0.102-2.5c-0.121-0.819-0.368-1.629-0.76-2.374
										c-0.76-1.499-2.025-2.775-3.59-3.517c-0.778-0.371-1.619-0.643-2.492-0.731c-0.86-0.089-1.781-0.051-2.693-0.058
										c-14.621,0.006-30.691,0.013-47.56,0.021c-2.047-0.006-4.06,0.825-5.495,2.246c-1.449,1.408-2.301,3.381-2.342,5.371
										c-0.005,4.106-0.011,8.152-0.016,12.127c-0.005,7.956-0.01,15.629-0.015,22.94c-0.004,3.655-0.007,7.219-0.011,10.683
										c0.012,0.858-0.031,1.742,0.03,2.559c0.075,0.825,0.265,1.634,0.575,2.39c0.642,1.502,1.726,2.785,3.07,3.622
										c1.336,0.858,2.9,1.251,4.437,1.226c1.568,0.003,3.107,0.005,4.616,0.008c3.017,0.006,5.913,0.012,8.678,0.017
										c11.043,0.035,19.986,0.063,26.187,0.083c3.077,0.021,5.473,0.038,7.12,0.049c0.801,0.011,1.416,0.02,1.852,0.026
										C525.816,384.011,526.03,384.029,526.03,384.029s-0.214,0.018-0.631,0.027c-0.436,0.006-1.051,0.015-1.852,0.026
										c-1.646,0.011-4.042,0.028-7.12,0.049c-6.201,0.02-15.144,0.048-26.187,0.083c-2.765,0.005-5.661,0.011-8.678,0.017
										c-1.509,0.003-3.048,0.005-4.616,0.008c-1.592,0.032-3.262-0.377-4.668-1.278c-1.42-0.879-2.568-2.229-3.251-3.815
										c-0.33-0.799-0.533-1.653-0.614-2.525c-0.067-0.886-0.024-1.723-0.038-2.596c-0.004-3.463-0.007-7.028-0.011-10.683
										c-0.005-7.311-0.01-14.985-0.015-22.94c0.003-3.98,0.005-8.031,0.008-12.142c0.043-2.12,0.952-4.227,2.495-5.727
										c1.529-1.514,3.678-2.403,5.859-2.395c16.869,0.007,32.939,0.014,47.56,0.021c0.914,0.01,1.814-0.03,2.749,0.067
										c0.925,0.095,1.817,0.385,2.641,0.78c1.656,0.791,2.992,2.144,3.793,3.732c0.86,1.574,0.958,3.439,0.899,5.081
										c-0.002,1.68-0.005,3.333-0.007,4.958c-0.011,6.5-0.022,12.555-0.032,18.085c-0.014,5.526-0.027,10.527-0.039,14.922
										c-0.008,2.195-0.015,4.239-0.021,6.121c-0.018,0.944,0.024,1.833-0.046,2.707c-0.087,0.864-0.277,1.678-0.581,2.4
										c-0.607,1.445-1.529,2.544-2.469,3.285c-0.946,0.744-1.889,1.166-2.669,1.409c-0.789,0.221-1.411,0.304-1.828,0.322
										C526.243,384.036,526.03,384.029,526.03,384.029z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.dark}} d="M483.098,335.39h35.729l0.058,11.967h-1.709c-0.56-4.281-2.164-7.178-4.81-8.689
					c-1.488-0.833-3.709-1.288-6.665-1.366v31.262c0,2.185,0.382,3.635,1.145,4.35c0.763,0.715,2.352,1.073,4.767,1.073v1.507
					h-21.153v-1.507c2.318,0,3.858-0.362,4.622-1.088c0.763-0.725,1.145-2.17,1.145-4.335v-31.262
					c-2.898,0.078-5.119,0.533-6.664,1.366c-2.84,1.55-4.444,4.446-4.811,8.689h-1.71L483.098,335.39z"/>
			</g>
		</g>
	</g>
</g>
<g id="Tools">
	<g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:'#FFFFFF'}} d="M545.246,224.163v119.655c0,3.553,2.88,6.433,6.433,6.433h2.258h18.034h56.846
								c3.553,0,6.433-2.88,6.433-6.433V224.163c0-3.553-2.88-6.433-6.433-6.433h-77.138
								C548.126,217.73,545.246,220.61,545.246,224.163z"/>
						</g>
						<g>
							<g>
								<path style={{fill:theme.dark}} d="M545.246,224.163c0,0-0.004-0.095,0.001-0.283c-0.001-0.188,0.013-0.469,0.076-0.836
									c0.117-0.726,0.425-1.837,1.337-2.986c0.9-1.115,2.504-2.275,4.67-2.387c2.121-0.043,4.554-0.026,7.301-0.042
									c10.992-0.02,26.878-0.048,46.505-0.083c4.909-0.005,10.051-0.011,15.41-0.017c2.679-0.003,5.412-0.005,8.197-0.008
									c2.886-0.083,5.773,2,6.495,4.937c0.202,0.712,0.247,1.492,0.23,2.209c0.001,0.728,0.001,1.458,0.002,2.192
									c0.001,1.467,0.002,2.947,0.003,4.438c0.002,2.983,0.004,6.012,0.006,9.087c0.003,6.149,0.007,12.478,0.011,18.967
									c0.006,25.96,0.013,54.5,0.021,84.456c0.012,1.954-0.895,3.873-2.376,5.121c-0.734,0.633-1.617,1.083-2.547,1.338
									c-0.934,0.272-1.92,0.247-2.826,0.244c-3.699,0-7.374-0.001-11.022-0.001c-7.296-0.001-14.485-0.003-21.549-0.004
									c-14.128-0.005-27.755-0.01-40.737-0.015c-0.811,0-1.62-0.001-2.426-0.001c-0.809,0.009-1.667-0.075-2.45-0.345
									c-1.581-0.518-2.934-1.65-3.723-3.088c-0.398-0.717-0.658-1.505-0.768-2.311c-0.108-0.819-0.06-1.598-0.07-2.376
									c0.001-1.56,0.002-3.108,0.003-4.646c0.004-6.15,0.009-12.12,0.013-17.892c0.011-11.544,0.022-22.297,0.032-32.115
									c0.035-19.627,0.063-35.512,0.083-46.502c0.021-5.482,0.038-9.742,0.049-12.643c0.011-1.437,0.02-2.536,0.026-3.288
									C545.228,224.542,545.246,224.163,545.246,224.163s0.018,0.379,0.027,1.12c0.006,0.752,0.015,1.851,0.026,3.288
									c0.011,2.901,0.028,7.161,0.049,12.643c0.02,10.991,0.048,26.875,0.083,46.502c0.01,9.818,0.02,20.571,0.032,32.115
									c0.004,5.772,0.008,11.743,0.013,17.892c0.001,1.538,0.002,3.086,0.003,4.646c0.01,0.779-0.032,1.572,0.069,2.312
									c0.103,0.749,0.346,1.481,0.716,2.147c0.734,1.337,1.995,2.387,3.463,2.866c0.738,0.25,1.499,0.326,2.301,0.318
									c0.806,0,1.615-0.001,2.426-0.001c12.982-0.005,26.609-0.01,40.737-0.015c7.064-0.001,14.253-0.003,21.549-0.004
									c3.648,0,7.323-0.001,11.022-0.001c0.937,0.003,1.83,0.024,2.689-0.226c0.856-0.236,1.671-0.65,2.348-1.234
									c1.367-1.154,2.202-2.919,2.192-4.724c0.007-29.956,0.014-58.495,0.02-84.456c0.004-6.49,0.008-12.818,0.011-18.967
									c0.002-3.074,0.004-6.104,0.006-9.087c0.001-1.491,0.002-2.971,0.003-4.438c0.001-0.734,0.001-1.464,0.002-2.192
									c0.016-0.736-0.025-1.418-0.213-2.099c-0.671-2.746-3.364-4.703-6.077-4.63c-2.785-0.003-5.518-0.005-8.197-0.008
									c-5.358-0.006-10.501-0.011-15.41-0.017c-19.627-0.035-35.513-0.063-46.505-0.083c-2.743,0-5.188-0.033-7.293-0.007
									c-2.115,0.093-3.699,1.212-4.597,2.299c-0.911,1.12-1.233,2.212-1.366,2.929
									C545.237,223.779,545.276,224.165,545.246,224.163z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M570.425,328.482h-0.284c-1.354,0-2.452-1.098-2.452-2.452v-82.848
						c0-1.354,1.098-2.452,2.452-2.452h0.284c1.354,0,2.452,1.098,2.452,2.452v82.848
						C572.877,327.384,571.779,328.482,570.425,328.482z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M570.425,328.482c-0.034-0.062,0.73,0.102,1.634-0.708c1.072-0.811,0.638-2.775,0.702-5.031
							c-0.023-4.629-0.055-11.272-0.095-19.467c-0.018-8.229-0.04-18.012-0.064-28.872c-0.006-5.434-0.011-11.137-0.017-17.049
							c-0.002-2.957-0.003-5.965-0.005-9.018c0-1.527-0.001-3.064-0.001-4.612c-0.004-0.402,0.021-0.762-0.071-1.081
							c-0.083-0.33-0.247-0.641-0.478-0.894c-0.467-0.525-1.13-0.771-1.884-0.721c-0.692-0.002-1.367,0.346-1.76,0.91
							c-0.203,0.277-0.324,0.606-0.373,0.942c-0.042,0.317-0.017,0.744-0.024,1.121c0,1.548-0.001,3.087-0.001,4.614
							c-0.002,3.054-0.003,6.063-0.005,9.02c-0.006,5.913-0.012,11.617-0.017,17.052c-0.024,10.862-0.046,20.645-0.064,28.874
							c-0.04,8.195-0.072,14.838-0.095,19.466c-0.009,1.144-0.018,2.163-0.025,3.052c0.008,0.866,0.477,1.523,0.94,1.871
							c0.473,0.361,0.938,0.46,1.238,0.491C570.263,328.471,570.426,328.468,570.425,328.482c0,0-0.157,0.03-0.471,0.021
							c-0.309-0.012-0.797-0.093-1.306-0.455c-0.498-0.349-1.018-1.033-1.046-1.964c-0.009-0.89-0.019-1.911-0.03-3.056
							c-0.023-4.628-0.055-11.271-0.095-19.466c-0.018-8.229-0.04-18.012-0.064-28.874c-0.005-5.435-0.011-11.139-0.018-17.052
							c-0.001-2.957-0.003-5.966-0.005-9.02c0-1.527-0.001-3.065-0.001-4.614c0.009-0.397-0.024-0.749,0.029-1.203
							c0.062-0.43,0.22-0.852,0.478-1.205c0.5-0.72,1.367-1.167,2.248-1.163c0.807-0.066,1.77,0.252,2.33,0.921
							c0.294,0.324,0.504,0.72,0.611,1.143c0.118,0.435,0.085,0.862,0.09,1.23c0,1.548-0.001,3.086-0.001,4.612
							c-0.002,3.053-0.003,6.061-0.004,9.018c-0.006,5.912-0.012,11.615-0.018,17.049c-0.024,10.86-0.046,20.643-0.064,28.872
							c-0.04,8.195-0.073,14.838-0.095,19.467c-0.01,1.145-0.02,2.164-0.028,3.055c0.05,0.912-0.361,1.665-0.825,2.065
							C571.162,328.679,570.388,328.457,570.425,328.482z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M590.39,328.482h-0.284c-1.354,0-2.452-1.098-2.452-2.452v-82.848
						c0-1.354,1.098-2.452,2.452-2.452h0.284c1.354,0,2.452,1.098,2.452,2.452v82.848
						C592.842,327.384,591.744,328.482,590.39,328.482z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M590.39,328.482c-0.034-0.062,0.73,0.102,1.634-0.708c1.072-0.811,0.638-2.775,0.702-5.031
							c-0.023-4.629-0.055-11.272-0.095-19.467c-0.018-8.229-0.04-18.012-0.064-28.872c-0.005-5.434-0.011-11.137-0.018-17.049
							c-0.001-2.957-0.003-5.965-0.004-9.018c0-1.527-0.001-3.064-0.001-4.612c-0.004-0.402,0.021-0.762-0.071-1.081
							c-0.083-0.33-0.247-0.641-0.478-0.894c-0.467-0.525-1.13-0.771-1.884-0.721c-0.692-0.002-1.367,0.346-1.76,0.91
							c-0.203,0.277-0.324,0.606-0.374,0.942c-0.042,0.317-0.017,0.744-0.024,1.121c0,1.548-0.001,3.087-0.001,4.614
							c-0.002,3.054-0.003,6.063-0.005,9.02c-0.006,5.913-0.012,11.617-0.017,17.052c-0.024,10.862-0.046,20.645-0.064,28.874
							c-0.04,8.195-0.073,14.838-0.095,19.466c-0.009,1.144-0.018,2.163-0.025,3.052c0.008,0.866,0.477,1.523,0.94,1.871
							c0.473,0.361,0.938,0.46,1.238,0.491C590.229,328.471,590.391,328.468,590.39,328.482c0,0-0.157,0.03-0.471,0.021
							c-0.309-0.012-0.797-0.093-1.305-0.455c-0.498-0.349-1.018-1.033-1.046-1.964c-0.009-0.89-0.019-1.911-0.03-3.056
							c-0.022-4.628-0.055-11.271-0.095-19.466c-0.018-8.229-0.04-18.012-0.064-28.874c-0.006-5.435-0.012-11.139-0.018-17.052
							c-0.002-2.957-0.003-5.966-0.005-9.02c0-1.527-0.001-3.065-0.001-4.614c0.009-0.397-0.024-0.749,0.029-1.203
							c0.062-0.43,0.22-0.852,0.478-1.205c0.5-0.72,1.367-1.167,2.248-1.163c0.807-0.066,1.77,0.252,2.33,0.921
							c0.294,0.324,0.504,0.72,0.611,1.143c0.118,0.435,0.084,0.862,0.09,1.23c0,1.548-0.001,3.086-0.001,4.612
							c-0.002,3.053-0.003,6.061-0.005,9.018c-0.006,5.912-0.012,11.615-0.017,17.049c-0.024,10.86-0.046,20.643-0.064,28.872
							c-0.04,8.195-0.073,14.838-0.095,19.467c-0.01,1.145-0.019,2.164-0.027,3.055c0.05,0.912-0.361,1.665-0.825,2.065
							C591.127,328.679,590.353,328.457,590.39,328.482z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:'#E0E0E0'}} d="M610.355,328.482h-0.284c-1.354,0-2.452-1.098-2.452-2.452v-82.848
						c0-1.354,1.098-2.452,2.452-2.452h0.284c1.354,0,2.452,1.098,2.452,2.452v82.848
						C612.807,327.384,611.709,328.482,610.355,328.482z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M610.355,328.482c-0.034-0.062,0.73,0.102,1.634-0.708c1.072-0.811,0.638-2.775,0.702-5.031
							c-0.023-4.629-0.055-11.272-0.095-19.467c-0.018-8.229-0.04-18.012-0.064-28.872c-0.005-5.434-0.011-11.137-0.017-17.049
							c-0.001-2.957-0.003-5.965-0.005-9.018c0-1.527-0.001-3.064-0.001-4.612c-0.004-0.402,0.021-0.762-0.071-1.081
							c-0.084-0.33-0.247-0.641-0.478-0.894c-0.467-0.525-1.13-0.771-1.884-0.721c-0.692-0.002-1.367,0.346-1.76,0.91
							c-0.203,0.277-0.324,0.606-0.374,0.942c-0.042,0.317-0.017,0.744-0.024,1.121c0,1.548-0.001,3.087-0.001,4.614
							c-0.002,3.054-0.003,6.063-0.005,9.02c-0.006,5.913-0.012,11.617-0.017,17.052c-0.024,10.862-0.046,20.645-0.064,28.874
							c-0.04,8.195-0.072,14.838-0.095,19.466c-0.009,1.144-0.018,2.163-0.025,3.052c0.008,0.866,0.477,1.523,0.94,1.871
							c0.473,0.361,0.938,0.46,1.238,0.491C610.194,328.471,610.356,328.468,610.355,328.482c0,0-0.157,0.03-0.471,0.021
							c-0.309-0.012-0.797-0.093-1.306-0.455c-0.498-0.349-1.018-1.033-1.046-1.964c-0.009-0.89-0.019-1.911-0.03-3.056
							c-0.023-4.628-0.055-11.271-0.095-19.466c-0.018-8.229-0.04-18.012-0.064-28.874c-0.005-5.435-0.011-11.139-0.017-17.052
							c-0.001-2.957-0.003-5.966-0.005-9.02c0-1.527-0.001-3.065-0.001-4.614c0.009-0.397-0.023-0.749,0.029-1.203
							c0.062-0.43,0.22-0.852,0.478-1.205c0.5-0.72,1.367-1.167,2.248-1.163c0.807-0.066,1.77,0.252,2.33,0.921
							c0.294,0.324,0.504,0.72,0.611,1.143c0.118,0.435,0.085,0.862,0.09,1.23c0,1.548-0.001,3.086-0.001,4.612
							c-0.001,3.053-0.003,6.061-0.004,9.018c-0.006,5.912-0.012,11.615-0.017,17.049c-0.024,10.86-0.046,20.643-0.064,28.872
							c-0.04,8.195-0.073,14.838-0.095,19.467c-0.01,1.145-0.019,2.164-0.027,3.055c0.05,0.912-0.362,1.665-0.825,2.065
							C611.092,328.679,610.318,328.457,610.355,328.482z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.imageHighlight}} d="M569.951,281.857H569c-2.787,0-5.047-2.26-5.047-5.047l0,0c0-2.787,2.259-5.047,5.047-5.047
					h0.951c2.787,0,5.047,2.259,5.047,5.047l0,0C574.997,279.598,572.738,281.857,569.951,281.857z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.imageHighlight}} d="M591.22,300.884h-0.951c-2.787,0-5.046-2.259-5.046-5.046v0c0-2.787,2.259-5.046,5.046-5.046
					h0.951c2.787,0,5.047,2.259,5.047,5.047l0,0C596.266,298.624,594.007,300.884,591.22,300.884z"/>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:theme.imageHighlight}} d="M610.461,265.024h-0.951c-2.787,0-5.046-2.259-5.046-5.046v0c0-2.787,2.259-5.046,5.046-5.046
					h0.951c2.787,0,5.047,2.259,5.047,5.047l0,0C615.507,262.764,613.248,265.024,610.461,265.024z"/>
			</g>
		</g>
	</g>
</g>
<g id="Colors">
	<g>
		<g>
			<g>
				<path style={{fill:'#FFFFFF'}} d="M648.362,203.394H541.432c-4.602,0-8.332-3.73-8.332-8.332V88.133
					c0-4.602,3.73-8.332,8.332-8.332h106.929c4.602,0,8.332,3.73,8.332,8.332v106.929
					C656.693,199.664,652.963,203.394,648.362,203.394z"/>
			</g>
			<g>
				<g>
					<path style={{fill:theme.dark}} d="M648.362,203.394c-0.002-0.026,0.423,0,1.23-0.128c0.801-0.123,2.009-0.432,3.344-1.305
						c1.296-0.883,2.74-2.402,3.364-4.657c0.337-1.117,0.318-2.376,0.304-3.723c-0.004-1.346-0.008-2.78-0.012-4.298
						c-0.02-12.156-0.048-29.729-0.083-51.44c-0.01-10.86-0.02-22.754-0.032-35.522c-0.002-3.192-0.004-6.439-0.007-9.738
						c-0.013-1.643,0.025-3.327-0.03-4.972c-0.09-1.628-0.718-3.227-1.745-4.521c-1.031-1.294-2.471-2.272-4.086-2.732
						c-1.605-0.488-3.366-0.272-5.115-0.32c-3.498,0.002-7.042,0.003-10.632,0.005c-28.715,0.006-60.284,0.013-93.419,0.021
						c-2.012-0.001-3.998,0.774-5.463,2.131c-1.476,1.345-2.412,3.246-2.584,5.222c-0.073,4.044-0.021,8.143-0.037,12.168
						c-0.001,8.07-0.003,16.022-0.004,23.836c-0.005,15.627-0.01,30.701-0.015,45.06c-0.004,7.18-0.007,14.181-0.011,20.983
						c-0.001,1.701-0.002,3.389-0.003,5.064c-0.059,1.656,0.297,3.28,1.134,4.662c0.399,0.697,0.918,1.319,1.507,1.854
						c0.581,0.544,1.247,0.994,1.963,1.328c1.431,0.736,3.008,0.851,4.609,0.807c12.769,0.012,24.664,0.022,35.524,0.032
						c21.712,0.035,39.283,0.063,51.438,0.083c6.066,0.021,10.778,0.038,13.985,0.049c1.592,0.011,2.807,0.02,3.637,0.026
						C647.942,203.376,648.362,203.394,648.362,203.394s-0.42,0.018-1.239,0.027c-0.83,0.006-2.045,0.014-3.637,0.026
						c-3.207,0.011-7.919,0.028-13.985,0.049c-12.155,0.02-29.726,0.048-51.438,0.083c-10.86,0.01-22.755,0.02-35.524,0.032
						c-1.568,0.049-3.315-0.07-4.799-0.843c-0.755-0.352-1.457-0.825-2.071-1.398c-0.621-0.563-1.169-1.219-1.591-1.953
						c-0.885-1.444-1.265-3.202-1.203-4.889c-0.001-1.675-0.002-3.364-0.003-5.064c-0.004-6.802-0.007-13.804-0.011-20.983
						c-0.005-14.36-0.01-29.433-0.015-45.06c-0.001-7.814-0.003-15.766-0.004-23.836c0.017-4.045-0.039-8.078,0.037-12.214
						c0.183-2.104,1.18-4.129,2.75-5.56c1.558-1.444,3.676-2.271,5.815-2.269c33.135,0.007,64.704,0.014,93.419,0.021
						c3.589,0.002,7.134,0.004,10.632,0.005c0.874,0,1.746,0.001,2.614,0.001c0.876,0.013,1.784,0.072,2.629,0.342
						c1.708,0.488,3.228,1.524,4.316,2.892c1.084,1.367,1.745,3.059,1.838,4.778c0.054,1.703,0.013,3.346,0.025,5.001
						c-0.002,3.299-0.005,6.546-0.007,9.738c-0.011,12.768-0.022,24.663-0.032,35.522c-0.035,21.712-0.063,39.284-0.083,51.44
						c-0.004,1.518-0.008,2.952-0.012,4.298c0.005,1.337,0.017,2.63-0.334,3.765c-0.652,2.293-2.134,3.823-3.455,4.702
						c-1.362,0.87-2.586,1.164-3.394,1.271c-0.406,0.072-0.718,0.062-0.925,0.073C648.466,203.398,648.362,203.394,648.362,203.394z
						"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path style={{fill:'#E0E0E0'}} d="M600.684,140.424h-12.946c-6.291,0-11.391-5.1-11.391-11.391v-27.91h35.728v27.91
									C612.075,135.324,606.975,140.424,600.684,140.424z"/>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path style={{fill:theme.dark}} d="M600.684,140.424c-0.009-0.083,1.213,0.018,3.307-0.61c1.029-0.35,2.266-0.907,3.499-1.871
										c1.231-0.952,2.458-2.322,3.287-4.135c0.912-1.805,1.139-3.995,1.041-6.424c-0.012-2.426-0.024-5.034-0.037-7.806
										c-0.016-5.558-0.035-11.766-0.054-18.455l0.349,0.349c-7.514,0.007-15.743,0.015-24.333,0.023
										c-3.903-0.002-7.732-0.003-11.394-0.005l0.367-0.367c-0.016,8.334-0.03,16.172-0.044,23.25
										c0.006,1.761-0.036,3.501,0.008,5.141c0.062,1.621,0.48,3.172,1.16,4.528c1.359,2.734,3.732,4.615,6.143,5.476
										c2.422,0.936,4.843,0.637,6.911,0.715c2.057,0.023,3.832,0.042,5.304,0.059c1.405,0.027,2.515,0.048,3.332,0.064
										c0.345,0.013,0.628,0.024,0.863,0.033C600.585,140.401,600.684,140.412,600.684,140.424s-0.099,0.023-0.293,0.034
										c-0.234,0.009-0.518,0.02-0.863,0.033c-0.817,0.016-1.927,0.037-3.332,0.064c-1.472,0.016-3.247,0.036-5.304,0.059
										c-1.041,0.006-2.152,0.012-3.331,0.018c-1.203,0.001-2.476-0.221-3.743-0.657c-2.528-0.868-5.036-2.809-6.492-5.672
										c-0.728-1.42-1.182-3.052-1.26-4.762c-0.057-1.692-0.026-3.394-0.042-5.167c-0.013-7.079-0.028-14.916-0.044-23.25
										l-0.001-0.367l0.368,0c3.663-0.002,7.491-0.003,11.394-0.005c8.591,0.008,16.819,0.016,24.333,0.023l0.35,0l-0.001,0.349
										c-0.02,6.689-0.038,12.897-0.054,18.455c-0.013,2.771-0.026,5.38-0.037,7.806c0.077,2.394-0.193,4.78-1.168,6.608
										c-0.89,1.869-2.184,3.262-3.472,4.216c-1.29,0.965-2.574,1.503-3.634,1.825
										C601.899,140.603,600.684,140.424,600.684,140.424z"/>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path style={{fill:theme.imageHighlight}} d="M576.529,101.393c5.201,3.785,10.558,6.278,16.989,6.147
								c6.431-0.131,13.515-2.195,18.557-6.19L576.529,101.393z"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path style={{fill:theme.dark}} d="M612.075,106.851c0.025-0.006,0.114,0.259,0.261,0.768c0.161,0.591,0.361,1.331,0.607,2.237
									c0.521,2.051,1.232,4.849,2.098,8.261c0.435,1.756,0.908,3.667,1.414,5.713c0.24,1.044,0.543,2.051,0.752,3.222
									c0.188,1.171,0.013,2.429-0.476,3.58c-0.478,1.161-1.343,2.179-2.42,2.928c-1.09,0.728-2.439,1.146-3.793,1.141
									c-2.591,0.014-5.27,0.029-8.015,0.044c-1.373,0.006-2.73,0.011-4.068,0.017c-1.318,0.003-2.687,0.056-4.005-0.239
									c-2.63-0.528-4.921-2.004-6.472-3.897c-0.782-0.945-1.388-1.992-1.813-3.07c-0.413-1.075-0.745-2.1-1.103-3.101
									c-0.692-1.991-1.338-3.851-1.932-5.559c-1.135-3.332-2.066-6.064-2.748-8.067c-0.288-0.893-0.524-1.623-0.712-2.206
									c-0.155-0.507-0.224-0.778-0.2-0.786c0.024-0.009,0.141,0.245,0.342,0.736c0.223,0.57,0.502,1.285,0.843,2.159
									c0.738,1.983,1.744,4.688,2.971,7.988c0.62,1.699,1.295,3.549,2.018,5.529c0.753,1.985,1.316,4.195,2.861,5.939
									c1.472,1.768,3.628,3.138,6.092,3.622c1.221,0.271,2.51,0.219,3.86,0.213c1.337-0.007,2.693-0.014,4.065-0.02
									c2.743-0.01,5.422-0.02,8.011-0.029c1.237,0.008,2.419-0.358,3.404-1.002c0.965-0.665,1.752-1.571,2.187-2.611
									c0.446-1.031,0.617-2.157,0.456-3.216c-0.174-1.05-0.482-2.15-0.704-3.169c-0.475-2.053-0.918-3.972-1.325-5.734
									c-0.772-3.435-1.404-6.251-1.869-8.316c-0.191-0.919-0.347-1.67-0.471-2.27C612.091,107.134,612.05,106.857,612.075,106.851
									z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M581.717,108.397c0,1.09-0.884,1.974-1.974,1.974c-1.09,0-1.974-0.884-1.974-1.974
								c0-1.09,0.884-1.974,1.974-1.974C580.833,106.424,581.717,107.307,581.717,108.397z"/>
						</g>
					</g>
					<g>
						<g>
							<path style={{fill:theme.imageHighlight}} d="M611.702,101.48c0.186,0.841-0.951,1.635-0.937,2.496c0.026,1.617-0.971,3.116-2.489,3.674
								c-0.121,0.044-0.234,0.106-0.335,0.186c-0.716,0.567-0.897,1.56-1.03,2.464c-0.219,1.495-0.438,2.99-0.656,4.485
								c-0.136,0.929-0.28,1.882-0.744,2.698c-0.464,0.816-1.329,1.474-2.265,1.414c-1.352-0.086-2.193-1.549-2.397-2.888
								c-0.256-1.678,0.07-3.476-1.076-4.93c-0.535-0.678-1.428-1.042-2.239-0.746c-2.052,0.75-0.801,4.007-2.739,4.758
								c-0.542,0.21-1.164,0.091-1.642-0.24c-1.013-0.7-1.318-1.872-1.595-2.988c-0.436-1.756-0.872-3.512-1.308-5.268
								L611.702,101.48"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<circle style={{fill:theme.imageHighlight}} cx="560.673" cy="165.389" r="11.045"/>
					</g>
				</g>
				<g>
					<g>
						<circle style={{fill:theme.imageHighlight}} cx="594.898" cy="165.389" r="11.045"/>
					</g>
				</g>
				<g>
					<g>
						<circle style={{fill:theme.imageHighlight}} cx="629.122" cy="165.389" r="11.045"/>
					</g>
				</g>
				<g style={{opacity:0}}>
					<g>
						<circle style={{fill:'#FFFFFF'}} cx="560.673" cy="165.389" r="11.045"/>
					</g>
				</g>
				<g style={{opacity:0.2}}>
					<g>
						<circle cx="629.122" cy="165.389" r="11.045"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M571.719,165.389c-0.072,0.005-0.012-0.985-0.449-2.671c-0.432-1.654-1.543-4.081-4.031-5.955
								c-1.233-0.915-2.783-1.691-4.569-2.001c-1.773-0.298-3.773-0.238-5.673,0.49c-0.957,0.334-1.879,0.838-2.726,1.474
								c-0.857,0.623-1.635,1.397-2.276,2.293c-1.325,1.765-2.071,4.032-2.093,6.368c0.021,2.337,0.767,4.605,2.092,6.371
								c0.641,0.896,1.419,1.67,2.276,2.293c0.847,0.636,1.77,1.14,2.727,1.475c1.901,0.727,3.9,0.788,5.674,0.49
								c1.786-0.31,3.336-1.086,4.569-2.001c2.488-1.874,3.599-4.301,4.031-5.955C571.707,166.373,571.646,165.383,571.719,165.389
								c0,0,0.138,0.987-0.242,2.722c-0.377,1.7-1.457,4.229-4.003,6.211c-1.261,0.968-2.863,1.797-4.72,2.139
								c-1.844,0.331-3.933,0.286-5.929-0.462c-1.004-0.344-1.974-0.866-2.866-1.528c-0.902-0.65-1.721-1.458-2.397-2.396
								c-1.396-1.848-2.187-4.234-2.207-6.688c0.021-2.454,0.812-4.838,2.208-6.686c0.676-0.938,1.495-1.746,2.397-2.395
								c0.892-0.662,1.861-1.184,2.866-1.528c1.996-0.747,4.084-0.793,5.928-0.462c1.857,0.343,3.459,1.171,4.72,2.139
								c2.546,1.982,3.626,4.51,4.003,6.211C571.857,164.402,571.719,165.389,571.719,165.389z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M605.943,165.389c-0.072,0.005-0.012-0.985-0.449-2.671c-0.432-1.654-1.543-4.081-4.031-5.955
								c-1.233-0.915-2.783-1.691-4.569-2.001c-1.773-0.298-3.773-0.238-5.673,0.49c-0.957,0.334-1.879,0.838-2.726,1.474
								c-0.857,0.623-1.635,1.397-2.276,2.293c-1.325,1.765-2.071,4.032-2.093,6.368c0.021,2.337,0.767,4.605,2.092,6.371
								c0.641,0.896,1.419,1.67,2.276,2.293c0.847,0.636,1.77,1.14,2.727,1.475c1.901,0.727,3.9,0.788,5.674,0.49
								c1.786-0.31,3.336-1.086,4.569-2.001c2.488-1.874,3.599-4.301,4.03-5.955C605.931,166.373,605.87,165.383,605.943,165.389
								c0,0,0.138,0.987-0.242,2.722c-0.377,1.7-1.457,4.229-4.003,6.211c-1.261,0.968-2.863,1.797-4.72,2.139
								c-1.844,0.331-3.933,0.286-5.929-0.462c-1.004-0.344-1.974-0.866-2.866-1.528c-0.901-0.65-1.721-1.458-2.397-2.396
								c-1.396-1.848-2.187-4.234-2.207-6.688c0.021-2.454,0.812-4.838,2.208-6.686c0.676-0.938,1.495-1.746,2.397-2.395
								c0.891-0.662,1.861-1.184,2.865-1.528c1.996-0.747,4.084-0.793,5.929-0.462c1.857,0.343,3.459,1.171,4.72,2.139
								c2.546,1.982,3.626,4.51,4.003,6.211C606.081,164.402,605.943,165.389,605.943,165.389z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M640.148,165.389c-0.072,0.005-0.012-0.985-0.449-2.671c-0.432-1.654-1.543-4.081-4.031-5.955
								c-1.233-0.915-2.783-1.691-4.569-2.001c-1.773-0.298-3.773-0.238-5.673,0.49c-0.957,0.334-1.879,0.838-2.726,1.474
								c-0.857,0.623-1.635,1.397-2.276,2.293c-1.325,1.765-2.071,4.032-2.093,6.368c0.021,2.337,0.767,4.605,2.092,6.371
								c0.641,0.896,1.419,1.67,2.276,2.293c0.847,0.636,1.77,1.14,2.727,1.475c1.901,0.727,3.9,0.788,5.674,0.49
								c1.786-0.31,3.336-1.086,4.569-2.001c2.488-1.874,3.599-4.301,4.03-5.955C640.136,166.373,640.076,165.383,640.148,165.389
								c0,0,0.138,0.987-0.242,2.722c-0.377,1.7-1.457,4.229-4.003,6.211c-1.261,0.968-2.863,1.797-4.72,2.139
								c-1.844,0.331-3.933,0.286-5.929-0.462c-1.004-0.344-1.974-0.866-2.866-1.528c-0.902-0.65-1.721-1.458-2.397-2.396
								c-1.396-1.848-2.187-4.234-2.207-6.688c0.021-2.454,0.812-4.838,2.208-6.686c0.676-0.938,1.495-1.746,2.397-2.395
								c0.892-0.662,1.861-1.184,2.865-1.528c1.996-0.747,4.084-0.793,5.928-0.462c1.857,0.343,3.459,1.171,4.72,2.139
								c2.546,1.982,3.626,4.51,4.003,6.211C640.286,164.402,640.148,165.389,640.148,165.389z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
<g id="Character_1">
	<g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<path style={{fill:theme.dark}} d="M140.104,193.528c0.892,1.342,2.313,2.603,3.923,2.531
												c0.475-0.022,1.733-0.394,1.733-0.394c0.537,0.373,0.288,2.278-0.13,2.781c-0.418,0.503-1.275,1.166-1.275,1.166
												c1.813,1.124,3.552,0.733,5.597,0.125c-0.563,3.383-3.075,6.37-6.311,7.504c-1.034,0.363-2.272,0.517-3.154-0.134
												c-0.776-0.573-1.046-1.595-1.233-2.542c-0.686-3.477-0.94-7.038-0.754-10.577L140.104,193.528z"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:'#FFBF9D'}} d="M98.315,208.545L95.52,253.86c-0.475,7.706,0.401,19.123,12.619,19.319l0,0
																										c10.613-0.63,13.906-10.486,14.342-17.677c0.207-3.411,0.386-6.256,0.431-6.605
																										c0,0,11.603-0.374,13.14-11.74c0.743-5.493,1.352-16.931,1.783-27.285
																										c0.463-11.12-7.705-20.727-18.752-22.082l-1.098-0.135C106.056,186.895,98.746,196.6,98.315,208.545z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:'#FF9A6C'}} d="M122.922,248.878c0,0-7.227-0.459-14.284-6.2c0,0,2.596,8.758,14.08,9.127
																										L122.922,248.878z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<g>
																										<g>
																											<g>
																												<g>
																													<path style={{fill:theme.dark}} d="M117.754,212.878c-0.238,0.22-1.504-0.906-3.414-1.051
																														c-1.906-0.174-3.384,0.722-3.573,0.466c-0.093-0.114,0.163-0.525,0.808-0.932
																														c0.635-0.406,1.694-0.766,2.883-0.667c1.189,0.101,2.155,0.628,2.694,1.133
																														C117.705,212.336,117.871,212.781,117.754,212.878z"/>
																												</g>
																											</g>
																										</g>
																									</g>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<g>
																										<g>
																											<g>
																												<path style={{fill:theme.dark}} d="M117.837,218.482c-0.071,0.816-0.82,1.428-1.673,1.37
																													c-0.851-0.059-1.486-0.765-1.415-1.581c0.071-0.816,0.82-1.429,1.672-1.371
																													C117.274,216.958,117.908,217.666,117.837,218.482z"/>
																											</g>
																										</g>
																									</g>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<g>
																										<g>
																											<g>
																												<path style={{fill:theme.dark}} d="M121.89,226.417c0.004-0.1,1.114-0.155,2.904-0.164
																													c0.452,0.009,0.886-0.028,0.999-0.324c0.145-0.31,0.017-0.814-0.136-1.361
																													c-0.287-1.133-0.588-2.32-0.904-3.564c-1.24-5.068-2.08-9.213-1.875-9.261
																													c0.205-0.048,1.376,4.021,2.616,9.089c0.295,1.248,0.578,2.439,0.846,3.577
																													c0.107,0.529,0.326,1.14,0.016,1.782c-0.161,0.319-0.519,0.521-0.814,0.563
																													c-0.297,0.052-0.549,0.026-0.772,0.012C122.985,226.644,121.884,226.518,121.89,226.417z"/>
																											</g>
																										</g>
																									</g>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path style={{fill:theme.dark}} d="M118.51,206.877c-0.246,0.393-1.714-0.151-3.543-0.329
																									c-1.825-0.217-3.381-0.002-3.531-0.437c-0.064-0.207,0.276-0.552,0.949-0.834
																									c0.668-0.282,1.674-0.467,2.77-0.35c1.095,0.12,2.034,0.515,2.62,0.934
																									C118.367,206.28,118.62,206.689,118.51,206.877z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path style={{fill:theme.dark}} d="M135.664,209.68c-0.218,0.336-1.691-0.122-3.497-0.266
																									c-1.804-0.177-3.318,0.013-3.491-0.358c-0.075-0.176,0.238-0.472,0.882-0.715
																									c0.638-0.244,1.614-0.406,2.697-0.31c1.082,0.098,2.026,0.431,2.627,0.786
																									C135.488,209.171,135.76,209.518,135.664,209.68z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<path style={{fill:'#FFBF9D'}} d="M97.875,216.377c-0.184-0.108-7.538-3.273-8.244,4.54
																					c-0.706,7.813,7.283,6.91,7.317,6.687C96.982,227.382,97.875,216.377,97.875,216.377z"/>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<path style={{fill:'#FF9A6C'}} d="M94.829,224.429c-0.032-0.028-0.145,0.083-0.384,0.168
																						c-0.234,0.082-0.626,0.118-1.02-0.071c-0.798-0.378-1.374-1.751-1.285-3.163
																						c0.044-0.712,0.255-1.376,0.557-1.914c0.291-0.55,0.696-0.916,1.119-0.952
																						c0.422-0.058,0.694,0.217,0.769,0.445c0.085,0.228,0.021,0.379,0.062,0.397c0.02,0.021,0.18-0.116,0.146-0.444
																						c-0.018-0.158-0.093-0.351-0.263-0.517c-0.174-0.169-0.443-0.272-0.735-0.267
																						c-0.606-0.013-1.199,0.493-1.531,1.083c-0.366,0.59-0.619,1.335-0.669,2.135
																						c-0.092,1.579,0.581,3.125,1.681,3.554c0.537,0.193,1.01,0.069,1.263-0.094
																						C94.798,224.617,94.855,224.445,94.829,224.429z"/>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path style={{fill:theme.dark}} d="M88.089,203.19c1.767-2.681,2.31-5.957,3.353-8.994
														c1.043-3.037,2.917-6.144,5.991-7.072l0.066,0.033c2.766-0.957,6.111-0.808,8.246,1.184
														c0.752-4.042,4.478-7.088,8.501-7.935c4.023-0.847,10.362,0.089,14.096,1.809c2.886,1.33,5.614,3.898,8.898,7.636
														c1.384,1.575,2.648,3.258,3.756,5.038l2.929,10.834l-3.234,5.447c-0.196,0.372-0.398,0.743-0.637,1.109l-0.001-0.026
														l-2.889,11.368c-0.01-3.222,0.415-14.105,0.404-17.327c-4.949-0.458-9.337-2.68-12.483-6.529
														c-1.66-2.031-2.92-4.535-5.21-5.812c-2.953-1.647-6.615-0.734-9.86,0.217l0.141-0.115
														c-1.698,0.686-3.262,1.701-4.579,2.974c0.406,6.362-0.572,12.81-2.857,18.762c-0.344,0.897-1.253,2.734-2.165,3.036
														c-1.792,0.593-2.177-1.22-3.188-2.224c-0.744-0.738-1.896-0.886-3.159-0.944c-0.646-1.989-1.249-5.317-1.749-8.23
														c-1.077,0.27-2.213,0.302-3.297,0.057c0.377-0.79,0.753-1.58,1.13-2.37c-0.452,0.256-0.921,0.517-1.438,0.567
														c-0.517,0.05-1.097-0.171-1.305-0.646C87.28,204.423,87.72,203.751,88.089,203.19z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path style={{fill:theme.jacketColor}} d="M109.273,189.829c0.021,0.03-0.493,0.278-1.197,0.991
														c-0.697,0.71-1.502,1.965-1.939,3.705c-0.223,0.865-0.383,1.836-0.435,2.887c-0.056,1.051-0.027,2.181-0.005,3.372
														c0.019,1.191,0.042,2.448-0.099,3.749c-0.07,0.649-0.181,1.308-0.354,1.963c-0.176,0.643-0.382,1.341-0.9,1.876
														c-0.414,0.437-1.077,0.607-1.638,0.463c-0.565-0.129-1.058-0.458-1.41-0.89c-0.693-0.894-0.822-1.99-0.957-2.957
														l0.238,0.041c-0.434,0.842-0.878,1.673-1.507,2.324c-0.619,0.655-1.453,1.067-2.281,1.1
														c-0.412,0.017-0.823-0.07-1.167-0.264c-0.351-0.193-0.598-0.504-0.761-0.827c-0.155-0.329-0.227-0.686-0.192-1.024
														c0.036-0.338,0.184-0.653,0.41-0.87l0.116,0.114c-0.93,0.959-1.886,1.453-2.544,1.72
														c-0.332,0.133-0.596,0.21-0.774,0.258c-0.18,0.044-0.274,0.061-0.276,0.054c-0.002-0.008,0.088-0.039,0.263-0.097
														c0.174-0.062,0.431-0.151,0.753-0.295c0.64-0.288,1.567-0.797,2.459-1.751l0.116,0.114
														c-0.404,0.388-0.462,1.102-0.159,1.693c0.152,0.291,0.38,0.569,0.689,0.733c0.307,0.169,0.681,0.246,1.058,0.226
														c0.761-0.038,1.538-0.425,2.118-1.048c0.594-0.621,1.024-1.431,1.453-2.275l0.182-0.358l0.056,0.399
														c0.136,0.966,0.279,2.02,0.911,2.826c0.315,0.387,0.766,0.685,1.262,0.797c0.495,0.121,1.032-0.018,1.392-0.392
														c0.453-0.463,0.661-1.123,0.832-1.756c0.17-0.639,0.28-1.284,0.351-1.922c0.142-1.278,0.124-2.527,0.112-3.716
														c-0.015-1.19-0.036-2.325,0.03-3.389c0.062-1.064,0.236-2.048,0.474-2.924c0.467-1.761,1.315-3.03,2.048-3.729
														C108.742,190.045,109.273,189.829,109.273,189.829z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path style={{fill:theme.jacketColor}} d="M144.049,202.678c0.003,0.004-0.03,0.029-0.095,0.074
														c-0.08,0.053-0.177,0.116-0.296,0.194c-0.129,0.087-0.293,0.188-0.499,0.286c-0.203,0.104-0.434,0.23-0.713,0.331
														c-1.085,0.452-2.81,0.869-4.93,0.639c-2.1-0.222-4.591-1.093-6.766-2.884c-2.201-1.753-4.083-4.267-5.584-7.115
														c-0.762-1.424-1.369-2.874-2.051-4.22c-0.677-1.346-1.436-2.597-2.38-3.602c-0.932-1.009-2.035-1.775-3.192-2.159
														c-0.576-0.202-1.163-0.271-1.725-0.285c-0.565,0.002-1.104,0.09-1.604,0.227c-1,0.282-1.821,0.786-2.451,1.316
														c-0.632,0.535-1.067,1.111-1.371,1.608c-0.571,1.024-0.633,1.696-0.674,1.683c-0.005-0.001,0.001-0.041,0.015-0.119
														c0.02-0.094,0.043-0.208,0.071-0.347c0.071-0.303,0.224-0.739,0.497-1.268c0.295-0.514,0.726-1.112,1.363-1.67
														c0.635-0.553,1.469-1.082,2.496-1.384c0.513-0.147,1.069-0.244,1.655-0.252c0.582,0.008,1.199,0.076,1.796,0.281
														c1.202,0.389,2.343,1.173,3.302,2.201c0.972,1.024,1.749,2.297,2.434,3.652c0.69,1.355,1.298,2.802,2.054,4.213
														c1.49,2.826,3.345,5.313,5.503,7.048c2.134,1.774,4.565,2.643,6.633,2.888c2.084,0.25,3.787-0.131,4.871-0.548
														C143.501,203.042,144.03,202.644,144.049,202.678z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path style={{fill:theme.dark}} d="M135.153,214.457c-0.238,0.22-1.504-0.906-3.414-1.052
																	c-1.905-0.174-3.384,0.722-3.572,0.466c-0.094-0.115,0.163-0.525,0.808-0.932c0.635-0.406,1.694-0.766,2.883-0.667
																	c1.189,0.101,2.155,0.628,2.694,1.133C135.104,213.915,135.269,214.36,135.153,214.457z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path style={{fill:theme.dark}} d="M133.371,220.005c-0.071,0.815-0.82,1.428-1.673,1.369
																c-0.851-0.058-1.486-0.765-1.415-1.581c0.071-0.815,0.82-1.429,1.672-1.371
																C132.807,218.481,133.442,219.19,133.371,220.005z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path style={{fill:theme.dark}} d="M121.775,232.824c0.007,0.066-0.448,0.199-1.197,0.103c-0.738-0.089-1.773-0.465-2.605-1.294
									c-0.831-0.831-1.21-1.865-1.3-2.603c-0.097-0.748,0.034-1.204,0.101-1.197c0.086,0.003,0.1,0.451,0.285,1.118
									c0.179,0.663,0.574,1.555,1.308,2.288c0.734,0.732,1.627,1.125,2.29,1.303C121.325,232.725,121.772,232.738,121.775,232.824
									z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:theme.imageHighlight}} d="M184.725,257.715l-30.076,26.604l-29.034-23.43l-2.194,30.779l18.008,14.417
						c7.45,2.904,15.561,4.341,21.477-1.038l33.32-33.769L184.725,257.715z"/>
				</g>
				<g>
					<g>
						<path style={{fill:theme.imageHighlight}} d="M66.864,272.507c0,0,0.698-11.573,11.055-17.672c1.512-0.89,14.012-6.345,14.012-6.345
							l3.718-5.325l22.813,14.549l2.877,2.627l2.345-5.638l2.777,4.223l-1.174-0.191l0.328,2.154l1.416,9.352l1.004,26.79
							c-0.013-0.284,0.426,9.415,0.153,13.888c-1.031,16.886-3.908,59.921-4.928,62.953c-1.264,3.759-4.821,3.866-4.821,3.866
							l-59.64-16.79c-3.367-0.286-6.436-9.444-1.402-12.388L66.864,272.507z"/>
					</g>
				</g>
			</g>
			<g>
				<g style={{opacity:0.3}}>
					<path d="M71.634,280.65c0,0,24.838,46.313,33.341,50.875c8.503,4.563,22.219,1.659,22.219,1.659v-3.755L71.634,280.65z"/>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill:theme.imageHighlight}} d="M89.286,260.696c3.443,1.553,35.313,43.434,35.313,43.434l45.113-0.373l2.935,21.109
										c0,0-47.298,6.843-58.327,4.433c-11.028-2.41-43.286-49.289-45.26-52.462c-3.131-5.032-0.463-12.996,4.379-17.225"/>
								</g>
								<g>
									<g>
										<path style={{fill:theme.dark}} d="M73.439,259.612c0,0-0.16,0.176-0.488,0.499c-0.336,0.314-0.797,0.823-1.362,1.518
											c-1.093,1.399-2.611,3.665-3.391,6.988c-0.365,1.645-0.571,3.578-0.073,5.58c0.396,2.047,1.923,3.804,3.251,5.822
											c2.767,3.986,5.977,8.484,9.614,13.39c3.64,4.904,7.692,10.229,12.242,15.782c2.276,2.775,4.676,5.608,7.248,8.434
											c2.581,2.812,5.298,5.674,8.409,8.217c1.56,1.236,3.222,2.496,5.128,3.103l0.355,0.115l0.38,0.053l0.762,0.106
											c0.51,0.061,1.014,0.164,1.533,0.193c1.039,0.046,2.084,0.092,3.135,0.139c4.216,0.048,8.525-0.207,12.905-0.496
											c8.758-0.655,17.806-1.636,27.052-2.79c4.21-0.527,8.373-1.075,12.47-1.656l-0.22,0.292
											c-1.003-7.228-1.981-14.283-2.928-21.109l0.252,0.217c-16.545,0.122-31.804,0.234-45.113,0.332l-0.106,0.001l-0.064-0.084
											c-10.312-13.542-18.935-24.583-25.067-32.165c-3.068-3.789-5.516-6.711-7.25-8.637c-0.864-0.966-1.553-1.679-2.043-2.128
											c-0.482-0.464-0.797-0.608-0.785-0.631c0,0,0.329,0.133,0.827,0.583c0.508,0.437,1.214,1.138,2.094,2.092
											c1.767,1.902,4.246,4.802,7.344,8.57c6.191,7.54,14.864,18.544,25.217,32.061l-0.169-0.083
											c13.309-0.122,28.568-0.263,45.113-0.415l0.221-0.002l0.031,0.219c0.951,6.826,1.935,13.88,2.942,21.107l0.036,0.256
											l-0.255,0.036c-4.103,0.584-8.266,1.132-12.48,1.66c-9.252,1.154-18.306,2.133-27.08,2.782
											c-4.389,0.286-8.707,0.538-12.95,0.484c-1.061-0.049-2.117-0.097-3.167-0.145c-0.525-0.03-1.047-0.135-1.569-0.199
											l-0.781-0.111l-0.39-0.055l-0.383-0.123c-2.051-0.66-3.712-1.946-5.298-3.201c-3.144-2.578-5.861-5.455-8.446-8.281
											c-2.574-2.84-4.971-5.684-7.244-8.469c-4.542-5.573-8.58-10.915-12.204-15.837c-3.621-4.923-6.811-9.439-9.557-13.443
											c-0.684-1.004-1.344-1.97-1.961-2.918c-0.608-0.959-1.085-1.977-1.292-3.011c-0.496-2.058-0.267-4.02,0.117-5.683
											c0.821-3.357,2.381-5.621,3.507-7.008c0.581-0.689,1.057-1.188,1.407-1.491C73.256,259.761,73.439,259.612,73.439,259.612
											z"/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path style={{fill:theme.imageHighlight}} d="M91.931,248.49c0.222,0.512,21.065,14.519,21.065,14.519l5.026-4.825l2.679,2.151"/>
					</g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M120.701,260.335c-0.018,0.022-0.281-0.154-0.754-0.501c-0.54-0.409-1.21-0.917-2.02-1.531
								l0.201-0.009c-1.313,1.291-3.012,2.962-4.968,4.885l-0.135,0.133l-0.16-0.107c-1.912-1.281-4.051-2.715-6.308-4.227
								c-4.116-2.787-7.818-5.346-10.467-7.242c-2.649-1.892-4.251-3.137-4.158-3.245c2.187,1.475,7.354,4.958,14.918,10.057
								c2.246,1.526,4.375,2.973,6.279,4.267l-0.295,0.026c2.001-1.876,3.74-3.505,5.084-4.765l0.101-0.094l0.1,0.085
								c0.774,0.658,1.415,1.203,1.931,1.641C120.491,260.094,120.719,260.313,120.701,260.335z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M126.112,264.162c0.036-0.004,0.125,0.55,0.255,1.554c0.131,1.004,0.297,2.459,0.481,4.26
							c0.37,3.601,0.799,8.584,1.112,14.1c0.311,5.517,0.436,10.52,0.449,14.14c0.007,1.81-0.006,3.275-0.036,4.287
							c-0.027,1.012-0.059,1.572-0.095,1.572c-0.036,0-0.072-0.559-0.107-1.571c-0.033-1.104-0.077-2.545-0.13-4.282
							c-0.107-3.615-0.29-8.607-0.6-14.117c-0.312-5.508-0.683-10.49-0.959-14.096c-0.128-1.733-0.235-3.171-0.317-4.273
							C126.096,264.725,126.076,264.165,126.112,264.162z"/>
					</g>
				</g>
				<g>
					<g>
						<path style={{fill:theme.imageHighlight}} d="M61.243,348.566c14.021-1.025,27.938-3.483,41.462-7.323"/>
					</g>
					<g>
						<g>
							<path style={{fill:theme.dark}} d="M102.705,341.243c0.01,0.035-0.549,0.225-1.57,0.54c-1.02,0.319-2.506,0.742-4.352,1.233
								c-3.691,0.984-8.835,2.184-14.576,3.201c-5.744,1.012-10.989,1.647-14.793,1.987c-1.902,0.171-3.444,0.283-4.511,0.333
								c-1.067,0.053-1.657,0.066-1.66,0.03c-0.003-0.036,0.583-0.117,1.644-0.232c1.149-0.127,2.662-0.295,4.489-0.497
								c3.788-0.433,9.011-1.124,14.74-2.133c5.727-1.014,10.869-2.154,14.577-3.045c1.786-0.436,3.265-0.797,4.388-1.071
								C102.117,341.333,102.695,341.208,102.705,341.243z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path style={{fill:theme.jacketColor}} d="M119.973,436.697l-28.478-9.74c0,0-19.464,25.675-24.903,24.387
																									l16.902-55.895l44.994,13.356L119.973,436.697z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:'#E0E0E0'}} d="M99.143,410.406c0.031-1.171-0.959-2.287-2.127-2.366
																										c-1.169-0.079-2.312,0.918-2.364,2.088c-0.052,1.17,1.095,2.546,2.266,2.494
																										c1.17-0.052,2.448-1.296,2.218-2.445"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path style={{fill:'#E0E0E0'}} d="M82.331,395.054l4.361,1.289l-16.596,53.652c0,0-1.835,2.161-3.503,1.35
																									L82.331,395.054z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:theme.dark}} d="M91.506,427.949c-0.27-0.091,0.019-1.494-0.453-3.102
																										c-0.43-1.621-1.402-2.671-1.218-2.889c0.129-0.236,1.638,0.665,2.174,2.622
																										C92.563,426.532,91.738,428.084,91.506,427.949z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:theme.dark}} d="M87.308,433.078c-0.283-0.017-0.39-1.34-1.264-2.616
																										c-0.836-1.302-2.014-1.913-1.92-2.181c0.035-0.27,1.69,0.025,2.747,1.632
																										C87.941,431.511,87.57,433.151,87.308,433.078z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:theme.dark}} d="M79.284,434.286c0.017-0.266,1.495-0.233,2.778,0.92
																										c1.291,1.144,1.492,2.609,1.229,2.656c-0.271,0.086-0.822-0.985-1.89-1.916
																										C80.357,434.991,79.229,434.565,79.284,434.286z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:theme.dark}} d="M98.242,429.448c-0.214-0.189,0.541-1.328,0.903-2.902
																										c0.394-1.567,0.239-2.924,0.516-2.996c0.245-0.098,0.901,1.38,0.449,3.228
																										C99.671,428.629,98.416,429.647,98.242,429.448z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path style={{fill:theme.dark}} d="M123.435,360.763c1.096-0.303,49.324,51.433,50.42,59.983
										c1.096,8.55-0.658,16.003-6.357,20.826c-5.7,4.823-70.589-10.961-70.589-10.961l6.138-27.183l-23.457-7.892l20.963-38.363
										L123.435,360.763z"/>
								</g>
							</g>
						</g>
						<g style={{opacity:0.3}}>
							<g>
								<g>
									<path d="M134.844,372.229l1.545,1.66c0,0-27.644,28.999-36.124,28.602l-13.573-4.598l45.92-28.18L134.844,372.229z"/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<path style={{fill:theme.jacketColor}} d="M102.888,404.135c-0.041,0.139,10.028,3.221,22.486,6.882
											c12.463,3.662,22.596,6.517,22.637,6.378c0.041-0.139-10.026-3.22-22.489-6.882
											C113.064,406.852,102.929,403.996,102.888,404.135z"/>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path style={{fill:theme.jacketColor}} d="M157.168,413.578l-8.482,26.908c0,0,24.501,17.658,23.418,22.783
																									l-52.763-14.572l11.495-42.468L157.168,413.578z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:'#E0E0E0'}} d="M133.003,433.694c-1.099-0.003-2.121,0.95-2.169,2.047
																										c-0.048,1.097,0.912,2.146,2.011,2.168c1.098,0.023,2.361-1.084,2.286-2.18
																										c-0.075-1.095-1.27-2.265-2.341-2.023"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path style={{fill:'#E0E0E0'}} d="M118.997,449.797l1.109-4.116l50.654,14.336c0,0,2.067,1.671,1.344,3.252
																									L118.997,449.797z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:theme.dark}} d="M149.616,440.453c-0.08,0.255-1.4,0.016-2.897,0.495
																										c-1.509,0.439-2.471,1.375-2.68,1.207c-0.224-0.116,0.586-1.55,2.408-2.097
																										C148.264,439.495,149.737,440.233,149.616,440.453z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:theme.dark}} d="M154.518,444.271c-0.01,0.266-1.247,0.396-2.423,1.244
																										c-1.201,0.813-1.747,1.931-2,1.849c-0.254-0.027-0.015-1.585,1.467-2.611
																										C153.034,443.713,154.58,444.024,154.518,444.271z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:theme.dark}} d="M155.832,451.762c-0.249-0.01-0.252-1.396,0.799-2.624
																										c1.043-1.236,2.411-1.457,2.461-1.212c0.087,0.252-0.905,0.793-1.752,1.815
																										C156.468,450.742,156.095,451.808,155.832,451.762z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<g>
																									<path style={{fill:theme.dark}} d="M150.868,434.107c-0.173,0.205-1.257-0.477-2.74-0.78
																										c-1.478-0.333-2.745-0.158-2.819-0.415c-0.098-0.228,1.272-0.876,3.015-0.494
																										C150.068,432.786,151.051,433.939,150.868,434.107z"/>
																								</g>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g style={{opacity:0.3}}>
							<g>
								<g>
									<polygon points="120.895,436.023 124.484,421.921 121.811,420.676 138.122,376.271 141.236,378.979 128.416,419.658 
										123.83,436.637 									"/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path style={{fill:theme.dark}} d="M58.799,360.947c0,0,96.423-33.589,98.176-34.246c0.481-0.18,1.374-0.394,2.417-0.608
										c6.396-1.316,13.133,0.013,18.355,3.934c4.378,3.288,8.033,8.556,6.85,16.842c-2.631,18.414-26.164,81.272-26.164,81.272
										l-32.56-9.789c-0.186-0.056-0.369-0.593-0.267-0.428c0.267,0.428,16.521-51.936,16.111-51.32
										c-0.438,0.658-32.861,26.816-46.336,31.631c-13.844,4.947-27.541-0.97-34.131-10.19
										C58.094,383.628,55.42,371.193,58.799,360.947z"/>
								</g>
							</g>
							<g>
								<g>
									<g>
										<path style={{fill:theme.jacketColor}} d="M58.799,360.947c0,0-0.115,0.337-0.324,1.022c-0.232,0.682-0.474,1.718-0.752,3.083
											c-0.489,2.734-0.897,6.89-0.174,12.16c0.391,2.618,0.976,5.545,2.283,8.522c0.327,0.742,0.71,1.482,1.183,2.191
											c0.49,0.691,1.026,1.368,1.583,2.047c1.175,1.306,2.452,2.629,3.967,3.768c2.958,2.366,6.631,4.259,10.779,5.404
											c2.078,0.542,4.27,0.946,6.544,1.01c2.271,0.136,4.613-0.07,6.962-0.503c0.585-0.126,1.173-0.253,1.764-0.38
											c0.588-0.14,1.163-0.346,1.75-0.518c1.189-0.31,2.304-0.863,3.467-1.325c1.141-0.511,2.249-1.118,3.395-1.682
											c1.109-0.628,2.23-1.263,3.363-1.904c4.474-2.659,8.97-5.679,13.556-8.904c4.582-3.23,9.246-6.682,13.985-10.329
											c2.372-1.82,4.756-3.697,7.151-5.632c0.595-0.49,1.192-0.982,1.791-1.475c0.151-0.13,0.293-0.238,0.452-0.387l0.236-0.221
											l0.117-0.111c-0.18-0.042,0.485,0.015-0.389-0.108c-0.019-0.178-0.007-0.036-0.017-0.053
											c-0.086,0.354-0.201,0.738-0.307,1.108c-0.447,1.492-0.898,2.994-1.351,4.506c-3.694,12.078-7.64,24.759-11.842,37.889
											c-0.534,1.638-1.071,3.283-1.611,4.935c-0.276,0.821-0.551,1.654-0.855,2.456c-0.023,0.074-0.113,0.25-0.08,0.212
											c0.847,0.355,0.27-0.516-0.113,0.228c0.015,0.07,0.027,0.095,0.04,0.13c0.026,0.065,0.053,0.123,0.087,0.182
											c0.062,0.099,0.149,0.276,0.386,0.349l0.314,0.095l1.259,0.379c3.36,1.011,6.749,2.031,10.164,3.058
											c6.831,2.054,13.767,4.141,20.793,6.254l0.249,0.075l0.089-0.242c1.073-2.916,2.228-6.053,3.389-9.207
											c5.2-14.321,10.141-28.354,14.556-42.029c2.196-6.84,4.308-13.577,6.06-20.272c0.464-1.666,0.817-3.354,1.228-5.016
											c0.39-1.667,0.659-3.357,0.953-5.029c0.465-3.376,0.125-6.884-1.272-9.946c-1.37-3.079-3.738-5.562-6.402-7.401
											c-2.676-1.852-5.727-3.057-8.836-3.606c-3.111-0.551-6.279-0.466-9.271,0.192c-0.745,0.189-1.488,0.378-2.227,0.566
											c-0.711,0.249-1.419,0.496-2.123,0.743c-1.41,0.493-2.808,0.982-4.194,1.467c-11.086,3.881-21.413,7.497-30.842,10.797
											c-18.83,6.61-34.07,11.959-44.615,15.661c-5.248,1.853-9.326,3.293-12.105,4.275c-1.371,0.49-2.419,0.864-3.138,1.121
											C59.15,360.806,58.799,360.947,58.799,360.947s0.378-0.114,1.096-0.355c0.727-0.248,1.788-0.609,3.175-1.081
											c2.794-0.962,6.893-2.375,12.169-4.193c10.558-3.66,25.815-8.948,44.668-15.483c9.422-3.275,19.741-6.861,30.819-10.711
											c1.385-0.482,2.781-0.967,4.189-1.457c0.704-0.245,1.411-0.491,2.12-0.738c0.719-0.181,1.441-0.362,2.165-0.544
											c2.93-0.64,6.038-0.719,9.083-0.175c3.043,0.542,6.025,1.725,8.638,3.538c2.604,1.803,4.9,4.221,6.221,7.201
											c1.347,2.964,1.678,6.364,1.221,9.668c-0.294,1.652-0.56,3.325-0.95,4.984c-0.411,1.655-0.762,3.333-1.227,4.995
											c-1.752,6.675-3.867,13.406-6.065,20.239c-4.418,13.662-9.362,27.69-14.56,42.006c-1.16,3.152-2.314,6.288-3.387,9.203
											l0.338-0.167c-7.027-2.112-13.964-4.196-20.795-6.249c-3.416-1.026-6.805-2.044-10.165-3.054l-1.259-0.378l-0.314-0.094
											c-0.033-0.027-0.103,0.223-0.021,0.034c0.048-0.057,0.009-0.02,0.011-0.041c-0.017-0.019-0.04-0.054-0.06-0.089
											c-0.021-0.036-0.04-0.077-0.055-0.114c-0.065-0.006,0.116-0.065-0.14-0.003c0.019-0.039-0.849,0.266,0.105,0.212
											c0.148-0.224,0.129-0.257,0.189-0.384c0.315-0.832,0.585-1.656,0.863-2.483c0.539-1.655,1.076-3.302,1.61-4.942
											c4.196-13.138,8.13-25.82,11.812-37.907c0.452-1.514,0.901-3.019,1.347-4.513c0.108-0.379,0.217-0.741,0.314-1.142
											c0-0.064,0.024,0.03,0.007-0.196c-0.857-0.112-0.298-0.028-0.488-0.033l-0.097,0.086l-0.192,0.174
											c-0.124,0.116-0.289,0.246-0.431,0.368c-0.595,0.492-1.189,0.982-1.78,1.47c-2.387,1.934-4.766,3.813-7.133,5.634
											c-4.729,3.649-9.382,7.106-13.953,10.34c-4.573,3.228-9.056,6.252-13.509,8.911c-1.126,0.641-2.241,1.275-3.344,1.903
											c-1.135,0.562-2.237,1.169-3.365,1.677c-1.148,0.458-2.248,1.009-3.421,1.317c-0.579,0.171-1.146,0.377-1.727,0.516
											c-0.584,0.127-1.164,0.254-1.741,0.379c-2.318,0.433-4.63,0.643-6.874,0.515c-2.247-0.057-4.414-0.45-6.471-0.979
											c-4.104-1.119-7.746-2.978-10.682-5.309c-1.505-1.122-2.772-2.426-3.941-3.714c-0.553-0.669-1.089-1.34-1.573-2.016
											c-0.464-0.688-0.844-1.415-1.171-2.145c-1.306-2.935-1.906-5.846-2.31-8.447c-0.751-5.239-0.38-9.38,0.073-12.111
											c0.26-1.365,0.485-2.401,0.694-3.076C58.711,361.311,58.799,360.947,58.799,360.947z"/>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<path style={{fill:theme.jacketColor}} d="M156.595,352.187c-0.032-0.055-1.088,0.467-2.684,1.5c-1.596,1.031-3.714,2.607-5.82,4.611
											c-2.104,2.008-3.771,4.062-4.838,5.638c-0.536,0.786-0.94,1.442-1.189,1.913c-0.253,0.468-0.378,0.735-0.346,0.753
											c0.073,0.043,0.699-0.947,1.837-2.449c1.133-1.505,2.82-3.491,4.899-5.475c2.081-1.98,4.138-3.583,5.668-4.685
											C155.651,352.891,156.637,352.258,156.595,352.187z"/>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<path style={{fill:theme.jacketColor}} d="M145.48,348.559c-0.061-0.026-0.535,0.899-1.146,2.461
											c-0.611,1.562-1.333,3.773-1.891,6.274c-0.555,2.504-0.839,4.812-0.946,6.485c-0.109,1.674-0.071,2.712-0.005,2.715
											c0.079,0.003,0.173-1.027,0.375-2.68c0.201-1.653,0.54-3.928,1.089-6.406c0.553-2.477,1.21-4.68,1.728-6.263
											C145.202,349.562,145.554,348.589,145.48,348.559z"/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path style={{fill:theme.jacketColor}} d="M185.529,299.432l12.338-24.932c0.838-1.693,2.575-2.767,4.477-2.767h31.001
									c1.15,0,1.885,1.217,1.342,2.223l-20.985,38.849c-0.588,1.089-1.733,1.769-2.979,1.769h-32.153L185.529,299.432z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill:theme.dark}} d="M234.482,274.138c0,0.02-0.214,0.036-0.62,0.05c-0.471,0.008-1.058,0.018-1.776,0.03
										c-1.625,0-3.835,0.001-6.529,0.002c-5.583-0.047-13.155-0.112-21.522-0.183c-1.024,0.042-2.035,0.205-2.862,0.687
										c-0.833,0.47-1.428,1.26-1.902,2.088c-0.901,1.704-1.659,3.481-2.444,5.101c-1.54,3.255-2.931,6.196-4.113,8.694
										c-1.163,2.41-2.117,4.387-2.819,5.84c-0.32,0.637-0.583,1.158-0.793,1.576c-0.187,0.357-0.294,0.541-0.312,0.533
										c-0.018-0.008,0.053-0.208,0.206-0.582c0.184-0.43,0.413-0.966,0.694-1.621c0.659-1.472,1.556-3.475,2.65-5.917
										c1.149-2.513,2.502-5.472,4-8.746c0.78-1.643,1.5-3.372,2.434-5.156c0.494-0.87,1.145-1.758,2.108-2.307
										c0.954-0.554,2.064-0.723,3.129-0.767c8.443,0.055,16.034,0.237,21.553,0.351c2.694,0.075,4.903,0.137,6.527,0.182
										c0.717,0.032,1.304,0.058,1.774,0.079C234.27,274.095,234.483,274.118,234.482,274.138z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path style={{fill:'#FFFFFF'}} d="M215.38,279.6c0.751,0.387,0.78,1.002,0.465,1.542c-0.315,0.54-1.034,0.783-1.641,0.617
									c-0.402-0.11-0.776-0.411-0.855-0.818c-0.079-0.406,0.297-1.284,0.947-1.418C214.296,279.524,215.006,279.407,215.38,279.6z
									"/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path style={{fill:'#FFBF9D'}} d="M166.581,309.043l2.138-2.181c0,0,3.139-4.528,4.423-5.943
									c1.284-1.415,8.989-6.367,8.989-6.367s3.713-4.99,4.283-4.99c0.571,0,2.377,0.32,1.89,2.64
									c-0.469,2.234-1.322,5.237-2.036,6.694l16.456,0.358c1.037,0.022,2.041,0.464,2.681,1.273
									c0.43,0.543,0.61,1.143-0.161,1.525c-1.712,0.849-8.704,2.547-8.704,2.547l3.122-0.798c0,0,0.669,2.961-1.305,4.367
									c-1.964,1.398-2.102,1.666-2.102,1.666s-0.097,2.801-0.953,3.933c-0.856,1.132-10.127,5.862-10.127,5.862
									s-5.925,1.736-8.921,1.595c-2.996-0.141-6.079,1.543-6.416,1.634C169.502,322.949,166.581,309.043,166.581,309.043z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill:'#FF9A6C'}} d="M187.853,298.968c-0.004,0.163-2.078,0.246-4.623,0.514
										c-2.545,0.279-4.646,0.405-4.66,0.228c-0.009-0.141,2.03-0.52,4.602-0.802C185.751,298.636,187.86,298.812,187.853,298.968
										z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill:'#FF9A6C'}} d="M185.656,306.21c-0.051-0.175,2.951-0.987,6.723-1.417c1.882-0.237,3.585-0.405,4.815-0.54
										c1.23-0.13,1.988-0.232,2.008-0.151c0.018,0.074-0.72,0.32-1.946,0.555c-1.224,0.24-2.927,0.473-4.803,0.709
										C188.692,305.795,185.695,306.343,185.656,306.21z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill:'#FF9A6C'}} d="M185.656,311.55c0.001-0.171,2.399-0.243,5.295-0.743c2.9-0.485,5.186-1.209,5.243-1.048
										c0.058,0.137-2.182,1.12-5.145,1.617C188.09,311.886,185.647,311.698,185.656,311.55z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill:'#FF9A6C'}} d="M186.65,317.534c-0.159,0.026-0.398-0.615-0.534-1.432
										c-0.136-0.817-0.118-1.501,0.041-1.527c0.159-0.026,0.397,0.615,0.533,1.432
										C186.827,316.824,186.809,317.507,186.65,317.534z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill:'#FF9A6C'}} d="M185.176,310.37c-0.165-0.013-0.251-0.544-0.534-1.103
										c-0.269-0.567-0.636-0.962-0.544-1.099c0.074-0.137,0.717,0.126,1.068,0.846
										C185.52,309.733,185.331,310.397,185.176,310.37z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill:'#FF9A6C'}} d="M183.298,303.198c-0.163,0.017-0.331-0.324-0.53-0.711c-0.193-0.39-0.367-0.729-0.256-0.848
										c0.104-0.118,0.529,0.097,0.776,0.59C183.537,302.72,183.455,303.186,183.298,303.198z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<path style={{fill:'#FFBF9D'}} d="M188.836,257.758c0,0,0.367-4.676,2.794-7.659c6.361-7.82,4.244-14.793,4.244-14.793
					c2.202-2.434,5.862,0.475,6.578,6.046c1.487,11.571-0.489,24.599-0.607,25.074c-0.158,0.63-6.746,3.426-6.746,3.426
					l-8.791-10.271L188.836,257.758z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path style={{fill:'#FFFFFF'}} d="M355.242,268.388H206.984c-4.777,0-8.65-3.873-8.65-8.65v-63.474c0-4.777,3.873-8.65,8.65-8.65
						h148.258c4.777,0,8.65,3.873,8.65,8.65v63.474C363.892,264.515,360.02,268.388,355.242,268.388z"/>
				</g>
				<g>
					<g>
						<path style={{fill:theme.dark}} d="M355.242,268.388c-0.001-0.024,0.421-0.004,1.225-0.123c0.798-0.115,2.005-0.407,3.347-1.255
							c1.326-0.831,2.79-2.306,3.536-4.506c0.39-1.095,0.495-2.337,0.453-3.679c-0.004-1.34-0.008-2.767-0.012-4.278
							c-0.02-12.099-0.048-29.589-0.083-51.199c-0.001-1.351-0.003-2.718-0.004-4.101c-0.001-0.691-0.001-1.387-0.002-2.086
							c-0.002-0.704,0.01-1.396-0.094-2.078c-0.356-2.742-2.217-5.322-4.86-6.499c-1.312-0.637-2.773-0.798-4.271-0.765
							c-1.504,0.001-3.023,0.003-4.556,0.004c-3.066,0.002-6.189,0.005-9.366,0.007c-12.71,0.007-26.289,0.016-40.578,0.024
							c-28.581,0.006-60.001,0.013-92.981,0.02c-2.004,0.004-3.983,0.739-5.487,2.042c-1.518,1.288-2.528,3.139-2.81,5.092
							c-0.149,0.966-0.093,1.988-0.104,3.005c0,1.015,0,2.028,0,3.039c0,2.023,0,4.038-0.001,6.046
							c-0.001,8.032-0.003,15.947-0.004,23.724c-0.002,7.777-0.004,15.416-0.006,22.898c-0.001,1.87-0.001,3.731-0.002,5.581
							c-0.072,1.825,0.429,3.607,1.41,5.097c0.999,1.467,2.439,2.636,4.099,3.224c1.646,0.676,3.454,0.496,5.245,0.525
							c28.582,0.021,54.321,0.04,75.94,0.056c21.61,0.035,39.098,0.063,51.196,0.083c6.037,0.021,10.727,0.038,13.919,0.049
							c1.584,0.011,2.794,0.02,3.62,0.026C354.825,268.37,355.242,268.388,355.242,268.388s-0.418,0.018-1.233,0.027
							c-0.826,0.006-2.036,0.015-3.62,0.026c-3.192,0.011-7.882,0.028-13.919,0.049c-12.098,0.02-29.586,0.048-51.196,0.083
							c-21.619,0.016-47.358,0.035-75.94,0.056c-0.897-0.007-1.78,0.018-2.697-0.016c-0.923-0.021-1.844-0.212-2.715-0.534
							c-1.757-0.622-3.281-1.855-4.339-3.407c-1.041-1.564-1.571-3.49-1.497-5.369c-0.001-1.85-0.001-3.711-0.002-5.581
							c-0.002-7.481-0.004-15.121-0.006-22.898c-0.001-7.777-0.003-15.692-0.004-23.724c0-2.008,0-4.024-0.001-6.046
							c0-1.011,0-2.024,0-3.039c0.012-1.009-0.049-2.032,0.11-3.082c0.3-2.076,1.373-4.042,2.984-5.409
							c1.596-1.384,3.702-2.166,5.827-2.169c32.98,0.007,64.4,0.014,92.981,0.02c14.289,0.008,27.869,0.016,40.578,0.024
							c3.177,0.002,6.3,0.005,9.366,0.007c1.533,0.001,3.052,0.003,4.556,0.004c1.496-0.034,3.08,0.14,4.438,0.809
							c2.767,1.239,4.708,3.942,5.075,6.811c0.108,0.717,0.093,1.439,0.094,2.132c-0.001,0.699-0.001,1.395-0.002,2.086
							c-0.001,1.383-0.003,2.75-0.004,4.101c-0.035,21.61-0.063,39.1-0.083,51.199c-0.004,1.511-0.008,2.938-0.012,4.278
							c0.036,1.333-0.08,2.625-0.485,3.729c-0.775,2.235-2.275,3.719-3.626,4.545c-1.368,0.844-2.591,1.12-3.396,1.219
							c-0.404,0.073-0.715,0.055-0.921,0.069C355.347,268.392,355.242,268.388,355.242,268.388z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<polygon style={{fill:'#EBEBEB'}} points="238.514,225.393 238.514,236.048 263.613,246.812 263.826,236.613 249.802,230.355 
						263.826,224.37 263.826,214.488 					"/>
				</g>
				<g>
					<polygon style={{fill:'#EBEBEB'}} points="323.712,236.763 323.712,226.109 298.394,215.344 298.18,225.543 312.326,231.801 
						298.18,237.787 298.18,247.669 					"/>
				</g>
				<g>
					<path style={{fill:'#EBEBEB'}} d="M282.573,205.692c0,0.265-14.743,50.004-14.326,49.916c0.417-0.088,10.979-0.007,10.979-0.007
						l14.54-49.909H282.573z"/>
				</g>
			</g>
			<g>
				<path style={{fill:'#E0E0E0'}} d="M215.551,198.327c0,1.809-1.562,3.275-3.488,3.275c-1.926,0-3.488-1.466-3.488-3.275
					c0-1.809,1.562-3.275,3.488-3.275C213.989,195.052,215.551,196.518,215.551,198.327z"/>
			</g>
			<g>
				<path style={{fill:'#E0E0E0'}} d="M225.133,198.327c0,1.809-1.562,3.275-3.488,3.275c-1.926,0-3.488-1.466-3.488-3.275
					c0-1.809,1.562-3.275,3.488-3.275C223.571,195.052,225.133,196.518,225.133,198.327z"/>
			</g>
			<g>
				<path style={{fill:'#E0E0E0'}} d="M234.715,198.327c0,1.809-1.562,3.275-3.488,3.275c-1.926,0-3.488-1.466-3.488-3.275
					c0-1.809,1.562-3.275,3.488-3.275C233.153,195.052,234.715,196.518,234.715,198.327z"/>
			</g>
		</g>
	</g>
</g>
</svg>

    );
  }
}
